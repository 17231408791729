import React, {useRef, useEffect,useState} from 'react';
import { Form, Button,Modal} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {getQuestion,editSingleQuestion} from '../../store/api/course.api';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from "react-i18next";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ck_config } from "./commonConst";

const EditQuestion = (prop) => {
   const dispatch = useDispatch();
   const editorConfiguration = ck_config;
   const [textContent, setTextContent] = useState(null);
   const [show, setShow] = useState(false);
   const { t } = useTranslation();
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   } 
   const [value, setValue] = useState({
      question: "",
      option_1: "",
      option_2: "",
      option_3: "",
      option_4:"",
      is_correct_ans:""
    });
   const onChange = (e) => {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    };
    
   useEffect(() => {
      setShow(prop.show);
      
      dispatch(getQuestion(prop.editId)).then((res) => {
         setValue({
            ...value,
            ['question']: res.payload.data[0].question,
            ['option_1']: res.payload.data[0].option_1,
            ['option_2']: res.payload.data[0].option_2,
            ['option_3']:res.payload.data[0].option_3,
            ['option_4']:res.payload.data[0].option_4,
            ['is_correct_ans']:res.payload.data[0].is_correct_ans,
           
          });
      });
   }, [])
   const onEdit = () => {
      
      let body;
      body = {
         "id":prop.editId,
        'question':value.question,
        'option_1':value.option_1,
        'option_2':value.option_2,
        'option_3':value.option_3,
        'option_4':value.option_4,
        'is_correct_ans':value.is_correct_ans,
       
      }
      dispatch(editSingleQuestion(body))
         .unwrap()
         .then(() => {
            prop.callbackModal();
         })
         .catch(() => {
            prop.callbackModal();
         });
     
   }
   return(
      <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
           <Modal.Title>{t('Edit_Note')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='usermanagement-modal'>
           <Form className='mx-auto'>
          
              <div className='mb-10 wow fadeInDown'>
              <Form.Group className="mb-10 wow fadeInDown" controlId="formTitle">
              <Form.Label className="font-20 text-white fw-medium mb-2 d-block">{t('Question')}</Form.Label>
                        <Form.Control type="text" name="question" placeholder={t('Question')} autoComplete="off" aria-label="Title" value={value.question || ""}
                        onChange={onChange} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formTitle">
                     <Form.Label className="font-20 text-white fw-medium mb-2 d-block">{t('Enter_answer_1')}</Form.Label>
                        <Form.Control type="text" name="option_1" placeholder={t('Enter_answer_1')} autoComplete="off" aria-label="Title" value={value.option_1 || ""}
                        onChange={onChange} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formVideoLength">
                     <Form.Label className="font-20 text-white fw-medium mb-2 d-block">{t('Enter_answer_2')}</Form.Label>
                     <Form.Control type="text" name="option_2" placeholder={t('Enter_answer_2')} autoComplete="off" aria-label="length" value={value.option_2}
                        onChange={onChange} />
                     </Form.Group>
                     
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formVideoLength">
                     <Form.Label className="font-20 text-white fw-medium mb-2 d-block">{t('Enter_answer_3')}</Form.Label>
                     <Form.Control type="text" name="option_3" placeholder={t('Enter_answer_3')} autoComplete="off" aria-label="length" value={value.option_3}
                        onChange={onChange} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formVideoLength">
                     <Form.Label className="font-20 text-white fw-medium mb-2 d-block">{t('Enter_answer_4')}</Form.Label>
                     <Form.Control type="text" name="option_4" placeholder={t('Enter_answer_4')} autoComplete="off" aria-label="length" value={value.option_4}
                        onChange={onChange} />
                     </Form.Group>
                     <Form.Group className='flex-grow-1 question-control'>
                     <Form.Label className="font-20 text-white fw-medium mb-2 d-block">{t('Enter_correct_answer')}</Form.Label>
                        <Form.Control type="text" name="is_correct_ans" placeholder={t('Enter_correct_answer')} autoComplete="off" aria-label="length" value={value.is_correct_ans}
                        onChange={onChange} />
                        </Form.Group>
                  </div>
              
           </Form>
           <Modal.Footer>
              
                        {prop.editId &&
                     <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onEdit}> {t('Update')} </Button>
                        }
            </Modal.Footer>
           
        </Modal.Body>
     </Modal>
         
      </>
   )
}

export default EditQuestion;