const TextBlock = ({ title, subtitle }) => {
    return (
        <>
            <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24">{title}</h4>
            {
                subtitle && <span className="opacity-60 fw-medium fs-20 mobile-font-14">{subtitle}</span>
            }

        </>
    )
}

export default TextBlock;