import React, { Component, useState, useEffect } from "react";
import Header from "../commom/Header";
import { Container, Image, Row, Col, Form, ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Footer from "../commom/Footer";
const InternaKurser = () => {

  return (
    <>
      <div className="d-flex flex-column flex-grow-1 homepage ">
        <Header />
        <div className="page-wrapper flex-grow-1 py-0">

          <section className="banner-wrapper team-banner position-relative bg-wrap">
            <Container>
              <div className="banner-content-area max-w-100">
                <div className="block font-32 text-center fw-light video-style-font">
                  <h1 className="font-64 fw-semibold pt-8 pt-md-0 no-extra">Utbilda medarbetarna med skräddarsydda interna kurser</h1>
                  <p className="font-25">
                    Säkerställ att alla medarbetare har tillgång till samma kunskap vare sig det gäller era interna <br className="d-xl-block d-none" /> datasystem, företagskultur eller kundbemötande.
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <a href="https://cal.com/docens" target="_blank" class="btn font-20 mobile-font-16 btn-gradient rounded-pill w-20"><span> Boka möte </span> </a>
                </div>
              </div>
              <div className="banner-video">
                <video
                  src="/assets/video/Background-flow.mp4"
                  autoplay="autoplay"
                  playsinline="playsinline"
                  muted="muted"
                  loop="loop"
                ></video>
              </div>
            </Container>
          </section>


          <section className="content-info-blocks team-content-block mt-5">
            <Container fluid>
              <div className="text-start">
                <h2 className="font-40 fw-light mb-md-3 mb-1">Att skapa egna interna kurser är enklare än du kanske tror</h2>
                <p className="font-25 fw-medium">Vi hjälper er genom processen</p>
              </div>
              <Row className="g-7">
                <Col lg={4}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">1 - Utforma kursen</h4>
                    <p className="fw-light text-white">Oavsett om ert syfte är att visa ett internt dataprogram med enbart en skärm, eller en rundtur kring er anläggning är vi med er och planerar upplägget från start. Hur vill ni att  innehållet ska presenteras och av vem? Vilken inspelningsplats fungerar bäst för syftet? Hur får vi kursen att spegla företagets kultur och ton? Önskar ni retorisk hjälp utifrån, eller extern kurshållare som skräddarsyr en utbildning till er personal?
                      Vi hjälper er med dessa frågor och mycket mer. </p>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">2 - Filma och redigera kursen</h4>
                    <p className="fw-light text-white">Vi riggar inspelningsplatsen med kvalitetsutrustning för inspelning, belysning och ljud. Redigerar och lägger till eventuell grafik och animationer för att göra innehållet  mer engagerande.</p>
                    <Row className="gap-md-0 gap-8">
                      <Col md={6}>
                        <div>
                          <Image
                            src="./assets/images/Egna_interna.png"
                            alt="Egna_interna"
                            className="img-fit center-fit rounded-20"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <Image
                            src="./assets/images/desktop-img.png"
                            alt="desktop-img"
                            className="img-fit center-fit rounded-20"
                          />
                        </div>
                      </Col>
                    </Row>

                  </div>
                </Col>
                <Col lg={12}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">3 - Distribuera kursen</h4>
                    <p className="fw-light text-white">Vi hjälper er distribuera filmen på ert interna intranät eller på vår plattform. Väljer ni vår plattform får ni även tillgång till administratörsverktyg som att tilldela medarbetare kursen, sätta deadlines och följa medarbetarnas utveckling. Det ges även möjlighet till att skapa ett diplom. </p>
                    <Row  className="gap-lg-0 gap-8">
                      <Col lg={3}>
                        <div className="inner-card h-100">
                          <div className="mb-2">
                            <label className="form-label">Tilldela kurs</label>
                            <Form.Select disabled className="dark-select-box" aria-label="Default select example">
                              <option>Excel</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </Form.Select>
                          </div>
                          <div>
                            <label className="form-label">Tilldela deadline</label>
                            <Form>
                              {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-2">
                                  <Form.Check disabled
                                    inline
                                    label="Ingen deadline"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                  />
                                </div>
                              ))}
                            </Form>
                            <div className="d-flex">
                              <Form.Group className="me-3">
                                <Form.Control type="text" value="2024-16-01" disabled />
                              </Form.Group>
                              <Form.Group>
                                <Form.Control type="text" value="12:00" disabled />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={9}>
                        <div className="inner-card h-100">
                          <h5 className="fw-medium">Erik Eriksson</h5>
                          <div className="d-flex align-items-center mb-2">
                            <div className="small-thumbnail flex-shrink-0">
                              <Image
                                src="./assets/images/sm-box-1.png"
                                alt="teams"
                                className="img-fit center-fit"
                              />
                            </div>
                            <div className="w-100 ps-6">
                              <h5 className="fw-medium mb-1 text-lightgray">Microsoft 365 - Excel grundkurs</h5>
                              <ProgressBar className="green-progressbar" now="65" label="65%" />
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="small-thumbnail flex-shrink-0">
                              <Image
                                src="./assets/images/sm-box-2.png"
                                alt="teams"
                                className="img-fit center-fit"
                              />
                            </div>
                            <div className="w-100 ps-6">
                              <h5 className="fw-medium mb-1 text-lightgray">Grundkurs i vårt interna affärssystem</h5>
                              <ProgressBar className="green-progressbar" now="100" label="100%" />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </div>
                </Col>

              </Row>
            </Container>
          </section>

          <section className="mb-4">
            <Container>
              <div className="text-center max-600 mx-auto mt-120">
                <h2 className="font-64 mobile-font-30 fw-semibold">Vill du veta mer?</h2>
                <p className="font-24 fw-light">Boka ett förutsättningslöst möte med oss så berättar vi mer!</p>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <a href="https://cal.com/docens" target="_blank" class="btn font-20 mobile-font-16 btn-gradient rounded-pill"><span>Boka möte </span></a>
                </div>
              </div>
            </Container>
          </section>

        </div>
        <Footer />
      </div>
    </>
  );
};
export default InternaKurser;
