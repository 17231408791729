import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/user.service";
import { setMessage } from "./message";
import { toast } from "react-toastify";

export const getPodcasts = createAsyncThunk(
  "podcast/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPodcasts(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addPodcasts = createAsyncThunk(
  "podcast/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addPodcasts(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const editPodcasts = createAsyncThunk(
  "/podcast/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editPodcasts(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removePodcasts = createAsyncThunk(
  "podcast/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removePodcasts(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addPodcastTag = createAsyncThunk(
  "/podcast/addTag",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addPodcastTag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editPodcastTag = createAsyncThunk(
  "podcast/editTag",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editPodcastTag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deletePodcastTag = createAsyncThunk(
  "/tag/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.deletePodcastTag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSinglePodcast = createAsyncThunk(
  "/podcast/singlePodcast",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSinglePodcast(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const myCount = createAsyncThunk(
  "/user/get_license_count",
  async (body, thunkAPI) => {
    try {
      const response = await userService.myCount(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const podcasttagList = createAsyncThunk(
  "/podcast/tagList",
  async (body, thunkAPI) => {
    try {
      const response = await userService.podcasttagList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const customSortPodcast = createAsyncThunk(
  "podcast/sort/custom",
  async (body, thunkAPI) => {
    try {
      const response = await userService.customSortPodcast(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getEducations = createAsyncThunk(
  "education/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getEducations(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getWebinar = createAsyncThunk(
  "webinar/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getWebinar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getReadPageOpenar = createAsyncThunk(
  "webinar/readpageopenar",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getReadPageOpenar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getWebinarSignup = createAsyncThunk(
  "webinar/getsignup",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getWebinarSignup(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getWebinarchat = createAsyncThunk(
  "webinar/getWebinarchat",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getWebinarchat(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const addWebinar = createAsyncThunk(
  "webinar/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addWebinar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const publishWeinar = createAsyncThunk(
  "/webinar/publish",
  async (body, thunkAPI) => {
    try {
      const response = await userService.publishWeinar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeWebinar = createAsyncThunk(
  "webinar/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeWebinar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addEducations = createAsyncThunk(
  "education/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addEducations(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const editEducation = createAsyncThunk(
  "/education/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editEducation(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeEducation = createAsyncThunk(
  "education/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeEducation(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const publishEducation = createAsyncThunk(
  "/education/publish",
  async (body, thunkAPI) => {
    try {
      const response = await userService.publishEducation(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const publishPodcast = createAsyncThunk(
  "/education/publish",
  async (body, thunkAPI) => {
    try {
      const response = await userService.publishPodcast(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPodcastProductList = createAsyncThunk(
  "podcast/mediaList",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPodcastProductList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPodcastProductAdd = createAsyncThunk(
  "podcast/mediaAdd",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPOdcastProductAdd(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPodcastProductEdit = createAsyncThunk(
  "podcast/mediaEdit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPOdcastProductEdit(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPodcastProductRemove = createAsyncThunk(
  "podcast/mediaList",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPOdcastProductRemove(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const educationEmail = createAsyncThunk(
  "education/sendEmail",
  async (body, thunkAPI) => {
    try {
      const response = await userService.sendEducationEmail(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const webinarEmail = createAsyncThunk(
  "webinar/sendEmail",
  async (body, thunkAPI) => {
    try {
      const response = await userService.webinarEmail(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editWebinar = createAsyncThunk(
  "/webinar/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editWebinar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getDates = createAsyncThunk(
  "webinar/getDates",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getDates(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const singleWebinar = createAsyncThunk(
  "/webinar/single",
  async (body, thunkAPI) => {
    try {
      const response = await userService.singleWebinar(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const sendChat = createAsyncThunk(
  "/webinar/sendChat",
  async (body, thunkAPI) => {
    try {
      const response = await userService.sendChat(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getMyChat = createAsyncThunk(
  "/webinar/getMyChat",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getMyChat(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const webinarWatch = createAsyncThunk(
  "webinar/webinarWatch",
  async (body, thunkAPI) => {
    try {
      const response = await userService.webinarWatch(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getWebinarWatcher = createAsyncThunk(
  "webinar/watcher",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getWebinarWatcher(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const pressReadMore = createAsyncThunk(
  "/webinar/pressReadMore",
  async (body, thunkAPI) => {
    try {
      const response = await userService.pressReadMore(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = {};
const podcastSlice = createSlice({
  name: "podcast",
  initialState,
  extraReducers: {
    [getPodcasts.fulfilled]: (state, action) => {
      state.allpodcast = action.payload.data;
    },
    [getPodcasts.rejected]: (state, action) => {
      state.allpodcast = null;
    },
    [getEducations.fulfilled]: (state, action) => {
      state.alleducation = action.payload.data;
    },
    [getEducations.rejected]: (state, action) => {
      state.alleducation = null;
    },
  },
});

const { reducer } = podcastSlice;
export default reducer;
