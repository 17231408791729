import React, { useEffect} from 'react';
import Header from '../commom/Header2';
import { Row, Col, Image } from 'react-bootstrap';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../commom/CheckoutForm'
import { useDispatch, useSelector } from 'react-redux';
import { addQuantity,removefromCart,removefromtotal } from '../../store/api/cart';
import { useTranslation } from "react-i18next";
import {stripeKey} from '../commom/commonConst';
const Cart = () =>{
   const stripePromise = loadStripe(stripeKey);
   const { cart } = useSelector((state) => state.cart);
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const onChange = (e,id,price) => {
      dispatch(addQuantity({'course_id':id,'qty':e.target.value,'price':price}))
      .unwrap()
      .then(() => {
      })
      .catch(() => {
         
      });
     };
     const handleDelete = (item) => {
      dispatch(removefromtotal(item))
      .unwrap()
      .then(() => {
         dispatch(removefromCart(item))
      })
      .catch(() => {
         
      });
    };
    const add3Dots = (string, limit) => {
      if(string) {
         var dots = "...";
         if (string?.length > limit) {
            string = string.substring(0, limit) + dots;
         }
      }
      return string !="null" ? string : '';
   }
   useEffect(() => {
         cart.map((item, key) => {
            dispatch(addQuantity({'course_id':item?.course_id,'qty':1,'price':item?.r_price}))
            .unwrap()
            .then(() => {
            })
            .catch(() => {
            });     
         });
    }, [])
   return(
      <>
         <Header />
         
         <div className='page-wrapper flex-grow-1 page-block overflow-hidden'>
         {cart && cart.length >0 &&
            <Row className='gx-lg-14'>
               <Col lg={7}>
                  <div className='cart-leftwrapper'>
                     <div className='block text-white mb-13 '>
                        <h1 className='mb-2 h2 fw-semibold wow fadeInDown'>{t('Item_in_your_Cart')}</h1>
                        
                     </div>
                     <div className='leftblock-products position-relative'>
                     { cart &&
                        cart.map((item, key) => (
                        <div className='course-block text-light d-flex align-items-center py-7 px-10 mb-lg-10 mb-8 wow fadeInDown'>
                           <span className='course-image flex me-8 flex-shrink-0'>
                              <Image src={item?.course_image} alt='Excel' width={88} height={88} />
                           </span>
                           <div className='d-flex justify-content-between align-items-center flex-grow-1 flex-sm-nowrap flex-wrap'>
                              <div className='pe-5 flex-grow-1'>
                                 <h5 className='mb-1 text-white fw-medium'>{item?.title}</h5>
                                 <span className='font-14' dangerouslySetInnerHTML={{ __html: add3Dots(item?.content,100) }}></span>
                              </div>
                              <div className='fw-medium flex-shrink-0 course-priceinfoblock w-sm-100 mt-sm-0 mynumberclass'>
                                 <input className='course-priceinfo text-center ' min="1" type='number' style={{width: "82px"}} name="qty" value={item.quantity} onChange={(e)=>onChange(e,item?.course_id,item?.r_price)} ></input>
                                 <span className='course-priceinfo'>{item?.r_price} Sek</span>
                              </div>
                              <a className='btn btn-link icon-ic-trash text-white link-hover action-btn' onClick={() => handleDelete(item)} ></a>
                           </div>
                        </div> 
                        ))
                     }
                     </div>
                     <Row>
                        <Col md={5}></Col>
                        <Col md={7}>
                       
                        </Col>
                     </Row>
                    
                  </div>
               </Col>
               <Col lg={5}>
                  <div className='cart-rightwrapper'>
                     <div className='block text-white mb-13'>
                        <h2 className='mb-2 fw-semibold opacity-0'>{t('Enter_payment_detail')}</h2>
                     </div>
                     <Elements stripe={stripePromise}>
                        <CheckoutForm />
                        </Elements>
                  </div>

               </Col>
            </Row>   
            }
             {cart && cart.length===0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                  }
         </div>
         
        
      </>
   )
}

export default Cart;