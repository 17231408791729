import React, { useState, useRef, useEffect } from "react";
import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { isMobile } from "react-device-detect";

import {
  Row,
  Col,
  Image,
  Container,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Modal,
  Accordion,
  Pagination,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getSingleProduct,
  videoProgress,
  createNote,
  randomAnkiQuestion,
  myPurchageList,
  getCheckoutUrl,
  getSubsPlan,
  getSingleMediaDetail
} from "../../store/api/course.api";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../store/api/cart";
import { useNavigate, Link } from "react-router-dom";
import Player from "@vimeo/player";
import AddNewProduct from "../public/AddNewProduct";
import SelectTest from "../commom/SelectTest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  checkSubsDate,
  monthlyPlan,
  yearlyPlanName,
} from "../commom/commonConst";
import Accordion_Custome from "../commom/Accordion";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import Wrapper from "../component/Wrapper";
const OpenCourseTopics = () => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showTestList, setShowTestList] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowAddNew = () => setShowAdd(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseid, mediaId } = useParams();
  const [allItems, setAllItems] = useState({});
  const [alllength, setAlllength] = useState(0);
  const { t } = useTranslation();
  const [mainVideoURL, setMainVideoURL] = useState(null);
  const [mainTitle, setMainTitle] = useState(null);
  const [mainMediaTitle, setMainMediaTitle] = useState(null);
  const [course_sub_title, setCourse_sub_title] = useState(null);
  const [mainPrice, setMainPrice] = useState(null);
  const [course_id, setCourseId] = useState(null);
  const [image, setImg] = useState(null);

  const [mainDiscription, setMainDiscription] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [mediaIdForPercent, setMediaIdForPercent] = useState(null);
  const [mediaLenth, setMediaLenth] = useState("0:00");
  const { id, role_id, business_id, email } = useSelector(
    (state) => state?.auth?.user || {}
  );
  const [mediaIdIndex, setMediaIdIndex] = useState(0);
  const [ankQuestion, setAnkiQuestion] = useState();
  const [showAns, setShowAns] = useState(false);
  const [value, setValue] = useState({ ankans: "" });
  const [showCreator, setShowCreator] = useState(false);
  const [singleCourse, setSingleCourses] = useState(0);
  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editCourseId, setEditCourseId] = useState(null);
  const editorConfiguration = {
    toolbar: ["bold", "italic", "undo", "redo"],
  };
  const [textContent, setTextContent] = useState(null);
  const user = useSelector((state) => state?.auth?.user);
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedTab, setSelectedTab] = useState(isMobile ? "Avsnitt" : "Om_kursen");
  const [isReadMore, setIsReadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [itemToMinus, setItemToMinus] = useState(0);
  const [showonMobileBuy, setShowonMobileBuy] = useState(false);
  const pageLimit = 10;
  var iframe;
  var player;
  useEffect(() => {
    refresh();
    if (id) {
      getAnkRandomly();
    }
    if (role_id === 2) {
      let body = {
        business_id: business_id,
      };
      dispatch(myPurchageList(body)).then((res) => {
        res.payload.data &&
          res.payload.data.purches_courses.map((list) => {
            if (list.course_id == courseid) {
              setSingleCourses(list.unassign_course_count);
            }
          });
      });
    }
    return () => {
      localStorage.removeItem("firstVideo");
      localStorage.removeItem("firstName");
      // Anything in here is fired on component unmount.
      if (parseFloat(localStorage.getItem("per")).toFixed(2) > 0 && role_id) {
        let data = {
          id: courseid,
          user_id: id,
          media_id: localStorage.getItem("mediaIdOnLeftCom"),
          view_time: localStorage.getItem("per"),
        };
        dispatch(videoProgress(data))
          .unwrap()
          .then(() => { })
          .catch(() => { });
      }
    };
  }, [courseid]);
  const onSetURL = (item, index) => {
    //window.scrollTo(0, 0);
    if (
      allItems.is_purchaed ||
      allItems.is_assigned ||
      role_id === 1 ||
      item.is_free ||
      allItems.podcast
    ) {
      if (role_id) {
        setMediaIdIndex(index);
        setMediaLenth(item.media_length);
        setMediaIdForPercent(item.media_id);
        localStorage.setItem("mediaIdOnLeftCom", item?.media_id);
        setMainVideoURL(item?.video_url);
        //setMainTitle(item?.media_title);
        setMainMediaTitle(item?.media_title)
        // setCourse_sub_title(item?.course_sub_title)
        //  setMainDiscription(item.description != "null" ? item.description : "");
        setMainPrice(item?.s_price);
        setImg(item.image);
        setCourseId(item.id);

      } else {
        toast.warning(<>{t("Please_register_or_login_to_see_free_courses")}</>);
        navigate("/registrera");
      }
    } else {
      if (role_id === 2 || role_id === 3 || !role_id) {
        toast.error(<>{t("Du_har_inte_köpt_den_här_kursen")}</>);
        scrollToSection('buysection')
      }
      if (role_id === 4) {
        toast.error(
          "Du har inte blivit tilldelad den här kursen"
        );
      }
      //  notify()
    }
    // call API to set half seen
    if (percentage > 0 && role_id) {
      let data = {
        id: courseid,
        user_id: id,
        media_id: mediaIdForPercent,
        view_time: percentage,
      };
      dispatch(videoProgress(data))
        .unwrap()
        .then(() => { })
        .catch(() => { });
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };
  const onSetEdit = (item, index) => {
    setEditId(item.media_id);
    setEditName(mainTitle);
    setEditCourseId(item.course_id);
    setShowEdit(true);
  };
  const addCourseToCart = () => {
    dispatch(addProductToCart(allItems))
      .unwrap()
      .then(() => {
        navigate("/cart");
      })
      .catch(() => { });
  };
  const callbackModal = () => {
    setShow(false);
    setShowTestList(false);
    setShowEdit(false);
  };
  const closeAdd = () => {
    setShowAdd(false);
    refresh();
  };
  const refresh = () => {
    getAllData();
  }
  const getAllData = () => {

    let req = {
      id: courseid,
      page: page,
      limit: pageLimit,
    };
    if (id) {
      req.user_id = id;
      req.role_id = role_id;
    }
    dispatch(getSingleProduct(req)).then((course) => {
      if (!localStorage.getItem("firstVideo")) {
        localStorage.setItem('firstVideo', course?.payload?.data.media[0]?.video_url)
      }
      if (!localStorage.getItem("firstName")) {
        localStorage.setItem('firstName', course?.payload?.data?.media[0]?.media_title)
      }

      setAllItems(course.payload?.data);
      let condition = page === 1 || course.payload?.data?.is_purchaed || course.payload?.data?.is_assigned || role_id === 1 || course?.payload?.data?.media[0]?.is_free
      setAlllength(course.payload?.data?.media.length);
      setMainVideoURL(condition ? course?.payload?.data.media[0]?.video_url : localStorage.getItem("firstVideo"));
      setMediaIdForPercent(course?.payload?.data?.media[0]?.media_id);
      setMediaLenth(course.payload?.data?.media[0]?.media_length);
      localStorage.setItem(
        "mediaIdOnLeftCom",
        course?.payload?.data?.media[0]?.media_id
      );
      setMainTitle(course.payload?.data?.title);
      setMainMediaTitle(condition ? course?.payload?.data?.media[0]?.media_title : localStorage.getItem("firstName"))
      setCourse_sub_title(course.payload?.data?.course_sub_title)
      setMainPrice(
        course.payload?.data?.s_price
          ? course.payload?.data?.s_price
          : course.payload?.data?.r_price
      );
      setImg(course.payload?.data?.course_image);
      setCourseId(course.payload?.data?.id);
      setMainDiscription(
        course.payload?.data?.content != "null"
          ? course.payload?.data?.content
          : ""
      );
      // added for specific episod
      if (mediaId) {
        allItems?.media_count
        dispatch(getSingleMediaDetail(mediaId)).then((media) => {
          setMediaIdIndex(media.payload.data[0].index);
          setMainVideoURL(media.payload.data[0].video_url);
          setMainMediaTitle(media.payload.data[0].media_title);
          setPage(media.payload.data[0].page);
          if (mediaIdIndex > 0 && mediaIdIndex < 9) {
            setItemToMinus(0)
          } else if (mediaIdIndex > 9 && mediaIdIndex < 19) {
            setItemToMinus(10)
          } else if (mediaIdIndex > 19 && mediaIdIndex < 29) {
            setItemToMinus(20)
          } else if (mediaIdIndex > 29 && mediaIdIndex <= 40) {
            setItemToMinus(30)
          }
        });
      }
    });

    dispatch(getSubsPlan())
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          let newArr = [];

          res.data
            .map((data) => {
              if (data.price_id == yearlyPlanName) {
                newArr.push(data);
              }
              if (data.price_id == monthlyPlan) {
                setSelectedPlan(data.price / 100);
                newArr.push(data);
              }
            })
            .filter((data) => (data = data));
        }
      })
      .catch(() => { });
  };

  let totalLength;
  let scd;
  var myinter = setInterval(function () {

    iframe = document.querySelector("#videoIframe");
    if (iframe?.src) {
      player = new Player(iframe);
      player
        .getDuration()
        .then(function (duration) {
          totalLength = duration;
        })
        .catch(function (error) {
          // an error occurred
        });
      player.getCurrentTime().then(function (seconds) {
        scd = seconds;
        setPercentage((scd / totalLength) * 100);
        localStorage.setItem("per", (scd / totalLength) * 100);
      });
      if (percentage > 99.9 && role_id) {
        let data = {
          id: courseid,
          user_id: id,
          media_id: mediaIdForPercent,
          view_time: 100,
          is_complete: true,
        };
        dispatch(videoProgress(data))
          .unwrap()
          .then(() => {
            clearInterval(myinter);
          })
          .catch(() => { });
      }
    }
  }, 1000);

  const saveNote = () => {
    var data = {
      user_id: id,
      media_id: mediaIdForPercent,
      note_content: textContent,
    };
    dispatch(createNote(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.success(<>{t("Din_reflektion_har")}</>);
          setTextContent(null)
          handleClose1()
        }
      })
      .catch((error) => {
        setTextContent(null)
        // toast.error(error.message)
      });
  };
  //  get anki randomly
  const getAnkRandomly = () => {
    setShowAns(false);
    dispatch(randomAnkiQuestion(courseid)).then((course) => {
      setAnkiQuestion(course.payload.data);
    });
  };
  const onShowans = () => {
    setShowAns(true);
  };
  const onChange = (e) => {
    setShowAns(false);
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit && isReadMore) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const handleClose = () => {
    setShowCreator(false);
    setShowonMobileBuy(false);
  };
  const openShow = () => {
    setShowCreator(true);
  };

  const MediaList = ({ item, onSetURL, index }) => {
    return (
      <div className="text-svart teacherinfo-box  wow fadeInDown d-md-block">
        <div className="full-width course-listing-table font-20 course-text-right mobile-font-13">
          <div
            className={
              (mediaId ? index === mediaIdIndex - itemToMinus : index === mediaIdIndex && page === 1)
                ? "border-primary-green d-flex justify-content-between free-color"
                : " d-flex justify-content-between link-hover "
            }
          >
            <div onClick={() => onSetURL(item, index)}>
              <p title={item.media_title} className={`mb-0 custome_opicity activate-link-black ${((index === 0 && page == 1) || allItems?.is_purchaed || allItems?.is_assigned || role_id === 1 || item.is_free) ? 'active' : ''}`}>
                {add3Dots(item.media_title, 60)}
              </p>
            </div>
            <div className="text-right ps-12 ps-xl-2">
              <span onClick={() => onSetURL(item, index)} className={`custome_opicity activate-link-black ${((index === 0 && page == 1) || allItems?.is_purchaed || allItems?.is_assigned || role_id === 1 || item.is_free) ? 'active' : ''}`}>
                {item.media_length} min
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const generateCheckoutURL = () => {
    let newObj = {
      course_id: allItems?.course_id,
      quantity: 1,
      price: allItems?.s_price ? allItems?.s_price : allItems?.r_price,
      currency: "SEK",
    };
    let newCOurseArr = [];
    newCOurseArr.push(newObj);

    const generateCheckoutURLObj = {
      item_id: course_id,
      item_name: mainTitle,
      s_price: mainPrice * 100,
      image: image,
      user_id: id ? id : null, // if not logged in pass null
      email: email ? email : null, // if not logged in pass null
      role_id: role_id,
      business_id: business_id ? business_id : null,
      currency: "SEK",
      is_course: true,
      mode: "payment", // payment or subscription
      quantity: 1,
      courses: newCOurseArr,
      is_trial: false,
      is_year_wise: false
    };
    dispatch(getCheckoutUrl(generateCheckoutURLObj)).then((data) => {
      if (data.payload.status == 200) {
        window.location.replace(data.payload.data.cs_url);
      }
      // setPackageDetails(packages.payload.data);
    });
  };
  const generateCheckoutURLSubscription = (plan) => {
    let newObj = {
      course_id: allItems?.course_id,
      quantity: 1,
      price: allItems?.s_price,
      currency: "SEK",
    };
    let newCOurseArr = [];
    newCOurseArr.push(newObj);
    const generateCheckoutURLObj = {
      item_id: course_id,
      item_name: mainTitle,
      s_price: mainPrice,
      image: image,
      user_id: id ? id : null, // if not logged in pass null
      email: email ? email : null, // if not logged in pass null
      role_id: role_id,
      business_id: business_id ? business_id : null,
      currency: "SEK",
      is_course: true,
      mode: "subscription", // payment or subscription
      interval: "month", //day, week, month or year
      price_id: plan == "month" ? monthlyPlan : yearlyPlanName,
      is_trial: plan == "month" ? true : false,
      quantity: 1,
      // "courses": []
      courses: newCOurseArr,
      is_year_wise: false
    };
    dispatch(getCheckoutUrl(generateCheckoutURLObj)).then((packages) => {
      if (packages.payload.status == 200) {
        window.location.replace(packages.payload.data.cs_url);
      }
    });
  };
  const setTab = (tab) => {
    setSelectedTab(tab);
  }

  useEffect(() => {
    refresh();
  }, [page]);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const showonMobileBuyFunction = () => {
    setShowonMobileBuy(true)
  }
  const onSetURLFromButton = (index) => {

    if (
      allItems.is_purchaed ||
      allItems.is_assigned ||
      role_id === 1 ||
      allItems.is_free ||
      allItems.podcast
    ) {
      if (role_id) {
        if (index >= 0 && index < allItems.media.length) {
          let item = allItems?.media[index];
          setMediaIdIndex(index);
          setMediaLenth(allItems.media.media_length);
          setMediaIdForPercent(item.media_id);
          localStorage.setItem("mediaIdOnLeftCom", item?.media_id);
          setMainVideoURL(item?.video_url);
          //setMainTitle(item?.media_title);
          setMainMediaTitle(item?.media_title)
          //  setCourse_sub_title(item?.course_sub_title)
          //   setMainDiscription(item.description != "null" ? item.description : "");
          setMainPrice(item?.s_price);
          setImg(item.image);
          setCourseId(item.id);
        } else {

          if (index >= allItems.media.length && index < allItems?.media_count) {

            if (mediaId) {
              navigate("/private-open-course-topics/" + courseid);
              setPage(1);
              setMediaIdIndex(0)
            } else {
              setPage(page + 1);
              setMediaIdIndex(0)
            }
          }
          if (index === -1 && page > 1) {
            setPage(page - 1);
          }
        }


      } else {
        toast.warning(<>{t("Please_register_or_login_to_see_free_courses")}</>);
        navigate("/registrera");
      }
    } else {
      if (role_id === 2 || role_id === 3 || !role_id) {
        toast.error(<>{t("Du_har_inte_köpt_den_här_kursen")}</>);
        scrollToSection('buysection')
      }
      if (role_id === 4) {
        toast.error(
          "Du har inte blivit tilldelad den här kursen"
        );
      }
      //  notify()
    }
    // call API to set half seen
    if (percentage > 0 && role_id) {
      let data = {
        id: courseid,
        user_id: id,
        media_id: mediaIdForPercent,
        view_time: percentage,
      };
      dispatch(videoProgress(data))
        .unwrap()
        .then(() => { })
        .catch(() => { });
    }
  }
  return (
    <>

      <div
        className={` innerblock-page`}
      >
        <Header headerShow={false} />

        <div className='page-wrapper page-block'>
          <InnerHeader title={mainTitle} subtitle={add3Dots(course_sub_title, isMobile ? 40 : 100)} backButton={true} backButtonURL="/my-course-details" />
          <Wrapper>
            <section className={`mb-md-80px pb-md-0 pb-20px`}>
              <Row className="gy-md-3 flex-md-row gap-md-0 ">
                <Col md={6}>
                  <div className="videoblock-wrap">
                    <iframe
                      id="videoIframe"
                      className="videoblock rounded-20 w-100 video-opencourse"
                      src={mainVideoURL}
                      width={400}
                      title="video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="p-md-10px shadow-block rounded-20 d-flex gap-5 mt-20px bg-white align-items-center">
                    <div className="d-flex text-svart align-items-center fs-20 fw-medium bg-grey-block px-5 py-18px rounded-20 flex-grow-1 title-mobile-box">
                      <Button variant="link" className="d-inline-flex align-items-center" onClick={() => onSetURLFromButton(mediaIdIndex - 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
                          <path d="M6 12.0798L1.40683 7.48663C0.864388 6.94419 0.864388 6.05654 1.40683 5.5141L6 0.920898" stroke="black" stroke-width="1.05671" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </Button>
                      <span className="flex-grow-1 text-center px-5" title={mainMediaTitle}>{add3Dots(mainMediaTitle)}</span>
                      <Button variant="link" className="d-inline-flex align-items-center" onClick={() => onSetURLFromButton(mediaIdIndex + 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
                          <path d="M1 12.0798L5.59317 7.48663C6.13561 6.94419 6.13561 6.05654 5.59317 5.5141L1 0.920898" stroke="black" stroke-width="1.05671" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </Button>
                    </div>

                    {((role_id === 2 || role_id === 3 || role_id === 4) && (allItems.is_purchaed || allItems.is_assigned)) &&
                      <Button className="flex-shrink-0 px-5 hide_in_mobile" variant="primary" onClick={handleShow1}>Reflektion</Button>
                    }
                    <Modal centered show={show1} onHide={handleClose1} className="modal-innerbox">
                      <Modal.Header closeButton>
                        <Modal.Title>Reflektion</Modal.Title>
                        <span>Vad tar du med dig ifrån det här avsnittet?</span>
                      </Modal.Header>
                      <Modal.Body className="p-20px">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" rows={5} className="bg-grey-block border-0 forminput-blockwrap fs-20" name="textContent" placeholder="Romarriket, även kallat det romerska riket (500 f.Kr - 500 e.Kr), var antikens dominerande stormakt." value={textContent} onChange={(e) => setTextContent(e.target.value)} />
                        </Form.Group>
                        {/* <CKEditor
                              editor={ ClassicEditor }
                              config={ editorConfiguration }
                              data={textContent}
                              placeholder="Enter data"
                              onChange={ ( event, editor ) => {
                                 setTextContent(editor.getData());
                              } }
                           /> */}
                        <div className="footer-box d-flex justify-content-end gap-20px align-items-center">
                          <Button variant="primary" className="font-16 btn-blocksec" onClick={saveNote}>
                            Spara
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="shadow-block bg-white rounded-20 box-mobileblock">
                    <div className="p-20px title-header d-md-block d-none">
                      <TextBlock title={mainTitle} subtitle={`${allItems?.media_count} avsnitt - ${allItems?.media_total_time ? allItems?.media_total_time : 0} minuter`} />
                    </div>
                    <div className="p-md-8 p-5">
                      <div className="d-md-none d-flex gap-12px mb-10px">
                        <a role="button" className={`card-link a-hover text-nowrap ${selectedTab === 'Avsnitt' ? "active" : ""}`} onClick={() => setTab('Avsnitt')}>Avsnitt</a>
                        <a role="button" className={`card-link a-hover text-nowrap ${selectedTab === 'Om_kursen' ? "active" : ""}`} onClick={() => setTab('Om_kursen')}>Om kursen</a>
                        <a role="button" className={`card-link a-hover text-nowrap ${selectedTab === 'Om_läraren' ? "active" : ""}`} onClick={() => setTab('Om_läraren')}>Om lärare</a>
                        
                        {((role_id === 2 || role_id === 3 || role_id === 4) && (allItems.is_purchaed || allItems.is_assigned)) &&
                          <a role="button" className={`card-link a-hover text-nowrap ${selectedTab === 'Reflektion' ? "active" : ""}`} onClick={() => setTab('Reflektion')}>Reflektion</a>
                        }
                      </div>
                      <div className={`d-flex flex-column gap-10px mobileinfo-block ${selectedTab === 'Avsnitt' ? "active" : ""}`}>
                        {allItems.media &&
                          allItems.media.map((item, key) => (
                            <MediaList
                              item={item}
                              key={key}
                              index={key}
                              onSetURL={onSetURL}
                            />
                          ))}
                        <div className="kurser-pagination">
                          <PaginationControl
                            page={page}
                            total={allItems?.media_count}
                            limit={pageLimit}
                            changePage={(page) => {
                              // added for specific episod
                              if (mediaId) {
                                navigate("/private-open-course-topics/" + courseid);
                              }

                              setPage(page);
                            }}
                            next={true}
                            ellipsis={1}
                          />
                          {(role_id === 3) && checkSubsDate(user?.subscription?.trial_end) == -1 &&
                            checkSubsDate(user?.subscription?.expire_at) == -1 &&
                            !allItems?.is_purchaed && !allItems.is_assigned && (
                              <div className="avsnitt-btn-mob d-sm-none d-flex justify-content-md-center">
                              <Button className="only_mobile d-none" variant="primary" onClick={showonMobileBuyFunction}>Köp kursen</Button>
                            </div>
                            )}
                       
                        </div>
                      </div>

                      <div className={`d-md-none d-block mobileinfo-block ${selectedTab === 'Om_kursen' ? "active" : ""}`}>
                        <div className="titleheader-box">
                          <div className="title-header pb-16px block">
                            <h5 className="fs-20 fw-medium mb-0">{mainTitle}</h5>
                            <div className="fs-20 fw-light text-black" dangerouslySetInnerHTML={{
                              __html: mainDiscription,
                            }}></div>
                          </div>
                          <div className="title-header py-16px block">
                            <h5 className="fs-20 fw-medium mb-0">Ingår i kursen:</h5>
                            <div className="fs-20 fw-light text-black block" dangerouslySetInnerHTML={{
                              __html: allItems?.included_in_course !== '<p>null</p>' ? allItems?.included_in_course : '',
                            }}>
                            </div>
                          </div>
                          <div className="title-header pt-16px block">
                            <h5 className="fs-20 fw-medium mb-0">Den här kursen passar för dig som:</h5>
                            <div className="fs-20 fw-light text-black block" dangerouslySetInnerHTML={{
                              __html: allItems?.suitable_for !== '<p>null</p>' ? allItems?.suitable_for : ''
                            }}>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`d-md-none d-block mobileinfo-block ${selectedTab === 'Om_läraren' ? "active" : ""}`}>
                        <div className="d-flex gap-20px">
                          <div className="userblock-content">
                            <h5 className="fs-20 fw-medium mb-0">{allItems?.creator_name}</h5>
                            <div className="fs-20 fw-light text-black" dangerouslySetInnerHTML={{
                              __html: allItems?.creator_about,
                            }}></div>
                          </div>

                          <span className="user-img d-md-block d-none  flex-shrink-0 flex-shrink-0 userblock-imag flex-shrink-0">
                            <Image
                              src={
                                allItems?.creator_image
                                  ? allItems?.creator_image
                                  : "assets/images/simual.png"
                              }
                              alt="Image"
                              className="img-fit rounded-20"
                            />
                          </span>

                        </div>
                      </div>
                      <div className={`d-md-none d-block mobileinfo-block  ${selectedTab === 'Reflektion' ? "active" : ""}`}>

                        <Form>
                          <Form.Group className="mb-16px pb-16px mt-3 title-header" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={8} className="bg-grey-block border-0 forminput-blockwrap fs-20" name="textContent" placeholder="Romarriket, även kallat det romerska riket (500 f.Kr - 500 e.Kr), var antikens dominerande stormakt." value={textContent} onChange={(e) => setTextContent(e.target.value)} />
                          </Form.Group>
                          <div className="footer-box text-center justify-content-end gap-20px align-items-center ">
                            <Button variant="primary" className="font-16 btn-blocksec mb-1" onClick={saveNote}>
                              Spara
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    {((role_id === 3 || role_id === 4)) && (page === Math.ceil(allItems?.media_count / pageLimit)) &&
                      <>
                      {((role_id === 2 || role_id === 3 || role_id === 4) && (allItems.is_purchaed || allItems.is_assigned)) &&
                        <div className="text-center p-md-20px p-16px btn-topborder">
                        <Button variant="primary" onClick={() => setShowTestList(true)} disabled={!allItems?.is_purchaed && !allItems.is_assigned} >Slutprov</Button>
                        </div>
                        }
                        {showTestList &&
                          <SelectTest show={showTestList} courseId={courseid} courseName={mainTitle} callbackModal={callbackModal}></SelectTest>
                        }
                     </>
                    }

                  </div>


                </Col>

              </Row>
            </section>

            <section className="d-md-block d-none">
              <div className="shadow-block bg-white rounded-20">
                <div className="p-20px title-header d-flex align-items-center">
                  <div className="leftbox-header">
                    <TextBlock title={mainTitle} subtitle={allItems?.creator_name} />
                  </div>
                  <div className="tabblock-box bg-grey-block rounded-pill">
                    <span className={`btn-tablink ${selectedTab === 'Om_kursen' ? "active" : ""}`} onClick={() => setTab('Om_kursen')}>Om kursen</span>
                    <span className={`btn-tablink ${selectedTab === 'Om_läraren' ? "active" : ""}`} onClick={() => setTab('Om_läraren')}>Om lärare</span>
                  </div>
                </div>
                <div className={`tabblock-wrap-in mobile-pb-60 ${selectedTab === 'Om_kursen' ? "active" : ""}`}>
                  <div className="titleheader-box">
                    <div className="title-header p-20px">
                      <h5 className="fs-20 fw-medium mb-0">{mainTitle}</h5>
                      <div className="fs-20 fw-light text-black" dangerouslySetInnerHTML={{
                        __html: mainDiscription,
                      }}></div>
                    </div>
                    <div className="title-header p-20px">
                      <h5 className="fs-20 fw-medium mb-0">Ingår i kursen:</h5>
                      <div className="fs-20 fw-light text-black block" dangerouslySetInnerHTML={{
                        __html: allItems?.included_in_course !== '<p>null</p>' ? allItems?.included_in_course : '',
                      }}>
                      </div>
                    </div>
                    <div className="title-header p-20px">
                      <h5 className="fs-20 fw-medium mb-0">Vem passar kursen för?</h5>
                      <div className="fs-20 fw-light text-black block" dangerouslySetInnerHTML={{
                        __html: allItems?.suitable_for !== '<p>null</p>' ? allItems?.suitable_for : ''
                      }}>
                      </div>
                    </div>
                  </div>

                </div>
                <div className={`tabblock-wrap-in mobile-pb-60 ${selectedTab === 'Om_läraren' ? "active" : ""}`}>
                  <div className="titleheader-box">
                    <div className="title-header p-20px">
                      <div className="d-flex gap-20px">
                        <div className="userblock-content">
                          <h5 className="fs-20 fw-medium mb-0">{allItems?.creator_name}</h5>
                          <div className="fs-20 fw-light text-black" dangerouslySetInnerHTML={{
                            __html: allItems?.creator_about,
                          }}></div>
                        </div>

                        <span className="user-img d-md-block d-none  flex-shrink-0 flex-shrink-0 userblock-imag flex-shrink-0">
                          <Image
                            src={
                              allItems?.creator_image
                                ? allItems?.creator_image
                                : "assets/images/simual.png"
                            }
                            alt="Image"
                            className="img-fit rounded-20"
                          />
                        </span>

                      </div>
                    </div>
                  </div>
                </div>
                <div id="buysection"></div>
                {(role_id === 3) && (
                  <>
                    {checkSubsDate(user?.subscription?.trial_end) == -1 &&
                      checkSubsDate(user?.subscription?.expire_at) == -1 &&
                      !allItems?.is_purchaed && !allItems.is_assigned && (

                        <div className="title-header-top p-20px">
                          <h5 className="fs-20 fw-medium mb-20px">Låter det intressant?</h5>
                          <div className="d-flex gap-20px flex-wrap">
                            <div className="fw-lighter font-20 d-flex justify-content-between gap-4 priceinfobox " onClick={generateCheckoutURL}>
                              <span className="mobile-font-13">Köp kursen</span>
                              <span className="fw-medium font-24">{mainPrice} kr</span>
                            </div>
                            {/* <div className="fw-lighter font-20 d-flex justify-content-between gap-4 priceinfobox " onClick={() => generateCheckoutURLSubscription("month")}>
                          <span className="mobile-font-13">Se alla kurser</span>
                          <span className="fw-medium font-24">
                            {selectedPlan} kr/mån
                          </span>
                        </div> */}
                            {/* <Button
                              onClick={() => generateCheckoutURLSubscription("month")}
                              variant=""
                              className="d-flex justify-content-between gap-4 px-32 align-items-center btn-gradient mobile-minh53 "
                            >
                              <span className="fw-light font-20 mobile-font-13">
                                Se alla kurser
                              </span>
                              <span className="fw-medium">gratis i 30 dagar</span>
                            </Button> */}
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>

              <Modal centered show={showonMobileBuy} onHide={handleClose} className="modal-innerbox">
                <Modal.Header closeButton>
                  <Modal.Title>Mötesteknik</Modal.Title>
                  <span>Hur vill du betala?</span>
                </Modal.Header>
                <Modal.Body className="p-20px">

                  <div className="d-flex gap-20px flex-wrap">
                    <div className="fw-lighter font-20 d-flex justify-content-between gap-4 priceinfobox text-black w-100 no_hover" onClick={generateCheckoutURL}>
                      <span className="mobile-font-13 text-black">Köp kursen</span>
                      <span className="fw-medium font-24 text-black">{mainPrice} kr</span>
                    </div>
                    {/* <Button
                      onClick={() => generateCheckoutURLSubscription("month")}
                      variant=""
                      className="d-flex justify-content-between gap-4 px-32 align-items-center btn-gradient mobile-minh53 w-100 "
                    >
                      <span className="fw-light font-20 mobile-font-13">
                        Se alla kurser
                      </span>
                      <span className="fw-medium">gratis i 30 dagar</span>
                    </Button> */}
                  </div>

                </Modal.Body>
              </Modal>
            </section>

          </Wrapper>

        </div>

      </div>

      {showEdit && (
        <AddNewProduct
          show={showEdit}
          editId={editId}
          editCourseId={editCourseId}
          editName={editName}
          callbackModal={callbackModal}
        />
      )}
      <Modal show={showCreator} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{allItems?.creator_name} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="usermanagement-modal">
          <p
            className="font-18"
            dangerouslySetInnerHTML={{ __html: allItems?.creator_about }}
          ></p>
        </Modal.Body>
      </Modal>

      {/* <Footer /> */}

    </>
  );
};
export default OpenCourseTopics;
