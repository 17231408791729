import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/user.service";
import { setMessage } from "./message";
import { toast } from "react-toastify";

export const getCources = createAsyncThunk(
  "course/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPublicContent(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addCources = createAsyncThunk(
  "course/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addCourse(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addProduct = createAsyncThunk(
  "course/add/media",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addProduct(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const editCourse = createAsyncThunk(
  "/course/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editCourse(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const editPackage = createAsyncThunk(
  "/package/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editPackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const editProduct = createAsyncThunk(
  "course/edit/media",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editProduct(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editSingleNote = createAsyncThunk(
  "course/edit/note",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editSingleNote(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getMyProfile = createAsyncThunk(
  "course/myprofile",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getUserProfile();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateProfile = createAsyncThunk(
  "course/update/Profile",
  async (body, thunkAPI) => {
    try {
      const response = await userService.updateProfile(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addCreator = createAsyncThunk(
  "course/add/creator",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addCreator(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editCreator = createAsyncThunk(
  "course/edit/creator",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editCreator(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCreator = createAsyncThunk(
  "course/creator",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getCreators();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeCreator = createAsyncThunk(
  "course/creator/delete",
  async (id, thunkAPI) => {
    try {
      const response = await userService.removeCreator(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getList_of_demand = createAsyncThunk(
  "contact-us/getList_of_demand",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getList_of_demand();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeNote = createAsyncThunk(
  "course/note/delete",
  async (id, thunkAPI) => {
    try {
      const response = await userService.removeNote(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleCreator = createAsyncThunk(
  "course/creator/get_single",
  async (id, thunkAPI) => {
    try {
      const response = await userService.getSingleCreator(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeUser = createAsyncThunk(
  "course/employee/delete_retrive",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeUser(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleUser = createAsyncThunk(
  "course/get/singleuser",
  async (id, thunkAPI) => {
    try {
      const response = await userService.getSingleUser(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeCources = createAsyncThunk(
  "course/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeCourse(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removePackages = createAsyncThunk(
  "package/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removePackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = {};
const courseSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: {
    [getCources.fulfilled]: (state, action) => {
      state.allcourse = action.payload.data;
    },
    [getCources.rejected]: (state, action) => {
      state.allcourse = null;
    },
  },
});
export const getProductList = createAsyncThunk(
  "course/media",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getProductList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getSingleProduct = createAsyncThunk(
  "course/single",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSingleProduct(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSinglePackage = createAsyncThunk(
  "package/single",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSinglePackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleExamDetail = createAsyncThunk(
  "course/single/exam",
  async (id, thunkAPI) => {
    try {
      const response = await userService.getSingleExamDetail(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getemployees = createAsyncThunk(
  "course/get/employee",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getEmployees(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const createPaymentIntent = createAsyncThunk(
  "course/get/paymentIntent",
  async (body, thunkAPI) => {
    try {
      const response = await userService.createPaymentIntent(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addSingleEmployee = createAsyncThunk(
  "course/addSingleEmp",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addSingleEmployee(body);
      thunkAPI.dispatch(setMessage(response.data.message));
      // return response.data;
      return { user: response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editSingleEmployee = createAsyncThunk(
  "course/editSingleEmp",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editSingleEmployee(body);
      thunkAPI.dispatch(setMessage(response.data.message));
      // return response.data;
      return { user: response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const assign_course_and_deadline = createAsyncThunk(
  "course/editSingleEmp",
  async (body, thunkAPI) => {
    try {
      const response = await userService.assign_course_and_deadline(body);
      thunkAPI.dispatch(setMessage(response.data.message));
      // return response.data;
      return { user: response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const assignCourcesNew = createAsyncThunk(
  "course/assignnew",
  async (body, thunkAPI) => {
    try {
      const response = await userService.assignCourcesNew(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const assignCources = createAsyncThunk(
  "course/assign",
  async (body, thunkAPI) => {
    try {
      const response = await userService.assignCources(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getExamList = createAsyncThunk(
  "course/exam",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getExamList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getExamById = createAsyncThunk(
  "course/exambyID",
  async (body, thunkAPI) => {
    try {
      const response = await userService.setSingleExam(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addQuestions = createAsyncThunk(
  "course/questions/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addQuestions(body);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addTest = createAsyncThunk(
  "course/exam/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addTest(body);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editTest = createAsyncThunk(
  "course/exam/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editTest(body);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeMedia = createAsyncThunk(
  "course/media/delete",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeMedia(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeExam = createAsyncThunk(
  "course/exam/delete",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeExam(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleMediaDetail = createAsyncThunk(
  "course/media/id",
  async (id, thunkAPI) => {
    try {
      const response = await userService.getSingleMediaDetail(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeQuestion = createAsyncThunk(
  "course/questions/delete",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeQuestion(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const videoProgress = createAsyncThunk(
  "video/watch",
  async (body, thunkAPI) => {
    try {
      const response = await userService.videoProgress(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const createNote = createAsyncThunk(
  "note/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.createNote(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getNote = createAsyncThunk("note", async (body, thunkAPI) => {
  try {
    const response = await userService.getNote(body);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});
export const completeTrasaction = createAsyncThunk(
  "/payment/transaction/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.completeTrasaction(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const trasactionHistory = createAsyncThunk(
  "/payment/transaction/history",
  async (body, thunkAPI) => {
    try {
      const response = await userService.trasactionHistory(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const verifyExam = createAsyncThunk(
  "exam/verify",
  async (body, thunkAPI) => {
    try {
      const response = await userService.verifyExam(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getDashboardCount = createAsyncThunk(
  "dashbaord/count",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getDashboardCount(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addfcmToken = createAsyncThunk(
  "notification/fcmtoken/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addfcmToken(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const sendNotificationFromAdmin = createAsyncThunk(
  "notification/send",
  async (body, thunkAPI) => {
    try {
      const response = await userService.sendNotification(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getnotification = createAsyncThunk(
  "notification/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getnotification();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const rednotification = createAsyncThunk(
  "notification/read",
  async (body, thunkAPI) => {
    try {
      const response = await userService.readnotification();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCertificate = createAsyncThunk(
  "employee/certificate",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getCertificate(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getankiExam = createAsyncThunk(
  "anki/exam",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getankiExam(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addAnkiQuestions = createAsyncThunk(
  "anki/questions/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addAnkiQuestions(body);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeAnkiQuestion = createAsyncThunk(
  "/anki/questions/delete",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeAnkiQuestion(body);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getQuestion = createAsyncThunk(
  "/questions/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.setSingleQuestion(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editSingleQuestion = createAsyncThunk(
  "questions/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editSingleQuestion(body);
      thunkAPI.dispatch(setMessage(response.data.message));
      // return response.data;
      return { user: response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const randomAnkiQuestion = createAsyncThunk(
  "anki/question/random/:id",
  async (id, thunkAPI) => {
    try {
      const response = await userService.randomAnkiQuestion(id);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getDashboardData = createAsyncThunk(
  "/dashbaord/topuser",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getDashboardData(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const coursestatistics = createAsyncThunk(
  "/dashbaord/statatic",
  async (body, thunkAPI) => {
    try {
      const response = await userService.coursestatistics();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const adminCourseStatistics = createAsyncThunk(
  "/dashbaord/adminCourseStatistics",
  async (body, thunkAPI) => {
    try {
      const response = await userService.adminCourseStatistics(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const privateCourseStatistics = createAsyncThunk(
  "/dashbaord/privateCourseStatistics",
  async (body, thunkAPI) => {
    try {
      const response = await userService.privateCourseStatistics(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const contactUs = createAsyncThunk(
  "contactus/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.contactUs(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const add_new_course_message = createAsyncThunk(
  "contactus/add_new_course_message",
  async (body, thunkAPI) => {
    try {
      const response = await userService.add_new_course_message(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const myPurchageList = createAsyncThunk(
  "/course/purches",
  async (body, thunkAPI) => {
    try {
      const response = await userService.myPurchageList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addBulk = createAsyncThunk(
  "/employee/add/multiple",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addBulk(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const publishCourse = createAsyncThunk(
  "/course/publish",
  async (body, thunkAPI) => {
    try {
      const response = await userService.publishCourse(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const inviteAddUser = createAsyncThunk(
  "course/invitation/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.inviteUserData(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const inviteUser = createAsyncThunk(
  "course/invitation/invite",
  async (body, thunkAPI) => {
    try {
      const response = await userService.inviteUser(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const customSort = createAsyncThunk(
  "course/sort/custom",
  async (body, thunkAPI) => {
    try {
      const response = await userService.customSort(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getPlans = createAsyncThunk(
  "subscription/plan",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPlans(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const buyPlans = createAsyncThunk(
  "subscription/purchase",
  async (body, thunkAPI) => {
    try {
      const response = await userService.buyPlans(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const completePlans = createAsyncThunk(
  "/subscription/complete",
  async (body, thunkAPI) => {
    try {
      const response = await userService.completePlans(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const completeTrial = createAsyncThunk(
  "/subscription/completeTrial",
  async (body, thunkAPI) => {
    try {
      const response = await userService.completeTrial(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const setPremium = createAsyncThunk(
  "business/setpremium",
  async (body, thunkAPI) => {
    try {
      const response = await userService.setPremium(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const setQuantityPremium = createAsyncThunk(
  "/business/setQuantityPremium",
  async (body, thunkAPI) => {
    try {
      const response = await userService.setQuantityPremium(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const tagList = createAsyncThunk(
  "/course/tagList",
  async (body, thunkAPI) => {
    try {
      const response = await userService.tagList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const tagWiseCourseList = createAsyncThunk(
  "course/tagWiseCourseList",
  async (body, thunkAPI) => {
    try {
      const response = await userService.tagWiseCourseList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getActiveSubscription = createAsyncThunk(
  "/subscription/active",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getActiveSubscription(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const cancleActiveSubscription = createAsyncThunk(
  "/subscription/cancel",
  async (body, thunkAPI) => {
    try {
      const response = await userService.cancleActiveSubscription(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateActiveSubscription = createAsyncThunk(
  "/subscription/updatepurchase",
  async (body, thunkAPI) => {
    try {
      const response = await userService.updateActiveSubscription(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addTag = createAsyncThunk(
  "/course/addTag",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addTag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editTag = createAsyncThunk(
  "course/editTag",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editTag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deleteTag = createAsyncThunk(
  "/tag/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.deleteTag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const myCourse = createAsyncThunk(
  "/course/myCourse",
  async (body, thunkAPI) => {
    try {
      const response = await userService.myCourse(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const myCount = createAsyncThunk(
  "/user/get_license_count",
  async (body, thunkAPI) => {
    try {
      const response = await userService.myCount(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const watchhistory = createAsyncThunk(
  "user/watch-history",
  async (body, thunkAPI) => {
    try {
      const response = await userService.watchhistory(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const user_count_for_admin = createAsyncThunk(
  "user/user_count_for_admin",
  async (body, thunkAPI) => {
    try {
      const response = await userService.user_count_for_admin(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addNewsLatter = createAsyncThunk(
  "news/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addNewsLatter(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const newslatterList = createAsyncThunk(
  "news/list",
  async (body, thunkAPI) => {
    try {
      const response = await userService.newslatterList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editNewsLatter = createAsyncThunk(
  "news/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editNewsLatter(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getSingleNews = createAsyncThunk(
  "news/single",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSingleNews(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const newslatter_downlaod = createAsyncThunk(
  "newslatter/downlaod",
  async (body, thunkAPI) => {
    try {
      const response = await userService.newslatter_downlaod(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeNews = createAsyncThunk(
  "newslatter/downlaod",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeNews(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const subribeChannel = createAsyncThunk(
  "newslatter/subribeChannel",
  async (body, thunkAPI) => {
    try {
      const response = await userService.subribeChannel(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const customNewsSort = createAsyncThunk(
  "newslatter/sort/custom",
  async (body, thunkAPI) => {
    try {
      const response = await userService.customNewsSort(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getSubcriptionList = createAsyncThunk(
  "/newslatter/subcription_list",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSubcriptionList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCoupanList = createAsyncThunk(
  "/subscription/coupan_list",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getCoupanList();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const couponUserList = createAsyncThunk(
  "course/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.couponUserList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const groupByCoupon = createAsyncThunk(
  "course/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.groupByCoupon(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const appybackendCoupon = createAsyncThunk(
  "course/appyCoupon",
  async (body, thunkAPI) => {
    try {
      const response = await userService.appyCoupon(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addBlog = createAsyncThunk("blog/add", async (body, thunkAPI) => {
  try {
    const response = await userService.addBlog(body);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const blogList = createAsyncThunk(
  "blog/list",
  async (body, thunkAPI) => {
    try {
      const response = await userService.blogList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeBlog = createAsyncThunk(
  "blog/delete",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeBlog(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editBlog = createAsyncThunk(
  "blog/edit",
  async (body, thunkAPI) => {
    try {
      const response = await userService.editBlog(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const singleBlog = createAsyncThunk(
  "blog/single",
  async (body, thunkAPI) => {
    try {
      const response = await userService.singleBlog(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const customBlogSort = createAsyncThunk(
  "blog/sort/custom",
  async (body, thunkAPI) => {
    try {
      const response = await userService.customBlogSort(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addMp3 = createAsyncThunk(
  "/convert/add/mp3",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addMp3(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const searchInchatgtp = createAsyncThunk(
  "/search/ingtp",
  async (body, thunkAPI) => {
    try {
      const response = await userService.searchInchatgtp(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const searchInblog = createAsyncThunk(
  "/search/blog",
  async (body, thunkAPI) => {
    try {
      const response = await userService.searchInblog(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addPackage = createAsyncThunk(
  "package/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.addpackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPackage = createAsyncThunk(
  "package/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getPackageListData = createAsyncThunk(
  "package",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getPackageListData(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCheckoutUrl = createAsyncThunk(
  "getCheckoutUrl",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getCheckoutUrl(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const verifyPayment = createAsyncThunk(
  "verifyPayment",
  async (body, thunkAPI) => {
    try {
      const response = await userService.verifyPayment(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const invoicelist = createAsyncThunk(
  "Invoice",
  async (body, thunkAPI) => {
    try {
      const response = await userService.invoicelist(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const invoiceByintentId = createAsyncThunk(
  "invoiceByintentId",
  async (body, thunkAPI) => {
    try {
      const response = await userService.invoiceByintentId(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const passwordRegister = createAsyncThunk(
  "passwordRegister",
  async (body, thunkAPI) => {
    try {
      const response = await userService.passwordRegister(body);
      if (response.data.data.token) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const verifyPassset = createAsyncThunk(
  "verifyPassset",
  async (body, thunkAPI) => {
    try {
      const response = await userService.verifyPassset(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
     // toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const nameChange = createAsyncThunk(
  "nameChange",
  async (body, thunkAPI) => {
    try {
      const response = await userService.nameChange(body);
      if (response.data.data.token) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const processAsBusiness = createAsyncThunk(
  "processAsBusiness",
  async (body, thunkAPI) => {
    try {
      const response = await userService.processAsBusiness(body);
      if (response.data.data.token) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const publishPackage = createAsyncThunk(
  "/package/publish",
  async (body, thunkAPI) => {
    try {
      const response = await userService.publishPackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const cancelActiveSubscriptionNew = createAsyncThunk(
  "/subscription_cancel",
  async (body, thunkAPI) => {
    try {
      const response = await userService.cancelActiveSubscriptionNew(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSubsPlan = createAsyncThunk(
  "getSubsPlan",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSubsPlans(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const customSortPackage = createAsyncThunk(
  "package/sort/custom",
  async (body, thunkAPI) => {
    try {
      const response = await userService.customSortPackage(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const { reducer } = courseSlice;
export default reducer;
