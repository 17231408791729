import React, { useRef, useEffect, useState } from "react";
import { Container, Form, Button, Image, Col, Modal } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { getMyProfile, updateProfile, myPurchageList, getActiveSubscription, cancelActiveSubscriptionNew,trasactionHistory,invoicelist,invoiceByintentId } from "../../store/api/course.api";
import Header from "../commom/Header2";
import { useTranslation } from "react-i18next";
import InnerHeader from "../public/InnerHeader";
import TextBlock from "../component/TextBlock";
import Wrapper from "../component/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { changePass } from "../../store/api/auth.api";
import { useNavigate,Link } from 'react-router-dom';

import moment from "moment";
import {
  checkSubsDate,
} from "./commonConst";
import { toast } from "react-toastify";
const MyProfile = (prop) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    firstname: "",
    lastname: "",
    code: "",
    phone: "",
    email: "",
    oldPassword:"********",
    password:"********"
  });
  const [iseditable, setIseditable] = useState(true);
  const [showResults, setShowResults] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFile = useRef(null);
  const [seletedRoleId, setSelectedRoleId] = useState(0);
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState("");
  const { role_id, id, business_id } =useSelector((state) => state?.auth?.user || {});
  const user = useSelector((state) => state?.auth?.user);
  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const [allCources, setAllCources] = useState();
  const [limit,setLimit]= useState(4);
  const [currentSubscrption, setCurrentSubscrption] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [allInvoice,setAllInvoice] = useState();
  const navigate = useNavigate();
  let body;
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
    //  "page":"1",
    //  "limit":"10"
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const reresh = () => {
    
    dispatch(invoicelist({})).then((res) => {
      setAllInvoice(res.payload?.data?.invoices?.data)
    });
  

    dispatch(getMyProfile()).then((res) => {
      setValue({
        ...value,
        ["firstname"]: res.payload.data[0].first_name,
        ["lastname"]: res.payload.data[0].last_name,
        ["email"]: res.payload.data[0].email,
        ["phone"]: res.payload.data[0].phone_no
          ? res.payload.data[0].phone_no
          : "",
        ["code"]: res.payload.data[0].tel_code,
      });

      if (res.payload.data[0].image) {
        setSelectedImage(res.payload.data[0].image);
      }
      if (res.payload.data[0].role_id === 2) {
        setShowResults(true);
        setSelectedRoleId(res.payload.data[0].role_id);
      }
    });
  };

  useEffect(() => {
    reresh();
    refereshSub();
   
  }, []);
  


  const refereshSub = () => {
    if (role_id === 2) {
      body = {
        business_id: business_id,
      };
      dispatch(myPurchageList(body)).then((res) => {
        setAllCources(res.payload.data.purches_courses);
      });
    }
    if (role_id === 3) {
      body = {
        business_id: id,
      };
      dispatch(trasactionHistory()).then((res) => {
        setAllCources(res.payload?.data?.course);
      
      });
    }
    if (role_id) {
      let data = {
        business_id: business_id,
      };
      if (role_id === 2) {
        data.business_id = business_id;
      }
      if (role_id === 3) {
        data.user_id = id;
      }
      dispatch(getActiveSubscription(data))
        .unwrap()
        .then((res) => {
          setCurrentSubscrption(res.data);
        })
        .catch(() => { });
    }
  };
  const onEdit = () => {
    var data = new FormData();
    data.append("first_name", value.firstname);
    data.append("last_name", value.lastname);
    data.append("phone_no", value.phone);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    dispatch(updateProfile(data))
      .unwrap()
      .then((res) => {
        reresh();
        setIseditable(true);
      //  toast.success(res.message);
      })
      .catch(() => { });
  };
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const onChangePass = () => {
    let data = {
      oldPassword: value.oldPassword,
      password:value.password
    }
    dispatch(changePass(data))
      .unwrap()
      .then(() => {
        navigate("/logga-in");
      })
      .catch(() => { });
  };
  const [showBlock, setShowBlock] = useState(false);

  const handleCloseBlock = () => setShowBlock(false);
  const handleShowBlock = () => setShowBlock(true);
  const cancleSubription = () => {
    let data = {
      stripe_subscription_id: stripe_subscription_id,
    };
    dispatch(cancelActiveSubscriptionNew(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          refereshSub();
          setShowBlock(false)
        }
      })
      .catch(() => { });
  };
  const andre = () => {
   
    setIseditable(false);
    togglePassword();
  }
  const andrePass = () => {
    setValue({
      oldPassword:"",
      password:""
    })
    togglePassword()
  }
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const downloadInvoice =()=>{
    window.open(allInvoice[0].invoice_pdf)
  }
const downloadPerticular = (id) => {
  let data ={
    payment_intent:id
  }
  dispatch(invoiceByintentId(data))
      .unwrap()
      .then((res) => {
      if(res.data.url) {
        window.open(res.data.url)
      } else {
        toast.warning('Invoice not available')
      }
      })
      .catch(() => { });
}
  
const add3Dots = (string, limit) => {
  if (string) {
    var dots = "...";
    if (string?.length > limit) {
      string = string.substring(0, limit) + dots;
    }
  }
  return string != "null" ? string : "";
};
  return (
    <div className="innerblock-page flex-grow-1">
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block overflow-hidden">
        <InnerHeader title="Min profil" subtitle={value.firstname} />
        <Wrapper className="pb-20px">
          <div className="row g-lg-20px g-16px">
            <Col xl={6}>
              <Form id="create-course-form" className="bg-white shadow-block rounded-20">
                <div className="p-lg-20px p-16px title-header">
                  <TextBlock title={"Konto inställningar"} subtitle={"Uppdatera dina konto inställningar"} />
                </div>
                <div className="d-flex flex-column gap-lg-20px gap-16px p-lg-20px p-16px">
                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium">E-post(går inte att redigera)</Form.Label>
                    <Form.Control
                      type="email"
                      className="fs-20 mobile-font-14 form-inputblock"
                      placeholder={t("Email")}
                      readOnly={true}
                      name="email"
                      aria-label="Email"
                      value={value.email}
                    />

                  </Form.Group>
                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicfirstname"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium">Namn</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={iseditable}
                      className={`fs-20 mobile-font-14 form-inputblock ${iseditable ? "" : "footer_black-black"}`}
                      placeholder={t("First_Name")}
                      name="firstname"
                      aria-label="First Name"
                      onChange={onChange}
                      value={value.firstname}
                    />
                  </Form.Group>
                  {/* <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={iseditable}
                      className="fs-20 mobile-font-14 form-inputblock"
                      placeholder={t("Last_Name")}
                      name="lastname"
                      aria-label="lastname"
                      onChange={onChange}
                      value={value.lastname}
                    />
                  </Form.Group> */}
                </div>
                <div className="d-flex justify-content-between align-items-center p-20px pt-0">
                  <Button className="text-blue text-decoration-underline fs-16 mobile-font-16 mobile-font-medium" variant="link" onClick={andre}>Ändra</Button>
                  <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark" onClick={onEdit}>Spara</Button>
                </div>
               
              </Form>
            </Col>
            <Col xl={6}>
              <Form id="create-course-form" className="bg-white shadow-block rounded-20">
                <div className="p-lg-20px p-16px title-header">
                  <TextBlock title={"Lösenord"} subtitle={"Vill du ändra ditt lösenord?"} />
                </div>
                <div className="d-flex flex-column gap-lg-20px gap-16px p-lg-20px p-16px">
                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium form-label">Lösenord</Form.Label>
                    <Form.Control
                      type={passwordType}
                      name="oldPassword"
                      placeholder="Lösenord"
                      autoComplete="off"
                      aria-label="Password"
                      value={value.oldPassword}
                      onChange={onChange}
                      className="fs-20 mobile-font-14 form-inputblock"
                    />
                  </Form.Group>

                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium form-label">Upprepa lösenord</Form.Label>
                    <Form.Control
                     type={passwordType}
                      name="password"
                      placeholder="Upprepa lösenord"
                      autoComplete="off"
                      aria-label="Password"
                      value={value.password}
                      onChange={onChange}
                      className="fs-20 mobile-font-14 form-inputblock"
                    />
                    
                  </Form.Group>

                  

                </div>
                <div className="d-flex justify-content-between align-items-center p-20px pt-0">
                  <Button className="text-blue text-decoration-underline fs-16 mobile-font-16 mobile-font-medium" variant="link" onClick={andrePass}>Ändra</Button>
                  <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark" onClick={onChangePass}>Spara</Button>
                </div>
               
              </Form>
            </Col>
            {currentSubscrption?.name != undefined && (
              <Col xl={12}>
                <div className=" bg-white rounded-20 shadow-block">
                  <div className="p-20px title-header">
                    <TextBlock title="Medlemskap" subtitle="Uppdatera ditt medlemskap" />
                  </div>
                  <div className="p-20px ">


                    <div className="bg-gradientbox rounded-17 p-20px mb-18px">
                      <div className="mb-20px">
                        <div className="d-flex justify-content-between text-white">
                          <h4 className="text-white fw-medium mobile-font-16 mb-0 fs-24 text-capitalize profile-leftbox">Medlemskap - {currentSubscrption?.is_year_plan
                            ? "Årsprenumeration"
                            : "Månadsprenumeration"}</h4>
                          <span className="width-90 font-16 fw-light mobile-font-13 flex-shrink-0">
                            {currentSubscrption?.price / 100}{" "}
                            {currentSubscrption?.currency} /månaden
                          </span>
                        </div>

                        <span className="opacity-60 text-white fw-medium fs-20 mobile-font-14">

                          {currentSubscrption?.trial_end &&
                            currentSubscrption?.is_cancel !== 1 && (
                              <>
                                <div className="cart-subleft ">
                                  <span className="mb-0">Din provperiod slutar</span>
                                </div>
                                <div className="cart-subright">
                                  <span className="fw-medium mb-0">
                                    {moment(currentSubscrption?.trial_end).format(
                                      "DD/MM-YYYY"
                                    )}
                                  </span>
                                </div>
                              </>
                            )}
                          {currentSubscrption?.is_cancel === 1 && (
                            <>
                              <div className="cart-subleft ">
                                <span className="mb-0">Din prenumeration slutar</span>
                              </div>
                              <div className="cart-subright">
                                <span className="fw-medium mb-0">
                                  {moment(currentSubscrption?.expire_at).format(
                                    "DD/MM-YYYY"
                                  )}
                                </span>
                              </div>
                            </>
                          )}

                        </span>
                      </div>
                      <div className="bg-grey-block d-flex align-items-center justify-content-between rounded-15 px-20px h-38px">
                        <span className="text-svart opacity-60 fs-20 fw-medium">Ladda ner kvitto</span>
                        <Button variant="link" className="flex-shrink-0 p-2 d-flex align-items-center me-n2" onClick={downloadInvoice}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g opacity="0.6">
                              <path d="M5.5187 11.749C5.44749 11.749 5.3834 11.7347 5.31219 11.7063C5.1128 11.6279 4.98462 11.4285 4.98462 11.2149V6.94228C4.98462 6.65032 5.22673 6.4082 5.5187 6.4082C5.81066 6.4082 6.05277 6.65032 6.05277 6.94228V9.92599L6.56549 9.41328C6.772 9.20677 7.11381 9.20677 7.32032 9.41328C7.52683 9.61979 7.52683 9.9616 7.32032 10.1681L5.89611 11.5923C5.79642 11.692 5.654 11.749 5.5187 11.749Z" fill="#191919" />
                              <path d="M5.51851 11.7484C5.38322 11.7484 5.24792 11.6985 5.1411 11.5917L3.71689 10.1675C3.51038 9.96101 3.51038 9.6192 3.71689 9.41269C3.9234 9.20619 4.26521 9.20619 4.47172 9.41269L5.89593 10.8369C6.10244 11.0434 6.10244 11.3852 5.89593 11.5917C5.78911 11.6985 5.65381 11.7484 5.51851 11.7484Z" fill="#191919" />
                              <path d="M9.79142 15.3102H5.5188C1.65208 15.3102 0 13.6581 0 9.79142V5.5188C0 1.65208 1.65208 0 5.5188 0H9.07932C9.37128 0 9.61339 0.242115 9.61339 0.534077C9.61339 0.82604 9.37128 1.06815 9.07932 1.06815H5.5188C2.236 1.06815 1.06815 2.236 1.06815 5.5188V9.79142C1.06815 13.0742 2.236 14.2421 5.5188 14.2421H9.79142C13.0742 14.2421 14.2421 13.0742 14.2421 9.79142V6.2309C14.2421 5.93894 14.4842 5.69683 14.7761 5.69683C15.0681 5.69683 15.3102 5.93894 15.3102 6.2309V9.79142C15.3102 13.6581 13.6581 15.3102 9.79142 15.3102Z" fill="#191919" />
                              <path d="M14.7763 6.76589H11.9279C9.49251 6.76589 8.54541 5.81878 8.54541 3.38339V0.534979C8.54541 0.321348 8.67359 0.121959 8.87298 0.0436282C9.07237 -0.0418242 9.30024 0.00802298 9.4569 0.157565L15.1537 5.85439C15.3033 6.00393 15.3531 6.23893 15.2677 6.43832C15.1822 6.63771 14.9899 6.76589 14.7763 6.76589ZM9.61356 1.82389V3.38339C9.61356 5.22062 10.0907 5.69773 11.9279 5.69773H13.4874L9.61356 1.82389Z" fill="#191919" />
                            </g>
                          </svg>
                        </Button>
                      </div>
                    </div>
                    {currentSubscrption?.is_cancel != 1 && (
                      <Button variant="link" className="fw-medium font-20 mobile-font-16 text-decoration-underline text-blue" onClick={handleShowBlock}>Avbryt</Button>
                    )}


                    <Modal centered show={showBlock} onHide={handleCloseBlock} className="modal-innerbox">
                      <Modal.Header closeButton>
                        <Modal.Title>Vill du avlsuta ditt medlemsskap?</Modal.Title>
                        <span>Vi vill inte se dig gå...</span>
                      </Modal.Header>
                      <Modal.Body className="p-20px">
                        <div className="footer-box d-flex justify-content-center gap-20px align-items-center">
                          <Button variant="outline-dark" className="fs-16 btn-blocksec" onClick={() => cancleSubription()}>
                            Ja
                          </Button>
                          <Button variant="primary" className="font-16 btn-blocksec" onClick={handleCloseBlock}>
                            Nej
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </Col>
            )}
             { (role_id === 2 || role_id === 3) && (
            <Col md={12}>
              <div className="shadow-block rounded-20 bg-white">
                <div className="title-header p-20px">
                  <TextBlock title="Mina köp" subtitle="Allt som du har köpt" />
                </div>
                <div className="p-20px d-flex flex-column gap-20px flex-col">
                  {allCources?.length > 0 &&
                    allCources?.slice(0, limit)?.map((course, key) => (
                      <div className="d-flex gap-20px align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-20px">
                          <div className="small-image flex-shrink-0">
                            <Image src={course.course_image} width={58} height={61} alt="Mina kurser" className="img-fit" />
                          </div>
                          <div>
                            <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24 hide_in_mobile">{course.course_title}</h4>
                            <span className="opacity-70 fw-medium fs-20 mobile-font-14 hide_in_mobile">{course.auther_details?.name}</span>
                            <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24 only_mobile d-none">{add3Dots(course.course_title,30)}</h4>
                            <span className="opacity-70 fw-medium fs-20 mobile-font-14 only_mobile d-none">{add3Dots(course.auther_details?.name,30)}</span>
                          </div>
                        </div>
                        {currentSubscrption?.name == undefined && (
                        <div className="flex-shrink-0">
                          <Button variant="outline-dark" title="Se kurs" className="py-2 btnfont-16 hide_in_mobile" onClick={() => downloadPerticular(course.transion_id)} >Ladda ner kvitto</Button>
                          <Button variant="outline-dark" title="Se kurs" className="py-2 btnfont-16 only_mobile d-none" onClick={() => downloadPerticular(course.transion_id)} >{add3Dots('Ladda ner kvitto',10)}</Button>
                        </div>
                        )}
                      </div>
                    ))}
                    {limit === 4 &&
                       <div className="text-center">
                    <Button type="button" className="fw-light opacity-60 text-svart link-hover fs-20" onClick={()=>setLimit(100)}>Visa mer</Button>
                  </div>
                  } 
                  {limit !== 4 && 
                  <div className="text-center">
                  <Button type="button" className="fw-light opacity-60 text-svart link-hover fs-20" onClick={()=>setLimit(4)}>Visa mindre</Button>
                </div>
                  }
                </div>
              </div>
            </Col>
             )}
          </div>

        </Wrapper>
      </div>
    </div>
  );
};

export default MyProfile;
