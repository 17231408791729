import React, {useState, useEffect } from 'react';
import { Container, Button, Col, Image, Row, Tab, Nav, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../commom/Header';
import {reset} from '../../store/api/auth.api';
import { clearMessage } from "../../store/api/message";
import {omit} from 'lodash';
import {Buffer} from 'buffer';
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import jwt from 'jwt-decode' // import dependency
const Reset = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { token } = useParams();
   let fromToken = JSON.parse(Buffer.from(token,'base64').toString()) 
   const { t } = useTranslation();

  // const user = jwt(token); // decode your token here
    const [value, setValue] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);
    const { message } = useSelector((state) => state.message);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [errors, setErrors] = useState({});
    useEffect(() => {
      dispatch(clearMessage());
    }, [dispatch]);

      const onAdd = () => {

         let data ={
            id: fromToken.id,
            password:value.password
         }         
         setLoading(true);
         dispatch(reset(data))
         .unwrap()
         .then(() => {
           navigate('/logga-in');
         })
         .catch(() => {
            setLoading(false);
         });
      };
      const validate = (event, name, value) => {
         //A function to validate each input values
         switch (name) {
            
             
            case 'password':
               if(
                   !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
               ){
                   setErrors({
                       ...errors,
                       password:<>{t(`Password_should`)}</>
                   })
               }else{
                   let newObj = omit(errors, "password");
                   setErrors(newObj);
               }
           break;
           case "cpassword":
             
                if (!value) {
                   
                   setErrors({
                      ...errors,
                      cpassword:<>{t('Please_enter_Confirm_Password')}</>
                  })
                } else if (value.password && value !== value.password) {
                   setErrors({
                      ...errors,
                      cpassword:<>{t('Password_and_Confirm_Password_does_not_match')}</>
                  })
                } else {
                   let newObj = omit(errors, "cpassword");
                   setErrors(newObj);
                }
                break;
             
             default:
                 break;
         }
     }
  const onChange = (e) => {
   validate(e,e.target.name,e.target.value);
  
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
   if (isLoggedIn) {
      navigate('/');
   }
  return (
     <>
       <Header />

      <div className='page-wrapper flex-grow-1 d-flex align-items-center'>
         <Container fluid>
            <Form className="max-387 mx-auto text-center border border-secondary loginpage px-11 pb-11 pt-14 rounded-3 wow fadeInDown" >
               <div className="text-center logintitle border-bottom border-secondary d-inline-block">
                  <h1 className='h2 mb-0 text-white fw-semibold'>{t('Reset')}</h1>
               </div>
              

               <Form.Group className="mb-10" controlId="formBasicPassword">
                  <Form.Control type="password" name="password" placeholder={t('Password')} autoComplete="off" aria-label="Password" value={value.password}
                  onChange={onChange} />
                  { errors.password && <span>{errors.password}</span> }
               </Form.Group>
                     <Form.Group className="mb-10" controlId="formBasicEmail">
                        <Form.Control type="password" placeholder={t('Confirm_New_Password')} name='cpassword' aria-label='Confirm New Password' onChange={onChange} />
                        { errors.cpassword && <span>{errors.cpassword}</span> }
                     </Form.Group>
               <Button variant="primary" type="button" className='mt-2 min-w-195  w-sm-100' onClick={onAdd}> {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>{t('Reset')}</span></Button>
               
               
                
                <p style={{'paddingTop':'10px'}}>{t('Dont_have_an_account')} ? <a className='btn btn-link font-100' href="/registrera">{t('Sign_up')}</a></p>
               </Form>
               {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
     
         </Container>
        
      </div>
     </>
  )
}

export default Reset;