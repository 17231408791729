import React, { useEffect, useState } from "react";
import Header from "../commom/Header2";
import { Image, Table } from "react-bootstrap";
import {
  myPurchageList,
  trasactionHistory,
  getActiveSubscription,
} from "../../store/api/course.api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import InnerHeader from "./InnerHeader";
require("moment/locale/sw.js");
const MyOrder = () => {
  const [allCourses, setAllCourses] = useState();
  const [allPackages, setAllPackages] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { business_id, id, role_id } = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [currentSubscrption, setCurrentSubscrption] = useState();
  let body;
  const refresh = () => {
    if (role_id === 2) {
      body = {
        business_id: business_id,
      };
      dispatch(myPurchageList(body)).then((res) => {
        setAllCourses(res.payload.data.purches_courses);
      });
    }
    if (role_id === 3) {
      body = {
        business_id: id,
      };
      dispatch(trasactionHistory()).then((res) => {
        setAllCourses(res.payload.data.course);
        setAllPackages(res.payload.data.package);
      });
    }
    if (role_id) {
      let data = {
        business_id: business_id,
      };
      if (role_id === 2) {
        data.business_id = business_id;
      }
      if (role_id === 3) {
        data.user_id = id;
      }
      dispatch(getActiveSubscription(data))
        .unwrap()
        .then((res) => {
          setCurrentSubscrption(res.data);
        })
        .catch(() => { });
    }
  };
  useEffect(() => {
    refresh();
  }, []);
  const openCourse = (id) => {
    navigate("/private-open-course-topics/" + id);
  };
  return (
    <div className="innerblock-page">
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block">
        <InnerHeader title={t('My_Order')} subtitle="" />
        {/* <div className="block text-white mb-13 text-white max-650">
          <h1 className="mb-2 h2 fw-semibold mb-1 wow fadeInDown">
            {t("My_Order")}
          </h1>
        </div> */}
        {/* Starts Table */}

        <div className="py-lg-40px px-xl-100px px-lg-30px px-20px flex-grow-1 table-borderwrap">
          <Table bordered responsive className="text-center tableblock-wrap mb-0">
            <thead>
              <tr>
                <th>{t("Course_Name")}</th>
                <th>{t("Purches_Details")} </th>
                {role_id === 2 && (
                  <>
                    <th>{t("quantity")}</th>
                    <th>{t("assigned_course")}</th>
                    <th>{t("unassigned_course")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {allCourses?.map((item, key) => (
                <tr
                  key={item.id}
                  className="border-color-on-hover"
                  onClick={() => openCourse(item.course_id)}
                >
                  {item.course_id !== 0 && (
                    <>
                      {role_id !== 4 && <td>{item?.course_title}</td>}
                      {role_id === 4 && <td>{item?.title}</td>}
                    </>
                  )}
                  {item.course_id === 0 && <td>{currentSubscrption?.name}</td>}
                  {item.course_id !== 0 && (
                    <td>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>
                  )}
                  {item.course_id === 0 && (
                    <td>
                      {moment(currentSubscrption?.created_at).format(
                        "DD-MMM-YYYY"
                      )}
                    </td>
                  )}

                  {item.course_id !== 0 && (
                    <>
                      {role_id === 2 && (
                        <>
                          <td>{item?.quantity}</td>
                          <td>{item?.course_assign_user_count}</td>
                          <td>{item?.unassign_course_count}</td>
                        </>
                      )}
                    </>
                  )}

                  {item.course_id === 0 && (
                    <>
                      {role_id === 2 && (
                        <>
                          <td>{currentSubscrption?.quantity}</td>
                          <td>{currentSubscrption?.quantity}</td>
                          <td>{currentSubscrption?.assign_count}</td>
                        </>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {allCourses && allCourses.length === 0 && (
            <p className="text-center">{t("No_records_found")}</p>
          )}
        </div>
        {/* Ends Table */}
        {/* <div className="wow fadeInDown">
          <Table bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>{t("Package_Name")}</th>
                <th>{t("Purches_Details")} </th>
                {role_id === 2 && (
                  <>
                    <th>{t("quantity")}</th>
                    <th>{t("assigned_course")}</th>
                    <th>{t("unassigned_course")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {allPackages?.map((item, key) => (
                <tr
                  key={item.id}
                  className="border-color-on-hover"
                  onClick={() => openCourse(item.package_id)}
                >
                  {item.package_id !== 0 && (
                    <>
                      {role_id !== 4 && <td>{item?.course_title}</td>}
                      {role_id === 4 && <td>{item?.title}</td>}
                    </>
                  )}
                  {item.package_id === 0 && <td>{currentSubscrption?.name}</td>}
                  {item.package_id !== 0 && (
                    <td>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>
                  )}
                  {item.package_id === 0 && (
                    <td>
                      {moment(currentSubscrption?.created_at).format(
                        "DD-MMM-YYYY"
                      )}
                    </td>
                  )}

                  {item.package_id !== 0 && (
                    <>
                      {role_id === 2 && (
                        <>
                          <td>{item?.quantity}</td>
                          <td>{item?.course_assign_user_count}</td>
                          <td>{item?.unassign_course_count}</td>
                        </>
                      )}
                    </>
                  )}

                  {item.package_id === 0 && (
                    <>
                      {role_id === 2 && (
                        <>
                          <td>{currentSubscrption?.quantity}</td>
                          <td>{currentSubscrption?.quantity}</td>
                          <td>{currentSubscrption?.assign_count}</td>
                        </>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>

          {allPackages && allPackages.length === 0 && (
            <p className="text-center">{t("No_records_found")}</p>
          )}
        </div> */}
        {/* <div className="wow fadeInDown">
          <Table bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>{t("subscription")}</th>
                <th>{t("Purches_Details")} </th>
                {role_id === 2 && (
                  <>
                    <th>{t("quantity")}</th>
                    <th>{t("assigned_course")}</th>
                    <th>{t("unassigned_course")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {role_id === 3 && currentSubscrption?.name !== undefined && (
                <tr>
                  <td>
                    {moment(currentSubscrption?.created_at).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>{currentSubscrption?.name}</td>
                </tr>
              )}
            </tbody>
          </Table>
          {currentSubscrption?.name == undefined && (
            <p className="text-center">{t("No_records_found")}</p>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default MyOrder;
