import React, { useEffect, useState, useRef } from "react";
import Header from "../commom/Header2";
import { Row, Col, Image, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  getCources,
  addCources,
  removeCources,
  getCreator,
  publishCourse,
  tagList,
  myCourse,
  publishPackage,
} from "../../store/api/course.api";
import ConfirmPopup from "../commom/ConfirmPopup";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditCourse from "../commom/EditCourse";
import Footer from "../commom/Footer";
import Slider from "react-slick";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import { checkSubsDate } from "../commom/commonConst";
import { ck_config } from "../commom/commonConst";
import Wrapper from "../component/Wrapper";

const MyCourseDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { role_id, id } = useSelector((state) => state?.auth?.user);
  const data = useSelector((state) => state.auth.user);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allCources, setAllCources] = useState();
  const [allCretor, setAllCreator] = useState();
  const [value, setValue] = useState({
    title: "",
    RegularPrice: "",
    SpecialPrice: "",
    tags: "",
    course_sub_title: '',
    sort_title: ''
  });
  // const editorRef = useRef(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPdf, setSelectedPDF] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [tempAllCources, setTempAllCources] = useState();
  const [showEditCourse, setshowEditCourse] = useState(false);
  const [editId, setEditId] = useState(null);
  const [pubMessage, setPubMessage] = useState("");
  const [tagListItems, setTagList] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  const [tagListToAssign, setTagListToAssign] = useState();
  const [textIncludedinCourse, setTextIncludedinCourse] = useState(null);
  const [textSuitableFor, setTextSuitableFor] = useState(null);
  const [is_podcast, setIsPodcast] = useState(0);

  let tags = [];
  let optionsTag = [];
  let courseoptionTag = [];


  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const user = useSelector((state) => state?.auth?.user);
  const { tagId, index, tagname } = useParams();
  // open when we click edit course
  const openShow = (id) => {
    setEditId(id);
    setshowEditCourse(true);
  };
  const editorConfiguration = ck_config;
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
    //  "page":"1",
    //  "limit":"10"
  };

  if (role_id !== 1) {
    dataFilter.filter.is_public = 1;
  }
  const options = [];
  useEffect(() => {
    if (tagId && tagId != 1) {
      setImageIndex(tagId);
      dataFilter.tags = tagId.toString();
    }
    dispatch(getCources(dataFilter)).then((course) => {
      // if (role_id == 1) {
      setAllCources(course.payload.data.courses);
      setTempAllCources(course.payload.data.courses);
      // } else {
      //   setAllCources(
      //     course.payload.data.courses.filter(
      //       (item) => item.is_purchaed === true || item.is_assigned === true
      //     )
      //   );
      //   setTempAllCources(
      //     course.payload.data.courses.filter(
      //       (item) => item.is_purchaed === true || item.is_assigned === true
      //     )
      //   );
      // }
    });
    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
      setSelectedCreator(data.payload.data[0].creator_id);
    });
    dispatch(tagList()).then((data) => {
      setTagList(data.payload.data.filter((item) => item.id !== 2 ));
    });
  }, []);
 
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeMycourse = (e) => {
   
    if (e.target.name === "mycourse" && e.target.checked === true) {
      delete dataFilter.tag;
      dispatch(getCources(dataFilter)).then((course) => {
        setAllCources(
          course.payload.data.courses.filter(
            (item) =>
              item.is_purchaed === true ||
              item.is_assigned === true ||
              checkSubsDate(user?.subscription?.trial_end) == 1
          )
        );
      });
      } else {
        delete dataFilter.tag;
        dispatch(getCources(dataFilter)).then((course) => {
          if (role_id !== 1) {
            setAllCources(
              course.payload.data.courses.filter((item) => item.podcast === 0)
            );
          } else {
            setAllCources(course.payload.data.courses);
          }
        });
      }
  }
  const onChangeCreator = (event) => {
    setSelectedCreator(event.value);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const closeModal = () => {
    setshowEditCourse(false);
    dispatch(getCources(dataFilter)).then((course) => {
      if (role_id == 1) {
        setAllCources(course.payload.data.courses);
        setTempAllCources(course.payload.data.courses);
      } else {
        setAllCources(
          course.payload.data.courses.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
        setTempAllCources(
          course.payload.data.courses.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
      }
    });
  };
  tagListItems &&
    tagListItems.map((list) =>
      optionsTag.push({ name: list.name, id: list.id })
    );

  tagListItems &&
    tagListItems.map((list) =>
      courseoptionTag.push({ label: list.name, value: list.id })
    );
  const onSelect = (selectedList, selectedItem) => {
    setTagListToAssign(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setTagListToAssign(selectedList);
  };
  allCretor &&
    allCretor.map((list) =>
      options.push({ value: list.creator_id, label: list.creator_name })
    );
  const CourseList = ({ course, onDelete, index }) => {
    return (
      <Col md={6} xl={4}>
         {role_id !== 1 && (
        <div className="getstart-block position-relative d-flex flex-column feature-box" onClick={() => gotoInnerPage(course)}>
          <>
            {" "}
            <Image
              className="img-fit cursor_class"
              src={course.image ? course.image : "/assets/images/Videi-1.jpeg"}
              alt="Image"
              width={526}
              height={348}
              onClick={() => gotoInnerPage(course)}
            />
            <div className="img-content text-white d-flex align-items-start justify-content-end py-3 py-md-5">
              <div className="bottom-text text-md-start text-center block" style={{ width: '100%' }}>
                <h5
                title={course.sort_title}
                  className="mb-0 fw-light cursor_class"
                  onClick={() => gotoInnerPage(course)}
                >
                  {course.sort_title}
                </h5>
                <p
                  className="fw-semibold cursor_class  mb-0 fs-16 mr-5"
                  // dangerouslySetInnerHTML={{
                  //   __html: add3Dots(course.content, 100),
                  // }}

                  onClick={() => gotoInnerPage(course)}
                >{add3Dots(course.auther_details?.name, 50)}</p>
                {course.number_of_courses > 0 && (
                  <p>{course.number_of_courses} kurser</p>
                )}
              </div>


              
            </div>
          </>
        </div>
         )}
         {role_id === 1 && (
            <div className="getstart-block position-relative d-flex flex-column feature-box" >
            <>
              {" "}
              <Image
                className="img-fit cursor_class"
                src={course.image ? course.image : "/assets/images/Videi-1.jpeg"}
                alt="Image"
                width={526}
                height={348}
                onClick={() => gotoInnerPage(course)}
              />
              <div className="img-content text-white d-flex align-items-start justify-content-end py-3 py-md-5">
                <div className="bottom-text text-md-start text-center block" style={{ width: '100%' }}>
                  <h5
                  title={course.sort_title}
                    className="mb-1 fw-light cursor_class"
                    onClick={() => gotoInnerPage(course)}
                  >
                    {course.sort_title}
                  </h5>
                  <p
                    className="fw-semibold cursor_class  mb-0 fs-16 mr-5"
                    // dangerouslySetInnerHTML={{
                    //   __html: add3Dots(course.content, 100),
                    // }}
  
                    onClick={() => gotoInnerPage(course)}
                  >{add3Dots(course.auther_details?.name, 50)}</p>
                  {course.number_of_courses > 0 && (
                    <p>{course.number_of_courses} kurser</p>
                  )}
                </div>
  
  
                {role_id === 1 && (
                  <div className="mt-1">
                    <a
                      className="btn btn-link icon-ic-trash text-white link-hover "
                      onClick={(e) =>
                        handleDelete(e, course.id ? course.id : course.package_id)
                      }
                    >
                      {" "}
                    </a>
  
                    {popup.show && (
                      <ConfirmPopup
                        handleDeleteTrue={handleDeleteTrue}
                        handleDeleteFalse={handleDeleteFalse}
                        message={t("You_sure_you_wanna_delete")}
                      />
                    )}
                    {role_id === 1 && (
                      <a
                        className="btn btn-link icon-ic-edit text-white link-hover"
                        style={{ "margin-left": "5px" }}
                        onClick={() =>
                          openShow(course.id ? course.id : course.package_id)
                        }
                      ></a>
                    )}
                    {showEditCourse &&
                      editId === (course.id ? course.id : course.package_id) && (
                        <EditCourse
                          show={showEditCourse}
                          editId={editId}
                          closeModal={closeModal}
                        />
                      )}
                    {role_id === 1 && (
                      <label className="toggle-public-label">
                        <input
                          type="checkbox"
                          value={course.is_public}
                          checked={course.is_public === 1 ? true : false}
                        />
                        <span className="back">
                          <span className="toggle"></span>
                          <span
                            className="label on"
                            onClick={() => handlePublish(false, course)}
                          >
                            {t("Off")}
                          </span>
                          <span
                            className="label off"
                            onClick={() => handlePublish(true, course)}
                          >
                            {t("On")}
                          </span>
                        </span>
                      </label>
                    )}
                    {publishPopup.show && (
                      <ConfirmPopup
                        handleDeleteTrue={handlePublishTrue}
                        handleDeleteFalse={handlePublishFalse}
                        message={t(pubMessage)}
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          </div>
           )}
      </Col>
    );
  };

  const onDelete = (id) => {
    let data = {
      id: id,
      is_delete: 1,
    };
    dispatch(removeCources(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCources(dataFilter)).then((course) => {
            if (role_id == 1) {
              setAllCources(course.payload.data.courses);
              setTempAllCources(course.payload.data.courses);
            } else {
              setAllCources(
                course.payload.data.courses.filter(
                  (item) =>
                    item.is_purchaed === true || item.is_assigned === true
                )
              );
              setTempAllCources(
                course.payload.data.courses.filter(
                  (item) =>
                    item.is_purchaed === true || item.is_assigned === true
                )
              );
            }
            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openEdit = (id) => {
    navigate("/course-management");
  };
  const gotoInnerPage = (course) => {
    if (course.id) {
      navigate("/private-open-course-topics/" + course.id);
    } else {
      navigate("/package/" + course?.package_id);
    }
  };
  const onSave = () => {
    var data = new FormData();
    tagListToAssign && tagListToAssign.map((list) => tags.push(list.id));
    data.append("title", value.title);
    data.append("content", textContent);
    data.append("r_price", value.RegularPrice);
    data.append("s_price", value.SpecialPrice);
    data.append("creator_id", selectedCreator);
    data.append("podcast", is_podcast);
    data.append("included_in_course", textIncludedinCourse);
    data.append("suitable_for", textSuitableFor);
    data.append('course_sub_title', value.course_sub_title);
    data.append('sort_title', value.sort_title);

    if (tags.length > 0) {
      data.append("tags", tags);
    }
    if (value.certificate_text) {
      data.append("certificate_text", value.certificate_text);
    }
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    if (selectedPdf) {
      data.append("file", selectedPdf, selectedPdf.name);
    }

    dispatch(addCources(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCources(dataFilter)).then((course) => {
            if (role_id == 1) {
              setAllCources(course.payload.data.courses);
              setTempAllCources(course.payload.data.courses);
            } else {
              setAllCources(
                course.payload.data.courses.filter(
                  (item) =>
                    item.is_purchaed === true || item.is_assigned === true
                )
              );
              setTempAllCources(
                course.payload.data.courses.filter(
                  (item) =>
                    item.is_purchaed === true || item.is_assigned === true
                )
              );
            }
            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });

  const [publishPopup, setPublishPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
    flag: false,
  });
  const handleDelete = (e, id) => {
    e.preventDefault();
    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      onDelete(popup.id);
      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };
  const oncheckChange = (flag) => {
    if (flag) {
      if (role_id == 1) {
        setAllCources(allCources);
        setTempAllCources(allCources);
      } else {
        setAllCources(
          allCources.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
        setTempAllCources(
          allCources.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
      }
      setAllCources(
        allCources.filter(
          (item) => item.is_purchaed === true || item.is_assigned === true
        )
      );
    } else {
      setAllCources(tempAllCources);
    }
  };
  const customSort = (item, index) => {
    setImageIndex(item.value);
    if (item.label === "Alla kurser") {
      delete dataFilter.tag;
      dispatch(getCources(dataFilter)).then((course) => {
        if (role_id !== 1) {
          setAllCources(
            course.payload.data.courses.filter((item) => item.podcast === 0)
          );
        } else {
          setAllCources(course.payload.data.courses);
        }
      });
    } else if (item.label === "Mina kurser") {
      //  setAllCources(tempAllCources.filter((item=> item.is_purchaed === true || item.is_assigned === true)));
      delete dataFilter.tag;
      dispatch(getCources(dataFilter)).then((course) => {
        setAllCources(
          course.payload.data.courses.filter(
            (item) =>
              item.is_purchaed === true ||
              item.is_assigned === true ||
              checkSubsDate(user?.subscription?.trial_end) == 1
          )
        );
      });
    } else {
      dataFilter.tags = item.value.toString();

      // dataFilter.tags = item.id.toString();
      dispatch(getCources(dataFilter)).then((course) => {
        setAllCources(course.payload.data.courses);
      });
    }
    navigate("/my-course-details/" + item.label + "/" + item.value + "/" + index);
  };
  const handlePublish = (flag, course) => {
    setPublishPopup({
      show: true,
      course,
      flag,
    });
    if (flag) {
      setPubMessage(
        course?.id
          ? "Vill_du_publicera_den_här_kursen"
          : "Vill_du_publicera_den_här_paketet"
      );
    } else {
      setPubMessage(
        course?.id
          ? "Vill_du_unpublicera_den_här_kursen"
          : "Vill_du_unpublicera_den_här_paketet"
      );
    }
  };
  const handlePublishTrue = () => {
    if (publishPopup.show && publishPopup.course) {
      makeMePublic(publishPopup.flag, publishPopup.course);
      setPublishPopup({
        show: false,
        id: null,
        flag: false,
      });
    }
  };
  const handlePublishFalse = () => {
    setPublishPopup({
      show: false,
      course: null,
      flag: false,
    });
  };

  const makeMePublic = (flag, course) => {
    // if (flag) {
    let data;
    if (course?.id) {
      data = {
        course_id: course.id,
        is_public: flag == true ? 1 : 0,
      };
      dispatch(publishCourse(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            dispatch(getCources(dataFilter)).then((course) => {
              setAllCources(course.payload.data.courses);
            });
          }
        });
    } else {
      data = {
        package_id: course.package_id,
        is_public: flag == true ? 1 : 0,
      };
      dispatch(publishPackage(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            dispatch(getCources(dataFilter)).then((course) => {
              setAllCources(course.payload.data.courses);
            });
          }
        });
    }

    const courseoptionTag = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ]
    // }
    // } else {
    //   let data = {
    //     course_id: id,
    //     is_public: 0,
    //   };
    //   dispatch(publishCourse(data))
    //     .unwrap()
    //     .then((res) => {
    //       if (res.status === 200) {
    //         dispatch(getCources(dataFilter)).then((course) => {
    //           setAllCources(course.payload.data.courses);
    //         });
    //       }
    //     });
    // }
  };
  const handleck = (e) => {
    setIsPodcast(e.target.checked ? 1 : 0);
  };
  // let options = [];
  // allCources && allCources.map(list =>
  //   options.push({ name: list.course_title, id: list.course_id })
  // )
  // const options = () => [{ name: 'Option 1️⃣', id: 1 }, { name: 'Option 2️⃣', id: 2 }]

  return (
    <>
      <div className="innerblock-page">
        <Header headerShow={false} />
        <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
          <div className="d-flex gap-lg-0 gap-20px justify-content-between align-items-center flex-lg-nowrap flex-wrap wow fadeInDown py-20px px-lg-30px  px-20px innerpage-header">
            <div className="text-svart fw-medium">
              <h1 className="mb-sm-0 mb-2px text-svart fw-medium text-white h4 mobile-font-16">{"Kurser"}</h1>
              <span className="opacity-60 fs-20 text-svart fw-medium mobile-font-14">Färdiginspelade kurser</span>
            </div>

            <div className="single-selectionblock shadow-selection selectinfo-box">
              <div className="shadow-selectwrap">
                <Select
                  className="custom-select  header-selection"
                  classNamePrefix="myselect"

                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  options={courseoptionTag}
                  placeholder='Alla kurser'
                  value={courseoptionTag.filter(function (option) {
                    return option.value === Number(imageIndex);
                  })}
                  isSearchable={false}
                  onChange={(values) => customSort(values, values.value - 1)} />
                <span className="mx-20px border-block"></span>
                <Form.Check
                  type="checkbox"
                  id={`default-1`}
                  name="mycourse"
                  label={`Mina kurser`}
                  onChange={onChangeMycourse}
                />
              </div>
            </div>

                     
          
          </div>

          {/* {tagListItems.length > 0 && (
          <div className="categories-slider">
            {tagListItems?.map((item, index) => (
              <div
                key={index}
                className="feature-card-wrap-slider"
                onClick={() => customSort(item, index)}
              >
                <div
                  className={`feature-box ${
                    index == imageIndex ? "border-primary-solid" : ""
                  }`}
                >
                  <p>{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        )} */}
          {/* <img src={item.image?item.image:'/assets/images/SVG/053-diamond.svg'}  className='feature-image svg-btn-image-no-hover' alt="Banner image" width={40} height={40} /> */}

          {/* <div className="feature-card-wrap"> */}
          {/* <Slider {...featurecard}> */}
          {/* </div> */}
          {/* </Slider> */}

          <Wrapper>
          {role_id === 1 && (
            <>
              <div className="right-button">
                <Button
                  variant="primary"
                  className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3"
                  onClick={handleShow}
                >
                  {t("Add_New")}
                </Button>
                <Link
                  to="/reorder"
                  className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3"
                >
                  {t("Ordering")}
                </Link>
                <Link
                  to="/taggar"
                  className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3"
                >
                  {t("Tags")}
                </Link>
              </div>
            </>
          )}
            <Row className="g-xl-20px g-8px course-details-blocks">
              {allCources?.length > 0 &&
                allCources?.map((course, key) => (
                  <CourseList
                    course={course}
                    key={key}
                    index={key}
                    onDelete={onDelete}
                  />
                ))}
            </Row>
          </Wrapper>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Create_Courses")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={"Title"}
                    name="title"
                    aria-label="Product Name"
                    value={value.title}
                    onChange={onChange}
                  />
                  <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                </div>
              </Form.Group>

              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("Underrubrik")}
                    name="course_sub_title"
                    aria-label="Product Name"
                    value={value.course_sub_title}
                    onChange={onChange}
                  />

                </div>
              </Form.Group>
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail">
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("sort_title")}
                    name="sort_title"
                    aria-label="Product Name"
                    value={value.sort_title}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-10 wow fadeInDown">
                {/* <Form.Control as="select"  className='flex-grow-1 question-control' custom='true' onClick={onChangeCreator}  >
                     
                     <option disabled value={-1} key={-1}>{t('select')}</option>
                     
                            { allCretor && allCretor.map(list =>
                                <option key={list.creator_id} value={list.creator_id}>{list.creator_name}</option>)}
                                
                  </Form.Control> */}
                {options && (
                  <Select
                    className="custom-select"
                    classNamePrefix="myselect"
                    options={options}
                    onChange={onChangeCreator}
                  />
                )}
              </Form.Group>
              <Row className="gy-10">
                <Col sm={6}>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file1"
                      className="visually-hidden"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="file1"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/Video_file_light.png"
                          alt="Select Image"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Select_Image")}</span>
                    </label>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file2"
                      className="visually-hidden"
                      onChange={(e) => setSelectedPDF(e.target.files[0])}
                    />
                    <label
                      htmlFor="file2"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/fileupload-ic.png"
                          alt="Attachments Link Pdf"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Attachments_Link_Pdf")}</span>
                    </label>
                  </div>
                </Col>
              </Row>

              {/**content */}
              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  style={{ color: "#fff" }}
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter data"
                  onChange={(event, editor) => {
                    setTextContent(editor.getData());
                  }}
                />
              </div>

              {/**included in package */}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Included_in_course")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter what is included in course"
                  onChange={(event, editor) => {
                    setTextIncludedinCourse(editor.getData());
                  }}
                />
              </div>
              {/*Who is the course suitable for ?*/}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Who_suitable_for")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Who is the course suitable for?"
                  onChange={(event, editor) => {
                    setTextSuitableFor(editor.getData());
                  }}
                />
              </div>

              <div className="d-flex flex-wrap price-wrapper">
                <Form.Group
                  className="mb-10 wow fadeInDown"
                  controlId="formRegularPrice"
                >
                  <Form.Control
                    type="number"
                    placeholder={t("Regular_Price")}
                    name="RegularPrice"
                    aria-label="Regular Price"
                    value={value.RegularPrice}
                    onChange={onChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-10 wow fadeInDown"
                  controlId="formSpecialPrice"
                >
                  <Form.Control
                    type="number"
                    placeholder={t("Special_Price")}
                    name="SpecialPrice"
                    aria-label="Special Price"
                    value={value.SpecialPrice}
                    onChange={onChange}
                  />
                </Form.Group>
              </div>
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formGroupAboutUs"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder={t("Certificate_Text")}
                  name="certificate_text"
                  aria-label="Certificate Text"
                  value={value.certificate_text}
                  onChange={onChange}
                />
              </Form.Group>

              {tagListItems && (
                <Multiselect
                  options={optionsTag}
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options,
                  selectedValues={tagListToAssign} // Preselected value to persist in dropdown
                  showCheckbox
                />
              )}
              <div className="mt-2 wow fadeInDown">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name="podcast"
                    checked={is_podcast === 1 ? true : false}
                    onChange={handleck}
                  />{" "}
                  {t("is_podcast")}{" "}
                </label>
              </div>
              <Button
                variant="primary"
                type="button"
                className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                onClick={onSave}
              >
                {" "}
                {t("Save")}{" "}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        {/* <Footer /> */}
      </div>

    </>
  );
};

export default MyCourseDetails;
