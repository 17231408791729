import { Children } from "react";

const Wrapper = ({ children, className }) => {
    return (
        <div className={`py-lg-40px px-xxl-100px wrapper-main  px-lg-30px px-20px flex-grow-1 ${className}`}>
            {children}
        </div>
    )
}

export default Wrapper;