import React, { useEffect, useState } from 'react';
import { getPlans } from '../../store/api/course.api'
import Slider from 'react-slick';
import { Link, useNavigate } from "react-router-dom";
import Header from '../commom/Header2';
import Header1 from '../commom/Header';
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent";
import FeatureCourseList from '../commom/FeatureCourseList';
import { useDispatch,useSelector } from 'react-redux';
import {yearlyPlanName,yearlyPrice} from '../commom/commonConst';
import Footer from '../commom/Footer';
function Pricing(props) {
    const [priceList, setpriceList] = useState();
    const dispatch = useDispatch();
     const { t } = useTranslation();
     const navigate = useNavigate();
     const { stripe_subscription_id } = useSelector((state) => state?.auth?.user?.subscription || {});
     const { role_id } = useSelector((state) => state?.auth?.user);
     const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      const ratingChanged = (newRating) => {
        console.log(newRating);
      };
    useEffect(() => {
        
        dispatch(getPlans()).then((res) => {
            setpriceList(res.payload.data);
         })
        
    }, []);
    const selectProcuct=(id,price,is_trial) => {
      localStorage.setItem('fromPricingId',id);
      localStorage.setItem('fromPricingPrice',price);
      localStorage.setItem('fromPricingIs_trial',is_trial);
      
      if(role_id) {
        navigate('/subsription/'+id+'/'+price+'/'+is_trial);
      } else {
        navigate('/registrera');
      }
      
  }
  const gotoOurCourse=() =>{
    navigate('/vara-kurser');
  }
  const gotoContactus=() =>{
    navigate('/kontakta-oss');
  }
    return (
        <>
       { (role_id) && 
         <Header />
       }
        { (!role_id) && 
        <Header1/>
         }
        
        <div className={"page-wrapper flex-grow-1 py-0 " + (role_id ? 'page-block' : '')}>
        {priceList &&
        <>
            <section className='pt-11 pb-100 pb-sm-50'>
                <Container fluid>
                <div className="block">
                    <div className="pricing-title">
                    {t('Prova_i_14_dagar_kostnadsfritt')}
                    </div>
                    <div className="bg-secondary-light border rounded-20 py-md-10 px-md-13 p-10 mb-5 ">
                        <div>
                            <h3 className="font-38 fw-semibold">Prenumeration</h3>
                            <ul className="pricing-list list-unstyled font-18">
                                <li>Tillgång till samtliga kurser på plattformen.</li>
                                <li>Direkt tillgång till nya kurser.</li>
                                <li>Dygnet runt, året runt. Utbildning som passar ditt schema.</li>
                                <li>Fullständig utbildningspanel för att följa utvecklingen.</li>
                                <li>{t('Prova_i_14_dagar_kostnadsfritt_Avbryt_när_du_vill')}</li>                               
                            </ul>
                        </div>
                        <Row className="gy-11">
                          {
                        priceList?.map((item, index) => (
                            <Col md={6}>
                                <div  className={"fw-semibold pricing-box active p-11 block h-100 rounded-20 " + (index%2==0 ? 'bg-primary border-color-on-hover-white' : 'bg-white text-primary border-color-on-hover')}  onClick={() => selectProcuct(item.price_id,item.price,false)}>
                                    <p className="font-18 mb-1">{index%2==0? 'Årsprenumeration':'Månadsprenumeration'}</p>
                                    <h3 className="mb-0"><span className="font-47 fw-medium">{item.price/100}</span> <span className="fw-normal">kr/Månad</span></h3>
                                    <p className="mt-1 fw-normal font-18">{index%2==0? 'Betalas varje månad':'Ingen bindningstid'}</p>
                                </div>
                            </Col>
                             ))
                          }
                          
                             {/* <Col md={6}>
                                <div className="fw-semibold pricing-box bg-white p-11 block h-100 rounded-20 text-primary">
                                    <p className="font-18 mb-1">Monthly</p>
                                    <h3 className="mb-0"><span className="font-47 fw-medium">199</span> <span className="fw-normal">kr/min</span></h3>
                                    <p className="mt-1 fw-normal font-18">lorem ipsum ipsum</p>
                                </div>
                            </Col> */}
                            {!stripe_subscription_id &&
                            <Col md={12}>
                              <div className="wrapper mt-3">
                                <Button onClick={() => selectProcuct(yearlyPlanName,yearlyPrice,true)}  className='btn btn-primary align-items-center wrapper-btn font-18  ' >{t('Paborja_proveperiod')}</Button>
                              </div>
                            </Col>
                            }
                        </Row> 
                    </div>
                    
                    <div className="bg-secondary-light border rounded-20 position-relative py-md-5 ps-md-13 pe-md-5 p-10 mb-5 border-color-on-hover">
                    <a className="stretched-link" href="/vara-kurser"></a>
                        <div className="d-flex flex-md-nowrap flex-wrap">
                            <div className="align-self-center block flex-grow-1">
                                <h3 className="font-38 fw-semibold">Enskild kurs</h3>
                                <ul className="pricing-list list-unstyled font-18">
                                    <li>Fullständig tilllgång till videomaterialet.</li>
                                </ul>
                            </div>
                            
                            <div className="align-items-center bg-primary d-flex p-10 rounded-20 block flex-shrink-0 me-md-5 skill-infobx mt-md-0 mt-11" onClick={gotoOurCourse}>
                            
                            <h3><span className="fw-normal font-18">fr</span><span className="font-38 fw-medium">795</span> <span className="fw-normal font-18">kr</span></h3>
                            </div>
                            <div className="d-md-flex d-none align-items-center rightmarkic flex-shrink-0">
                            <a href="/vara-kurser" className="d-flex align-items-center justify-content-center"><img src="/assets/img/icons/right-angle-icon.svg" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="bg-secondary-light border rounded-20 position-relative py-md-5 ps-md-13 pe-md-5 p-10 mb-5 border-color-on-hover">
                     <a className="stretched-link" href="/kontakta-oss"></a>
                        <div className="d-flex flex-md-nowrap flex-wrap">
                            <div className="align-self-center block flex-grow-1">
                                <h3 className="font-38 fw-semibold">Företagserbjudande</h3>
                                <ul className="pricing-list list-unstyled font-18">
                                    <li>När du behöver fler än 50 licenser.</li>
                                    <li>Adminpanel för teamets översikt.</li>
                                </ul>
                            </div>
                            <div className="align-items-center bg-primary d-flex p-10 rounded-20 block flex-shrink-0 me-md-5 skill-infobx mt-md-0 mt-11" onClick={gotoContactus}>
                                <h3 className="text-center"><span className="font-18 fw-medium">Kontakta oss för prisförslag</span></h3>

                            </div>
                            <div className="d-md-flex d-none align-items-center rightmarkic flex-shrink-0">
                            <a href="/kontakta-oss" className="d-flex align-items-center justify-content-center"><img src="/assets/img/icons/right-angle-icon.svg" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                </Container>
            </section>
            { (!role_id) && 
            <>
            <section className="bg-gray pt-100 pb-100 hv-100">
              <Container fluid>
                <Row className="mb-50 align-items-center">
                  <Col md={6}>
                    <div className='portfolio-leftblock mb-sm-0 mb-3'>
                      <h2 className="font-medium fw-light font-size-35-A " style={{'white-space':'nowrap'}}>{t('En_utbildningsplattform')}.  <h2 className='font-medium fw-medium'>{t('oändliga_möjligheter')}.</h2></h2>
                      <h4 className='mt-3 mt-md-5 f-w-600'>{t('Docens_ett_enkelt_sätt_att_utbilda_medarbetare')}.</h4>
                        <p>{t('Väl_valda_experter_lär_ut_enligt')}</p>
                        <p>{t('Docens_experter_och_produktionsteam')}</p>
                      <div className="d-flex ct-btn web-btns">
                        {/* <Link to="/functionality" className='btn btn-outline-secondary d-flex align-items-center'>{t('Läs_mer')}<span className='icon-right-angle-icon d-block ms-2'></span></Link> */}
                        <a href="/funktioner" className='btn btn-outline-secondary d-flex align-items-center'>{t('Hur_det_funkar')}<span className='icon-right-angle-icon d-block ms-2'></span></a>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='portfolio-image custome-height'>
                    <Image src='/assets/images/home_image.webp' alt='Översikt' />
                    </div>
                    <div className="d-flex ct-btn mb-btns mt-4">
                      {/* <Link to="/functionality" className='btn btn-outline-secondary d-flex align-items-center'>{t('Läs_mer')}<span className='icon-right-angle-icon d-block ms-2'></span></Link> */}
                      <a href="/funktioner" className='btn btn-outline-secondary d-flex align-items-center'>{t('Hur_det_funkar')}<span className='icon-right-angle-icon d-block ms-2'></span></a>
                    </div>
                  </Col>
                </Row>
               
              </Container>
            </section>

        <Footer isShow={true}/>
          </>
          }
          </>
}
        </div>
        </>
    );
}

export default Pricing;