import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { buyPlans,setQuantityPremium,updateActiveSubscription,completePlans,completeTrial,getCoupanList } from '../../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Modal,Row,Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { yearlyPlanName} from '../commom/commonConst';
import { updatedSubcription } from '../../store/api/auth.api';
import {googlePurchangeEvent,facebookPurchangeEvent,facebookTrialStartEvent} from '../commom/facebookandgoogleevents';
import moment from 'moment'

export default function Subscription(props) {
  
  const { plan_id,price,is_trial } = useParams();
  const { t } = useTranslation();
  const [currency] = useState("SEK");
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const [show, setShow] = useState(false);
  const [setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [isLoading, setProcessing] = useState(false);
  const { business_id, id } = useSelector((state) => state.auth.user);
  const { stripe_subscription_id,expire_at,is_cancel } = useSelector((state) => state?.auth?.user?.subscription || {});
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [value, setValue] = useState({ coupen: "", name: '', email: '', last_name: "" });
  const [selectedtc, setSelectedTc] = useState(false);
  const [selectedmk, setSelectedMk] = useState(true);
  const [yearlyPlan] = useState( plan_id === yearlyPlanName);
  const [couponDiscount, setCountDiscount]= useState(0)
  const [couponList,setCoupanList] = useState();
  const [couponTosend,setCoupanToSend] = useState();
  useEffect(() => {
    
    dispatch(getCoupanList()).then(async (res) => {
      if (res.payload.status == 200) {
        setCoupanList(res.payload.data)
      }
    }).catch(err => {
      toast.error(err.message)
    });
  }, []);

  const onChange = (e) => {
    console.log(e.target.value)
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (status, phone, country) => {
    setValue({
      ...value,
      ['phone']: phone,
      ['code']: country.dialCode
    });
  }

  const handleSubmit = async ev => {
    setProcessing(true);
    if (stripe_subscription_id && is_cancel != 1) {
      let data = {
        stripe_subscription_id: stripe_subscription_id,
        quantity: props.quantity,
        price_id: plan_id,
        is_year_plan: yearlyPlan ? true : false
      }
      dispatch(updateActiveSubscription(data)).then(async (res) => {
        if (res.payload.status == 200) {
          setShow(true)
        }
      }).catch(err => {
        toast.error(err.message)
      });
    } else {
      const { token } = await stripe.createToken(elements.getElement(CardElement))
      let data = {
        "email": value.email,
        "first_name": value.name,
        "last_name": value.last_name,
        "phone": value.code + value.phone,
        "token": token.id,
        "price_id": plan_id,
        "is_year_plan": yearlyPlan ? true : false,
        quantity: props.quantity,
        is_trial: is_trial === 'true' && yearlyPlan? true: false
      }
      if (business_id) {
        data.business_id = business_id;
      } else {
        data.user_id = id;
      }
      if(couponTosend) {
        data.coupon = couponTosend;
      }
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          // Include any additional collected billing details.
          name: value.name,
        },
      })
      data.pm = result.paymentMethod.id
      dispatch(buyPlans(data)).then(async (res) => {

        if (res.payload.status == 200) {
        
          if (res.payload.data.actionRequired) {
           // We perform 3D Secure authentication
            const { paymentIntent, error } = await stripe.confirmCardPayment(
              res.payload.data.clientSecret
            );
            if (error){
              toast.error('Error in payment, please try again later')
              return false;
            } 
            if (paymentIntent.status === "succeeded") {
               let body = {
                reqObj:res.payload.data.reqObj
               }
               
               dispatch(completePlans(body)).then(async (res1) => {
               // toast.success(`Payment successful`);
               if(res.payload.data.reqObj.stripe_subscription_id){
                dispatch(updatedSubcription(res.payload.data.reqObj.stripe_subscription_id))
                .unwrap()
                .then(() => {
                })
                .catch(() => {
                   
                });
              }
                }).catch(err => {
               
                setProcessing(false);
                toast.error(err.message)
              });
            }
          } else if(res.payload.data.actionRequiredWithTrial) {
           
            const { error, paymentIntent } =
            await stripe.handleCardAction(res.payload.data.clientSecret);
           if(error) {
            toast.error(error)
           } else {
            res.payload.data.reqObj.trial_end =  moment(res.payload.data.reqObj.trial_end).format("YYYY-MM-DD:HH:mm")
            let body = {
              reqObj:res.payload.data.reqObj,
              payment_intent_id:paymentIntent.id
             }

            dispatch(completeTrial(body)).then(async (res1) => {
              // toast.success(`Payment successful`);
              if(res.payload.data.reqObj.stripe_subscription_id){
               dispatch(updatedSubcription(res.payload.data.reqObj.stripe_subscription_id))
               .unwrap()
               .then(() => {
               })
               .catch(() => {
                  
               });
             }
               }).catch(err => {
              
               setProcessing(false);
               toast.error(err.message)
             });
           }
            
          }


          setTimeout(async () => {
            // dispatch(setPremium({
            //   business_id: business_id,
            //   set_flag:true
            // })).then(async (res) => {
            //   setShow(true)

            // }).catch(err => {
            //   setProcessing(false);
            //   toast.error(err.message)
            // });
           
            if(res.payload.data.stripe_subscription_id){
            dispatch(updatedSubcription(res.payload.data.stripe_subscription_id))
            .unwrap()
            .then(() => {
            })
            .catch(() => {
               
            });
          }
            googlePurchangeEvent(res.payload.items,(price * props.quantity)/100,res.payload.items,yearlyPlan?'Årsprenumeration':'Månadsprenumeration',price,props.quantity);
            if(is_trial === 'true') {
              facebookTrialStartEvent((price * props.quantity)/100);
            } else {
              facebookPurchangeEvent((price * props.quantity)/100);
            }
            
            let data = {
              course_quantity: props.quantity,
              is_quantity_purchased: true
            }
            if (business_id) {
              data.business_id = business_id;
            } else {
              data.user_id = id;
            }
            dispatch(setQuantityPremium(data)).then(async (res) => {
              setShow(true)
              //  navigate('/my-course-details'); 
            }).catch(err => {
              setProcessing(false);
              toast.error(err.message)
            });
          }, 1000);
        }
      }).catch(err => {
        // setError(err.message);
        setProcessing(false);
        toast.error(err.message)
      });
    }

  };



  const renderForm = () => {
    const options = {
      hidePostalCode: true,
      style: {
        base: {
          color: "#ffffff",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          Border: "1px solid #313131",
          fontSize: "16px",
          "::placeholder": {
            color: "#ffffff66"
          },
          lineHeight: '29px',
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#ffffff"
        }
      },
    };
    const handletc = () => {
      setSelectedTc(!selectedtc);
    };
    const handlemk = () => {
      setSelectedMk(!selectedmk);
    };
    const handleClose = () => {
      setShow(false);
      //toast.success('Tack för din prenumeration. Logga in igen för att ta del av vårt utbud')
      // dispatch(logout());
       navigate('/my-course-details');
    };
    const applyCoupen =() => {
      couponList?.map((item) => {
        if(item.coupon_name === value.coupen) {
          setCountDiscount(((((price * props.quantity)/100) *item.percentage)/100 ));
          setCoupanToSend(item.coupon_value);
          toast.success('Rabattkod!');
        }
      })
    }
    return (
      <>

        <div className='cart-rightwrapper'>
          <Form  >
        
            {!stripe_subscription_id && is_cancel == undefined  &&
            <>
            <div className='row gy-10'>
              <Form.Group className=" wow fadeInDown" controlId="formBasicEmail">
                <Form.Control type="email" placeholder={t('Email_Address')} aria-label='Email Address' name="email" onChange={onChange} value={value.email} />
              </Form.Group>
              <Form.Group className=" wow fadeInDown" controlId="name">
                <Form.Control type="text" placeholder={t('Card_Holder')} name="name" aria-label='Card Holder' onChange={onChange} value={value.name} />
              </Form.Group>
              {/* <Form.Group className="col-md-6  wow fadeInDown" controlId="name">
                <Form.Control type="text" placeholder={t('Last_Name')} name="last_name" aria-label='Card Holder' onChange={onChange} value={value.last_name} />
              </Form.Group>
              <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicEmail">
                <IntlTelInput containerClassName="intl-tel-input" preferredCountries={['se']} inputClassName="form-control col-md-6" style={{ 'width': '100%' }} name='phone' onPhoneNumberChange={handlePhoneChange} />
              </Form.Group> */}
            </div>
            <div className='pt-13 pb-10 wow fadeInDown'>
              <CardElement className="form-control st-controll" options={options} />
            </div>
            <div className='pt-13 wow fadeInDown'>
           
            <Row >
            <Col  md={8} >
            <Form.Group className=" wow fadeInDown" controlId="coupen">
            <Form.Control type="text" placeholder={t('Do_You_have_Coupen')}  name="coupen" aria-label='coupen' value={value.coupen} onChange={onChange} />
          </Form.Group>
            </Col>
            <Col  md={4} >
            <Button variant="primary" type="button" className='btn' onClick={applyCoupen} >
            {t('Apply_coupen')}
          </Button>
            </Col>
            </Row> 
          </div>
            </>
            }
          

            <div className='pt-13 wow fadeInDown'>
                           
                    <div className='cart-submain d-flex flex-wrap text-white-25 font-13 '>
                              <div className='cart-subleft '>
                                 <span className='mb-0'> Pris</span>
                              </div>
                              <div className='cart-subright'>
                                 <span className={"fw-medium mb-0 " + (is_trial === 'true' && yearlyPlan ? 'on_trial' : '')}> 
                                 {currency.toLocaleUpperCase()}{" "}
                                  {(((price * props.quantity)/100)-((((price * props.quantity)/100) *25)/100 )).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}
                                 </span>
                              </div>
                           </div>
                          <div className='cart-submain d-flex flex-wrap text-white-25 font-13'>
                              <div className='cart-subleft '>
                                 <span className='mb-0'>Moms</span>
                              </div>
                              <div className='cart-subright'>
                                 <span  className={"fw-medium mb-0 " + (is_trial === 'true' && yearlyPlan ? 'on_trial' : '')}  > 
                                 {currency.toLocaleUpperCase()}{" "}
                                  {(((((price * props.quantity)/100) *25)/100 )).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}
                                 </span>
                              </div>
                           </div>
                           <div className='cart-submain d-flex flex-wrap text-white-25 font-13 mb-2'>
                              <div className='cart-subleft '>
                                 <span className='mb-0'>Rabatt</span>
                              </div>
                              <div className='cart-subright'>
                                 <span className='fw-medium mb-0'> 
                                - {currency.toLocaleUpperCase()}{" "}
                                  {(couponDiscount).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}
                                 </span>
                              </div>
                           </div>
                           <div className='cart-submain d-flex flex-wrap text-white-25 mb-8'>
                              <div className='cart-subleft mb-0'>
                                 <h6 className='fw-medium mb-0'>Slutpris</h6>
                              </div>
                              <div className='cart-subright '>   
                                <span className={"fw-medium mb-0 " + (is_trial === 'true' && yearlyPlan ? 'on_trial' : '')}> 
                                {currency.toLocaleUpperCase()}{" "}
                                 {(((price * props.quantity)/100)-couponDiscount).toLocaleString(navigator.language, {
                                   minimumFractionDigits: 2
                                 })}{" "}/mån
                                </span>
                               
                              </div>
                           </div>
                           {is_trial === 'true' && yearlyPlan &&
                           <div className='cart-submain d-flex flex-wrap text-white-25 mb-8'>
                           
                              <div className='cart-subleft mb-0'>
                                 <h6 className='fw-medium mb-0'>Slutpris</h6>
                              </div>
                              <div className='cart-subright '>
                              
                                  {is_trial === 'true' && yearlyPlan &&
                                   <span className="fw-medium mb-0"> 
                                 {currency.toLocaleUpperCase()}{" "}
                                  {(0).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}
                                   </span>
                                  }
                              </div>
                           </div>
                          }
                           
                           <div className="mb-4">
                          
                          <div className="p-default">
                            <input type="checkbox" name="tc" value={value.tc} onChange={handletc} />
                            <label> &nbsp; {t('I_agree_to_the')} <a className="tc_link" href="/anvandarvilkor" target="_blank" ><b>{t('Terms_of_Use')}</b></a> {t('och')} <a className="tc_link" href="/integritetspolicy" target="_blank" ><b>{t('My_courses_Questions_and_answers')}</b></a></label>
                          </div>
                        
                          <div className="p-default">
                            <input type="checkbox" name="mk" value={value.mk} checked={selectedmk} onChange={handlemk}  />
                            <label> &nbsp;{t('getting_marketing_emails')}</label>
                          </div>
                          {is_trial === 'true' && yearlyPlan &&
                         <p className="mt-1">*Ifall ditt kort har en 3D verifikation kommer vi att debitera 3kr för 7 dagars provperiod. Pengarna betalas tillbaka vid avslutad prenumeration.</p>
                         }
                    </div>
                        </div>

            <div className=' pb-10 wow fadeInDown'>
              <Button variant="primary" type="button" className='pay-btn w-sm-100 wow fadeInDown ' disabled={!selectedtc} onClick={handleSubmit}  >
              {t('Prenumerera')}

              </Button>
            </div>

            <div style={style}>
              {isLoading &&
                <div className="loading">
                  <div className="row">
                    <div className="spin">
                      <span className="one"></span>
                      <span className="two"></span>
                      <span className="three"></span>
                    </div>
                  </div>
                </div>
              }
            </div>
          </Form>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <h5> {t('Thanks_for_buying')}</h5>
          </Modal.Header>

          <Modal.Footer className="display-class">
            <Button variant="primary" type="button" className='font-18 min-200  w-sm-100 wow fadeInDown' onClick={handleClose}> {t('Fortsätt')} </Button>
          </Modal.Footer>
        </Modal>
      </>

    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderForm() : renderForm()}
      </div>
    </div>
  );
}
