import InnerHeader from "../public/InnerHeader";
import TextBlock from "../component/TextBlock";
import Header from "../commom/Header2";
import Wrapper from "../component/Wrapper";
import { Container } from "react-bootstrap";

const ReflektionerTip = () => {

    return (
        <div className="innerblock-page flex-grow-1">
            <Header headerShow={false} />
            <div className="page-wrapper flex-grow-1 page-block overflow-hidden">
                <InnerHeader title="Reflektioner" subtitle="Såhär använder du reflektionsverktyget" backButton={true} backButtonURL="/oversitk" />
                <Wrapper className="pb-20px">
                    <div className="shadow-block rounded-20 bg-white">
                        <div className="title-header p-20px">
                            <TextBlock title="Reflektioner" subtitle="Såhär använder du reflektionsverktyget" />
                        </div>
                        <div className="p-20px">
                            <p>Reflektion är en viktig del av lärandeprocessen. Genom att aktivt granska och analysera det vi lär oss ökar förståelsen och förmågan att tillämpa kunskap. Så om du stannar upp efter varje avsnitt och tänker efter, så ökar du din möjlighet till inlärning.</p>
                            <p className="mb-0">Vad tar jag med mig från detta avsnitt? </p>
                            <p className="mb-0">Vad var nytt, vad visste jag sedan innan? </p>
                            <p >Vad kan hjälpa mig att utvecklas ytterligare i min yrkesroll? </p>
                            <p>Vårt verktyg för anteckningar gör det möjligt att reflektera och skriva ner det du vill ha med dig från respektive avsnitt. Anteckningarna sparas sedan under respektive kurs och det är enkelt gå tillbaka och läsa igenom dem i efterhand.</p>
                        </div>
                    </div>
                </Wrapper>
            </div>
        </div>
    );
};

export default ReflektionerTip;
