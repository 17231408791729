import React, { useEffect, useState } from "react";
import { Table, Image, Button, Pagination } from "react-bootstrap";
import Header from "../commom/Header2";
import { useDispatch } from "react-redux";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import {
    getWebinarSignup
} from "../../store/api/podcast.api";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import { useParams } from "react-router-dom";
require("moment/locale/sw.js");

const WebinarSignup = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {id} = useParams()
    const [allEducation, setAllEducation] = useState();
    let dataFilter = {
       webinar_id:id
      };
    
      useEffect(() => {
        dispatch(getWebinarSignup(dataFilter)).then((course) => {
          setAllEducation(course.payload.data);
        });
      }, []);


      
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block product-listpage">
        <InnerHeader title={t("Skapa webinar")} backButton={true} backButtonURL="/webinar-history" />
        <Wrapper>
        
          {/* Starts Table */}
          <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
            <Table bordered responsive className="text-center tableblock-wrap mb-0">
              <thead className='top-bordernone'>
                <tr>
                 
                 
                  <th>{t("Sign up")}</th>
                  <th>{t("Sign up date")}</th>
                  <th>{t("Webinar date")}</th>
                  <th>{t("Webinar Time")}</th>
                  {/* <th>{t("Mail sent")}</th>
                  <th>{t("Replay Mail sent")}</th> */}
                </tr>
              </thead>
              <tbody>
                {allEducation?.map((product, key) => (
                  <tr key={product?.id}>
                  
                    <td>{product?.email}</td>
                    <td>{moment(product?.created_at).format(
                            "DD-MMM-YYYY HH:mm"
                          )}</td>
                    <td>{moment(product?.webinar_date).format(
                            "DD-MMM-YYYY"
                          )}</td>
                          <td>{moment.utc(product?.webinar_date).format(
                            "HH:mm"
                          )}</td>
                          {/* <td>{product?.is_mail_sent === 1?'Yes':'No' }</td>
                          <td>{product?.is_replay_sent === 1?'Yes':'No'}</td> */}
                    
                  </tr>
                ))}
              </tbody>
            </Table>
            {allEducation && allEducation?.length === 0 && (
              <p className="text-center mt-3">{t("No_records_found")}</p>
            )}
          </div>
         
        </Wrapper>
      </div>
    </div>
  );
};

export default WebinarSignup;
