import InnerHeader from "../public/InnerHeader";
import TextBlock from "../component/TextBlock";
import Header from "../commom/Header2";
import Wrapper from "../component/Wrapper";
import {useSelector } from "react-redux";


const goback = () => {

}
const Inlarning = () => {
    const user = useSelector((state) => state.auth.user);
    return (
        <div className="innerblock-page flex-grow-1">
            <Header headerShow={false} />
            <div className="page-wrapper flex-grow-1 page-block overflow-hidden">
            
                <InnerHeader title="Företagsöversikt" subtitle={user.business.name} backButton={true} backButtonURL="/company-dashboard" />
                <Wrapper className="pb-20px">
                    <div className="shadow-block rounded-20 bg-white">
                        <div className="title-header p-20px">
                            <TextBlock title="Maximera inlärning" subtitle="Få ut det mesta av Docens" />
                        </div>
                        <div className="p-20px">
                        <h6>Kompetensutveckling är viktigare nu än någonsin</h6>
                            <p> Ett utav det viktigaste redskapen för att behålla medarbetare idag är att utbilda dem. Att låta personalen vidareutbilda sig är att rusta för framtiden och visa dem uppskattning. Det här behöver inte alltid innebära långa, kostsamma utbildningar. Även kortare kurser kan förbättra deras kompetens, få dem att känna en ökad motivation och bli mer produktiva på jobbet. Men det är också viktigt att säkerställa att den investering ni lägger på utbildning också tas till vara, och bidrar till verksamhetens resultat.
                            <br></br><br></br>
                            Docens utbildningsmall är framtagen och utformad av en retorikexpert. Den är vetenskapligt designad för att maximera intresse och inlärning. Varje kurs byggs utifrån samma struktur och innehåller praktiska tips och övningar som kan användas direkt. Efter varje avsnitt finns möjlighet för reflektion, att skriva ner tankar och insikter som sen enkelt kan plockas fram för repetition. Plattformen gör det enkelt för dig att tilldela dina anställda relevanta kurser och att följa deras framsteg.
                            <br></br><br></br>
                            <h6>Du som chef är viktig</h6>
                            Det är inte bara kursens utformning som är viktig för att se till att  er utbildningsinsats verkligen tas till vara. Som chef är kan du påverka dina medarbetares framsteg och se till att den investering ni gör i utbildning verkligen blir lönsam. Det är viktigt att visa att du finns där kontinuerligt genom utbildningens gång, och att uppmuntra dina medarbetare att delta aktivt i utbildningen. 
                            <br></br><br></br>
                            Några konkreta tips på hur du kan stötta dina medarbetare är: 
                            <br></br><br></br>
                            - Ge dina medarbetare konkreta aktiviteter och övningar att göra för att stärka lärandet.
                            <br></br><br></br>
                            - Var närvarande under utbildningsinsatsen och ge dina medarbetare kontinuerligt stöd och feedback.
                            <br></br><br></br>
                            - Ställ frågor om hur medarbetarna tänker använda sin nya kunskap i praktiken och hur du kan stötta dem i det dagliga arbetet.
                            <br></br><br></br>
                            Som chef kan det vara svårt att veta exakt vad man behöver göra för att stötta medarbetarnas lärande. Genom att använda dessa tips, och vara närvarande och engagerad i dina medarbetares utveckling kan du göra stor skillnad för hur den nya kunskapen omsätts i beteenden och bidrar till verksamhetens resultat.
                            </p>
                            </div>
                    </div>
                </Wrapper>
            </div>
        </div>
    );
};

export default Inlarning;
