import React, { useRef, useEffect, useState } from 'react';
import { Container, Form, Button, Image, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import { addSingleEmployee, myPurchageList,getSingleUser,editSingleEmployee } from '../../store/api/course.api';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import moment from 'moment'
import {toast} from 'react-toastify';
import {omit} from 'lodash'
require('moment/locale/sw.js');
const AddUser = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   const [value, setValue] = useState({ firstname: "", lastname: "", code: "", phone: "", email: "" });
   let options = [];
   let courseId = [];
   const [allCources, setAllCources] = useState();
   const [assign_course,setAssign_course]= useState();
   const [singleUser, setSingleUser] = useState();
   const { business_id,role_id } = useSelector((state) => state.auth.user);
   const { message } = useSelector((state) => state.message);
   const [errors, setErrors] = useState({});
   const [expired_at, setExpired_at] = useState();
   let body = 
      {
         "business_id":business_id,
     }
   
   const handleClose = () => {
      setShow(false);
      prop.closeModal();
   }
   const onChange = (e) => {
      
      setValue({
         ...value,
         [e.target.name]: e.target.value,
      });
   };


   useEffect(() => {
      setShow(prop.show)
      
      dispatch(myPurchageList(body)).then((course) => {
         if ( role_id === 1) {
            setAllCources(course.payload.data.other);
         } else {
            setAllCources(course.payload.data.purches_courses.filter((item=> item.course_id !== 0)));
         }
      });
      if (prop.editId) {
         dispatch(getSingleUser(prop.editId)).then((res) => {
            setSingleUser(res.payload.data[0]);
            let selectedCourses = [];
            res.payload.data[0].assign_course.map((item, key) => (
               selectedCourses.push({'id':item.course_id,'name':item.course_title})
            ));
            setAssign_course(selectedCourses)
   
            setValue({
               ...value,
               ['firstname']: res.payload.data[0].first_name,
               ['lastname']: res.payload.data[0].last_name,
               ['email']: res.payload.data[0].email,
               ['phone']: res.payload.data[0].phone_no?res.payload.data[0].phone_no:'',
               ['code']: res.payload.data[0].tel_code,
             });
         })
      }
      

   }, [])
   const handlePhoneChange = (status, phone, country) => {
      validate('phone',phone);
      setValue({
         ...value,
         ['phone']: phone,
         ['code']: country.dialCode
      });
   };
   const selectedValue = () => {

   }

   const validate = ( name, value) => {
      switch (name) {
          case 'phone':
              if(!new RegExp( /^[0-9]{10}$/).test(value)
              ){
                
                 setErrors({
                  ...errors,
                  phone: <span> Enter valid 10 digit number</span> 
              })
              } else{
 
               let newObj = omit(errors, "phone");
               setErrors(newObj);
               
           }
          break;
          default:
              break;
      }
  }
   const onSelect = (selectedList, selectedItem) => {
     
      setAssign_course(selectedList)
   }
   const onRemove = (selectedList, removedItem) => {
      setAssign_course(selectedList)
   }
   // on press signup button
   const onAdd = () => {
      
      assign_course && assign_course.map(list =>
         courseId.push({course_id: list.id,business_id:business_id,expired_at: expired_at})
      )
      let body;
      body = {
        'first_name':value.firstname,
        'last_name':value.lastname,
        'email':value.email,
        'code':value.code,
        'phone_no':value.phone,
        'business_id':business_id,
        "assign_course":courseId
      }
      dispatch(addSingleEmployee(body))
         .unwrap()
         .then((res) => {
            prop.closeModal();
         })
         .catch((e) => {
            prop.closeModal();
           // toast.error(message)
         });
   };
   const onEdit = () => {
      
      assign_course && assign_course.map(list =>
         courseId.push({course_id: list.id,business_id:business_id,expired_at: expired_at })
      )
      let body;
      body = {
         "id":prop.editId,
        'first_name':value.firstname,
        'last_name':value.lastname,
        'email':value.email,
        'code':value.code,
        'phone_no':value.phone,
        'business_id':business_id,
        "assign_course":courseId
      }
      dispatch(editSingleEmployee(body))
         .unwrap()
         .then(() => {
            prop.closeModal();
         })
         .catch(() => {
            prop.closeModal();
         });
   }
   allCources && allCources.map(list =>
      options.push({ name: list.course_title, id: list.course_id })
   )

   return (
      <>
         <Modal show={show} onHide={handleClose} centered className="modal-innerbox ">
            <Modal.Header closeButton>
               <Modal.Title> {prop.editId? <span>{t('Edit_Employee')}</span> : <span>{t('Add_Employee')}</span>}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='usermanagement-modal'>
               <Form id="create-course-form">

                  <div className='row gy-10'>
                 
                     <Form.Group  controlId="formBasicfirstname">
                     <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">Namn</Form.Label>
                        <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' placeholder={t('First_Name')} name='firstname' aria-label='First Name' onChange={onChange} value={value.firstname}  />
                     </Form.Group>
                     <Form.Group  controlId="formBasiclastname">
                        <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' placeholder={t('Last_Name')}  name='lastname' aria-label='Last Name' onChange={onChange} value={value.lastname} />
                     </Form.Group>
                     {/* <Form.Group  controlId="formBasicEmail">
                        <IntlTelInput containerClassName="intl-tel-input" preferredCountries={['se']} inputClassName="form-control col-md-6" style={{ 'width': '100%' }} name='phone' value={value?.phone} onPhoneNumberChange={handlePhoneChange} />
                        { errors.phone && <span>{errors.phone}</span> }
                     </Form.Group> */}
                     <Form.Group  controlId="formBasicEmail">
                        <Form.Control type="email" className='fs-20 mobile-font-14 form-inputblock fw-light' placeholder={t('Email')} name='email' aria-label='Email' onChange={onChange} value={value.email} readOnly={true} />
                     </Form.Group>
                    
                     {allCources &&
                     <Multiselect
                        options={options}
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options,
                        selectedValues={assign_course} // Preselected value to persist in dropdown
                        showCheckbox
                     /> 
                  }
                  <Form.Group className="mb-10" controlId="formBasicPassword">
                     <div className='position-relative datepicker-block'>
                        <Image src='/assets/images/Calendar_add_light.png' alt='' className='datepicker-icon' />
                        <DatePicker className='text-black fs-20 mobile-font-14 form-inputblock fw-light black-theme' placeholderText={t('Set_Deadline')} selected={expired_at} onChange={(date) => setExpired_at(date)} minDate={moment().toDate()} />
                     </div>
                  </Form.Group>
                  </div>
                 
               </Form>

            </Modal.Body>
            <Modal.Footer>
               {!prop.editId &&
                     <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onAdd}> {t('Save')} </Button>
                        }
                        {prop.editId &&
                     <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onEdit}> {t('Update')} </Button>
                        }
            </Modal.Footer>
         </Modal>

      </>
   )
}

export default AddUser;