import React, { useEffect, useState } from 'react';
import {  Form, Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getExamById, verifyExam } from '../../store/api/course.api'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import Slider from "react-slick";

const AnswerTest = (props) => {
   const { id, courseid } = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [allQuestion, setAllQuestion] = useState();
   const { t } = useTranslation();
   const [showCongratulation, setShowCongratulation] = useState(false)
   const [certiUrl, setCertiUrl] = useState()
   let questionAnswers = [];
   const [show, setShow] = useState(false);
   const [showNotPassed, setShowNotPassed] = useState(false);
   const [pageIndex, setPageIndex] = useState(0)
   useEffect(() => {
      setShow(props.show);
      refresh();
   }, [])

   useEffect(() => {
      return () => {
         localStorage.removeItem("questionAnswers")
      }; 
   }, []);
   const refresh = () => {
      let data = {
         "filter": {
            "is_delete": 0,
            'exam_id': props.selectedExam
         }
      }
      dispatch(getExamById(data)).then((res) => {
         setAllQuestion(res.payload.data);
      })
   }
   const handleClose = () => {
      setShow(false);
      props.callbackModal();
   }
   // callback function to add item in array
   const upsert = (array, item) => { // (1)
      const i = array.findIndex(_item => _item.question_id === item.question_id);
      if (i > -1) array[i] = item; // (2)
      else array.push(item);
   }

   const onChangeValue = (e, question_id) => {
      if(localStorage.getItem('questionAnswers')){
         questionAnswers = JSON.parse(localStorage.getItem("questionAnswers"));
      }
      upsert(questionAnswers, { question_id: question_id, value: e.target.value })
      localStorage.setItem('questionAnswers',JSON.stringify(questionAnswers))
   }
   const submitExam = () => {
      let data = {
         exam_id: props.selectedExam,
         course_id: courseid,
         questionAnswers: JSON.parse(localStorage.getItem("questionAnswers"))
      }
      dispatch(verifyExam(data))
         .unwrap()
         .then((res) => {
            console.log(res)
            if (res.status === 200) {
               if (res.data.result === 100) {
                  // toast.success(res.message);
                  setShow(false);
                  setShowCongratulation(true);
                  //  setCertiUrl(res.data.url)
               } else {
                  setShow(false);
                  setShowNotPassed(true);

               }

            }
         })
         .catch((e) => {
            navigate('/private-open-course-topics/' + courseid);
            //  toast.error(e.message)
         });

   }
   const callbackModal = () => {
      setShowCongratulation(false);

   }
   const slider = React.useRef(null);
   
   const renderCustomDots = () => {
      return allQuestion?.map((_, index) => (
         <span key={index} onClick={() => slider.current.slickGoTo(index)} className={`custom-dot ${index === pageIndex ? 'active' : ''}`}>
            {index === pageIndex && index + 1}
         </span>
      ));
   };

   const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      customPaging: (i) => <div className='d-inline-flex align-items-center'>{i + 1}</div>,
   };

   const goToPrev = () => {
      slider.current.slickPrev();
   };

   const goToNext = () => {
      slider.current.slickNext();
   };
   return (
      <>
         {/* <div className="innerblock-page">
         <Header headerShow={false} />
        
         <div className='page-wrapper flex-grow-1 page-block'>
         <InnerHeader title={t('Users_List')} subtitle="" />
         <div className='py-lg-40px px-xl-100px px-lg-30px px-20px flex-grow-1'>
         {
                        allQuestion?.map((question,index) => (
                           <div key={index} className='text-black question-block font-20 fw-semibold mx-auto wow fadeInDown'>
                           <div className='question-info d-flex text-black'>
                              <span>{index +1}</span>
                              <p className='mb-0 text-black'>{question?.question}</p>
                           </div>
                           <div className='text-black answer-options answer-options' onChange={(e)=>onChangeValue(e,question.question_id)}>
                              <Form.Check 
                                 type="radio"
                                 id={'radio0'+index}
                                 label={question.option_1}
                                 name={'question'+index}
                                 value={question.option_1}
                                 
                              />
                              <Form.Check 
                                 type="radio"
                                 id={'radio1'+index}
                                 label={question.option_2}
                                 name={'question'+index}
                                 value={question.option_2}
                                 
                              />
                              <Form.Check 
                                 type="radio"
                                 id={'radio2'+index}
                                 label={question.option_3}
                                 name={'question'+index}
                                 value={question.option_3}
                                 
                              />
                              
                           </div>
                        </div>
                           ))
                        }
                        <div className='text' style={{'text-align':'center'}}>
                     {allQuestion && allQuestion.length > 0 &&
                      <Button variant="primary" type="button" className='text-center font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={submitExam}>
                      {t('Submit_Exam')}
                      </Button>
                      
                     }
                     </div>
                     {allQuestion && allQuestion.length===0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                  }
                    {showCongratulation &&
                  <Congratulations show={showCongratulation} certificate_url={certiUrl} callbackModal={callbackModal} />
               }
               </div>
         </div>
         </div> */}
         <Modal centered show={show} onHide={handleClose} className="modal-innerbox answer-testmodal">
            <Modal.Header closeButton>
               <Modal.Title>Slutprov</Modal.Title>
               <span>{props.courseName}</span>
            </Modal.Header>
            <Modal.Body className='p-0' >
               <div className="testimonial-block p-20 sliderbox-wrap mx-auto">
                  <Slider {...settings} className="sliderbox-wrap" ref={slider} beforeChange={(oldIndex, newIndex) => setPageIndex(newIndex)}>
                     {allQuestion?.map((question, index) => (
                        <div key={index} className='text-black question-block font-20 fw-normal mx-auto wow fadeInDown'>
                           <div className='question-info d-flex text-black m-0'>
                              <p className='mb-2 text-black text-start'>{index + 1}. {question?.question}</p>
                           </div>
                           <div className='text-black answer-options answer-options' >
                              <Form.Check
                                 type="radio"
                                 className='ms-0'
                                 id={'radio0' + index}
                                 label={question.option_1}
                                 name={'question' + index}
                                 value={question.option_1}
                                 onChange={(e) => onChangeValue(e, question.question_id)}

                              />
                              <Form.Check
                                 type="radio"
                                 className='ms-0'
                                 id={'radio1' + index}
                                 label={question.option_2}
                                 name={'question' + index}
                                 value={question.option_2}
                                 onChange={(e) => onChangeValue(e, question.question_id)}

                              />
                              <Form.Check
                                 type="radio"
                                 className='ms-0'
                                 id={'radio2' + index}
                                 label={question.option_3}
                                 name={'question' + index}
                                 value={question.option_3}
                                 onChange={(e) => onChangeValue(e, question.question_id)}

                              />

                           </div>
                          
                        </div>
                     ))
                     }
                  </Slider>
                  {/* Manual placement of prev, next, and dots within the track */}
                  <div className='manual-slider-action d-flex align-items-center justify-content-md-center justify-content-between'>
                     <div className='gap-4 d-flex align-items-center justify-content-center'>
                        <button onClick={goToPrev} className='slick-arrow slick-prev'>
                           Prev
                        </button>
                        <div className="custom-dots text-black">
                           {renderCustomDots()}
                        </div>
                        {allQuestion && pageIndex+1 != allQuestion.length  &&
                        <button onClick={goToNext} className='slick-arrow slick-next'>
                           Next
                        </button>
}
                     </div>
                     <div className='last-button'>
                        {allQuestion && pageIndex+1 === allQuestion.length  &&
                           <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark" onClick={submitExam}>Spara</Button>
                        }
                     </div>
                  </div>
               </div>
               {allQuestion && allQuestion.length === 0 &&
                  <p className='text-center text-svart'>{t('No_records_found')}</p>
               }
            </Modal.Body>


         </Modal>

         {showNotPassed &&
            <Modal centered show={showNotPassed} onHide={handleClose} className="modal-innerbox ">
               <Modal.Header closeButton className="closeclass-block">
                  <Modal.Title>Du klarade inte provet</Modal.Title>
               </Modal.Header>
               <Modal.Body className="p-20px ">
                  <span className="text-start fw-light text-svart block d-block">Du klarade tyvärr inte provet men försök gärna igen! 👊🏼</span>

               </Modal.Body>
            </Modal>
         }

         {showCongratulation &&
            // <Congratulations show={showCongratulation} certificate_url={certiUrl} callbackModal={callbackModal} />
            <Modal centered show={showCongratulation} onHide={handleClose} className="modal-innerbox ">
               <Modal.Header closeButton className="closeclass-block">
                  <Modal.Title>Du klarade provet</Modal.Title>
               </Modal.Header>
               <Modal.Body className="p-20px ">
                  <span className="text-start fw-light text-svart block d-block">Grattis! Du hittar ditt diplom under fliken “Diplom”. 🤩</span>

               </Modal.Body>
            </Modal>
         }
      </>

   )
}

export default AnswerTest;