import { useEffect, useState } from "react";
import { Button, Container, Form, Navbar, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import { verifyPassset,passwordRegister } from "../../store/api/course.api";
import { toast } from "react-toastify";
const passwordForWebinar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token,slug } = useParams();
  const tokenData = JSON.parse(atob(token)); // decode your token here

  const [userEmail, setEmail] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
   
   setEmail(tokenData.email);

   let pwdObj = {
    email: tokenData.email,
  };
  dispatch(verifyPassset(pwdObj)).then((res) => {
    if (res.payload?.status == 200) {
     navigate("/logga-in/" + slug + "/" + tokenData.option);
    }
  });
  }, []);
  const onClickRegister = () => {
    if (
      !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(password)
    ) {
      setErrors({
        ...errors,
        password: <>{t("Password_should")}</>,
      });
    } else {
      let newObj = omit(errors, "password");
      setErrors(newObj);
    
      let pwdObj = {
        email: userEmail,
        password: password,
      };
      dispatch(passwordRegister(pwdObj)).then((res) => {
        localStorage.setItem("user", JSON.stringify(res.payload.data));
        // navigate("/onboarding/select-type", {
        //   state: { email: userEmail,password: password },
        // });
          navigate("/view-webinar/" + slug + "/" + tokenData.option);
      });
    }
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div className="onboarding-page">
      <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
      <div className="header position-fixed top-0 start-0 end-0">
        <Container fluid>
          <Navbar.Brand href="/">
            <svg
              id="Lager_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 710.11 134.41"
            >
              <defs>
                <style>{"fill:#fff;"}</style>
              </defs>
              <g id="Lager_1-2">
                <g>
                  <path
                    className="cls-1"
                    d="M106.99,68.38c0,20.93-7.04,39.33-20.57,50.7-9.56,7.93-22.01,12.45-38.25,12.45H.25c-.14,0-.25-.11-.25-.25v-26.51c0-23.3,9.26-45.65,25.73-62.12h0c.16-.16,.43-.05,.43,.18V108.72c0,.14,.11,.25,.25,.25h19.96c24.53,0,34.1-14.97,34.1-40.59s-11.19-43.48-33.38-43.48H.25c-.14,0-.25-.11-.25-.25V2.78c0-.14,.11-.25,.25-.25H50.34c34.46,0,56.65,27.78,56.65,65.85Z"
                  />
                  <path
                    className="cls-1"
                    d="M117.63,67.29C117.63,28.69,141.81,0,179.88,0s62.06,28.69,62.06,67.29-23.99,67.11-62.06,67.11-62.24-28.51-62.24-67.11Zm97.6,0c0-25.8-11.55-45.83-35.18-45.83s-35.72,20.03-35.72,45.83,12.09,45.65,35.72,45.65,35.18-20.03,35.18-45.65Z"
                  />
                  <path
                    className="cls-1"
                    d="M250.59,67.48C250.59,29.23,274.4,0,312.47,0c31.75,0,50.52,18.94,53.22,43.48h-25.98c-2.35-12.99-12.09-21.47-27.24-21.47-23.81,0-35.18,19.85-35.18,45.47s13.71,45.28,35.36,45.28c15.34,0,26.16-9.2,27.78-22.73h25.62c-.72,11.37-5.59,22.37-14.25,30.49-8.84,8.3-21.29,13.71-39.15,13.71-36.26,0-62.06-27.96-62.06-66.75Z"
                  />
                  <path
                    className="cls-1"
                    d="M378.57,2.53h94.9V24.54h-68.74v28.87h60.26v21.47h-60.26v34.82h69.28v21.83h-95.44V2.53Z"
                  />
                  <path
                    className="cls-1"
                    d="M488.23,2.53h26.52l42.94,71.99c4.15,7.04,9.02,18.04,9.02,18.04h.36s-.72-13.35-.72-22.37V2.53h25.8V131.52h-25.26l-44.02-71.26c-4.15-6.86-9.2-17.86-9.2-17.86h-.36s.72,13.53,.72,22.55v66.57h-25.8V2.53Z"
                  />
                  <path
                    className="cls-1"
                    d="M604.21,91.65h25.62c1.8,15.52,10.64,21.65,29.05,21.65,13.35,0,25.08-4.69,25.08-16.6,0-12.63-12.27-15.16-31.93-19.67-23.09-5.23-44.2-11.37-44.2-38.25,0-25.44,20.75-38.61,49.43-38.61s47.81,14.25,49.79,40.23h-25.08c-1.44-12.99-11.55-19.48-24.9-19.48-14.07,0-23.27,5.95-23.27,15.15,0,10.46,9.02,13.53,28.14,17.68,26.52,5.77,48.17,12.09,48.17,39.87,0,26.16-21.11,40.77-50.15,40.77-35,0-54.85-15.52-55.75-42.76Z"
                  />
                </g>
              </g>
            </svg>
          </Navbar.Brand>
        </Container>
      </div>
      <Container fluid>
        <div className="text-white maxw-507">
          <div className="block mb-8">
            <h1 className="font-32 fw-semibold">Ett sista steg...</h1>
           
          </div>
         
          <Form className="mb-8 signup-form" onSubmit={e => { e.preventDefault(); }}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="font-24 fw-medium mb-2  mobile-font-14">
              Vad vill du ha för lösenord?
              </Form.Label>
              <div className="form-group">
                <Form.Control
                  type={passwordType}
                  name="password"
                  placeholder="Tänk på något unikt..."
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  variant="light"
                  type="button"
                  className="nohover"
                  onClick={togglePassword}
                >
                  {" "}
                  {passwordType === "password" ? "Visa" : "Hide"}
                </Button>
                {errors.password && (
                  <span style={{ marginLeft: 10, marginTop: 5 }}>
                    {errors.password}
                  </span>
                )}
              </div>
            </Form.Group>
          </Form>
          <Button
            variant="primary"
            disabled={userEmail == ""}
            className=""
            onClick={onClickRegister}
          >
            Se webinar
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default passwordForWebinar;
