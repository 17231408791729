import React, { useEffect, useState } from "react";
import { Table, Image, Button, Pagination } from "react-bootstrap";
import Header from "../commom/Header2";
import { useDispatch } from "react-redux";
import { uniq } from 'lodash';
import { useTranslation } from "react-i18next";
import {
  getWebinarWatcher
} from "../../store/api/podcast.api";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import { useParams } from "react-router-dom";
import moment from 'moment'

require("moment/locale/sw.js");

const WebinarWacher = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {id,type} = useParams();
    const _ = require('lodash');
    const [allEducation, setAllEducation] = useState();
    let dataFilter = {
       filter: {
        'w.webinar_id':id,
        is_deleted:0
       } 
      };
    if(type === "2"){
      dataFilter.filter.is_complete = 1;
    } 
    if(type === "3" ) {
      dataFilter.filter.is_reply = 1;
    }
      useEffect(() => {
        dispatch(getWebinarWatcher(dataFilter)).then((course) => {
          setAllEducation(_.uniqBy(course.payload.data, 'user_id'));
        });
      }, []);


      
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block product-listpage">
        <InnerHeader title={t("Skapa webinar")} backButton={true} backButtonURL="/webinar-history" />
        <Wrapper>
        
          {/* Starts Table */}
          <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
            <Table bordered responsive className="text-center tableblock-wrap mb-0">
              <thead className='top-bordernone'>
                <tr>
                 
                 
                  <th>{t("Users")}</th>
                  <th>Datum</th>
                </tr>
              </thead>
              <tbody>
                {allEducation?.map((product, key) => (
                  <tr key={product?.id}>
                  
                    <td>{product?.email}</td>
                    <td>{moment(product?.created_at).format(
                            "DD-MMM-YYYY HH:mm"
                          )}</td>
                    
                  </tr>
                ))}
              </tbody>
            </Table>
            {allEducation && allEducation?.length === 0 && (
              <p className="text-center mt-3">{t("No_records_found")}</p>
            )}
          </div>
         
        </Wrapper>
      </div>
    </div>
  );
};

export default WebinarWacher;
