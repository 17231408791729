import { Accordion, Button, Col, Container, Image, Row, Navbar } from "react-bootstrap";
import Footer from "../commom/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WebinarContactForm from "../commom/WebinarContactForm";
import moment from 'moment'
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDates,singleWebinar } from "../../store/api/podcast.api";
import { sitleft } from "../commom/commonConst";
import { isMobile } from "react-device-detect";
const WebinarDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {id} =useParams();
  const [educationData, setEducationData] = useState();
  const [show, setShow] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const { role_id } = useSelector((state) => state?.auth?.user || {});
  const navigate = useNavigate();
  const [deadline, setDeadline] = useState();
  const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [displayTimer, setDisplayTimer] = useState(true);
    const [value, setValue] = useState({ chat_text: "" });
  const onCLickGotoContactUs = () => {
    setShow(!show)
    // navigate("/kontakta-oss-utbildning");
  };
  const callbackModal = () => {
    setIsShowForm(false);
  };
  const showForm1 = () => {
    setIsShowForm(true);
    scrollToSection('menuid');
  }
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };
  const getTime = (date) => {
    let dt = new Date();
    dt.setHours(dt.getHours() + 2);
    const time = Date.parse(date) - dt;
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
    if (time <= 0) {
        setDisplayTimer(false);
    } else {
        setDisplayTimer(true)
    }
};

useEffect(() => {
  dispatch(getDates()).then((res) => {
    setDeadline(res.payload?.data[0]);
})
let data = {
  slug: id
}
dispatch(singleWebinar(data)).then((course) => {
  setEducationData(course.payload?.data[0]);
});
}, []);
useEffect(() => {
  if (deadline) {
      const interval = setInterval(() => getTime(moment(Number(deadline)).format("YYYY-MM-DD H:mm")), 1000);
      return () => clearInterval(interval);
   
  }
}, [deadline]);
  return (

    <>
      <div className="d-flex flex-column flex-grow-1 homepage ">
        {/* {role_id && <Header />}
        {!role_id && <Header1 />} */}
      

        <div className="main-wrapper page-wrapper py-0">

          <section className="pb-100 webinar-banner-block border-0 mobile-pt-15 banner-wrapper position-relative bg-wrap d-block">
            <Container fluid>
              <div className="banner-content-area">
                <div className="text-center pt-4 pb-14">
                  <Navbar.Brand href="/" className="mx-auto">
                    {/* <Image src="/assets/images/logo.svg" className="navbar-brand-img" alt="DOCENS" width="105" height="48" /> */}
                    <svg
                      id="Lager_2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 710.11 134.41"
                    >
                      <defs>
                        <style>{"fill:#fff;"}</style>
                      </defs>
                      <g id="Lager_1-2">
                        <g>
                          <path
                            className="cls-1"
                            d="M106.99,68.38c0,20.93-7.04,39.33-20.57,50.7-9.56,7.93-22.01,12.45-38.25,12.45H.25c-.14,0-.25-.11-.25-.25v-26.51c0-23.3,9.26-45.65,25.73-62.12h0c.16-.16,.43-.05,.43,.18V108.72c0,.14,.11,.25,.25,.25h19.96c24.53,0,34.1-14.97,34.1-40.59s-11.19-43.48-33.38-43.48H.25c-.14,0-.25-.11-.25-.25V2.78c0-.14,.11-.25,.25-.25H50.34c34.46,0,56.65,27.78,56.65,65.85Z"
                          />
                          <path
                            className="cls-1"
                            d="M117.63,67.29C117.63,28.69,141.81,0,179.88,0s62.06,28.69,62.06,67.29-23.99,67.11-62.06,67.11-62.24-28.51-62.24-67.11Zm97.6,0c0-25.8-11.55-45.83-35.18-45.83s-35.72,20.03-35.72,45.83,12.09,45.65,35.72,45.65,35.18-20.03,35.18-45.65Z"
                          />
                          <path
                            className="cls-1"
                            d="M250.59,67.48C250.59,29.23,274.4,0,312.47,0c31.75,0,50.52,18.94,53.22,43.48h-25.98c-2.35-12.99-12.09-21.47-27.24-21.47-23.81,0-35.18,19.85-35.18,45.47s13.71,45.28,35.36,45.28c15.34,0,26.16-9.2,27.78-22.73h25.62c-.72,11.37-5.59,22.37-14.25,30.49-8.84,8.3-21.29,13.71-39.15,13.71-36.26,0-62.06-27.96-62.06-66.75Z"
                          />
                          <path
                            className="cls-1"
                            d="M378.57,2.53h94.9V24.54h-68.74v28.87h60.26v21.47h-60.26v34.82h69.28v21.83h-95.44V2.53Z"
                          />
                          <path
                            className="cls-1"
                            d="M488.23,2.53h26.52l42.94,71.99c4.15,7.04,9.02,18.04,9.02,18.04h.36s-.72-13.35-.72-22.37V2.53h25.8V131.52h-25.26l-44.02-71.26c-4.15-6.86-9.2-17.86-9.2-17.86h-.36s.72,13.53,.72,22.55v66.57h-25.8V2.53Z"
                          />
                          <path
                            className="cls-1"
                            d="M604.21,91.65h25.62c1.8,15.52,10.64,21.65,29.05,21.65,13.35,0,25.08-4.69,25.08-16.6,0-12.63-12.27-15.16-31.93-19.67-23.09-5.23-44.2-11.37-44.2-38.25,0-25.44,20.75-38.61,49.43-38.61s47.81,14.25,49.79,40.23h-25.08c-1.44-12.99-11.55-19.48-24.9-19.48-14.07,0-23.27,5.95-23.27,15.15,0,10.46,9.02,13.53,28.14,17.68,26.52,5.77,48.17,12.09,48.17,39.87,0,26.16-21.11,40.77-50.15,40.77-35,0-54.85-15.52-55.75-42.76Z"
                          />
                        </g>
                      </g>
                    </svg>
                  </Navbar.Brand>
                </div>
                <Row className="gy-3 align-items-center">
                  <Col md={6}>
                    <div className="text-md-start text-center">
                      <span className="text-white d-block font-24 letter-spacing fw-light  mb-sm-8 mb-4">
                        Webinar
                      </span>
                      <h1 className="text-white font-64 fw-semibold mb-sm-0 mb-2">
                        {educationData?.title}
                      </h1>
                      <p className="text-white fw-light font-24  mb-sm-8 mb-3">
                        {educationData?.sub_title}
                      </p>
                      {displayTimer && educationData?.is_no_timer === 0 &&
                      <div className="d-md-flex d-none align-items-center gap-5">
                        <div className='d-flex align-items-center datepicker-block'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-5">
                            <g opacity="0.7">
                              <rect x="3" y="6" width="18" height="15" rx="2" stroke="white" stroke-width="2"/>
                              <path d="M3 10C3 8.11438 3 7.17157 3.58579 6.58579C4.17157 6 5.11438 6 7 6H17C18.8856 6 19.8284 6 20.4142 6.58579C21 7.17157 21 8.11438 21 10H3Z" fill="white"/>
                              <path d="M7 3L7 6" stroke="white" stroke-width="2" stroke-linecap="round"/>
                              <path d="M17 3L17 6" stroke="white" stroke-width="2" stroke-linecap="round"/>
                              <rect x="7" y="12" width="4" height="2" rx="0.5" fill="white"/>
                              <rect x="7" y="16" width="4" height="2" rx="0.5" fill="white"/>
                              <rect x="13" y="12" width="4" height="2" rx="0.5" fill="white"/>
                              <rect x="13" y="16" width="4" height="2" rx="0.5" fill="white"/>
                            </g>
                          </svg>
                            <p className="text-white fw-light font-24 mobile-font-14 mb-0 ms-2"> Börjar om 0{days}: {hours>9?hours:'0'+hours}: {minutes>9?minutes:'0'+minutes}: {seconds>9?seconds:'0'+seconds}</p> 
                        </div>
                        
                        <div className='d-flex align-items-center datepicker-block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-5">
                          <path d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" stroke="#B1B1B5" stroke-width="2" stroke-linecap="round"/>
                          <circle cx="12" cy="8" r="4" stroke="#B1B1B5" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                            <p className="text-white fw-light font-24 mobile-font-14 mb-0 ms-2">{(educationData?.platser-sitleft(hours,educationData?.platser))}  Platser kvar</p> 
                        </div>
                      </div>
                      }
                      <div className="individule_button mt-4 d-md-block d-none">
                        <Button className="btn-small font-20 px-8 rounded-pill"  onClick={showForm1} variant="gradient">
                          <span>Säkra din plats</span>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="h-375">
                      <Image
                        src={educationData?.image}  
                        alt=""
                        className="rounded-20  img-fit"
                      />
                    </div>
                    <div className="individule_button mt-4 d-md-none d-block w-100">
                      <Button className="btn-small font-20 px-8 rounded-pill w-100"  onClick={showForm1} variant="gradient">
                        <span>Säkra din plats</span>
                      </Button>
                    </div>
                    {educationData?.is_no_timer === 0 &&
                    <div className="d-md-none d-flex align-items-center justify-content-center gap-5">
                        <div className='d-flex align-items-center datepicker-block'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-5">
                            <g opacity="0.7">
                              <rect x="3" y="6" width="18" height="15" rx="2" stroke="white" stroke-width="2"/>
                              <path d="M3 10C3 8.11438 3 7.17157 3.58579 6.58579C4.17157 6 5.11438 6 7 6H17C18.8856 6 19.8284 6 20.4142 6.58579C21 7.17157 21 8.11438 21 10H3Z" fill="white"/>
                              <path d="M7 3L7 6" stroke="white" stroke-width="2" stroke-linecap="round"/>
                              <path d="M17 3L17 6" stroke="white" stroke-width="2" stroke-linecap="round"/>
                              <rect x="7" y="12" width="4" height="2" rx="0.5" fill="white"/>
                              <rect x="7" y="16" width="4" height="2" rx="0.5" fill="white"/>
                              <rect x="13" y="12" width="4" height="2" rx="0.5" fill="white"/>
                              <rect x="13" y="16" width="4" height="2" rx="0.5" fill="white"/>
                            </g>
                          </svg>
                            <p className="text-white fw-light font-24 mobile-font-14 mb-0 ms-2"> Börjar om 0{days}:{hours>9?hours:'0'+hours}:{minutes>9?minutes:'0'+minutes}:{seconds>9?seconds:'0'+seconds}</p> 
                        </div>
                        <div className='d-flex align-items-center datepicker-block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-5">
                          <path d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" stroke="#B1B1B5" stroke-width="2" stroke-linecap="round"/>
                          <circle cx="12" cy="8" r="4" stroke="#B1B1B5" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                            <p className="text-white fw-light font-24 mobile-font-14 mb-0 ms-2"> {(educationData?.platser-sitleft(hours,educationData?.platser))} Platser kvar</p> 
                        </div>
                    </div>
                    }
                  </Col>
                </Row>
              </div>
              <div className="banner-video">
                <video
                  src="/assets/video/Background-flow.mp4"
                  autoplay="autoplay"
                  playsinline="playsinline"
                  muted="muted"
                  loop="loop"
                ></video>
              </div>
            </Container>
          </section>
          <Container fluid>
            <section className={`d-flex flex-wrap  ${!isMobile ? "pt-100" : ""} `} id={`${!isMobile ? "menuid" : ""}`}>
              <div className="text-white content-block block  font-20 mobile-font-13 mobile-pb-60">
                <div
                  className="mb-13 fw-lighter webinar-section-text"
                  dangerouslySetInnerHTML={{
                    __html: educationData?.description,
                  }}
                />

              </div>
              <div className={`${isShowForm ? "width-461" : ""} ${isMobile ? " " : ""}`}   id={`${isMobile ? "menuid" : ""}`}>
                {isShowForm &&
                  <WebinarContactForm educationData={educationData} callbackModal={callbackModal} />
                }
              </div>
              {!isShowForm &&
                <div className="p-md-32 p-6 bg-darkblock flex-shrink-0 text-white fw-lighter font-16 width-461 set-height">
                  <h2 className="text-white font-32 fw-lighter">
                    {educationData?.title}
                  </h2>
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">
                    Ingår i webinar:
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: educationData?.what_you_will_learn,
                      }}
                      className="mb-0 webinar-section-text"
                    />
                  </div>
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">
                    Det här webinariet är för dig som:
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: educationData?.suitable_for,
                      }}
                      className="mb-0 webinar-section-text"
                    />
                  </div>
                  {educationData?.is_no_timer === 0 &&
                  <>
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">Datum</h3>
                    <p className="mb-0"> {moment(new Date).format("YYYY-MM-DD")}</p>
                  </div>
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">Tid</h3>
                    <p className="mb-0">13:00 & 19:00</p>
                  </div>
                  </>
                  }
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">Längd</h3>
                    <p className="mb-0">{educationData?.length} minuter</p>
                  </div>
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">Lärare</h3>
                    <p className="mb-0">{educationData?.creator_name}</p>
                  </div>
                  {displayTimer && educationData?.is_no_timer === 0 &&
                  <div>
                    <h3 className="fw-medium font-20 mobile-font-16 mb-2">Platser kvar</h3>
                    <p className="mb-0">{(educationData?.platser-sitleft(hours,educationData?.platser))}</p>
                  </div>
                    }
                  <Button
                    onClick={showForm1}
                    variant="gradient"
                    className="w-100 btn-small rounded-pill mt-md-2 mt-0 mb-1"
                    type="button"
                  >
                    <span>Säkra din plats</span>
                  </Button>
                </div>
              }
            </section>
          </Container>
        </div>
        <Footer isBlack={false} />
      </div>


    </>
  );
};

export default WebinarDetails;
