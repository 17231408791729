import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import { getWebinar,getDates } from "../../store/api/podcast.api";
import { sitleft } from "../commom/commonConst";

const PrivateWebinarListList = () => {
  const dispatch = useDispatch();
  const { role_id, id } = useSelector((state) => state?.auth?.user || {});
  const [allEducation, setAllEducation] = useState();
  const [limit,setLimit] = useState(4);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deadline, setDeadline] = useState();
  const [hours, setHours] = useState(0);
  const [displayTimer, setDisplayTimer] = useState(true);
  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    },
    user_id: id ? id : null,
    role_id: role_id,
  };

  useEffect(() => {
    dispatch(getWebinar(dataFilter)).then((course) => {
      setAllEducation(course.payload.data);
    });
    dispatch(getDates()).then((res) => {
      setDeadline(res.payload?.data[0]);
  })
  }, []);

  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };

  const onCLickReadMore = (education) => {
    navigate("/private-webinar-individual/" + education.slug);
  };

  const getTime = (date) => {
    let dt = new Date();
    dt.setHours(dt.getHours() + 2);
    const time = Date.parse(date) - dt;
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    if (time <= 0) {
      setDisplayTimer(false);
  } else {
      setDisplayTimer(true)
  }
};
useEffect(() => {
    if (deadline) {
        const interval = setInterval(() => getTime(moment(Number(deadline)).format("YYYY-MM-DD H:mm")), 1000);
        return () => clearInterval(interval);
    }
  }, [deadline]);
  return (
    <>
      <section>
        <div className="text-darkblock">


          {allEducation?.length > 0 &&
            allEducation?.slice(0, limit)?.map((item) => {
              return (
                <div className="titleheader-box">
                  <div className="d-flex w-100 align-items-center-mob position-relative bg-white title-header gap-40 p-lg-20px p-16px py-md-20px py-2" onClick={() => onCLickReadMore(item)}>
                    <div className="teamimg-wrap flex-shrink-0 ">
                      <Image src={item?.image} alt="Utbildning" className="w-100 h-100 img-fit" />
                    </div>

                    <div className="d-md-flex d-block flex-column w-100">
                      <span className="fw-semibold d-md-block d-none mb-2">Webinar </span>
                      <h3 className="font-32 fw-light font-32 mb-2 education-title"> {item?.title}</h3>
                      <div className="block font-20 fw-light blockinfo-content d-md-block d-none  mb-18px">
                        <div className="content-blokcwysiwg block"
                          dangerouslySetInnerHTML={{
                            __html: add3Dots(item?.short_discription, 500),
                          }}
                        ></div>
                      </div>
                      <div className="d-md-flex d-none align-items-center justify-content-between gap-2 mt-auto w-100">
                        <div className="d-flex gap-20px">
                         {item?.is_no_timer === 0 &&
                          <div className="fw-light font-16 text-svart d-md-block d-none"><span className="fw-semibold d-block">Dag</span> <span className="d-inline-block">{moment(new Date).format("YYYY-MM-DD")}</span></div>
                        }
                        {item?.is_no_timer === 0 &&
                          <div className="fw-light font-16 text-svart subtext-block "><span className="fw-semibold d-block">Tid</span> <span className="d-inline-block">13:00 & 19:00</span></div>
                        }
                          <div className="fw-light font-16 text-svart d-md-block d-none"><span className="fw-semibold d-block">Längd</span> <span className="d-inline-block">{item?.length} minuter</span></div>
                          {displayTimer && item?.is_no_timer === 0 &&
                          <div className="fw-light font-16 text-svart subtext-block "><span className="fw-semibold d-block">Platser kvar</span> <span className="d-inline-block">{(item?.platser-sitleft(hours,item?.platser))}</span></div>
                          }
                          </div>
                        <Button variant="primary" className=" mobile-left  d-md-block d-none flex-shrink-0" onClick={() => onCLickReadMore(item)}>Läs mer</Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="text-center pb-lg-20px pb-16px">
            {limit > 4 &&
              <Button onClick={()=> setLimit(100)} variant="link" className="readmore-btn">Visa mer</Button>
            }              
          </div>
        </div>

      </section>
    </>
  );
};
export default PrivateWebinarListList;
