import React, { useEffect, useState, useRef, Fragment } from "react";
import Header from "../commom/Header2";
import { Row, Col, Image, Button, Modal, Form, Table } from "react-bootstrap";
import InnerHeader from "./InnerHeader";
import { getDates,singleWebinar,sendChat,getMyChat,webinarWatch,pressReadMore } from "../../store/api/podcast.api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import Player from "@vimeo/player";
const WebinarExcel = () => {
  const dispatch = useDispatch();
  const [deadline, setDeadline] = useState();
  const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [displayTimer, setDisplayTimer] = useState(true);
    const [value, setValue] = useState({ chat_text: "" });
    const [oldChat,setOldChat]= useState();
    const [percentage, setPercentage] = useState(0);
    const [numberofwatcher,setNumberofwatcher] = useState(0)
    const user = useSelector((state) => state.auth.user);
  const [webinarData,setWebinarData]= useState();
  const { id, option,replay } = useParams();
  var iframe;
  var player;
    useEffect(() => {
      dispatch(getDates()).then((res) => {
        if (option === 'Option01') {
          setDeadline(res.payload?.data[0]);
        } else {
          setDeadline(res.payload.data[1]);
        }
    })
    let data = {
      slug: id
    }
    dispatch(singleWebinar(data)).then((course) => {
        setWebinarData(course.payload?.data[0]);
    });
    refresh();
    }, []);
    let scd;
    let totalLength;
    const getTime = (date) => {
      let dt = new Date();
      dt.setHours(dt.getHours() + 2);
      const time = Date.parse(date) - dt;
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
      if (time <= 0) {
          setDisplayTimer(false);
      } else {
          setDisplayTimer(true)
      }
      iframe = document.querySelector("#videoIframe");
      if (iframe?.src) {
        player = new Player(iframe);
        player
          .getDuration()
          .then(function (duration) {
            totalLength = duration;
          })
          .catch(function (error) {
            // an error occurred
          });
        player.getCurrentTime().then(function (seconds) {
          scd = seconds;
          setPercentage((scd / totalLength) * 100);
        });
 


      }
  };
  useEffect(() => {
    if (deadline) {
        const interval = setInterval(() => getTime(moment(Number(deadline)).format("YYYY-MM-DD H:mm")), 1000);
        return () => clearInterval(interval);
    }
}, [deadline]);

useEffect(() => {
  
  if(percentage > 0 && percentage < 20){
    setNumberofwatcher(Number.parseInt((webinarData?.platser*50)/100))
  } else if(percentage >20 && percentage < 80) {
    setNumberofwatcher(Number.parseInt((webinarData?.platser*70)/100))
  } else if(percentage >80 && percentage < 99) {
    setNumberofwatcher(Number.parseInt((webinarData?.platser*35)/100))
  } else if(percentage >99 ) {
    setNumberofwatcher(0)
  }

        if (percentage > 10 && percentage <11 ) {
          let data = {
            user_id: user.id,
            webinar_id: webinarData?.id,
            view_time: percentage,
          };
          dispatch(webinarWatch(data))
            .unwrap()
            .then(() => { })
            .catch(() => { });
        }
        if (percentage > 90 && percentage <91 ) {
          let data = {
            user_id: user.id,
            webinar_id: webinarData?.id,
            view_time: percentage,
            is_complete: true,
          };
          dispatch(webinarWatch(data))
            .unwrap()
            .then(() => { })
            .catch(() => { });
        }
        
      if(replay && replay === 'true') {
        if (percentage > 10 && percentage <11 ) {
          let data = {
            user_id: user.id,
            webinar_id: webinarData?.id,
            view_time: percentage,
            is_reply: true,
          };
          dispatch(webinarWatch(data))
            .unwrap()
            .then(() => { })
            .catch(() => { });
        }
      }
        
}, [percentage]);

const refresh = () => {
  if(webinarData && webinarData.id) {
    let getChatData = {
      webinar_id: webinarData?.id,
      user_id: user.id
    }
    dispatch(getMyChat(getChatData)).then((res) => {
      setOldChat(res?.payload?.data)
  });
  }
}
useEffect(() => {
  refresh();
}, [webinarData]);
const onChange = (e) => {
   setValue({
     ...value,
     [e.target.name]: e.target.value,
   });
 };
const sendMessage =() =>{
  let data = {
    webinar_id: webinarData?.id,
    chat_text: value.chat_text,
    user_id:user.id
  }

  dispatch(sendChat(data)).then((course) => {
    //  setWebinarData(course.payload?.data[0]);
      setValue({ chat_text: "" });
      refresh();
  });
}

const openUrl =(url)=>{
 
  let data = {
    webinar_id: webinarData?.id,
    user_id:user.id
  }

  dispatch(pressReadMore(data)).then((res) => {
    if (res.payload.status === 200) {
      window.open(url,'_blank')
    }
  });
}

  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
        <InnerHeader title={replay && replay === 'true'?'Repris':webinarData?.title} subtitle={replay && replay === 'true' ? '':webinarData?.sub_title} backButton={true} backButtonURL="/private-webinar"  />
        <div className="py-lg-40px px-xxl-100px wrapper-main  px-lg-30px px-20px flex-grow-1 ">
          <Row className="g-7">
            <Col md={7}>
            {((!displayTimer && deadline) || replay && replay === 'true' || webinarData?.is_no_timer === 1) &&
               <div className="webinar-details-imgbox">
                <iframe className="w-100 h-100 rounded-20 img-fit" id="videoIframe" width="560" height="100%"  src={webinarData?.video_url} title="YouTube video player" frameborder="0" allow="fullscreen;" ></iframe>
              </div>
                } 
                {((displayTimer && deadline) && (!replay || replay !== 'true') && webinarData?.is_no_timer === 0) &&
                <div className="img-overlay-content rounded-20 position-relative webinar-details-imgbox">
                  <Image
                    src={webinarData?.image}
                    alt="Egna_interna"
                    className="w-100 h-100 rounded-20 img-fit "
                  />
                  <div className="overlay-info">
                    <span className="d-block mobile-font-18">Webinar startar om</span>
                    <h2 className="mb-0 font-64 fw-semibold">0{days}: {hours>9?hours:'0'+hours}: {minutes>9?minutes:'0'+minutes}: {seconds>9?seconds:'0'+seconds}</h2>
                  </div>
                </div>
                }
              
              <div className="p-md-10px shadow-block rounded-20 d-md-flex d-none gap-5 mt-20px bg-white align-items-center">
                <div className="d-flex text-svart align-items-center fs-20 fw-medium bg-grey-block py-3 px-6 rounded-20 flex-grow-1">
                  <span className="flex-grow-1 text-center font-20">{webinarData?.title}
                  </span>
                </div>
                <a onClick={()=>openUrl(webinarData?.read_more)}  target="_blank"  className={`flex-shrink-0 btn font-16 btn-sm px-8 py-3 btn-gradient rounded-pill ${((displayTimer && deadline) && (!replay || replay !== 'true') && webinarData?.is_no_timer === 0 ) ?' disabled-link':''}`} ><span>Läs mer</span></a>
              </div>
              <a  onClick={()=>openUrl(webinarData?.read_more)} target="_blank" className={`d-md-none d-block mt-3 btn font-16 btn-sm px-8 py-3 btn-gradient rounded-pill ${((displayTimer && deadline) && (!replay || replay !== 'true') && webinarData?.is_no_timer === 0 )?' disabled-link':''}`} ><span>Läs mer</span></a>
            </Col>
            <Col md={5}>
              <div className="chat-wrapper">
                <div className="chat-header">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="fw-medium mb-1">Chat</h4>
                    {!displayTimer && deadline && webinarData?.is_no_timer === 0 &&
                    <div className="d-flex align-items-center">
                      <span className="me-4 chat-user-ic">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" stroke="#101010" stroke-width="2" stroke-linecap="round" />
                          <circle cx="12" cy="8" r="4" stroke="#101010" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </span>
                      <span className="font-20 mobile-font-14 fw-medium">{numberofwatcher}</span>
                    </div>
                    }
                  </div>
                  <p className="mb-0">Skriv dina frågor och få svar på mail efter webinariet. (ingen annan ser dina frågor)</p>
                </div>
                <div className="chat-body">
                
                {oldChat?.length > 0 &&
            oldChat?.map((item) => (
                  <p className="chat-msg-box">
                    <span className="chat-message">{item.chat_text}</span>
                  </p>
                    ))}
                </div>
                <div className="chat-footer d-flex">
                  <div className="me-5 w-100">
                    <Form.Control
                      placeholder="Ställ en fråga här..."
                      aria-label="chatinput"
                      aria-describedby="chatinput"
                      name="chat_text"
                      readOnly={((displayTimer && deadline) && (!replay || replay !== 'true') && webinarData?.is_no_timer === 0 )}
                      value={value.chat_text}
                      onChange={onChange}
                    />
                  </div>
                  <Button variant="primary" type="button" onClick={sendMessage} className="btn-sm flex-shrink-0" disabled = {((displayTimer && deadline) && (!replay || replay !== 'true') && webinarData?.is_no_timer === 0 )}>
                    Skicka
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default WebinarExcel;
