import React, { useState, useEffect } from 'react';
import Header from '../commom/Header';
import { Container, Image, Accordion } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { newslatterList } from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import SubscribeForm from '../commom/SubscribeForm';
import { toast } from 'react-toastify';
import Footer from '../commom/Footer';
const TwoNewslatters = () => {
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "is_deleted": 0
    }
  }
  const [allnewsLatters, setAllnewsLatters] = useState();
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  useEffect(() => {
    refresh();
  }, []);
  const refresh = () => {
    dispatch(newslatterList(data)).then((res) => {
      setAllnewsLatters(res.payload.data);
    })
  }
  const showMessage = () => {
    toast.success('Du måste logga in för att ladda ner denna boken');
  }
  return (

    <div className='d-flex flex-column flex-grow-1 homepage'>
      <Header />
      <div className='page-wrapper flex-grow-1 py-0'>


         {/* Starts content with banner section */}
         <section className="cnt-with-image pt-100-acurate">
          {
           allnewsLatters && allnewsLatters.length >0 && allnewsLatters?.slice(1, 2)?.map((item, index) => (
              <div key={index} className={`content-image-box back-erik`}>
                <div className='book-images'>
                  {/* <Image  src={`/assets/images/book${index+1}.png`} width={725} height={517} alt="book"/> */}
                  <Image className={`my-image2`} src={item?.image} width={725} height={517} alt="book" />
                </div>
                <Container fluid>
                  <div className='cnt-content'>
                    <h2 className="fw-normal">{item?.name} </h2>
                    <h4 className=" fw-bold mt-1 mt-md-5">{item?.sub_title !== 'null' ? item?.sub_title : ''}  </h4>
                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                    <div className="d-flex align-items-start justify-content-start cnts-btn">
                      <Link to='/logga-in' onClick={()=>showMessage()} className={`btn flex-shrink-0  d-inline-flex align-items-center  ${index == 1? 'btn-outline-secondary' : 'btn btn-outline-light a-hover'}`} >{t('ladda_ned')} <span className='icon-right-angle-icon d-block ms-2'></span></Link>
                    </div>
                  </div>
                </Container>
              </div>
            ))}
        </section>
        {/* Starts Page Banner */}

        <div className='pt-100 position-relative'>
          <div className="banner-video">
            <video src="/assets/video/Background-flow.mp4" autoplay="autoplay" playsinline="playsinline"
              muted="muted"
              loop="loop">
            </video>
          </div>
         <SubscribeForm chk_id={1}/>
        </div>
        {/* Ends Page Banner */}
      </div>
     <Footer/>
    </div>
  )
}

export default TwoNewslatters;