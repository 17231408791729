import React, { useRef, useEffect, useState } from "react";
import { Container, Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addBlog, singleBlog, editBlog } from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getCources } from "../../store/api/course.api";
import Select from "react-select";
import { ck_config } from "../commom/commonConst";

const AddBlog = (prop) => {
  //  const editorRef = useRef(null);
  const [value, setValue] = useState({
    title: "",
    about: "",
    sub_title: "",
    book_me_now_url: "",
  });
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [allCources, setAllCources] = useState();
  const [selectedCourse, setselectedCourse] = useState(
    prop.creator_id
      ? { value: prop.creator_id, label: prop.creator_name }
      : null
  );
  const { t } = useTranslation();
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  const options = [];
  const editorConfiguration = ck_config;

  const [textContent, setTextContent] = useState(null);
  const handleClose = () => {
    setShow(false);
    prop.closeModal();
  };
  const onAdd = () => {
    var data = new FormData();
    data.append("title", value.title);
    data.append("sub_title", value.sub_title);
    data.append("image", selectedFile, selectedFile.name);
    data.append("description", textContent);
    data.append("attached_course_id", selectedCourse);
    data.append("cover_text", value.cover_text);
    dispatch(addBlog(data))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.closeModal();
      })
      .catch(() => {});
  };
  const onEdit = () => {
    var data = new FormData();
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    data.append("slug", prop.editId);
    data.append("title", value.title);
    data.append("sub_title", value.sub_title);
    data.append("description", textContent);
    data.append("attached_course_id", selectedCourse);
    data.append("cover_text", value.cover_text);
    dispatch(editBlog(data))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.closeModal();
      })
      .catch(() => {});
  };

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources(course.payload.data.courses);
    });
  }, []);

  useEffect(() => {
    if (prop.editId) {
      let data = {
        slug: prop.editId,
      };
      dispatch(singleBlog(data)).then((blog) => {
        setValue({
          ...value,
          ["title"]: blog.payload.data.title,
          ["sub_title"]: blog.payload.data.sub_title,
          ["cover_text"]: blog.payload.data.cover_text,
        });
        setTextContent(blog.payload.data.description);
      });
    }
    setShow(prop.show);
  }, []);
  allCources &&
    allCources.map((list) =>
      options.push({ value: list.id, label: list.title })
    );
  const onChangeCourse = (selectedOption) => {
    setselectedCourse(selectedOption.value);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {prop.editId ? (
              <span>{t("Edit_Blog")}</span>
            ) : (
              <span>{t("Add_Blog")}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="page-wrapper flex-grow-1 ">
            <Container fluid>
              <div className="max-950 overflow-hidden">
                <Form>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="title"
                      name="title"
                      placeholder={t("Title")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.title}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="sub_title"
                      placeholder={t("Sub_title")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.sub_title}
                      onChange={onChange}
                    />
                  </Form.Group>

                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file1"
                      className="visually-hidden"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="file1"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/Video_file_light.png"
                          alt="Select Image"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Select_Image")}</span>
                    </label>
                  </div>
                  <Form.Group className="mb-10 wow fadeInDown">
                    <Form.Label className="font-16 fw-bold text-white ">
                      {t("Select_Course")}
                    </Form.Label>
                    {options && (
                      <Select
                      className="custom-select black-theme"
                      classNamePrefix="myselect"
                        defaultValue={selectedCourse}
                        options={options}
                        onChange={onChangeCourse}
                      />
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formGroupAboutUs"
                  >
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder={t("cover_text")}
                      name="cover_text"
                      aria-label="Certificate Text"
                      value={value.cover_text}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <div className="mb-10 wow fadeInDown">
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={textContent}
                      placeholder="Enter data"
                      onChange={(event, editor) => {
                        setTextContent(editor.getData());
                      }}
                    />
                  </div>
                  {!prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onAdd}
                    >
                      {" "}
                      {t("Save")}{" "}
                    </Button>
                  )}
                  {prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onEdit}
                    >
                      {" "}
                      {t("Update")}{" "}
                    </Button>
                  )}
                </Form>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBlog;
