import React, { useEffect, useState } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import Header from '../commom/Header2';
import { useDispatch, useSelector } from 'react-redux';
import { getemployees, removeUser, user_count_for_admin } from '../../store/api/course.api';
import MediawiseDetails from '../commom/MediawiseDetails';
import AssignSubcription from '../commom/AssignSubcription';
import AddUser from '../commom/AddUser';
import ConfirmPopup from '../commom/ConfirmPopup';
import { useTranslation } from "react-i18next";
import BulkUserCreate from '../commom/BulkUserCreate';
import AssignCourse from '../commom/AssignCourse';
import ViewEmailSubscriber from '../commom/ViewEmailSubscriber';
import moment from 'moment'
import ViewCategoryWiseUser from '../commom/ViewCategoryWiseUser';
import Wrapper from '../component/Wrapper';
import InnerHeader from './InnerHeader';
import TextBlock from '../component/TextBlock';
require('moment/locale/sw.js');
const UserManagment = () => {
   const [show, setShow] = useState(false);
   const [showAssing, setshowAssing] = useState(false);
   const [bulkShow, setBulkShow] = useState(false);
   const [showCreateuser, setShowCreateuser] = useState(false);
   const [allEmployee, setAllEmployees] = useState();
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [selectedId, setSelectedId] = useState(false);
   const [selectedEmail, setSelectedEmail] = useState('');
   const [editId, setEditId] = useState(null);
   const { business_id, role_id } = useSelector((state) => state.auth.user);
   const [allCount, setAllCount] = useState();
   const [showSubcription, setShowSubcription] = useState(false);
   const [userDetail, setUserDetail] = useState();
   const [editUserId, setEditUserId] = useState(null);
   const [showOpen, setShowUsers] = useState(false);
   const [showCategory, setShowCategory] = useState(false);
   const [roll, setRoll] = useState();
   const [isSubscription, setSubscription] = useState(false);
   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
   });
   let filter = {
      "filter": {
         "is_delete": 0,
      }
   }
   if (role_id === 2) {
      filter.filter.business_id = business_id
      filter.filter.role_id = '4'
   }
   const handleDelete = (id) => {
      setPopup({
         show: true,
         id,
      });
   };
   const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         onDelete(popup.id)
         setPopup({
            show: false,
            id: null,
         });
      }
   };
   const handleDeleteFalse = () => {
      setPopup({
         show: false,
         id: null,
      });
   };

   const openShowBulk = () => {
      setBulkShow(true);
   }
   const openShow = (id) => {
      setEditId(id)
      setShowCreateuser(true);
   }

   const handleShow = (id, email) => {
      setSelectedId(id)
      setShow(true);
      setSelectedEmail(email)
   }
   const callbackModal = () => {
      setShowUsers(false);
      setShow(false);
      setBulkShow(false);
      setshowAssing(false);
      setShowCategory(false);
      setRoll(1)

   }
   const closeModal = () => {

      setShowCreateuser(false);
      setShowSubcription(false);
      dispatch(getemployees(filter)).then((res) => {
         setAllEmployees(res.payload.data);
      })

   }
   const openUsers = () => {

      setShowUsers(true);
   }
   const openCategory = (roll, isSub) => {
      setShowCategory(true);
      setRoll(roll);
      setSubscription(isSub);
   }

   const onDelete = (id) => {
      let data = {
         'is_delete': 1,
         'id': id
      }
      dispatch(removeUser(data))
         .unwrap()
         .then((res) => {
            if (res.status === 200) {
               dispatch(getemployees(filter)).then((res) => {
                  setAllEmployees(res.payload.data);
               })
            }
         })
         .catch((e) => {
            console.log(e)
         });

   };
   const openShowSubscription = (item, id) => {
      setEditUserId(id)
      setUserDetail(item);
      setShowSubcription(true);
   }
   useEffect(() => {

      dispatch(getemployees(filter)).then((res) => {
         setAllEmployees(res.payload.data);
      })
      let data = {
         business_id: business_id
      }
      dispatch(user_count_for_admin(data)).then((course) => {
         setAllCount(course.payload.data);
      });

   }, [])
   return (
      <div className="innerblock-page">
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title="User Management" subtitle="" />
            <Wrapper>
               <div className='dashboard-topinfo mb-13'>

                  <Row className='g-16px'>
                     <Col md={4} xl={4} className='d-flex flex-column gap-16px'>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('Number_Of_Total_User')} subtitle={allCount?.number_of_user} />
                        </div>

                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('Number_of_Private_User')} subtitle={allCount?.number_of_private} />
                           {showCategory && roll === 3 && isSubscription === false &&
                              <ViewCategoryWiseUser show={showCategory} allEmployee={allEmployee} roll={roll} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('Number_of_Business_User')} subtitle={allCount?.number_of_business} />
                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_business}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openCategory(2, false)}>{t('Number_of_Business_User')} </h2> */}
                           {showCategory && roll === 2 && isSubscription === false &&
                              <ViewCategoryWiseUser show={showCategory} allEmployee={allEmployee} roll={roll} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('Number_of_Employee_User')} subtitle={allCount?.number_of_employee} />
                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_employee}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openCategory(4, false)}>{t('Number_of_Employee_User')}</h2> */}
                           {showCategory && roll === 4 && isSubscription === false &&
                              <ViewCategoryWiseUser show={showCategory} allEmployee={allEmployee} roll={roll} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('Number_of_Admin_User')} subtitle={allCount?.number_of_admin} />

                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_admin}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openCategory(1, false)}>{t('Number_of_Admin_User')}</h2> */}
                           {showCategory && roll === 1 && isSubscription === false &&
                              <ViewCategoryWiseUser show={showCategory} allEmployee={allEmployee} roll={roll} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                     </Col>
                     <Col md={4} xl={4} className='d-flex flex-column gap-16px'>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('number_of_business_subcription')} subtitle={allCount?.number_of_business_subcription} />

                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_business_subcription}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openCategory(2, true)}>{t('number_of_business_subcription')}</h2> */}
                           {showCategory && roll === 2 && isSubscription === true &&
                              <ViewCategoryWiseUser show={showCategory} allEmployee={allEmployee} roll={roll} isSub={isSubscription} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('number_of_private_subcription')} subtitle={allCount?.number_of_private_subcription} />

                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_private_subcription}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openCategory(3, true)}>{t('number_of_private_subcription')}</h2> */}
                           {showCategory && roll === 3 && isSubscription === true &&
                              <ViewCategoryWiseUser show={showCategory} allEmployee={allEmployee} roll={roll} isSub={isSubscription} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('number_of_email_subscriber')} subtitle={allCount?.number_of_email_subscriber} />

                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_email_subscriber}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openUsers()}>{t('number_of_email_subscriber')}</h2> */}
                           {showOpen &&
                              <ViewEmailSubscriber show={showOpen} callbackModal={callbackModal}></ViewEmailSubscriber>
                           }
                        </div>
                     </Col>
                     <Col md={4} xl={4} className='d-flex flex-column gap-16px'>
                        <div className='bg-white shadow-block rounded-20 p-20px'>
                           <TextBlock title={t('number_of_trial_subcription')} subtitle={allCount?.number_of_trial_subcription} />

                           {/* <span className='info-count flex flex-shrink-0 me-5'>{allCount?.number_of_trial_subcription}</span>
                           <h2 className='h5 fw-normal text-white info-text line-clamp2' onClick={() => openCategory(0, false)}>{t('number_of_trial_subcription')}</h2> */}
                           {showCategory && roll === 0 &&
                              <ViewCategoryWiseUser show={true} allEmployee={allEmployee} roll={roll} isSub={isSubscription} isTrial={true} callbackModal={callbackModal}></ViewCategoryWiseUser>
                           }
                        </div>
                     </Col>
                  </Row>


               </div>

               <div className='bg-white shadow-block rounded-20'>
                  <div className='p-20px title-header'>
                     <TextBlock title={t('Users_List')} />
                  </div>
                  <div className=''>
                     <div className="text-svart bg-white overflow-hidden table-borderwrap">
                        <Table bordered className="text-center tableblock-wrap mb-0 rounded-0" responsive>
                           <thead className='top-bordernone'>
                              <tr>


                                 <th>{t('E-post')}</th>
                                 <th>{t('User_type')}</th>
                                 <th>{t('Check_Watch')}</th>
                                 <th>{t('Date')}</th>
                                 <th>{t('subscription')}</th>
                                 <th>{t('trial_end')}</th>
                                 <th>{t('Action')}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 allEmployee?.map((item, key) => (
                                    <tr key={item.id}>


                                       <td>{item?.email}</td>
                                       <td>
                                          {item?.role_id === 2 &&
                                             <span>Business</span>
                                          }
                                          {item?.role_id === 3 &&
                                             <span>Private</span>
                                          }
                                          {item?.role_id === 4 &&
                                             <span>Employee</span>
                                          }
                                       </td>
                                       <td>
                                          <div className='d-flex align-items-center justify-content-center'>
                                             <a className='btn btn-link icon-stats-dots text-white link-hover action-btn' onClick={() => handleShow(item.id, item.email)}></a>
                                          </div>
                                       </td>

                                       <td>{moment(item?.created_at).format("DD/MM-YYYY HH:mm")}</td>
                                       <td>
                                          {item.subscription &&
                                             <>
                                                {item?.subscription[0]?.is_year_plan == 1 ? '249 subcription' : item?.subscription[0]?.is_year_plan === 0 ? '499 subcription' : '-'}
                                             </>
                                          }
                                       </td>

                                       <td>
                                          {item.subscription &&
                                             <>
                                                {new Date(item?.subscription[0]?.trial_end) > new Date() ? moment(item?.subscription[0]?.trial_end).format("DD/MM-YYYY HH:mm") : '-'}
                                             </>
                                          }
                                       </td>
                                       <td>
                                          <div className='d-flex align-items-end justify-content-center'>

                                             <a className='btn btn-link icon-books text-black link-hover action-btn' onClick={() => openShowSubscription(item, item.id)} ></a>

                                             {(showSubcription && editUserId == item.id) &&
                                                <AssignSubcription show={showSubcription} userDetail={userDetail} closeModal={closeModal} />
                                             }

                                             <a className='btn btn-link icon-ic-edit text-black link-hover action-btn' onClick={() => openShow(item.id)} ></a>

                                             {(showCreateuser && editId === item?.id) &&

                                                <AddUser show={showCreateuser} editId={editId} closeModal={closeModal} />
                                             }
                                             <a className='btn btn-link icon-ic-trash text-black link-hover action-btn' onClick={() => handleDelete(item.id)} ></a>
                                             {popup.show && (
                                                <ConfirmPopup
                                                   handleDeleteTrue={handleDeleteTrue}
                                                   handleDeleteFalse={handleDeleteFalse}
                                                   message={t('You_sure_you_wanna_delete')}
                                                />
                                             )}
                                          </div>
                                       </td>
                                    </tr>
                                 ))
                              }
                           </tbody>
                        </Table>
                     </div>
                     {allEmployee && allEmployee.length === 0 &&
                        <p className='text-center'>{t('No_records_found')}</p>
                     }
                     {show &&
                        <MediawiseDetails show={show} id={selectedId} email={selectedEmail} callbackModal={callbackModal} />
                     }
                     {bulkShow &&
                        <BulkUserCreate show={bulkShow} callbackModal={callbackModal} />
                     }

                     {showAssing &&
                        <AssignCourse show={showAssing} callbackModal={callbackModal} />
                     }

                  </div>
               </div>
               {/* Starts Table */}
               {/* <div className='wow fadeInDown'>
                  <Table bordered hover responsive className='text-center'>
                     <thead>
                        <tr>


                           <th>{t('E-post')}</th>
                           <th>{t('User_type')}</th>
                           <th>{t('Check_Watch')}</th>
                           <th>{t('Date')}</th>
                           <th>{t('subscription')}</th>
                           <th>{t('trial_end')}</th>
                           <th>{t('Action')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           allEmployee?.map((item, key) => (
                              <tr key={item.id}>


                                 <td>{item?.email}</td>
                                 <td>
                                    {item?.role_id === 2 &&
                                       <span>Business</span>
                                    }
                                    {item?.role_id === 3 &&
                                       <span>Private</span>
                                    }
                                    {item?.role_id === 4 &&
                                       <span>Employee</span>
                                    }
                                 </td>
                                 <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                       <a className='btn btn-link icon-stats-dots text-white link-hover action-btn' onClick={() => handleShow(item.id, item.email)}></a>
                                    </div>
                                 </td>

                                 <td>{moment(item?.created_at).format("DD/MM-YYYY HH:mm")}</td>
                                 <td>
                                    {item.subscription &&
                                       <>
                                          {item?.subscription[0]?.is_year_plan == 1 ? '249 subcription' : item?.subscription[0]?.is_year_plan === 0 ? '499 subcription' : '-'}
                                       </>
                                    }
                                 </td>

                                 <td>
                                    {item.subscription &&
                                       <>
                                          {new Date(item?.subscription[0]?.trial_end) > new Date() ? moment(item?.subscription[0]?.trial_end).format("DD/MM-YYYY HH:mm") : '-'}
                                       </>
                                    }
                                 </td>
                                 <td>
                                    <div className='d-flex align-items-end justify-content-center'>

                                       <a className='btn btn-link icon-books text-white link-hover action-btn' onClick={() => openShowSubscription(item, item.id)} ></a>

                                       {(showSubcription && editUserId == item.id) &&
                                          <AssignSubcription show={showSubcription} userDetail={userDetail} closeModal={closeModal} />
                                       }

                                       <a className='btn btn-link icon-ic-edit text-white link-hover action-btn' onClick={() => openShow(item.id)} ></a>

                                       {(showCreateuser && editId === item?.id) &&

                                          <AddUser show={showCreateuser} editId={editId} closeModal={closeModal} />
                                       }
                                       <a className='btn btn-link icon-ic-trash text-white link-hover action-btn' onClick={() => handleDelete(item.id)} ></a>
                                       {popup.show && (
                                          <ConfirmPopup
                                             handleDeleteTrue={handleDeleteTrue}
                                             handleDeleteFalse={handleDeleteFalse}
                                             message={t('You_sure_you_wanna_delete')}
                                          />
                                       )}
                                    </div>
                                 </td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>
                  {allEmployee && allEmployee.length === 0 &&
                     <p className='text-center'>{t('No_records_found')}</p>
                  }
                  {show &&
                     <MediawiseDetails show={show} id={selectedId} email={selectedEmail} callbackModal={callbackModal} />
                  }
                  {bulkShow &&
                     <BulkUserCreate show={bulkShow} callbackModal={callbackModal} />
                  }

                  {showAssing &&
                     <AssignCourse show={showAssing} callbackModal={callbackModal} />
                  }

               </div> */}
               {/* Ends Table */}
            </Wrapper>
         </div>
      </div>
   )
}
export default UserManagment;