import React, { useState, useEffect } from 'react';
import Header from '../commom/Header2';
import { Container, Table, Image, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { groupByCoupon } from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import CouponUsers from '../public/CouponUsers'
import moment from 'moment';
import DatePicker from "react-datepicker";
import Wrapper from '../component/Wrapper';
import InnerHeader from './InnerHeader';
const CouponCountList = () => {

  const [allnewsLatters, setAllnewsLatters] = useState();
  const [showUser, setShowUser] = useState(false);
  const [couponName, setcouponName] = useState();
  var d = new Date();
  d.setMonth(d.getMonth() - 1);
  const [start_date, setStartDate] = useState(d);
  const [end_date, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "c.is_deleted": 0
    },
    'start_date': start_date,
    'end_date': end_date,
  }

  useEffect(() => {
    refresh();
  }, [])
  const refresh = () => {
    dispatch(groupByCoupon(data)).then((res) => {
      setAllnewsLatters(res.payload.data);
    })
  }
  const { t } = useTranslation();
  const openShowUser = (name) => {

    setcouponName(name)
    setShowUser(true);

  }
  const closeModal = () => {

    setShowUser(false);

  }
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className='page-wrapper flex-grow-1 page-block'>
        <InnerHeader title={t('Rabattkoder')} subtitle="" />
        <Wrapper>

          {/* <div className='d-flex justify-content-between align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown '>
            <h1 className='mb-sm-0  fw-semibold text-white h2'>{t('Rabattkoder')}</h1>
          </div> */}
          <Row className='mb-5 mt-5 '>
            <Col md={4}>
              <div className='position-relative datepicker-block'>
                <Image src='/assets/images/Calendar_add_light.png' alt='' className='datepicker-icon' />
                <DatePicker className='form-control text-black' placeholderText={t('Set_Deadline')} selected={start_date} onChange={(date) => setStartDate(date)} />
              </div>
            </Col>
            <Col md={4}>
              <div className='position-relative datepicker-block'>
                <Image src='/assets/images/Calendar_add_light.png' alt='' className='datepicker-icon' />
                <DatePicker className='form-control text-black' placeholderText={t('Set_Deadline')} selected={end_date} onChange={(date) => setEndDate(date)} />
              </div>
            </Col>
            <Col md={4}>
              <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={() => refresh()} >{t('Search')}</Button>
            </Col>
          </Row>
          {/* Starts Table */}
          <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
            <Table bordered responsive className="text-center tableblock-wrap mb-0">
              <thead className='top-bordernone'>
                <tr>
                  <th>{t('Name')}</th>
                  <th>{t('Number_of_user')}</th>
                  <th>{t('Single_total')}</th>
                  <th>{t('Subscription_total')}</th>
                  <th>{t('Total_earning')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  allnewsLatters && allnewsLatters.length > 0 && allnewsLatters?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item.coupon_name}</td>
                      <td className='cursor_class' onClick={() => openShowUser(item.coupon_name)}>{item?.count}</td>
                      <td>{item.singlesubscriptionsum.toFixed(2)} Kr</td>
                      <td>{item.subscriptionsum.toFixed(2)} Kr</td>
                      <td>{(item.subscriptionsum + item.singlesubscriptionsum).toFixed(2)} Kr</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            {allnewsLatters && allnewsLatters.length === 0 &&
              <p className='text-center'>{t('No_records_found')}</p>
            }
            {(showUser) &&
              <CouponUsers show={showUser} couponName={couponName} closeModal={closeModal} />
            }
          </div>
          {/* Ends Table */}
        </Wrapper>
      </div>
    </div>
  )
}


export default CouponCountList;