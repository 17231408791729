import axios from "./httpService";
const API_URL = axios.Api;
const getPublicContent = (body) => {
  return axios.post(API_URL + "course", body);
};

const addCourse = (body) => {
  return axios.post(API_URL + "course/add", body);
};
const removeCourse = (body) => {
  return axios.post(API_URL + "course/remove", body);
};
const getCreators = () => {
  return axios.post(API_URL + "creator");
};

const addProduct = (body) => {
  return axios.post(API_URL + "course/media/add", body);
};

const editProduct = (body) => {
  return axios.put(API_URL + "course/media/edit", body);
};
const getProductList = (body) => {
  return axios.post(API_URL + "course/media", body);
};

const getSingleProduct = (body) => {
  return axios.post(API_URL + "course/single", body);
};
const getSinglePackage = (body) => {
  return axios.post(API_URL + "package/single", body);
};
const addCreator = (body) => {
  return axios.post(API_URL + "creator/add", body);
};
const editCreator = (body) => {
  return axios.put(API_URL + "creator/edit", body);
};

const removeCreator = (id) => {
  return axios.delete(API_URL + "creator/delete/" + id);
};
const removeUser = (body) => {
  return axios.put(API_URL + "employee/delete_retrive", body);
};
const getEmployees = (body) => {
  return axios.post(API_URL + "employee", body);
};

const getUserProfile = (id) => {
  return axios.get(API_URL + "user/profile");
};
const getSingleUser = (id) => {
  return axios.get(API_URL + "employee/" + id);
};
const createPaymentIntent = (body) => {
  return axios.post(API_URL + "create-payment-intent", body);
};
const addSingleEmployee = (body = {}) => {
  return axios.post(API_URL + "employee/add/single", body).then((response) => {
    if (response.status === 200) {
    }
    return response.data;
  });
};
const editSingleEmployee = (body = {}) => {
  return axios.put(API_URL + "employee/edit", body).then((response) => {
    if (response.status === 200) {
    }
    return response.data;
  });
};

const editSingleQuestion = (body = {}) => {
  return axios.put(API_URL + "questions/edit", body).then((response) => {
    if (response.status === 200) {
    }
    return response.data;
  });
};

const assign_course_and_deadline = (body = {}) => {
  return axios.put(API_URL + "employee/assign_course_and_deadline", body).then((response) => {
    if (response.status === 200) {
    }
    return response.data;
  });
};

const assignCources = (body) => {
  return axios.post(API_URL + "course/assign", body);
};
const assignCourcesNew = (body) => {
  return axios.post(API_URL + "course/assign_new", body);
};

const addQuestions = (body) => {
  return axios.post(API_URL + "/questions/add", body);
};

const getExamList = (body) => {
  return axios.post(API_URL + "exam", body);
};
const removeMedia = (body) => {
  return axios.put(API_URL + "course/media/delete", body);
};
const removeExam = (body) => {
  return axios.put(API_URL + "exam/delete", body);
};
const removeQuestion = (body) => {
  return axios.put(API_URL + "questions/delete", body);
};
const videoProgress = (body) => {
  return axios.put(API_URL + "video/watch", body);
};
const webinarWatch = (body) => {
  return axios.post(API_URL + "webinar/watch", body);
};

const setSingleQuestion = (id) => {
  return axios.get(API_URL + "questions/" + id);
};
const setSingleExam = (body) => {
  return axios.post(API_URL + "questions/", body);
};
const addTest = (body) => {
  return axios.post(API_URL + "/exam/add", body);
};
const editTest = (body) => {
  return axios.put(API_URL + "/exam/edit", body);
};

const getSingleMediaDetail = (id) => {
  return axios.get(API_URL + "course/media/" + id);
};
const getSingleCreator = (id) => {
  return axios.get(API_URL + "creator/" + id);
};
const getSingleExamDetail = (id) => {
  return axios.get(API_URL + "exam/" + id);
};
const createNote = (body) => {
  return axios.post(API_URL + "note/add", body);
};
const getNote = (body) => {
  return axios.post(API_URL + "note", body);
};
const removeNote = (id) => {
  return axios.delete(API_URL + "note/delete/" + id);
};
const editSingleNote = (body) => {
  return axios.put(API_URL + "note/update", body);
};
const updateProfile = (body) => {
  return axios.put(API_URL + "user/profile/update", body);
};
const completeTrasaction = (body) => {
  return axios.post(API_URL + "payment/transaction/add", body);
};
const trasactionHistory = (body) => {
  return axios.post(API_URL + "payment/history", body);
};
const verifyExam = (body) => {
  return axios.post(API_URL + "exam/verify", body);
};
const getDashboardCount = (body) => {
  return axios.post(API_URL + "dashbaord/count", body);
};
const addfcmToken = (body) => {
  return axios.post(API_URL + "notification/fcmtoken/add", body);
};
const sendNotification = (body) => {
  return axios.post(API_URL + "notification/send", body);
};
const getnotification = () => {
  return axios.get(API_URL + "notification");
};
const readnotification = () => {
  return axios.put(API_URL + "notification/read");
};
const getCertificate = (body) => {
  return axios.post(API_URL + "employee/certificate", body);
};
const getankiExam = (body) => {
  return axios.post(API_URL + "anki/questions", body);
};
const addAnkiQuestions = (body) => {
  return axios.post(API_URL + "anki/questions/add", body);
};
const removeAnkiQuestion = (body) => {
  return axios.put(API_URL + "anki/questions/delete", body);
};
const randomAnkiQuestion = (id) => {
  return axios.get(API_URL + "anki/question/random/" + id);
};
const getDashboardData = (body) => {
  return axios.post(API_URL + "dashbaord/topuser", body);
};
const contactUs = (body) => {
  return axios.post(API_URL + "contactus/add", body);
};
const add_new_course_message = (body) => {
  return axios.post(API_URL + "contactus/add_new_course_message", body);
};

const coursestatistics = () => {
  return axios.get(API_URL + "dashbaord/coursestatistics");
};
const adminCourseStatistics = (body) => {
  return axios.post(API_URL + "dashbaord/adminCourseStatistics", body);
};
const privateCourseStatistics = (body) => {
  return axios.post(API_URL + "dashbaord/privateCourseStatistics", body);
};

const editCourse = (body) => {
  return axios.post(API_URL + "course/edit", body);
};

const editPackage = (body) => {
  return axios.post(API_URL + "package/edit", body);
};
const myPurchageList = (body) => {
  return axios.post(API_URL + "course/purches", body);
};
const addBulk = (body) => {
  return axios.post(API_URL + "employee/add/multiple", body);
};
const publishCourse = (body) => {
  return axios.post(API_URL + "/course/publish", body);
};
const inviteUserData = (body = {}) => {
  return axios.post(API_URL + "invitation/adduser", body);
};
const inviteUser = (body = {}) => {
  return axios.post(API_URL + "invitation/send", body);
};
const customSort = (body = {}) => {
  return axios.post(API_URL + "course/sort/custom", body);
};
const getPlans = (body = {}) => {
  return axios.get(API_URL + "subscription/plan");
};
const buyPlans = (body = {}) => {
  return axios.post(API_URL + "subscription/purchase", body);
};
const completePlans = (body = {}) => {
  return axios.post(API_URL + "subscription/complete", body);
};
const completeTrial = (body = {}) => {
  return axios.post(API_URL + "subscription/completeTrial", body);
};

const setPremium = (body = {}) => {
  return axios.put(API_URL + "business/setpremium", body);
};
const setQuantityPremium = (body = {}) => {
  return axios.put(API_URL + "business/setQuantityPremium", body);
};
const tagList = (body = {}) => {
  return axios.get(API_URL + "course/listTags");
};
const tagWiseCourseList = (body = {}) => {
  return axios.get(API_URL + "course/myCourse", body);
};
const getActiveSubscription = (body = {}) => {
  return axios.post(API_URL + "subscription/active", body);
};
const cancleActiveSubscription = (body = {}) => {
  return axios.post(API_URL + "subscription/cancel", body);
};
const updateActiveSubscription = (body = {}) => {
  return axios.put(API_URL + "/subscription/updatepurchase", body);
};
const addTag = (body = {}) => {
  return axios.post(API_URL + "/course/addTag", body);
};
const editTag = (body = {}) => {
  return axios.post(API_URL + "/course/editTag", body);
};
const deleteTag = (body = {}) => {
  return axios.post(API_URL + "tag/remove", body);
};
const myCourse = (body) => {
  return axios.post(API_URL + "course/myCourse", body);
};

const myCount = (body) => {
  return axios.post(API_URL + "user/get_license_count", body);
};

const watchhistory = (body) => {
  return axios.post(API_URL + "/user/watch-history", body);
};
const user_count_for_admin = (body) => {
  return axios.post(API_URL + "/user/user_count_for_admin", body);
};
const getList_of_demand = () => {
  return axios.post(API_URL + "/contactus/list_of_demand");
};
const addNewsLatter = (body) => {
  return axios.post(API_URL + "newslatter/add", body);
};
const editNewsLatter = (body) => {
  return axios.post(API_URL + "newslatter/edit", body);
};

const newslatterList = (body) => {
  return axios.post(API_URL + "newslatter", body);
};
const getSingleNews = (body) => {
  return axios.post(API_URL + "newslatter/single", body);
};
const newslatter_downlaod = (body) => {
  return axios.post(API_URL + "newslatter/downlaod", body);
};
const removeNews = (body) => {
  return axios.post(API_URL + "newslatter/remove", body);
};
const subribeChannel = (body) => {
  return axios.post(API_URL + "subribeChannel", body);
};
const customNewsSort = (body = {}) => {
  return axios.post(API_URL + "newslatter/sort/custom", body);
};
const getSubcriptionList = (body) => {
  return axios.post(API_URL + "newslatter/subcription_list", body);
};
const getCoupanList = () => {
  return axios.get(API_URL + "subscription/coupan_list");
};
const couponUserList = (body) => {
  return axios.post(
    API_URL + "subscription/subscription_of_coupon_user_list",
    body
  );
};
const groupByCoupon = (body) => {
  return axios.post(API_URL + "subscription/groupByCoupon", body);
};

const appyCoupon = (body) => {
  return axios.post(API_URL + "subscription/appyCoupon", body);
};
const addBlog = (body) => {
  return axios.post(API_URL + "blog/add", body);
};
const blogList = (body) => {
  return axios.post(API_URL + "blog/list", body);
};
const removeBlog = (body) => {
  return axios.post(API_URL + "blog/delete", body);
};
const editBlog = (body) => {
  return axios.post(API_URL + "blog/edit", body);
};
const singleBlog = (body) => {
  return axios.post(API_URL + "blog/single", body);
};
const customBlogSort = (body = {}) => {
  return axios.post(API_URL + "blog/sort/custom", body);
};
const addMp3 = (body) => {
  return axios.post(API_URL + "convert/add/mp3", body);
};
const searchInchatgtp = (body) => {
  return axios.post(API_URL + "search/ingtp", body);
};
const searchInblog = (body) => {
  return axios.post(API_URL + "search/inblog", body);
};
const addpackage = (body) => {
  return axios.post(API_URL + "package/add", body);
};

const getPackage = (body) => {
  return axios.post(API_URL + "course", body);
};
const getPackageListData = (body) => {
  return axios.post(API_URL + "package", body);
};
const removePackage = (body) => {
  return axios.post(API_URL + "package/remove", body);
};
const getCheckoutUrl = (body) => {
  return axios.post(API_URL + "generate_checkout_url", body);
};

const verifyPayment = (body) => {
  return axios.post(API_URL + "verify_payment", body);
};

const passwordRegister = (body) => {
  return axios.post(API_URL + "onetime_create_password", body);
};
const nameChange = (body) => {
  return axios.post(API_URL + "onetime_name_change", body);
};
const processAsBusiness = (body) => {
  return axios.post(API_URL + "onetime_business", body);
};


const publishPackage = (body) => {
  return axios.post(API_URL + "package/publish", body);
};
// new cancel subscription api
const cancelActiveSubscriptionNew = (body = {}) => {
  return axios.post(API_URL + "subscription_cancel", body);
};

const getSubsPlans = (body = {}) => {
  return axios.get(API_URL + "subscription/plan", body);
};
const customSortPackage = (body = {}) => {
  return axios.post(API_URL + "package/sort/custom", body);
};

const addPodcasts = (body) => {
  return axios.post(API_URL + "podcast/add", body);
};

const removePodcasts = (body) => {
  return axios.post(API_URL + "podcast/remove", body);
};

const customSortPodcast = (body = {}) => {
  return axios.post(API_URL + "podcast/sort/custom", body);
};

const editPodcasts = (body) => {
  return axios.post(API_URL + "podcast/edit", body);
};

const podcasttagList = (body = {}) => {
  return axios.get(API_URL + "podcast/listTags");
};

const addPodcastTag = (body = {}) => {
  return axios.post(API_URL + "podcast/addTag", body);
};
const editPodcastTag = (body = {}) => {
  return axios.post(API_URL + "podcast/editTag", body);
};
const deletePodcastTag = (body = {}) => {
  return axios.post(API_URL + "podcast/removeTag", body);
};
const myPodcast = (body) => {
  return axios.post(API_URL + "podcast/myPodcast", body);
};

const addEducations = (body) => {
  return axios.post(API_URL + "education/add", body);
};

const getEducations = (body) => {
  return axios.post(API_URL + "education", body);
};

const editEducation = (body) => {
  return axios.post(API_URL + "education/edit", body);
};

const removeEducation = (body) => {
  return axios.post(API_URL + "education/remove", body);
};

const publishEducation = (body) => {
  return axios.post(API_URL + "education/publish", body);
};

const publishPodcast = (body) => {
  return axios.post(API_URL + "podcast/publish", body);
};

const getPodcasts = (body) => {
  return axios.post(API_URL + "podcast", body);
};

const getSinglePodcast = (body) => {
  return axios.post(API_URL + "podcast/singlePodcast", body);
};

const getPodcastProductList = (body) => {
  return axios.post(API_URL + "podcast/mediaList", body);
};

const getPOdcastProductAdd = (body) => {
  return axios.post(API_URL + "podcast/mediaAdd", body);
};

const getPOdcastProductEdit = (body) => {
  return axios.post(API_URL + "podcast/mediaEdit", body);
};

const getPOdcastProductRemove = (body) => {
  return axios.post(API_URL + "podcast/mediaRemove", body);
};

const sendEducationEmail = (body) => {
  return axios.post(API_URL + "education/sendEmail", body);
};
const invoicelist = (body) => {
  return axios.post(API_URL + "invoicelist", body);
};

const invoiceByintentId = (body) => {
  return axios.post(API_URL + "invoiceByintentId", body);
};
const getWebinar = (body) => {
  return axios.post(API_URL + "webinar", body);
};
const getWebinarSignup = (body) => {
  return axios.post(API_URL + "webinar/getsignups", body);
};
const getReadPageOpenar = (body) => {
  return axios.post(API_URL + "webinar/readpageopenar", body);
};

const getWebinarWatcher = (body) => {
  return axios.post(API_URL + "webinar/watcher", body);
};
const getWebinarchat = (body) => {
  return axios.post(API_URL + "webinar/getallchat", body);
};

const addWebinar = (body) => {
  return axios.post(API_URL + "webinar/add", body);
};
const publishWeinar = (body) => {
  return axios.post(API_URL + "webinar/publish", body);
};
const removeWebinar = (body) => {
  return axios.post(API_URL + "webinar/remove", body);
};
const editWebinar = (body) => {
  return axios.post(API_URL + "webinar/edit", body);
};
const getDates = (body) => {
  return axios.get(API_URL + "webinar/getDates", body);
};
const webinarEmail = (body) => {
  return axios.post(API_URL + "webinar/sendEmail", body);
};
const singleWebinar = (body) => {
  return axios.post(API_URL + "webinar/single", body);
};
const sendChat = (body) => {
  return axios.post(API_URL + "webinar/chat", body);
};
const getMyChat = (body) => {
  return axios.post(API_URL + "webinar/getMychat", body);
};
const verifyPassset = (body) => {
  return axios.post(API_URL + "webinar/verify_passset", body);
};
const pressReadMore = (body) => {
  return axios.post(API_URL + "webinar/readpage", body);
};
const userService = {
  getPublicContent,
  getCreators,
  addProduct,
  getProductList,
  addCourse,
  removeCourse,
  getSingleProduct,
  addCreator,
  removeCreator,
  getEmployees,
  removeUser,
  getSingleUser,
  createPaymentIntent,
  addSingleEmployee,
  assignCources,
  getExamList,
  addQuestions,
  removeMedia,
  setSingleExam,
  addTest,
  setSingleQuestion,
  getSingleMediaDetail,
  editProduct,
  getSingleCreator,
  editCreator,
  editSingleEmployee,
  removeExam,
  getSingleExamDetail,
  editTest,
  removeQuestion,
  videoProgress,
  createNote,
  getNote,
  getUserProfile,
  removeNote,
  editSingleNote,
  updateProfile,
  completeTrasaction,
  trasactionHistory,
  verifyExam,
  getDashboardCount,
  addfcmToken,
  sendNotification,
  getnotification,
  readnotification,
  getCertificate,
  getankiExam,
  addAnkiQuestions,
  removeAnkiQuestion,
  randomAnkiQuestion,
  getDashboardData,
  contactUs,
  coursestatistics,
  editCourse,
  myPurchageList,
  addBulk,
  publishCourse,
  inviteUserData,
  inviteUser,
  customSort,
  getPlans,
  buyPlans,
  setPremium,
  setQuantityPremium,
  tagList,
  tagWiseCourseList,
  getActiveSubscription,
  cancleActiveSubscription,
  updateActiveSubscription,
  addTag,
  editTag,
  deleteTag,
  myCourse,
  myCount,
  completePlans,
  completeTrial,
  watchhistory,
  user_count_for_admin,
  add_new_course_message,
  getList_of_demand,
  adminCourseStatistics,
  addNewsLatter,
  newslatterList,
  editNewsLatter,
  getSingleNews,
  newslatter_downlaod,
  removeNews,
  subribeChannel,
  customNewsSort,
  getSubcriptionList,
  getCoupanList,
  couponUserList,
  groupByCoupon,
  appyCoupon,
  addBlog,
  blogList,
  removeBlog,
  editBlog,
  singleBlog,
  customBlogSort,
  addMp3,
  searchInchatgtp,
  searchInblog,
  addpackage,
  getPackage,
  getSinglePackage,
  getPackageListData,
  removePackage,
  editPackage,
  getCheckoutUrl,
  verifyPayment,
  passwordRegister,
  publishPackage,
  cancelActiveSubscriptionNew,
  getSubsPlans,
  customSortPackage,
  nameChange,
  addPodcastTag,
  editPodcastTag,
  deletePodcastTag,
  myPodcast,
  removePodcasts,
  addPodcasts,
  editPodcasts,
  customSortPodcast,
  podcasttagList,
  addEducations,
  addWebinar,
  getEducations,
  editEducation,
  removeEducation,
  publishEducation,
  getPodcasts,
  getSinglePodcast,
  publishPodcast,
  getPOdcastProductAdd,
  getPOdcastProductEdit,
  getPOdcastProductRemove,
  getPodcastProductList,
  sendEducationEmail,
  processAsBusiness,
  privateCourseStatistics,
  assign_course_and_deadline,
  invoicelist,
  invoiceByintentId,
  editSingleQuestion,
  assignCourcesNew,
  getWebinar,
  publishWeinar,
  removeWebinar,
  editWebinar,
  getDates,
  webinarEmail,
  singleWebinar,
  sendChat,
  getMyChat,
  getWebinarSignup,
  verifyPassset,
  getWebinarchat,
  webinarWatch,
  getWebinarWatcher,
  pressReadMore,
  getReadPageOpenar
};

export default userService;
