import React, { useState, useRef, useEffect } from "react";
import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import {
  Row,
  Col,
  Image,
  Container,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Modal,
  Accordion,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getSingleProduct,
  videoProgress,
  createNote,
  randomAnkiQuestion,
  myPurchageList,
  getCheckoutUrl,
  getSubsPlan,
} from "../../store/api/course.api";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../store/api/cart";
import { useNavigate, Link } from "react-router-dom";
import AssignCourse from "../commom/AssignCourse";
import Player from "@vimeo/player";
import { Editor } from "@tinymce/tinymce-react";
import AddNewProduct from "../public/AddNewProduct";
import SelectTest from "../commom/SelectTest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FeatureCourseList from "../commom/FeatureCourseList";
import Footer from "../commom/Footer";
import {
  checkSubsDate,
  monthlyPlan,
  yearlyPlanName,
} from "../commom/commonConst";
import Accordion_Custome from "../commom/Accordion";
import { ck_config } from "../commom/commonConst";

const OpenCourseTopics = () => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showTestList, setShowTestList] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowAddNew = () => setShowAdd(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseid } = useParams();
  const [allItems, setAllItems] = useState({});
  const [alllength, setAlllength] = useState(0);
  const { t } = useTranslation();
  const [mainVideoURL, setMainVideoURL] = useState(null);
  const [mainTitle, setMainTitle] = useState(null);
  const [course_sub_title, setCourse_sub_title] = useState(null);
  const [mainPrice, setMainPrice] = useState(null);
  const [course_id, setCourseId] = useState(null);
  const [image, setImg] = useState(null);

  const [mainDiscription, setMainDiscription] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [mediaIdForPercent, setMediaIdForPercent] = useState(null);
  const [mediaLenth, setMediaLenth] = useState("0:00");
  const { id, role_id, business_id, email } = useSelector(
    (state) => state?.auth?.user || {}
  );
  const [mediaIdIndex, setMediaIdIndex] = useState(1);
  const [ankQuestion, setAnkiQuestion] = useState();
  const [showAns, setShowAns] = useState(false);
  const [value, setValue] = useState({ ankans: "" });
  const [showCreator, setShowCreator] = useState(false);
  const [singleCourse, setSingleCourses] = useState(0);
  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editCourseId, setEditCourseId] = useState(null);
  const editorConfiguration = ck_config;
  const [textContent, setTextContent] = useState(null);
  const user = useSelector((state) => state?.auth?.user);
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedTab, setSelectedTab] = useState('Avsnitt');
  const [selectedTabDesk, setTabDesk] = useState('deskAvsnitt')
  const [isReadMore, setIsReadMore] = useState(true);
  var iframe;
  var player;
  useEffect(() => {
    getAllData();
    if (id) {
      getAnkRandomly();
    }
    if (role_id === 2) {
      let body = {
        business_id: business_id,
      };
      dispatch(myPurchageList(body)).then((res) => {
        res.payload.data &&
          res.payload.data.purches_courses.map((list) => {
            if (list.course_id == courseid) {
              setSingleCourses(list.unassign_course_count);
            }
          });
      });
    }
    return () => {
      // Anything in here is fired on component unmount.
      if (parseFloat(localStorage.getItem("per")).toFixed(2) > 0 && role_id) {
        let data = {
          id: courseid,
          user_id: id,
          media_id: localStorage.getItem("mediaIdOnLeftCom"),
          view_time: localStorage.getItem("per"),
        };
        dispatch(videoProgress(data))
          .unwrap()
          .then(() => { })
          .catch(() => { });
      }
    };
  }, [courseid]);
  const onSetURL = (item, index) => {
    //window.scrollTo(0, 0);
    if (
      allItems.is_purchaed ||
      allItems.is_assigned ||
      role_id === 1 ||
      item.is_free ||
      allItems.podcast
    ) {
      if (role_id) {
        setMediaIdIndex(index + 1);
        setMediaLenth(item.media_length);
        setMediaIdForPercent(item.media_id);
        localStorage.setItem("mediaIdOnLeftCom", item.media_id);
        setMainVideoURL(item?.video_url);
        setMainTitle(item?.media_title);
        setCourse_sub_title(item?.course_sub_title)
        setMainDiscription(item.description != "null" ? item.description : "");
        setMainPrice(item?.s_price);
        setImg(item.image);
        setCourseId(item.id);
      } else {
        toast.warning(<>{t("Please_register_or_login_to_see_free_courses")}</>);
        navigate("/registrera");
      }
    } else {
      if (role_id === 2 || role_id === 3 || !role_id) {
        toast.error(<>{t("Du_har_inte_köpt_den_här_kursen")}</>);
        scrollToSection('buysection')
      }
      if (role_id === 4) {
        toast.error(
          "Du har inte blivit tilldelad den här kursen"
        );
      }
      //  notify()
    }
    // call API to set half seen
    if (percentage > 0 && role_id) {
      let data = {
        id: courseid,
        user_id: id,
        media_id: mediaIdForPercent,
        view_time: percentage,
      };
      dispatch(videoProgress(data))
        .unwrap()
        .then(() => { })
        .catch(() => { });
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };
  const onSetEdit = (item, index) => {
    setEditId(item.media_id);
    setEditName(mainTitle);
    setEditCourseId(item.course_id);
    setShowEdit(true);
  };
  const addCourseToCart = () => {
    dispatch(addProductToCart(allItems))
      .unwrap()
      .then(() => {
        navigate("/cart");
      })
      .catch(() => { });
  };
  const callbackModal = () => {
    setShow(false);
    setShowTestList(false);
    setShowEdit(false);
  };
  const closeAdd = () => {
    setShowAdd(false);
    getAllData();
  };
  const getAllData = () => {
    let req = {
      id: courseid,
    };
    if (id) {
      req.user_id = id;
      req.role_id = role_id;
    }
    dispatch(getSingleProduct(req)).then((course) => {
      setAllItems(course.payload.data);
      setAlllength(course.payload.data?.media.length);
      setMainVideoURL(course.payload.data.media[0].video_url);
      setMediaIdForPercent(course.payload.data.media[0].media_id);
      setMediaLenth(course.payload.data.media[0].media_length);
      localStorage.setItem(
        "mediaIdOnLeftCom",
        course.payload.data.media[0].media_id
      );
      setMainTitle(course.payload.data?.title);
      setCourse_sub_title(course.payload.data?.course_sub_title)
      setMainPrice(
        course.payload.data.s_price
          ? course.payload.data.s_price
          : course.payload.data.r_price
      );
      setImg(course.payload.data.course_image);
      setCourseId(course.payload.data.id);
      setMainDiscription(
        course.payload.data?.content != "null"
          ? course.payload.data?.content
          : ""
      );
    });

    dispatch(getSubsPlan())
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          let newArr = [];

          res.data
            .map((data) => {
              if (data.price_id == yearlyPlanName) {
                newArr.push(data);
              }
              if (data.price_id == monthlyPlan) {
                setSelectedPlan(data.price / 100);
                newArr.push(data);
              }
            })
            .filter((data) => (data = data));
        }
      })
      .catch(() => { });
  };
  let totalLength;
  let scd;
  var myinter = setInterval(function () {
    iframe = document.querySelector("#videoIframe");
    if (iframe?.src) {
      player = new Player(iframe);
      player
        .getDuration()
        .then(function (duration) {
          totalLength = duration;
        })
        .catch(function (error) {
          // an error occurred
        });
      player.getCurrentTime().then(function (seconds) {
        scd = seconds;
        setPercentage((scd / totalLength) * 100);
        localStorage.setItem("per", (scd / totalLength) * 100);
      });
      if (percentage > 99.9 && role_id) {
        let data = {
          id: courseid,
          user_id: id,
          media_id: mediaIdForPercent,
          view_time: 100,
          is_complete: true,
        };
        dispatch(videoProgress(data))
          .unwrap()
          .then(() => {
            clearInterval(myinter);
          })
          .catch(() => { });
      }
    }
  }, 1000);
  const saveNote = () => {
    var data = {
      user_id: id,
      media_id: mediaIdForPercent,
      note_content: textContent,
    };
    dispatch(createNote(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.success(<>{t("Din_reflektion_har")}</>);
        }
      })
      .catch((error) => {
        // toast.error(error.message)
      });
  };
  //  get anki randomly
  const getAnkRandomly = () => {
    setShowAns(false);
    dispatch(randomAnkiQuestion(courseid)).then((course) => {
      setAnkiQuestion(course.payload.data);
    });
  };
  const onShowans = () => {
    setShowAns(true);
  };
  const onChange = (e) => {
    setShowAns(false);
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit && isReadMore) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const handleClose = () => {
    setShowCreator(false);
  };
  const openShow = () => {
    setShowCreator(true);
  };
  
  const BuySection = () => {
    return (<>
      {checkSubsDate(user?.subscription?.trial_end) == -1 &&
        checkSubsDate(user?.subscription?.expire_at) == -1 &&
        !allItems?.is_purchaed && (


          <div className="bg-darkblock p-xl-10 p-6  text-darkblock fw-lighter font-16 dark radius-20  greysec-block " >
            <h2 className="text-white font-32 fw-lighter border-bottom border-white pb-5 mb-3 mobile-font-20">
              {allItems?.title}
            </h2>
            <div className="border-white border-bottom pb-4 mb-3">
              <h5 className="text-white fw-medium font-20 mb-3">
              Ingår i kursen:
              </h5>
              <p className="text-white"
                dangerouslySetInnerHTML={{
                  __html: allItems?.included_in_course,
                }}
              ></p>
            </div>
            <div className="border-bottom-none">
              <h5 className="text-white fw-medium font-20 mb-3">
                Den här kursen är för dig som:
              </h5>
              <p className="text-white"
                dangerouslySetInnerHTML={{
                  __html: allItems?.suitable_for,
                }}
              ></p>


            </div>

           
            <Button
              onClick={() => generateCheckoutURL()}
              variant=""
              className="d-flex justify-content-between mt-3 px-8 gap-4 w-100 align-items-center btn-gradient mb-md-0 mb-2"
            >
              <span className="fw-light font-20 mobile-font-13">
              Köp kursen
              </span>
              <span className="fw-normal font-22">{mainPrice} kr</span>
            </Button>

            <a
              onClick={()=>window.open("https://cal.com/docens")}
              className="btn btn-outline-light fw-light font-20 px-8 d-flex align-items-center justify-content-between gap-4  mt-md-7 mt-0 "
            >
              <span className="mobile-font-13">Fler än 1 licens?</span>
              <span className="fw-normal font-22">Boka möte</span>
            </a>
            
            
            {/* <a
              onClick={() => generateCheckoutURLSubscription("month")}
              className="btn btn-outline-light fw-light font-20 d-flex align-items-center justify-content-between gap-4 mt-16px"
            >
              <span className="mobile-font-13">Se alla kurser</span>
              <span className="fw-normal font-24">
                {selectedPlan} kr/mån
              </span>
            </a> */}
            {/* <Button
              onClick={() => generateCheckoutURLSubscription("month")}
              variant=""
              className="d-flex justify-content-between mt-3  gap-4 w-100 align-items-center btn-gradient mobile-minh53"
            >
              <span className="fw-light font-20 mobile-font-13">
                Se alla kurser
              </span>
              <span className="fw-normal">gratis i 30 dagar</span>
            </Button>
            <p className="year_P mb-0 no-border-p">Därefter 499 kr/mån - avbryt när du vill</p> */}
          </div>
        )}


    </>)
  };
  const MediaList = ({ item, onSetURL, index }) => {
    return (

      <div className="full-width course-listing-table font-20 course-text-right mobile-font-13 mb-2">
        <div
          className={
            item.is_free
              ? "text-white d-flex justify-content-between pointer "
              : " d-flex justify-content-between link-hover"
          }
        >
          <div onClick={() => onSetURL(item, index)}>
            <p title={item.media_title} className={`mb-0 activate-link ${index === 0 ? 'active':''}`}>
              {add3Dots(item.media_title, 80)}
            </p>
          </div>
          <div className="text-right ps-12 ps-xl-2">
            {role_id === 1 && (
              <a
                className="btn btn-link icon-ic-edit text-white link-hover"
                style={{ "margin-right": "15px" }}
                onClick={() => onSetEdit(item, index)}
              ></a>
            )}
            <span onClick={() => onSetURL(item, index)} className="">
              {item.media_length} min
            </span>
          </div>
        </div>
      </div>

    );
  };


  const generateCheckoutURL = () => {
    let newObj = {
      course_id: allItems?.course_id,
      quantity: 1,
      price: allItems?.s_price ? allItems?.s_price : allItems?.r_price,
      currency: "SEK",
    };
    let newCOurseArr = [];
    newCOurseArr.push(newObj);

    const generateCheckoutURLObj = {
      item_id: course_id,
      item_name: mainTitle,
      s_price: mainPrice * 100,
      image: image,
      user_id: id ? id : null, // if not logged in pass null
      email: email ? email : null, // if not logged in pass null
      role_id: role_id,
      business_id: business_id ? business_id : null,
      currency: "SEK",
      is_course: true,
      mode: "payment", // payment or subscription
      quantity: 1,
      courses: newCOurseArr,
      is_trial:false,
      is_year_wise: false
    };
    dispatch(getCheckoutUrl(generateCheckoutURLObj)).then((data) => {
      if (data.payload.status == 200) {
        window.location.replace(data.payload.data.cs_url);
      }
      // setPackageDetails(packages.payload.data);
    });
  };
  const generateCheckoutURLSubscription = (plan) => {
    let newObj = {
      course_id: allItems?.course_id,
      quantity: 1,
      price: allItems?.s_price,
      currency: "SEK",
    };
    let newCOurseArr = [];
    newCOurseArr.push(newObj);
    const generateCheckoutURLObj = {
      item_id: course_id,
      item_name: mainTitle,
      s_price: mainPrice,
      image: image,
      user_id: id ? id : null, // if not logged in pass null
      email: email ? email : null, // if not logged in pass null
      role_id: role_id,
      business_id: business_id ? business_id : null,
      currency: "SEK",
      is_course: true,
      mode: "subscription", // payment or subscription
      interval: "month", //day, week, month or year
      price_id: plan == "month" ? monthlyPlan : yearlyPlanName,
      is_trial: plan == "month" ? true : false,
      quantity: 1,
      // "courses": []
      courses: newCOurseArr,
      is_year_wise: false
    };
    dispatch(getCheckoutUrl(generateCheckoutURLObj)).then((packages) => {
      if (packages.payload.status == 200) {
        window.location.replace(packages.payload.data.cs_url);
      }
    });
  };
  const setTab = (tab) => {
    setSelectedTab(tab);
  }
  return (
    <>

      <div
        className={` flex-grow-1 overflow-hidden header-border-none  mobile-pb-0 ${!role_id ? "is-not-login pt-0 page-wrapper" : "pt-100"
          } ${role_id && 'innerblock-page'}`}
      >
        {role_id && <Header headerShow={false} />}
        {!role_id && <Header1 />}
        <div className={`${role_id && 'page-wrapper page-block'}`}>
          <section className={`py-80 mobile-pt-15`}>
            <Container fluid style={{ marginTop: role_id ? 50 : 0 }} className="">
              <Row className="gy-md-3 flex-md-row flex-column-reverse">
                <Col md={5}>
                  <div className="tab-courses d-flex align-items-center flex-wrap gap-5 d-md-none fw-medium mobile-mt-20 mb-md-5 mb-3">
                    <a role="button" className={`linkblock ${selectedTab === 'Avsnitt' ? "active" : ""}`} onClick={() => setTab('Avsnitt')}>Om kursen</a>
                    <a role="button" className={`linkblock ${selectedTab === 'Om_kursen' ? "active" : ""}`} onClick={() => setTab('Om_kursen')}>Avsnitt</a>
                    <a role="button" className={`linkblock ${selectedTab === 'Om_läraren' ? "active" : ""}`} onClick={() => setTab('Om_läraren')}>Om läraren</a>
                  </div>
                  <div className={`tabblock-wrap mobile-pb-60 ${selectedTab === 'Avsnitt' ? "active" : ""}`}>
                    <span className="d-md-block d-none font-12 letter-spacing fw-semibold  mb-8">
                      KURS
                    </span>
                    <>
                      <h2 className="font-40 fw-lighter mb-1 mobile-font-30">{mainTitle}</h2>

                      <h4 className="fw-medium font-24 mt-1 margin-b-16 mobile-font-16">
                        {course_sub_title}
                      </h4>
                      <div
                        className="block fw-lighter mb-0 maxw-443 fs-20 line-h-small mobile-font-13"
                        dangerouslySetInnerHTML={{
                          __html: add3Dots(mainDiscription, 320),
                        }}
                      >
                      </div>
                      <span onClick={toggleReadMore} className="read-or-hide link-hover">
                        {isReadMore ? "Läs mer" : " Läs mindre"}
                      </span>
                    </>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="videoblock-wrap">
                    <iframe
                      id="videoIframe"
                      className="videoblock rounded-20 w-100 video-opencourse"
                      src={mainVideoURL}
                      width={400}
                      title="video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="only_mobile d-none">
            <Container fluid>
              <div className={` flex-wrap py-xl-17 py-md-13 pb-0 justify-content-between fw-light couescount-wrap pb-80 border-bottom tabblock-wrap mobile-pb-60 ${selectedTab === 'Om_kursen' ? "active" : ""}`}>
                <div className="text-white teacherinfo-box  wow fadeInDown d-md-block">
                  {allItems.media &&
                    allItems.media.map((item, key) => (
                      <MediaList
                        item={item}
                        key={key}
                        index={key}
                        onSetURL={onSetURL}
                      />
                    ))}
                </div>
              </div>
            </Container>
          </section>


          <div className={`hide_in_mobile block text-lightblock py-80 pe-md-5 mobile-pb-60 tabblock-wrap-desk tab-courses ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`}>
            <section className="mobile-pb-60 mobile-pt-0 hide_in_mobile">
              <Container fluid>
                <Row className="d-flex flex-wrap">
                  <Col sm={12} className="pb-80px hide_in_mobile" >
                    <div className="borderbottom-block"></div>
                  </Col>
                  <Col xl={8} sm={7} >
                    <div className="mobile-pt-0 hide_in_mobile">
                      <div className="d-flex align-items-center flex-wrap gap-5  fw-medium mobile-mt-20 mb-md-5 mb-3 hide_in_mobile">
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`} onClick={() => setTabDesk('deskAvsnitt')}>Avsnitt</a>
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`} onClick={() => setTabDesk('deskOm_läraren')}>Om läraren</a>
                      </div>
                    </div>
                    <div className={` flex-wrap   pb-0 justify-content-between fw-light couescount-wrap pb-80  tabblock-wrap mobile-pb-60 `}>
                      {allItems.media &&
                        allItems.media.map((item, key) => (
                          <MediaList
                            item={item}
                            key={key}
                            index={key}
                            onSetURL={onSetURL}
                          />
                        ))}
                    </div>
                  </Col>
                  <Col xl={4} md={5} >
                  <div id="buysection"></div>
                    <BuySection />
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
          <div className={`block text-lightblock py-80  pe-md-5 mobile-pb-60 tabblock-wrap-desk tab-courses ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`}>
            <section className="mobile-pt-0">
              <Container fluid>
                <Row className="d-flex flex-wrap">
                  <Col sm={12} className="pb-80px hide_in_mobile" >
                    <div className="borderbottom-block"></div>
                  </Col>
                  <Col xl={8} sm={7}>
                    <div className="mobile-pt-0 hide_in_mobile">
                      <div className="d-flex align-items-center flex-wrap gap-5  fw-medium mobile-mt-20 mb-md-5 mb-3 hide_in_mobile">
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`} onClick={() => setTabDesk('deskAvsnitt')}>Avsnitt</a>
                        <a role="button" className={`text-white linkblock ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`} onClick={() => setTabDesk('deskOm_läraren')}>Om läraren</a>
                      </div>
                    </div>
                    <div className="d-xl-flex mt-md-0">
                      <div className={`block text-lightblock d-md-block  pe-xl-5 w-100 mobile-pb-60 tabblock-wrap ${selectedTab === 'Om_läraren' ? "active" : ""}`}>
                        <span className="d-md-block d-none font-12 letter-spacing fw-semibold  mb-8">
                          MÖT DIN LÄRARE
                        </span>
                        <>
                          <h2 className="font-40 fw-lighter mb-1 mobile-font-30">{allItems?.creator_name}</h2>
                          <h4 className="fw-medium font-24 mt-1 margin-b-16 mobile-font-16">
                            {allItems?.sub_title}
                          </h4>

                        </>
                        <div className="block d-block font-20 fw-light mobile-font-13 maxw-443">

                          <div className="mb-00"
                            dangerouslySetInnerHTML={{
                              __html: add3Dots(allItems?.creator_about, 300),
                            }}
                          ></div>
                          <span onClick={toggleReadMore} className="read-or-hide" >
                            {isReadMore ? "Läs mer" : " Läs mindre"}
                          </span>
                          <div className="mb-2"></div>
                          <span className="user-img image-blockwrap d-md-none d-block me-xxl-7 me-0 mb-3 flex-shrink-0 flex-shrink-0">
                            {/* <Image
                      src={
                        allItems?.creator_image
                          ? allItems?.creator_image
                          : "/assets/images/simual.png"
                      }
                      alt=""
                      width={114}
                      height={114}
                      className="img-fit rounded-pill"
                    /> */}
                            <Image
                              src={
                                allItems?.creator_image
                                  ? allItems?.creator_image
                                  : "assets/images/simual.png"
                              }
                              alt="Image"
                              className="img-fit rounded-20"
                            />
                          </span>
                          <div class="d-flex gap-4 buttons">
                            {allItems?.book_me_now_url &&
                              <a class="btn btn-outline-light fs-16" href={allItems?.book_me_now_url}>Boka live</a>
                            }
                            {allItems?.buy_my_book_url &&
                              <a class="btn btn-outline-light fs-16" href={allItems?.buy_my_book_url}>Böcker</a>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="tab-img flex-shrink-0 d-md-block d-none">
                          <Image
                            src={
                              allItems?.creator_image
                                ? allItems?.creator_image
                                : "assets/images/simual.png"
                            }
                            alt="Image"
                            className="img-fit rounded-20"
                          />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4} md={5}>
                    <BuySection />
                  </Col>
                </Row>
              </Container>
            </section>
          </div>


          <section className="py-80 mobile-pt-0">
            <Container fluid>
              <h2 className="fw-light text-center font-40  mb-8">
                Hitta den perfekta kursen för dig!
              </h2>
              <div className="future-slider-list">

                <FeatureCourseList
                  isHide={true}
                  isShowBlue={false}
                ></FeatureCourseList>
              </div>
            </Container>
          </section>
          {/* <section className="pb-120">
            <Container fluid>
              <h2 className="text-center font-40 fw-lighter mb-9 mobile-mb-20">
                Vanliga frågor
              </h2>
              <Accordion_Custome></Accordion_Custome>
            </Container>
          </section> */}
        </div>

      </div>

      {showEdit && (
        <AddNewProduct
          show={showEdit}
          editId={editId}
          editCourseId={editCourseId}
          editName={editName}
          callbackModal={callbackModal}
        />
      )}
      <Modal show={showCreator} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{allItems?.creator_name} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="usermanagement-modal">
          <p
            className="font-18"
            dangerouslySetInnerHTML={{ __html: allItems?.creator_about }}
          ></p>
        </Modal.Body>
      </Modal>

      <Footer />

    </>
  );
};
export default OpenCourseTopics;
