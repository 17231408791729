import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { createPaymentIntent,completeTrasaction,getCoupanList,appybackendCoupon } from '../../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button,Modal, Row,Col } from 'react-bootstrap';
import { useNavigate,Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { addCupen,clearFullcart } from '../../store/api/cart';
import {googlePurchangeEvent,facebookPurchangeEvent} from '../commom/facebookandgoogleevents';
export default function CheckoutForm() {
  const { total,cart,originalTotal } = useSelector((state) => state.cart);
  
  const { t } = useTranslation();
  const [currency] = useState("SEK");
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const [show, setShow] = useState(false);
  const [setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [ isLoading, setProcessing] = useState(false);
  const { business_id,id } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [value, setValue] = useState({ coupen: "",name:'',email:''});
  const [selectedtc, setSelectedTc] = useState(false);
  const [selectedmk, setSelectedMk] = useState(true);
  const [totalQty,setQty] = useState(0);
  const [couponList,setCoupanList] = useState();
  const [couponTosend,setCoupanToSend] = useState();
  useEffect(() => {
    let qtyTot = 0;
      cart.map(cart => {
        qtyTot = qtyTot + Number(cart.quantity);
      });
      setQty(qtyTot);
  }, [cart]);
  
  useEffect(() => {
    dispatch(getCoupanList()).then(async (res) => {
      if (res.payload.status == 200) {
        setCoupanList(res.payload.data)
      }
    }).catch(err => {
      toast.error(err.message)
    });
  }, []);
 
  const onChange = (e) => {
    console.log(e.target.value)
     setValue({
       ...value,
       [e.target.name]: e.target.value,
     });
   };
  const handleClose = () => {
    setShow(false);
    dispatch(clearFullcart())
      .unwrap()
      .then(() => {
        
      })
      .catch(() => {
         
      });
    navigate('/my-course-details'); 
  };

  const handleSubmitContact = () =>{
    navigate('/kontakta-oss'); 
  }

  const handleSubmit = async ev => {
let purches_courses = [];
    cart.map((item, key) => {
      
      if (business_id) {
        purches_courses.push({
          "course_id": item.course_id,
              "quantity": item.quantity,
              "price": item.r_price,
              "currency": "SEK",
              "business_id": business_id
        })
      } else {
        purches_courses.push({
          "course_id": item.course_id,
              "quantity": item.quantity,
              "price": item.r_price,
              "currency": "SEK",
              
        })
      }
  });
    ev.preventDefault();
   setProcessing(true);
    // Step 2: Create PaymentIntent over Stripe API
    dispatch(createPaymentIntent({
      payment_method_types: ["card"],
      amount: total * 100,
      currency: 'sek',
    })).then(async (res) => {
      setTimeout(async () => {
      const payload = await stripe.confirmCardPayment(res.payload.data.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: value.name,
            email: value.email
          }
        }
      });

      if (payload.error) {
        toast.error(`Payment failed: ${payload.error.message}`);
        setProcessing(false);
        console.log("[error]", payload.error);
      } else {
      //  setError(null);
        setSucceeded(true);
        setProcessing(false);
        setMetadata(payload.paymentIntent);
        dispatch(completeTrasaction({
          payment_intent_id: payload.paymentIntent.id,
          purches_courses:purches_courses
        })).then(async (res) => {
          googlePurchangeEvent('',((total * 100)*totalQty),'','Single',total,totalQty);
          facebookPurchangeEvent(total * 100);
        //  setShow(true)
          dispatch(appybackendCoupon({
            user_id: id,
            coupon:couponTosend
          })).then(async (res) => {
           
            setShow(true)
          //  navigate('/my-course-details'); 
          }).catch(err => {
            setProcessing(false);
            toast.error(err.message)
          });
        //  navigate('/my-course-details'); 
        }).catch(err => {
          setProcessing(false);
          toast.error(err.message)
        });
      }
    }, 1000);
    }).catch(err => {
      // setError(err.message);
      setProcessing(false);
      toast.error(err.message)
    });


    
  };
  const applyCoupen =() => {
    if (total > 0)
    {
      couponList?.map((item) => {
        if(item.coupon_name === value.coupen) {
          setCoupanToSend(item.coupon_value)
          dispatch(addCupen({'percent':item.percentage}));
          toast.success('Rabattkod!');
        }
      })
  } else {
    toast.error('Not applicable on 0 !')
  }
  }
  const renderSuccess = () => {
    return (
      <div className="sr-field-success message">
        <h1>Your test payment succeeded</h1>
        <p>View PaymentIntent response:</p>
        <pre className="sr-callout">
          <code>{JSON.stringify(metadata, null, 2)}</code>
        </pre>
      </div>
    );
  };

  const renderForm = () => {
    const options = {
      hidePostalCode: true,
      style: {
        base: {
          color: "#ffffff",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          Border: "1px solid #313131",
          fontSize: "16px",
          "::placeholder": {
            color: "#ffffff66"
          },
          lineHeight: '29px',
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#ffffff"
        }
      },
    };
    const handletc = () => {
      setSelectedTc(!selectedtc);
    };
    const handlemk = () => {
      setSelectedMk(!selectedmk);
    };

    return (
      <>

<div className='cart-rightwrapper'>
        <Form  >
          <Form.Group className="mb-lg-13 mb-10 wow fadeInDown" controlId="formBasicEmail">
            <Form.Control type="email" placeholder={t('Email_Address')} aria-label='Email Address' name="email" onChange={onChange} value={value.email} />
          </Form.Group>
          <Form.Group className="mb-lg-13 mb-10 wow fadeInDown" controlId="name">
            <Form.Control type="text" placeholder={t('Card_Holder')}  name="name" aria-label='Card Holder' onChange={onChange} value={value.name}  />
          </Form.Group>
         
            <CardElement className="form-control st-controll"   options={options} />
        
          <div className='pt-13 wow fadeInDown'>
           
            <Row >
            <Col  md={8} >
            <Form.Group className=" wow fadeInDown" controlId="coupen">
            <Form.Control type="text" placeholder={t('Do_You_have_Coupen')}  name="coupen" aria-label='coupen' value={value.coupen} onChange={onChange} />
          </Form.Group>
            </Col>
            <Col  md={4} >
            <Button variant="primary" type="button" className='btn' onClick={applyCoupen} >
            {t('Apply_coupen')}
          </Button>
            </Col>
            </Row> 
          </div>
          <div className='pt-13 wow fadeInDown'>
                           <div className='cart-submain d-flex flex-wrap text-white-25'>
                           
                              <div className='cart-subleft mb-0'>
                                 <h6 className='fw-medium mb-0'>Pris</h6>
                              </div>
                              <div className='cart-subright '>
                                 <p className='font-20 fw-medium mb-0'>
                                 
                                  {currency.toLocaleUpperCase()}{" "}
                                   {originalTotal.toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}  
                                  </p>
                              </div>
                           </div>
                           <div className='cart-submain d-flex flex-wrap text-white-25 font-13 mb-2'>
                              <div className='cart-subleft '>
                                 <span className='mb-0'>Rabatt</span>
                              </div>
                              <div className='cart-subright'>
                                 <span className='fw-medium mb-0'> 
                                - {currency.toLocaleUpperCase()}{" "}
                                  {(originalTotal - total).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}
                                 </span>
                              </div>
                           </div>
                           <div className='cart-submain d-flex flex-wrap text-white-25 font-13 mb-8'>
                              <div className='cart-subleft '>
                                 <span className='mb-0'>Slutpris</span>
                              </div>
                              <div className='cart-subright'>
                                 <span className='fw-medium mb-0'> 
                                 {currency.toLocaleUpperCase()}{" "}
                                  {(total).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2
                                  })}{" "}
                                 </span>
                              </div>
                           </div>
                           <div className="mb-4">
                          
                          <div className="p-default">
                            <input type="checkbox" name="tc" value={value.tc} onChange={handletc} />
                            <label> &nbsp; {t('I_agree_to_the')} <a className="tc_link" href="/anvandarvilkor" target="_blank" ><b>{t('Terms_of_Use')}</b></a> {t('och')} <a className="tc_link" href="/integritetspolicy" target="_blank" ><b>{t('My_courses_Questions_and_answers')}</b></a></label>
                          </div>
                        
                          <div className="p-default">
                            <input type="checkbox" name="mk" value={value.mk} checked={selectedmk} onChange={handlemk}  />
                            <label> &nbsp;{t('getting_marketing_emails')}</label>
                          </div>
                       
                    </div>
                        </div>
                     
                        
          {/* {totalQty <= 99 &&           */}
          <Button variant="primary" type="button" className='pay-btn w-sm-100 wow fadeInDown' disabled={!selectedtc}  onClick={handleSubmit}  >
            {t('Pay')} <span>
              {currency.toLocaleUpperCase()}{" "}
              {total.toLocaleString(navigator.language, {
                minimumFractionDigits: 2
              })}{" "}
            </span>
            </Button>
          {/* } */}
          {/* {totalQty > 99 && 
          <Button variant="primary" type="button" className='pay-btn w-sm-100 wow fadeInDown' disabled={!selectedtc}  onClick={handleSubmitContact}  >
          {t('Contact_Us')}
          </Button>
        } */}
          <div style={style}>
      {isLoading &&
       <div className="loading">
       <div className="row">
         <div className="spin">
             <span className="one"></span>
             <span className="two"></span>
             <span className="three"></span>
         </div>
       </div>
     </div>
      }
    </div>
        </Form>
      </div>
       <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <h5> {t('Thanks_for_buying')}</h5>
            </Modal.Header>
            
            <Modal.Footer className="display-class">
                     <Button variant="primary" type="button" className='font-18 min-200  w-sm-100 wow fadeInDown' onClick={handleClose}> {t('Fortsätt')} </Button>
            </Modal.Footer>
         </Modal>
      </>
      
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderForm() : renderForm()}
      </div>
    </div>
  );
}
