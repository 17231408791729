import { Container } from "react-bootstrap";
import React, {useEffect} from 'react';
import Header from '../commom/Header';


const PrivacyPolicy = () =>{
  
   useEffect(() => {
    
    }, [])
   return(
      <>
      <div className='d-flex flex-column flex-grow-1 homepage'>
         <Header />
         <div className='page-wrapper flex-grow-1 py-0'>
            {/* Starts Page Banner */}
            <section className="banner-wrapper text-start position-relative bg-wrap mb-5">
               <Container>
               <div className="block font-32 text-center fw-light video-style-font">
                  <h1 className="font-64 mb-md-3 mb-0">Integritetspolicy</h1>
                  <p className="d-none d-md-block">Fortress edu AB</p>
               </div>
               </Container>
               <div className="banner-video">
               <video
               src="/assets/video/Background-flow.mp4"
               autoplay="autoplay"
               playsinline="playsinline"
               muted="muted"
               loop="loop"
               ></video>
         </div>
            </section>
            {/* Ends Page Banner */}
            <div className="pageContainer">
               <div className="container-fluid container--small container--big__text">
                  <h1 className="h1">Integritetspolicy Docens</h1>
                  <p>Gäller från och med 17:e oktober 2022 </p>
                  <h6>1. Inledning</h6>
                  <div>
                     <p>
                     Välkommen till DOCENS - en plattform för utbildningar som riktar sig till företag och privatpersoner. Vi tillhandahåller en mängd utbildningar samt möjlighet att skräddarsy utbildningar efter ert behov.
                     </p>
                     <p>
                     Vår målsättning är att du, efter att ha läst denna policy, ska känna dig säker på att din personliga integritet respekteras och att dina personuppgifter behandlas korrekt. Vi arbetar därför också kontinuerligt med att säkerställa att vår behandling av personuppgifter till fullo sker i enlighet med gällande lagstiftning och då främst Dataskyddsförordningen (GDPR). 
                     </p>
                  </div>

                  <h6>2. Vad är en personuppgift och vad innebär behandling av personuppgifter? </h6>
                  <div>
                     <p>
                     2.1 Personuppgifter är all information som direkt, eller indirekt tillsammans med andra uppgifter, kan kopplas till en levande fysisk person. En icke-uttömmande lista av exempel på personuppgifter är bl.a.:
                     </p>
                     <ul>
                        <li>Namn</li>
                        <li>	Personnummer</li>
                        <li>	Mail-adress</li>
                        <li>	Kreditkortsnummer</li>
                        <li>	IP-adress</li>
                        <li>	Bilder</li>
                        <li>	Användardata</li>

                     </ul>
                     <p>
                     2.2 Behandling av personuppgifter innefattar varje åtgärd som vidtas med personuppgifterna, oberoende av om den utförs automatiserat eller ej. Det här innebär att bl.a. följande åtgärder omfattas:
                     </p>
                     <ul>
                        <li>Insamling</li>
                        <li>Registrering</li>
                        <li>Användning</li>
                        <li>Sammanförande</li>
                        <li>Överföring</li>
                        <li>Radering</li>
                        

                     </ul>
                  </div>
                  
                  <h6>3. För vem gäller policyn? </h6>
                  <div>
                     <p>
                     Denna Integritetspolicy är i första hand tillämplig på samtliga individer som registrerar ett användarkonto för att nyttja vår plattform (i fortsättningen kallade för ”Registrerade” eller ”Användare”). 
                     </p>
                     <p>Olika delar av denna Integritetspolicy kan även vara relevanta för dig beroende på din relation till DOCENS, beroende på om du exempelvis köper en licens till någon av våra utbildningar, beställer en skräddarsydd utbildning, besöker vår hemsida eller kontaktar oss i sociala medier.</p>
                  </div>

                  <h6>4. För vad gäller policyn?</h6>
                  <div>
                     <p>Denna Integritetspolicy reglerar hur vi på DOCENS samlar in och behandlar personuppgifter för att kunna leverera och fortsätta utveckla vår plattform.</p>
                  </div>

                  <h6>5. Vad innebär det att vara Personuppgiftsansvarig?</h6>
                  <div>
                     <p>
                     Personuppgiftsansvarig är en fysisk eller juridisk person eller annat organ som bestämmer ändamålet och medlen för behandlingen av personuppgifter. Ett företag är personuppgiftsansvarig med avseende på personuppgifter de innehar för egen räkning om sina anställda, kunder, leverantörer och andra. 
                     </p>
                  </div>

                  <h6>6. DOCENS som Personuppgiftsansvarig </h6>
                  <div>
                     <p> <b>Fortress Edu AB</b> (org.nr. 559376-5521) Dumpergatan 3, 442 40 Kungälv) är personuppgiftsansvarig för den behandling som sker avseende dina personuppgifter inom ramen för DOCENSs plattform och ansvarar för att sådan behandling sker i enlighet med tillämplig lagstiftning. </p>
                  </div>
                  
                  <h6>7. Varför får vi behandla personuppgifter?</h6>
                  <div>
                     <p>7.1 För att det ska vara tillåtet att behandla personuppgifter måste det alltid finnas ett stöd i GDPR, en så kallad rättslig grund. En sådan rättslig grund är bl.a. </p>
                  <ul>
                     <li>ett samtycke från den Registrerade</li>
                     <li>att personuppgiftsbehandlingen är nödvändig för att fullgöra ett avtal (i det här fallet DOCENS användarvillkor) med den Registrerade</li>
                     <li>att fullgöra en rättslig förpliktelse, exempelvis avseende att spara uppgifter p.g.a. bokföringsskyldighet </li>
                     <li>att efter en intresseavvägning göra bedömningen att DOCENS intresse av att behandla en personuppgift är större än den registrerades intresse av skydd för densamma.</li>
                  </ul>
                  <p>7.2 DOCENS behandlar alltid dina personuppgifter i enlighet med tillämplig lagstiftning. Vår främsta grund för att behandla dina personuppgifter är att det är nödvändigt för att fullgöra vårt avtal med dig angående användning av vår plattform. </p>
                  <p>7.3 Det finns också situationer där vi har ett berättigat intresse av att behandla dina personuppgifter, t.ex. vid intresse av att få marknadsföra oss mot besökare i våra digitala kanaler eller vid intresse av att utveckla vår plattform.</p>
                  <p>7.4 För det fall att vi på DOCENS skulle behandla dina personuppgifter för något ändamål som enligt tillämplig lagstiftning kräver ditt samtycke, så kommer vi alltid att inhämta ditt samtycke i förväg.</p>
                  <p>7.5 Det kan förekomma att samma personuppgift behandlas både med stöd av fullgörande av ett avtal, specifikt av samtycke eller med stöd av att uppgiften är nödvändig för att uppfylla andra rättsliga förpliktelser. Detta innebär att även om du återkallar ditt samtycke och den behandling som grundas på samtycket upphör, kan personuppgiften ändå finnas kvar hos oss för andra ändamål. I huvudsak behandlar vi dock endast dina uppgifter för att fullgöra ett avtal i vilket du är part. För mer information, kontakta info@docens.se</p>
                  </div>
                  
                  <h6>8. Vilka personuppgifter samlar vi in? </h6>
                  <div>
                     <p>I den här delen av vår policy vill vi ge dig några exempel på hur vi hanterar personuppgifter för att garantera att vi kontinuerligt levererar fantastiska upplevelser med en hög servicenivå.</p>
                  <p>8.1 När du registrerar ett användarkonto på vår plattform</p>
                  <p>När du registrerar ett användarkonto på vår plattform så hanterar vi följande uppgifter som du själv lämnar till oss:</p>
                  <ul>
                     <li>Namn</li>
                     <li>Födelseår</li>
                     <li>E-postadress</li>
                     <li>Telefonnummer </li>
                  </ul>
                     <p>8.1.1 Vi hanterar dina personuppgifter för att:</p>
                  <ul>
                     <li>Identifiera dig i anslutning till ditt användande av plattformen</li>
                     <li>Debitera dig för de tjänster som du köper</li>
                     <li>Kontakta dig med information som berör ditt användande av plattformen, via t.ex. e-post och sms</li>
                     <li>Ta fram statistik och genomföra analyser i syfte för att förbättra vår plattform och dess erbjudanden</li>
                     <li>Tillhandahålla, underhålla, testa, förbättra och utveckla de vår plattform och den tekniska plattform som används för att tillhandahålla denna</li>
                     <li>Säkerställa säkerheten för vår plattform, för att upptäcka eller förhindra olika typer av olaglig användning eller användning som på annat sätt strider mot våra användarvillkor</li>
                     <li>Informera dig om personliga och anpassade erbjudanden, kampanjer och förmåner från oss och våra samarbetspartners, via t.ex. e-post och sms</li>
                  </ul>
                  <p>8.1.2 Rättslig grund för hanteringen: </p>
                  <p>Vi hanterar dina personuppgifter med stöd i fullgörande av avtal när vi uppfyller våra åtaganden gentemot dig som Användare (t.ex. när vi administrerar service kopplat till ditt användarkonto och när vi tillhandahåller relevanta erbjudanden) och med stöd av intresseavvägning när vi har ett berättigat intresse att använda uppgifter om din aktivitet på plattformen för att ta fram statistik och för att utveckla, förbättra och säkerställa säkerheten för vår plattform.</p>
                  <p>8.1.3 Lagringstid: </p>
                  <p>Vi sparar dina personuppgifter i högst 24 månader efter det att du senast genomfört ett köp eller på annat sätt interagerat med vår plattform samt i förekommande fall i sju år i form av räkenskapsmaterial (i enlighet med gällande lagstiftning). </p>
                  <p>8.2 När du betalar för våra tjänster</p>
                  <p>
                  Betalning erläggs i direkt anslutning till beställning av en licens, flera licenser eller en skräddarsydd utbildning och hanteras antingen via våra samarbetspartners eller direkt genom fakturering. Varje betalning av en licens eller utbildning fullgörs genom den betalningstjänst som vår samarbetspartner tillhandahåller (alternativt genom sedvanlig fakturering). Vid nyttjandet av separata betalningstjänster tillämpas respektive leverantörs vid var tid gällande villkor. 
                  </p>
                  <p>8.3 När du kommunicerar med oss</p>
                  <p>Du kan välja att kommunicera med oss på flertalet sätt, bland annat genom våra konton i sociala medier eller med vår kundtjänst via epost.</p>
                  <p>När du kommunicerar med oss så hanterar vi följande uppgifter som du själv lämnar till oss: </p>
                  <ul>
                     <li>
                        Ditt namn och kontaktinformation (telefonnummer, epost)
                     </li>
                     <li>Information om din fråga, synpunkt eller ärende</li>
                     <li>Övrig information som du bestämmer dig för att inkludera i ditt meddelande</li>
                  </ul>
                  <p>8.3.1 Vi hanterar dina personuppgifter för att:</p>
                  <ul>
                     <li>Besvara dina frågor och hantera ditt ärende, exempelvis genom att avhjälpa fel och hantera klagomål</li>
                     <li>Förbättra våra tjänster och den information som vi kommunicerar via våra olika kanaler</li>
                  </ul>
                  <p>8.3.2 Rättslig grund för hanteringen: </p>
                  <p>Vi hanterar dina personuppgifter med stöd i vårt och ditt berättigade intresse av att hantera ärendet (intresseavvägning)</p>
                  <p>8.3.3 Lagringstid: </p>
                  <p>För att säkra spårbarhet sparar vi uppgifter om vår kommunikation med dig i 12 månader. </p>
                  <p>8.4 Användning av vår plattform</p>
                  <p>DOCENS samlar in data kopplat till ditt användande av vår plattform. Sådan data innefattar exempelvis </p>
                  <ul>
                     <li>information om din typ av användarkonto och dina interaktioner med plattformen och dess tillhörande applikationer</li>
                     <li>tekniska data, vilket kan innefatta URL-information, cookie-data, din IP-adress, nätverksinformation samt de typer av enheter du använder för att få tillgång till vår plattform</li>
                     <li>tekniska data via Google Analytics, läs mer om hur Google använder informationen från vår webbplats på www.google.com/policies/privacy/partners/</li>
                     
                  </ul>
                  </div>
                  
                  <h6>9. För vilka ändamål behandlas personuppgifter? </h6>
                  <div>
                     <p>De personuppgifter om dig som samlas in i anslutning till att du använder DOCENSs plattform kommer att behandlas av DOCENS, eller våra samarbetspartners, för följande ändamål: </p>
                  <ul>
                     <li>för att tillhandahålla, personanpassa och förbättra din upplevelse av plattformen samt administrera vårt avtal med dig. </li>
                     <li>För att vi ska kunna kommunicera med dig, exempelvis för att möjliggöra kundvård och kundservice, vid kontakt via telefon, epost, notifikationer samt genom våra konton i sociala medier. </li>
                     <li>För marknadsföringsändamål, däribland för marknadsföring via epost, som du givetvis kan avregistrera dig ifrån via en länk i varje separat utskick. </li>
                     <li>För att behandla dina betalningar samt förhindra eller upptäcka bedrägerier. </li>
                     <li>För att ta fram statistik över användningen av våra digitala kanaler och plattformen.</li>
                     <li>För att underhålla, utveckla, testa och förbättra våra digitala kanaler och de tekniska plattformar som de tillhandahålls på. </li>
                  </ul>
                  </div>

                  <h6>11. Hur länge sparar vi personuppgifter?</h6>
                  <div>
                     <p>11.1 Dina personuppgifter sparas endast under den period som det finns ett behov av att spara dem för att fullgöra de ändamål som uppgifterna samlades in för i enlighet med denna Integritetspolicy. DOCENS kan komma att spara uppgifterna längre om det behövs för att följa lagkrav eller för att bevaka DOCENSs rättsliga intressen. </p>
                     <p>11.2 DOCENS sparar uppgifter om den Registrerade i högst 24 månader efter det att personen senast genomfört ett köp eller på annat sätt interagerat med vår plattform.</p>
                  </div>
                  
                  <h6>12. Våra åtgärder för att skydda dina personuppgifter </h6>
                  <div>
                     <p>12.1 Vi på DOCENS har säkerställt att vi har vidtagit lämpliga tekniska och organisatoriska åtgärder för att skydda dina personuppgifter mot bland annat förlust, missbruk och obehörig åtkomst. </p>
                  <p>12.2 För att tekniskt sett tillförsäkra att personuppgifter behandlas på ett säkert och konfidentiellt sätt använder vi oss av digitala nätverk som är intrångsskyddade med hjälp av exempelvis kryptering, brandväggar och lösenordsskydd. Vidare finns utvecklade system för att hantera och vid behov, genom behörighetsstyrning, begränsa tillgång till diverse personuppgifter, baserat på deras karaktär och känslighet. För det fall att ett intrång äger rum har DOCENS skapat goda rutiner för identifiering, skademinimering och rapportering. Slutligen har DOCENS även utarbetat en väl fungerande metod för att tillgodose den registrerades rättigheter, bland annat avseende rätten att bli bortglömd.</p>
                  <p>12.3 DOCENS har organisatoriskt sett säkerställt att samtliga anställda, konsulter samt de leverantörer som DOCENS använder för att tillhandahålla av vår plattform är bundna av sekretessavtal och är skyldiga att följa DOCENSs regler för informations- och IT-säkerhet samt denna Integritetspolicy. För att säkerställa en god kunskapsnivå avseende behandling av personuppgifter arrangeras löpande utbildningar, både för DOCENSs anställda samt de konsulter som från tid till annan anlitas för att utföra uppdrag åt bolaget.</p>
                  </div>

                  <h6>13. När delar vi med oss av personuppgifter? </h6>
                     <div>
                        <p>13.1 DOCENS kommer inte att sälja, lämna ut eller sprida personuppgifter till tredje part, med undantag för vad som anges i denna Integritetspolicy. Inom ramen för vår plattform kan personuppgifter lämnas vidare till exempelvis underleverantörer och samarbetspartners, om det är nödvändigt för utförandet och tillhandahållandet av plattformen. För de fall att vi väljer att dela med oss av personuppgifter ingår vi ett s.k. personuppgiftsbiträdesavtal för att säkerställa att mottagaren av personuppgifterna behandlar dessa uppgifter i enlighet med tillämplig lagstiftning samt att mottagaren har vidtagit nödvändiga tekniska och organisatoriska åtgärder enligt GDPR för att på ett tillfredställande sätt kunna skydda den registrerades rättigheter och friheter.</p>
                        <p>13.2 Vidare kan vi lämna ut personuppgifter om vi är skyldiga till det enligt gällande lag, domstolsbeslut eller om sådant utlämnande annars är nödvändigt för att medverka till en rättslig utredning.</p>
                     </div>
                  

                     <h6>14. Dina rättigheter</h6>
                     <div>
                        <p>14.1 DOCENS ansvarar för att dina personuppgifter behandlas i enlighet med gällande lagstiftning.</p>
                        <p>14.2 DOCENS kommer på din begäran eller på eget initiativ att rätta, avidentifiera, radera eller komplettera uppgifter som upptäcks vara felaktiga, ofullständiga eller missvisande.</p>
                        <p>14.3 Du har rätt att av begära tillgång till dina personuppgifter. Det innebär att du har rätt att begära ett registerutdrag över den behandling som vi genomför avseende dina personuppgifter. Du har även rätt att få en kopia av de personuppgifter som behandlas. Du har rätt att en gång per kalenderår, genom en skriftligt undertecknad ansökan, kostnadsfritt erhålla ett registerutdrag från om vilka personuppgifter som finns registrerade om dig, ändamålen med behandlingen och till vilka mottagare uppgifterna har lämnats eller ska lämnas ut. Du har även rätt att i registerutdraget få information om den förutsedda period under vilken uppgifterna kommer att lagras eller kriterierna som används för att fastställa denna period.</p>
                        <p>14.4 Du har rätt till rättelse av dina personuppgifter. Vi kommer på din begäran att så snabbt som möjligt rätta de felaktiga eller ofullständiga uppgifter vi behandlar om dig.</p>
                        <p>14.5 Du har rätt till radering av dina personuppgifter. Det innebär att du har rätt att begära att dina personuppgifter tas bort om de inte längre är nödvändiga för det ändamål de samlades in för. Det kan dock finnas lagkrav på att vi inte omedelbart får radera dina personuppgifter i exempelvis bokförings- och skattelagstiftning. Vi kommer då att avsluta den behandling som görs för andra ändamål än att följa lagstiftningen.</p>
                        <p>14.6 Du har rätt att invända mot personuppgiftsbehandling som utförs med stöd av en intresseavvägning. Om du invänder mot sådan behandling kommer vi enbart att fortsätta behandlingen om det finns berättigade skäl till behandlingen som väger tyngre än dina intressen.</p>
                        <p>14.7 Om du inte vill att behandlar dina personuppgifter för direkt marknadsföring har du alltid rätt att invända mot sådan behandling genom att antingen avregistrera dig direkt i varje specifikt epostmeddelande, alternativt skicka ett mail till info@docens.se. När har vi mottagit din invändning kommer vi att upphöra med att behandla personuppgifterna för sådant marknadsföringsändamål. När har vi mottagit din invändning kommer vi att upphöra med att behandla personuppgifterna för sådant marknadsföringsändamål. Om du inte är nöjd med hur vi hanterar dina personuppgifter har du också möjlighet att anmäla vår behandling av dina personuppgifter till Integritetsskyddsmyndigheten. För smidig och effektiv hantering rekommenderar vi dock att du i första hand hör av dig till oss så att vi kan hjälpa dig med eventuella frågor och funderingar.</p>
                     </div>
                     
                     <h6>15. Ändringar i denna policy </h6>
                     <div>
                        <p>DOCENS förbehåller sig rätten att revidera denna Integritetspolicy från tid till annan. Datumet för den senaste ändringen anges i slutet av Integritetspolicyn. Om vi gör några ändringar i Integritetspolicyn kommer vi att publicera dessa ändringar på plattformen. Du rekommenderas därför att läsa denna Integritetspolicy regelbundet för att uppmärksamma eventuella ändringar. Om vi ändrar Integritetspolicyn på ett sätt som väsentligt skiljer sig från vad som angavs när ditt samtycke samlades in, kommer vi att underrätta dig om dessa förändringar och vid behov be dig om att på nytt samtycka till DOCENSs personuppgiftsbehandling.</p>
                     </div>

                     <h6>16. Kontakt</h6>
                     <div>
                        <p>Tveka inte att kontakta oss om du har några frågor om denna Integritetspolicy, behandlingen av dina personuppgifter eller om du vill begära ett registerutdrag. Vår kontaktinformation hittar du nedan.</p>
                        <address><b>Fortress Edu AB</b>  (org.nr. 559376-5521) Dumpergatan 3, 442 40 Kungälv</address>
                        <p>Mail: <b><a href="mailto:info@docens.se">info@docens.se</a></b></p>
                     </div>
                  


                  
                  
               </div>
            </div>
            
         </div>
      </div>
      </>
      
   )
}

export default PrivacyPolicy;