import React, { useEffect } from "react";
import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import { useSelector } from "react-redux";
import { Container, Button, Form,Navbar } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';

const PaymenTFail = () => {
  const { id, role_id } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const goBack = () =>{
    navigate('/');
  }
  return (
    <>
      
       <div className="onboarding-page">
      <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
          
      
      <Container fluid>
        <div className="text-white maxw-507 mx-auto">
        {/* <Link to="/" className="back-btnblock mb-8 d-inline-block mobile-mb-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M22.5 27L13.5 18L22.5 9" stroke="white" stroke-width="1.5"/>
              </svg>
                    </Link> */}
          <div className="block mb-8 text-center">
            <h1 className="font-32 maxw-507 mobile-font-20 mobile-mb-20">Ooops!</h1>
            <h4 className="fw-lighter font-24 mt-1 margin-b-16">Betalningen misslyckades</h4>
          </div>
          <div className="d-flex align-items-center justify-content-center">
             <Button variant="primary" type="button" className='mt-2 ' onClick={goBack}> Gå tillbaka</Button>
          </div>
        </div>
      </Container>
    </div>
     
     </>
  );
};

export default PaymenTFail;
