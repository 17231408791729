import { Button, Col, Container, Image, Modal, ModalBody, Row, Form } from "react-bootstrap";
import Header from "../commom/Header2";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDates,singleWebinar } from "../../store/api/podcast.api";
import { sitleft } from "../commom/commonConst";

const PrivateWebinarIndividual = () => {
    let options = [];
    const { id } = useParams();
    const dispatch = useDispatch();
    const [bothDates,setDates] = useState();
    const location = useLocation();
    const [educationData, setEducationData] = useState();
    const [show, setShow] = useState(false);
    const [selectedDate,setSelectedDate]= useState();
    const [deadline, setDeadline] = useState();
    const [hours, setHours] = useState(0);
    const [displayTimer, setDisplayTimer] = useState(true);
    const add3Dots = (string, limit) => {
        if (string) {
            var dots = "...";
            if (string?.length > limit) {
                string = string.substring(0, limit) + dots;
            }
        }
        return string != "null" ? string : "";
    };
    const { role_id } = useSelector((state) => state?.auth?.user || {});
    const navigate = useNavigate();

    const onCLickGotoContactUs = () => {
        if(educationData?.is_no_timer === 1) {
            navigate("/view-webinar/"+id+"/"+"Option00");
        } else {
            setShow(!show)
        }
        
       
    };
    const callbackModal = () => {
        setShow(false);
    };
    useEffect(() => {
        dispatch(getDates()).then((res) => {
            setDates(res.payload.data);
            setDeadline(res.payload?.data[0]);
         });
         let data = {
            slug: id
          }
         dispatch(singleWebinar(data)).then((course) => {
            setEducationData(course.payload?.data[0]);
          });
    }, []);
    bothDates &&
    bothDates.map((list,index) =>
        options.push({ value: `Option`+ index+1, label: moment.utc(Number(list)).format("YYYY-MM-DD HH:mm") })
      );
  
      const onChangeoption = (selectedOption) => {
        navigate("/view-webinar/"+id+"/"+selectedOption.value);
      };

      const getTime = (date) => {
        let dt = new Date();
      dt.setHours(dt.getHours() + 2);
      const time = Date.parse(date) - dt;
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        if (time <= 0) {
            setDisplayTimer(false);
        } else {
            setDisplayTimer(true)
        }
    };
    useEffect(() => {
        if (deadline) {
            const interval = setInterval(() => getTime(moment(Number(deadline)).format("YYYY-MM-DD H:mm")), 1000);
            return () => clearInterval(interval);
        }
      }, [deadline]);
    return (
        <div
            className={` innerblock-page  flex-grow-1`}
        >
            <Header headerShow={false} />
            <div className="page-wrapper flex-grow-1 page-block">
                <InnerHeader title={educationData?.title} subtitle={educationData?.sub_title} backButton={true} backButtonURL="/private-webinar" />
                <div className="py-lg-40px px-xxl-100px wrapper-main  px-lg-30px px-20px flex-grow-1 pb-40px">
                    <div className="shadow-block rounded-20 bg-white mb-md-80px  mb-40px">
                        <div className="p-md-6 px-9 p-5 title-header">
                            <TextBlock title={educationData?.title} subtitle={educationData?.sub_title} />
                        </div>
                        <div className="p-md-6 px-9 p-5">
                            <Row className="flex-md-row flex-column-reverse">
                                <Col md={6}>
                                    <div className="text-svart fw-light d-flex flex-column">
                                        <div className="flex-grow-1 font-20 mobile-font-14"
                                            dangerouslySetInnerHTML={{
                                                __html: educationData?.description,
                                            }}>
                                        </div>
                                        <div>
                                            <button type="button" onClick={onCLickGotoContactUs}  className="mob-w-100 btn btn-small d-sm-inline d-block px-6 btn-gradient me-sm-5 me-0 rounded-pill" title="Intresseanmälan"><span>Säkra din plats</span></button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="ps-md-11 mb-md-0 mb-3 mobile-img-block">
                                        <Image
                                            src={educationData?.image}
                                            alt=""
                                            className="rounded-20 w-100 img-fit"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="shadow-block rounded-20 bg-white overflow-hidden">
                        <div className="p-md-6 px-9 p-5 title-header">
                            <TextBlock title={educationData?.title} subtitle={educationData?.sub_title} />
                        </div>
                        <div>
                            <div className="titleheader-box">
                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Ingår i webinar:</h5>
                                    <div className="fs-20 fw-light text-black webinar-section-text"
                                        dangerouslySetInnerHTML={{
                                            __html: educationData?.what_you_will_learn,
                                        }}
                                    ></div>
                                </div>

                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Det här webinariet är för dig som:</h5>
                                    <div className="fs-20 fw-light text-black block webinar-section-text"
                                        dangerouslySetInnerHTML={{
                                            __html: educationData?.suitable_for,
                                        }}>

                                    </div>
                                </div>
                                {educationData?.is_no_timer === 0 &&
                                <>
                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Datum</h5>
                                    <div className="fs-20 fw-light text-black">
                                    {moment(new Date).format("YYYY-MM-DD")}
                                    </div>
                                </div>
                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Tid</h5>
                                    <div className="fs-20 fw-light text-black">13:00 & 19:00</div>
                                </div>
                                </>
                                }
                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Längd</h5>
                                    <div className="fs-20 fw-light text-black">{educationData?.length} minuter</div>
                                </div>
                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Lärare</h5>
                                    <div className="fs-20 fw-light text-black">{educationData?.creator_name}</div>
                                </div>
                                {displayTimer && educationData?.is_no_timer === 0 &&
                                <div className="title-header p-md-6 px-9 p-5">
                                    <h5 className="fs-20 fw-medium mb-0">Platser kvar</h5>
                                    <div className="fs-20 fw-light text-black">{(educationData?.platser-sitleft(hours,educationData?.platser))}</div>
                                </div>
                                }
                                <div className="text-center p-md-6 px-9 p-5">
                                    <button type="button"  className="btn btn-gradient font-16 mob-w-100 desktop-btn-big" onClick={onCLickGotoContactUs}><span>Säkra din plats</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show && (
                <Modal centered className="modal-innerbox " show={show} onHide={callbackModal}>
                    <Modal.Header closeButton className="closeclass-block">
                        <Modal.Title>Webinar</Modal.Title>
                        <span>Vad för sveriges absolut främsta administratörer?</span>
                    </Modal.Header>
                    <ModalBody>
                        <Form.Group>
                            <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">Välj tid och dag som passar dig bäst</Form.Label>
                            <Select
                                className="custom-select"
                                classNamePrefix="myselect"
                                options={options}
                                onChange={onChangeoption}
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-center mt-5">
                            <Button variant="primary">Säkra din plats</Button>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </div>

    )
}

export default PrivateWebinarIndividual;