import React, { useEffect, useState } from 'react';
import { Form, Button, Image, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { assignCources, myPurchageList, getemployees } from '../../store/api/course.api';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { toast } from 'react-toastify';

const AssignCourse = (prop) => {
   const dispatch = useDispatch();
   const [show, setShow] = useState(false);
   const { t } = useTranslation();
   let options = [];
   let optionsEmployee = [];
   let courseId = [];
   const [allCources, setAllCources] = useState();
   const [assign_course, setAssign_course] = useState();
   const [startDate, setStartDate] = useState(new Date());
   const [expired_at, setExpired_at] = useState(new Date());
   const { business_id, role_id } = useSelector((state) => state.auth.user);
   const [allEmployee, setAllEmployees] = useState();
   const [selectedEmployee, setselectedEmployee] = useState();
   const { stripe_subscription_id } = useSelector((state) => state?.auth?.user?.subscription || {});
   let filter = {
      "filter": {
         "is_delete": 0,
         "business_id":0
      }
   }
   let body = 
      {
         "business_id":business_id,
     }
   if (role_id === 2) {
      filter.filter.business_id = business_id
      filter.filter.role_id = '4'
   }
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   }



   useEffect(() => {
      setShow(prop.show)
      dispatch(myPurchageList(body)).then((course) => {
         if (stripe_subscription_id || role_id === 1) {
            setAllCources(course.payload.data.other);
         } else {
            setAllCources(course.payload.data.purches_courses);
         }
      });
      dispatch(getemployees(filter)).then((res) => {
         setAllEmployees(res.payload.data);
      })
   }, [])

   const selectedValue = () => {

   }
   const onSelect = (selectedList, selectedItem) => {

      setAssign_course(selectedList)
   }
   const onRemove = (selectedList, removedItem) => {
      setAssign_course(selectedList)
   }
   // on press signup button
   const onAdd = () => {
      assign_course && assign_course.map(list =>
         courseId.push({ course_id: list.id, business_id: business_id })
      )
      let body;
      body = {
         'user_id': selectedEmployee,
         'assign_course': courseId,
         'assign_at': startDate,
         'expired_at': expired_at
      }

      dispatch(assignCources(body))
         .unwrap()
         .then((res) => {
            toast.success(res.message);
            handleClose()
         })
         .catch((e) => {
            toast.error(e.message)
         });
   };
   allCources && allCources.map(list =>
      options.push({ name: list.course_title, id: list.course_id })
   )

   const onChangeEmployee = selectedOption => {
      setselectedEmployee(selectedOption.value);
   };
   allEmployee && allEmployee.map(list =>
      optionsEmployee.push({ value: list.id, label: list.first_name })
   )

   return (
      <>
         <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title>{t('Assign_Course')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form className='max-511 mx-auto assigncourse-wrap'>
                  <Form.Group className='mb-10'>
                     {allCources &&
                        <Multiselect
                           options={options}
                           onSelect={onSelect} // Function will trigger on select event
                           onRemove={onRemove} // Function will trigger on remove event
                           displayValue="name" // Property name to display in the dropdown options
                           showCheckbox
                           placeholder={t('select_course')}
                        />
                     }
                  </Form.Group>
                  <Form.Group className='mb-10'>
                     {optionsEmployee &&
                        <Select className="custom-select black-theme"
                        classNamePrefix="myselect" options={optionsEmployee} placeholder={t('select_creator')} onChange={onChangeEmployee} />
                     }
                  </Form.Group>
                  <Form.Group className="mb-10" controlId="formBasicPassword">
                     <div className='position-relative datepicker-block'>
                        <Image src='/assets/images/Calendar_add_light.png' alt='' className='datepicker-icon' />
                        <DatePicker className='form-control' placeholderText={t('Set_Deadline')} selected={expired_at} onChange={(date) => setExpired_at(date)} />
                     </div>
                  </Form.Group>

                  <Button variant="primary" className='btn-small btn-block font-18 w-100' onClick={onAdd}>
                     {t('Assign_Course')}
                  </Button>
               </Form>
            </Modal.Body>
         </Modal>
      </>
   )
}

export default AssignCourse;