import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { inviteUser,myCount } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

require('moment/locale/sw.js');
const SendInvitation = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   const [value, setValue] = useState({ firstname: "", lastname: "", code: "", phone: "", email: "" });
   const { business_id,role_id } = useSelector((state) => state.auth.user);
   const { stripe_subscription_id } = useSelector((state) => state?.auth?.user?.subscription || {});
   const [countAnalytic,setCountAnalytic] = useState();
   const [showMessage, setShowMessage] = useState(false);

   let body = 
      {
         "business_id":business_id,
     }
   
   const handleClose = () => {
      setShow(false);
      prop.closeModal();
   }
   const onChange = (e) => {
      setValue({
         ...value,
         [e.target.name]: e.target.value,
      });
   };


   useEffect(() => {
      setShow(prop.show)
   
      dispatch(myCount(body)).then((res) => {
         setCountAnalytic(res.payload.data)
      });
   }, [])
   
   
   // on press signup button
   const onAdd = () => {
      
      let is_license_assign = false;
      if(stripe_subscription_id && countAnalytic.licennseAssigned < countAnalytic.qtyWithLicense) {
         is_license_assign = true
      } else {
         is_license_assign = false;
      }
      let body;
      body = {
        'email':value.email,
        'business_id':business_id,
        'role_id':4,
        "is_license_assign":true
      }
      dispatch(inviteUser(body))
         .unwrap()
         .then((res) => {
            if(res.status === 200) {
               // toast.success(res.message);
               setShow(false);
               setShowMessage(true);
               
            }
           
         })
         .catch((e) => {
            prop.closeModal();
           // toast.error(message)
         });
   };
 
  

   return (
      <>
       
          <Modal centered show={show} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Lägg till användare</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-20px">
          <Form  >

            <Form.Group className="mb-10" controlId="formBasicEmail">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">E-post</Form.Label>
              <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light footer_black-black' name="email" placeholder="Obama@usa.com'" autoComplete="off" aria-label="exam_name" onChange={onChange} value={value.email} />

            </Form.Group>
          </Form>
          <div className="footer-box d-flex justify-content-center gap-20px align-items-center">
            <Button variant="primary" className="font-16 btn-blocksec btninner-sec" onClick={onAdd}>
              Skicka inbjudan
            </Button>

          </div>
        </Modal.Body>
      </Modal>

      {showMessage &&
      <Modal centered show={showMessage} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Din inbjudan är skickad</Modal.Title>
         
        </Modal.Header>
        <Modal.Body className="p-20px">
          <div><p className="font-20 fw-light text-black mb-0">Använderen kommer att få ett mail med mer information</p></div>
        </Modal.Body>
      </Modal>
      }
      </>
   )
}

export default SendInvitation;