import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { newslatterList,customNewsSort } from '../../store/api/course.api'
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Header from '../commom/Header2';
import { Reorder } from "framer-motion";
import InnerHeader from '../public/InnerHeader';
import Wrapper from '../component/Wrapper';
const ReorderNews = (prop) => {
  const dispatch = useDispatch();
  const [allnews, setAllNews] = useState();
  const { t } = useTranslation();
  const listItems=[];
  const [items, setItems] = useState(listItems)
 const newsArray = [];
  let dataFilter = {
    "filter": {
      "is_deleted": 0,

    }
  }
  useEffect(() => {
    dispatch(newslatterList(dataFilter)).then((res) => {
      setAllNews(res.payload.data)
      res.payload.data?.map((list, index) => {
        listItems.push({name:list.name,id:list.id})
      });
    })
  }, [])
 
  const onAdd = () => {
    items?.map((list, index) => (
      newsArray.push({id:list.id,sort:index})
  ));
  let data ={
    newsLatters:newsArray
  }
  dispatch(customNewsSort(data)).then((response) => {
   if(response.status === 200) {
      console.log(response);
   }
  })
  }
  allnews?.map((list, index) => {
    listItems.push({name:list.title,id:list.id})
  });
  return (
    <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t('Change_Order')} />
            <Wrapper>
          <Reorder.Group values={items} onReorder={setItems}>
          {items.map((item) => (
            
            // Change the li to Reorder.Item and add value prop
            <Reorder.Item key={item.id} value={item}>
             
              <div style={{color: '7015FF', fontSize:20, width:'300px',
            height:'30px', borderRadius:'2px',
            paddingLeft:'10px', marginTop:'20px','border':'1px solid black'}}> 
                             {item.name}</div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
          <Button variant="primary" className='font-18 mt-15 w-sm-100 wow fadeInDown' type="button" onClick={onAdd} >
                  {t('Submit')}
                  </Button>
        </Wrapper>
        </div>
      </div>
   
  );
}
export default ReorderNews;