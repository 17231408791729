import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getEducations } from "../../store/api/podcast.api";

const EducationList = () => {
  const dispatch = useDispatch();
  const { role_id, id } = useSelector((state) => state?.auth?.user || {});
  const [allEducation, setAllEducation] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    },
    user_id: id ? id : null,
    role_id: role_id,
  };

  useEffect(() => {
    dispatch(getEducations(dataFilter)).then((course) => {
      setAllEducation(course.payload.data);
    });
  }, []);

  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };

  const onCLickReadMore = (education) => {
    navigate("/individual-education/" + education.slug);
  };

  return (
    <>
      <section>
        <Container fluid>
          <div className="text-darkblock">
            <div className="text-center mb-40 d-md-block d-none">
              <h2 className="text-white fw-lighter text-center">Utforska utbildningar</h2>
              <svg
                width="19"
                height="10"
                viewBox="0 0 19 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L9.58815 9L18 1" stroke="#FFFFFF" />
              </svg>
            </div>
            <div className="d-flex flex-column gap-40">
              {allEducation?.length > 0 &&
                allEducation.map((item) => {
                  return (
                    <div className="education-block-list d-flex w-100 flex-sm-nowrap flex-wrap blockinfo-wrap position-relative">
                      <div className="blockleft-wrap d-flex">
                        <div className="teamimg-wrap flex-shrink-0">
                          <Image
                            src={item?.image}
                            alt="Utbildning"
                            className="w-100 h-100 img-fit "
                          />
                        </div>
                      </div>
                      <div className="d-md-flex d-block education-mob-right w-md-100 justify-content-between">
                        <div className="teamcontent-wrap">
                          <span className="text-white fw-medium d-block mb-sm-2 mb-1 team-sm-tilte">
                            Utbildning{" "}
                          </span>
                          <h3 className="text-white font-32 fw-light font-32 mb-sm-2 mb-1 team-main-title text-ellipse">
                            {item?.title}
                          </h3>
                          <div className="text-white block font-20 fw-light blockinfo-content d-md-block d-none  ">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: add3Dots(item?.content, 500),
                              }}
                            ></p>
                          </div>
                        </div>
                        <div className="blockright-wrap fs-16  d-flex flex-column">
                          <div>
                            <div className="d-md-block d-flex mb-md-3 mt-md-0  flex-wrap">
                              <span className="text-white fw-medium mb-sm-1 d-block">
                                Kursstart
                              </span>
                              <span className="text-white d-block  ps-2 ps-md-0 text-ellipse fw-light">
                                {item?.course_start}
                              </span>
                            </div>
                            <div className="d-md-block d-flex mb-md-3 mt-md-0 ">
                              <span className="text-white fw-medium mb-sm-1 d-block">
                              Kurslängd
                              </span>
                              <span className="text-white d-block  ps-2 ps-md-0 text-ellipse fw-light">
                                {item?.course_length}
                              </span>
                            </div>
                            <div className="d-md-block d-flex mb-md-3 mt-md-0 ">
                              <span className="text-white fw-medium mb-sm-1 d-block">
                              Kursavgift
                              </span>
                              <span className="text-white d-block  ps-2 ps-md-0 text-ellipse fw-light">
                                {item?.course_fee}
                              </span>
                            </div>
                            <div className="d-md-block d-flex mb-0 mt-md-0">
                              <span className="text-white fw-medium mb-sm-1 d-block">
                              Lärare
                              </span>
                              <span className="text-white d-block  ps-2 ps-md-0 text-ellipse fw-light">
                                {item?.extent}
                              </span>
                            </div>
                          </div>
                          <Button
                            variant="primary"
                            className="mobile-left d-sm-block d-none width-90 w-lg-100 mt-auto mobile-font-10 py-3"
                            onClick={() => onCLickReadMore(item)}
                          >
                            Läs mer om utbildningen
                          </Button>
                        </div>
                      </div>
                      <Button
                            variant="primary"
                            className="d-sm-none d-block w-100 mobile-font-10 py-3 mt-3"
                            onClick={() => onCLickReadMore(item)}
                          >
                            Läs mer om utbildningen
                          </Button>
                    </div>
                  );
                })}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default EducationList;
