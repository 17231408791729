import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import GlobalLoader from './pages/commom/LoadingSpinner';
import "./assets/language/i18n";
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <GoogleOAuthProvider clientId="719095458377-jsrfo5l09nvtdca9929bced4kqfpki4s.apps.googleusercontent.com">
  <Provider store={store}>
      {/* <GlobalLoader /> */}
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
