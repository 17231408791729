import { toast } from "react-toastify";
import axios from "./httpService";
  const API_URL = axios.Api;
  
const register = (body = {}) => {
  return axios.post(API_URL + "register", 
    body
  ).then((response) => {
    if (response.data.data.token) {
      localStorage.setItem("token", response.data.data.token);
    }
    return response.data;
  });
};

const login = (email, password) => {
  
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    });
};
const reset = (id, password) => {
  
  return axios
    .put(API_URL + "resetPassword", {
      id,
      password,
    })
    .then((response) => {
      return response.data;
    });
};
const googleSignUp = (body = {}) => {
  
  return axios .post(API_URL + "google-signup",body)
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    });
};
const checkFacebookToken = async (accessToken,email) => {
  try {
    const check = await axios.get(`https://graph.facebook.com/me?access_token=${accessToken}&fields=email`
    );
    if(check.data.email === email){
      return true;
    } else {
      return false;
    }
   
  } catch (error) {
    console.log(error.response.data.error.message);
    return false;
  }
};
const facebookSignUp = async (body = {}) => {
  if (await checkFacebookToken(body.token, body.email)) {
    
    return axios.post(API_URL + "facebook-signup",body)
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    });
  } else {
    toast.error('Email eller lösenord var felaktigt')
    return false;
  }
 
};


const forget = (email) => {
  
  return axios
    .post(API_URL + "forgetPassword", {
      email
    })
    .then((response) => {
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("token");
  localStorage.clear()
};
const changePass = (body = {}) => {
  return axios.put(API_URL + "changePassword", 
    body
  ).then((response) => {
    if (response.status === 200) {
      
    }
    return response.data;
  });
};

const authService = {
  register,
  login,
  logout,
  changePass,
  forget,
  reset,
  googleSignUp,
  facebookSignUp
};

export default authService;
