import React, { Component, useState, useEffect } from 'react';
import Header from '../commom/Header2';
import Header1 from '../commom/Header';
import { Form, Button,Container, Col, Row, Image, Accordion } from 'react-bootstrap';

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { contactUs } from '../../store/api/course.api'
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from './Footer';
import Accordion_Custome from './Accordion';
const Contact = () => {
    const dispatch = useDispatch();
    const { role_id } = useSelector((state) => state?.auth?.user || {});
    const [value, setValue] = useState({ email: "" });
    useEffect(() => {

    }, [])
    const { t } = useTranslation();

    let navigate = useNavigate();


    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    const onAdd = () => {

        dispatch(contactUs(value))
            .unwrap()
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message)
                   // navigate('/my-course-details'); 
                }

            })
            .catch(() => {

            });
    };
    return (
      <>
{ (role_id) && 
         <Header />
       }
        { (!role_id) && 
        <Header1/>
         }


<div className='page-wrapper flex-grow-1 pt-100 mobile-pt-15 mobile-pb-0'>
            <section className='d-flex align-items-center '>
                <Container fluid>

                    <Row className='gy-md-4'>
                        <Col md={6} className=''>
                        <Form className="mx-auto text-start mobile-pb-60" >
                    <div className='block text-white mb-10 text-white mobile-mb-20'>
                                <h1 className='mb-2 h2 font-40 fw-lighter wow fadeInDown mobile-font-30'>{t('Contact_Us')}</h1>
                                {/* <p className=''>{t('Vi_kommer_att_kontakta')}</p> */}
                            </div>
                    <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Control type="text" placeholder={t('Name')} aria-label="Name" name='name' value={value.name} onChange={onChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPhone">
                                <Form.Control type="number" placeholder={t('phone')} aria-label="Phone" name='phone' value={value.phone} onChange={onChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="email" placeholder={t('Email')} aria-label="Email" name='email' value={value.email} onChange={onChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control as="textarea" rows={5} placeholder={t('Message')} aria-label='Message' name='message' value={value.message} onChange={onChange} />
                            </Form.Group>
                            <div className='text' style={{'text-align':'center'}}>
                            <Button variant="primary" className='my-md-6 ' type="button" onClick={onAdd}>
                                {t('Submit')}
                            </Button>
                            </div>
                    </Form>
                        </Col>
                        <Col md={6} className='mobile-pb-60'>
                            <div className=''>
                                <Image src="/assets/images/contact-bannner.png" alt="" className='w-100' />
                            </div>
                        </Col>
                    </Row>
                
                
                </Container>
            </section>
            <section className='pt-100 mobile-pt-0'>
                <Container fluid>
                    <h2 className='text-center font-40 fw-lighter mb-5'>Vanliga frågor</h2>
                <Accordion_Custome></Accordion_Custome>
                </Container>
            </section>
        
      </div>

<Footer />

</>

    )
}
export default Contact;