import React, { useState } from 'react';
import Header from '../commom/Header2';
import { Container, Form, Button, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendNotificationFromAdmin } from '../../store/api/course.api';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import InnerHeader from './InnerHeader';
import TextBlock from '../component/TextBlock';
import Wrapper from '../component/Wrapper';

const SendNotification = () => {

   const [value, setValue] = useState({ title: "", content: "" });
   const { t } = useTranslation();
   const dispatch = useDispatch();

   const handelSend = () => {
      dispatch(sendNotificationFromAdmin(value))
         .unwrap()
         .then(() => {
            toast.success(<>{t('Notification_Sent')}</>)
         })
         .catch((e) => {
            toast.success(<>{t('Fail_to_send_notification')}</>)
         });

   }
   const onChange = (e) => {
      setValue({
         ...value,
         [e.target.name]: e.target.value,
      });
   };

   return (
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block'>
            <InnerHeader title={t('Send_Notification')} />
            <Wrapper>
               <div className="row g-20px">
                  <Col md={6}>
                     {/* <h1 className='text-white mb-13 fw-semibold h2 wow fadeInDown'>{t('Send_Notification')}</h1> */}

                     <Form className="bg-white shadow-block rounded-20">
                        <div className="p-20px title-header">
                           <TextBlock title={t('Send_Notification')} subtitle={"Uppdatera dina konto inställningar"} />
                        </div>
                        <div className="d-flex flex-column gap-20px p-20px">
                           <Form.Group className="wow fadeInDown" controlId="formGroupName">
                              <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium">{t('Title')}</Form.Label>
                              <Form.Control type="text" placeholder={t('Title')} name='title' aria-label="Title" value={value.title} onChange={onChange} className="fs-20 mobile-font-14 form-inputblock" />
                           </Form.Group>
                           <Form.Group className="wow fadeInDown" controlId="formGroupAboutUs">
                              <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium">{t('Description')}</Form.Label>
                              <Form.Control as="textarea" rows={6} placeholder={t('Description')} name='content' aria-label="Description" value={value.content} onChange={onChange} className="fs-20 mobile-font-14 form-inputblock" />
                           </Form.Group>
                        </div>
                        <div className="d-flex justify-content-between align-items-center p-20px pt-0">
                           <Button variant="primary" type="button" className='mt-12 min-200 font-18 w-sm-100 wow fadeInDown' onClick={handelSend}>{t('Send')}</Button>
                        </div>
                     </Form>
                  </Col>
               </div>
            </Wrapper>
         </div>

      </div >
   )
}

export default SendNotification;