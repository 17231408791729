import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCources,
  customSort,
  customSortPackage,
} from "../../store/api/course.api";
import { Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../commom/Header2";
import { Reorder } from "framer-motion";
import InnerHeader from '../public/InnerHeader';
import Wrapper from '../component/Wrapper';
const ReorderPackage = (prop) => {
  const dispatch = useDispatch();
  const [allCources, setAllCources] = useState();
  const { t } = useTranslation();
  const listItems = [];
  const [items, setItems] = useState(listItems);
  const courses = [];
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  useEffect(() => {
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources(course.payload.data.packages);
      course.payload.data?.packages.map((list, index) => {
        listItems.push({
          name: list.title,
          id: list.package_id,
          podcast: list.podcast,
        });
      });
    });
  }, []);

  const onAdd = () => {
    items?.map((list, index) => courses.push({ id: list.id, sort: index }));
    let data = {
      packages: courses,
    };
    dispatch(customSortPackage(data)).then((response) => {});
  };
  allCources?.map((list, index) => {
    listItems.push({ name: list.title, id: list.id, podcast: list.podcast });
  });
  return (
    
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t('Change_Order')} />
            <Wrapper>
          <Reorder.Group values={items} onReorder={setItems}>
            {items.map((item) => (
              // Change the li to Reorder.Item and add value prop
              <Reorder.Item key={item.id} value={item}>
                <div
                  style={{
                    color: item.podcast == 1 ? "#47B872" : "#7015FF",
                    fontSize: 20,
                    width: "300px",
                    height: "30px",
                    borderRadius: "2px",
                    paddingLeft: "10px",
                    marginTop: "20px",
                    border: "1px solid white",
                  }}
                >
                  {item.name}
                </div>
              </Reorder.Item>
            ))}
          </Reorder.Group>
          <Button
            variant="primary"
            className="font-18 mt-15 w-sm-100 wow fadeInDown"
            type="button"
            onClick={onAdd}
          >
            {t("Submit")}
          </Button>
          </Wrapper>
      </div>
    </div>
  );
};
export default ReorderPackage;
