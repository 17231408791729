import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getCreator } from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "multiselect-react-dropdown";
import {
  editPodcasts,
  getSinglePodcast,
  podcasttagList,
} from "../../store/api/podcast.api";
import { ck_config } from "./commonConst";

const EditPodcast = (prop) => {
    
  const options = [];
  let tags = [];
  let optionsTag = [];

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState({
    title: "",
    tags: "",
    short_title: "",
    name_of_guest: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [allCretor, setAllCreator] = useState();

  const [tagListItems, setTagList] = useState([]);
  const [tagListToAssign, setTagListToAssign] = useState();
  const [textDescription, settextDescription] = useState(null);

  const editorConfiguration = ck_config;

  const handleClose = () => {
    setShow(false);
    prop.closeModal();
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setShow(prop.show);
    dispatch(podcasttagList()).then((data) => {
      setTagList(data.payload.data);
    });
    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
      setSelectedCreator(data.payload.data[0].creator_id);
    });
    if (prop.editId) {
      let req = {
        podcast_id: prop.editId,
      };

      dispatch(getSinglePodcast(req)).then((res) => {
        settextDescription(res.payload.data?.description);

        let selectedTag = [];
        res.payload.data.tags.map((item, key) =>
          selectedTag.push({ id: item.id, name: item.name })
        );
        setTagListToAssign(selectedTag);

        setValue({
          ...value,
          ["title"]: res.payload.data.title,
          ["short_title"]: res.payload.data.short_title,
          ["tags"]: res.payload.data.tags,
          ["name_of_guest"]: res.payload.data.name_of_guest,
        });
      });
    }
  }, []);

  const onSave = () => {
    tagListToAssign && tagListToAssign.map((list) => tags.push(list.id));
    var data = new FormData();
    data.append("id", prop.editId);
    data.append("title", value.title);
    data.append("short_title", value.short_title);
    data.append("name_of_guest", value.name_of_guest);
    data.append("tags", tags);

    data.append("description", textDescription);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }

    dispatch(editPodcasts(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          handleClose();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  allCretor &&
    allCretor.map((list) =>
      options.push({ value: list.creator_id, label: list.creator_name })
    );
  tagListItems &&
    tagListItems.map((list) =>
      optionsTag.push({ name: list.name, id: list.id })
    );

  const onSelect = (selectedList, selectedItem) => {
    setTagListToAssign(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setTagListToAssign(selectedList);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Create_Podcast")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={"Title"}
                  name="title"
                  aria-label="Product Name"
                  value={value.title}
                  onChange={onChange}
                />
                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
              </div>
            </Form.Group>

            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("short_title")}
                  name="short_title"
                  aria-label="Product Name"
                  value={value.short_title}
                  onChange={onChange}
                />
                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("name_of_guest")}
                  name="name_of_guest"
                  aria-label="Product Name"
                  value={value.name_of_guest}
                  onChange={onChange}
                />
                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
              </div>
            </Form.Group>

            {/* <Form.Group className='mb-10 wow fadeInDown'>
                            {options &&
                                <Select className="custom-select" options={options} onChange={onChangeCreator} />
                            }
                        </Form.Group> */}
            <Row className="gy-10">
              <Col sm={6}>
                <div className="mb-10 wow fadeInDown">
                  <input
                    type="file"
                    id="file1"
                    className="visually-hidden"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <label
                    htmlFor="file1"
                    className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                  >
                    <span className="file-icon">
                      <Image
                        src="/assets/images/Video_file_light.png"
                        alt="Select Image"
                        width="48"
                        height="48"
                      />
                    </span>
                    <span>{t("Select_Image")}</span>
                  </label>
                </div>
              </Col>
            </Row>

            <div className="mb-10 wow fadeInDown">
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={textDescription}
                placeholder="Enter data"
                onChange={(event, editor) => {
                  settextDescription(editor.getData());
                }}
              />
            </div>

            {tagListItems && (
              <Multiselect
                options={optionsTag}
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options,
                selectedValues={tagListToAssign} // Preselected value to persist in dropdown
                showCheckbox
              />
            )}

            <Button
              variant="primary"
              type="button"
              className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
              onClick={onSave}
            >
              {" "}
              {t("Save")}{" "}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditPodcast;
