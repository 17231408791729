import React from "react";
import { Container, Image, Navbar, Nav, Button, Dropdown} from "react-bootstrap";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  //   if($('.App').height() > 1400){
  //     $(window).scroll(function() {
  //       var scroll = $(window).scrollTop();

  //       if (scroll >= 250) {
  //           $(".main-header").addClass("fixed-header");
  //       }else{
  //         $(".main-header").removeClass("fixed-header");
  //       }
  //   });
  // }
  return (
    <>
      <header className="main-header mobile-border-none">
        <Container fluid>
          <Navbar
            collapseOnSelect
            expand="md"
            className="bg-transparent py-md-12 py-5"
          >
            {/* <div className="border-bottom"> */}
            <Navbar.Brand href="/" className="me-auto">
              {/* <Image src="/assets/images/logo.svg" className="navbar-brand-img" alt="DOCENS" width="105" height="48" /> */}
              <svg
                id="Lager_2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 710.11 134.41"
              >
                <defs>
                  <style>{"fill:#fff;"}</style>
                </defs>
                <g id="Lager_1-2">
                  <g>
                    <path
                      className="cls-1"
                      d="M106.99,68.38c0,20.93-7.04,39.33-20.57,50.7-9.56,7.93-22.01,12.45-38.25,12.45H.25c-.14,0-.25-.11-.25-.25v-26.51c0-23.3,9.26-45.65,25.73-62.12h0c.16-.16,.43-.05,.43,.18V108.72c0,.14,.11,.25,.25,.25h19.96c24.53,0,34.1-14.97,34.1-40.59s-11.19-43.48-33.38-43.48H.25c-.14,0-.25-.11-.25-.25V2.78c0-.14,.11-.25,.25-.25H50.34c34.46,0,56.65,27.78,56.65,65.85Z"
                    />
                    <path
                      className="cls-1"
                      d="M117.63,67.29C117.63,28.69,141.81,0,179.88,0s62.06,28.69,62.06,67.29-23.99,67.11-62.06,67.11-62.24-28.51-62.24-67.11Zm97.6,0c0-25.8-11.55-45.83-35.18-45.83s-35.72,20.03-35.72,45.83,12.09,45.65,35.72,45.65,35.18-20.03,35.18-45.65Z"
                    />
                    <path
                      className="cls-1"
                      d="M250.59,67.48C250.59,29.23,274.4,0,312.47,0c31.75,0,50.52,18.94,53.22,43.48h-25.98c-2.35-12.99-12.09-21.47-27.24-21.47-23.81,0-35.18,19.85-35.18,45.47s13.71,45.28,35.36,45.28c15.34,0,26.16-9.2,27.78-22.73h25.62c-.72,11.37-5.59,22.37-14.25,30.49-8.84,8.3-21.29,13.71-39.15,13.71-36.26,0-62.06-27.96-62.06-66.75Z"
                    />
                    <path
                      className="cls-1"
                      d="M378.57,2.53h94.9V24.54h-68.74v28.87h60.26v21.47h-60.26v34.82h69.28v21.83h-95.44V2.53Z"
                    />
                    <path
                      className="cls-1"
                      d="M488.23,2.53h26.52l42.94,71.99c4.15,7.04,9.02,18.04,9.02,18.04h.36s-.72-13.35-.72-22.37V2.53h25.8V131.52h-25.26l-44.02-71.26c-4.15-6.86-9.2-17.86-9.2-17.86h-.36s.72,13.53,.72,22.55v66.57h-25.8V2.53Z"
                    />
                    <path
                      className="cls-1"
                      d="M604.21,91.65h25.62c1.8,15.52,10.64,21.65,29.05,21.65,13.35,0,25.08-4.69,25.08-16.6,0-12.63-12.27-15.16-31.93-19.67-23.09-5.23-44.2-11.37-44.2-38.25,0-25.44,20.75-38.61,49.43-38.61s47.81,14.25,49.79,40.23h-25.08c-1.44-12.99-11.55-19.48-24.9-19.48-14.07,0-23.27,5.95-23.27,15.15,0,10.46,9.02,13.53,28.14,17.68,26.52,5.77,48.17,12.09,48.17,39.87,0,26.16-21.11,40.77-50.15,40.77-35,0-54.85-15.52-55.75-42.76Z"
                    />
                  </g>
                </g>
              </svg>
            </Navbar.Brand>
           

            <Link to="/logga-in" className="btn btn-light me-sm-6 me-2 d-sm-block d-none">
              Logga in
            </Link>
            
            <Dropdown  align="end" className="header-toggle-menu d-sm-block d-none">
              <Dropdown.Toggle  id="dropdown-basic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="16"
                viewBox="0 0 25 16"
                fill="none"
                className="hamburger-menu"
              >
                <rect
                  x="5.92493"
                  y="-0.000244141"
                  width="18.8243"
                  height="3.13738"
                  fill="#ffffff"
                />
                <rect
                  x="0.43457"
                  y="6.27454"
                  width="24.3147"
                  height="3.13738"
                  fill="#ffffff"
                />
                <rect
                  x="12.9841"
                  y="12.5493"
                  width="11.7652"
                  height="3.13738"
                  fill="#ffffff"
                />
              </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/vara-kurser">Kurser</Dropdown.Item>
                {/* <Dropdown.Item href="/utbildning">Utbildning</Dropdown.Item> */}
                {/* <Dropdown.Item href="/podcast">Podcast</Dropdown.Item>
                <Dropdown.Item href="/webinar">Webinar</Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item href="/contact-new">Kontakt</Dropdown.Item>
                {/* <Dropdown.Item href="/företag">För företag</Dropdown.Item>
                <Dropdown.Item href="/för-utbildare">För utbildare</Dropdown.Item> */}
                <Dropdown.Divider />
                {/* <Dropdown.Item href="/blogg">Blogg</Dropdown.Item> */}
                <Dropdown.Item href="/om-oss">Om oss</Dropdown.Item>
                <Dropdown.Item href="/anvandarvilkor">Användarvillkor</Dropdown.Item>
                <Dropdown.Item href="/integritetspolicy ">Integritetspolicy</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Navbar.Toggle aria-controls="responsive-navbar-nav d-block d-sm-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="16"
                viewBox="0 0 25 16"
                fill="none"
                className="hamburger-menu"
              >
                <rect
                  x="5.92493"
                  y="-0.000244141"
                  width="18.8243"
                  height="3.13738"
                  fill="#fff"
                />
                <rect
                  x="0.43457"
                  y="6.27454"
                  width="24.3147"
                  height="3.13738"
                  fill="#fff"
                />
                <rect
                  x="12.9841"
                  y="12.5493"
                  width="11.7652"
                  height="3.13738"
                  fill="#fff"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                className="closemenu"
              >
                <path
                  d="M18.4346 6L6.43457 18"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.43457 6L18.4346 18"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Navbar.Toggle>

            <Navbar.Offcanvas
              id="responsive-navbar-nav"
              placement="start"
              className="d-block d-sm-none overflow-auto">
              <Nav className="d-flex align-items-md-center align-items-start justify-content-between">
                <div className="d-md-flex gap-4 mobile-first mobilenavmenu">
                  <Link to="/logga-in" className="btn btn-light mobile-navlink">
                    Logga in
                  </Link>
                  <Link
                    to="/registrera"
                    className="btn btn-primary mobile-navlink mb-6"
                  >
                   Registrera
                  </Link>
                  <Link to="/vara-kurser" className="btn btn-light mobile-navlink only_mobile d-none">
                  Kurser
                  </Link>
                  <Link to="/contact-new" className="btn btn-light mobile-navlink only_mobile d-none">
                  Kontakt
                  </Link>
                  
                  {/* <Link to="/webinar" className="btn btn-light mobile-navlink only_mobile d-none">
                  Webinar
                  </Link>
                  <Link to="/podcast" className="btn btn-light mobile-navlink only_mobile d-none mb-6">
                  Podcast
                  </Link> */}
                 
                {/* <Link to="/blogg" className="btn btn-light mobile-navlink only_mobile d-none">
                  Blogg
                </Link> */}
                <Link to="/om-oss" className="btn btn-light mobile-navlink only_mobile d-none">
                  Om oss
                </Link>
                <Link to="/anvandarvilkor" className="btn btn-light mobile-navlink only_mobile d-none">
                Användarvillkor
                </Link>
                <Link to="/integritetspolicy" className="btn btn-light mobile-navlink only_mobile d-none">
                Integritetspolicy
                </Link>
                </div>
              </Nav>
            </Navbar.Offcanvas>

              {/* <Nav.Link href="/logga-in" className='fw-medium navlink'>{t('SignIn')}</Nav.Link>
                <Nav.Link href="/registrera" className='fw-medium navlink'>{t('Create_your_account')}</Nav.Link>
                <Nav.Link href="/vara-kurser" className='fw-medium navlink'>{t('Our_courses')}</Nav.Link>
                <Nav.Link href="/funktioner" className='fw-medium navlink'>{t('Hur_det_funkar')}</Nav.Link>
                <Nav.Link href="/pris" className='fw-medium navlink'>{t('Price')}</Nav.Link>
                <Nav.Link href="/om-oss" className='fw-medium navlink'>{t('About_us')}</Nav.Link> */}
                {/* <Nav.Link onClick={() => changeLanguage("en")} className=''>
                <Image src="/assets/images/en.png" className="width-28" alt="En" width="40" height="40" />
                </Nav.Link>
                <Nav.Link onClick={() => changeLanguage("sw")} className=''>
                <Image src="/assets/images/sv.png" className="width-28" alt="Sv" width="40" height="40" />
                </Nav.Link> */}
                {/* <div className="close-btn">
                  <a role="button"><span className="icon-close"></span></a>
                </div> */}

            {/* <div className='d-flex headeraccount-link'>
              <Link to="/logga-in" className='icon-ic-user width-24 d-flex align-items-center justify-content-center font-22 text-white link-hover'>
              </Link>
              <Link to="/" className='icon-ic-checkout width-24 d-flex align-items-center justify-content-center link-hover text-white'>
              </Link>
            </div> */}
            {/* </div> */}
          </Navbar>
        </Container>
      </header>
    </>
  );
};

export default Header;
