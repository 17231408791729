import React, { useEffect, useState, useRef, Fragment } from "react";
import Header from "../commom/Header2";
import { Row, Col, Image, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  getPackage,
  addPackage,
  removeCources,
  getCreator,
  publishCourse,
  tagList,
  myCourse,
  getPackageListData,
  removePackages,
  publishPackage,
} from "../../store/api/course.api";
import ConfirmPopup from "../commom/ConfirmPopup";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditCourse from "../commom/EditCourse";
import Footer from "../commom/Footer";
import Slider from "react-slick";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import EditPackage from "../commom/EditPackage";
import { checkSubsDate } from "../commom/commonConst";
import InnerHeader from "./InnerHeader";
import { ck_config } from "../commom/commonConst";
import Wrapper from "../component/Wrapper";

const AllPackage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const { role_id, id } = useSelector((state) => state?.auth?.user);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setTagListToAssign([]);
    setCourseListToAssign([]);
    setValue({
      title: "",
      RegularPrice: "",
      SpecialPrice: "",
      tags: "",
      subtitle: "",
    });
  };
  const [allCources, setAllCources] = useState();
  const [allCretor, setAllCreator] = useState();
  const [value, setValue] = useState({
    title: "",
    RegularPrice: "",
    SpecialPrice: "",
    tags: "",
    subtitle: "",
  });
  // const editorRef = useRef(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPdf, setSelectedPDF] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [isPurchageFilter, setIsPurchageFilter] = useState(false);
  const [tempAllCources, setTempAllCources] = useState();
  const [showEditCourse, setshowEditCourse] = useState(false);
  const [editId, setEditId] = useState(null);
  const [pubMessage, setPubMessage] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [tagListItems, setTagList] = useState([]);
  const [tagListToAssign, setTagListToAssign] = useState();
  const [courseListItems, setcourseList] = useState([]);
  const [courseListToAssign, setCourseListToAssign] = useState();
  const [textDescription, setTextDescription] = useState(null);
  const [textIncludedinPackage, setTextIncludedinPackage] = useState(null);
  const [textSuitableFor, setTextSuitableFor] = useState(null);
  let tags = [];
  let optionsTag = [];
  let coursesArr = [];
  let optionsCourse = [];
  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const { tagId, index } = useParams();
  const [is_podcast, setIsPodcast] = useState(0);

  // open when we click edit course
  const openShow = (id) => {
    setEditId(id);
    setshowEditCourse(true);
  };
  const editorConfiguration = ck_config;
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
  };

  if (role_id !== 1) {
    dataFilter.filter.is_public = 1;
  }
  const options = [];
  useEffect(() => {
    if (tagId && tagId != 1) {
      setImageIndex(index);
      dataFilter.tags = tagId.toString();
    }
    dispatch(getPackageListData(dataFilter)).then((course) => {
      if (role_id !== 1) {
        setAllCources(
          course.payload.data.filter((item) => item.is_purchased === true)
        );
        setTempAllCources(
          course.payload.data.filter((item) => item.is_purchased === true)
        );
      } else {
        setAllCources(course.payload.data);
        setTempAllCources(course.payload.data);
      }
    });
    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
      setSelectedCreator(data.payload.data[0].creator_id);
    });
    dispatch(
      getPackage({
        filter: {
          is_delete: 0,
          // is_public: 1,
        },
        user_id: id,
        role_id: role_id,
        // "page":"1",
        // "limit":"10"
      })
    ).then((data) => {
      // setTagList(data.payload.data.courses);
      setcourseList(data.payload.data.courses);
    });
    dispatch(tagList()).then((data) => {
      setTagList(data.payload.data);
    });
  }, []);

  var featurecard = {
    arrows: tagListItems.length > 6 ? true : false,
    infinite: tagListItems.length > 7 ? true : false,
    speed: 500,
    variableWidth: true,
    // slidesToShow: 8,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          //  slidesToShow: 6,
        },
      },
      {
        breakpoint: 767,
        settings: {
          //  slidesToShow: 3,
        },
      },
    ],
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeCreator = (event) => {
    setSelectedCreator(event.value);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const closeModal = () => {
    setshowEditCourse(false);
    dispatch(getPackage(dataFilter)).then((course) => {
      setAllCources(course.payload.data.packages);
      setTempAllCources(course.payload.data.packages);
    });
  };
  tagListItems &&
    tagListItems.map((list) =>
      optionsTag.push({ name: list.name, id: list.id })
    );
  courseListItems &&
    courseListItems.map((list) =>
      optionsCourse.push({ name: list.title, id: list.id })
    );
  const onSelect = (selectedList, selectedItem) => {
    setTagListToAssign(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setTagListToAssign(selectedList);
  };
  const onSelectCourse = (selectedList, selectedItem) => {
    setCourseListToAssign(selectedList);
  };
  const onRemoveCourse = (selectedList, removedItem) => {
    setCourseListToAssign(selectedList);
  };
  allCretor &&
    allCretor.map((list) =>
      options.push({ value: list.creator_id, label: list.creator_name })
    );
  const CourseList = ({ course, onDelete, index }) => {
    return (
      <Col md={6} xl={4}>
        <div className="getstart-block position-relative d-flex flex-column feature-box">
          {/* <div className="img-content-green">
            <div className="img-header-green">
            { !stripe_subscription_id &&
              <span className="btn-img d-flex align-items-center justify-content-center">{course?.r_price} SEK Inkl moms</span>
            }
            </div>
            </div> */}

          <>
            {" "}
            <Image
              className="img-fit feature-img"
              src={course.image ? course.image : "/assets/images/Videi-1.jpeg"}
              alt="Image"
              width={526}
              height={348}
              onClick={() => gotoInnerPage(course.package_id)}
            />
            <div className="img-content text-white d-flex align-items-start justify-content-end">
              <div className="bottom-text text-md-start text-center">
                <h5
                  className="mb-1 fw-light cursor_class font-32"
                  onClick={() => gotoInnerPage(course.package_id)}
                >
                  {course.title}
                </h5>
                <div className="d-flex gap-2">
                  <span
                    className="fw font-16"
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      display: "flex",
                    }}
                  >
                    <p
                      className="fw-semibold cursor_class  mb-0 fs-16 mr-5"
                      // dangerouslySetInnerHTML={{
                      //   __html: add3Dots(course.content, 100),
                      // }}
                      onClick={() => gotoInnerPage(course.package_id)}
                    >
                      Paket
                      {course.number_of_courses > 0 && <Fragment>{course.number_of_courses} kurser</Fragment>}
                    </p>
                    {/* {course.number_of_courses > 0 && (
                      <p>{course.number_of_courses} kurser</p>
                    )} */}
                  </span>
                </div>
                <div className="mt-1">
                  {role_id === 1 && (
                    <a
                      className="btn btn-link icon-ic-trash text-white link-hover "
                      onClick={(e) => handleDelete(e, course.package_id)}
                    >
                      {" "}
                    </a>
                  )}
                  {popup.show && (
                    <ConfirmPopup
                      handleDeleteTrue={handleDeleteTrue}
                      handleDeleteFalse={handleDeleteFalse}
                      message={t("You_sure_you_wanna_delete")}
                    />
                  )}
                  {role_id === 1 && (
                    <a
                      className="btn btn-link icon-ic-edit text-white link-hover"
                      style={{ "margin-left": "5px" }}
                      onClick={() => openShow(course.package_id)}
                    ></a>
                  )}
                  {showEditCourse && editId === course?.package_id && (
                    <EditPackage
                      show={showEditCourse}
                      editId={editId}
                      closeModal={closeModal}
                    />
                  )}
                  {role_id === 1 && (
                    <label className="toggle-public-label">
                      <input
                        type="checkbox"
                        value={course.is_public}
                        checked={course.is_public === 1 ? true : false}
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span
                          className="label on"
                          onClick={() => handlePublish(false, course.package_id)}
                        >
                          {t("Off")}
                        </span>
                        <span
                          className="label off"
                          onClick={() => handlePublish(true, course.package_id)}
                        >
                          {t("On")}
                        </span>
                      </span>
                    </label>
                  )}
                  {publishPopup.show && (
                    <ConfirmPopup
                      handleDeleteTrue={handlePublishTrue}
                      handleDeleteFalse={handlePublishFalse}
                      message={t(pubMessage)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </Col >
    );
  };

  const onDelete = (id) => {
    let data = {
      package_id: id,
      is_delete: 1,
    };
    dispatch(removePackages(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPackage(dataFilter)).then((course) => {
            setAllCources(course.payload.data.packages);
            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openEdit = (id) => {
    navigate("/course-management");
  };
  const gotoInnerPage = (id) => {
    //navigate("/private-package-detail/" + id);
  };
  const onSave = () => {
    var data = new FormData();
    tagListToAssign && tagListToAssign.map((list) => tags.push(list.id));
    courseListToAssign &&
      courseListToAssign.map((list) => coursesArr.push(list.id));

    data.append("title", value.title);
    data.append("content", textContent);
    data.append("description", textDescription);
    data.append("included_in_package", textIncludedinPackage);
    data.append("suitable_for", textSuitableFor);
    data.append("r_price", value.RegularPrice);
    data.append("s_price", value.SpecialPrice);
    data.append("creator_id", selectedCreator);
    data.append("certificate_text", value.certificate_text);
    if (coursesArr.length > 0) {
      data.append("courses", coursesArr);
    }
    if (tags.length > 0) {
      data.append("tags", tags);
    }
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    if (selectedPdf) {
      data.append("file", selectedPdf, selectedPdf.name);
    }
    data.append("subtitle", value.subtitle);

    dispatch(addPackage(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPackageListData(dataFilter)).then((course) => {
            setAllCources(course.payload.data);
            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });

  const [publishPopup, setPublishPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
    flag: false,
  });
  const handleDelete = (e, id) => {
    e.preventDefault();

    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      onDelete(popup.id);
      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };
  const oncheckChange = (flag) => {
    if (flag) {
      setAllCources(
        allCources.filter(
          (item) => item.is_purchaed === true || item.is_assigned === true
        )
      );
    } else {
      setAllCources(tempAllCources);
    }
  };
  const customSort = (item, index) => {
    setImageIndex(index);
    if (item.name === "Alla kurser") {
      delete dataFilter.tag;
      dispatch(getPackage(dataFilter)).then((course) => {
        if (role_id !== 1) {
          setAllCources(
            course.payload.data.filter((item) => item.podcast === 0)
          );
        } else {
          setAllCources(course.payload.data.packages);
        }
      });
    } else if (item.name === "Mina kurser") {
      //  setAllCources(tempAllCources.filter((item=> item.is_purchaed === true || item.is_assigned === true)));
      delete dataFilter.tag;
      dispatch(getPackage(dataFilter)).then((course) => {
        setAllCources(
          course.payload.data.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
      });
    } else {
      dataFilter.tags = item.id.toString();
      dispatch(getPackage(dataFilter)).then((course) => {
        setAllCources(course.payload.data);
      });
    }
    navigate("/my-course-details/" + item.name + "/" + item.id + "/" + index);
  };
  const handlePublish = (flag, id) => {
    setPublishPopup({
      show: true,
      id,
      flag,
    });
    if (flag) {
      setPubMessage("Vill_du_publicera_den_här_kursen");
    } else {
      setPubMessage("Vill_du_unpublicera_den_här_kursen");
    }
  };
  const handlePublishTrue = () => {
    if (publishPopup.show && publishPopup.id) {
      makeMePublic(publishPopup.flag, publishPopup.id);
      setPublishPopup({
        show: false,
        id: null,
        flag: false,
      });
    }
  };
  const handlePublishFalse = () => {
    setPublishPopup({
      show: false,
      id: null,
      flag: false,
    });
  };

  const makeMePublic = (flag, id) => {
    if (flag) {
      let data = {
        package_id: id,
        is_public: 1,
      };
      dispatch(publishPackage(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            dispatch(getPackage(dataFilter)).then((course) => {
              setAllCources(course.payload.data.packages);
            });
          }
        });
    } else {
      let data = {
        package_id: id,
        is_public: 0,
      };
      dispatch(publishPackage(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            dispatch(getPackage(dataFilter)).then((course) => {
              setAllCources(course.payload.data.packages);
            });
          }
        });
    }
  };
  const handleck = (e) => {
    setIsPodcast(e.target.checked ? 1 : 0);
  };

  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
        <InnerHeader title={t('my-package')} subtitle="" />
        {/* <h1 className="mb-sm-0  fw-semibold text-white h2">
            {t("my-package")}
          </h1> */}
        {/* <span>{t('Köp_enstaka_kurs_till_ett')}</span> */}
        {/* {role_id !== 1 &&
               <label className="toggle-label">
                    <input type="checkbox" />
                    <span className="back">
		              <span className="toggle"></span>
                    <span className="label on" onClick={()=>oncheckChange(false)}>{t('All')}</span>
                    {role_id !== 4 &&
                    <span className="label off" onClick={()=>oncheckChange(true)}>{t('Course_you_buy')}</span>
                     }
                    {role_id === 4 &&
                    <span className="label off" onClick={()=>oncheckChange(true)}>{t('Course_you_Assigned')}</span>
                     }
                    </span>
                </label>
               } */}

        <Wrapper>
          {role_id === 1 && (
            <>
              <div className="right-button d-flex align-items-center justify-content-end flex-wrap gap-10px mb-3">
                <Button
                  variant="primary"
                  className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100"
                  onClick={handleShow}
                >
                  {t("Add_New")}
                </Button>
                <Link
                  to="/reorder-package"
                  className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100"
                >
                  {t("Ordering")}
                </Link>
                <Link
                  to="/taggar"
                  className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100"
                >
                  {t("Tags")}
                </Link>
              </div>
            </>
          )}

          <Row className="g-xl-20px g-8px">
            {allCources?.length > 0 &&
              allCources?.map((course, key) => (
                <CourseList
                  course={course}
                  key={key}
                  index={key}
                  onDelete={onDelete}
                />
              ))}
          </Row>
        </Wrapper>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Create_Packages")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={"Title"}
                    name="title"
                    aria-label="Product Name"
                    value={value.title}
                    onChange={onChange}
                  />
                  <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                </div>
              </Form.Group>
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formRegularPrice"
              >
                <Form.Control
                  type="text"
                  placeholder={t("Texta")}
                  name="subtitle"
                  aria-label="Subtitle"
                  value={value.subtitle}
                  onChange={onChange}
                />
              </Form.Group>
              <Form.Group className="mb-10 wow fadeInDown">
                {/* <Form.Control as="select"  className='flex-grow-1 question-control' custom='true' onClick={onChangeCreator}  >
                     
                     <option disabled value={-1} key={-1}>{t('select')}</option>
                     
                            { allCretor && allCretor.map(list =>
                                <option key={list.creator_id} value={list.creator_id}>{list.creator_name}</option>)}
                                
                  </Form.Control> */}
                {options && (
                  <Select
                    className="custom-select"
                    classNamePrefix="myselect"
                    options={options}
                    onChange={onChangeCreator}
                  />
                )}
              </Form.Group>
              <Row className="g-xl-20px g-8px">
                <Col md={6} xl={4}>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file1"
                      className="visually-hidden"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="file1"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/Video_file_light.png"
                          alt="Select Image"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Select_Image")}</span>
                    </label>
                  </div>
                </Col>
                <Col md={6} xl={4}>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file2"
                      className="visually-hidden"
                      onChange={(e) => setSelectedPDF(e.target.files[0])}
                    />
                    <label
                      htmlFor="file2"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/fileupload-ic.png"
                          alt="Attachments Link Pdf"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Attachments_Link_Pdf")}</span>
                    </label>
                  </div>
                </Col>
              </Row>

              {/**content */}
              <p className="mb-sm-0  fw-semibold text-white h3">{t("Content")}</p>
              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter data"
                  onChange={(event, editor) => {
                    setTextContent(editor.getData());
                  }}
                />
              </div>

              {/**description */}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Description")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter description"
                  onChange={(event, editor) => {
                    setTextDescription(editor.getData());
                  }}
                />
              </div>

              {/**included in package */}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Included_in_package")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter what is included in package"
                  onChange={(event, editor) => {
                    setTextIncludedinPackage(editor.getData());
                  }}
                />
              </div>
              {/*Who is the course suitable for ?*/}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Who_suitable_forPackage")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Who is the package suitable for?"
                  onChange={(event, editor) => {
                    setTextSuitableFor(editor.getData());
                  }}
                />
              </div>

              <div className="d-flex flex-wrap price-wrapper">
                <Form.Group
                  className="mb-10 wow fadeInDown"
                  controlId="formRegularPrice"
                >
                  <Form.Control
                    type="number"
                    placeholder={t("Regular_Price")}
                    name="RegularPrice"
                    aria-label="Regular Price"
                    value={value.RegularPrice}
                    onChange={onChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-10 wow fadeInDown"
                  controlId="formSpecialPrice"
                >
                  <Form.Control
                    type="number"
                    placeholder={t("Special_Price")}
                    name="SpecialPrice"
                    aria-label="Special Price"
                    value={value.SpecialPrice}
                    onChange={onChange}
                  />
                </Form.Group>
              </div>

              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formGroupAboutUs"
              >
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder={t("Certificate_Text")}
                  name="certificate_text"
                  aria-label="Certificate Text"
                  value={value.certificate_text}
                  onChange={onChange}
                />
              </Form.Group>
              {courseListItems && (
                <Multiselect
                  options={optionsCourse}
                  onSelect={onSelectCourse} // Function will trigger on select event
                  onRemove={onRemoveCourse} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options,
                  selectedValues={courseListToAssign} // Preselected value to persist in dropdown
                  showCheckbox
                // style={{ marginBottom: 10, backgroundColor: "red" }}
                // className="search-wrapper searchWrapper "
                />
              )}
              <p style={{ marginBottom: 15 }}></p>
              {tagListItems && (
                <Multiselect
                  options={optionsTag}
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options,
                  selectedValues={tagListToAssign} // Preselected value to persist in dropdown
                  showCheckbox
                />
              )}

              {/* <div className="mt-2 wow fadeInDown">
              <label>
                {" "}
                <input
                  type="checkbox"
                  name="podcast"
                  checked={is_podcast === 1 ? true : false}
                  onChange={handleck}
                />{" "}
                {t("is_podcast")}{" "}
              </label>
            </div> */}
              <Button
                variant="primary"
                type="button"
                className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                onClick={onSave}
              >
                {" "}
                {t("Save")}{" "}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AllPackage;
