import React, { useEffect, useState} from 'react';
import Header from '../commom/Header2';
import { Row, Col, Image } from 'react-bootstrap';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Subscription from '../public/Subsctription'
import { useDispatch, useSelector } from 'react-redux';
import {monthlyPlan, yearlyPlanName,stripeKey} from '../commom/commonConst';

import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';
const BuySubscription = () =>{
   const stripePromise = loadStripe(stripeKey);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { plan_id,price,is_trial } = useParams();
   const [yearlyPlan] = useState( plan_id === yearlyPlanName);
   const [MonthlyPlan] = useState( plan_id === monthlyPlan);
   const [value, setValue] = useState({ quantity: 1});
   const { role_id } = useSelector((state) => state?.auth?.user);
   const onChange = (e) => {
      setValue({
         ...value,
         [e.target.name]: e.target.value,
       });
     };
     const handleDelete = (item) => {
       navigate('/pris'); 
    };
    const add3Dots = (string, limit) => {
      if(string) {
         var dots = "...";
         if (string?.length > limit) {
            string = string.substring(0, limit) + dots;
         }
      }
      return string !="null" ? string : '';
   }
   useEffect(() => {
        
    }, [])
   return(
      <>
         <Header />
         
         <div className='page-wrapper flex-grow-1 page-block overflow-hidden'>
        
            <Row className='gx-lg-14'>
               <Col lg={7}>
                  <div className='cart-leftwrapper'>
                     <div className='block text-white mb-13 '>
                        <h1 className='mb-2 h2 fw-semibold wow fadeInDown'>{t('Item_in_your_Cart')}</h1>
                        
                     </div>
                     <div className='leftblock-products position-relative'>
                   
                        <div className='course-block text-light d-flex align-items-center py-7 px-10 mb-lg-10 mb-8 wow fadeInDown'>
                           <span className='course-image flex me-8 flex-shrink-0'>
                              <Image src="https://docens.s3.eu-north-1.amazonaws.com/images/2022-12-05T13%3A48%3A38.473Z-all_course.png" alt='Excel' width={88} height={88} />
                           </span>
                           <div className='d-flex justify-content-between align-items-center flex-grow-1 flex-sm-nowrap flex-wrap'>
                              <div className='pe-5 flex-grow-1'>
                                 <h5 className='mb-1 text-white fw-medium'>{MonthlyPlan?'Månadsprenumeration': is_trial === 'true' && yearlyPlan?'7 dagar kostnadsfritt (Årsprenumeration)': 'Årsprenumeration'}</h5>
                                 { is_trial === 'true' && yearlyPlan &&
                                 <span className='font-14'>{t('Prova_Docens_i_14_dagar_kostnadsfritt_Avbryt_när_du_vill_Därefter_249_kr_i_mânaden')} {price/100} kr {t('i_mânaden')}</span>
                                 }
                              </div>
                              <div className='fw-medium flex-shrink-0 course-priceinfoblock w-sm-100 mt-sm-0 mynumberclass'>
                                 <input className='course-priceinfo text-center ' min="1" type='number' style={{width: "82px"}} name="quantity" value={value.quantity} onChange={onChange} disabled={role_id === 3}  ></input>
                                 <span className='course-priceinfo'>{is_trial === 'true' && yearlyPlan? 0 : price/100} Sek</span>
                              </div>
                              <a className='btn btn-link icon-ic-trash text-white link-hover action-btn' onClick={() => handleDelete()} ></a>
                           </div>
                        </div> 
                       
                     </div>
                    
                    
                  </div>
               </Col>
               <Col lg={5}>
                  <div className='cart-rightwrapper'>
                     <div className='block text-white mb-13'>
                        <h2 className='mb-2 fw-semibold opacity-0'>{t('Enter_payment_detail')}</h2>
                     </div>
                     <Elements stripe={stripePromise}>
                        <Subscription quantity={value.quantity} />
                        </Elements>
                  </div>

               </Col>
            </Row>   
           
         </div>
         
        
      </>
   )
}

export default BuySubscription;