import axios from "axios";
 const API_URL = "https://docens.se:3005/api/v1/";
//const API_URL = "http://localhost:3006/api/v1/";
//const API_URL = "http://docens.se:3006/api/v1/"
//const API_URL = "http://test.docens.se:3006/api/v1/";
export const LIVE_BASE_URL = "http://docens.se";
export const TEST_BASE_URL = "http://test.docens.se";
export const LCOAL_BASE_URL = "http://localhost:3000";

export const EDUCATION_LINK = `${LIVE_BASE_URL}/kontakta-oss-utbildning`;

axios.interceptors.request.use(
  async (config) => {
    const userJwtToken = localStorage.getItem("token");
    if (userJwtToken) {
      config.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error) {
      const originalConfig = error.config;
      if (originalConfig.url !== "/auth/signin" && error.response) {
        // Access Token was expired
        if (
          error.response.status === 403 &&
          error.response.data.message === "TokenExpiredError: jwt expired"
        ) {
          try {
            localStorage.clear();
            window.location = "/";
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        if (
          error.response.status === 401 &&
          error.response.data.message === "token missing"
        ) {
          try {
            localStorage.clear();
            window.location = "/";
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(error);
    }
    return Promise.resolve();
  }
);

const axiosExport = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  Api: API_URL,
};

export default axiosExport;
