import React, { useState, useEffect } from "react";
import Header from "../commom/Header2";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { changePass } from "../../store/api/auth.api";
import { useTranslation } from "react-i18next";
import {
  getActiveSubscription,
  cancleActiveSubscription,
  cancelActiveSubscriptionNew,
} from "../../store/api/course.api";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPopup from "../commom/ConfirmPopup";
import moment from "moment";
import { toast } from "react-toastify";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const { business_id, id, role_id } = useSelector((state) => state.auth.user);
  const [value, setValue] = useState({
    oldPassword: "",
    password: "",
    cpassword: "",
  });
  const [currentSubscrption, setCurrentSubscrption] = useState();
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });
  const handleDelete = (id) => {
    setPopup({
      show: true,
      id,
    });
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onChangePass = () => {
    dispatch(changePass(value))
      .unwrap()
      .then(() => {
        navigate("/logga-in");
      })
      .catch(() => { });
  };
  useEffect(() => {
    referesh();
  }, []);

  const referesh = () => {
    if ((role_id && role_id === 2) || role_id === 3) {
      let data = {
        business_id: business_id,
      };
      if (role_id === 2) {
        data.business_id = business_id;
      }
      if (role_id === 3) {
        data.user_id = id;
      }
      dispatch(getActiveSubscription(data))
        .unwrap()
        .then((res) => {
          setCurrentSubscrption(res.data);
        })
        .catch(() => { });
    }
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      cancleSubription();
      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };

  //   const cancleSubription = () => {
  //     let data = {
  //       stripe_subscription_id: stripe_subscription_id,
  //     };
  //     dispatch(cancleActiveSubscription(data))
  //       .unwrap()
  //       .then((res) => {
  //         if (res.status === 200) {
  //           toast.success(res.message);
  //           referesh();
  //         }
  //       })
  //       .catch(() => {});
  //   };
  const cancleSubription = () => {
    let data = {
      stripe_subscription_id: stripe_subscription_id,
    };
    dispatch(cancelActiveSubscriptionNew(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          referesh();
        }
      })
      .catch(() => { });
  };
  return (
    <div className="innerblock-page flex-grow-1">
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block changepassword-wrap">
        <InnerHeader title={t('Create_new_password')} subtitle="" />
        <Wrapper>
          <Row className="g-lg-20px g-16px">
            <Col lg={6}>
              <Form className="bg-white shadow-block rounded-20 p-20px">
                <div className="d-flex flex-column gap-lg-20px gap-16px">
                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium form-label">{t("Old_Password")}</Form.Label>
                    <Form.Control
                      type="password"
                      name="oldPassword"
                      placeholder={t("Old_Password")}
                      autoComplete="off"
                      aria-label="Password"
                      value={value.oldPassword}
                      onChange={onChange}
                      className="fs-20 mobile-font-14 form-inputblock"
                    />
                  </Form.Group>

                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium form-label">{t("nytt_lösenord")}</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder={t("nytt_lösenord")}
                      autoComplete="off"
                      aria-label="Password"
                      value={value.password}
                      onChange={onChange}
                      className="fs-20 mobile-font-14 form-inputblock"
                    />
                    <Form.Text className="text-svart fw-medium font-12 d-block mt-1">
                      {t("Must_be_at_least_8_charachter")}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group
                    className="wow fadeInDown"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-svart fs-20 mobile-font-14 fw-medium form-label">{t("Confirm_new_Password_2")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("Confirm_new_Password_2")}
                      name="cpassword"
                      aria-label="Confirm New Password"
                      onChange={onChange}
                      className="fs-20 mobile-font-14 form-inputblock"
                    />
                  </Form.Group>
                  <div className="text" style={{ "text-align": "center" }}>
                    <Button
                      variant="primary"
                      type="button"
                      className=" w-100 font-20 mobile-font-16 btn-small wow fadeInDown"
                      onClick={onChangePass}
                    >
                      {t("Reset_password")}
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
            <Col lg={6}>
              {/* <div className='block-wrapper mx-md-auto'>
                     <div className='block text-white mb-10 text-white'>
                        <h1 className='mb-2 h2 fw-semibold wow fadeInDown'>Edit Settings </h1>
                        <p className='wow fadeInDown'>Change Type.</p>
                     </div>
                     <Form>
                     <Form.Group className="mb-12 wow fadeInDown" controlId="formBasicEmail">
                        <Form.Label className='font-16 fw-bold text-white mb-5'>Type</Form.Label>
                        <div className=' d-flex flex-wrap'>
                           <Form.Check type="radio" style={{ 'margin-left': '10px' }} label="business" name="register_type" value={'true'} id="radio1" onChange={onChange} checked={value.register_type === 'business'} />
                           <Form.Check type="radio" style={{ 'margin-left': '10px' }} label="private" name="register_type" value={'true'} id="radio2" onChange={onChange} checked={value.register_type === 'private'} />
                        </div>
                     </Form.Group>
                        
                        <Row>
                           <Col md={6}>
                           <Form.Group className="mb-12 wow fadeInDown" controlId="formBasicEmail">
                        <Form.Label className='font-16 fw-bold text-white mb-5'>Notification require</Form.Label>
                        <div className=' d-flex flex-wrap ml-5'>
                           <Form.Check type="radio" style={{ 'margin-left': '10px' }} label="Yes" name="notification_type" value={'true'} id="radio3" onChange={onChange} checked={value.notification_type === 'true'} />
                           <Form.Check type="radio" style={{ 'margin-left': '10px' }} label="No" name="notification_type" value={'true'} id="radio4" onChange={onChange} checked={value.notification_type === 'private'} />
                        </div>
                     </Form.Group>
                              
                           </Col>
                           
                        </Row>
                        
                        <Button variant="primary" type="submit" className=' w-100 font-20 btn-small mt-lg-10 wow fadeInDown'> Change Setting
                        </Button>
                     </Form>
                  </div> */}

              {currentSubscrption?.name != undefined && (
                <>
                  <div className="block-wrapper mx-md-auto">
                    <div className="block text-white mb-10 text-white">
                      <h1 className="mb-2 h2 fw-semibold wow fadeInDown">
                        Prenumeration översikt{" "}
                      </h1>
                    </div>

                    <div className="cart-submain d-flex flex-wrap text-white-25 font-13 mb-8">
                      <div className="cart-subleft ">
                        <span className="mb-0">Pris</span>
                      </div>
                      <div className="cart-subright">
                        <span className="fw-medium mb-0">
                          {currentSubscrption?.price / 100}{" "}
                          {currentSubscrption?.currency} /månaden
                        </span>
                      </div>
                      <div className="cart-subleft ">
                        <span className="mb-0">Plan</span>
                      </div>
                      <div className="cart-subright">
                        <span className="fw-medium mb-0">
                          {currentSubscrption?.is_year_plan
                            ? "Årsprenumeration"
                            : "Månadsprenumeration"}
                        </span>
                      </div>
                      {currentSubscrption?.trial_end &&
                        currentSubscrption?.is_cancel !== 1 && (
                          <>
                            <div className="cart-subleft ">
                              <span className="mb-0">Din provperiod slutar</span>
                            </div>
                            <div className="cart-subright">
                              <span className="fw-medium mb-0">
                                {moment(currentSubscrption?.trial_end).format(
                                  "DD/MM-YYYY"
                                )}
                              </span>
                            </div>
                          </>
                        )}
                      {currentSubscrption?.is_cancel === 1 && (
                        <>
                          <div className="cart-subleft ">
                            <span className="mb-0">Din prenumeration slutar</span>
                          </div>
                          <div className="cart-subright">
                            <span className="fw-medium mb-0">
                              {moment(currentSubscrption?.expire_at).format(
                                "DD/MM-YYYY"
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    {currentSubscrption?.is_cancel != 1 && (
                      <Button
                        variant="primary"
                        type="submit"
                        className="cancle_width font-20 btn-small mt-lg-10 wow fadeInDown"
                        onClick={() => handleDelete(1)}
                      >
                        Avbryt prenumeration
                      </Button>
                    )}
                  </div>
                </>
              )}
              {popup.show && (
                <ConfirmPopup
                  handleDeleteTrue={handleDeleteTrue}
                  handleDeleteFalse={handleDeleteFalse}
                  message={t("You_sure_you_cancle_subscription")}
                />
              )}
            </Col>
          </Row>
        </Wrapper>
      </div>
    </div>
  );
};

export default ChangePassword;
