import React, { useEffect, useState } from "react";

import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Row, Col, Image, Button, Modal, Form } from "react-bootstrap";

import {
  addEducations,
  getEducations,
  publishEducation,
  removeEducation,
} from "../../store/api/podcast.api";
import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import { getCreator } from "../../store/api/course.api";
import ConfirmPopup from "../commom/ConfirmPopup";
import Footer from "../commom/Footer";
import EditEducation from "../commom/EditEducation";
import { ck_config } from "../commom/commonConst";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";

const MyEducationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { role_id, id } = useSelector((state) => state?.auth?.user);

  const [show, setShow] = useState(false);
  const [allEducations, setAllEducations] = useState();
  const [allCretor, setAllCreator] = useState();
  const [value, setValue] = useState({
    title: "",
    sub_title: "",
    course_length: "",
    course_fee: "",
    course_start: "",
    extent: "",
  });
  const [textContent, setTextContent] = useState(null);
  const [textDescription, setTextDescription] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [showEditEducation, setshowEditEducation] = useState(false);
  const [editId, setEditId] = useState(null);
  const [pubMessage, setPubMessage] = useState("");
  const [whatyouwillLearn, setWhatyouwilllearn] = useState(null);
  const [textSuitableFor, setTextSuitableFor] = useState(null);

  // open when we click edit education
  const openShow = (id) => {
    setEditId(id);
    setshowEditEducation(true);
  };
  const editorConfiguration = ck_config;
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
  };

  if (role_id !== 1) {
    dataFilter.filter.is_public = 1;
  }
  const options = [];

  // get data of education list api
  useEffect(() => {
    dispatch(getEducations(dataFilter)).then((education) => {
      setAllEducations(education.payload.data);
    });
    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
      setSelectedCreator(data.payload.data[0].creator_id);
    });
  }, []);

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeCreator = (event) => {
    setSelectedCreator(event.value);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const closeModal = () => {
    setshowEditEducation(false);
    dispatch(getEducations(dataFilter)).then((education) => {
      setAllEducations(education.payload.data);
    });
  };

  allCretor &&
    allCretor.map((list) =>
      options.push({ value: list.creator_id, label: list.creator_name })
    );
  const EducationsList = ({ education, onDelete, index }) => {
    return (
      <Col md={6} xl={4}>
        <div className="getstart-block position-relative d-flex flex-column feature-box">
          <>
            {" "}
            <Image
              className="img-fit cursor_class feature-img"
              src={
                education.image
                  ? education.image
                  : "/assets/images/placeholder-image.png"
              }
              alt="Image"
              width={526}
              height={348}
              onClick={() => gotoInnerPage(education)}
            />
            <div className="img-content text-white d-flex align-items-start justify-content-end">
              <div className="bottom-text text-md-start text-center">
                <h5
                  className="mb-1 fw-medium cursor_class"
                  onClick={() => gotoInnerPage(education)}
                >
                  {education.title}
                </h5>
                <p
                  className="cursor_class tree-dot mb-0"
                  // dangerouslySetInnerHTML={{
                  //   __html: add3Dots(education.content, 100),
                  // }}

                  onClick={() => gotoInnerPage(education)}
                >
                  {education.sub_title}
                </p>

                <div className="mt-1">
                  {role_id === 1 && (
                    <a
                      className="btn btn-link icon-ic-trash text-white link-hover "
                      onClick={(e) => handleDelete(e, education.id)}
                    >
                      {" "}
                    </a>
                  )}
                  {popup.show && (
                    <ConfirmPopup
                      handleDeleteTrue={handleDeleteTrue}
                      handleDeleteFalse={handleDeleteFalse}
                      message={t("You_sure_you_wanna_delete")}
                    />
                  )}
                  {role_id === 1 && (
                    <a
                      className="btn btn-link icon-ic-edit text-white link-hover"
                      style={{ "margin-left": "5px" }}
                      onClick={() => openShow(education.id)}
                    ></a>
                  )}
                  {showEditEducation && editId === education.id && (
                    <EditEducation
                      show={showEditEducation}
                      editId={editId}
                      closeModal={closeModal}
                      selectedEducationData={education}
                    />
                  )}
                  {role_id === 1 && (
                    <label className="toggle-public-label">
                      <input
                        type="checkbox"
                        value={education.is_public}
                        checked={education.is_public === 1 ? true : false}
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span
                          className="label on"
                          onClick={() => handlePublish(false, education)}
                        >
                          {t("Off")}
                        </span>
                        <span
                          className="label off"
                          onClick={() => handlePublish(true, education)}
                        >
                          {t("On")}
                        </span>
                      </span>
                    </label>
                  )}
                  {publishPopup.show && (
                    <ConfirmPopup
                      handleDeleteTrue={handlePublishTrue}
                      handleDeleteFalse={handlePublishFalse}
                      message={t(pubMessage)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </Col>
    );
  };

  // delete education
  const onDelete = (id) => {
    let data = {
      education_id: id,
      is_delete: 1,
    };
    dispatch(removeEducation(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getEducations(dataFilter)).then((education) => {
            setAllEducations(education.payload.data);
            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const onSave = () => {
    var data = new FormData();
    data.append("title", value.title);
    data.append("content", textContent);
    data.append("creator_id", selectedCreator);
    data.append("what_you_will_learn", whatyouwillLearn);
    data.append("suitable_for", textSuitableFor);
    data.append("sub_title", value.sub_title);
    data.append("course_length", value.course_length);
    data.append("course_start", value.course_start);
    data.append("course_fee", value.course_fee);
    data.append("description", textDescription);
    data.append("extent", value.extent);

    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }

    dispatch(addEducations(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getEducations(dataFilter)).then((education) => {
            setAllEducations(education.payload.data);
            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });

  const [publishPopup, setPublishPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
    flag: false,
  });
  const handleDelete = (e, id) => {
    e.preventDefault();
    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      onDelete(popup.id);
      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };

  const handlePublish = (flag, education) => {
    setPublishPopup({
      show: true,
      education,
      flag,
    });
    if (flag) {
      setPubMessage("Vill_du_publicera_denna_utbildning");
    } else {
      setPubMessage("Vill_du_ta_bort_den_här_utbildningen");
    }
  };
  const handlePublishTrue = () => {
    if (publishPopup.show && publishPopup.education) {
      makeMePublic(publishPopup.flag, publishPopup.education);
      setPublishPopup({
        show: false,
        id: null,
        flag: false,
      });
    }
  };
  const handlePublishFalse = () => {
    setPublishPopup({
      show: false,
      education: null,
      flag: false,
    });
  };

  const makeMePublic = (flag, education) => {
    let data;
    if (education?.id) {
      data = {
        education_id: education.id,
        is_public: flag == true ? 1 : 0,
      };
      dispatch(publishEducation(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            dispatch(getEducations(dataFilter)).then((education) => {
              setAllEducations(education.payload.data);
            });
          }
        });
    }
  };

  return (
    <>
      <div className="innerblock-page">
        <Header headerShow={false} />
        <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
          <InnerHeader title={t('Utbildning')} subtitle="" />

          <Wrapper>

            {role_id === 1 && (
              <>
                <div className="right-button d-flex align-items-center justify-content-end flex-wrap gap-10px mb-3">
                  <Button
                    variant="primary"
                    className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100"
                    onClick={handleShow}
                  >
                    {t("Add_New")}
                  </Button>
                </div>
              </>
            )}


            <Row className="g-xl-20px g-8px">
              {allEducations?.length > 0 &&
                allEducations?.map((education, key) => (
                  <EducationsList
                    education={education}
                    key={key}
                    index={key}
                    onDelete={onDelete}
                  />
                ))}
            </Row>
          </Wrapper>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Create_Education")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/*Title*/}
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={"Title"}
                    name="title"
                    aria-label="Product Name"
                    value={value.title}
                    onChange={onChange}
                  />
                  <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                </div>
              </Form.Group>

              {/*Sub title*/}
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("Underrubrik")}
                    name="sub_title"
                    aria-label="Product Name"
                    value={value.sub_title}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-10 wow fadeInDown">
                {options && (
                  <Select
                    className="custom-select black-theme"
                    classNamePrefix="myselect"
                    options={options}
                    onChange={onChangeCreator}
                  />
                )}
              </Form.Group>

              {/*Image*/}
              <Row className="g-xl-20px g-8px">
                <Col md={6} xl={4}>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file1"
                      className="visually-hidden"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="file1"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/Video_file_light.png"
                          alt="Select Image"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Select_Image")}</span>
                    </label>
                  </div>
                </Col>
              </Row>

              {/**content */}
              <p className="mb-sm-0  fw-semibold text-white h3">{t("Content")}</p>
              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  style={{ color: "#fff" }}
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter data"
                  onChange={(event, editor) => {
                    setTextContent(editor.getData());
                  }}
                />
              </div>
              {/**description */}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Beskrivning")}
              </p>
              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  style={{ color: "#fff" }}
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter description"
                  onChange={(event, editor) => {
                    setTextDescription(editor.getData());
                  }}
                />
              </div>
              {/**what you will learn */}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("What_you_will_learn")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="What you will learn"
                  onChange={(event, editor) => {
                    setWhatyouwilllearn(editor.getData());
                  }}
                />
              </div>
              {/*Who is the course suitable for ?*/}
              <p className="mb-sm-0  fw-semibold text-white h3">
                {t("Who_suitable_for")}
              </p>

              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Who is the course suitable for?"
                  onChange={(event, editor) => {
                    setTextSuitableFor(editor.getData());
                  }}
                />
              </div>

              {/*Course length*/}
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("Course_length")}
                    name="course_length"
                    aria-label="Course Length"
                    value={value.course_length}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              {/*Course start*/}
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("Course_start")}
                    name="course_start"
                    aria-label="Product Name"
                    value={value.course_start}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              {/*Course fee*/}
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("Course_fee")}
                    name="course_fee"
                    aria-label="Product Name"
                    value={value.course_fee}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              {/*Course extent*/}
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("Extent")}
                    name="extent"
                    aria-label="Product Name"
                    value={value.extent}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              <Button
                variant="primary"
                type="button"
                className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                onClick={onSave}
              >
                {" "}
                {t("Save")}{" "}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div >
    </>
  );
};

export default MyEducationDetails;
