import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addTest,
  getCources,
  getSingleExamDetail,
  editTest,
} from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const AddNewTest = (prop) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [allCources, setAllCources] = useState();
  const [selectedCourse, setselectedCourse] = useState(null);
  const [value, setValue] = useState({ exam_name: "" });
  const options = [];
  const handleClose = () => {
    setShow(false);
    prop.callbackModal();
  };
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };

  useEffect(() => {
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources(course.payload.data.courses);
    });
    if (prop.editId) {
      dispatch(getSingleExamDetail(prop.editId)).then((exam) => {
        setselectedCourse(exam.payload.data[0].course_id);
        setValue({
          ...value,
          ["exam_name"]: exam.payload.data[0].exam_name,
        });
      });
    }
    setShow(prop.show);
  }, []);
  const onChangeCourse = (selectedOption) => {
    setselectedCourse(selectedOption.value);
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onAdd = () => {
    let body = {
      course_id: selectedCourse,
      exam_name: value.exam_name,
    };
    dispatch(addTest(body))
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  };
  const onEdit = () => {
    let body = {
      id: prop.editId,
      course_id: selectedCourse,
      exam_name: value.exam_name,
    };
    dispatch(editTest(body))
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  };
  allCources &&
    allCources.map((list) =>
      options.push({ value: list.id, label: list.title })
    );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {prop.editId ? (
              <span>{t("Edit_Test")}</span>
            ) : (
              <span>{t("Add_New_Test")}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="page-wrapper flex-grow-1">
            <Container fluid>
              <div className="max-950 overflow-hidden">
                <Form>
                  <Form.Group className="mb-10 wow fadeInDown">
                    <Form.Label className="font-16 fw-bold text-white mb-5">
                      {t("Select_Course")}
                    </Form.Label>
                    {options && (
                      <Select
                      className="custom-select black-theme"
                      classNamePrefix="myselect"
                        options={options}
                        onChange={onChangeCourse}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-10" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      name="exam_name"
                      placeholder="Exam name"
                      autoComplete="off"
                      aria-label="exam_name"
                      value={value.exam_name}
                      onChange={onChange}
                    />
                  </Form.Group>
                  {!prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onAdd}
                    >
                      {" "}
                      {t("Save")}{" "}
                    </Button>
                  )}
                  {prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onEdit}
                    >
                      {" "}
                      {t("Update")}{" "}
                    </Button>
                  )}
                </Form>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewTest;
