import React, {useEffect,useState} from 'react';
import { Container, Form, Button, Image,Modal,Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {addQuestions} from '../../store/api/course.api';
import { useTranslation } from "react-i18next";


const AddNewQuestion = (prop) => {
   const dispatch = useDispatch();
   const [show, setShow] = useState(false);
   const { t } = useTranslation();

   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   } 
  
    
   useEffect(() => {
      setShow(prop.show)
   }, [])
   const [formValues, setFormValues] = useState([{ question: "", option_1 : "",option_2 : "",option_3 : "",option_4 : "",is_correct_ans:""}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { question: "", option_1 : "",option_2 : "",option_3 : "",option_4 : "",is_correct_ans:""}])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }
    
    let handleSubmit = (event) => {
        event.preventDefault();
      let body = {
      'exam_id':prop.id,
      'questions': formValues,
      }
     
      dispatch(addQuestions(body))
      .unwrap()
      .then(() => {
         setShow(false);
        prop.callbackModal();
      })
      .catch(() => {
      });
    }
   return(
      <>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title>{t('Add_New_Question')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
         <div className='page-wrapper flex-grow-1'>
            <Container fluid>
               <div className='max-950 overflow-hidden'>
                  <Form  >
                  
                  {formValues.map((element, index,row) => (
                     <div className="form-inline" key={index}>
                    
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formTitle">
                        <Form.Control type="text" name="question" placeholder={t('Question')} autoComplete="off" aria-label="Title" value={element.question || ""}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                     <Row className='gx-lg-13 gy-13'>
                     <Col lg={6} xl={6}>

                     <Form.Group className="mb-10 wow fadeInDown" controlId="formTitle">
                        <Form.Control type="text" name="option_1" placeholder={t('Enter_answer_1')} autoComplete="off" aria-label="Title" value={element.option_1 || ""}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formVideoLength">
                     <Form.Control type="text" name="option_2" placeholder={t('Enter_answer_2')} autoComplete="off" aria-label="length" value={element.option_2}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                     </Col>
                     <Col lg={6} xl={6}>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formVideoLength">
                     <Form.Control type="text" name="option_3" placeholder={t('Enter_answer_3')} autoComplete="off" aria-label="length" value={element.option_3}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                     <Form.Group className="mb-10 wow fadeInDown" controlId="formVideoLength">
                     <Form.Control type="text" name="option_4" placeholder={t('Enter_answer_4')} autoComplete="off" aria-label="length" value={element.option_4}
                        onChange={e => handleChange(index, e)} />
                     </Form.Group>
                     </Col>
                     </Row>
                    
                     
                    
                     <div className='d-flex align-items-center mb-10 wow fadeInDown'>
                        <Form.Group className='flex-grow-1 question-control'>
                        <Form.Control type="text" name="is_correct_ans" placeholder={t('Enter_correct_answer')} autoComplete="off" aria-label="length" value={element.is_correct_ans}
                        onChange={e => handleChange(index, e)} />
                        </Form.Group>
                        <div className="plus-minusbtn d-flex flex-shrink-0">
                        {index + 1 === row.length &&
                           <a role="button" className='btn btn-link' onClick={() => addFormFields()}>
                              <Image src='/assets/images/plus-icon.svg' alt='Plus' width={45} height={45} />
                           </a>
                           }
                           {index + 1 !== row.length &&
                            <a role="button" className='btn btn-link' onClick={() => removeFormFields(index)}>
                            <Image src='/assets/images/minus-icon.svg' alt='Plus' width={45} height={45} />
                         </a>
                           }
                          
                        </div>
                     </div>

                     </div>
                  ))}
                 
                 <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={handleSubmit}> {t('Save')} </Button>
               </Form>
                 
               </div>
            </Container>
         </div>
         </Modal.Body>
         </Modal>
         
      </>
   )
}

export default AddNewQuestion;