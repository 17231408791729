import React, { useState, useEffect } from 'react';
import Header from '../commom/Header2';
import { Container, Table ,Modal} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { couponUserList } from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import moment from 'moment';
const CouponUsers = (prop) => {
  const [show, setShow] = useState(prop.showOpen);
  const [allnewsLatters, setAllnewsLatters] = useState();
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "coupon_name":prop.couponName
    }
  }
  useEffect(() => {
    setShow(prop.show)
    refresh();
  }, [])
  const refresh = () => {
    console.log(data);
    dispatch(couponUserList(data)).then((res) => {
      setAllnewsLatters(res.payload.data);
    })
  }
  const { t } = useTranslation();
  const handleClose = () => {
    setShow(false);
    prop.closeModal();
 } 

  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
       <Modal.Title>{t('Total_User')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className='wow fadeInDown'>
    <Table bordered hover responsive className='text-center'>
                  <thead>
                    <tr>
                      <th>{t('E-post')}</th>
                      <th>{t('Name')}</th>
                      <th>{t('Is_single')}</th>
                      <th>{t('Date')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      allnewsLatters && allnewsLatters.length > 0 && allnewsLatters?.map((item, index) => (
                        <tr key={item.id}>
                          <td>{item?.email}</td>
                          <td>{item.coupon_name}</td>
                          <td>{item.from_single===1?'true':'false'}</td>
                          <td>{moment(item?.created_on).format("DD-MMM-YYYY")}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                {allnewsLatters && allnewsLatters.length === 0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                }
                </div>
                </Modal.Body>
         </Modal>
         
  )
}


export default CouponUsers;