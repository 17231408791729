import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Header from "../commom/Header2";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactUsPopup from '../commom/ContactUsPopup';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getEducations } from "../../store/api/podcast.api";

const PrivateEducationIndividual = () => {

    const [educationData, setEducationData] = useState();
    const [show, setShow] = useState(false);
    const { educationid } = useParams();
    const dispatch = useDispatch();
    const add3Dots = (string, limit) => {
      if (string) {
        var dots = "...";
        if (string?.length > limit) {
          string = string.substring(0, limit) + dots;
        }
      }
      return string != "null" ? string : "";
    };
    const { role_id } = useSelector((state) => state?.auth?.user || {});
    const navigate = useNavigate();
  
    const onCLickGotoContactUs = () => {
        setShow(!show)
      // navigate("/kontakta-oss-utbildning");
    };
    const callbackModal = () => {
      setShow(false);
    };
    useEffect(() => {
        let dataFilter = {
          filter: {
            is_delete: 0,
            is_public: 1,
            slug:educationid
          },
          
        };
        dispatch(getEducations(dataFilter)).then((course) => {
          setEducationData(course.payload.data[0]);
        });
      }, []);
    return (
        <div
            className={` innerblock-page  flex-grow-1`}
        >
            <Header headerShow={false}  />
            <div className="page-wrapper flex-grow-1 page-block">
                <InnerHeader title={"Utbildningar"} subtitle="Unika utbildningar för din yrkesroll" />
                <div className="py-lg-40px px-xxl-100px wrapper-main  px-lg-30px px-20px flex-grow-1 pb-40px">
                    <div className="shadow-block rounded-20 bg-white mb-8">
                        <div className="p-20px title-header">
                            <TextBlock title={educationData?.title} subtitle={educationData?.sub_title} />
                        </div>
                        <div className="p-20px">
                            <Row className="gy-md-3 flex-column-reverse flex-md-row">
                                <Col md={6}>
                                    <div className="text-svart fw-light d-flex flex-column">
                                        <div className="flex-grow-1 font-20 mobile-font-14"
                                        dangerouslySetInnerHTML={{
                                            __html: add3Dots(educationData?.content, 500),
                                          }}>  
                                        </div>
                                        <div>
                                            <Button className="mob-w-100 btn btn-small d-sm-inline d-block px-6 me-sm-5 me-0 rounded-pill" onClick={onCLickGotoContactUs} variant="gradient" title="Intresseanmälan"><span>Säkra din plats</span> </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="mb-5">
                                <div className="">
                                        <Image
                                        src={educationData?.image}
                                        alt=""
                                        className="rounded-20 w-100 object-fit"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="shadow-block rounded-20 bg-white overflow-hidden">
                        <div className="p-20px title-header">
                            <TextBlock title={educationData?.title} subtitle={educationData?.sub_title} />
                        </div>
                        <div>
                            <div className="titleheader-box">
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">Vad du kommer lära dig</h5>
                                    <div className="fs-20 fw-light text-black" 
                                    dangerouslySetInnerHTML={{
                                    __html: educationData?.what_you_will_learn,
                                  }}
                                ></div>
                                </div>
                              
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">Vem passar kursen för?</h5>
                                    <div className="fs-20 fw-light text-black block"
                                     dangerouslySetInnerHTML={{
                                        __html: educationData?.suitable_for,
                                      }}>
                                        
                                    </div>
                                </div>
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">Kurslängd</h5>
                                    <div className="fs-20 fw-light text-black">{educationData?.course_length}</div>
                                </div>
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">Kursstart</h5>
                                    <div className="fs-20 fw-light text-black">{educationData?.course_start}</div>
                                </div>
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">Kursavgift</h5>
                                    <div className="fs-20 fw-light text-black">{educationData?.course_fee}</div>
                                </div>
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">Lärare</h5>
                                    <div className="fs-20 fw-light text-black">{educationData?.extent}</div>
                                </div>
                                <div className="text-center p-20px">
                                    <Button variant="gradient" className="font-16 mob-w-100 desktop-btn-big  "  onClick={onCLickGotoContactUs}><span> Säkra din plats </span></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show && (
             <ContactUsPopup titledata={educationData?.title}
             show={show}
             callbackModal={callbackModal}/>
          )}
        </div>
        
    )
}

export default PrivateEducationIndividual;