import React, {useEffect,useState} from 'react';
import { Container, Form, Button, Image,Modal,Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {editCourse, getSingleProduct,getCreator,tagList} from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from 'multiselect-react-dropdown';
import { ck_config } from "./commonConst";

const EditCourse = (prop) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [value, setValue] = useState({ title: '', RegularPrice: '', SpecialPrice: '',tags:'',certificate_text:'', course_sub_title:'',sort_title:'' });
    const options = [];
    const [selectedFile, setSelectedFile] = useState(null);
   const [selectedPdf, setSelectedPDF] = useState(null);
   const [selectedCreator, setSelectedCreator] = useState(null);
   const [textContent, setTextContent] = useState(null);
   const { id, role_id } = useSelector((state) => state.auth.user);
   const [allCretor, setAllCreator] = useState();
   let optionsTag = [];
   const [tagListItems, setTagList] = useState([])
   const [tagListToAssign,setTagListToAssign]= useState();
   const [is_podcast, setIsPodcast] =  useState(0);
   const [textIncludedinCourse, setTextIncludedinCourse] = useState(null);
  const [textSuitableFor, setTextSuitableFor] = useState(null);
   let tags = [];
   const editorConfiguration = ck_config;
    const handleClose = () => {
       setShow(false);
       prop.closeModal();
    } 
    const onChange = (e) => {
        setValue({
           ...value,
           [e.target.name]: e.target.value,
        });
     };
     const onChangeCreator = event => {
        setSelectedCreator(event.value);
     };
     
    useEffect(() => {
       setShow(prop.show);
       dispatch(tagList()).then((data) => {
        setTagList(data.payload.data);
     })
       dispatch(getCreator()).then((data) => {
        setAllCreator(data.payload.data);
        setSelectedCreator(data.payload.data[0].creator_id);
     })
       if (prop.editId) {
        let req = {
            id:prop.editId,
         }
         if (id){
            req.user_id = id;
            req.role_id = role_id;
         }
        dispatch(getSingleProduct(req)).then((res) => {
            setTextContent(res.payload.data.content);
            setTextIncludedinCourse(res.payload.data.included_in_course)
            setTextSuitableFor(res.payload.data.suitable_for)
          
            let selectedTag = [];
            res.payload.data.tags.map((item, key) => (
                selectedTag.push({'id':item.id,'name':item.name})
            ));
            setTagListToAssign(selectedTag)

            setIsPodcast(res.payload.data.podcast)

           setValue({
              ...value,
              ['title']: res.payload.data.title,
              ['course_sub_title']: res.payload.data.course_sub_title,
              ['sort_title']: res.payload.data.sort_title,
              ['RegularPrice']:res.payload.data.r_price,
              ['SpecialPrice']:res.payload.data.s_price,
              ['tags']:res.payload.data.tags,
              ['certificate_text']:res.payload.data.certificate_text,
            });
        })
     }
    }, [])
    const onSave = () => {
        tagListToAssign && tagListToAssign.map(list =>
            tags.push(list.id)
         )
        var data = new FormData();
        data.append('id',prop.editId);
        data.append('title', value.title);
        data.append('course_sub_title', value.course_sub_title);
        data.append('sort_title', value.sort_title);
        data.append('content', textContent);
        data.append('r_price', value.RegularPrice);
        data.append('s_price', value.SpecialPrice);
        data.append('tags',tags);
        data.append('podcast', is_podcast);
        data.append('certificate_text',value.certificate_text);
        data.append("included_in_course", textIncludedinCourse);
        data.append("suitable_for", textSuitableFor);
        if (selectedFile) {
           data.append('image', selectedFile, selectedFile.name);
        }
        if(selectedPdf) {
           data.append('file', selectedPdf, selectedPdf.name);
        }
        
  
        dispatch(editCourse(data))
           .unwrap()
           .then((res) => {
              if (res.status === 200) {
                handleClose();
              }
           })
           .catch((e) => {
              console.log(e)
           });
           
     };
     allCretor && allCretor.map(list =>
        options.push({value:list.creator_id,label:list.creator_name})
        );
        tagListItems && tagListItems.map(list =>
            optionsTag.push({name:list.name,id:list.id})
            
        );
            
        
        const onSelect = (selectedList, selectedItem) => {
            setTagListToAssign(selectedList)
         }
         const onRemove = (selectedList, removedItem) => {
            
            setTagListToAssign(selectedList)
         }
         const handleck = (e) => {
            setIsPodcast(e.target.checked?1:0)
          };
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Create_Courses')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                            <div className='position-relative control-withic'>
                                <Form.Control type="text" placeholder={('Title')} name="title" aria-label="Product Name" value={value.title} onChange={onChange} />
                                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                            <div className='position-relative control-withic'>
                                <Form.Control type="text" placeholder={t('Underrubrik')} name="course_sub_title" aria-label="Product Name" value={value.course_sub_title} onChange={onChange} />
                                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                            <div className='position-relative control-withic'>
                                <Form.Control type="text" placeholder={t('sort_title')} name="sort_title" aria-label="Product Name" value={value.sort_title} onChange={onChange} />
                                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                            </div>
                        </Form.Group>
                        
                        {/* <Form.Group className='mb-10 wow fadeInDown'>
                            {options &&
                                <Select className="custom-select" options={options} onChange={onChangeCreator} />
                            }
                        </Form.Group> */}
                        <Row className='gy-10'>
                            <Col sm={6}>
                                <div className='mb-10 wow fadeInDown'>
                                    <input type="file" id="file1" className='visually-hidden' onChange={(e) => setSelectedFile(e.target.files[0])} />
                                    <label htmlFor="file1" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                                        <span className='file-icon'><Image src="/assets/images/Video_file_light.png" alt="Select Image" width="48" height="48" /></span>
                                        <span>{t('Select_Image')}</span>
                                    </label>
                                </div>
                            </Col>
                            {/* <Col sm={6}>
                                <div className='mb-10 wow fadeInDown'>
                                    <input type="file" id="file2" className='visually-hidden' onChange={(e) => setSelectedPDF(e.target.files[0])} />
                                    <label htmlFor="file2" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                                        <span className='file-icon'><Image src="/assets/images/fileupload-ic.png" alt="Attachments Link Pdf" width="48" height="48" /></span>
                                        <span>{t('Attachments_Link_Pdf')}</span>
                                    </label>
                                </div>
                            </Col> */}
                        </Row>

                        <div className='mb-10 wow fadeInDown'>
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={textContent}
                                placeholder="Enter data"
                                onChange={(event, editor) => {
                                    setTextContent(editor.getData());
                                }}

                            />
                        </div>
            {/**included in package */}
            <p className="mb-sm-0  fw-semibold text-white h3">
                        {t("Included_in_course")}
                        </p>

                        <div className="mb-10 wow fadeInDown">
                        <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={textIncludedinCourse}
                            placeholder="Enter what is included in course"
                            onChange={(event, editor) => {
                            setTextIncludedinCourse(editor.getData());
                            }}
                        />
                        </div>
                        {/*Who is the course suitable for ?*/}
                        <p className="mb-sm-0  fw-semibold text-white h3">
                        {t("Who_suitable_for")}
                        </p>

                        <div className="mb-10 wow fadeInDown">
                        <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={textSuitableFor}
                            placeholder="Who is the course suitable for?"
                            onChange={(event, editor) => {
                            setTextSuitableFor(editor.getData());
                            }}
                        />
                        </div>
                        <div className='d-flex flex-wrap price-wrapper'>
                            <Form.Group className="mb-10 wow fadeInDown" controlId="formRegularPrice">
                                <Form.Control type="number" placeholder={t("Regular_Price")} name="RegularPrice" aria-label="Regular Price" value={value.RegularPrice} onChange={onChange} />
                            </Form.Group>
                            <Form.Group className="mb-10 wow fadeInDown" controlId="formSpecialPrice">
                                <Form.Control type="number" placeholder={t('Special_Price')} name="SpecialPrice" aria-label="Special Price" value={value.SpecialPrice} onChange={onChange} />

                            </Form.Group>
                        </div>
                        <Form.Group className="mb-10 wow fadeInDown" controlId="formGroupAboutUs">
                        <Form.Control as="textarea" rows={2} placeholder={t('Certificate_Text')} name='certificate_text' aria-label="Certificate Text" value={value.certificate_text} onChange={onChange}  />
                        </Form.Group>
                        {tagListItems &&
                     <Multiselect
                        options={optionsTag}
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options,
                        selectedValues={tagListToAssign} // Preselected value to persist in dropdown
                        showCheckbox
                     /> 
                  }
                  <div className="mt-2 wow fadeInDown">
                            <label> <input type="checkbox" name="podcast"  checked={is_podcast === 1?true:false} onChange={handleck} /> {t('is_podcast')} </label>
                 </div>
                        <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onSave}> {t('Save')} </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default EditCourse;

