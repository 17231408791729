import React, { useEffect, useState } from 'react';
import Header from '../commom/Header';
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { searchInchatgtp, searchInblog } from '../../store/api/course.api'
import { toast } from 'react-toastify';

import Footer from '../commom/Footer';
function ChatGtpSearch(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState({ question: "" });
  const [allItems, setAllItems] = useState({});
  const [isSubmited, IsSetSubmited] = useState(false);
  const [allBlog, setAllBlog] = useState([]);
  const arrayOfList = [];
  useEffect(() => {

  }, []);
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const searchIngtp = () => {
    dispatch(searchInchatgtp(value))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          // setValue({ question: "" });
          setAllItems(res.data.data);

          dispatch(searchInblog(value))
            .unwrap()
            .then((res) => {
              setAllBlog(res.data.data);
              IsSetSubmited(true)
            })
            .catch(() => {

            });
        }
      })
      .catch(() => {

      });
  }

  return (
    <div className='d-flex flex-column flex-grow-1 homepage'>
      <Header />
      <div className='page-wrapper flex-grow-1 py-0'>
        {/* Starts Page Banner */}

        <div className='banner-wrapper position-relative'>

          <Container fluid>
            <div className="pt-100 pb-10 banner-login position-relative">
              <div className='bottom-container-fixed '>
                <Form>
                  <div className='ex-class'>
                    <h2 className="font-medium fw-light text-center ">{t('looking_something')} <span className='fw-bold'>{t('Search_in_platform')}</span></h2>
                    <p className='pb-50 text-center'>{t('Search_in_easy')}</p>
                  </div>
                  <Form.Group className="em-group" controlId="formBasicEmail">
                    <Form.Control type="text" name="question" placeholder={t('question')} autoComplete="off" aria-label="question" value={value.question}
                      onChange={onChange} />
                  </Form.Group>
                  <div className="d-flex ct-btn align-items-center justify-content-center mt-4 web-btns">
                    <Button className='btn btn-primary d-flex align-items-center' onClick={() => searchIngtp()} >{t('SearchInGPT')}<span className='icon-right-angle-icon d-block ms-2'></span></Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className=' pb-10'>
            {Object.keys(allItems).length  > 0 &&
             <div className='text-center mb-1'>
              <h1 className='mb-sm-0  fw-semibold text-white h2'>{t('Your search in podcast')}</h1>
              </div>
              }
              <Table bordered hover responsive >
                <tbody>
                  <>
                    {
                      allItems && Object.keys(allItems).map(template_name => {
                        if (allItems[template_name].length > 0) {
                          return (
                            <tr>
                              <td>
                                <div>{template_name}</div>
                              </td>
                              <td>
                                {
                                  allItems[template_name].map(item => {
                                    return (<span className='btn'>{Number(item.start).toFixed(2)} - {Number(item.end).toFixed(2)} </span>)
                                  })
                                }
                              </td>
                            </tr>
                          )
                        }
                      })
                    }
                  </>
                </tbody>
              </Table>

            </div>
                    
            <div className=' pb-10'>
              {allBlog.length > 0 &&
             <div className='text-center mb-1'>
              <h1 className='mb-sm-0  fw-semibold text-white h2'>{t('Your search in blog')}</h1>
              </div>
              }
              <Table bordered hover responsive >
                <tbody>
                  <>
                    {
                      allBlog && allBlog.map(item => {
                        return (
                          <tr>
                            <td>
                              <div>Blog</div>
                            </td>
                            <td>
                              {item}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </>
                </tbody>
              </Table>

            </div>
            {isSubmited && Object.keys(allItems).length === 0 && allBlog.length === 0 &&
              <p className='text-center'>{t('No_records_found')}</p>
            }
          </Container>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default ChatGtpSearch;