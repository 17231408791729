import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Navbar,
  Nav,
  Image,
} from "react-bootstrap";
import Header from "../commom/Header";
import Footer from "../commom/Footer";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  register,
  googleSignUp,
  facebookSignUp,
} from "../../store/api/auth.api";
import IntlTelInput from "react-intl-tel-input";
// import 'react-intl-tel-input/dist/main.css';
import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import {
  googlesignupEvent,
  facebookSignupEvent,
} from "../commom/facebookandgoogleevents";
import { addfcmToken } from "../../store/api/course.api";
import { useGoogleLogin } from "@react-oauth/google";

import { toast } from "react-toastify";

const SignUp = () => {
  const [value, setValue] = useState({
    firstname: "",
    lastname: "",
    code: "",
    phone: "",
    email: "",
    password: "",
    cpassword: "",
    register_type: "private",
    business_name: "",
    designation: "",
    address: "",
    bus_phone_no: "",
    city: "",
    street_name: "",
  });
  const [errors, setErrors] = useState({});
  const [showResults, setShowResults] = React.useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onChange = (e) => {
    validate(e, e.target.name, e.target.value);
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "register_type" && e.target.value === "business") {
      setShowResults(true);
    } else if (
      e.target.name === "register_type" &&
      e.target.value === "private"
    ) {
      setShowResults(false);
      document.getElementById("create-course-form").reset();
    }
  };

  const handlePhoneChange = (status, phone, country) => {
    setValue({
      ...value,
      ["phone"]: phone,
      ["code"]: country.dialCode,
    });
  };
  // on press signup button
  const onAdd = () => {
    dispatch(register(value))
      .unwrap()
      .then((res) => {
        googlesignupEvent();
        facebookSignupEvent();

        if (res.user) {
          if (
            localStorage.getItem("fromPricingIs_trial") &&
            localStorage.getItem("fromPricingId") &&
            localStorage.getItem("fromPricingPrice")
          ) {
            navigate(
              "/subsription/" +
                localStorage.getItem("fromPricingId") +
                "/" +
                localStorage.getItem("fromPricingPrice") +
                "/" +
                localStorage.getItem("fromPricingIs_trial")
            );
          } else {
          //  navigate("/my-course-details");
              navigate("/onboarding/select-type", {
                state: { email: res.user.email,password: res.user.password },
              });
          }
        }
        if (localStorage.getItem("fcmToken")) {
          let data = {
            fcm_token: localStorage.getItem("fcmToken"),
          };
          dispatch(addfcmToken(data))
            .unwrap()
            .then(() => {})
            .catch(() => {});
        }

        // navigate('/logga-in');
      })
      .catch(() => {});
  };
  const validate = (event, name, value1) => {
    //A function to validate each input values

    switch (name) {
      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value1)
        ) {
          setErrors({
            ...errors,
            email: <>{t("enter_email")}</>,
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "password":
        if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(
            value1
          )
        ) {
          setErrors({
            ...errors,
            password: <>{t("Password_should")}</>,
          });
        } else {
          let newObj = omit(errors, "password");
          setErrors(newObj);
        }
        break;
      case "cpassword":
        if (!value1) {
          setErrors({
            ...errors,
            cpassword: <>{t("Please_enter_Confirm_Password")}</>,
          });
        } else if (value.password && value1 !== value.password) {
          setErrors({
            ...errors,
            cpassword: <>{t("Password_and_Confirm_Password_does_not_match")}</>,
          });
        } else {
          let newObj = omit(errors, "cpassword");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const googlelogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      let data = {
        id_token: tokenResponse.access_token,
      };
      dispatch(googleSignUp(data))
        .unwrap()
        .then((res) => {
          googlesignupEvent();
          facebookSignupEvent();

          if (res.user) {
            if (
              localStorage.getItem("fromPricingIs_trial") &&
              localStorage.getItem("fromPricingId") &&
              localStorage.getItem("fromPricingPrice")
            ) {
              navigate(
                "/subsription/" +
                  localStorage.getItem("fromPricingId") +
                  "/" +
                  localStorage.getItem("fromPricingPrice") +
                  "/" +
                  localStorage.getItem("fromPricingIs_trial")
              );
            } else {
              navigate("/oversitk");
            }
          }
          if (localStorage.getItem("fcmToken")) {
            let data = {
              fcm_token: localStorage.getItem("fcmToken"),
            };
            dispatch(addfcmToken(data))
              .unwrap()
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
  });
  const responseFacebook = (response) => {
    let data = {
      email: response.email,
      token: response.accessToken,
      userID: response.userID,
    };
    dispatch(facebookSignUp(data))
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res.user) {
          googlesignupEvent();
          facebookSignupEvent();

          if (res.user) {
            if (
              localStorage.getItem("fromPricingIs_trial") &&
              localStorage.getItem("fromPricingId") &&
              localStorage.getItem("fromPricingPrice")
            ) {
              navigate(
                "/subsription/" +
                  localStorage.getItem("fromPricingId") +
                  "/" +
                  localStorage.getItem("fromPricingPrice") +
                  "/" +
                  localStorage.getItem("fromPricingIs_trial")
              );
            } else {
              navigate("/oversitk");
            }
          }
          if (localStorage.getItem("fcmToken")) {
            let data = {
              fcm_token: localStorage.getItem("fcmToken"),
            };
            dispatch(addfcmToken(data))
              .unwrap()
              .then(() => {})
              .catch(() => {});
          }
        } else {
          toast.error(res.message);
        }
      })
      .catch(() => {});
  };
  const gotoHome = () => {
    navigate('/');
  }
  return (
    <>
      {/* <Header />
         <div className='page-wrapper flex-grow-1 signup-page overflow-hidden'>
            <Container fluid>
               <div className='pagetitle-wrapper text-white mb-13 block wow fadeInDown'>
                  <h1 className='page-title fw-semibold h2'>{t('Create_your_account')}</h1>
                  <p style={{'paddingTop':'1px'}}>{t('Already_have_an_account')} <Link className='' to="/logga-in">&nbsp;{t('SignIn')}</Link></p>
               </div>

               <Form id="create-course-form">
                     <Form.Group className="mb-12 wow fadeInDown" controlId="formBasicEmail">
                        <Form.Label className='font-16 fw-bold text-white mb-5'>{t('Type')}</Form.Label>
                        <div className='custom-radio d-flex flex-wrap'>
                        <Form.Check type="radio" label={t('private')} name="register_type" value={'private'} id="radio2" onChange={onChange} checked={value.register_type === 'private'} />
                        <Form.Check type="radio" label={t('business')} name="register_type" value={'business'} id="radio1" onChange={onChange} checked={value.register_type === 'business'} />                           
                        </div>
                     </Form.Group>
                  <div className='row gy-10'>
                    
                    
                     
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder={t('Email')+'*'} name='email' aria-label='Email' onChange={onChange}  />
                        { errors.email && <span>{errors.email}</span> }
                     </Form.Group>
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicEmail">
                        <Form.Control type="password" placeholder={t('New_Password')+'*'} name='password' aria-label='New Password' onChange={onChange} />
                        { errors.password && <span>{errors.password}</span> }
                     </Form.Group>
                     
                     { showResults && 
                  <>
                   <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicfirstname">
                        <Form.Control type="text" placeholder={t('First_Name')+'*'} name='firstname' aria-label='First Name' onChange={onChange} />
                     </Form.Group>
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicEmail">
                        <IntlTelInput containerClassName="intl-tel-input"  preferredCountries={['se']} inputClassName="form-control col-md-6" style={{'width':'100%'}} name='phone'  onPhoneNumberChange={handlePhoneChange} />
                     </Form.Group>
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBusinessName">
                       <Form.Control type="text" placeholder={t('Business_Name')} name="business_name" aria-label="Business*" onChange={onChange}  />
                     </Form.Group>
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicAddress">
                     <Form.Control type="text" placeholder={t('Address')}  name="address" aria-label="address"  onChange={onChange} />
                     </Form.Group>
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicDesignation">
                     <Form.Control type="text" placeholder={t('PinCode')} name="street_name" aria-label="street_name" onChange={onChange}  />
                     </Form.Group>
                     <Form.Group className="col-md-6 wow fadeInDown" controlId="formBasicNumber">
                     <Form.Control type="text" placeholder={t('city')} name="city"   aria-label="city" onChange={onChange}  />
                     </Form.Group>
                     </>
                  }
                     </div>
                     <div className='mt-3 mb-3 '>
                     <Button variant="primary" className='btn btn-primary  min-w-195  new_login_signup mt-2 min-h-38' type="button" onClick={onAdd} >
                           {t('Create_your_account')}
                        </Button>
                         { !showResults && 
                  <>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <Button className='loginBtn loginBtn--google mt-2 min-h-38' onClick={() => googlelogin()}>
                           {t('Registrera_med_Google')}
                        </Button>
                      
                     </>
                     }
                  </div>
                  <p style={{'paddingTop':'1px'}}>{t('Already_have_an_account')} <Link className='' to="/logga-in">&nbsp; {t('SignIn')}</Link></p>
               </Form>
              
            </Container>
         </div>
         <Footer /> */}

      <div className="onboarding-page">
        {/* <div className="header position-fixed top-0 start-0 end-0">
          <Container fluid>
            <Navbar.Brand href="/">
              <svg
                id="Lager_2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 710.11 134.41"
              >
                <defs>
                  <style>{"fill:#fff;"}</style>
                </defs>
                <g id="Lager_1-2">
                  <g>
                    <path
                      className="cls-1"
                      d="M106.99,68.38c0,20.93-7.04,39.33-20.57,50.7-9.56,7.93-22.01,12.45-38.25,12.45H.25c-.14,0-.25-.11-.25-.25v-26.51c0-23.3,9.26-45.65,25.73-62.12h0c.16-.16,.43-.05,.43,.18V108.72c0,.14,.11,.25,.25,.25h19.96c24.53,0,34.1-14.97,34.1-40.59s-11.19-43.48-33.38-43.48H.25c-.14,0-.25-.11-.25-.25V2.78c0-.14,.11-.25,.25-.25H50.34c34.46,0,56.65,27.78,56.65,65.85Z"
                    />
                    <path
                      className="cls-1"
                      d="M117.63,67.29C117.63,28.69,141.81,0,179.88,0s62.06,28.69,62.06,67.29-23.99,67.11-62.06,67.11-62.24-28.51-62.24-67.11Zm97.6,0c0-25.8-11.55-45.83-35.18-45.83s-35.72,20.03-35.72,45.83,12.09,45.65,35.72,45.65,35.18-20.03,35.18-45.65Z"
                    />
                    <path
                      className="cls-1"
                      d="M250.59,67.48C250.59,29.23,274.4,0,312.47,0c31.75,0,50.52,18.94,53.22,43.48h-25.98c-2.35-12.99-12.09-21.47-27.24-21.47-23.81,0-35.18,19.85-35.18,45.47s13.71,45.28,35.36,45.28c15.34,0,26.16-9.2,27.78-22.73h25.62c-.72,11.37-5.59,22.37-14.25,30.49-8.84,8.3-21.29,13.71-39.15,13.71-36.26,0-62.06-27.96-62.06-66.75Z"
                    />
                    <path
                      className="cls-1"
                      d="M378.57,2.53h94.9V24.54h-68.74v28.87h60.26v21.47h-60.26v34.82h69.28v21.83h-95.44V2.53Z"
                    />
                    <path
                      className="cls-1"
                      d="M488.23,2.53h26.52l42.94,71.99c4.15,7.04,9.02,18.04,9.02,18.04h.36s-.72-13.35-.72-22.37V2.53h25.8V131.52h-25.26l-44.02-71.26c-4.15-6.86-9.2-17.86-9.2-17.86h-.36s.72,13.53,.72,22.55v66.57h-25.8V2.53Z"
                    />
                    <path
                      className="cls-1"
                      d="M604.21,91.65h25.62c1.8,15.52,10.64,21.65,29.05,21.65,13.35,0,25.08-4.69,25.08-16.6,0-12.63-12.27-15.16-31.93-19.67-23.09-5.23-44.2-11.37-44.2-38.25,0-25.44,20.75-38.61,49.43-38.61s47.81,14.25,49.79,40.23h-25.08c-1.44-12.99-11.55-19.48-24.9-19.48-14.07,0-23.27,5.95-23.27,15.15,0,10.46,9.02,13.53,28.14,17.68,26.52,5.77,48.17,12.09,48.17,39.87,0,26.16-21.11,40.77-50.15,40.77-35,0-54.85-15.52-55.75-42.76Z"
                    />
                  </g>
                </g>
              </svg>
            </Navbar.Brand>
          </Container>
        </div> */}
         <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
           
        <Container fluid>
          <div className="text-white maxw-507 mx-auto">
          <Link to="/" className="back-btnblock mb-8 d-inline-block mobile-mb-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M22.5 27L13.5 18L22.5 9" stroke="white" stroke-width="1.5"/>
              </svg>
                    </Link>
            <div className="block mb-8">
              <h1 className="font-32 mb-3 fw-semibold mobile-font-36 mobile-mb-20" onClick={gotoHome}>Registrera</h1>
              <Button
                variant="light"
                className="bg-white w-100 google-btn d-flex align-items-center justify-content-center gap-3 py-0"
                onClick={() => googlelogin()}
              >
                <Image src="/assets/images/google.svg" />
                Google
              </Button>
              <div className="or-text font-20 fw-light d-flex justify-content-between align-items-center mt-4 mobile-mt-20">
                <span>Eller</span>
              </div>
            </div>
            <Form className="mb-8 signup-form d-flex flex-column gap-5 mobile-mb-20">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="font-24 fw-medium mb-2">
                  E-post
                </Form.Label>
                <div className="form-group">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Exempelvis obama@usa.com..."
                    value={value.email}
                    onChange={onChange}
                  />
                  {/* {errors.email && <span>{errors.email}</span>} */}
                </div>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="font-24 fw-medium mb-2">
                  Lösenord
                </Form.Label>
                <div className="form-group">
                  <Form.Control
                    type={passwordType}
                    name="password"
                    placeholder="Tänk på något unikt..."
                    onChange={onChange}
                  />
                  <Button
                    variant="light"
                    type="button"
                    className="nohover"
                    onClick={togglePassword}
                  >
                    {" "}
                    {passwordType === "password" ? "Visa" : "Hide"}
                  </Button>
                </div>
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-between mb-8 mobile-mb-20 align-items-md-start align-items-end">
              <Button variant="primary" className="" onClick={onAdd}>
                Registrera
              </Button>
             
            </div>
            <p className="fw-light font-20 mb-8 mobile-font-13 mobile-mb-20">
              {t("Already_have_an_account")}{" "}
              <Link
                to="/logga-in"
                className="fw-semibold text-white btnlink-hover"
              >
                {t("SignIn")}
              </Link>
            </p>
            <p className="fw-light font-16 mb-0 mobile-font-10">
              Genom att registrera dig godkänner du våra{" "}
              <Link
                to="/anvandarvilkor"
                className="text-white font-16 text-decoration-underline fw-light btnlink-hover"
              >
                användarvilkor
              </Link>{" "}
              och{" "}
              <Link
                className="text-white font-16 text-decoration-underline fw-light btnlink-hover"
                to="/integritetspolicy"
              >
                integritetspolicy.
              </Link>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SignUp;
