import React, { useState, useRef, useEffect } from "react";

import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import Footer from "../commom/Footer";
import Accordion_Custome from "../commom/Accordion";
import { getSinglePodcast } from "../../store/api/podcast.api";

import { Row, Col, Container, Modal, Button, Form, Pagination } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Player from "@vimeo/player";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ck_config } from "../commom/commonConst";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import Wrapper from "../component/Wrapper";

const OpenPodcastDetails = (prop) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { podcastid } = useParams();
  const [allItems, setAllItems] = useState({});
  const [alllength, setAlllength] = useState(0);
  const { t } = useTranslation();
  const [mainVideoURL, setMainVideoURL] = useState(null);
  const [mainTitle, setMainTitle] = useState(null);
  const [course_sub_title, setCourse_sub_title] = useState(null);
  const [mainDiscription, setMainDiscription] = useState("");
  const [mediaLenth, setMediaLenth] = useState("0:00");
  const { id, role_id, business_id, email } = useSelector(
    (state) => state?.auth?.user || {}
  );


  const [selectedTab, setSelectedTab] = useState("Avsnitt");
  const [isReadMore, setIsReadMore] = useState(true);
  useEffect(() => {
    getAllData();
  }, [podcastid]);

  const onSetEdit = (item, index) => {
    setEditId(item.media_id);
    setEditName(mainTitle);
    setEditCourseId(item.course_id);
    setShowEdit(true);
  };

  const closeAdd = () => {
    setShowAdd(false);
    getAllData();
  };
  const getAllData = async () => {
    let req = {
      podcast_id: podcastid,
    };
    if (id) {
      req.user_id = id;
      req.role_id = role_id;
    }
    dispatch(getSinglePodcast(req)).then(async (podcast) => {
      setAllItems(podcast.payload.data);
      setAlllength(podcast.payload.data?.media_details.length);
      setMainVideoURL(podcast.payload.data?.media_details[1]?.video_url);
      setMediaLenth(podcast.payload.data?.media_details[1]?.length);
      localStorage.setItem(
        "mediaIdOnLeftCom",
        podcast.payload.data?.media_details[0]?.id
      );
      setMainTitle(podcast.payload.data?.title);
      setCourse_sub_title(podcast.payload.data?.name_of_guest);

      setMainDiscription(
        podcast.payload.data?.description != "null"
          ? podcast.payload.data?.description
          : ""
      );
    });
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit && isReadMore) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };

  const setTab = (tab) => {
    setSelectedTab(tab);
  };





  const onSetURL = (item, index) => {
    setMainVideoURL(item?.video_url);
    setMainTitle(item?.title);
    setMediaLenth(item.length)
  };

  const onSetURLFromButton = (item,index) => {
    
 
    setMainVideoURL(item?.video_url);
    setMainTitle(item?.title);
    setMediaLenth(item?.length)
  }



  return (
    <div className="innerblock-page">
      {role_id && <Header headerShow={false} />}
      {!role_id && <Header1 />}
      <div
        className={`page-wrapper page-block flex-grow-1 overflow-hidden header-border-none  mobile-pb-0 ${!role_id ? "is-not-login pt-0" : "pt-100"
          }`}
      >
        <InnerHeader title={t("Podcast")} subtitle={t("Experterna")} backButton={true} backButtonURL={'/my-podcast-details'} />
        <Wrapper>
          <section className={`mb-md-80px`}>
            <Row className="gy-md-3 flex-md-row gap-md-0">
              <Col md={6}>
                <div className="videoblock-wrap">
                  <iframe
                    id="videoIframe"
                    className="videoblock rounded-20 w-100 video-opencourse"
                    src={mainVideoURL}
                    width={400}
                    title="video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="p-md-10px shadow-block rounded-20 d-flex gap-5 mt-20px bg-white align-items-center">
                  <div className="d-flex text-svart align-items-center fs-20 fw-medium bg-grey-block px-5 py-18px rounded-20 flex-grow-1 title-mobile-box">
                    
                    <span className="flex-grow-1 text-center">{mainTitle}</span>
                    
                  </div>

                </div>
              </Col>
              <Col md={6}>
                <div className="shadow-block bg-white rounded-20 box-mobileblock">
                  <div className="p-20px title-header d-md-block d-none">
                    <TextBlock title={allItems?.title} subtitle={mediaLenth} />
                  </div>
                  <div className="p-20px  border-none">
                    <div className="d-md-none d-flex gap-16px mb-10px">
                      <a role="button" className={`card-link a-hover ${selectedTab === 'Avsnitt' ? "active" : ""}`} onClick={() => setTab('Avsnitt')}>Avsnitt</a>

                      <a role="button" className={`card-link a-hover ${selectedTab === 'Om_kursen' ? "active" : ""}`} onClick={() => setTab('Om_kursen')}>Om avsnittet</a>
                    </div>
                    <div className={`tabblock-wrap ${selectedTab === 'Avsnitt' ? "active" : ""}`}>
                      <div className="d-flex flex-column gap-10px">
                        {allItems.media_details &&
                          allItems?.media_details?.slice(1)?.map((item, index) => (
                            <div className="text-svart teacherinfo-box  wow fadeInDown d-md-block">
                              <div className="full-width course-listing-table font-20 course-text-right mobile-font-13">
                                <div className=" d-flex justify-content-between link-hover" onClick={() => onSetURL(item, index)}>
                                  <p className="mb-0">{add3Dots(item.title, 50)}</p>
                                  <div className="text-right ps-12 ps-xl-2">{item.length} min</div>
                                </div>
                              </div>
                            </div>
                          ))}

                      </div>
                    </div>
                    <div  className={`tabblock-wrap  d-md-none ${selectedTab === 'Om_kursen' ? "active" : ""}`}>
                      <div className="bg-grey-block rounded-20">
                        <div className="p-20px title-header">

                          <TextBlock title={mainTitle} subtitle={course_sub_title} />

                        </div>
                        <div>
                          <div className="titleheader-box">
                            <div className="title-header p-20px">
                              <div className="fs-20 fw-light text-black" dangerouslySetInnerHTML={{
                                __html: mainDiscription,
                              }}>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Col>

            </Row>
          </section>

          <section className="d-md-block d-none">
            <div className="shadow-block bg-white rounded-20">
              <div className="p-20px title-header">
                <TextBlock title={allItems?.title} subtitle={course_sub_title} />
              </div>
              <div>
                <div className="titleheader-box">
                  <div className="title-header p-20px">
                    <div className="fs-20 fw-light text-black" dangerouslySetInnerHTML={{
                      __html: mainDiscription,
                    }}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Wrapper>
      </div>
    </div>
  );
};
export default OpenPodcastDetails;
