import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Header from "../commom/Header2";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import PrivateEducationList from '../commom/PrivateEducationList'
import Wrapper from "../component/Wrapper";
const PrivateEducations = () => {
  return (
    <div
      className={` innerblock-page d-lg-flex flex-grow-1`}
    >
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block">
        <InnerHeader title={"Utbildningar"} subtitle="Vidareutbilda dig hemifrån på kvällstid" />
        <Wrapper className={'pb-40px'}>
          <div className="shadow-block rounded-20 bg-white mb-lg-80px mb-20px">
            <div className="p-lg-20px p-16px title-header">
              <TextBlock title={"Utbildning på kvällstid"} subtitle={"Kombinera jobb med studier"} />
            </div>
            <div className="p-lg-20px p-16px">
              <Row className="flex-md-row flex-column-reverse">
                <Col md={6}>
                  <div className="text-svart fw-light font-20 mobile-font-13">
                    
                    <p > Att kunna gå en utbildning ledd av kunniga universitetslärare digitalt på kvällstid erbjuder en möjlighet för dig som vill fortsätta arbeta och vidareutbilda dig samtidigt. Det kan även vara en lösning för dig som har familjeansvar, eller andra åtaganden som gör det svårt att delta i traditionella dagtidskurser.</p>
                    <p>För företag ger det en möjlighet att ge medarbetarna en chans till vidareutbildning utan att det påverkar det dagliga arbetet.
                      </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="ps-md-10 ps-0 mb-md-0 mb-16px">
                    <Image
                      src="./assets/images/designicanva.png"
                      alt=""
                      className="rounded-20 w-100"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="shadow-block rounded-20 bg-white overflow-hidden ">
            <div className="p-lg-20px p-16px title-header">
              <TextBlock title={"Våra utbildningar"} subtitle={"Utbud"} />
            </div>
            <PrivateEducationList />
          </div>

        </Wrapper>
      </div>
    </div>
  )
}

export default PrivateEducations;