import React, { useState, useEffect } from 'react';
import Header from '../commom/Header2';
import { Container, Image, Accordion } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { newslatterList, newslatter_downlaod } from '../../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
const DownloadNewslatter = () => {
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "is_deleted": 0
    }
  }
  const [allnewsLatters, setAllnewsLatters] = useState();
  const { id } = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  useEffect(() => {
    refresh();
  }, []);
  const refresh = () => {
    dispatch(newslatterList(data)).then((res) => {
      setAllnewsLatters(res.payload.data);
    })
  }
  const onDownload = (item) => {
    let data = {
      'user_id': id,
      'news_id': item.id
    }
    dispatch(newslatter_downlaod(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          window.open(item.url, 'Download');

        }
      })
      .catch((e) => {
        console.log(e)
      });
  }
  return (
    <>
      <Header />

      <div className='flex-grow-1 overflow-hidden pt-100 ml-in-web'>
       
        {/* Starts content with banner section */}
        <section className="cnt-with-image">
          {
            allnewsLatters && allnewsLatters.length >0 && allnewsLatters?.slice(0, 3)?.map((item, index) => (
              <div key={index} className={`content-image-box banner${index + 1}`}>
                <div className='book-images'>
                  {/* <Image  src={`/assets/images/book${index+1}.png`} width={725} height={517} alt="book"/> */}
                  <Image src={item?.image} width={725} height={517} alt="book" />
                </div>
                <Container fluid>
                  <div className='cnt-content'>
                    <h2 className="fw-normal">{item?.name} </h2>

                    <h4 className=" fw-bold mt-1 mt-md-5">{item?.sub_title !== 'null' ? item?.sub_title : ''}  </h4>
                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                    <div className="d-flex mt-3 align-items-start justify-content-start cnts-btn">
                      <a onClick={() => onDownload(item)} className={`btn flex-shrink-0  d-inline-flex align-items-center  ${index === 1? 'btn-outline-secondary' : 'btn btn-outline-light a-hover'}`}>{t('download')} <span className='icon-right-angle-icon d-block ms-2'></span></a>
                    </div>
                  </div>
                </Container>
              </div>
            ))}
        </section>
        {/* Starts content with banner section */}
        <section className='hv-100 gray-background pt-10 pb-10'>
          <Container fluid>
            <Accordion defaultActiveKey="0" className='ns-accordion'>
              {
                allnewsLatters && allnewsLatters.length >0 && allnewsLatters?.map((item, index) => (
                  <Accordion.Item key={index} eventKey={`${index}`}>
                    <Accordion.Header className='d-flex align-items-center'><span className='d-block me-4 fw-bold'> {item.version}</span><h4 className='fw-normal mb-0'>{item?.creator_name} | <strong>{item?.name}</strong></h4></Accordion.Header>
                    <Accordion.Body>
                      <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                      <div className="d-flex mt-3 align-items-start justify-content-start">
                        <a onClick={() => onDownload(item)} className='btn btn-primary flex-shrink-0  d-inline-flex align-items-center a-hover'>{t('download')} <span className='icon-right-angle-icon d-block ms-2'></span></a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </Container>
        </section>
      </div>
    </>
  )
}

export default DownloadNewslatter;