import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "sw",
    // we init with resources
    resources: {
      sw: {
        translations: {
          About_us: "Om oss",
          Planner: "Planner",
          Our_courses: "Våra kurser",
          SignIn: "Logga in",
          Course_to_Get_Start: "Våra kurser",
          Popular_Topic: "Populära ämnen",
          Let_IT_educator_Mikael_Boustedt_teach_you_all_about_how_you_can_reduce_job_stress_by_using_Microsoft_Planner:
            "Let IT educator Mikael Boustedt teach you all about how you can reduce job stress by using Microsoft Planner",
          More_About_The_Courses: "Mer om kursen",
          Testimonial: "Recensioner",
          What_Our_Customer_Says_about_Us: "Recensioner",
          Contact_Us: "Kontakta oss",
          phone: "Telefon",
          Name: "Namn",
          Submit: "Skicka",
          Message: "Meddelande",
          Email: "Email",
          Add_New_Question: "Lägg till ny fråga",
          Question: "Fråga",
          Enter_answer_1: "Svar 1",
          Enter_answer_2: "Svar 2",
          Enter_answer_3: "Svar 3",
          Enter_answer_4: "Svar 4",
          Enter_correct_answer: "Enter correct answer",
          Save: "Spara",
          Edit_Test: "Redigera prov",
          Add_New_Test: "Lägg till nytt prov",
          Select_Course: "Välj kurs",
          select: "Välj",
          Update: "Uppdatera",
          My_Profile: "Min profil",
          Logout: "Logga ut",
          Dashboard: "Översikt",
          My_Course: "Kurser",
          Product: "Produkt",
          Creator_Teacher: "Kreatör / Lärare",
          Users_List: "Deltagare",
          Create_Test_paper: "Create Test paper",
          Send_Notification: "Skicka notis",
          ANKI: "Anki",
          My_orders: "Mina köp",
          My_note: "Mina reflektioner",
          My_tests: "Mina prov",
          Settings: "Inställningar",
          Edit_Employee: "Redigera",
          Add_Employee: "Lägg till",
          First_Name: "Namn",
          Last_Name: "Efternamn",
          Assign_Course: "Tilldela kurs",
          Email_Address: "Epost",
          Card_Holder: "Namn",
          Subtotal: "Subtotal",
          Pay: "Betala",
          Cancle: "Avbryt",
          Ok: "Ok",
          Confirm_delete: "Godkänn ta bort",
          Edit_Note: "Redigera",
          Designation: "Beteckning",
          Address: "Postadress",
          Task_and_Certification: "Task and Certification",
          Edit_Teacher: "Redigera",
          Add_New_Teacher: "Lägg till en",
          Select_Image: "Välj bild",
          Title: "Rubrik",
          Video_Length: "Video längd",
          Thumbh_Video: "Omslagsbild",
          Video_URL: "Video länk",
          Edit_Product: "Redigera",
          Add_New_Product: "Lägg till produkt",
          Item_in_your_Cart: "Varukorg",
          Enter_payment_detail: "Enter payment detail",
          Create_new_password: "Skapa nytt lösenord",
          Your_new_password_must_be_diffrent_from_previous_used_passwords:
            "Your new password must be diffrent from previous used passwords",
          Old_Password: "Nuvarande lösenord",
          New_Password: "Lösenord",
          Must_be_at_least_8_charachter: "Måste vara minst 8 bokstäver",
          Confirm_Password: "Ange lösenord igen",
          Confirm_New_Password: "Ange lösenord igen",
          Reset_password: "Återställ lösenord",
          Congratulations_you_won_certificate:
            "Grattis! Du klarade utbildningen",
          Cretor_List: "Kreatör lista",
          Add_New: "Lägg till",
          Images: "Bilder",
          Profile_Images: "Profilbild",
          Creator_name: "Kreatör namn",
          About: "Om",
          Date: "Datum",
          Action: "Redigera",
          No_records_found: "Tomt",
          Forget: "Glömt lösenord?",
          Send: "Skicka",
          Our_Clients: "Tidigare kunder",
          Dont_have_an_account: `Inget konto?`,
          Sign_up: "Sign up",
          My_Cources_List: "Våra kurser",
          Create_Courses: "Skapa kurs",
          Attachments_Link_Pdf: "Kursmaterial",
          Regular_Price: "Pris",
          Special_Price: "Rabatterat pris",
          My_Order: "Mina köp",
          Purches_Details: "Köpdata",
          Price: "Pris",
          You_sure_you_wanna_delete: "Vill du radera?",
          My_Notes: "Mina reflektioner",
          Course: "Kurs",
          Note: "Anteckningar",
          Created_Date: "Datum",
          On_Episode: "Avsnitt",
          Buy_Now: "Köp kurs",
          Book_me_Now: "Boka mig live",
          Do_you_want_to_add_note_on_this_chepter:
            "vad tar du med dig ifrån det här avsnittet?",
          Submit_Now: "Skicka",
          Start_a_test: "Starta test",
          View_Attachments: "kurslitteratur",
          Play_List: "Spellista",
          Show_Answer: "Visa svar",
          Correct: "Rätt",
          In_Correct: "Fel",
          Next: "Nästa",
          Course_Name: "Kursnamn",
          Media_Name: "Media namn",
          Email_or_password_was_wrong: "Email eller lösenord var fel.",
          Enter_a_valid_email_address: "Ange email",
          Password_should: "Lösenord bör bestå av minst 8 tecken.",
          Select_test: "Välj test",
          Start: "start",
          Your_name: "Ditt namn",
          Submit_Exam: "Skicka",
          Notification_Sent: "Notis skickad",
          Fail_to_send_notification: "Misslyckades att skicka notis.",
          Password: "Lösenord",
          Enter_Answer: "Svar",
          Contact_details: "Ta bort telefonnummer under kontaktinformation",
          Information: "Villkor",
          Terms_of_Use: "Användarvilkor",
          Privacy_Policy: "GDPR",
          Social_Media: "Social Media",
          Anki_Questions_List: "Anki frågor lista",
          Welcome_to_Admin: "Välkommen",
          Course_You_Buy: "Mina kurser",
          Total_User: "Antal användare",
          Completed_Course: "Färdiga kurser",
          Started_Course: "Påbörjade kurser",
          Total_Goal_And_Scoring: "Summering ",
          Top_5_User: "Top 5 användare",
          Top_5_Lasy: "Sista 5 användare",
          My_Certificates: "Mina certifikat",
          Exam_Name: "Exam namn",
          Please_enter_Confirm_Password: "Ange lösenord igen",
          Password_and_Confirm_Password_does_not_match:
            "Lösenordet matchar inte",
          Reset: "Återställ",
          Question_List: "Frågorlista",
          Correct_Ans: "Correct Ans",
          Test_List: "Test List",
          User_List: "User List",
          Course_you_buy: "Mina kurser",
          Notifications: "Notiser",
          All: "Alla kurser",
          Course_you_Assigned: "Mina kurser",
          Test_Name: "Test namn",
          Product_List: "Produktlista",
          Thanks_for_buying: "Tack för ditt köp!",
          Create_Purchaed: "Course has been purchased",
          Knowledge_through_edutainment:
            "Kompetensutveckling som den borde vara.",
          Knowledge_through_mobile_1: "Kompetensutveckling",
          Knowledge_through_mobile_2: "som den borde vara.",
          Utbildande_Underhållande_Inspirerande:
            "Utbildande. Underhållande. Inspirerande.",
          Docens_är_det_mest_intuitiva_sättet_att:
            "Docens är det mest intuitiva sättet att",
          utbilda_medarbetare: "utbilda medarbetare",
          Experterna_i_sin_rätta_miljö: "Experterna i sin rätta miljö",
          lat_väl_valda_experter_utbilda_ert_företag: "Låt väl valda experter",
          inom_ämnen_som_gör_skillnad:
            "Kompetensutveckling, viktigare än någonsin.",
          Ledarskap_Jurdik_Mentorskap_Skatterätt:
            "Allt fler inser vikten av att investera i sig själv. Den kunskap du samlar på dig under livets gång kan ingen ta ifrån dig.",
          Att_utbilda_personal:
            "Samhället och tekniken förändras och för att hänga med krävs att vi kontinuerligt uppdaterar oss. Ett livslångt lärande är inte bara viktigt för framgång inom karriären, utan även för personlig utveckling och välmående i livet.",
          Se_kurser: "Se kurser",
          En_utbildningsplattform: "En utbildningsplattform",
          oändliga_möjligheter: "Oändliga möjligheter",
          Docens_ett_enkelt_sätt_att_utbilda_medarbetare:
            "Docens - ett enkelt sätt att utbilda medarbetare",
          Väl_valda_experter_lär_ut_enligt:
            "Väl valda experter lär ut enligt Docens unika utbildningsmall, framtagen speciellt för att maximera inlärning. Plattformen gör det enkelt för dig att tilldela dina anställda relevanta kurser och att följa deras framsteg.",
          Docens_experter_och_produktionsteam:
            "Docens experter och produktionsteam kan även hjälpa er att utforma specialanpassade utbildningsfilmer till ert bolag. Vår plattform gör det enkelt för er att hantera och administrera era kurser.",
          Kurser_med_kända_expert_inom_omrädet:
            "Kurser med kända expert inom omrädet",
          Kurser_interna: "Kurser interna",
          Verktyg: "Verktyg",
          Hur_det_funkar: "Hur det funkar",
          utvalda_kurser: "Utvalda kurser",
          Se_fler_kurser: "Se fler kurser",
          Börja_redan_idag: "Börja redan idag!",
          Höj_kunskapsnivän_i_ert_företag: "Höj kunskapsnivån i ert företag",
          Med_koden_fär_du_pä_varlfri_kurs_innan_den_23_oktober:
            'Med koden "Docens1022" får du 10% på valfri kurs året ut',
          på_valfri_kurs_innan: "på valfri kurs innan",
          Vi_hjälper_er_skapa_en_skräddarsydd: "Vi hjälper er att skapa",
          intern_företagsutbildning: "intern",
          företagsutbildning: "företagsutbildning",
          Tillsammans_skapar_vi_ert_utbildningsmaterial_av_högsta_klass:
            "Tillsammans skapar vi ert utbildningsmaterial av högsta klass.",
          Vi_hjälper_till_med_hela_processen:
            "Vi hjälper till med hela processen, från planering till färdigt videomaterial. Gör er onboarding enkel och spännande eller öka engagemanget på säkerhetsgenomgångar.",
          Vi_hjälper_till_med: "Vi hjälper er med",
          Planering_av_kursupplägg: "Planering av kursupplägg",
          Kommunikation_och_språk: "Kommunikation och språk",
          Filmning_regisering_och_redigering:
            "Filmning, regissering och redigering",
          Produktbild: "Produktbild",
          För_mer_information_kontakta_oss: "För mer information kontakta oss",
          Coupen_is_copied_to_clipboard: "Du har kopierat koden",
          Do_You_have_Coupen: "Rabattkod",
          Apply_coupen: "Använd",
          utbilda_ert_företag: "utbilda dig!",
          Skriv_in_din_email_så_kontaktar_vi_dig:
            "Skriv in din email så kontaktar vi dig",
          Bestäm_dina_anställdas_kursplan: "Bestäm dina anställdas kursplan",
          En_ansvarig_administratör_kan:
            "En ansvarig administratör kan enkelt tilldela alla anställda utvalda kurser. Administratören kan även sätta ett slutdatum då kursen ska vara klar samt ha koll så att alla som tilldelats kursen har slutfört. Kontrollfrågor i slutet av varje kurs säkerställer att kursdeltagaren har fått med sig viktig kunskap i ämnet.",
          Utbilda_i_egen_takt: "Utbilda i egen takt",
          En_digital_utbildning_tillåter:
            "En digital utbildning tillåter varje deltagare att lära i sin egen takt. Du bestämmer slutdatum då alla anställda skall vara färdiga, de kan sedan se varje avsnitt när det passar dem. Ansvarig administratör kan enkelt följa alla deltagare och se hur de ligger till, berömma den som slutfört eller påminna om någon halkat efter.",
          Diplom_efter_slutförd_kurs: "Diplom efter slutförd kurs",
          Efter_varje_slutförd_kurs:
            "Efter varje slutförd kurs finns möjlighet för dina anställda att få ut ett fint Diplom. Det som krävs för att få ut detta är att de har sett alla avsnitt i kursen och svarat rätt på de kontrollfrågor som tillhör - allt för att säkerställa att personen i fråga har fått med sig relevant kunskap.",
          Tid_för_Reflektion: "Tid för Reflektion",
          Reflektion_är_en:
            "Reflektion är en del av lärandeprocessen. Genom att stanna upp efter varje avsnitt och tänka efter ökar deltagarens möjlighet till inlärning.",
          Vad_tar_jag_med: "Vad tar jag med mig från detta avsnitt?",
          Vad_var_nytt: "Vad var nytt, vad visste jag sedan innan?",
          Vad_kan_hjälpa: "Vad kan hjälpa mig att utvecklas i min yrkesroll?",
          Vårt_verktyg_för_anteckningar:
            "Vårt verktyg för anteckningar gör det möjligt att reflektera och skriva ner det som deltagaren vill ha med sig från respektive avsnitt. Anteckningarna sparas sedan under respektive kurs och det är enkelt gå tillbaka och läsa igenom dem i efterhand.",
          En_licens_per_anställd: "En licens per anställd",
          Alla_Docens_kurser_säljs:
            "Alla Docens kurser säljs per licens. Du kan enkelt tilldela varje anställd en licens och följa dennes utveckling genom kursen. Varje licens är personlig och kan inte överlåtas till någon annan. Genom att låta alla dina medarbetare få möjlighet till kompetensutveckling får du inte bara mer motiverade och nöjda medarbetare, utan även än mer prisvärda kurser.",
          Prislista: "Prislista",
          Vid_köp_av_10: "Vid köp av 10-25 licenser avgår 10 % på angivet pris",
          Vid_köp_av_50: "Vid köp av 26-50 licenser avgår 20% på angivet pris",
          Vid_köp_av: "Vid köp av 51-99 licenser avgår 40% på angivet pris",
          "100_licenser": "100 licenser = kontakta oss",
          Möjlighet_till_att_boka:
            "Möjlighet till att boka föreläsningar på plats",
          Vårt_samarbete_med:
            "Vårt samarbete med talarförmedlingen Booky gör det enkelt för Dig att boka våra kurshållare även live. För maximal inlärning och inspiration, låt dina anställda först gå kursen och boka sedan in kurshållaren till ert event eller kick off.",
          Hosting_av_kurs:
            "Hosting av kurs och tillgång till administratörsfunktioner",
          Bild_hemsida_App: "Bild hemsida/App",
          Gå_din_kurs_på_alla_enheter: "Gå din kurs på alla enheter",
          I_webben_På: "I webben. På mobilen",
          Var_du_än_är: "Var du än är.",
          book_me_now_url: "Book me now",
          buy_my_book_url: "Buy my book",
          en_skräddarsydd: "en skräddarsydd",
          My_Book: "Böcker",
          Köp_igen: "Köp fler licenser",
          Skapa_konto_här: "Skapa konto här",
          Du_har_inte_köpt_den_här_kursen: "Du har inte köpt den här kursen",
          enter_email: "Ange email",
          Create_your_account: "Skapa konto",
          Already_have_an_account: "Har du redan ett konto?",
          Type: "Typ av konto",
          business: "Företag",
          private: "Privat",
          Business_Name: "Företagsnamn",
          Business_Mobile_Number: "Företag telefon",
          select_course: "Välj Kurs",
          select_creator: "Välj deltagare",
          Set_Deadline: "Välj deadline",
          Ange_din_email: "Ange din e-mail så kontaktar vi dig.",
          My_courses_Questions_and_answers: "Integritetspolicy",
          Det_är_inte_alltid_vi_finner:
            "Det är inte alltid vi finner ro att gå en kurs på dagtid framför datorn. Kanske vill du passa på att se ett avsnitt i sängen, på bussen eller flyget. Vår app ger dig möjlighet att på ett lättillgängligt sätt komma åt din kurs var du än befinner dig.",
          nytt_lösenord: "Nytt lösenord",
          Confirm_new_Password_2: "Ange nytt lösenord igen",
          quantity: "Antal licenser",
          unassigned_course: "Använda licenser",
          assigned_course: "Oanvända license",
          visa_mer: "Visa mer",
          We_are_accepting_predefined_format_Please_download_sample_file:
            "Ladda ner",
          Select_File: "Välj fil",
          Import_Employees: "Lägg till flera",
          mall: "mall",
          Course_Id: "Kurs Id",
          Din_reflektion_har:
            'Din reflektion har sparats i "Reflektioner"',
          kontaktinfo: "kontaktinfo",
          vära_värderingar: "vära värderingar -En företagspresentation",
          ladda_hem_diplom: "ladda hem diplom",
          Dela_på_Linkedin: "Dela på Linkedin",
          On: "On",
          Off: "Off",
          Fortsätt: "Fortsätt",
          I_agree_to_the: "Jag godkänner",
          och: "och",
          getting_marketing_emails: "Ja, jag vill få nyhetsbrev ifrån Docens",
          Accept_Invitation: "Accept Invitation",
          Course_Order: "Course Order",
          Ordering: "Ordning",
          subscription: "Prenumeration",
          PinCode: "Postnummer",
          city: "Stad",
          Paborja_proveperiod: "Påbörja provperiod",
          Prova_i_14_dagar_kostnadsfritt_Avbryt_när_du_vill:
            "Prova i 7 dagar kostnadsfritt. Avbryt när du vill.",
          Prova_i_14_dagar_kostnadsfritt: "Prova i 7 dagar kostnadsfritt",
          Prova_Docens_i_14_dagar_kostnadsfritt_Avbryt_när_du_vill_Därefter_249_kr_i_mânaden:
            "Prova Docens i 7 dagar kostnadsfritt. Avbryt när du vill. Därefter",
          i_mânaden: "i mânaden",
          uppdatera_prenumeration: "uppdatera prenumeration",
          Prenumerera: "Prenumerera",
          You_sure_you_cancle_subscription:
            "Är du säker på att du vill avbryta din prenumeration?",
          is_free: "Gör avsnitt gratis",
          Please_register_or_login_to_see_free_courses:
            "Skapa konto för att se det här avsnittet",
          För_företag_med_mer_än_50_licenser:
            "För företag med mer än 50 licenser",
          Årsprenumeration: "Årsprenumeration",
          Tags: "Taggar",
          Mina_licenser: "Mina licenser",
          Vi_kommer_att_kontakta:
            "Vi kommer att kontakta er inom 24h för att ta fram ett kostnadsfritt skräddarsytt upplägg",
          Prenumerera_på_samtliga:
            "Prenumerera på samtliga av Docens kurser från 249 kr/månad",
          Köp_singelkurs: "Köp singelkurs",
          Köp_enstaka_kurs_till_ett:
            "Köp enstaka kurs till ett fast pris eller få tillgång till alla kurser för 249kr / månaden.",
          Klicka_här_och_få_tillgång_till_alla_kurser_kostnadsfritt_i_14_dagar:
            "Testa alla kurser gratis i 30 dagar!",
          Prova_Docens_i_14_dagar_kostnadsfritt:
            "Prova Docens i 7 dagar kostnadsfritt",
          Eller_köp_enbart_denna_kurs_för: "Eller köp enbart denna kurs för",
          User_type: "Användartyp",
          Progress: "progress",
          Check_Watch: "Aktivitet",
          Number_Of_Total_User: "Antal användare totalt",
          Number_of_Private_User: 'Antal av "privat" användare',
          Number_of_Business_User: 'Antal av "företags" användare',
          Number_of_Employee_User: 'Antal av "anställda" användare',
          Number_of_Admin_User: 'Antal av "admin" användare',
          number_of_business_subcription: 'Antal av "företags" prenumerationer',
          number_of_private_subcription: 'Antal av "privat" prenumerationer',
          number_of_trial_subcription: "Antal provperioder aktiva",
          Ny_kurs_ute: "Ny kurs ute",
          varannan_vecka: "varannan vecka!",
          Häng_med: "Häng med!",
          Ta_del_av_ny_kunskap_och:
            "Ta del av ny kunskap och inspiration var 14:e dag. Under vintern/våren -23 ser vi fram emot kurser inom varierande ämnen som bl.a. mötesteknik, friskvård och hur du lär dig mer och bättre.",
          Har_du_önskemål_om_ett:
            "Har du önskemål om ett ämne du skulle vilja veta mer om, eller en person du skulle vilja gå en kurs för?  Tipsa oss!",
          Demand_List: "Demand List",
          Certificate_Text: "Certificate Text",
          trial_end: "Trial end",
          "E-post": "E-post",
          Set_Qantity: "Set_Qantity",
          Set_Primium_Quantity: "Set primium quantity",
          Sign_In_with_Google: "Google",
          Testa_kursen_gratis: "Testa kursen gratis i 30 dagar!",
          Logga_in_med_Google: "Logga in med Google",
          Registrera_med_Google: "Registrera med Google",
          Last_3_Months: "Senaste 3 månader",
          Last_6_Months: "Senaste 6 månader",
          Last_9_Months: "Senaste 9 månader",
          Analysis: "Analysis",
          watched_by_number_of_user: "Tittad på av antal användare",
          overallProgress: "Populäritet",
          Number_of_media_in_course: "Antal av avsnitt i kurs",
          Genomfört: "Genomfört",
          Populäritet: "Populäritet",
          Search: "Sök",
          is_podcast: "Det här är en podcast",
          sub_title: "Underrubrik (visas bara för top 3)",
          ladda_ned: "Ladda ner",
          newsletter_list: "Nyhetsbrev",
          manage_newslatter: "Manage Newslatters",
          download_newslatter: "Ladda ner nyhetsbrev",
          download: "Ladda ner",
          My_newslatters_List: "Nyhetsbrev",
          Prenumerera_pa: "Prenumerera på",
          Kompetensutveckling_direkt:
            "Missa inte senaste kunskapen inom svenskt näringsliv! - Nytt brev varje vecka.",
          Jag_Godkanner: "Jag godkänner att Docens hanterar mina uppgifter",
          Prenumerera_pa_Competensbrevet: "Prenumerera på kompetensbrevet",
          Sverigs_mest: "Sveriges mest",
          Utbuildande_nyhestbrev: "Utbildande nyhetsbrev",
          Las_mer: "Läs mer",
          Kompatensbrevet: "Kompetensbrevet",
          Change_Order: "Ändra ordning",
          number_of_email_subscriber: "Antal e-post prenumeranter",
          Subscription_date: "Datum",
          Create_Newslatter: "Ladda upp nyhetsbrev",
          Edit_NewsLatter: "Redigera nyhetsbrev ",
          Logga_in: "Logga in.",
          Som_inloggad_på_ditt_Docens_konto:
            "Som inloggad på ditt Docens-konto har du tillgång till alla våra freebies när du vill.",
          Inget_konto: "Inget konto?",
          Det_är_helt_kostnadsfritt:
            " Det är helt kostnadsfritt att skapa konto och du kan avsluta ditt konto när du vill, ingen bindningstid.",
          Beskrivning: "Beskrivning",
          Nedladdningar: "Nedladdningar",
          Titel: "Titel",
          Lärare: "Lärare",
          Välj_omslag: "Välj omslag",
          Välj_fil: "Välj fil",
          Ladda_ner: "Ladda ner",
          Vill_du_publicera_den_här_kursen: "Vill du publicera den här kursen?",
          Vill_du_unpublicera_den_här_kursen:
            "Vill du avpublicera den här kursen?",
          Is_single: "Enstaka kurs",
          Number_of_user: "Antal användare",
          Single_total: "Singelköp",
          Subscription_total: "Prenumeration",
          Total_earning: "Summa",
          Rabattkoder: "Rabattkoder",
          Apply_a_valid_coupon: "enter a valid coupon code",
          blog_list: "Bloggar",
          Edit_Blog: "Edit_Blog",
          Add_Blog: "Lägg till ny blogg",
          Sub_title: "Lärare",
          Blogs: "Artiklar",
          redigera_artiklar: "Redigera Artiklar",
          cover_text: "Beskrivning",
          version: "Vecka",
          Se_podcast: "Se podcast",
          Tag_List: "Tag List",
          question: "Enter Question",
          SearchInGPT: "Search In GPT",
          Search_in_platform: "Want to search In Platform?",
          Search_in_easy: "Chat GPT make searching easy",
          looking_something: "looking something?",
          Included_in_package: "Ingår i paketet",
          Who_suitable_for: "Den här kursen är för dig som",
          Content: "Innehåll",
          Description: "Beskrivning",
          Vill_du_publicera_den_här_paketet:
            "Vill du publicera den här paketet?",
          Vill_du_unpublicera_den_här_paketet:
            "Vill du avpublicera den här paketet?",
          Create_Packages: "Skapa paket",
          Subtitle: "Texta",
          mina_Paket: "Mina paket",
          Package_Name: "Paketnamn",
          Included_in_course: "Ingår i kursen",
          Who_suitable_forPackage: "Vem passar paket för?",
          Lärarrubrik: "Lärarrubrik",
          Underrubrik: "Underrubrik",
          My_Podcast: "Podcast",
          My_Education: "Utbildning",
          What_you_will_learn: "Vad du kommer att lära dig",
          Course_length: "Kurslängd",
          Course_start: "Kursstart",
          Course_fee: "Kursavgift",
          Extent: "Omfattning",
          Create_Education: "Skapa utbildning",
          Edit_Education: "Redigera utbildning",
          Vill_du_publicera_denna_utbildning:
            "Vill du publicera denna utbildning?",
          Vill_du_ta_bort_den_här_utbildningen:
            "Vill du avpublicera den här utbildningen?",
          What_to_learn_more_about: "Vad vill du lära dig mer om?",
          Create_Podcast: "Skapa podcast",
          name_of_guest: "Gästens namn",
          Vill_du_publicera_denna_podcast: "Vill du publicera denna podcast?",
          Vill_du_ta_bort_den_här_podcast:
            "Vill du avpublicera den här podcast?",
          PodcastProduct: "Podcast Produkt",
          Podcast_Name: "Podcastens namn",
          Podcast_Product_List: "Podcast Produktlista",
          Expression:"Intresseanmälan",
          Digitalmarketing:"Digital marknadsföring",
          All_Categories:"Alla kategorier",
          Alla_Podcast:'Alla Podcast',
          short_title:'short title',
          Reflektioner:'Reflektioner'
        },
      },
    },
    fallbackLng: "sw",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
