import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Header from "../commom/Header2";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getNote, removeNote } from '../../store/api/course.api';

const ReflactionDetail = () => {
    const { course_id } = useParams();
    const dispatch = useDispatch();
    const [allNote, setAllNotes] = useState();
    const { id } = useSelector((state) => state.auth.user);

useEffect(() => {
    refresh();
}, []);


const refresh = () => {
    let data = {
       "filter": {
          "n.user_id": id,
          "md.is_delete": 0

       }
    }
    dispatch(getNote(data)).then((res) => {
        let temp = res.payload.data.filter((item) =>  item.course_id === Number(course_id));
       setAllNotes(temp);
    })
 }


    return (
        <div
            className={` innerblock-page flex-grow-1`}
        >
            <Header headerShow={false} backButton="true" />
            <div className="page-wrapper flex-grow-1 page-block">
                <InnerHeader title={"Reflektioner"} subtitle="Dina reflektioner" backButton={true} backButtonURL="/reflaction" />
                <div className="py-40px px-xl-100px px-lg-30px px-20px flex-grow-1">

                    <div className="shadow-block rounded-20 bg-white overflow-hidden">
                        <div className="p-20px title-header text-center">
                            <TextBlock title={allNote && allNote[0]?.title} />
                        </div>
                        <div>
                            <div className="titleheader-box">
                            {
                           allNote?.map((item, index) => (
                                <div className="title-header p-20px">
                                    <h5 className="fs-20 fw-medium mb-0">{item?.media_title} </h5>
                                    <div className="fs-20 fw-light text-black"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.note_content,
                                      }}></div>
                                </div>
                                
                                ))
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReflactionDetail;