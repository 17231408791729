import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { contactUs } from "../../store/api/course.api";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FeatureCourseList from "../commom/FeatureCourseList";

const Footer = (prop) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState({ email: "", password: "" });
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onAdd = () => {
    dispatch(contactUs(value))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
        }
      })
      .catch(() => {});
  };
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  return (
    <Container fluid>
      <footer className={`footer  pt-16 pb-md-14 pb-3 mt-120 ${prop?.isBlack ? "footer_black border-top-black" : "border-top "}`} >
        {prop.isShow && (
          <section className="hv-100 border-bottom pb-1">
            <FeatureCourseList
              isHide={true}
              isShowBlue={true}
            ></FeatureCourseList>
          </section>
        )}
        <div className="footer-top">
          <Image
            src={prop?.isBlack?'/assets/images/Docens_logo_black.png':'/assets/images/Docens_Logo_whitelow_res.png'}
            alt="Mer tid för hemmaarbete"
          />
        </div>
        <Container fluid>
          {/* <Link to="/" className='footer-logo  d-inline-block'>
                <Image src="/assets/images/logo.svg" className="navbar-brand-img" alt="DOCENS" width="105" height="48" />
            </Link> */}
          <div className="footer-bottomblock d-flex justify-content-between flex-sm-nowrap flex-wrap">
            {/* <div className='d-flex'> */}
            <div className="d-flex gap-md-15 gap-8 justify-content-between w-sm-100 justify-content-sm-start flex-wrap px-md-0 px-24">
              <div className="footer-wrapper">
                <div className="footerinfo-block">
                  {/* <h5 className='text-white mb-7 fw-semibold'>{t('kontaktinfo')}</h5> */}
                  <address className="">
                    Dumpergatan 3 <br />
                    442 40 Kungälv
                  </address>
                  <ul className="list-unstyled footer-contactlist block  lh-lg">
                    <li className="mb-4">
                      <a
                        href="mailto:hej@docens.se"
                        className={`footer-contactlink text-light d-inline-flex align-items-center link-hover fw-semibold ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        hej@docens.se
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-wrapper">
                <div className="footerlink-block">
                  {/* <h5 className='text-white mb-7 fw-semibold'>{t('Information')}</h5> */}
                  <ul className="list-unstyled footer-informationlist block lh-lg">
                    <li>
                      <a
                        href="/vara-kurser"
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Kurser
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="/utbildning"
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Utbildningar
                      </a>
                    </li>
                    <li>
                      <a
                        href="/podcast"
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Podcast
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="footer-wrapper">
                <div className="footerlink-block">
                  {/* <h5 className='text-white mb-7 fw-semibold'>{t('Information')}</h5> */}
                  <ul className="list-unstyled footer-informationlist block  lh-lg">
                    {/* <li>
                          <a href="/integritetspolicy" className='footer-link text-light  link-hover'>Prissättning</a>
                        </li> */}
                    {/* <li>
                          <a href="/anvandarvilkor" className='footer-link text-light  link-hover'>Docens för företag</a>
                        </li> */}
                    <li>
                      <a
                        href="/contact-new"
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Kontakt
                      </a>
                    </li>
                    <li>
                      <a
                        href="/om-oss"
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Om oss
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-wrapper">
                <div className="footerlink-block">
                  {/* <h5 className='text-white mb-7 fw-semibold'>{t('Information')}</h5> */}
                  <ul className="list-unstyled footer-informationlist block lh-lg">
                    <li>
                      <a
                        href="/anvandarvilkor"
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Användarvillkor
                      </a>
                    </li>
                    <li>
                      <a
                        href="/integritetspolicy "
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Integritetspolicy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-wrapper">
                <div className="footerlink-block">
                  {/* <h5 className='text-white mb-7 fw-semibold'>{t('Information')}</h5> */}
                  <ul className="list-unstyled footer-informationlist block lh-lg">
                    <li>
                      <a
                        href="https://www.instagram.com/docens.se/" target={"_blank"}
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/Docens.se/" target={"_blank"}
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/docens-f%C3%B6retagsutbildningar/" target={"_blank"}
                        className={`footer-link text-light  link-hover ${prop?.isBlack ? "footer_black" : ""}`}
                      >
                        Linkedin
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="footerinfo-block">
             
              <ul className="list-unstyled footer-contactlist d-flex align-items-center gap-5 lh-lg justify-content-center">
                <li>
                  <a
                    href="https://www.instagram.com/docens.se/"
                    target={"_blank"}
                    className="footer-contactlink text-white d-inline-flex align-items-center link-hover"
                  >
                    <span className="d-inline-block footer-ic">
                      <Image
                        src="/assets/images/instagram.svg"
                        className={`${prop?.isBlack ? "svg-btn-footer" : ""}`}
                        alt="hej@docens.se"
                      />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/Docens.se/"
                    target={"_blank"}
                    className="footer-contactlink text-white d-inline-flex align-items-center link-hover"
                  >
                    <span className="d-inline-block footer-ic">
                      <Image
                        src="/assets/images/facebook.svg"
                        className={`${prop?.isBlack ? "svg-btn-footer" : ""}`}
                        alt="0303-390204"
                      />
                    </span>{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/docens-f%C3%B6retagsutbildningar/"
                    target={"_blank"}
                    className="footer-contactlink text-white d-inline-flex align-items-center link-hover"
                  >
                    <span className="d-inline-block footer-ic">
                      <Image
                        src="/assets/images/LinkedIn.svg"
                        className={`${prop?.isBlack ? "svg-btn-footer" : ""}`}
                        alt="hej@docens.se"
                      />
                    </span>
                  </a>
                </li>
              </ul>
            </div> */}

          </div>
        </Container>
      </footer>
    </Container>
  );
};

export default Footer;
