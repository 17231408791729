import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCources,
  getPackage,
  tagList,
  tagWiseCourseList,
} from "../../store/api/course.api";
import { Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toHoursAndMinutes } from "./commonConst";
import Select from "react-select";

const OurCourseList = (prop) => {
  const dispatch = useDispatch();
  const { role_id, id } = useSelector((state) => state?.auth?.user || {});
  const [allCources, setAllCources] = useState();
  const [tagListItems, setTagList] = useState([]);
  const [tagListToAssign, setTagListToAssign] = useState();

  const [tempAllCources, setTempAllCources] = useState();
  const { t } = useTranslation();
  const { tagId, index } = useParams();
  const [imageIndex, setImageIndex] = useState(-1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const navigate = useNavigate();
  //   let dataFilter = {
  //     filter: {
  //       is_delete: 0,
  //       is_public: 1,
  //     },
  //   };
  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    },
    user_id: id,
    role_id: role_id,
    // page,
    // limit: pageLimit,
  };
  // useEffect(() => {
  //   dispatch(getPackage(dataFilter)).then((course) => {
  //     let newArr = course.payload.data?.packages.concat(
  //       course.payload.data?.courses
  //     );

  //     setAllCources(newArr);
  //   });
  // }, []);
  useEffect(() => {
    if (tagId && tagId != 1) {
      setImageIndex(index);
      dataFilter.tags = tagId.toString();
    }
    dispatch(getCources(dataFilter)).then((course) => {
      if (prop.limit) {
        setAllCources(course.payload.data.courses.slice(0, prop.limit));
      } else {
        if (tagId && tagId != 1) {
          if (tagId == 3) {
            setAllCources(course.payload.data.courses);
            setTempAllCources(course.payload.data.courses);
          } else {
            setAllCources(course.payload.data.courses);
            setTempAllCources(course.payload.data.courses);
          }
        } else {
          dispatch(getPackage(dataFilter)).then((course) => {
            let newArr = course.payload.data?.packages.concat(
              course.payload.data?.courses
            );

            setAllCources(newArr);
            setTempAllCources(newArr);
          });
          // setAllCources(
          //   course.payload.data.courses.filter((item) => item.podcast === 0)
          // );
        }
      }
    });
    dispatch(tagList()).then((data) => {
      setTagList(
        data?.payload?.data?.filter((item) => item?.name !== "Mina kurser")
      );
    });
  }, []);
  var featurecard = {
    arrows: tagListItems.length > 6 ? true : false,
    infinite: tagListItems.length > 7 ? true : false,
    speed: 500,
    variableWidth: true,
    // slidesToShow: 8,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          //  slidesToShow: 6,
        },
      },
      {
        breakpoint: 767,
        settings: {
          //  slidesToShow: 3,
        },
      },
    ],
  };
  const customSort = (item, index) => {
    setImageIndex(index);
    // if (item.name === "Alla kurser") {

    if (item.label === "Alla kurser") {
      delete dataFilter.tag;
      dispatch(getCources(dataFilter)).then((course) => {
        let newArr = course.payload.data?.packages.concat(
          course.payload.data?.courses
        );
        setAllCources(newArr);
      });
    } else {
      // dataFilter.tags = item.id.toString();

      dataFilter.tags = item.value.toString();
      dispatch(getCources(dataFilter)).then((course) => {
        let newArr = course.payload.data?.packages.concat(
          course.payload.data?.courses
        );
        setAllCources(newArr);
      });
    }
    // navigate("/vara-kurser/" + item.name + "/" + item.id + "/" + index);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const MediaList = ({ item, index }) => {
    return (
      <Col md={4} sm={6} className="wow fadeInDown">
        <Link
          to={
            (item.number_of_courses ? "/package/" : "/open-course-topics/") +
            (item.number_of_courses ? item.package_id : item.id)
          }
          // style={{
          //   borderColor: item.number_of_courses ? "#7015ff" : "transparent",
          //   borderWidth: 1,
          // }}
          className={`getstart-block getstart-block-new position-relative d-flex flex-column feature-box ${!role_id ? "feature-box-new" : ""} ${item.number_of_courses ? "custome-a" : ""}`} 
        >
           <div className="get-started-img">
            <Image
              className="img-fit feature-img"
              src={item.image ? item.image : "/assets/images/Videi-1.jpeg"}
              alt="Image"
              width={526}
              height={348}
            />
          </div>
          <div className="img-content text-white d-flex align-items-start justify-content-end py-3 py-md-5">
            <div className="bottom-text text-start">
              <h5 className="mb-1 fw-light cursor_class">
                {add3Dots(item.title, 50)}
              </h5>
              <div className="d-flex  flex-md-row flex-column">
                <span>
                  <p className="fw-semibold cursor_class  mb-0 fs-16 mr-5">
                    {!item.number_of_courses
                      ? add3Dots(item.auther_details?.name, 50)
                      : "Paket"}
                  </p>
                </span>
                {/* <span>
                {item.number_of_courses > 0 && (
                    <p className="fw cursor_class  mb-0 fs-16 mobile-font-10 ">{item.number_of_courses} kurser</p>
                  )}
                  {!item.number_of_courses &&
                  <p className="fw cursor_class fs-16 mb-0 mobile-font-10">{`${item.media_count} avsnitt - `}</p>
                  }
                </span>
                {item?.id && (
                  <span className="fw-light fs-16 mobile-font-10">
                    {" "}
                    {item?.media_total_time == 0
                      ? ""
                      : toHoursAndMinutes(item?.media_total_time)}
                  </span>
                )} */}

                {/* <span className="fw-light">1h 45min</span> */}
              </div>
              {/* <p  className='tree-dot' dangerouslySetInnerHTML={{ __html: add3Dots(item.content,100) }}></p> */}
            </div>
          </div>
        </Link>
      </Col>
    );
  };
  let optionsTag=[]

  tagListItems &&
    tagListItems.map((list) =>
      optionsTag.push({ label: list.name, value: list.id })
    );
  
  return (
    <div className="d-flex flex-column flex-grow-1 homepage">
      <div className="page-wrapper flex-grow-1 py-0">
        {index != "1" ? (
          <section className="banner-wrapper text-start position-relative bg-wrap">
            <Container>
              <div className="block font-32 text-center fw-light video-style-font">
                <h1 className="font-64 mb-md-3 mb-0">Kurser & paket</h1>
                <p className="d-none d-md-block">
                  Färdiginspelade kurser
                </p>
              </div>
            </Container>
            <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
          </section>
        ) : (
          <section className="banner-wrapper text-start position-relative bg-wrap py-60">
            <Container>
              <div className="block font-32 text-center fw-light video-style-font">
                <h1 className="font-64  mb-md-3 mb-0">Podcast</h1>
                <p className="d-none d-md-block">
                  “Experterna” är podcasten där Michel Westher får experterna
                  att dela med sig av sin kunskap.
                </p>
              </div>
              <div className="banner-video">
                <video
                  src="/assets/video/Background-flow.mp4"
                  autoplay="autoplay"
                  playsinline="playsinline"
                  muted="muted"
                  loop="loop"
                ></video>
              </div>
            </Container>
          </section>
        )}
        {/* Starts Courses To get start section */}
        <div className="">
          <Container fluid>
            <div className="py-80 mobile-pt-40">
              {/* <div className="text-center mb-md-3 mb-0">
                <h2 className="text-white mb-0 d-inline-block wow fadeInDown fw-lighter font-40 d-md-block d-none">
                  {prop.title}{" "}
                </h2>
                <p className='mb-6'>{t('Köp_enstaka_kurs_till_ett')}</p>
              </div> */}
              <Row>
                  <Col md={8} sm={7} className="mb-4">
                    <div className="dark-card h-100">
                      <h4 className="fw-medium">Färdiginspelade kurser</h4>
                      <p className="fw-light font-20 mobile-font-14 mb-0">Våra färdiginspelade kurser är speciellt utformade för att främja inlärning. Med kurshållare som är experter inom sina områden och en högkvalitativ produktion skapar de en idealisk miljö för inlärning och ökar dina möjligheter att utvecklas. </p>
                    </div>
                  </Col>
                  <Col md={4} sm={5} className="mb-4">
                    <div className="dark-card h-100">
                      <h4 className="fw-medium text-center mb-0">Vad är du intresserad av?</h4>
                      <div className="course-select single-select w-100">
                      {/* <Multiselect
                        singleSelect
                        showArrow
                        options={optionsTag}
                        placeholder={t('All_Categories')}
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options,
                        selectedValues={tagListToAssign} // Preselected value to persist in dropdown
                        showCheckbox
                        selectionLimit={1}
                      /> */}

                      <Select 
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        className="custom-select"
                        classNamePrefix="myselect"
                        options={optionsTag} 
                        placeholder={t('All_Categories')} 
                        onChange={(values) =>customSort(values, values.value-1)} />

                      </div>
                    </div>
                  </Col>
              </Row>
            
              {/* {tagListItems.length > 0 && (
                <div className="categories-slider mobile-mb-14">
                  {tagListItems?.map((item, index) => (
                    <div
                      key={index}
                      className="feature-card-wrap-slider"
                      onClick={() => customSort(item, index)}
                    >
                      <div
                        className={`feature-box ${
                          index == imageIndex ? "border-primary-solid" : ""
                        }`}
                      >
                        <p>{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
              {/* <img src={item.image?item.image:'/assets/images/SVG/053-diamond.svg'}  className='feature-image svg-btn-image-no-hover' alt="Banner image" width={40} height={40} /> */}
              {/* <div className="pricing-title text-center border-color-on-hover">
             <a href="/pris" className='text-white' >{t('Klicka_här_och_få_tillgång_till_alla_kurser_kostnadsfritt_i_14_dagar')}</a>
                    </div> */}
              <Row className="g-md-7 g-5">
                {allCources?.length > 0 &&
                  allCources.map((item, key) => (
                    <MediaList item={item} key={key} index={key} />
                  ))}
              </Row>
            </div>
          </Container>
        </div>
        {/* Ends Courses To get start section */}
        {allCources && allCources.length === 0 && (
          <p className="text-center">{t("No_records_found")}</p>
        )}
      </div>
    </div>
  );
};
export default OurCourseList;
