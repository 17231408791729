import { Link } from "react-router-dom"

const InnerHeader = ({ title, subtitle, backButton = false, backButtonURL }) => {
  return (
    <div className="d-flex align-items-center flex-sm-nowrap flex-wrap wow fadeInDown py-20px px-lg-30px  px-20px innerpage-header gap-20px">
      {backButton &&
        <Link to={backButtonURL} className="backbtn d-flex align-items-center justify-content-center flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
          <path d="M8.24813 1L1 8.2481L8.24813 15.4962" stroke="#292D32" stroke-width="1.79114" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21.2996 8.24805H1.20306" stroke="#292D32" stroke-width="1.79114" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg></Link>
      }

      <div className="text-svart fw-medium">
        <h1 className="mb-0 text-svart fw-medium text-white h4 mobile-font-16">{title}</h1>
        {subtitle && <span className="opacity-60 fs-20 text-svart fw-medium mobile-font-14">{subtitle}</span>}

      </div>
    </div>
  )
}

export default InnerHeader;