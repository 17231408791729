import React, { useEffect, useState } from 'react';
import { Form, Button, Image, Modal } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { addTag, editTag } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";

const AddEditTag = (prop) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [value, setValue] = useState({ name: '' });

    const [selectedFile, setSelectedFile] = useState(null);


    




    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }
    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };


    useEffect(() => {
        setShow(prop.show);
        if (prop.editId) {
            setValue({
                ...value,
                ['name']: prop.editName,
            });
        }
    }, [])
    const onSave = () => {
        var data = new FormData();
        data.append('name', value.name);
        if (selectedFile) {
            data.append('image', selectedFile, selectedFile.name);
        }
        dispatch(addTag(data))
            .unwrap()
            .then((res) => {
                if (res.status === 200) {
                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e)
            });
    };

    const onEdit = () => {
        var data = new FormData();
        data.append('id', prop.editId);
        data.append('name', value.name);
        if (selectedFile) {
            data.append('image', selectedFile, selectedFile.name);
        }
        dispatch(editTag(data))
            .unwrap()
            .then((res) => {
                if (res.status === 200) {
                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e)
            });
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Create_Courses')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                            <div className='position-relative control-withic'>
                                <Form.Control type="text" placeholder={('Title')} name="name" aria-label="Product Name" value={value.name} onChange={onChange} />
                                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                            </div>
                        </Form.Group>
                        <div className='mb-10 wow fadeInDown'>
                            <input type="file" id="file1" className='visually-hidden' onChange={(e) => setSelectedFile(e.target.files[0])} />
                            <label htmlFor="file1" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                                <span className='file-icon'><Image src="/assets/images/Video_file_light.png" alt="Select Image" width="48" height="48" /></span>
                                <span>{t('Select_Image')}</span>
                            </label>
                        </div>
                        {!prop.editId &&
                            <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onSave}> {t('Save')} </Button>
                        }
                        {prop.editId &&
                            <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onEdit}> {t('Update')} </Button>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AddEditTag;

