import React, { useEffect, useState } from 'react';
import { Container, Table, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../commom/Header2';
import { useDispatch, useSelector } from 'react-redux';
import { getExamList, removeExam } from '../../store/api/course.api'
import AddNewTest from '../commom/AddNewTest';
import ConfirmPopup from '../commom/ConfirmPopup'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import InnerHeader from './InnerHeader';
require('moment/locale/sw.js');
const TestPaperList = () => {
   const [show, setShow] = useState(false);
   const { t } = useTranslation();
   const [allProduct, setAllProduct] = useState();
   const dispatch = useDispatch();
   const [editId, setEditId] = useState(null);
   let data = {
      "filter": {
         "e.is_delete": 0
      }
   }
   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
   });

   const handleDelete = (id) => {
      setPopup({
         show: true,
         id,
      });
   };
   const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         let data = {
            'is_delete': 1,
            'id': popup.id
         }
         dispatch(removeExam(data))
            .unwrap()
            .then((res) => {
               if (res.status === 200) {
                  refresh();
               }
            })
            .catch((e) => {
               console.log(e)
            });
         setPopup({
            show: false,
            id: null,
         });
      }
   };
   const handleDeleteFalse = () => {
      setPopup({
         show: false,
         id: null,
      });
   };
   const handleShow = (id) => {
      setEditId(id)
      setShow(true);
   }
   const callbackModal = () => {
      setShow(false);
      refresh();
   }
   useEffect(() => {
      refresh();
   }, [])
   const refresh = () => {
      dispatch(getExamList(data)).then((res) => {
         setAllProduct(res.payload.data);
      })
   }
   return (
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t('Test_List')} subtitle="" />
            <div className='py-lg-40px px-xl-100px px-lg-30px px-20px flex-grow-1'>
               <div className='d-flex justify-content-between align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown'>
                  {/* <h1 className='mb-sm-0 mb-4 fw-semibold text-white h2'>{t('Test_List')} </h1> */}
                  <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={() => handleShow(null)}>{t('Add_New')}</Button>
                  {(show && !editId) &&
                     <AddNewTest show={show} editId={null} callbackModal={callbackModal} />
                  }
               </div>
               {/* Starts Table */}
               <div className='wow fadeInDown table-borderwrap'>
                  <Table bordered responsive className='text-center tableblock-wrap mb-0'>
                     <thead className='top-bordernone'>
                        <tr>
                           <th>{t('Index')}</th>
                           <th>{t('Course_Name')}</th>
                           <th>{t('Test_Name')}</th>
                           <th>{t('Date')}</th>
                           <th>{t('Action')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           allProduct?.map((product, index) => (
                              <tr key={product?.id}>
                                 <td>
                                    {index + 1}
                                 </td>
                                 <td>{product?.course_title}</td>
                                 <td>{product?.exam_name}</td>
                                 <td>{moment(product?.created_at).format("DD-MMM-YYYY")}</td>
                                 <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                       <Link to={"/test-detail/" + product.id + '/' + product?.course_id} className='btn btn-link icon-eye text-black link-hover action-btn'></Link>
                                       <a onClick={() => handleShow(product?.id)} className='btn btn-link icon-ic-edit text-black link-hover action-btn'></a>
                                       {(show && editId === product?.id) &&
                                          <AddNewTest show={show} editId={editId} callbackModal={callbackModal} />
                                       }
                                       <a onClick={() => handleDelete(product.id)} className='btn btn-link icon-ic-trash text-black link-hover action-btn'></a>
                                       {popup.show && (
                                          <ConfirmPopup
                                             handleDeleteTrue={handleDeleteTrue}
                                             handleDeleteFalse={handleDeleteFalse}
                                             message={t('You_sure_you_wanna_delete')}
                                          />
                                       )}
                                    </div>
                                 </td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>
                  {allProduct && allProduct.length === 0 &&
                     <p className='text-center'>{t('No_records_found')}</p>
                  }
               </div>
               {/* Ends Table */}
            </div>
         </div>
      </div>
   )

}

export default TestPaperList;

