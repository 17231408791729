import React, { Component, useState, useEffect } from 'react';
import Header from '../commom/Header';
import Footer from '../commom/Footer';
import OurCourseList from '../commom/OurCourseList'
const OurCourses = () => {
  return (
    <div className='d-flex flex-column flex-grow-1 homepage '>
      <Header />
      <div className={`page-wrapper flex-grow-1  overflow-hidden py-0 `}>
          {/* Starts Courses To get start section */}
        <OurCourseList title={'Våra kurser'} ></OurCourseList>
          {/* Ends Courses To get start section */}
      </div>
      <Footer />
    </div>
  )
}
export default OurCourses;