import React, { useEffect, useState } from 'react';
import { Container, Form, Button, InputGroup ,Navbar} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import { inviteAddUser } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { omit } from 'lodash'
import Header from '../commom/Header';
import { useParams, useNavigate,Link } from 'react-router-dom';

require('moment/locale/sw.js');
const Invitation = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { token } = useParams();
   const invitationData = JSON.parse(atob(token)); // decode your token here
   const [value, setValue] = useState({ firstname: "", lastname: "", code: "", phone: "", email: invitationData.email ? invitationData.email : '' });
   const { message } = useSelector((state) => state.message);
   const [errors, setErrors] = useState({});
   const navigate = useNavigate();
   const [passwordType, setPasswordType] = useState("password");
   const [cpasswordType, setcpassword] = useState("password");
   const onChange = (e) => {
      validate(e, e.target.name, e.target.value);
      setValue({
         ...value,
         [e.target.name]: e.target.value,
      });
   };

   const togglePassword = () => {
      if (passwordType === "password") {
         setPasswordType("text")
         return;
      }
      setPasswordType("password")
   }

   const togglecPassword = () => {
      if (cpasswordType === "password") {
         setcpassword("text")
         return;
      }
      setcpassword("password")
   }
   useEffect(() => {

   }, [])
   const handlePhoneChange = (status, phone, country) => {
      validate(status, 'phone', phone);
      setValue({
         ...value,
         ['phone']: phone,
         ['code']: country.dialCode
      });
   };

   const validate = (event, name, value1) => {
      //A function to validate each input values

      switch (name) {
         case 'email':
            if (
               !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value1)
            ) {
               setErrors({
                  ...errors,
                  email: <>{t('enter_email')}</>
               })
            } else {

               let newObj = omit(errors, "email");
               setErrors(newObj);
            }
            break;

         case 'password':
            if (
               !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value1)
            ) {
               setErrors({
                  ...errors,
                  password: <>{t('Password_should')}</>
               })
            } else {
               let newObj = omit(errors, "password");
               setErrors(newObj);
            }
            break;
         case "cpassword":

            if (!value1) {

               setErrors({
                  ...errors,
                  cpassword: <>{t('Please_enter_Confirm_Password')}</>
               })
            } else if (value.password && value1 !== value.password) {
               setErrors({
                  ...errors,
                  cpassword: <>{t('Password_and_Confirm_Password_does_not_match')}</>
               })
            } else {
               let newObj = omit(errors, "cpassword");
               setErrors(newObj);
            }
            break;
         case 'phone':
            if (!new RegExp(/^[0-9]{10}$/).test(value1)
            ) {

               setErrors({
                  ...errors,
                  phone: <span> Enter valid 10 digit number</span>
               })
            } else {

               let newObj = omit(errors, "phone");
               setErrors(newObj);

            }
            break;
         default:
            break;
      }
   }

   // on press signup button
   const onAdd = () => {
      let body;
      body = {
         'id': invitationData.id[0],
         'first_name': value.firstname,
         'last_name': value.lastname,
         'email': value.email,
         'code': value.code,
         'phone_no': value.phone,
         'business_id': invitationData.business_id,
         'password': value.password

      }
      dispatch(inviteAddUser(body))
         .unwrap()
         .then((res) => {
            if (res.status === 200) {
               toast.success(res.message);
               navigate('/logga-in');
            }
         })
         .catch((e) => {
            toast.error(message);
            navigate('/logga-in');
         });
   };

   return (
      <>
         
          <div className="onboarding-page">
      <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
      
      
      <Container fluid>
        <div className="text-white maxw-507 mx-auto">
        {/* <Link to="/" className="back-btnblock mb-8 d-inline-block mobile-mb-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M22.5 27L13.5 18L22.5 9" stroke="white" stroke-width="1.5"/>
              </svg>
                    </Link> */}
          <div className="block mb-8 mobile-mb-20">
            <h1 className="font-32 mobile-font-20">Registrering</h1>
          </div>
          <Form className="mb-8 signup-form d-flex flex-column gap-5 mobile-mb-20">
            <Form.Group controlId="formBasicname">
            <Form.Label className="font-24 fw-medium mb-2">{t('First_Name')}</Form.Label>
              <div className="form-group">
                <Form.Control
                  type="text"
                  placeholder={t('First_Name')}
                  name="firstname"
                  value={value.firstname}
                  onChange={onChange}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
            <Form.Label className="font-24 fw-medium mb-2">{t('Email')}</Form.Label>
              <div className="form-group">
                <Form.Control
                  type="email"
                  placeholder={t('Email')}
                  name="email"
                  readOnly={true}
                  value={value.email}
                  onChange={onChange}
                />
              </div>
            </Form.Group>
            <Form.Group  controlId="formBasicEmail" >
                            <Form.Label className="font-24 fw-medium mb-2">Lösenord</Form.Label>
                            <div className="form-group">
                            <Form.Control  type={passwordType} name="password"   placeholder="Tänk på något unikt..."  onChange={onChange} />
                            <Button variant="light" type="button" className='nohover'  onClick={togglePassword}> {passwordType === "password"? "Visa": "Hide"}</Button>
                            </div>
                           
                        </Form.Group>
          </Form>
          <Button variant="primary" className="" onClick={onAdd} >
          Gå vidare
          </Button>
        </div>
      </Container>
    </div>
      </>
   )
}
export default Invitation;