import React, { useState, useEffect } from "react";
import { Button, Image, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/api/auth.api";
import { useTranslation } from "react-i18next";
import { getnotification } from "../../store/api/course.api";
import { useLocation } from "react-router-dom";
import {monthlyPlan} from "./commonConst";
const Header = ({ headerShow = true }) => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [allNotification, setAllNotification] = useState(0);
  const { price_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const { pathname } = useLocation();
  useEffect(() => {
    
    dispatch(getnotification()).then((res) => {
      setAllNotification(
        res.payload.data?.unread_count ? res.payload.data?.unread_count : 0
      );
    });
  }, []);
  const handleToggle = () => {
    setActive(!isActive);
  };
  const [sidebarActive, setSidebarActive] = useState(false);
  const { role_id,email,first_name } = useSelector((state) => state?.auth?.user  || {});
  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const goto = () =>{
    navigate("/onboarding/confirm-selection", {
      state: { email: email,from_inside:true,first_name:first_name},
    });
  }

  return (
    <>
      {headerShow ?
        <header
          className={
            sidebarActive
              ? "border-bottom fixed-top start-0 top-0 end-0 header-wrap d-flex flex-wrap align-items-center sidebar-active"
              : "border-bottom fixed-top start-0 top-0 end-0 header-wrap d-flex flex-wrap align-items-center"
          }
        >
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn hamburger-btn flex flex-column d-inline-flex"
              onClick={toggleSidebar}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <Navbar.Brand href="/oversitk" className="me-13">
              {/* <Image src="/assets/images/logo.svg" className="navbar-brand-img" alt="DOCENS" width="105" height="48" /> */}
              <svg
                id="Lager_2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 710.11 134.41"
              >
                <defs>
                  <style>{"fill:#fff;"}</style>
                </defs>
                <g id="Lager_1-2">
                  <g>
                    <path
                      className="cls-1"
                      d="M106.99,68.38c0,20.93-7.04,39.33-20.57,50.7-9.56,7.93-22.01,12.45-38.25,12.45H.25c-.14,0-.25-.11-.25-.25v-26.51c0-23.3,9.26-45.65,25.73-62.12h0c.16-.16,.43-.05,.43,.18V108.72c0,.14,.11,.25,.25,.25h19.96c24.53,0,34.1-14.97,34.1-40.59s-11.19-43.48-33.38-43.48H.25c-.14,0-.25-.11-.25-.25V2.78c0-.14,.11-.25,.25-.25H50.34c34.46,0,56.65,27.78,56.65,65.85Z"
                    />
                    <path
                      className="cls-1"
                      d="M117.63,67.29C117.63,28.69,141.81,0,179.88,0s62.06,28.69,62.06,67.29-23.99,67.11-62.06,67.11-62.24-28.51-62.24-67.11Zm97.6,0c0-25.8-11.55-45.83-35.18-45.83s-35.72,20.03-35.72,45.83,12.09,45.65,35.72,45.65,35.18-20.03,35.18-45.65Z"
                    />
                    <path
                      className="cls-1"
                      d="M250.59,67.48C250.59,29.23,274.4,0,312.47,0c31.75,0,50.52,18.94,53.22,43.48h-25.98c-2.35-12.99-12.09-21.47-27.24-21.47-23.81,0-35.18,19.85-35.18,45.47s13.71,45.28,35.36,45.28c15.34,0,26.16-9.2,27.78-22.73h25.62c-.72,11.37-5.59,22.37-14.25,30.49-8.84,8.3-21.29,13.71-39.15,13.71-36.26,0-62.06-27.96-62.06-66.75Z"
                    />
                    <path
                      className="cls-1"
                      d="M378.57,2.53h94.9V24.54h-68.74v28.87h60.26v21.47h-60.26v34.82h69.28v21.83h-95.44V2.53Z"
                    />
                    <path
                      className="cls-1"
                      d="M488.23,2.53h26.52l42.94,71.99c4.15,7.04,9.02,18.04,9.02,18.04h.36s-.72-13.35-.72-22.37V2.53h25.8V131.52h-25.26l-44.02-71.26c-4.15-6.86-9.2-17.86-9.2-17.86h-.36s.72,13.53,.72,22.55v66.57h-25.8V2.53Z"
                    />
                    <path
                      className="cls-1"
                      d="M604.21,91.65h25.62c1.8,15.52,10.64,21.65,29.05,21.65,13.35,0,25.08-4.69,25.08-16.6,0-12.63-12.27-15.16-31.93-19.67-23.09-5.23-44.2-11.37-44.2-38.25,0-25.44,20.75-38.61,49.43-38.61s47.81,14.25,49.79,40.23h-25.08c-1.44-12.99-11.55-19.48-24.9-19.48-14.07,0-23.27,5.95-23.27,15.15,0,10.46,9.02,13.53,28.14,17.68,26.52,5.77,48.17,12.09,48.17,39.87,0,26.16-21.11,40.77-50.15,40.77-35,0-54.85-15.52-55.75-42.76Z"
                    />
                  </g>
                </g>
              </svg>
            </Navbar.Brand>
          </div>

          {/* <div className={
                    isActive ? "d-flex search-form flex-grow-1 active" : "d-flex search-form flex-grow-1 "
                  }>
               <form className="d-flex position-relative flex-grow-1">
                  <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-link position-absolute search-button" type="submit"><Image src="/assets/images/Search_alt_light.png" alt="Search" width={32} height={32} /></button>
               </form>
               <a role="button" className="search-close flex d-sm-none"  onClick={handleToggle}>
                  <Image src='/assets/images/closemark.svg' alt="Close" width={30} height={30} />
               </a>
            </div> */}
          {(role_id === 2 || role_id === 3) && (
            <>
              {/* {!stripe_subscription_id && (
              <a
                role="button"
                href="/pris"
                className="button-14 border-color-on-hover"
              >
                {t("Prova_i_14_dagar_kostnadsfritt")}
              </a>
            )} */}

              {/* {stripe_subscription_id && (
              <a
                role="button"
                href="/pris"
                className="button-14 border-color-on-hover"
              >
                {t("uppdatera_prenumeration")}
              </a>
            )} */}
            </>
          )}
          <div className="d-flex align-items-center">
            <ul className="list-unstyled d-flex align-items-center ms-sm-auto flex-shrink-0 header-buttons align-items-center mb-0">
              {/* <li>
              <Link to="" onClick={() => changeLanguage("en")} className='width-28'>
              <Image src="/assets/images/en.png" className="width-28" alt="En" width="40" height="40" />
              </Link>
               </li>
               <li>
               <Link to="" onClick={() => changeLanguage("sw")} className='width-28 '>
              <Image src="/assets/images/sv.png" className="width-28" alt="Sv" width="40" height="40" />
              </Link>
               </li> */}

              <li>
                <Link
                  to={"/alla-notiser"}
                  className="header-blocklink position-relative"
                >
                  {/* <span className='icon-Bell_pin text-white link-hover' style={{'margin-top':'5px'}}></span> */}
                  <Image
                    alt=""
                    src="/assets/images/SVG/015-notification.svg"
                    className="svg-btn-image"
                  />
                  <span className="notif-count">{allNotification} </span>
                </Link>
              </li>
              {(role_id === 2 || role_id === 3) && (
                <li>
                  <Link
                    to={"/cart"}
                    className="header-blocklink position-relative"
                  >
                    {/* <span className='icon-ic-bag  text-white link-hover'></span> */}
                    <Image
                      alt=""
                      src="/assets/images/SVG/035-shopping-cart.svg"
                      className="svg-btn-image"
                    />
                  </Link>
                </li>
              )}
              <li>
                <NavDropdown
                  className="uc-dropdown"
                  title={
                    <Image
                      alt=""
                      src="/assets/images/SVG/011-user.svg"
                      className="svg-btn-image"
                    />
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item href="/profil">
                    {t("My_Profile")}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onLogout}>
                    {" "}
                    {t("Logout")}{" "}
                  </NavDropdown.Item>
                </NavDropdown>
              </li>
            </ul>
          </div>
        </header>
        :
        <header className="d-lg-none d-flex p-20px bg-grey-block justify-content-between">
          <Image src="/assets/img/docens-white-logo.svg" alt="Docens" width={100} height={30} className="filter-invert" />
          <button
            type="button"
            className="btn  flex flex-column d-inline-flex p-0"
            onClick={toggleSidebar}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
              <rect x="5.49042" width="18.8243" height="3.13738" fill="#191919" />
              <rect y="6.27539" width="24.3147" height="3.13738" fill="#191919" />
              <rect x="12.5495" y="12.5488" width="11.7652" height="3.13738" fill="#191919" />
            </svg>
          </button>
        </header>
      }

      {/* Sidebar */}
      <aside
        className={
          sidebarActive ? "sidebar-menu sidebar-active" : "sidebar-menu"
        }
      >
        <div className="logobox ">
          <div className="d-flex align-items-center justify-content-between mb-lg-0 mb-40px">
            {/* <Image src="/assets/img/docens-white-logo.svg" /> */}
            <a >
              <Image src="/logo-white.png" /></a>
            <Button onClick={toggleSidebar} variant="link" className="d-lg-none d-block"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M18.4346 6L6.43457 18" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.43457 6L18.4346 18" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg></Button>
            <NavDropdown
              className="uc-dropdown userdropdown d-lg-block d-none"
              title={
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <path d="M30 15C30 6.735 23.265 0 15 0C6.735 0 0 6.735 0 15C0 19.35 1.875 23.265 4.845 26.01C4.845 26.025 4.845 26.025 4.83 26.04C4.98 26.19 5.16 26.31 5.31 26.445C5.4 26.52 5.475 26.595 5.565 26.655C5.835 26.88 6.135 27.09 6.42 27.3C6.525 27.375 6.615 27.435 6.72 27.51C7.005 27.705 7.305 27.885 7.62 28.05C7.725 28.11 7.845 28.185 7.95 28.245C8.25 28.41 8.565 28.56 8.895 28.695C9.015 28.755 9.135 28.815 9.255 28.86C9.585 28.995 9.915 29.115 10.245 29.22C10.365 29.265 10.485 29.31 10.605 29.34C10.965 29.445 11.325 29.535 11.685 29.625C11.79 29.655 11.895 29.685 12.015 29.7C12.435 29.79 12.855 29.85 13.29 29.895C13.35 29.895 13.41 29.91 13.47 29.925C13.98 29.97 14.49 30 15 30C15.51 30 16.02 29.97 16.515 29.925C16.575 29.925 16.635 29.91 16.695 29.895C17.13 29.85 17.55 29.79 17.97 29.7C18.075 29.685 18.18 29.64 18.3 29.625C18.66 29.535 19.035 29.46 19.38 29.34C19.5 29.295 19.62 29.25 19.74 29.22C20.07 29.1 20.415 28.995 20.73 28.86C20.85 28.815 20.97 28.755 21.09 28.695C21.405 28.56 21.72 28.41 22.035 28.245C22.155 28.185 22.26 28.11 22.365 28.05C22.665 27.87 22.965 27.705 23.265 27.51C23.37 27.45 23.46 27.375 23.565 27.3C23.865 27.09 24.15 26.88 24.42 26.655C24.51 26.58 24.585 26.505 24.675 26.445C24.84 26.31 25.005 26.175 25.155 26.04C25.155 26.025 25.155 26.025 25.14 26.01C28.125 23.265 30 19.35 30 15ZM22.41 22.455C18.345 19.725 11.685 19.725 7.59 22.455C6.93 22.89 6.39 23.4 5.94 23.955C3.66 21.645 2.25 18.48 2.25 15C2.25 7.965 7.965 2.25 15 2.25C22.035 2.25 27.75 7.965 27.75 15C27.75 18.48 26.34 21.645 24.06 23.955C23.625 23.4 23.07 22.89 22.41 22.455Z" fill="white" fill-opacity="0.5" />
                  <path d="M15 7.39551C11.895 7.39551 9.375 9.91551 9.375 13.0205C9.375 16.0655 11.76 18.5405 14.925 18.6305H15.06H15.165H15.195C18.225 18.5255 20.61 16.0655 20.625 13.0205C20.625 9.91551 18.105 7.39551 15 7.39551Z" fill="white" fill-opacity="0.5" />
                </svg>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/profil">
                {t("My_Profile")}
              </NavDropdown.Item>
              <hr className="profile-hr" />
              {/* <NavDropdown.Item href="/anvandarvilkor">Användarvilkor</NavDropdown.Item>
              <NavDropdown.Item href="/integritetspolicy">Integritetspolicy</NavDropdown.Item>
              <NavDropdown.Item href="/kontakta-oss">Kontakt</NavDropdown.Item>
              <hr className="profile-hr" /> */}
              <NavDropdown.Item onClick={onLogout}>
                {" "}
                {t("Logout")}{" "}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          <hr className="border-grey-neutral-100 opacity-50 mt-40px d-lg-block d-none" />
        </div>
        <ul className="list-unstyled">
          {role_id !== 1 && (
            <>
              <li className="d-lg-none d-block">
                <NavLink
                  className="menu-link mt-0"
                  exact
                  activeClassName="active"
                  to="/profil"
                  title="Min profil"
                >
                  {/* <span className="icon-ic-dashboard"></span> */}
                  {/* <Image
                alt=""
                src="/assets/images/SVG/032-folders.svg"
                className="svg-btn-image"
              /> */}
                  <span className="menu-text">Min profil</span>
                </NavLink>
              </li>
              <hr className="border-grey-neutral-100 opacity-50 d-lg-none d-block" />
              <li>
                <NavLink
                  className="menu-link"
                  exact
                  activeClassName="active"
                  to="/oversitk"
                  title={t("Dashboard")}
                >
                  <span className="menu-text">{t("Dashboard")}</span>
                </NavLink>
              </li>
            
              {role_id === 2 && (
              <li>
                <NavLink
                  className="menu-link"
                  exact
                  activeClassName="active"
                  to="/company-dashboard"
                  title={t("Företagsöversikt")}
                >
                  <span className="menu-text">{t("Företagsöversikt")}</span>
                </NavLink>
              </li>
                )}
              
             
              
              <hr className="border-grey-neutral-100 opacity-50" />
            </>
          )}

          <li>
            <NavLink
              className={`menu-link ${pathname.includes('private-open-course-topics') ? "active" : ""}`}
              exact
              activeClassName="active"
              to="/my-course-details"
              title={t("My_Course")}
            >
              {/* <span className='icon-books icon-menu'></span> */}
              {/* <Image
                alt=""
                src="/assets/images/SVG/032-folders.svg"
                className="svg-btn-image"
              /> */}
              <span className="menu-text">{t("My_Course")} </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              className={`menu-link ${pathname.includes('private-podcast-topics') ? "active" : ""}`}
              exact
              activeClassName="active"
              to="/my-podcast-details"
              title={t("My_Podcast")}
            >
             
              <span className="menu-text">{t("My_Podcast")} </span>
            </NavLink>
          </li> */}
          {role_id === 1 && (
            <li>
              <NavLink
                 className={`menu-link ${pathname.includes('private-educations-individual') ? "active" : ""}`}
                exact
                activeClassName="active"
                to="/my-education-details"
                title={t("My_Education")}
              >
                {/* <span className='icon-books icon-menu'></span> */}
                {/* <Image
                alt=""
                src="/assets/images/SVG/008-design.svg"
                className="svg-btn-image"
              /> */}
                <span className="menu-text">{t("My_Education")} </span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                 className={`menu-link ${pathname.includes('my-webinar-details') ? "active" : ""}`}
                exact
                activeClassName="active"
                to="/my-webinar-details"
                title={t("My_Webinar")}
              >
                {/* <span className='icon-books icon-menu'></span> */}
                {/* <Image
                alt=""
                src="/assets/images/SVG/008-design.svg"
                className="svg-btn-image"
              /> */}
                <span className="menu-text">{t("Webinar")} </span>
              </NavLink>
            </li>
          )}
           {role_id === 1 && (
            <li>
              <NavLink
                 className={`menu-link ${pathname.includes('webinar-history') ? "active" : ""}`}
                exact
                activeClassName="active"
                to="/webinar-history"
                title={t("Webinar History")}>
                <span className="menu-text">{t("Webinar History")} </span>
              </NavLink>
            </li>
          )}
          
          {role_id !== 1 && (
            <li>
              <NavLink
                className={`menu-link ${pathname.includes('private-educations-individual') ? "active" : ""}`}
                exact
                activeClassName="active"
                to="/private-educations"
                title={t("My_Education")}
              >
                {/* <span className='icon-books icon-menu'></span> */}
                {/* <Image
                          alt=""
                          src="/assets/images/SVG/008-design.svg"
                          className="svg-btn-image"
                        /> */}
                <span className="menu-text">{t("My_Education")} </span>
              </NavLink>
            </li>
          )}
          {/* {role_id !== 1 && (
            <li>
              <NavLink
                className={`menu-link ${pathname.includes('private-webinar-individual') ? "active" : ""}`}
                exact
                activeClassName="active"
                to="/private-webinar"
                title={t("Webinar")}
              >
                
                <span className="menu-text">{t("Webinar")} </span>
              </NavLink>
            </li>
          )} */}
          <hr className="border-grey-neutral-100 opacity-50" />
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link "
                exact
                activeClassName="active"
                to="/my-package"
                title={t("mina_Paket")}
              >
                {/* <span className='icon-books icon-menu'></span> */}
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/067-folder.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text ">{t("mina_Paket")} </span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/produkt-lista"
                title={t("Product")}
              >
                {/* <span className="icon-ic-chart icon-menu"></span> */}

                {/* <Image
                  alt=""
                  src="/assets/images/SVG/091-database.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("Product")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/podcast-produkt-lista"
                title={t("Product")}
              >
                {/* <span className="icon-ic-chart icon-menu"></span> */}

                {/* <Image
                  alt=""
                  src="/assets/images/SVG/091-database.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("PodcastProduct")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/kreator"
                title={t("Creator_Teacher")}
              >
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/002-group.svg"
                  className="svg-btn-image"
                /> */}
                {/* <span className="icon-users icon-menu"><span className="path1"></span><span className="path2"></span></span> */}
                <span className="menu-text">{t("Creator_Teacher")}</span>
              </NavLink>
            </li>
          )}
          {/* {role_id === 2 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/user-list"
                title={t("Users_List")}
              >
               
                <span className="menu-text opacity-50">{t("Users_List")}</span>
              </NavLink>
            </li>
          )} */}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/user-managment"
                title={t("Users_List")}
              >
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/001-man.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("Users_List")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/demand-list"
                title={t("Demand_List")}
              >
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/051-science.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("Demand_List")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/analyctics"
                title={t("Analysis")}
              >
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/057-chart.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("Analysis")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/test"
                title={t("Create_Test_paper")}
              >
                {/* <span className="icon-insert-template"></span> */}
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/074-checklist.svg"
                  className="svg-btn-image"
                /> */}

                <span className="menu-text">{t("Create_Test_paper")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/send-notificatios"
                title={t("Send_Notification")}
              >
                {/* <span className="icon-Bell_pin"></span> */}
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/034-megaphone.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("Send_Notification")}</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/add-edit-anki"
                title={t("ANKI")}
              >
                {/* <span className="icon-command"></span> */}
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/059-puzzle.svg"
                  className="svg-btn-image"
                /> */}

                <span className="menu-text">{t("ANKI")}</span>
              </NavLink>
            </li>
          )}
          {/* {(role_id === 2 ) && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/my-order"
                title={t("My_orders")}
              >
               
                <span className="menu-text opacity-50">{t("My_orders")}</span>
              </NavLink>
            </li>
          )} */}

          {(role_id === 2 || role_id === 3 || role_id === 4) && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/reflaction"
                title={t("Reflektioner")}
              >

                {/* <Image
                  alt=""
                  src="/assets/images/SVG/022-write.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text opacity-50">{t("Reflektioner")}</span>
              </NavLink>
            </li>
          )}
          {/* { (role_id ===3 || role_id ===4) && 
            <li>
               <NavLink className='menu-link' exact activeClassName="active"  to="/my-test-list" >
               <span className="icon-paste"></span>
                  <span className='menu-text'>{t('My_tests')}</span>
               </NavLink>
            </li>
           } */}

          {(role_id === 4 || role_id === 3) && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/certificate"
                title="diplom"
              >
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/033-favorite.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text opacity-50">diplom</span>
              </NavLink>
            </li>
          )}
          {role_id === 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/nyhetsbrev-lista"
                title={t("manage_newslatter")}
              >
                {/* <Image
                  alt=""
                  src="/assets/images/SVG/013-mail.svg"
                  className="svg-btn-image"
                /> */}
                <span className="menu-text">{t("manage_newslatter")}</span>
              </NavLink>
            </li>
          )}
          {/* {role_id !== 1 && (
            <li>
              <NavLink
                className="menu-link"
                exact
                activeClassName="active"
                to="/kunskapsbanken"
                title={t("download_newslatter")}
              >
                <Image
                  alt=""
                  src="/assets/images/SVG/096-download.svg"
                  className="svg-btn-image"
                />
                <span className="menu-text">{t("download_newslatter")}</span>
              </NavLink>
            </li>
          )} */}
          <li>
            {role_id === 1 && (
              <li>
                <NavLink
                  className="menu-link"
                  exact
                  activeClassName="active"
                  to="/rabattkoder"
                  title={t("Rabattkoder")}
                >
                  {/* <Image
                    alt=""
                    src="/assets/images/SVG/064-shield.svg"
                    className="svg-btn-image"
                  /> */}
                  <span className="menu-text">{t("Rabattkoder")}</span>
                </NavLink>
              </li>
            )}
            {role_id === 1 && (
              <li>
                <NavLink
                  className="menu-link"
                  exact
                  activeClassName="active"
                  to="/redigera-artiklar"
                  title={t("Rabattkoder")}
                >
                  {/* <Image
                    alt=""
                    src="/assets/images/SVG/029-tag.svg"
                    className="svg-btn-image"
                  /> */}
                  <span className="menu-text">{t("redigera_artiklar")}</span>
                </NavLink>
              </li>
            )}
             { (role_id ===4) && 
            <li>
               <NavLink className='menu-link' exact activeClassName="active"  to="/deadline" >
                  <span className='menu-text opacity-50'>Deadline</span>
               </NavLink>
            </li>
           }
             
            <li>
               <a  className={`only_mobile d-none`}   onClick={onLogout}  >
                  <span className='menu-text opacity-50'>Logout</span>
               </a>
            </li>
           
            {/* <NavLink
              className="menu-link"
              exact
              activeClassName="active"
              to="/change-password"
              title={t("Settings")}
            >
              
              <span className="menu-text">{t("Settings")}</span>
            </NavLink> */}
          </li>
        </ul>
        {role_id === 3 && price_id !== monthlyPlan && (
        <div className="text-center btnbox-block ">
          <a role="button" onClick={goto} className="btngreen-box btn-gradient"><span>Docens för företag</span></a>
        </div>
         )} 
      </aside>
      <div className={`overlay-menu ${sidebarActive ? "active" : ""}`} onClick={toggleSidebar}></div>
    </>
  );
};

export default Header;
