import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAnkiQuestions, getCources } from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const AddNewAnkiQuestion = (prop) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [selectedCourse, setselectedCourse] = useState(null);
  const [allCources, setAllCources] = useState();
  const options = [];
  const onChangeCourse = (selectedOption) => {
    setselectedCourse(selectedOption.value);
  };

  const handleClose = () => {
    setShow(false);
    prop.callbackModal();
  };
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };

  useEffect(() => {
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources(course.payload.data.courses);
      setselectedCourse(course.payload.data.courses[0].id);
    });
    setShow(prop.show);
  }, []);
  const [formValues, setFormValues] = useState([{ question: "", answer: "" }]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { question: "", answer: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    let body = {
      course_id: selectedCourse,
      anki_questions: formValues,
    };
    console.log(body);
    dispatch(addAnkiQuestions(body))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.callbackModal();
      })
      .catch(() => {});
  };
  allCources &&
    allCources.map((list) =>
      options.push({ value: list.id, label: list.title })
    );
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add_New_Question")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="page-wrapper flex-grow-1">
            <Container fluid>
              <div className="max-950 overflow-hidden">
                <Form>
                  <Form.Group className="mb-10 wow fadeInDown">
                    {options && (
                      <Select
                      className="custom-select black-theme"
                      classNamePrefix="myselect"
                        options={options}
                        onChange={onChangeCourse}
                      />
                    )}
                  </Form.Group>
                  {formValues.map((element, index, row) => (
                    <div className="form-inline" key={index}>
                      <Form.Group
                        className="mb-10 wow fadeInDown"
                        controlId="formTitle"
                      >
                        <Form.Control
                          type="text"
                          name="question"
                          placeholder={t("Question")}
                          autoComplete="off"
                          aria-label="Title"
                          value={element.question || ""}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Form.Group>

                      <div className="d-flex align-items-center mb-10 wow fadeInDown">
                        <Form.Group className="flex-grow-1 question-control">
                          <Form.Control
                            type="text"
                            name="answer"
                            placeholder={t("Enter_correct_answer")}
                            autoComplete="off"
                            aria-label="length"
                            value={element.answer}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </Form.Group>
                        <div className="plus-minusbtn d-flex flex-shrink-0">
                          {index + 1 === row.length && (
                            <a
                              role="button"
                              className="btn btn-link"
                              onClick={() => addFormFields()}
                            >
                              <Image
                                src="/assets/images/plus-icon.svg"
                                alt="Plus"
                                width={45}
                                height={45}
                              />
                            </a>
                          )}
                          {index + 1 !== row.length && (
                            <a
                              role="button"
                              className="btn btn-link"
                              onClick={() => removeFormFields(index)}
                            >
                              <Image
                                src="/assets/images/minus-icon.svg"
                                alt="Plus"
                                width={45}
                                height={45}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  <Button
                    variant="primary"
                    type="button"
                    className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                    onClick={handleSubmit}
                  >
                    {" "}
                    {t("Save")}{" "}
                  </Button>
                </Form>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewAnkiQuestion;
