import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import {
  getPodcastProductAdd,
  getPodcastProductEdit,
  getPodcasts,
} from "../../store/api/podcast.api";

const AddNewPodcastProduct = (prop) => {
  const [value, setValue] = useState({
    title: "",
    video_url: "",
    length: 0,
    description: "",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [allPodcasts, setAllPodcasts] = useState();
  const [selectedPodcast, setselectedPodcast] = useState(
    prop.editCourseId
      ? { value: prop.editCourseId, label: prop.editName }
      : null
  );
  const [selectedFile, setSelectedFile] = useState(null);

  const options = [];

  const handleClose = () => {
    setShow(false);
    prop.callbackModal();
  };
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  const onEdit = () => {
    var data = new FormData();
    data.append("podcast_id", selectedPodcast.value);
    data.append("title", value.title);
    data.append("video_url", value.video_url);
    data.append("length", value.length);
    data.append("media_id", prop.editId.toString());

    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    dispatch(getPodcastProductEdit(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          setShow(false);
          prop.callbackModal();
        }
      })
      .catch(() => {});
  };

  // on add product api
  const onAdd = () => {
    var data = new FormData();
    data.append("podcast_id", selectedPodcast.value);
    data.append("title", value.title);
    data.append("video_url", value.video_url);

    data.append("length", value.length);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    dispatch(getPodcastProductAdd(data))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.callbackModal();
      })
      .catch(() => {});
  };

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePodcast = (selectedOption) => {
    setselectedPodcast(selectedOption);
  };
  useEffect(() => {
    dispatch(getPodcasts(dataFilter)).then((podcast) => {
      setAllPodcasts(podcast.payload.data);
    });
    if (prop.editId) {
      setselectedPodcast({ value: prop.editCourseId, label: prop.editName });

      setValue({
        ...value,
        ["title"]: prop.selectedEditData.media_title,
        ["length"]: prop.selectedEditData.length,
        ["video_url"]: prop.selectedEditData.video_url,
      });
    }
    setShow(prop.show);
  }, []);

  allPodcasts &&
    allPodcasts.map((list) =>
      options.push({ value: list.podcast_id, label: list.title })
    );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {prop.editId ? (
              <span>{t("Edit_Product")}</span>
            ) : (
              <span>{t("Add_New_Product")}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="page-wrapper flex-grow-1 ">
            <Container fluid>
              <div className="max-950 overflow-hidden">
                <Form>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder={t("Title")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.title}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-10 wow fadeInDown">
                    {options && (
                      <Select
                      className="custom-select black-theme"
                      classNamePrefix="myselect"
                        defaultValue={selectedPodcast}
                        options={options}
                        onChange={onChangePodcast}
                      />
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formVideoLength"
                  >
                    <Form.Control
                      type="text"
                      name="length"
                      placeholder={t("Video_Length")}
                      autoComplete="off"
                      aria-label="length"
                      value={value.length}
                      onChange={onChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formoriginalVideo"
                  >
                    <Form.Control
                      type="text"
                      name="video_url"
                      placeholder={t("Video_URL")}
                      autoComplete="off"
                      aria-label="video_url"
                      value={value.video_url}
                      onChange={onChange}
                    />
                  </Form.Group>

                  <Row className="gy-10">
                    <Col sm={6}>
                      <div className="mb-10 wow fadeInDown">
                        <input
                          type="file"
                          id="file1"
                          className="visually-hidden"
                          onChange={(e) => setSelectedFile(e.target.files[0])}
                        />
                        <label
                          htmlFor="file1"
                          className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                        >
                          <span className="file-icon">
                            <Image
                              src="/assets/images/Video_file_light.png"
                              alt="Select Image"
                              width="48"
                              height="48"
                            />
                          </span>
                          <span>{t("Select_Image")}</span>
                        </label>
                      </div>
                    </Col>
                  </Row>

                  {!prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onAdd}
                    >
                      {" "}
                      {t("Save")}{" "}
                    </Button>
                  )}
                  {prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onEdit}
                    >
                      {" "}
                      {t("Update")}{" "}
                    </Button>
                  )}
                </Form>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewPodcastProduct;
