import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Header from '../commom/Header2';
import { getCertificate } from '../../store/api/course.api'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
require('moment/locale/sw.js');
const MyCertiListing = () =>{
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { id } = useSelector((state) => state.auth.user);

   const [allCerti, setAllCerti] = useState();
   let dataFilter = {
      "filter":{
         "c.user_id":id
     }
    }
   useEffect(() => {
       dispatch(getCertificate(dataFilter)).then((res) => {
         setAllCerti(res.payload.data);
       });
   }, [])
   return(
      <>
         <Header />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
         
         <div className='d-flex justify-content-between align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown'>
               <h1 className='mb-sm-0 mb-4 fw-semibold text-white h2'>{t('My_Certificates')}</h1>
            </div>

               {/* Starts Table */}
               <div className='wow fadeInDown'>
            
                   
               <Table bordered hover responsive className='text-center'>
                  <thead>
                     <tr>
                        <th>{t('Index')}</th>
                        <th>{t('Course')}</th>
                        <th>{t('Exam_Name')}</th>
                        <th>{t('Created_Date')}</th>
                        <th>{t('Action')}</th>
                     </tr>
                  </thead>
                  <tbody>
                  {
                     
                     allCerti &&  allCerti.length >0 &&  allCerti?.map((item, index) => (
                           <tr key={item.certificate_id}>
                        <td>
                           {index +1}
                        </td>
                        <td>{item.course_title}</td>
                        <td>{item.exam_name}</td>
                       
                        <td>{moment(item?.created_at).format("DD-MMM-YYYY") }</td>
                        <td>
                           <div className='d-flex align-items-center justify-content-center'>
                              <a href={'https://docens.se:3005/'+item.certificate_url} target="_blank" className='btn btn-link icon-eye text-white link-hover action-btn'></a>
                           </div>
                        </td>
                     </tr>
                    ))
                  }
                  
                  </tbody>
               </Table>
             
               {allCerti && allCerti.length===0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                  }
               </div>
               {/* Ends Table */}
            
         </div>
      </>
   )
}

export default MyCertiListing;