import React, { useEffect, useState } from 'react';
import { Table, Image, Button } from 'react-bootstrap';
import Header from '../commom/Header2';
import { useDispatch } from 'react-redux';
import { getList_of_demand, removeCreator } from '../../store/api/course.api';
import AddEditCreator from '../public/AddEditCreator';
import ConfirmPopup from '../commom/ConfirmPopup';
import { useTranslation } from "react-i18next";
import moment from 'moment'
import InnerHeader from './InnerHeader';
import Wrapper from '../component/Wrapper';
require('moment/locale/sw.js');
const DemandList = () => {
   const { t } = useTranslation();
   const [allDemand, setAllDemand] = useState();
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(getList_of_demand()).then((res) => {
         setAllDemand(res.payload.data);
      })
   }, [])
   return (
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={'Demand List'} />
            {/* Starts Table */}
            <Wrapper>
               <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
                  <Table bordered responsive className="text-center tableblock-wrap mb-0">
                     <thead className='top-bordernone'>
                        <tr>
                           <th>{t('Message')}</th>
                           <th>{t('Date')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           allDemand?.map((item, key) => (
                              <tr key={item.id}>
                                 <td>{item?.message}</td>
                                 <td>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>
                  {allDemand && allDemand.length === 0 &&
                     <p className='text-center'>{t('No_records_found')}</p>
                  }
               </div>
               {/* Ends Table */}
            </Wrapper>
         </div>
      </div >
   )
}
export default DemandList;