import React, {useState, useEffect } from 'react';
import { Container, Button, Form,InputGroup,Navbar, Nav, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../commom/Header';
import {login,googleSignUp,facebookSignUp} from '../../store/api/auth.api';
import { clearMessage } from "../../store/api/message";
import {omit} from 'lodash';
import { useNavigate,Link,useParams } from 'react-router-dom';
import {toast} from 'react-toastify';
import { useTranslation } from "react-i18next";
import { addfcmToken } from '../../store/api/course.api';
import { useGoogleLogin } from '@react-oauth/google';

const Login = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { t } = useTranslation();
    const [value, setValue] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);
    const { message } = useSelector((state) => state.message);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [errors, setErrors] = useState({});
    const [passwordType, setPasswordType] = useState("password");
    const { id, option,replay } = useParams();
    useEffect(() => {
      dispatch(clearMessage());
    }, [dispatch]);

      const onAdd = () => {
         setLoading(true);
         dispatch(login(value))
         .unwrap()
         .then(() => {
           if (localStorage.getItem('fcmToken')) {
            let data = {
              fcm_token: localStorage.getItem('fcmToken')
            }
            dispatch(addfcmToken(data))
            .unwrap()
            .then(() => {
              
            })
            .catch(() => {
               
            });
          }
          
          if( localStorage.getItem('is_from_podcast')!=null||localStorage.getItem('is_from_podcast')!=undefined){
            navigate("/private-podcast-topics/"+localStorage.getItem('is_from_podcast'));
            } else if(id && option) {
              if(replay) {
                navigate("/view-webinar/" + id + "/" + option + "/" + replay);
              } else {
                navigate("/view-webinar/" + id + "/" + option);
              }
               
             } else {
               navigate('/oversitk');
            }
         })
         .catch(() => {
            setLoading(false); 
         });
      };
      const validate = (event, name, value) => {
         //A function to validate each input values
         switch (name) {
             case 'email':
                 if(
                     !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                 ){
                     setErrors({
                         ...errors,
                         email: <span> {t(`Enter_a_valid_email_address`)}</span> 
                     })
                 }else{
 
                     let newObj = omit(errors, "email");
                     setErrors(newObj);
                     
                 }
             break;
             
             case 'password':
                 if(
                     !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
                 ){
                     setErrors({
                         ...errors,
                         password: <span> {t(`Password_should`)}</span>
                     })
                 }else{
                     let newObj = omit(errors, "password");
                     setErrors(newObj);
                 }
             break;
             
             default:
                 break;
         }
     }
  const onChange = (e) => {
   validate(e,e.target.name,e.target.value);
  
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const googlelogin = useGoogleLogin({
    onSuccess: tokenResponse => {
       let data ={
          id_token: tokenResponse.access_token,                        
       }
       dispatch(googleSignUp(data))
       .unwrap()
       .then((res) => {
          
          
          if(res.user) {
           if(localStorage.getItem('fromPricingIs_trial') && localStorage.getItem('fromPricingId') && localStorage.getItem('fromPricingPrice')){
              navigate('/subsription/'+localStorage.getItem('fromPricingId')+'/'+localStorage.getItem('fromPricingPrice')+'/'+localStorage.getItem('fromPricingIs_trial'));
           } else {
              navigate('/oversitk');
           }
           
          }
          if (localStorage.getItem('fcmToken')) {
           let data = {
             fcm_token: localStorage.getItem('fcmToken')
           }
           dispatch(addfcmToken(data))
           .unwrap()
           .then(() => {
             
           })
           .catch(() => {
              
           });
         }
        
       })
       .catch(() => {
          
       });
    }


  });
 
  const gotoHome = () => {
    navigate('/');
  }
  return (
     <>
    

      {/* <div className='page-wrapper flex-grow-1 d-flex align-items-center'>
         <Container fluid>
            <Form className="max-387 mx-auto text-center border border-secondary loginpage px-11 pb-11 pt-14 rounded-3 wow fadeInDown" >
               <div className="text-center logintitle border-bottom border-secondary d-inline-block">
                  <h1 className='h2 mb-0 text-white fw-semibold'>{t('SignIn')}</h1>
               </div>
               <Form.Group className="mb-10" controlId="formBasicEmail">
                  <Form.Control type="email" name="email" placeholder={t('Email')} autoComplete="off" aria-label="Email" value={value.email}
                  onChange={onChange} />
                  { errors.email && <span>{errors.email}</span> }
               </Form.Group>

              
               <Form.Group className="mb-10" controlId="formBasicPassword" >
                  
                  <InputGroup className="password-input">
                    <Form.Control 
                    type={passwordType} 
                    name="password" 
                    placeholder={t('Password')}
                    autoComplete="off" 
                    aria-label="Password" 
                    value={value.password} 
                    onChange={onChange}
                    required/>
                    <InputGroup.Text>
                      <i className={`${passwordType!=="password"? "icon-ic-View_hide": "icon-eye" }`} onClick={togglePassword}></i>
                    </InputGroup.Text>
                   
                  </InputGroup>          
                  { errors.password && <span>{errors.password}</span> }        
                </Form.Group>
               <Button variant="primary" type="button" className='btn btn-primary mt-2 min-w-100  new_login_signup min-h-38' onClick={onAdd}> {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>{t('SignIn')}</span></Button>
                <br></br>
               
                        <Button className='btn btn-primary loginBtn loginBtn--google mt-3 min-w-100 min-h-38' onClick={() => googlelogin()}>
                        <span className='mr-34'>{t('Logga_in_med_Google')}</span>
                        </Button>
                        <br></br>
                        
                     <br></br>
                <Link to="/glomt-losenord" > 
                  <span >{t('Forget')}</span>
               </Link>
               
                <p style={{'paddingTop':'10px'}}>{t('Dont_have_an_account')} <Link className='' to="/registrera">{t('Skapa_konto_här')}</Link></p>
               </Form>
         </Container>
        
      </div> */}

<div className="onboarding-page">
           
                <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
          
            <Container fluid>
                <div className="text-white maxw-507 mx-auto">
                <Link to="/" className="back-btnblock mb-8 d-inline-block mobile-mb-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M22.5 27L13.5 18L22.5 9" stroke="white" stroke-width="1.5"/>
              </svg>
                    </Link>
                    <div className="block mb-8">
                        <h1 className="font-32 mb-3 mobile-font-36 mobile-mb-20" onClick={gotoHome} >{t('SignIn')}</h1>
                        <Button variant="light" className="bg-white w-100 google-btn d-flex align-items-center justify-content-center gap-3 py-0"  onClick={() => googlelogin()} >
                            <Image src='/assets/images/google.svg' />Google
                        </Button>
                        <div className="or-text font-20 fw-light d-flex justify-content-between align-items-center mt-4 mobile-mt-20"><span>Eller</span></div>
                    </div>
                    <Form className="mb-8 signup-form d-flex flex-column gap-5 mobile-mb-20">
                        <Form.Group  controlId="formBasicEmail" >
                            <Form.Label className="font-24 fw-medium mb-2">E-post</Form.Label>
                            <div className="form-group">
                            <Form.Control type="email" name="email" placeholder="Exempelvis obama@usa.com..." value={value.email}
                              onChange={onChange} />
                              {/* { errors.email && <span>{errors.email}</span> } */}
                            </div>
                           
                        </Form.Group>
                        <Form.Group  controlId="formBasicEmail" >
                            <Form.Label className="font-24 fw-medium mb-2">Lösenord</Form.Label>
                            <div className="form-group">
                            <Form.Control  type={passwordType} name="password"   placeholder="Tänk på något unikt..."  onChange={onChange} />
                            <Button variant="light" type="button" className='nohover'  onClick={togglePassword}> {passwordType === "password"? "Visa": "Hide"}</Button>
                            </div>
                           
                        </Form.Group>
                        
                      
                    </Form>
                    <div className="d-flex justify-content-between mb-8 mobile-mb-20 align-items-md-start align-items-end">
                        <Button variant="primary" className="" onClick={onAdd}>Logga in</Button>
                        <Link to="/glomt-losenord" className="btn btn-link grey-link p-0 text-white text-decoration-underline font-16 btnlink-whitehover">Glömt ditt lösenord?</Link>
                    </div>
                    <p className="fw-light font-20 mb-8 mobile-font-13  mobile-mb-20">Har du inget konto? <Link to="/registrera" className="fw-semibold text-white btnlink-hover">Registrera</Link></p>
                </div>
            </Container>
        </div>
     </>
  )
}

export default Login;