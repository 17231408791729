import React, { useEffect, useState } from 'react';
import { Table, Image, Button, Row, Col } from 'react-bootstrap';
import Header from '../commom/Header2';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { adminCourseStatistics } from '../../store/api/course.api'
import moment from 'moment'
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DatePicker from "react-datepicker";
import Wrapper from '../component/Wrapper';
import InnerHeader from './InnerHeader';
import TextBlock from '../component/TextBlock';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
require('moment/locale/sw.js');
const AnalysisList = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   Chart.register(CategoryScale);

   const [courseAdminData, setCourseAdminData] = useState();
   const { business_id, role_id } = useSelector((state) => state.auth.user);
   var d = new Date();
   d.setMonth(d.getMonth() - 1);
   const [start_date, setStartDate] = useState(d);
   const [end_date, setEndDate] = useState(new Date());
   let datasum = 0;
   const courseNamesAdmin = [];
   const courseProgressAdmin = [];
   useEffect(() => {

      loadMonthlyData()

   }, [])

   const loadMonthlyData = () => {
      let data = {
         'start_date': start_date,
         'end_date': end_date,
      }
      if (role_id === 1) {
         dispatch(adminCourseStatistics(data)).then((stat) => {
            setCourseAdminData(stat.payload.data)
         });
      }

   }
   if (courseAdminData) {
      courseAdminData.sort((a, b) => (b.watchCount - a.watchCount))
   }


   courseAdminData && courseAdminData.map(list => {
      courseNamesAdmin.push(list.title);
      //setSum(list.watchCount)
      datasum += list.watchCount
   }


   )
   courseAdminData && courseAdminData.map(list =>
      courseProgressAdmin.push(parseFloat((list?.watchCount * 100) / datasum).toFixed(2) ? parseFloat((list?.watchCount * 100) / datasum).toFixed(2) : 0)
   )

   return (
      <div className="innerblock-page">
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title="Analytics" subtitle="" />
            <Wrapper className="text-black">
               <Row className='mb-20px'>
                  <Col md={4}>
                     <div className='position-relative datepicker-block'>
                        <Image src='/assets/images/Calendar_add_light.png' alt='' className='datepicker-icon filter-invert' />
                        <DatePicker className='form-control text-black' placeholderText={t('Set_Deadline')} selected={start_date} onChange={(date) => setStartDate(date)} />
                     </div>
                  </Col>
                  <Col md={4}>
                     <div className='position-relative datepicker-block'>
                        <Image src='/assets/images/Calendar_add_light.png' alt='' className='datepicker-icon filter-invert' />
                        <DatePicker className='form-control text-black' placeholderText={t('Set_Deadline')} selected={end_date} onChange={(date) => setEndDate(date)} />
                     </div>
                  </Col>
                  <Col md={4}>
                     <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={() => loadMonthlyData()} >{t('Search')}</Button>
                  </Col>
               </Row>


               {role_id === 1 &&


                  <div className='bg-white shadow-block rounded-20 mb-20px' style={{ minWidth: "850px" }}>
                     <div className='p-20px title-header'>
                        <TextBlock title={t('Total_Goal_And_Scoring')} />
                     </div>
                     {/* <div className='card-header'>
                        <h4 className='mb-0 fw-medium text-white text-truncate'>{t('Total_Goal_And_Scoring')}</h4>
                     </div> */}
                     <div >

                        <Bar plugins={[ChartDataLabels]}
                           data={{
                              // Name of the variables on x-axies for each bar
                              labels: courseNamesAdmin,
                              datasets: [
                                 {
                                    // Label for bars
                                    label: "Progress of courses",
                                    // Data or value of your each variable
                                    data: courseProgressAdmin,
                                    // Color of each bar
                                    backgroundColor: ["#7015FF"],
                                    // Border color of each bar
                                    borderColor: ["#7015FF"],
                                    borderWidth: 0.1,
                                    barThickness: 35
                                 },
                              ],
                           }}
                           // Height of graph
                           height={400}
                           options={{
                              plugins: {
                                 datalabels: {
                                    display: true,
                                    color: 'white',
                                    font: {
                                       size: 10
                                    },
                                    formatter: function (value, context) {
                                       return value + '%';
                                    }
                                 },
                              },
                              maintainAspectRatio: false,
                              scales: {
                                 yAxes: [
                                    {
                                       ticks: {
                                          min: 10
                                       },
                                    },
                                 ],
                              },
                           }}
                        />
                     </div>
                  </div>


               }

               {/* Starts Table */}
               <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
                  <Table bordered responsive className="text-center tableblock-wrap mb-0">
                     <thead className='top-bordernone'>
                        <tr>


                           <th>{t('Course')}</th>
                           <th>{t('Genomfört')}</th>
                           <th>{t('watched_by_number_of_user')}</th>
                           <th>{t('Populäritet')}</th>
                           <th>{t('Number_of_media_in_course')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           courseAdminData?.map((item, key) => (
                              <tr key={item.id}>
                                 <td>{item?.title}</td>
                                 <td>{item?.overallProgress ? parseFloat(item?.overallProgress).toFixed(2) : 0}% </td>
                                 <td>{item?.watchCount}</td>
                                 <td>{parseFloat((item?.watchCount * 100) / datasum).toFixed(2)} %</td>
                                 <td>{item?.total_media_count}</td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>

               </div>
               {/* Ends Table */}
            </Wrapper>
         </div>
      </div>
   )
}
export default AnalysisList;