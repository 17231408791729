
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const addProductToCart = createAsyncThunk("course/single",
async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(addToCart(data));
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
}
)

export const addQuantity = createAsyncThunk("add/qty",
async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(addQty(data));
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
}
)
export const addCupen = createAsyncThunk("add/cupen",
async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(addCupenOnTotal(data));
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
}
)
export const clearFullcart = createAsyncThunk("clear/cart",
async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(clearCart());
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
}
)
export const removefromCart = createAsyncThunk("remove/qty",
async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(removeQty(data));
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
}
)
export const removefromtotal = createAsyncThunk("remove/total",
async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(reduceTotal(data));
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
}
)


const initialState = {
  'chosenIds': [],
  cart:[],
  total: 0,
  originalTotal:0
};


const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
       let idAlreadyExists = state.chosenIds.indexOf(action.payload.course_id) > -1;
            // make a copy of the existing array
            let chosenIds = state.chosenIds.slice();

            if(!idAlreadyExists) {
              chosenIds.push(action.payload.course_id);        
              return {
                // "spread" the original state object
                ...state,
                // but replace the "chosenIds" field
                chosenIds,
                cart: [...state.cart, action.payload],

            };        
            }     
    },
    addQty: (state, action) => {
      state.total = 0;
      state.originalTotal = 0
       state.cart.map(cart => {
      if (cart.course_id === action.payload.course_id) {
         cart.quantity = action.payload.qty;
      }
      let diPer = 0;
      let qtyTot = 0;
      state.cart.map(cart => {
        qtyTot = qtyTot + Number(cart.quantity);
      });
      // if (qtyTot >= 10 && qtyTot <= 25) {
      //   diPer = 10;
      // } else if (qtyTot >= 26 && qtyTot <= 50) {
      //   diPer = 20;
      // } else if (qtyTot >= 51 && qtyTot <= 99) {
      //   diPer = 40;
      // } 
       
      
      state.originalTotal = state.originalTotal + cart.quantity * cart.r_price;
      let di = (state.originalTotal * diPer)/100;
     
      state.total = state.total + cart.quantity * cart.r_price;
      state.total = state.originalTotal -di;

      return {
        ...state,        
        cart: [...state.cart, action.payload],
    };  
    });
    },
    addCupenOnTotal: (state, action) => {
      let di = (state.originalTotal * action.payload.percent)/100;
    state.cart.map(cart => {
     state.total = state.originalTotal -di;
   return {
     ...state,        
     cart: [...state.cart, action.payload],
    };  
 });
    },
    removeQty: (state, action) => {
      let idAlreadyExists = state.chosenIds.indexOf(action.payload.course_id) > -1;
           // make a copy of the existing array
           const chosenIds = state.chosenIds.filter((item) => item !== action.payload.course_id);
           if(idAlreadyExists) {  
             return {
              ...state,
              chosenIds,
              cart:[...state.cart.filter(item=> item.course_id !== action.payload.course_id)]
           };     
             
           }     
   },
   reduceTotal: (state, action) => {
    state.cart.map(cart => {
      if (cart.course_id === action.payload.course_id) {
        state.total = state.total - cart.quantity * cart.r_price;
        state.originalTotal = state.originalTotal - cart.quantity * cart.r_price;
        return {
          ...state,        
          cart: [...state.cart, action.payload],
         };  
      }
    }); 
 },
    clearCart: () => {
      return initialState;
    },
  },
});

const { reducer, actions } = cartSlice;

export const { addToCart, addQty,clearCart,removeQty,addCupenOnTotal,reduceTotal } = actions
export default reducer;


