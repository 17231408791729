import React, {useEffect} from 'react';
import Header from '../commom/Header2';
import Header1 from '../commom/Header';
import {  useSelector } from 'react-redux';


const PageFound = () =>{
   const { id, role_id } = useSelector((state) => state.auth.user);
   useEffect(() => {
    
    }, [])
   return(
      <>
        { (role_id) && 
         <Header />
       }
        { (!role_id) && 
        <Header1/>
         }
         <div className='page-wrapper flex-grow-1 page-block'>
               <div className="pageContainer">
                     <div className="container container--small container--big__text">
                        <div className="found-text">
                           <h1 className="fw-bold">404 Page Not Found</h1>
                        </div>
                         
                     </div>
               </div> 
         </div>
      </>
      
   )
}

export default PageFound;