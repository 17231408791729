import {Accordion} from "react-bootstrap";



const Accordion_Custome = () => {
    return(
<Accordion defaVanliga frågorultActiveKey="0">
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="0">
                <Accordion.Header>Hur fungerar era kurser?</Accordion.Header>
                <Accordion.Body>
                  Våra kurshållare är alla experter inom sitt område. Deras
                  kursinnehåll är samma som de undervisar/föreläser om vid deras
                  fysiska motsvarigheter. Du tillhandahåller därför samma
                  kunskapsinhämtning som vid en fysisk kurs/föreläsning. Här har
                  du dock flexibiliteten att studera i ditt eget tempo, när det
                  passar dig. Du kan även pausa eller gå tillbaka och repetera
                  så mycket du behöver.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="1">
                <Accordion.Header>Vem kan köpa era kurser?</Accordion.Header>
                <Accordion.Body>
                  Våra kurser riktar sig både till dig som privatperson och till
                  företag. Du kan välja att köpa enstaka kurser eller signa upp
                  dig på en årsprenumeration där du får tillgång till alla
                  kurser. Om du representerar ett företag och vill köpa flera
                  licenser, klicka <a href="/kontakta-oss" className='text-light  link-hover'>här</a> för att kontakta oss.
                  
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="2">
                <Accordion.Header>
                  Vad gör Docens kurser unika?
                </Accordion.Header>
                <Accordion.Body>
                  Docens kursmall är framtagen av en retorikexpert och speciellt
                  utformad för att främja inlärning. Alla kurser är uppbyggda i
                  kortare avsnitt med möjlighet till reflektion. Reflektion är
                  en del av lärandeprocessen och avgörande för utveckling. Det
                  hjälper till att förändra beteenden och förbättra framtida
                  prestationer
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="3">
                <Accordion.Header>
                  Vilka fördelar innebär det för oss som företag att välja
                  Docens?
                </Accordion.Header>
                <Accordion.Body>
                  Hos oss kan du som företag få tillgång till ett
                  administratörsverktyg som gör det enkelt för dig som
                  administratör att tilldela medarbetare kurser, sätta deadlines
                  och följa medarbetarnas utveckling. Genom att veta var i
                  lärandet dina medarbetare befinner sig, kan du enklare
                  uppmuntra och stötta för att förstärka lärandet.
                </Accordion.Body>
              </Accordion.Item>
             
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="5">
                <Accordion.Header>Hur betalar jag?</Accordion.Header>
                <Accordion.Body>
                  Vid köp av enstaka kurser sker betalning direkt och du har
                  sedan tillgång till kursen dygnet runt i 6 månader. Vid
                  årsprenumeration sker betalningen automatiskt månadsvis.
                  Avtalet löper årsvis och förlängs med tolv månader i taget om
                  prenumerationen ej sagts upp senast 7 dagar innan
                  prenumerationsperioden har löpt ut. All betalning sker med
                  konto-/kreditkort via Stripe som är en säker betallösning som
                  används av företag över hela världen.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="6">
                <Accordion.Header>
                  Hur länge har jag tillgång till min kurs/mina kurser?
                </Accordion.Header>
                <Accordion.Body>
                  Vid köp av enstaka kurser finns kursen tillgänglig dygnet runt
                  i 6 månader. Vi prenumeration finns alla kurser tillgängliga
                  så länge prenumerationen är aktiv.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="7">
                <Accordion.Header>
                  Kan jag få ut ett diplom efter genomförd utbildning?
                </Accordion.Header>
                <Accordion.Body>
                  Efter varje slutförd kurs (med något undantag) finns möjlighet
                  att få ut ett diplom. För att få ut detta måste alla avsnitt i
                  kursen vara avslutade och du behöver ha svarat rätt på de
                  kontrollfrågor som tillhör - allt för att säkerställa att du
                  har fått med dig relevant kunskap.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="8">
                <Accordion.Header>
                  Hur gör jag för att avsluta min prenumeration?
                </Accordion.Header>
                <Accordion.Body>
                  Prenumerationen löper antingen månadsvis eller på årsbasis
                  beroende på vilken prenumeration du valt. Om du valt
                  månadsprenumeration löper den på månadsbasis utan
                  uppsägningstid. Vid den 25 dagen i innevarande månad förlängs
                  prenumerationen per automatik med en månad i taget om
                  uppsägning ej har skett. Vid årsprenumeration löper avtalet
                  årsvis och förlängs med tolv månader i taget om
                  prenumerationen ej sagts upp senast 7 dagar innan
                  prenumerationsperioden har löpt ut. Debitering sker månadsvis.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


    );
}

export default Accordion_Custome;