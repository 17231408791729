import React, { Component, useState, useEffect } from "react";
import Header from "../commom/Header";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Footer from "../commom/Footer";
import { getWebinar,getDates } from "../../store/api/podcast.api";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import { sitleft } from "../commom/commonConst";
const Webinar = () => {
  const [allwebinar, setAllWebinar] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deadline, setDeadline] = useState();
  const [hours, setHours] = useState(0);
  const [displayTimer, setDisplayTimer] = useState(true);
  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    }
  };
  useEffect(() => {
    dispatch(getWebinar(dataFilter)).then((course) => {
      setAllWebinar(course?.payload?.data);
    });
    dispatch(getDates()).then((res) => {
      setDeadline(res.payload?.data[0]);
  })
  }, []);
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const onCLickReadMore = (education) => {
    navigate("/webinar-detail/" + education.slug);
  };

  const getTime = (date) => {
    // const time = Date.parse(date) - Date.now();
    let dt = new Date();
      dt.setHours(dt.getHours() + 2);
      const time = Date.parse(date) - dt;
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    if (time <= 0) {
      setDisplayTimer(false);
  } else {
      setDisplayTimer(true)
  }
};

useEffect(() => {
  if (deadline) {
      const interval = setInterval(() => getTime(moment(Number(deadline)).format("YYYY-MM-DD H:mm")), 1000);
      return () => clearInterval(interval);
  }
}, [deadline]);
  return (
    <>
      <div className="d-flex flex-column flex-grow-1 homepage ">
        <Header />
        <div className="page-wrapper flex-grow-1 py-0">
          <section className="banner-wrapper  position-relative bg-wrap">
            <Container>
              <div className="banner-content-area">
                <div className="block font-32 text-center fw-light video-style-font">
                  <h1 className="font-64 fw-semibold mb-md-3 mb-0 mt-md-0 mt-2">Webinar </h1>
                  <p className="font-25 d-md-block d-none">
                  Gratis webinar för din personliga utveckling
                  </p>
                </div>
              </div>
              <div className="banner-video">
                <video
                  src="/assets/video/Background-flow.mp4"
                  autoplay="autoplay"
                  playsinline="playsinline"
                  muted="muted"
                  loop="loop"
                ></video>
              </div>
            </Container>
          </section>

          <section className="pb-100 pb-80-mob education-block mobile-pt-40px">
            <Container fluid>
              <Row className="gy-md-3 position-relative">
                <Col md={5} className="order-md-0 order-1">
                  <div>
                    <span className="text-white d-md-block d-none font-12 letter-spacing fw-semibold  mb-8 mobile-mb-20">
                    Webinar
                    </span>
                    <h2 className="text-white font-40 fw-lighter mb-1">
                    Webinar
                    </h2>
                    <h4 className="text-white fw-medium font-24 mt-1 margin-b-16">
                    Gratis webinar för din personliga utveckling
                    </h4>
                    <div className="text-white block fw-lighter mb-md-8 font-20 mobile-font-13">
                      <p>
                      Att delta i våra webinars ger dig en unik möjlighet att gratis lära dig av våra kunniga lärare. Att delta är enkelt - registrera dig nedan och följ sedan de instruktioner du får via mailen. 
                      </p>
                      <p>
                      Bekvämt och kostnadsfritt kan du sedan ta del av all värdefull kunskap våra experter delar med sig av.
                      </p>
                    </div>
                    <Button variant="primary" className="d-none mobile-mt-20">
                    Säkra din plats
                    </Button>
                  </div>
                </Col>
                <Col md={7} className="order-md-1 order-0">
                  <span className="text-white d-md-none d-block font-12 letter-spacing fw-semibold  mb-8 mobile-mb-20">
                  WEBINAR
                  </span>
                  <div className="ps-md-10 ps-0 mobile-mb-20">
                    <Image
                      src="./assets/images/webinar-main.png"
                      alt=""
                      className="rounded-20 w-100"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section>
            <Container fluid>
              <div className="text-darkblock">
                <div className="text-center mb-40 d-md-block d-none">
                  <h2 className="text-white fw-lighter text-center">Utforska webinar</h2>
                  <svg
                    width="19"
                    height="10"
                    viewBox="0 0 19 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L9.58815 9L18 1" stroke="#FFFFFF" />
                  </svg>
                </div>
                <div className="d-flex flex-column gap-40">
                  {/* Repeated Block start*/}
                  {allwebinar?.length > 0 &&
                allwebinar.map((item) => (
                  <div className="webinar-block-list d-flex w-100 flex-sm-nowrap flex-wrap blockinfo-wrap position-relative">
                    <div className="blockleft-wrap d-flex">
                      <div className="teamimg-wrap flex-shrink-0">
                        <Image
                          src={item?.image}
                          alt="Egna_interna"
                          className="w-100 h-100 img-fit "
                        />
                      </div>
                    </div>
                    <div className="d-md-flex d-block education-mob-right w-md-100 justify-content-between">
                      <div className="teamcontent-wrap">
                        <span className="text-white fw-medium d-block mb-sm-2 mb-1 team-sm-tilte">
                          Webinar
                        </span>
                        <h3 className="text-white font-32 fw-light font-32 mb-sm-2 mb-1 team-main-title">
                        {item?.title}
                        </h3>
                        <div className="text-white block font-20 fw-light blockinfo-content d-md-block d-none  ">
                        <p className=""
                              dangerouslySetInnerHTML={{
                                __html: add3Dots(item?.short_discription, 500),
                              }}
                            ></p>
                        </div>
                      </div>
                      <div className="blockright-wrap fs-16 d-flex flex-column">
                        <div className="mb-auto w-100">
                          {item?.is_no_timer === 0 &&
                          <div className="d-md-block d-flex mb-md-3 mt-md-0  flex-wrap">
                            <span className="text-white fw-medium mb-sm-1 d-block">
                              Datum
                            </span>
                            <span className="text-white d-block  ps-2 ps-md-0">
                            {moment(new Date).format("YYYY-MM-DD")}
                            </span>
                          </div>
                          }
                          {item?.is_no_timer === 0 &&
                          <div className="d-md-block d-flex mb-md-3 mt-md-0 ">
                            <span className="text-white fw-medium mb-sm-1 d-block">
                              Tid
                            </span>
                            <span className="text-white d-block  ps-2 ps-md-0">
                              13:00 / 19:00
                            </span>
                          </div>
                           }
                          <div className="d-md-block d-flex mb-md-3 mt-md-0 ">
                            <span className="text-white fw-medium mb-sm-1 d-block">
                              Längd
                            </span>
                            <span className="text-white d-block  ps-2 ps-md-0">
                            {item?.length} minuter
                            </span>
                          </div>
                          
                          <div className="d-md-block d-flex mb-0 mt-md-0">
                          {displayTimer && item?.is_no_timer === 0 &&
                          <>
                            <span className="text-white fw-medium mb-sm-1 d-block">
                            Platser kvar
                            </span>
                            <span className="text-white d-block  ps-2 ps-md-0">
                            {(item.platser-sitleft(hours,item.platser))}
                            </span>
                            </>
                            }
                          </div>
                        </div>
                        <Button onClick={() => onCLickReadMore(item)}
                          variant="primary"
                          className="mobile-left d-sm-block d-none width-90 w-lg-100  mobile-font-10 py-3"
                        >
                         Läs mer
                        </Button>
                      </div>
                    </div>
                    <Button onClick={() => onCLickReadMore(item)}
                          variant="primary"
                          className="d-sm-none d-block w-100 mobile-font-10 py-3 mt-3"
                        >
                          Läs mer
                        </Button>
                  </div>
                  ))}
                  {/* Repeated Block end*/}
                </div>
              </div>
            </Container>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Webinar;
