import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {  webinarEmail,getDates } from "../../store/api/podcast.api";
import {  WEBINAR_LINK } from "../../services/httpService";
import { toast } from "react-toastify";
import { useNavigate,Link } from 'react-router-dom';
import Select from "react-select";
import moment from 'moment'

const WebinarContactForm = (prop) => {
  let options = [];
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [bothDates,setDates] = useState();
  const [selectedDate,setSelectedDate]= useState();
  const [selectedDateandTime,SetSelectedDateandTime] = useState();
  const [value, setValue] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    setShow(prop.show);
    if (prop.editId) {
      setValue({
        ...value,
        ["name"]: prop.editName,
      });
    }
    dispatch(getDates()).then((res) => {
      setDates(res.payload.data);
      setSelectedDate('Option00');
      SetSelectedDateandTime(moment.utc(Number(res.payload.data[0])).format("YYYY-MM-DD H:mm"));
   })
  }, []);

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onAdd = () => {
   
    if (value?.name.trim() == "") {
      toast.error("Name is required");
    } else if (value.email.trim() == "") {
      toast.error("Email is required");
    } else {
      let data = {
        ...value,
        title:prop.educationData?.title,
        id: prop.educationData?.id,
        slug: prop.educationData?.slug,
        dateandtime:selectedDateandTime,
        option: selectedDate,
        is_no_timer: prop.educationData?.is_no_timer
      };
      dispatch(webinarEmail(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.message);
            setValue({
              email: "",
              name: "",
              phone: "",
              message: "",
            });
            setShow(false)
            prop.callbackModal();
            navigate("/thanks-webinar")
          } else {
            toast.error(res.message);
          }
        })
        .catch(() => {});
    }
  };
  const handleClose = () => {
    setShow(false);
    prop.callbackModal();
  };
  bothDates &&
  bothDates.map((list,index) =>
      options.push({ value: `Option`+ index+1, label: moment.utc(Number(list)).format("YYYY-MM-DD H:mm") })
    );

    const onChangeoption = (selectedOption) => {
      setSelectedDate(selectedOption.value);
      SetSelectedDateandTime(selectedOption.label);
    };
   
  return (
    <>
    <div className="p-sm-8 p-5 py-7 bg-darkblock flex-shrink-0 text-white fw-lighter font-16 width-100 education-form ">
        
       <div className=" flex-grow-1 m-0 p-0 no-bottom-border">
       <div className="d-flex align-items-center border-bottom ">
       <a onClick={handleClose} className="back-btnblock mb-2 d-inline-block mobile-mb-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M22.5 27L13.5 18L22.5 9" stroke="white" stroke-width="1.5"/>
              </svg>
                    </a>
                    <h2 className="text-white font-32 fw-lighter">
                  {prop.educationData?.title}
                </h2>
       </div>
        <section className="d-flex align-items-center ">
            
          <Container fluid>
            <Row className="gy-md-4">
              <Col md={12} className="p-0">
                <Form className="mx-auto text-start">
                  <div className="block text-white mb-10 text-white mobile-mb-20">
                    {/* <h1 className="mb-2 h2 font-40 fw-lighter wow fadeInDown mobile-font-30">
                      {t("Contact_Us")}
                    </h1> */}
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <p className="text-white mb-2 mobile-font-16 font-20 fw-medium wow fadeInDown ">
                      {t("Name")}
                    </p>
                    <Form.Control
                      type="text"
                      placeholder="Robin Hood"
                      aria-label="Name"
                      name="name"
                      value={value.name}
                      onChange={onChange}
                      className="input-font form-input-md"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                  <p className="text-white mb-2  font-20  mobile-font-16 fw-medium wow fadeInDown ">
                      {t("Email_Address")}
                    </p>
                    <Form.Control
                      type="email"
                      placeholder="bill.gates@microsoft.com"
                      aria-label="Email"
                      name="email"
                      value={value.email}
                      onChange={onChange}
                      className="input-font form-input-md"

                    />
                  </Form.Group>

                  {prop.educationData?.is_no_timer === 0 &&
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="font-20  mobile-font-16 fw-medium mb-2 d-block">Välj tid och dag som passar dig bäst</Form.Label>
                            <Select
                                className="custom-select dark-select text-white myselect__placeholder_white"
                                classNamePrefix="myselect"
                                placeholder={'Välj'}
                                options={options}
                                onChange={onChangeoption}
                            />
                        </Form.Group>
                    }
                 
                  <div className="text bg-darkblock bg-transparent border-0" style={{ "text-align": "center" }}>
                    <Button
                      variant="gradient"
                      className="w-100 btn-small rounded-pill mt-md-2 mt-0"
                      type="button"
                      onClick={onAdd}
                    >
                     <span>{t("Submit")}</span> 
                    </Button>
                  </div>
                </Form>
              </Col>
             
            </Row>
          </Container>
        </section>
       
      </div>
      </div>
    </>
  );
};
export default WebinarContactForm;
