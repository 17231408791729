import React, { useEffect, useState } from 'react';
import { Container, Table, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../commom/Header2';
import { useDispatch, useSelector } from 'react-redux';
import { tagList,deleteTag } from '../../store/api/course.api'
import AddEditTag from '../commom/AddEditTag';
import ConfirmPopup from '../commom/ConfirmPopup'
import { useTranslation } from "react-i18next";
import moment from 'moment';
import InnerHeader from '../public/InnerHeader';
import Wrapper from '../component/Wrapper';
require('moment/locale/sw.js');
const TagListing = () => {
   const [show, setShow] = useState(false);
   const { t } = useTranslation();
   const [allProduct, setAllProduct] = useState();
   const dispatch = useDispatch();
   const [editId,setEditId] = useState(null);
   let data ={
      "filter":{
          "e.is_delete":0
      }
  }
   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
    });

    const handleDelete = (id) => {
      setPopup({
        show: true,
        id,
      });
    };
    const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         let data = {
            'id': popup.id
         }
         dispatch(deleteTag(data))
            .unwrap()
            .then((res) => {
               if (res.status === 200) {
                  refresh();
               }
            })
            .catch((e) => {
               console.log(e)
            });
        setPopup({
          show: false,
          id: null,
        });
      }
    };
    const handleDeleteFalse = () => {
      setPopup({
        show: false,
        id: null,
      });
    };
   const handleShow = (id) => {
      setEditId(id)
      setShow(true);
   }
   const callbackModal = () => {
      setShow(false);
      refresh();
   }
   useEffect(() => {
      refresh();
   }, [])
   const refresh = () => {
      dispatch(tagList(data)).then((res) => {
         setAllProduct(res.payload.data);
      })
      
   }
   return (
     
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t('Tag_List')} />
            <Wrapper>
            <div className="d-flex justify-content-end align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown">
               <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={() => handleShow(null)}>{t('Add_New')}</Button>
               {(show && !editId) &&
                  <AddEditTag show={show} editId={null} callbackModal={callbackModal} />
               }
            </div>
            <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
               <Table bordered  responsive className="text-center tableblock-wrap mb-0">
                  <thead className='top-bordernone'>
                     <tr>
                        <th>{t('Index')}</th>
                        <th>{t('Image')}</th>
                        <th>{t('Name')}</th>
                        <th>{t('Date')}</th>
                        <th>{t('Action')}</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        allProduct?.map((product, index) => (
                           <tr key={product?.id}>
                              <td>
                                 <span className='table-img mx-auto'>
                                    {index+1}
                                 </span>
                              </td>
                              <td>
                              <span className='table-img mx-auto'> 
                              <Image src={product.image ? product.image : '/assets/images/placeholder-image.png'} alt='Outlook' width={68} height={68} className="img-fit" />
                              </span>
                              </td>
                              <td>{product?.name}</td>
                              <td>{moment(product?.created_at).format("DD-MMM-YYYY") }</td>
                              <td>
                                 <div className='d-flex align-items-center justify-content-center'>
                                    
                                    <a onClick={() => handleShow(product?.id)} className='btn btn-link icon-ic-edit text-black link-hover action-btn'></a>
                                    {(show && editId === product?.id) &&
                                        <AddEditTag show={show} editId={editId} editName={product?.name}  callbackModal={callbackModal} />
                                     }
                                    <a onClick={() => handleDelete(product.id)} className='btn btn-link icon-ic-trash text-black link-hover action-btn'></a>
                                    {popup.show && (
                                    <ConfirmPopup
                                       handleDeleteTrue={handleDeleteTrue}
                                       handleDeleteFalse={handleDeleteFalse}
                                       message={t('You_sure_you_wanna_delete')}
                                    />
                                    )}
                                 </div>
                              </td>
                           </tr>
                        ))
                     }
                  </tbody>
               </Table>
               {allProduct && allProduct.length===0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                  }
            </div>
            </Wrapper>
            </div>
            </div>
        
      
   )
  
}

export default TagListing;

