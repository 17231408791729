import React, { useRef, useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import "react-intl-tel-input/dist/main.css";
import Header from "../commom/Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const ThanksForEducation = (prop) => {
  let navigate = useNavigate();

  const gotoHome=()=>{
    navigate('/')
  }
  return (
    <>
      <Header />
      <div className="page-wrapper flex-grow-1  ">
        <section>
          <Container fluid>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="text-sm-start text-center max-565">
                <h2 className="font-64 mobile-font-30 fw-semibold pt-8 pt-md-0 lh-1">Tack för din kontakt!</h2>
                <p className="font-24 fw-light">Vi ser fram emot att höras vid! 🥳</p>
                <button type="button" class="btn btn-small px-6 btn-primary rounded-pill mt-2 d-sm-block d-none">Tillbaka till förstasidan</button>
              </div>
              <div className="ms-sm-5 mt-sm-0 mt-3">
                <Image
                  src="./assets/images/thanksforeducation.gif"
                  alt="thanksforeducation"
                  className="rounded-20"
                />
              </div>
              <div className="d-sm-none d-flex justify-content-center mt-3">
                <button type="button" class="btn btn-small px-6 btn-primary rounded-pill" onClick={gotoHome} >Tillbaka till förstasidan</button>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ThanksForEducation;
