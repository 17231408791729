import React, {useState, useEffect } from 'react';
import { Container, Button, Form,Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../commom/Header';
import {forget} from '../../store/api/auth.api';
import { clearMessage } from "../../store/api/message";
import {omit} from 'lodash'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Forget = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const navigate = useNavigate();
    const [value, setValue] = useState({ email: "" });
    const [loading, setLoading] = useState(false);
    const { message } = useSelector((state) => state.message);
    
    const [errors, setErrors] = useState({});
    useEffect(() => {
      dispatch(clearMessage());
      localStorage.clear();
    }, [dispatch]);

      const onAdd = () => {
         setLoading(true);
         dispatch(forget(value))
         .unwrap()
         .then(() => {
           navigate('/');
         })
         .catch(() => {
            setLoading(false);
         });
      };
      const validate = (event, name, value) => {
         //A function to validate each input values
         switch (name) {
             case 'email':
                 if(
                     !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                 ){
                     setErrors({
                         ...errors,
                         email:'Enter a valid email address'
                     })
                 }else{
 
                     let newObj = omit(errors, "email");
                     setErrors(newObj);
                     
                 }
             break;
             
           
             
             default:
                 break;
         }
     }
  const onChange = (e) => {
   validate(e,e.target.name,e.target.value);
  
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
   
  return (
     <>
      
       <div className="onboarding-page">
      <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
          
      
      <Container fluid>
        <div className='d-flex flex-column align-items-center'>
          <div className="text-white ">
          <Link to="/logga-in" className="back-btnblock mb-8 d-inline-block mobile-mb-12">
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                  <path d="M22.5 27L13.5 18L22.5 9" stroke="white" stroke-width="1.5"/>
                </svg>
                      </Link>
            <div className="block mb-8">
              <h1 className="font-32 maxw-507 mobile-font-20 mobile-mb-20">Har du glömt ditt lösenord?</h1>
            </div>
            <Form className="mb-8 signup-form d-flex flex-column gap-5 mobile-mb-20">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="font-24 fw-medium mb-2 mobile-font-14">
                Skriv in din e-post så skickar vi en återställningslänk
                </Form.Label>
                <div className="form-group maxw-507">
                  <Form.Control  placeholder={t('Email')} type="email" name="email" value={value.email}
                    onChange={onChange}
                  />
                </div>
              
              </Form.Group>
            </Form>
            <Button variant="primary" type="button" className='mt-2 ' onClick={onAdd}> {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>{t('Send')}</span></Button>
          </div>
        </div>
      </Container>
    </div>
      {/* <div className='page-wrapper flex-grow-1 d-flex align-items-center'>
         <Container fluid>
            <Form className="max-387 mx-auto text-center border border-secondary loginpage px-11 pb-11 pt-14 rounded-3 wow fadeInDown" >
               <div className="text-center logintitle border-bottom border-secondary d-inline-block">
                  <h1 className='h2 mb-0 text-white fw-semibold'>{t('Forget')}</h1>
               </div>
               <Form.Group className="mb-10" controlId="formBasicEmail">
                  <Form.Control type="email" name="email" placeholder={t('Email')} autoComplete="off" aria-label="Email" value={value.email}
                  onChange={onChange} />
                  { errors.email && <span>{errors.email}</span> }
               </Form.Group>
               <Button variant="primary" type="button" className='mt-2 min-w-195  w-sm-100' onClick={onAdd}> {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>{t('Send')}</span></Button>
                
               </Form>
               {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
     
         </Container>
        
      </div> */}
     </>
  )
}

export default Forget;