import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Image, Modal, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getExamList } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AnswerTest from '../public/AnswerTest'

const SelectTest = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   const [showQuestions,setShowQuestions] = useState(false);
   const [selectedExam, setselectedExam] = useState(null);
   const navigate = useNavigate();

   let data = {
      "filter": {
         "e.is_delete": 0,
         'course_id': prop.courseId
      }
   }
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   }

   useEffect(() => {
      dispatch(getExamList(data)).then((course) => {
         setselectedExam(course.payload?.data[0]?.id)
      })
      setShow(prop.show);
      
   }, [])

   const onAdd = () => {
        // navigate('/answer-test/' + selectedCourse + '/' + prop.courseId);
        setShow(false);
        setShowQuestions(true);
   }
const callbackModal = () => {
   setShow(false);
   setShowQuestions(false);
   prop.callbackModal();
}
   return (
      <>
         <Modal centered show={show} onHide={handleClose} className="modal-innerbox">
            <Modal.Header closeButton>
               <Modal.Title>Slutprov</Modal.Title>
               <span>{prop.courseName}</span>
            </Modal.Header>
            <Modal.Body className="p-20px">
               {selectedExam && 
                  <>
               <div className="font-20 fw-medium text-center mb-20px text-svart">Är du redo?</div>
               <div className="footer-box d-flex justify-content-center gap-20px align-items-center">
                  <Button variant="outline-dark" className="fs-16 btn-blocksec" onClick={handleClose}>
                     Nej jag klickade fel
                  </Button>
                  <Button variant="primary" className="font-16 btn-blocksec" onClick={() => onAdd()}>
                     Bring it on!
                  </Button>
               </div>
               </>
               }
               {!selectedExam &&
                <p className='text-center text-svart'>Det finns inget slutprov för den här kursen</p>
               }
            </Modal.Body>
            
         </Modal>

         {showQuestions  &&
      <AnswerTest show={showQuestions} selectedExam={selectedExam} courseName={prop.courseName} callbackModal={callbackModal} />
        }
      </>
   )
}

export default SelectTest;