import React, { useRef, useEffect, useState } from 'react';
import { Container, Form, Button, Image, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import {addBulk} from '../../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';

const BulkUserCreate = (prop) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }
    const onAdd = () => {
     var data = new FormData();  
      if(selectedFile) {
         data.append('file', selectedFile, selectedFile.name);
      } 
     
      dispatch(addBulk(data))
      .unwrap()
      .then(() => {
         setShow(false);
        prop.callbackModal();
      })
      .catch(() => {
      });
    }
    useEffect(() => {
        setShow(prop.show);
    }, [])
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> {t('Add_Employee')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='usermanagement-modal'>
                    <Form>
                        <div id="holder" className="holder_default">
                            <div className="content">
                                <div className='mb-10 wow fadeInDown'>
                                    <input type="file" id="file1" className='visually-hidden' onChange={(e) => setSelectedFile(e.target.files[0])} />
                                    <label htmlFor="file1" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                                        <span className='file-icon'><Image src="assets/images/file.png" alt="Select Image" width="48" height="48" /></span>
                                        <span>{t('Select_File')}</span>
                                    </label>
                                </div>
                                <p className="subcontent">{t('We_are_accepting_predefined_format_Please_download_sample_file')}
                                    <a download target="_blank" href="assets/sample.xlsx">   &nbsp;{t('mall')}</a> </p>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={!selectedFile} type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onAdd}> {t('Save')} </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default BulkUserCreate;