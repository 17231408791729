//export const yearlyPlanName = 'price_1MArkXCZa0Obi5mZP1D7b56t';
//export const monthlyPlan= 'price_1MAuBiCZa0Obi5mZWE8GIMZn';
 export const yearlyPlanName = "price_1MFdaMCZa0Obi5mZm8ZQuJbx";
 export const monthlyPlan = "price_1MFdYqCZa0Obi5mZTigv09UP";
export const yearlyPrice = 24900;
//export const stripeKey = 'pk_test_51LomgyCZa0Obi5mZDhPo8NFIv7AKBnEWyo7Yti4JDUf9lVoKdxZBhZPZGHjZS42RM05GscvwHcsMtoeRjMBe1IVr003uaZej1C'
 export const stripeKey =
   "pk_live_51LomgyCZa0Obi5mZk71z6uU3w4APdRhXGX8oS96nWNLfgNTYKU0WxOdbvObmmnCLvuFKmRXRm3Rd1foxsE0Iwaaz005qu2hNML";
export const toHoursAndMinutes = (totalMinutes) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  if (hours > 0) {
    return `${hours}h ${padTo2Digits(minutes)}mins`;
  } else {
    return `${padTo2Digits(minutes)}mins`;
  }
};

export const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export const checkSubsDate = (date) => {
  // Create a Date object for the given date
  const givenDate = new Date(date);

  // Get the current date
  const currentDate = new Date();

  // Compare the two dates
  if (givenDate > currentDate) {
    return 1;
  } else {
    return -1;
  }
};
export const SHOW_OFFICE_PACKAGE_ID = 64;
export const SHOW_DESIGN_PACKAGE_ID = 61;
export const DESIGNICANVA = 32;

export const sitleft = (num,TotalSit) => {
  let math = parseInt(TotalSit/24);
 
  switch(num) {
    case 24:
      return 1 * math;
    case 23:
      return 2*math;
    case 22:
      return 3*math;
    case 21:
      return 4*math;
    case 20:
      return 5*math;  
    case 19:
      return 6*math;
    case 18:
      return 7*math;
    case 17:
      return 8*math;
    case 16:
      return 9*math;
    case 15:
      return 10*math;
    case 14:
      return 11*math;  
    case 13:
      return 12*math;
    case 12:
      return 13*math;
    case 11:
      return 14*math;
    case 10:
      return 15*math;
    case 9:
      return 16*math;
    case 8:
      return 17*math; 
    case 7:
      return 18*math;
    case 6:
      return 19*math;
    case 5:
      return 20*math;
    case 4:
      return 21*math;
    case 3:
     return 22*math;
    case 2:
      return 22*math;
    case 1:
      return 23*math;
    case 0:
      return TotalSit-1;
    
  }

};


export const ck_config ={
  toolbar: {
    items: [
      'heading',
      '|',
      'fontSize',
      'fontFamily',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'highlight',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'indent',
      'outdent',
      '|',
      'todoList',
      'link',
      'blockQuote',
      'undo',
      'redo'
    ]
  }
}
