import React, { useState, useRef, useEffect } from "react";

import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import Footer from "../commom/Footer";
import Accordion_Custome from "../commom/Accordion";
import { getSinglePodcast } from "../../store/api/podcast.api";

import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Player from "@vimeo/player";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ck_config } from "../commom/commonConst";
import { toast } from "react-toastify";

const OpenPodcastTopics = (prop) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { podcastid } = useParams();
  const [allItems, setAllItems] = useState({});
  
  const { t } = useTranslation();
  const [mainVideoURL, setMainVideoURL] = useState(null);
  const [mainTitle, setMainTitle] = useState(null);
  const [course_sub_title, setCourse_sub_title] = useState(null);
  const [podcast_id, setPodcastId] = useState(null);
  const [mainDiscription, setMainDiscription] = useState("");
  
  const { id, role_id, business_id, email } = useSelector(
    (state) => state?.auth?.user || {}
  );

  
  const [selectedTab, setSelectedTab] = useState("Avsnitt");
  const [isReadMore, setIsReadMore] = useState(true);
  

  const setMediaPlayerPodcastMainVideo = async () => {
    if (role_id == null) {
      localStorage.setItem("is_from_podcast", podcastid)
      navigate('/logga-in')
      toast.success('Logga in för att se hela avsnittet');
    } else {
      setMainVideoURL(allItems?.media_details[1].video_url)
    }
  };

  useEffect(() => {
    getAllData();
  }, [podcastid]);

  const onSetEdit = (item, index) => {
    setEditId(item.media_id);
    setEditName(mainTitle);
    setEditCourseId(item.course_id);
    setShowEdit(true);
  };

  const closeAdd = () => {
    setShowAdd(false);
    getAllData();
  };
  const getAllData = async () => {
    let req = {
      podcast_id: podcastid,
    };
    if (id) {
      req.user_id = id;
      req.role_id = role_id;
    }
    dispatch(getSinglePodcast(req)).then(async (podcast) => {
      setAllItems(podcast.payload.data);
      localStorage.setItem(
        "mediaIdOnLeftCom",
        podcast.payload.data?.media_details[0]?.id
      );
      setMainTitle(podcast.payload.data?.title);
      setCourse_sub_title(podcast.payload.data?.name_of_guest);
      setMainVideoURL(podcast.payload.data?.media_details[0]?.video_url)  
      setPodcastId(podcast.payload.data.podcast_id);
      setMainDiscription(
        podcast.payload.data?.description != "null"
          ? podcast.payload.data?.description
          : ""
      );
     
    });
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit && isReadMore) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };

  const setTab = (tab) => {
    setSelectedTab(tab);
  };


  return (
    <>
      <div
        className={`page-wrapper flex-grow-1 overflow-hidden header-border-none  mobile-pb-0 ${!role_id ? "is-not-login pt-0" : "pt-100"
          }`}
      >
        {role_id && <Header />}
        {!role_id && <Header1 />}
        <section className="py-80 mobile-pt-15">
          <Container fluid style={{ marginTop: role_id ? 50 : 0 }}>
            <Row className="gy-md-3 flex-md-row flex-column-reverse">
              <Col md={5}>
                <div className="d-flex align-items-center flex-wrap gap-5 d-md-none fw-medium mobile-mt-20 mb-md-5 mb-3">
                  <a
                    role="button"
                    className={`text-white linkblock ${selectedTab === "Avsnitt" ? "active" : ""
                      }`}
                    onClick={() => setTab("Avsnitt")}
                  >
                    {/* Om Podcast */}
                  </a>
                  <a
                    role="button"
                    className={`text-white linkblock ${selectedTab === "Om_kursen" ? "active" : ""
                      }`}
                    onClick={() => setTab("Om_kursen")}
                  >
                    {/* Avsnitt */}
                  </a>
                  <a
                    role="button"
                    className={`text-white linkblock ${selectedTab === "Om_läraren" ? "active" : ""
                      }`}
                    onClick={() => setTab("Om_läraren")}
                  >
                    {/* Om läraren */}
                  </a>
                </div>
                <div
                  style={{ marginLeft: role_id ? 30 : 0 }}
                  className={`tabblock-wrap  ${selectedTab === "Avsnitt" ? "active" : ""
                    }`}
                >
                  <span className="d-md-block d-none font-12 letter-spacing fw-semibold  mb-8">
                    PODCAST
                  </span>
                  <>
                    <h2 className="font-40 fw-lighter mb-1 mobile-font-30">
                      {mainTitle}
                    </h2>

                    <h4 className="fw-medium font-24 mt-1 margin-b-16 mobile-font-16">
                      {course_sub_title}
                    </h4>
                    <div
                      className="block fw-lighter mb-0 maxw-443 fs-20 line-h-small mobile-font-13"
                      dangerouslySetInnerHTML={{
                        __html: add3Dots(mainDiscription, 320),
                      }}
                    ></div>
                    {mainDiscription.length > 320 && (
                      <span onClick={toggleReadMore} className="read-or-hide">
                        {isReadMore ? "Läs mer" : " Läs mindre"}
                      </span>
                    )}
                  </>
                </div>
                <Button
                  style={{ marginLeft: role_id ? 30 : 0, marginTop: 20 }}
                  onClick={setMediaPlayerPodcastMainVideo}
                  className="btn btn-primary mobile-navlink podcast-button"
                >
                  Se hela avsnittet
                </Button>
              </Col>
              <Col md={7}>
                <div className="videoblock-wrap">
                <iframe
                      id="videoIframe"
                      className="videoblock rounded-20 w-100 video-opencourse"
                      src={mainVideoURL}
                      width={400}
                      title="video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="py-80 mobile-pt-0 mobile-pb-60"></section> */}

        {/* <section className="pb-120">
          <Container fluid>
            <h2 className="text-center font-40 fw-lighter mb-9 mobile-mb-20">
              Vanliga frågor
            </h2>
            <Accordion_Custome></Accordion_Custome>
          </Container>
        </section> */}
      </div>
      {/* {showEdit && (
        <AddNewProduct
          show={showEdit}
          editId={editId}
          editCourseId={editCourseId}
          editName={editName}
          callbackModal={callbackModal}
        />
      )} */}
      {/* <Modal show={showCreator} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{allItems?.creator_name} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="usermanagement-modal">
          <p
            className="font-18"
            dangerouslySetInnerHTML={{ __html: allItems?.creator_about }}
          ></p>
        </Modal.Body>
      </Modal> */}
      <Footer />
    </>
  );
};
export default OpenPodcastTopics;
