import React, { Component, useState, useEffect } from "react";
import Header from "../commom/Header";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Footer from "../commom/Footer";
import {  useNavigate } from "react-router-dom";
const ForTeachers = () => {
  const navigate = useNavigate();
  const gotoPage = (page) =>{
    navigate(page)
  }
  return (
    <>
      <div className="d-flex flex-column flex-grow-1 homepage ">
        <Header />
        <div className="page-wrapper flex-grow-1 py-0">

          <section className="banner-wrapper team-banner position-relative bg-wrap">
            <Container>
              <div className="banner-content-area">
                <div className="block font-32 text-center fw-light video-style-font">
                 
                  <h1 className="font-64 fw-semibold pt-8 pt-md-0">Skapa och sälj din <br></br> expertis online </h1>
                  <p className="font-24 mobile-font-16 mx-auto" style={{'max-width': '765px'}}>
                  Digitala kurser är en stressfri affärsmodell som erbjuder en helt annan effektivitet och frihet än fysiska föreläsningar. Tänk om du kunde frigöra dig helt från produktion och marknadsföring, och bara fokusera på din kunskap. 
                  </p>
                  <p  className="font-24 mobile-font-16"> Hos oss får du möjlighet att göra just det.</p>
                </div>
                <div className="rounded-20 d-md-none d-block mt-4 mb-3">
                  <Image
                    src="./assets/images/Egna_interna.png"
                    alt="Egna_interna"
                    className="rounded-20 img-fit center-fit"
                  />
                </div>
                <div className="d-flex flex-sm-row flex-column-reverse justify-content-center mt-sm-5 mt-4 px-sm-0 px-8 gap-5">
                <button type="button" class="btn btn-small d-sm-inline d-block px-6 btn-gradient  rounded-pill" onClick={()=>window.open("https://cal.com/docens")}> <span> Boka möte </span> </button>
              </div>
              </div>
              <div className="banner-video">
                <video
                  src="/assets/video/Background-flow.mp4"
                  autoplay="autoplay"
                  playsinline="playsinline"
                  muted="muted"
                  loop="loop"
                ></video>
              </div>
            </Container>
          </section>

          <section className="team-block-img-wrap d-md-block d-none pb-5">
            <Container>
              <div className="team-block-img">
                <Image
                  src="./assets/images/Egna_interna.png"
                  alt="Egna_interna"
                  className="img-fit center-fit"
                />
              </div>
            </Container>
          </section>

          <section className="content-info-blocks mt-100 pt-0" id="menuid">
         <Container fluid>
            <div className="text-start">
              <h2 className="font-40 fw-light mb-lg-3 mb-0">Fokusera på att förmedla din kunskap, så vi tar hand om resten.</h2>
              <p className="font-25 fw-medium">Det här kan vi hjälpa till med - välj det som passar just dig!</p>
            </div>
            <Row className="g-7">
              <Col md={7}  >
                <div className="dark-card h-100  d-flex flex-column">
                  <div className="mb-auto">
                    <h4 className="fw-medium">För-produktion</h4>
                    <p className="fw-light">I förproduktionen läggs grunden för en framgångsrik digital kurs. För dig som behöver hjälp att planera och utforma din kurs kan vi erbjuda vår unika utbildningsmall, speciellt framtagen av en av Sveriges främsta retorikexperter. Mallen är utformad för att hjälpa dig att strukturera upp din kurs för att på bästa möjliga sätt främja inlärning. <br></br> <br></br> Vi kan även hjälpa till med att förmedla retorikhjälp, visuell identitet, layout, design och mycket mer. </p>
                  </div>
                  
                 
                </div>
              </Col>
              <Col md={5} >
                <div className="dark-card h-100 ">
                  <h4 className="fw-medium">Produktion</h4>
                  <p className="fw-light">Vi tar hand om alla aspekter av filmandet av din digitala kurs. - allt från att fånga dina föreläsningar till skärminspelning vid användning av presentationer och annat digitalt material. Vid filmning i vår studio kan vi säkerställa att varje inspelning är av högsta kvalitet, från belysning och ljud till bildkomposition och redigering. <br></br> <br></br> Vårt mål är att göra produktionsprocessen så smidig och effektiv som möjligt för dig, så att du kan fokusera på att leverera din kunskap. </p>
                 
                </div>
              </Col>
              <Col md={6} >
                <div className="dark-card h-100  d-flex flex-column">
                  <div className="mb-auto">
                    <h4 className="fw-medium">Möjlighet till hosting</h4>
                    <p className="fw-light">Vi erbjuder även hosting till dina digitala kurser på vår plattform. Du kan då erbjuda diplom efter avslutad kurs, samt möjlighet för kursdeltagaren att skriva ner och spara anteckningar från varje kursavsnitt. <br></br> <br></br> För företagskunder finns även möjlighet att erbjuda verktyg; som att tilldela kursen till medarbetare samt att följa upp deras framsteg,.</p>
                  </div>
                 
                </div>
              </Col>
              <Col md={3} >
                <div className="dark-card h-100  d-flex flex-column">
                  <div className="mb-auto">
                    <h4 className="fw-medium">Marknadsföring</h4>
                    <p className="fw-light">Att synas är avgörande för om din digitala kurs ska bli framgångsrik. Vi hjälper dig att synas genom att skapa effektiva webinar funnels och betald trafik. Vi förstår vikten av att nå ut till rätt publik och skräddarsyr en marknadsföringsstrategi som passar just ditt ämne och publik.</p>
                  </div>
                
                </div>
              </Col>
              <Col md={3} >
                <div className="dark-card h-100  d-flex flex-column">
                  <div className="mb-auto">
                    <h4 className="fw-medium">Administration</h4>
                    <p className="fw-light">Om du väljer att använda vår plattform till din kurs erbjuder vi även våra administrativa tjänster, som en säker betalning samt kundsupport. Via vår betaltjänst Stripe kan vi säkerställa att alla transaktioner för din kurs sker på ett tryggt och pålitligt sätt för kunden.</p>
                  </div>
                 
                </div>
              </Col>
            </Row>
          </Container>
        </section>

          <section className="mb-4">
            <Container>
              <div className="text-center max-565 mx-auto mt-120">
                <h2 className="font-64 mobile-font-30 fw-semibold">Vill du veta mer?</h2>
                <p className="font-24 fw-light">Kontakta oss om du vill veta mer om hur vi kan hjälpa dig att skapa den där kursen du alltid drömt om 😃</p>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <a href="https://cal.com/docens" target="_blank" class="btn font-20 mobile-font-16 btn-gradient rounded-pill"><span> Boka möte </span></a>
                </div>
              </div>
            </Container>
          </section>

        </div>
        <Footer />
      </div>
    </>
  );
};
export default ForTeachers;
