import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Header from "../commom/Header2";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import PrivateEducationList from '../commom/PrivateEducationList'
import Wrapper from "../component/Wrapper";
import PrivateWebinarListList from "../commom/PrivateWebinarListList";
const PrivateWebinar = () => {
  return (
    <div
      className={` innerblock-page d-lg-flex flex-grow-1`}
    >
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block">
        <InnerHeader title={"Webinar"} subtitle="Gratis kompetensutveckling för dig" />
        <Wrapper className={'pb-40px'}>
          <div className="shadow-block rounded-20 bg-white mb-lg-80px mb-20px">
            <div className="p-lg-20px p-16px title-header">
              <TextBlock title={"Webinar"} subtitle={"Gratis kompetensutveckling för dig"} />
            </div>
            <div className="p-lg-20px p-16px">
              <Row className="flex-md-row flex-column-reverse">
                <Col md={6}>
                  <div className="text-svart fw-light font-20">
                    <h5 className="fs-20 fw-medium mb-lg-20px mb-1">Gratis kompetensutveckling med våra experter</h5>
                    <p  className="mobile-font-14 mb-0"> Att delta i våra webinars ger dig en unik möjlighet att gratis lära dig av våra kunniga lärare. Att delta är enkelt - registrera dig nedan och följ sedan de instruktioner du får via mailen.
<br></br><br></br>
Bekvämt och kostnadsfritt kan du sedan ta del av all värdefull kunskap våra experter delar med sig av.</p>
                  
                  </div>
                </Col>
                <Col md={6}>
                  <div className="ps-md-10 ps-0 mb-md-0 mb-16px mobile-img-block">
                    <Image
                      src="./assets/images/webinar-main.png"
                      alt=""
                      className="rounded-20 w-100 img-fit"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="shadow-block rounded-20 bg-white overflow-hidden ">
            <div className="p-lg-20px p-16px title-header mb-md-0 mb-2">
              <TextBlock title={"Våra webinar"} subtitle={"Utbud"} />
            </div>
            <PrivateWebinarListList />
          </div>

        </Wrapper>
      </div>
    </div>
  )
}

export default PrivateWebinar;