import React, { useRef, useEffect } from "react";
import Header from "../commom/Header";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../commom/Footer";
import Slider from "react-slick";
import Cal, { getCalApi } from "@calcom/embed-react";


const Contactnew = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (isLoggedIn) {
    navigate("/my-course-details", { replace: true });
  }

  // Add class to body on mount and clean up on unmount
  useEffect(() => {
    document.body.classList.add('full-bg-black'); 
    return () => {
      document.body.classList.remove('full-bg-black'); 
    };
  }, []);

  const settings1 = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centermode: true,
    centerPadding: "0px",
    autoplay: true,
  };
  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 6,
    pauseOnHover: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    centerMode: true,
  };

  const iframeRef = useRef(null);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ "namespace": "mote" });
      cal("ui", { "styles": { "branding": { "brandColor": "#000000" } }, "hideEventTypeDetails": false, "layout": "month_view" });
    })();
  }, [])

  return (

    <div className="d-flex flex-column flex-grow-1 homepage contact-bg">
      <Header />
      <div className="page-wrapper flex-grow-1 py-0">

        <section className="banner-wrapper home-banner position-relative bg-wrap home-banner-contact">
          <Container>
            <div className="banner-content-area" style={{ maxWidth: "897px" }}>
              <div className="block font-32 text-center fw-light video-style-font">
                <h1 className="font-64 mb-md-3 ">
                  Funderar du kring er <br className="d-md-block d-none" /> interna utbildning?
                </h1>
                <p className="font-25">
                  Vi ser fram emot att hjälpa dig med stora problem som små. <br className="d-md-block d-none" /> Välj en tid som passar dig!
                </p>
              </div>

            </div>

            <div className="banner-video">
              <video src="/assets/video/Background-flow.mp4" autoplay="autoplay" playsinline="playsinline" muted="muted"
                loop="loop"></video>
            </div>
          </Container>
        </section>
        <section className="calendar-iframe">
          <Container>
            <div className='video-wrapper wow fadeInDown'>
              <Cal namespace="mote"
                calLink="docens/mote"
                style={{ width: "100%", height: "100%", overflow: "scroll" }}
                config={{ layout: 'month_view' }}
              />
              {/* <iframe src="https://cal.com/docens"  ref={iframeRef}  width="1600" height="900" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
            </div>
          </Container>
        </section>

        <section className="Booky-slider ">
          <Container>
            <div className="Booky-slider-itembox">
              <Slider {...settings1}>
                <div className="Booky-slider-item">
                  <span className="Booky-slider-logo"> booky</span>
                  <p className="Booky-slider-text">
                    Vi tog hjälp av Docens för att skapa en intern
                    utbildningsfilm till en av våra kunder. Docens levererade på
                    topp från start till slut. De var med på förmöte, de skötte
                    hela produktionen och inkluderade vår kunds grafiska profil
                    i redigeringen. Funderar ni på att skapa utbildningsmaterial
                    eller utbildningsfilmer så tycker jag verkligen att ni ska
                    kontakta Docens.
                  </p>
                  <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-6">
                    <Image width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                      className="rounded-3" />
                    <div>
                      <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                        Charlotte Dunhoff
                      </span>
                      <span className="Booky-slider-profileinfo-text2 d-block">
                        Grundare & VD
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Booky-slider-item">
                  <span className="Booky-slider-logo"> booky</span>
                  <p className="Booky-slider-text">
                    Vi tog hjälp av Docens för att skapa en intern
                    utbildningsfilm till en av våra kunder. Docens levererade på
                    topp från start till slut. De var med på förmöte, de skötte
                    hela produktionen och inkluderade vår kunds grafiska profil
                    i redigeringen. Funderar ni på att skapa utbildningsmaterial
                    eller utbildningsfilmer så tycker jag verkligen att ni ska
                    kontakta Docens.
                  </p>
                  <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-6">
                    <Image width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                      className="rounded-3" />
                    <div>
                      <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                        Charlotte Dunhoff
                      </span>
                      <span className="Booky-slider-profileinfo-text2 d-block">
                        Grundare & VD
                      </span>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </Container>
        </section>

        {/* <section className="pb-120 accordion-wrap top-index">
          <Container fluid>
            <h2 className="text-center font-40 fw-lighter mb-9 mobile-mb-20">
              Vanliga frågor
            </h2>
            <Accordion defaVanliga frågorultActiveKey="0">
        <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="0">
            <Accordion.Header>Hur kan vi som företag få hjälp att skapa en intern kurs eller utbildningsfilm??</Accordion.Header>
            <Accordion.Body>
            Om ni vill skapa en intern utbildningsfilm eller kurs kan vi hjälpa er med allt ifrån att sätta upp en struktur, hitta kurshållare, filmning och redigering till uppladdning. Vi kan hjälpa er med ett utav momenten, eller allihop. Kontakta oss så diskuterar vi fram ett upplägg som passar just er.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="1">
            <Accordion.Header>
            Om vi tar hjälp av er att skapa en intern kurs/utbildningsfilm, måste den ligga på Docens plattform?
            </Accordion.Header>
            <Accordion.Body>
            Ni bestämmer själva om ni vill ha er kurs/utbildningsfilm på Docens plattform och ta del utav de verktyg som finns där, eller om ni vill ladda upp dem internt.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="2">
            <Accordion.Header>Hur kan vi som företag få hjälp att skapa en intern kurs eller utbildningsfilm??</Accordion.Header>
            <Accordion.Body>
            Om ni vill skapa en intern utbildningsfilm eller kurs kan vi hjälpa er med allt ifrån att sätta upp en struktur, hitta kurshållare, filmning och redigering till uppladdning. Vi kan hjälpa er med ett utav momenten, eller allihop. Kontakta oss så diskuterar vi fram ett upplägg som passar just er.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="3">
            <Accordion.Header>
             Är det för mig som privatperson eller företag?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet consectetur. Mauris adipiscing
              pellentesque est non enim eu id massa. Sit semper adipiscing
              enim cras non porta magna donec. Consectetur fermentum sit
              diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et
              blandit id purus enim auctor consectetur risus eros.
              Pellentesque sodales ornare malesuada tellus.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="4">
            <Accordion.Header>Vem är läraren?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet consectetur. Mauris adipiscing
              pellentesque est non enim eu id massa. Sit semper adipiscing
              enim cras non porta magna donec. Consectetur fermentum sit
              diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et
              blandit id purus enim auctor consectetur risus eros.
              Pellentesque sodales ornare malesuada tellus.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="5">
            <Accordion.Header>
              Vad händer om jag missar ett tillfälle?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet consectetur. Mauris adipiscing
              pellentesque est non enim eu id massa. Sit semper adipiscing
              enim cras non porta magna donec. Consectetur fermentum sit
              diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et
              blandit id purus enim auctor consectetur risus eros.
              Pellentesque sodales ornare malesuada tellus.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
            
          </Container>
        </section> */}

      </div>
      <div className="top-index">
        <Footer />
      </div>

    </div>
  );
};

export default Contactnew;