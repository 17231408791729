import { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCheckoutUrl,
  getPlans,
  getSinglePackage,
  getSubsPlan,
} from "../../store/api/course.api";
import {
  toHoursAndMinutes,
  yearlyPlanName,
  monthlyPlan,
  checkSubsDate,
} from "./commonConst";
import Footer from "./Footer";
import Header from '../commom/Header2';
import Header1 from '../commom/Header';
import Accordion_Custome from "./Accordion";

const Package = () => {
  const { packageid } = useParams();
  const dispatch = useDispatch();
  const [packageDetails, setPackageDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState('Om_Paketet');
  const { role_id, id, business_id, email } = useSelector((state) => state?.auth?.user || {});
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedTabDesk, setTabDesk] = useState('deskAvsnitt')
  const { subscription } = useSelector((state) => state.auth.user || {});
  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = () => {
    let req = { package_id: packageid, user_id: id || null };

    dispatch(getSinglePackage(req)).then((packages) => {
      setPackageDetails(packages.payload.data);
    });
    dispatch(getSubsPlan())
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          let newArr = [];

          res.data
            .map((data) => {
              if (data.price_id == yearlyPlanName) {
                newArr.push(data);
              }
              if (data.price_id == monthlyPlan) {
                setSelectedPlan(data.price / 100);
                newArr.push(data);
              }
            })
            .filter((data) => (data = data));
        }
      })
      .catch(() => { });
  };
  const generateCheckoutURL = () => {
    // const newCOurseArr=packageDetails?.courses.map((course)=>{
    //   newCOurseArr.push(...newCOurseArr,{course_id:course.id)
    // })
    const generateCheckoutURLObj = {
      item_id: packageDetails?.package_id,
      item_name: packageDetails?.title,
      s_price: packageDetails?.s_price * 100,
      image: packageDetails?.image,
      user_id: id ? id : null, // if not logged in pass null
      email: email ? email : null, // if not logged in pass null
      role_id: role_id,
      business_id: business_id ? business_id : null,
      currency: "SEK",
      is_course: false,
      mode: "payment", // payment or subscription
      // "interval":"month",//day, week, month or year
      // "price_id":"plan_Mu6OExFSZnJuFj",
      quantity: 1,
      // "courses": []
      courses: [],
      is_trial:false,
      is_year_wise: false
    };
    dispatch(getCheckoutUrl(generateCheckoutURLObj)).then((packages) => {
      if (packages.payload.status == 200) {
        window.location.replace(packages.payload.data.cs_url);
      }
      // setPackageDetails(packages.payload.data);
    });
  };
  const generateCheckoutURLSubscription = (plan) => {
    const generateCheckoutURLObj = {
      item_id: packageDetails?.package_id,
      item_name: packageDetails?.title,
      s_price: packageDetails?.s_price,
      image: packageDetails?.image,
      user_id: id ? id : null, // if not logged in pass null
      email: email ? email : null, // if not logged in pass null
      role_id: role_id,
      business_id: business_id ? business_id : null,
      currency: "SEK",
      is_course: false,
      mode: "subscription", // payment or subscription
      interval: "month", //day, week, month or year
      price_id: plan == "month" ? monthlyPlan : yearlyPlanName,
      is_trial: plan == "month" ? true : false,
      quantity: 1,
      courses: [],
      is_year_wise: false
    };
    dispatch(getCheckoutUrl(generateCheckoutURLObj)).then((packages) => {
      if (packages.payload.status == 200) {
        window.location.replace(packages.payload.data.cs_url);
      }
      // setPackageDetails(packages.payload.data);
    });
    // const newCOurseArr=packageDetails?.courses.map((course)=>{
    //   newCOurseArr.push(...newCOurseArr,{course_id:course.id)
    // })
  };
  const setTab = (tab) => {
    setSelectedTab(tab);
  }
  const BuySection = () => {
    return (<>
      {packageDetails.is_purchased !== true &&
        checkSubsDate(subscription?.trial_end) == -1 &&
        checkSubsDate(subscription?.expire_at) == -1 && (
          <div className="d-flex flex-column gap-12 mobile-width-full">
            <div className="bg-darkblock p-xl-10 p-6  flex-shrink-0 text-white fw-lighter font-16 dark h-100 radius-20  greysec-block">
              <h2 className="text-white font-32 fw-lighter border-bottom border-white pb-4 mb-3 mobile-font-20">
                Kurspaket
              </h2>
              <div className="border-white border-bottom pb-4 mb-3">
                <h5 className="fw-medium font-20 mb-3">
                  Ingår i paketet
                </h5>
                <div className="d-flex flex-column gap-5">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: packageDetails?.included_in_package,
                    }}
                  ></p>

                </div>
              </div>
              <div className="border-bottom-none">
                <h5 className="fw-medium font-20 mb-3">
                  Vem passar kursen för?
                </h5>
                <p

                  dangerouslySetInnerHTML={{
                    __html: packageDetails?.suitable_for,
                  }}
                ></p>


              </div>
              <a
                onClick={generateCheckoutURL}
                className="btn btn-outline-light fw-light font-20 d-flex align-items-center justify-content-between gap-4  mt-10"
              >
                <span className="mobile-font-13">Köp hela paketet</span>
                <span className="fw-medium font-24">
                  {packageDetails?.s_price
                    ? packageDetails?.s_price
                    : packageDetails?.r_price}{" "}
                  kr
                </span>
              </a>
              {/* <a
                onClick={() => generateCheckoutURLSubscription("month")}
                className="btn btn-outline-light fw-light font-20 d-flex align-items-center justify-content-between gap-4 mt-16px"
              >
                <span className="mobile-font-13">Se alla kurser</span>
                <span className="fw-medium font-24">
                  {selectedPlan} kr/mån
                </span>
              </a> */}
              {/* <Button
                onClick={() => generateCheckoutURLSubscription("month")}
                variant=""
                className="d-flex justify-content-between mt-3  gap-4 w-100 align-items-center btn-gradient mobile-minh53 btn"
              >
                <span className="fw-light font-20 mobile-font-13">
                  Se alla kurser
                </span>
                <span className="fw-medium mobile-font-16">gratis i 30 dagar</span>
              </Button>
              <p className="year_P border-0 mb-0 pb-0"> Därefter 499 kr/mån - avbryt när du vill</p> */}
            </div>
          </div>
        )}

    </>)
  };
  return (
    <div className="header-border-none">
      {(role_id) &&
        <Header />
      }
      {(!role_id) &&
        <Header1 />
      }
      <div className={"main-wrapper text-lightblock " + (role_id ? 'pt-100 mobile-pt-15' : 'pt-14 mobile-pt-15')}>
        <Container fluid>

          <section className="mobile-border-none mobile-pb-0">
            <Row className="gy-md-3 flex-column-reverse flex-md-row">
              <Col md={5}>
                <div className="tab-courses d-flex align-items-center flex-wrap gap-5 d-md-none fw-medium mobile-mt-20 mb-md-5 mb-3">
                  <a role="button" className={`linkblock ${selectedTab === 'Om_Paketet' ? "active" : ""}`} onClick={() => setTab('Om_Paketet')}>Om Paketet</a>
                  <a role="button" className={`linkblock ${selectedTab === 'Kurser_som_ingår' ? "active" : ""}`} onClick={() => setTab('Kurser_som_ingår')}>Kurser som ingår</a>
                  <a role="button" className={`linkblock ${selectedTab === 'Mer_info' ? "active" : ""}`} onClick={() => setTab('Mer_info')}>Mer info</a>
                </div>
                <div className={`tabblock-wrap ${selectedTab === 'Om_Paketet' ? "active" : ""}`}>
                  <span className="d-md-block d-none font-12 letter-spacing fw-semibold  mb-8">
                    PAKET
                  </span>
                  <h2 className="font-40 fw-lighter mb-1 mobile-font-30">
                    {packageDetails?.title}
                  </h2>
                  <h4 className="fw-medium font-24 mt-1 margin-b-16 mobile-font-16">
                    {packageDetails?.subtitle}
                  </h4>
                  <p
                    className="block mobile-mb-0"
                    dangerouslySetInnerHTML={{
                      __html: packageDetails?.content,
                    }}
                  ></p>
                  <div className="pt-5 only_mobile d-none">
                  <BuySection />
                  </div>
                 
                </div>
              </Col>
              <Col md={7}>
                <div className="ps-md-11">
                  <Image
                    src={packageDetails?.image}
                    alt=""
                    className="rounded-20 w-100"
                  />
                </div>
              </Col>
            </Row>
          </section>

          <div className="d-flex flex-column">
            <div className={` block text-lightblock pt-100 pe-md-5  tabblock-wrap-desk tab-courses ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`}>
              {/* <div className="mobile-pt-0 hide_in_mobile">
                      <div className="d-flex align-items-center flex-wrap gap-5  fw-medium mobile-mt-20 mb-md-5 mb-3 hide_in_mobile">
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`} onClick={() => setTabDesk('deskAvsnitt')}>Avsnitt</a>
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`} onClick={() => setTabDesk('deskOm_läraren')}>Om läraren</a>
                      </div>
                    </div> */}
              <section className="pb-100  mobile-pt-0 ">
                <Row className="d-flex flex-wrap">
                  <Col xl={8} sm={7} className="pe-xl-12">
                    <div className="d-flex align-items-center flex-wrap gap-5  fw-medium mobile-mt-20 mb-md-5 mb-3 hide_in_mobile">
                      <a role="button" className={`linkblock ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`} onClick={() => setTabDesk('deskAvsnitt')}>Beskrivning</a>
                      <a role="button" className={`linkblock ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`} onClick={() => setTabDesk('deskOm_läraren')}>Ingår i paket</a>
                    </div>

                    <div className={` block text-lightblock font-20 mobile-space-0 mobile-font-13 tabblock-wrap  ${selectedTab === 'Mer_info' ? "active" : ""}`}  >
                      <p
                        className="block"
                        dangerouslySetInnerHTML={{
                          __html: packageDetails?.description,
                        }}
                      ></p>
                    </div>
                  </Col>
                  <Col xl={4} md={5} className="ps-xl-0 hide_in_mobile pb-5">
                    <BuySection />
                  </Col>
                </Row>

              </section>
            </div>
            <div className={`block text-lightblock pt-100 pe-md-5  tabblock-wrap-desk tab-courses ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`}>
              {/* <div className="mobile-pt-0 hide_in_mobile">
                <div className="d-flex align-items-center flex-wrap gap-5  fw-medium mobile-mt-20 mb-md-5 mb-3 hide_in_mobile">
                  <a role="button" className={`linkblock ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`} onClick={() => setTabDesk('deskAvsnitt')}>Avsnitt</a>
                  <a role="button" className={`linkblock ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`} onClick={() => setTabDesk('deskOm_läraren')}>Om läraren</a>
                </div>
              </div> */}
              <section className="order-md-1 order-0">
                <div className={`tabblock-wrap  ${selectedTab === 'Kurser_som_ingår' ? "active" : ""}`}>
                  <Row className="d-flex flex-wrap">
                    <Col xl={8} sm={7} className="pe-xl-12">
                      <div className="d-flex align-items-center flex-wrap gap-5  fw-medium mobile-mt-20 mb-md-5 mb-3 hide_in_mobile">
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskAvsnitt' ? "active" : ""}`} onClick={() => setTabDesk('deskAvsnitt')}>Beskrivning</a>
                        <a role="button" className={`linkblock ${selectedTabDesk === 'deskOm_läraren' ? "active" : ""}`} onClick={() => setTabDesk('deskOm_läraren')}>Ingår i paket</a>
                      </div>
                      <Row className="g-md-7 g-5 mb-3">
                        {packageDetails?.courses &&
                          packageDetails?.courses.map((course) => {
                            return (
                              <Col xs={6}>
                                <a
                                  class="getstart-block getstart-block-new position-relative d-flex flex-column feature-box"
                                  href={role_id ? `/private-open-course-topics/${course.id}` : `/open-course-topics/${course.id}`}
                                >
                                  <div className="get-started-img">
                                    <Image
                                      src={course.image}
                                      alt="Image"
                                      width="526"
                                      height="348"
                                      className="img-fit feature-img"
                                    />
                                  </div>
                                  <div className="img-content text-white d-flex align-items-start justify-content-end">
                                    <div className="bottom-text text-md-start text-center">
                                      <h5 className="mb-1 fw-light cursor_class">
                                        {course?.title}
                                      </h5>
                                      <div className="d-flex  flex-md-row flex-column">
                                        <span className="fw-semibold cursor_class  mb-0 fs-16 mr-5">
                                          {course?.auther_details?.name}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                    <Col xl={4} md={5} className="ps-xl-0 pb-5">
                      <BuySection />
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
          </div>

          <section className="pt-100 mobile-pt-0">
            <h2 className="text-center font-40 fw-lighter mb-9 mobile-mb-20">
              Vanliga frågor
            </h2>
            <Accordion_Custome></Accordion_Custome>
          </section>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Package;
