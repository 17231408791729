import React, { useEffect, useState } from "react";
import { Table, Image, Button, Pagination } from "react-bootstrap";
import Header from "../commom/Header2";
import { useDispatch } from "react-redux";
import ConfirmPopup from "../commom/ConfirmPopup";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
    getWebinar
} from "../../store/api/podcast.api";
import AddNewPodcastProduct from "./AddNewPodcastProduct";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import { useNavigate } from "react-router-dom";

require("moment/locale/sw.js");

const WebinarHistory = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [allEducation, setAllEducation] = useState();
    let dataFilter = {
        filter: {
          is_delete: 0
        }
      };
    
      useEffect(() => {
        dispatch(getWebinar(dataFilter)).then((course) => {
          setAllEducation(course.payload.data);
        });
      }, []);


      const add3Dots = (string, limit) => {
        if (string) {
          var dots = "...";
          if (string?.length > limit) {
            string = string.substring(0, limit) + dots;
          }
        }
        return string != "null" ? string : "";
      };
    
      
      const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
      });
    
      
      const handleDelete = (id) => {
        setPopup({
          show: true,
          id,
        });
      };
      const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
          let data = {
            is_delete: 1,
            media_id: popup.id,
          };
          dispatch(getPodcastProductRemove(data))
            .unwrap()
            .then((res) => {
              if (res.status === 200) {
                refresh();
              }
            })
            .catch((e) => {
              console.log(e);
            });
    
          setPopup({
            show: false,
            id: null,
          });
        }
      };
      const handleDeleteFalse = () => {
        setPopup({
          show: false,
          id: null,
        });
      };

      const gotosignups = (id) => {
        navigate('/webinar-signups/'+id)
      }
      const openchatWindow = (id) =>{
        navigate('/webinar-chats/'+id)
      }
      const openWatcher = (id,type) =>{
        navigate('/webinar-watcher/'+id+'/'+type)
      }
      const openReadmore = (id) =>{
        navigate('/webinar-readmore/'+id)
      }
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block product-listpage">
        <InnerHeader title={t("Skapa webinar")} />
        <Wrapper>
        
          {/* Starts Table */}
          <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
            <Table bordered responsive className="text-center tableblock-wrap mb-0">
              <thead className='top-bordernone'>
                <tr>
                 
                  <th>{t("webinar")}</th>
                  <th>{t("Sign up")}</th>
                  <th>{t("Quastions")}</th>
                  <th>{t("Watched")}</th>
                  <th>{t("Completed")}</th>
                  <th>{t("Started replay")}</th>
                  <th>{t("read more")}</th>
                 
                </tr>
              </thead>
              <tbody>
                {allEducation?.map((product, key) => (
                  <tr key={product?.id}>
                  
                    <td>{product?.title}</td>
                    <td className="cursor_class" onClick={()=>gotosignups(product.id)}>{product?.sign_up}</td>
                    <td className="cursor_class" onClick={()=>openchatWindow(product.id)}>{product?.chatCount}</td>
                    <td className="cursor_class" onClick={()=>openWatcher(product.id,1)}>{product?.watchCount?product?.watchCount:0}</td>
                    <td className="cursor_class" onClick={()=>openWatcher(product.id,2)}>{product?.watchCountComplited?product?.watchCountComplited:0}</td>
                    <td className="cursor_class" onClick={()=>openWatcher(product.id,3)}>{product?.replyCount?product?.replyCount:0}</td>
                    <td className="cursor_class" onClick={()=>openReadmore(product.id)}>{product?.readMoreCount?product?.readMoreCount:0}</td>
                    
                  </tr>
                ))}
              </tbody>
            </Table>
            {allEducation && allEducation?.length === 0 && (
              <p className="text-center mt-3">{t("No_records_found")}</p>
            )}
          </div>
         
        </Wrapper>
      </div>
    </div>
  );
};

export default WebinarHistory;
