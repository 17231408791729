import InnerHeader from "../public/InnerHeader";
import TextBlock from "../component/TextBlock";
import Header from "../commom/Header2";
import Wrapper from "../component/Wrapper";
import { Container } from "react-bootstrap";

const goback = () => {

}
const DiplomTip = () => {

    return (
        <div className="innerblock-page flex-grow-1">
            <Header headerShow={false} />
            <div className="page-wrapper flex-grow-1 page-block overflow-hidden">
            
                <InnerHeader title="Diplom" subtitle="Hämta ut ditt diplom" backButton={true} backButtonURL="/oversitk" />
                <Wrapper className="pb-20px">
                    <div className="shadow-block rounded-20 bg-white">
                        <div className="title-header p-20px">
                            <TextBlock title="Diplom" subtitle="Efter avslutad kurs kan du hämta ut ett diplom" />
                        </div>
                        <div className="p-20px">
                            <p> Efter att ha framgångsrikt genomfört en kurs finns det möjlighet att erhålla ett diplom. För att få ut detta krävs att du har sett alla avsnitt i kursen samt svarat rätt på de kontrollfrågor som tillhör – allt för att säkerställa att du har fått med dig relevant kunskap.</p>
                            </div>
                    </div>
                </Wrapper>
            </div>
        </div>
    );
};

export default DiplomTip;
