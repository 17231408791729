import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCources, tagList } from "../../store/api/course.api";
import Slider from "react-slick";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toHoursAndMinutes } from "./commonConst";

const FeatureCourseList = (prop) => {
  const [tagListItems, setTagList] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const [allCources, setAllCources] = useState();
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [tempAllCources, setTempAllCources] = useState();

  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const { tagId, index } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    },
    user_id: user?.id,
    role_id: user?.role_id,
    // page,
    // limit: pageLimit,
  };
  useEffect(() => {
    dispatch(getCources(dataFilter)).then((course) => {
      let newArr = course.payload.data?.packages.concat(
        course.payload.data?.courses
      );
      setAllCources(newArr);
    });
    dispatch(tagList()).then((data) => {
      setTagList(
        data.payload.data.filter((item) => item.name !== "Mina kurser")
      );
    });
    // Calculate the left offset
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var gridContainer = document.querySelector(".container-fluid");
    var slickTrackElement = document.querySelector(
      ".feature-slide .slick-list"
    );

    if (gridContainer && slickTrackElement) {
      var gridContainerWidth = gridContainer.clientWidth;
      var leftOffset = -(windowWidth - gridContainerWidth) / 2 - 10;

      // Set the margin-left for the .slick-track element
      slickTrackElement.style.marginRight = leftOffset + "px";
    } else {
      console.error("Couldn't find necessary elements.");
    }
  }, []);

  const settings1 = {
    dots: false,
    infinite: allCources?.length> 4? true : false ,
    // autoplay: true,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          arrows: false
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 0,
          arrows: false
        },
      },
    ],
  };

  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const gotoCourse = (item) => {
    if(item.number_of_courses){
      navigate("/package/" + item.package_id);
    } else {
      navigate("/open-course-topics/" + item.id);
    }
  };
  const gotoAllCourse = () => {
    navigate("/vara-kurser");
  };
  // useEffect(() => {
  //   dispatch(tagList()).then((data) => {
  //     setTagList(
  //       data.payload.data.filter((item) => item.name !== "Mina kurser")
  //     );
  //   });
  // }, []);

  const customSort = (item, index) => {
    setImageIndex(index);
    if (item.name === "Alla kurser") {
      delete dataFilter.tag;
      dispatch(getCources(dataFilter)).then((course) => {
        let newArr = course.payload.data?.packages.concat(
          course.payload.data?.courses
        );
        setAllCources(newArr);
      });
    } else {
      dataFilter.tags = item.id.toString();
      dispatch(getCources(dataFilter)).then((course) => {
        let newArr = course.payload.data?.packages.concat(
          course.payload.data?.courses
        );
        setAllCources(newArr);
      });
    }
    // if(prop.isHome){
    //   navigate("/vara-kurser/" + item.name + "/" + item.id + "/" + index);
    // }
  };
  const MediaList = ({ item, index }) => {
    return (
      <div>
        <div className={`feature-box cursor ${item.number_of_courses ? "custome-a" : ""}`}  onClick={() => gotoCourse(item)}>
          <div className="feature-img"   >
            <Image
              src={item.image ? item.image : "/assets/images/feature1.png"}
              alt="Image"
            />
          </div>
          <div className="overlay-content"></div>
          <div className="img-content">
            <div className="img-header">
              {/* { !stripe_subscription_id &&
              <span className="btn-img d-flex align-items-center justify-content-center">{item?.r_price} SEK  Inkl moms</span>
            } */}
            </div>
            <div className="bottom-text text-center">
              <h5 title={item.title} className="fw-light mobile-font-20">
                {item.sort_title ? item.sort_title: item.title}
              </h5>
              
              {/* Marknads-föring */}
              <span className="fw-semibold font-16 d-block mobile-font-10 mb-0">
              {!item.number_of_courses
                      ? add3Dots(item.auther_details?.name, 13)
                      : "Paket"}
              </span>
              {/* <span className="fw-light font-16 d-block">

              <span>
                {item.number_of_courses > 0 && (
                    <p className="fw cursor_class  mb-0 mobile-font-10">{item.number_of_courses} kurser</p>
                  )}
                  {!item.number_of_courses &&
                  <p className="fw cursor_class  mb-0 mobile-font-10">{`${item.media_count} avsnitt - `}</p>
                  }
                </span>
                
                {item?.id && (
                  <span className="fw cursor_class  mb-0 mobile-font-10">
                    {" "}
                    {item?.media_total_time == 0
                      ? ""
                      : toHoursAndMinutes(item?.media_total_time)}
                    
                  </span>
                )}
              </span> */}
              {/* <div className="text-box">
                    <span className='d-block'>{item.media_count} avsnitt - {item?.media_total_time?item?.media_total_time:0} minuter</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {tagListItems.length > 0 && user?.id == null && (
        <div className="categories-slider mobile-mb-14">
          {tagListItems?.map((item, index) => (
            <div
              key={index}
              className="feature-card-wrap-slider"
              onClick={() => customSort(item, index)}
            >
              <div
                className={`feature-box ${
                  index == imageIndex ? "border-primary-solid" : ""
                }`}
              >
                {/* <img src={item.image?item.image:'/assets/images/SVG/053-diamond.svg'}  className='feature-image svg-btn-image-no-hover' alt="Banner image" width={40} height={40} /> */}
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <section
        className={
          "pb-100 feature-slide hv-100 features-wrapper mobile-pb-60" + (prop.isShowBlue ? "showblue" : "")
        }
      >
        {/* <Container fluid> */}
        {/* <div className='pt-5 text-sm-start text-center'>
                  <h2 className='text-white page-title text wow fadeInDown mb-3'>{t('utvalda_kurser')}  </h2>
                </div> */}
        <Slider {...settings1} className="wow fadeInDown">
          {allCources?.length > 0 &&
            allCources.map((item, key) => (
              <MediaList item={item} key={key} index={key} />
            ))}
        </Slider>
        {allCources && allCources.length > 0 && prop.isHide && (
          <div className="d-flex align items-center justify-content-center">
            <Button
              variant="primary"
              className="mt-md-4 mobile-mt-10"
              type="button"
              onClick={gotoAllCourse}
            >
              {t("Se_fler_kurser")}
            </Button>
          </div>
        )}
        {allCources && allCources.length === 0 && (
          <p className="text-center">{t("No_records_found")}</p>
        )}
        {/* </Container> */}
      </section>
    </div>
  );
};
export default FeatureCourseList;
