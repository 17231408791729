import React, { useEffect, useState } from 'react';
import { Table, Image, Button,Row,Col } from 'react-bootstrap';
import Header from '../commom/Header2';
import { useDispatch, useSelector } from 'react-redux';
import { getemployees, removeUser } from '../../store/api/course.api';
import ViewUserDetail from '../commom/ViewUserDetail';
import SendInvitation from '../commom/sendInvitation';
import AddUser from '../commom/AddUser';
import ConfirmPopup from '../commom/ConfirmPopup';
import { useTranslation } from "react-i18next";
import BulkUserCreate from '../commom/BulkUserCreate';
import AssignCourse from '../commom/AssignCourse';
import moment from 'moment';
import InnerHeader from './InnerHeader';

require('moment/locale/sw.js');
const UserListing = () => {
   const [show, setShow] = useState(false);
   const [showAssing, setshowAssing] = useState(false);
   const [bulkShow, setBulkShow] = useState(false);
   const [showCreateuser, setShowCreateuser] = useState(false);
   const [allEmployee, setAllEmployees] = useState();
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [selectedId, setSelectedId] = useState(false);
   const [editId,setEditId] = useState(null);
   const { business_id, role_id } = useSelector((state) => state.auth.user);
   const handleShowAddNew = () => setshowAssing(true);
   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
    });
    let filter = {
      "filter":{
          "is_delete":0,
      }
  }
  if (role_id === 2) {
   filter.filter.business_id = business_id
   filter.filter.role_id = '4'
  }
    const handleDelete = (id) => {
      setPopup({
        show: true,
        id,
      });
    };
    const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         onDelete(popup.id)
        setPopup({
          show: false,
          id: null,
        });
      }
    };
    const handleDeleteFalse = () => {
      setPopup({
        show: false,
        id: null,
      });
    };

    const openShowBulk = () => {
      setBulkShow(true);
    }
   const openShow = (id) => {
      setEditId(id)
      setShowCreateuser(true);
   }

   const handleShow = (id) => {
      setSelectedId(id)
      setShow(true);
   }
   const callbackModal = () => {
      dispatch(getemployees(filter)).then((res) => {
         setAllEmployees(res.payload.data);
      })
      setShow(false);
      setBulkShow(false);
      setshowAssing(false)

   }
   const closeModal = () => {
      
      setShowCreateuser(false);
      dispatch(getemployees(filter)).then((res) => {
         setAllEmployees(res.payload.data);
      })

   }
   
   const onDelete = (id) => {
      let data = {
         'is_delete':1,
         'id': id
      }
      dispatch(removeUser(data))
         .unwrap()
         .then((res) => {
            if (res.status === 200) {
               dispatch(getemployees(filter)).then((res) => {
                  setAllEmployees(res.payload.data);
               })
            }
         })
         .catch((e) => {
            console.log(e)
         });

   };
   useEffect(() => {
      dispatch(getemployees(filter)).then((res) => {
         setAllEmployees(res.payload.data);
      })
   }, [])
   return (
      <div className="innerblock-page">
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
         <InnerHeader title={t('Users_List')} subtitle="" />
         <div className='py-lg-40px px-xl-100px px-lg-30px px-20px flex-grow-1'>
               <div className='d-flex justify-content-between align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown'>
                  {/* <h1 className='mb-sm-0 mb-4 fw-semibold text-white h2'>{t('Test_List')} </h1> */}
                  <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={() =>openShow(null)} >{t('Add_New')}</Button>
               </div>
               {(showCreateuser && !editId) &&
                  <SendInvitation show={showCreateuser} editId={null}  closeModal={closeModal} />
               }
           
            {/* Starts Table */}
            <div className='wow fadeInDown table-borderwrap'>
               <Table bordered  responsive className='text-center tableblock-wrap mb-0'>
                  <thead>
                     <tr>
                       
                        <th>{t('Name')}</th>
                        <th>{t('Email')}</th>
                        <th>{t('Course')}</th>
                        <th>{t('Action')}</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        allEmployee?.map((item, key) => (
                           <tr key={item.id}>
                            
                              <td>{item?.first_name} {item?.last_name}</td>
                              <td>{item?.email}</td>
                              <td>
                              {(item?.role_id === 4) &&
                              <div className='d-flex align-items-center justify-content-center'>
                                    <a className='btn btn-link icon-stats-dots text-black link-hover action-btn' onClick={() =>handleShow(item.id)}></a>
                                    </div>
                                 }
                              </td>
                              <td>
                                 <div className='d-flex align-items-end justify-content-center'>
                                
                                 {item?.role_id === 4 && (role_id ===1 || role_id ===2) &&
                                    <a className='btn btn-link icon-ic-edit text-black link-hover action-btn' onClick={() =>openShow(item.id)} ></a>
                                 }
                                    {(showCreateuser && editId === item?.id) &&
                                    
                                        <AddUser show={showCreateuser} editId={editId}  closeModal={closeModal} />
                                     }
                                    <a className='btn btn-link icon-ic-trash text-black link-hover action-btn' onClick={() => handleDelete(item.id)} ></a>
                                    {popup.show && (
                                    <ConfirmPopup
                                       handleDeleteTrue={handleDeleteTrue}
                                       handleDeleteFalse={handleDeleteFalse}
                                       message={t('You_sure_you_wanna_delete')}
                                    />
                                    )}
                                 </div>
                              </td>
                           </tr>
                        ))
                     }
                  </tbody>
               </Table>
               {allEmployee && allEmployee.length===0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                  }
               {show &&
                  <ViewUserDetail show={show} id={selectedId} callbackModal={callbackModal} />
               }
               {bulkShow &&
                  <BulkUserCreate show={bulkShow}  callbackModal={callbackModal} />
               }

                  {showAssing &&
                  <AssignCourse show={showAssing} callbackModal={callbackModal} />
                   }

            </div>
            {/* Ends Table */}
</div>
         </div>
      </div>
   )
}
export default UserListing;