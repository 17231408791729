import React, { useRef, useEffect, useState } from "react";
import { Container, Form, Button, Image, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import {
  addCreator,
  getSingleCreator,
  editCreator,
} from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ck_config } from "../commom/commonConst";

const AddEditCreator = (prop) => {
  //  const editorRef = useRef(null);
  const [value, setValue] = useState({
    name: "",
    about: "",
    buy_my_book_url: "",
    book_me_now_url: "",
  });
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { t } = useTranslation();
  const editorConfiguration = ck_config;
  const [textContent, setTextContent] = useState(null);
  const handleClose = () => {
    setShow(false);
    prop.callbackModal();
  };
  const onAdd = () => {
    var data = new FormData();
    data.append("name", value.name);
    data.append("sub_title", value.sub_title);
    data.append("image", selectedFile, selectedFile.name);
    if (value.buy_my_book_url) {
      data.append("buy_my_book_url", value.buy_my_book_url);
    }
    if (value.book_me_now_url) {
      data.append("book_me_now_url", value.book_me_now_url);
    }
    data.append("about", textContent);
    dispatch(addCreator(data))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.callbackModal();
      })
      .catch(() => {});
  };
  const onEdit = () => {
    var data = new FormData();
    data.append("id", prop.editId.toString());
    data.append("name", value.name);
    data.append("sub_title", value.sub_title);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    data.append("buy_my_book_url", value.buy_my_book_url);
    data.append("book_me_now_url", value.book_me_now_url);
    data.append("about", textContent);
    dispatch(editCreator(data))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.callbackModal();
      })
      .catch(() => {});
  };

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (prop.editId) {
      dispatch(getSingleCreator(prop.editId)).then((media) => {
        setValue({
          ...value,
          ["name"]: media.payload.data[0].creator_name,
          ["sub_title"]: media.payload.data[0]?.sub_title,
          ["buy_my_book_url"]: media.payload.data[0].buy_my_book_url,
          ["book_me_now_url"]: media.payload.data[0].book_me_now_url,
        });
        setTextContent(media.payload.data[0].about);
      });
    }
    setShow(prop.show);
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {prop.editId ? (
              <span>{t("Edit_Teacher")}</span>
            ) : (
              <span>{t("Add_New_Teacher")}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="page-wrapper flex-grow-1 ">
            <Container fluid>
              <div className="max-950 overflow-hidden">
                <Form>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder={t("Name")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.name}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="sub_title"
                      placeholder={t("Lärarrubrik")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.sub_title}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="book_me_now_url"
                      placeholder={t("book_me_now_url")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.book_me_now_url}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="buy_my_book_url"
                      placeholder={t("buy_my_book_url")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.buy_my_book_url}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file1"
                      className="visually-hidden"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="file1"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/Video_file_light.png"
                          alt="Select Image"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Select_Image")}</span>
                    </label>
                  </div>

                  <div className="mb-10 wow fadeInDown">
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={textContent}
                      placeholder="Enter data"
                      onChange={(event, editor) => {
                        setTextContent(editor.getData());
                      }}
                    />
                  </div>
                  {!prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onAdd}
                    >
                      {" "}
                      {t("Save")}{" "}
                    </Button>
                  )}
                  {prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onEdit}
                    >
                      {" "}
                      {t("Update")}{" "}
                    </Button>
                  )}
                </Form>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEditCreator;
