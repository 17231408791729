import React, { Component, useState, useEffect } from "react";
import Header from "../commom/Header";
import { Container, Image, Row, Col, Form, ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Footer from "../commom/Footer";
import {  useNavigate } from "react-router-dom";
const TeamsNew = () => {
  const navigate = useNavigate();
  const gotoPage = (page) =>{
    navigate(page)
  }
  return (
    <>
      <div className="d-flex flex-column flex-grow-1 homepage ">
        <Header />
        <div className="page-wrapper flex-grow-1 py-0">

          <section className="banner-wrapper team-banner position-relative bg-wrap">
            <Container>
              <div className="banner-content-area">
                <div className="block font-32 text-center fw-light video-style-font">
                  <h1 className="font-64 fw-semibold pt-8 pt-md-0">Utbilda era medarbetare <br></br> enkelt och automatiserat på <br></br> ett tidseffektivt sätt</h1>
                  <p className="font-25 text-white">
                  Välj fritt bland våra färdiginspelade kurser eller skapa med vår hjälp egna <br></br> interna kurser - Eller varför inte både och? 🤩
                  </p>
                </div>
                <div className="rounded-20 d-md-none d-block mt-4 mb-3">
                  <Image
                    src="./assets/images/Egna_interna.png"
                    alt="Egna_interna"
                    className="rounded-20 img-fit center-fit"
                  />
                </div>
                {/* <div className="d-flex align-items-center justify-content-center mt-2">
                  <a  href="https://cal.com/docens" target="_blank" class="btn font-20 mobile-font-16 btn-gradient rounded-pill"><span> Kom igång </span> </a>
                </div> */}
                <div className="d-flex flex-sm-row flex-column-reverse justify-content-center mt-sm-5 mt-4 px-sm-0 px-8 gap-5">
                <button type="button" class="btn btn-small d-sm-inline d-block px-6 btn-gradient  rounded-pill" onClick={()=>window.open("https://cal.com/docens")}> <span> Kom igång </span> </button>
                <button type="button" class="btn btn-small d-sm-inline d-block px-6 btn-outline-light rounded-pill" onClick={()=>gotoPage('/interna-kurser')}>Interna kurser </button>
              </div>
              </div>
              <div className="banner-video">
                <video
                  src="/assets/video/Background-flow.mp4"
                  autoplay="autoplay"
                  playsinline="playsinline"
                  muted="muted"
                  loop="loop"
                ></video>
              </div>
            </Container>
          </section>

          <section className="team-block-img-wrap d-md-block d-none pb-5">
            <Container>
              <div className="team-block-img">
                <Image
                  src="./assets/images/Egna_interna.png"
                  alt="Egna_interna"
                  className="img-fit center-fit"
                />
              </div>
            </Container>
          </section>

          <section className="content-info-blocks team-content-block ">
            <Container fluid>
              <div className="text-start">
                <h2 className="font-40 fw-light mb-sm-3 mb-0">Översikt</h2>
                <p className="font-25 fw-medium mb-3">Häng med - det är busenkelt  😎</p>
              </div>
              <Row className="g-7">
                <Col md={8}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">1 - Välj bland våra färdiginspelade eller interna kurser</h4>
                    <p className="fw-light text-white">Er administratör kan enkelt välja ut vilka av våra färdiginspelade kurser, eller era egna interna kurser, som ska tilldelas till vilken medarbetare. </p>
                    <div className="inner-card d-flex align-items-center">
                      <div className="small-thumbnail flex-shrink-0">
                        <Image
                          src="./assets/images/sm-box-1.png"
                          alt="teams"
                          className="img-fit center-fit"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100 ps-6">
                        <div className="me-sm-4">
                          <h5 className="fw-medium mb-0">Microsoft 365 - Excel grundkurs</h5>
                          <p className="mb-0">Mikael Boustedt</p>
                        </div>
                        <span className="d-sm-block d-none mockup-label">Tilldela</span>
                      </div>
                    </div>
                    <div className="inner-card d-flex align-items-center">
                      <div className="small-thumbnail flex-shrink-0">
                        <Image
                          src="./assets/images/sm-box-2.png"
                          alt="teams"
                          className="img-fit center-fit"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100 ps-6">
                        <div className="me-sm-4">
                          <h5 className="fw-medium mb-0">Grundkurs i internt affärsystem</h5>
                          <p className="mb-0">Företaget AB</p>
                        </div>
                        <span className="d-sm-block d-none mockup-label">Tilldela</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">2 - Sätts en deadline då medarbetaren ska ha genomfört tilldelad kurs.</h4>
                    <div className="inner-card">
                      <div className="mb-2">
                        <label className="form-label">Tilldela kurs</label>
                        <Form.Select disabled className="dark-select-box" aria-label="Default select example">
                          <option>Excel</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>
                      <div>
                        <label className="form-label">Tilldela deadline</label>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`inline-${type}`} className="mb-2">
                              <Form.Check disabled
                                inline
                                label="Ingen deadline"
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                            </div>
                          ))}
                        </Form>
                        <div className="d-flex">
                          <Form.Group className="me-3">
                              <Form.Control type="text" value="2024-16-01" disabled />
                          </Form.Group>
                          <Form.Group>
                              <Form.Control type="text" value="12:00" disabled />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={9}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">3 - Se över medarbetarnas utveckling</h4>
                    <p className="fw-light text-white">När medarbetare blivit tilldelade sina kurser kan administratören enkelt hålla koll på deras utveckling.  Efter utförd kurs finns möjlighet för varje deltagare att få ut ett diplom. För att säkerställa att de fått med sig relevant kunskap måste deltagaren dock svara rätt på ett antal frågor innan diplomet blir tillgängligt. </p>
                    <div className="inner-card">
                      <h5 className="fw-medium">Erik Eriksson</h5>
                      <div className="d-flex align-items-center mb-2">
                        <div className="small-thumbnail flex-shrink-0">
                          <Image
                            src="./assets/images/sm-box-1.png"
                            alt="teams"
                            className="img-fit center-fit"
                          />
                        </div>
                        <div className="w-100 ps-6">
                          <h5 className="fw-medium mb-1 ">Microsoft 365 - Excel grundkurs</h5>
                          <ProgressBar className="green-progressbar" now="65" label="65%" />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="small-thumbnail flex-shrink-0">
                          <Image
                            src="./assets/images/sm-box-2.png"
                            alt="teams"
                            className="img-fit center-fit"
                          />
                        </div>
                        <div className="w-100 ps-6">
                          <h5 className="fw-medium mb-1 ">Grundkurs i vårt interna affärssystem</h5>
                          <ProgressBar className="green-progressbar" now="100" label="100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="dark-card h-100">
                    <h4 className="fw-medium">4 - Gratulera era medarbetare till ett jobb väl utfört!</h4>
                    <div className="rounded-20 img-h-160">
                      <Image
                        src="./assets/images/team-certificate.png"
                        alt="Certificate"
                        className="rounded-20 img-fit center-fit"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="mb-4">
            <Container>
              <div className="text-center  mx-auto mt-120">
                <h2 className="font-64 mobile-font-30 fw-semibold">Vill du veta mer?</h2>
                <p className="font-24 fw-light">Boka ett förutsättningslöst möte med oss så berättar vi mer!</p>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <a href="https://cal.com/docens" target="_blank" class="btn font-20 mobile-font-16 btn-gradient rounded-pill"><span> Boka möte </span></a>
                </div>
              </div>
            </Container>
          </section>

        </div>
        <Footer />
      </div>
    </>
  );
};
export default TeamsNew;
