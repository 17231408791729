import { Accordion, Button, Col, Container, Image, Row } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";

const Teams = () => {
    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centermode: true,
        centerPadding: '0px', 
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              centermode: true,
              centerPadding: '100px', 
              // infinite: false, 
              // variableWidth: true
            }
          },
        ]
    
      };
    return(
        <div className="d-flex flex-column flex-grow-1 homepage">
            <Header />
            <div className="main-wrap">
                <Image src="/assets/images/bgimage.png" alt="" className="position-absolute h-100 w-100 pointer-none" />
                <Container className="position-relative z-index-1 pt-100" fluid>
                    <div className="d-flex flex-column gap-14 py-14 mobile-gap-60">
                        <div className="team-blockbox  mobile-pt-40px">
                            <Row className="gx-14">
                                <Col md={6}>
                                    <div>
                                        <div className="block fw-medium font-24 margin-b-16">
                                            <h2 className="font-40 fw-lighter mb-1 mobile-font-30">Docens för företag</h2>
                                            <span>Allt du behöver veta som marknadsförare</span>
                                        </div>
                                        <div className="block fw-lighter font-20 mobile-font-13">
                                            <p>Många därute har en känsla eller en vision för hur något ska se ut, men begränsas av att inte ha rätt verktyg för att skapa det.</p>
                                            <p>I den här kursen utforskar vi Canva. Ett intuitivt designverktyg som låter </p>
                                        </div>
                                        <Button variant="light" className="mt-4">Se alla kurser</Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <Image src="./assets/images/image3.jpg" alt="" className="rounded-20 w-100" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="team-blockbox">
                            <Row className="gx-14">
                                <Col md={6}>
                                    <div>
                                        <div className="block fw-medium font-24 margin-b-16">
                                            <h2 className="font-40 fw-lighter mb-1">Docens för företag</h2>
                                            <span>Allt du behöver veta som marknadsförare</span>
                                        </div>
                                        <div className="block fw-lighter font-20 mobile-font-13">
                                            <p>Många därute har en känsla eller en vision för hur något ska se ut, men begränsas av att inte ha rätt verktyg för att skapa det.</p>
                                            <p>I den här kursen utforskar vi Canva. Ett intuitivt designverktyg som låter </p>
                                        </div>
                                        <Button variant="light" className="mt-4">Se alla kurser</Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <Image src="./assets/images/image3.jpg" alt="" className="rounded-20 w-100" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div>
                    <Slider {...settings} className="featuresslider" centerMode={true} >
              <div className="box-sec">
                <div className="text-black text-center">
                    <span className="box-roundblock d-inline-flex align-items-center justify-content-center">
                      <Image
                        src="/assets/images/home1.png"
                        alt="Öka din"
                        
                      />
                    </span>
                    <div className="bg-white boxinfo-block font-16 fw-lighter block line-h-small">
                      <h5 className="font-20 mb-0 fw-medium mobile-font-16">
                      Underhållande & inspirerande
                      </h5>
                      <p>
                      Kurser för arbetslivet behöver inte vara tråkiga. Engagerande kurser ökar Din motivation för inlärning och bidrar till att Du utvecklas på ett roligt och meningsfullt sätt.
                      </p>
                    </div>
                  </div>
              </div>
              <div className="box-sec">
                <div className="text-black text-center">
                    <span className="box-roundblock d-inline-flex align-items-center justify-content-center">
                      <Image
                        src="/assets/images/home3.png"
                        alt="Öka din"
                        
                      />
                    </span>
                    <div className="bg-white boxinfo-block font-16 fw-lighter block line-h-small mobile-font-13">
                      <h5 className="font-20 mb-0 fw-medium mobile-font-16">Unik kursmall</h5>
                      <p>
                      En kursmall speciellt utformad för att främja inlärning hjälper Dig att effektivt planera och genomföra Dina studier. Du kan enkelt hoppa mellan avsnitt och får möjlighet till reflektion.
                      </p>
                    </div>
                  </div>
              </div>
              <div className="box-sec">
                <div className="text-black text-center">
                    <span className="box-roundblock d-inline-flex align-items-center justify-content-center">
                      <Image
                        src="/assets/images/home2.png"
                        alt="Öka din"
                        
                      />
                    </span>
                    <div className="bg-white boxinfo-block font-16 fw-lighter block line-h-small mobile-font-13">
                      <h5 className="font-20 mb-0 fw-medium mobile-font-16">
                        Produktion av hög klass
                      </h5>
                      <p>
                      Kurshållare som är experter inom sitt ämne tillsammans med en högkvalitativ produktion skapar en idealiskt miljö för inlärning och ökar Dina möjligheter att utvecklas.
                      </p>
                    </div>
                  </div>
              </div>
              
            </Slider>
                    </div>
                   

                    <div className="pt-100">
                        <div className="fw-lighter maxw-680 pb-14">
                            <h2 className="font-32 fw-lighter">Vad du kommer lära dig</h2>
                            <div className="block font-20 line-h-small">
                                <p>Många därute har en känsla eller en vision för hur något ska se ut, men begränsas av att inte ha rätt verktyg för att skapa det.</p>
                                <p>I den här kursen utforskar vi Canva. Ett intuitivt designverktyg som låter dig skapa snygga och tydliga verk, utan att behöva avancerad verktygskunskap som många andra program kräver.</p>
                            </div>
                        </div>
                        <div>
                            <Image src="./assets/images/banner-img.jpg" alt="" className="w-100" />
                        </div>
                    </div>

                    <section className='pt-100'>
                        
                            <h2 className='text-center font-40 fw-lighter mb-3'>Vanliga frågor</h2>
                        <Accordion defaVanliga frågorultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>När startar kursen?</Accordion.Header>
                            <Accordion.Body>Lorem ipsum dolor sit amet consectetur. Mauris adipiscing pellentesque est non enim eu id massa. Sit semper adipiscing enim cras non porta magna donec. Consectetur fermentum sit diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et blandit id purus enim auctor consectetur risus eros. Pellentesque sodales ornare malesuada tellus.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Är det för mig som privatperson eller företag?</Accordion.Header>
                            <Accordion.Body>Lorem ipsum dolor sit amet consectetur. Mauris adipiscing pellentesque est non enim eu id massa. Sit semper adipiscing enim cras non porta magna donec. Consectetur fermentum sit diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et blandit id purus enim auctor consectetur risus eros. Pellentesque sodales ornare malesuada tellus.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Vem är läraren?</Accordion.Header>
                            <Accordion.Body>Lorem ipsum dolor sit amet consectetur. Mauris adipiscing pellentesque est non enim eu id massa. Sit semper adipiscing enim cras non porta magna donec. Consectetur fermentum sit diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et blandit id purus enim auctor consectetur risus eros. Pellentesque sodales ornare malesuada tellus.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Vad händer om jag missar ett tillfälle?</Accordion.Header>
                            <Accordion.Body>Lorem ipsum dolor sit amet consectetur. Mauris adipiscing pellentesque est non enim eu id massa. Sit semper adipiscing enim cras non porta magna donec. Consectetur fermentum sit diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et blandit id purus enim auctor consectetur risus eros. Pellentesque sodales ornare malesuada tellus.</Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                        
                    </section>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Teams;