import React, {useState, useEffect } from 'react';

import { Image, Row, Col, Form, Button,Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';
import {addNewsLatter,editNewsLatter,getSingleNews,getCreator} from '../../store/api/course.api';
import Select from 'react-select';
import { ck_config } from "../commom/commonConst";

const AddNewslatter = (prop) => {
 
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState({ name: ''});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPdf, setSelectedPDF] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [allCretor, setAllCreator] = useState();
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedCourse, setselectedCourse] = useState(prop.editCourseId?{value:prop.editCourseId,label:prop.editName}:null);
  const options = [];
  const editorConfiguration = ck_config;
  const handleClose = () => {
     setShow(false);
     prop.closeModal();
  } 
  const onChange = (e) => {
      setValue({
         ...value,
         [e.target.name]: e.target.value,
      });
   };
   
   
  useEffect(() => {
    if(prop.editId) {
        let data ={
            id: prop.editId
        }
        dispatch(getSingleNews(data)).then((single) => {
           setValue({
            ...value,
            ['name']: single.payload.data[0].name,
            ['sub_title']: single.payload.data[0].sub_title,
            //['creator_name']:single.payload.data[0].creator_name,
          });
         setTextContent(single.payload.data[0].description);
         }); 
       }
       dispatch(getCreator()).then((data) => {
        setAllCreator(data.payload.data);
     })
    setShow(prop.show);
  }, [])
  const onSave = () => {
    var data = new FormData();
    data.append('name', value.name);
    data.append('version', value.version);
    data.append('sub_title', value.sub_title);
    data.append('creator_name', selectedCreator);
    data.append('description', textContent);
    if (selectedFile) {
       data.append('image', selectedFile, selectedFile.name);
    }
    if(selectedPdf) {
       data.append('file', selectedPdf, selectedPdf.name);
    }
    

    dispatch(addNewsLatter(data))
       .unwrap()
       .then((res) => {
          if (res.status === 200) {
            handleClose();
          }
       })
       .catch((e) => {
          console.log(e)
       });
       
   };
   const onEdit = () => {
      
    var data = new FormData();
    data.append('id', prop.editId.toString());
    data.append('name', value.name);
    data.append('sub_title', value.sub_title);
    data.append('version', value.version);
    data.append('creator_name', selectedCreator);
    data.append('description', textContent);
    if (selectedFile) {
       data.append('image', selectedFile, selectedFile.name);
    }
    if(selectedPdf) {
       data.append('file', selectedPdf, selectedPdf.name);
    }
    

    dispatch(editNewsLatter(data))
       .unwrap()
       .then((res) => {
          if (res.status === 200) {
            handleClose();
          }
       })
       .catch((e) => {
          console.log(e)
       });
 };   
          
 allCretor && allCretor.map(list =>
    options.push({value:list.creator_id,label:list.creator_name})
    );
    const onChangeCreator = event => {
        setSelectedCreator(event.label);
     };
       
  return (
      <>
          <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                  <Modal.Title>{prop.editId?<span>{t('Edit_NewsLatter')}</span>: <span>{t('Create_Newslatter')}</span>}</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                  <Form>
                      <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                          <div className='position-relative control-withic'>
                              <Form.Control type="text" placeholder={t('Titel')} name="name" aria-label="Name" value={value.name} onChange={onChange} />
                          </div>
                      </Form.Group>
                      <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                          <div className='position-relative control-withic'>
                              <Form.Control type="text" placeholder={t('sub_title')} name="sub_title" aria-label="Sub_title" value={value.sub_title} onChange={onChange} />
                          </div>
                      </Form.Group>
                      <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                          <div className='position-relative control-withic'>
                              <Form.Control type="text" placeholder={t('version')} name="version" aria-label="Sub_title" value={value.version} onChange={onChange} />
                          </div>
                      </Form.Group>
                    
                      <Form.Group className="mb-10 wow fadeInDown" controlId="formBasicEmail">
                      <div className='position-relative control-withic'>
                      { options && 
                   <Select  className="custom-select mb-10 black-theme"
                    classNamePrefix="myselect"
                   defaultValue={selectedCourse} placeholder={t('Lärare')} options={options} onChange={onChangeCreator} />
                  }
                  </div>
                  </Form.Group>
                      <Row className='gy-10'>
                          <Col sm={6}>
                              <div className='mb-10 wow fadeInDown'>
                                  <input type="file" id="file1" className='visually-hidden' onChange={(e) => setSelectedFile(e.target.files[0])} />
                                  <label htmlFor="file1" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                                      <span className='file-icon'><Image src="/assets/images/Video_file_light.png" alt="Select Image" width="48" height="48" /></span>
                                      <span>{t('Välj_omslag')}</span>
                                  </label>
                              </div>
                          </Col>
                          <Col sm={6}>
                              <div className='mb-10 wow fadeInDown'>
                                  <input type="file" id="file2" className='visually-hidden' onChange={(e) => setSelectedPDF(e.target.files[0])} />
                                  <label htmlFor="file2" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                                      <span className='file-icon'><Image src="/assets/images/fileupload-ic.png" alt="Attachments Link Pdf" width="48" height="48" /></span>
                                      <span>{t('Välj_fil')}</span>
                                  </label>
                              </div>
                          </Col>
                      </Row>

                      <div className='mb-10 wow fadeInDown'>
                          <CKEditor
                              editor={ClassicEditor}
                              config={editorConfiguration}
                              data={textContent}
                              placeholder={t('Beskrivning')}
                              onChange={(event, editor) => {
                                  setTextContent(editor.getData());
                              }}

                          />
                      </div>

                    
                      {!prop.editId &&
                             <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onSave}> {t('Save')} </Button>
                        }
                        {prop.editId &&
                     <Button variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' onClick={onEdit}> {t('Update')} </Button>
                        }
                     
              
                      
                  </Form>
              </Modal.Body>
          </Modal>
      </>
  )
}


export default AddNewslatter;