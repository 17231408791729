

import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { assignCourcesNew, getCources } from '../../store/api/course.api';
import { toast } from 'react-toastify';

const AssignOrRemoveCourseNew = (prop) => {
   const dispatch = useDispatch();
   const [show, setShow] = useState(false);
   const { business_id } = useSelector((state) => state.auth.user);
   let courseId = [];
   const [startDate, setStartDate] = useState(new Date());
   const [allCources, setAllCources] = useState();
   const handleClose = () => {
      setShow(false);
      prop.closeModal();
   }
   let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: prop.editId,
    role_id: 4
  };
   useEffect(() => {
      setShow(prop.show)
      dispatch(getCources(dataFilter)).then((course) => {
        setAllCources(
            course.payload.data.courses.map((course) =>
              course.is_assigned === true
                ? { ...course, checked: true }
                : { ...course, checked: false }
            )
          )
      });
   }, [])

   const updateCheckStatus = (index) => {
    setAllCources(
      allCources.map((course, currentIndex) =>
        currentIndex === index
          ? { ...course, checked: !course.checked }
          : course
      )
    )
  }

   // on press signup button
   const onAdd = () => {
    allCources && allCources.map(list =>{
        if(list.checked){
            courseId.push({ course_id: list.id, business_id: business_id,checked:list.checked })
        }
     }
     )
     let body;
     body = {
        'user_id': prop.editId,
        'assign_course': courseId,
        'assign_at': startDate,
     }

     dispatch(assignCourcesNew(body))
        .unwrap()
        .then((res) => {
           toast.success(res.message);
           handleClose()
        })
        .catch((e) => {
           toast.error(e.message)
        });
   };
   

   return (
      <>
       <Modal centered show={show} onHide={handleClose} className="modal-innerbox">
      <Modal.Header closeButton>
          <Modal.Title>Kurser</Modal.Title>
          <span>{prop.userName}</span>
        </Modal.Header>
        <Modal.Body>
        {allCources?.map((course, index) => (
          <Form.Check
            className="text-black form-check-black mb-1"
            type="checkbox"
            id={`default-`+index}
            checked={course.checked}
            name={`mycourse-`+index}
            value={course.id}
            onChange={()=>updateCheckStatus(index)} 
            label={course.title}
          />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark" onClick={onAdd}>
              Spara
          </Button>
        </Modal.Footer>
      </Modal>
        
      </>
   )
}

export default AssignOrRemoveCourseNew;