import React, { Component, useState, useEffect } from 'react';
import Header from '../commom/Header';
import Footer from '../commom/Footer';
import { Container, Image, Row, Col, Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { singleBlog } from '../../store/api/course.api';


import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
const SingleBlog = () => {
  const [blogDetail, setBlogDetail] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  useEffect(() => {
    // window.scrollTo(0, 0)
    let data = {
      slug: slug
    }
    dispatch(singleBlog(data)).then((res) => {
      setBlogDetail(res.payload.data)
    });
  }, [])

  const { t } = useTranslation();

  const add3Dots = (string, limit) => {
    if (string && string != '<p>null</p>') {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots + '<a class="btn-link"> Läs mer</a>';
      }
    }
    return string != "null" ? string : '';
  }
  const openDetail = (link) => {
    navigate(link);
  }
  return (

    <div className='d-flex flex-column flex-grow-1 homepage '>
      <Header />

      <div className='page-wrapper flex-grow-1 py-0'>

        {/* Starts Page Banner */}
        <section className="banner-wrapper text-start position-relative bg-wrap mb-5">
          <Container>
            <div className="block font-32 text-center fw-light video-style-font">
              <h1 className="font-64 mb-md-3 mb-0">{blogDetail?.title}</h1>
              <p className="d-none d-md-block">{blogDetail?.sub_title}</p>
            </div>
          </Container>
          <div className="banner-video">
            <video
              src="/assets/video/Background-flow.mp4"
              autoplay="autoplay"
              playsinline="playsinline"
              muted="muted"
              loop="loop"
            ></video>
          </div>
        </section>
        {/* Ends Page Banner */}

        {/* <div className='blg-wraper banner-wrapper text-center position-relative align-items-end justify-content-end'>
          <div className="banner-images">
            <Image src={blogDetail?.image} alt="Inlending banner"/>
          </div>
          <div className="overlay"></div>
          <Container fluid>
            <div className="position-relative mb-70 m-zindex">
              <div className='block pb-md-0 pb-3'>
                <h2 className='font-large fw-light'>{blogDetail?.title}</h2>
                <h4 className='fw-bold'>{blogDetail?.sub_title}</h4>
              </div>
            </div>
          </Container>
         
        </div> */}

        <Container fluid>
          <section className="hv-100 mb-100">
            <div className="mb-5 inlending-details">
              <p dangerouslySetInnerHTML={{ __html: blogDetail?.description }}></p>
              {/* <h4>1.Inlending</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
            </div>
          </section>
        </Container>

        <Container fluid>
          <section className="hv-100 dark-background border-top pt-100">
            <Row className='flex-column-reverse flex-md-row align-items-center'>
              <Col md={6} className="mb-md-6">
                <div className='wrap-block mb-sm-0 text-start'>
                  <h4 className="mt-3 f-w-600">{blogDetail?.course_title}</h4>
                  <p className='m-0' onClick={() => openDetail("/open-course-topics/" + blogDetail?.course_id)} dangerouslySetInnerHTML={{ __html: add3Dots(blogDetail?.content, 500) }}></p>
                </div>
                <div className="d-flex ct-btn mt-4">
                  <Link to={"/open-course-topics/" + blogDetail?.course_id} className='btn btn-primary d-flex align-items-center'>{t('Las_mer')}</Link>
                </div>
              </Col>
              <Col md={6} className=" justify-content-center">
                <div className='portfolio-image mb-md-0'>
                  <Image src={blogDetail?.course_image} alt='Översikt' />
                  {/* <iframe width="400px" height="400px" src="https://player.vimeo.com/video/649052824?h=7bbfec822b"></iframe> */}
                </div>
                {/* <div className="d-flex ct-btn mt-4 mb-btns">
                  <Link to={"/open-course-topics/" + blogDetail?.course_id} className='btn btn-primary d-flex align-items-center'>se kursen</Link>
                </div> */}
              </Col>
            </Row>
          </section>
        </Container>

      </div>
      <Footer />
    </div>
  )
}


export default SingleBlog;