import React, { Component, useState, useEffect } from "react";
import Header from "../commom/Header";
import Header1 from "../commom/Header2";

import Footer from "../commom/Footer";
import PodcastList from "../commom/PodcastList";
import { useSelector } from "react-redux";
const PublicPodcast = () => {
  const { role_id } = useSelector((state) => state?.auth?.user || {});

  return (
    <>
      <div className="d-flex flex-column flex-grow-1 homepage ">
        {!role_id && <Header />}
        {role_id && <Header1 />}
        <div className={`page-wrapper flex-grow-1  overflow-hidden py-0 `}>
          {/* Starts podcast To get start section */}
          <PodcastList title={"Podcast"} />
          {/* Ends podcast To get start section */}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default PublicPodcast;
