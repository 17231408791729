import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { subribeChannel } from '../../store/api/course.api'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {omit} from 'lodash'
const SubscribeForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [value, setValue] = useState({ email: "" });
    const [isSubscribe, setSubscribe] =  useState(false);
    const [errors, setErrors] = useState({});
    useEffect(() => {
       
      }, []);
      const closeModal = () => {
        props.closeModal();
      }
      const onChange = (e) => {
       // validate(e,e.target.name,e.target.value);
         setValue({
           ...value,
           [e.target.name]: e.target.value,
         });
       };
       const handleck = (e) => {
        setSubscribe(e.target.checked)
      };
      const submitSubription = () =>{
        dispatch(subribeChannel(value))
        .unwrap()
        .then((res) => {
          if(res.status === 200) {
            toast.success(res.message);
            setValue({ email: "" });
            setSubscribe(false)
            closeModal();
          }
        })
        .catch(() => {
          
        });
      }
      const validate = (event, name, value) => {
        //A function to validate each input values
        switch (name) {
            case 'email':
                if(
                    !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ){
                    setErrors({
                        ...errors,
                        email: <span> {t(`Enter_a_valid_email_address`)}</span> 
                    })
                }else{

                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                    
                }
            break;
            default:
                break;
        }
    }
    return (
    <>
        <Container fluid>
            <div className="pt-100 pb-100 banner-login position-relative">
              <div className='bottom-container-fixed '>
              <Form>
                <div className='ex-class'>
                <h2 className="font-medium fw-light text-center ">{t('Prenumerera_pa')} <span className='fw-bold'>{t('Kompatensbrevet')}</span></h2>
                <p className='pb-50 text-center'>{t('Kompetensutveckling_direkt')}</p>
                </div>
                <Form.Group className="em-group" controlId="formBasicEmail">
                  <Form.Control type="email" name="email" placeholder={t('E-post')} autoComplete="off" aria-label="Email" value={value.email}
                  onChange={onChange} />
                  { errors.email && <span>{errors.email}</span> }
               </Form.Group>
                <div className="form-group custom-checkbox">
                  <input type="checkbox" id={props.chk_id} name="sub_check" checked={isSubscribe} onChange={handleck}  />
                  <label for={props.chk_id} >{t('Jag_Godkanner')}</label>
                </div>
                <div className="d-flex ct-btn align-items-center justify-content-center mt-4 web-btns">
                  <Button  className='btn btn-primary d-flex align-items-center' onClick={()=> submitSubription()} disabled={!isSubscribe}>{t('Prenumerera')}<span className='icon-right-angle-icon d-block ms-2'></span></Button>
                </div>
                </Form>
              </div>
            </div>
          </Container>
          
    </>

    )
}
export default SubscribeForm;