import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AuthService from "../../services/auth.service";
import { getSubsPlan, nameChange, passwordRegister, verifyPayment,processAsBusiness } from "./course.api";
import { setMessage } from "./message";

const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : [];
export const register = createAsyncThunk(
  "auth/register",
  async (
    {
      firstname,
      lastname,
      code,
      phone,
      email,
      password,
      cpassword,
      register_type,
      business_name,
      designation,
      address,
      bus_phone_no,
      city,
      street_name,
    },
    thunkAPI
  ) => {
    try {
      let body;
      if (register_type === "business") {
        body = {
          register_type: register_type,
          first_name: firstname,
          last_name: lastname,
          email: email,
          password: password,
          code: code,
          phone_no: phone,
          role_id: 2,
          city: city,
          street_name: street_name,
          business: {
            name: business_name,
            address: address,
            designation: designation,
            code: code,
            phone_no: bus_phone_no,
          },
        };
      } else {
        body = {
          register_type: register_type,
          first_name: firstname,
          last_name: lastname,
          email: email,
          password: password,
          code: code,
          phone_no: phone,
          role_id: 3,
        };
      }
      const data = await AuthService.register(body);
      thunkAPI.dispatch(setMessage(data.data.message));
      localStorage.setItem("user", JSON.stringify(data.data));
      return { user: data.data };
      // return response.data;
      //return { user: response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      localStorage.setItem("user", JSON.stringify(data.data));
      return { user: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const googleSignUp = createAsyncThunk(
  "auth/googleSignUp",
  async (body, thunkAPI) => {
    try {
      const data = await AuthService.googleSignUp(body);
      localStorage.setItem("user", JSON.stringify(data.data));
      return { user: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const facebookSignUp = createAsyncThunk(
  "auth/facebookSignUp",
  async (body, thunkAPI) => {
    try {
      const data = await AuthService.facebookSignUp(body);
      localStorage.setItem("user", JSON.stringify(data.data));
      return { user: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reset = createAsyncThunk(
  "auth/reset",
  async ({ id, password }, thunkAPI) => {
    try {
      const data = await AuthService.reset(id, password);
      return { user: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forget = createAsyncThunk(
  "auth/forget",
  async ({ email }, thunkAPI) => {
    try {
      const data = await AuthService.forget(email);

      return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const changePass = createAsyncThunk(
  "auth/changePassword",
  async (body, thunkAPI) => {
    try {
      const data = await AuthService.changePass(body);
      return { user: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatedSubcription = createAsyncThunk(
  "add/subscription",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateSubscription(data));
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
const wlogin = user.id ? true : false;
const initialState = user
  ? { isLoggedIn: wlogin, user, subsPlan: null }
  : { isLoggedIn: wlogin, user: null, subsPlan: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [googleSignUp.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [facebookSignUp.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [passwordRegister.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.data;
    },
    [passwordRegister.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [nameChange.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.data;
    },
    [nameChange.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [verifyPayment.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.data;
    },
    [verifyPayment.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [processAsBusiness.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.data;
    },
    [processAsBusiness.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [getSubsPlan.fulfilled]: (state, action) => {
      state.subsPlan = action.data;
    },
    [getSubsPlan.rejected]: (state, action) => {
      state.subsPlan = null;
    },
  },
  reducers: {
    updateSubscription: (state, action) => {
      state.user.subscription.stripe_subscription_id = action.payload;
      let user = JSON.parse(localStorage.getItem("user"));
      user.subscription.stripe_subscription_id = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
    },
  },
});

const { reducer, actions } = authSlice;
export const { updateSubscription } = actions;
export default reducer;
