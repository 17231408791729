import React, { useState, useEffect } from 'react';
import Header from '../commom/Header';
import { Container, Image, Accordion, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { blogList } from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import SubscribeForm from '../commom/SubscribeForm';
import { toast } from 'react-toastify';
import Footer from '../commom/Footer';
const BlogList = () => {
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "is_deleted": 0
    }
  }
  const [allBlogs, setAllBlogs] = useState();
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  useEffect(() => {
    refresh();
  }, []);
  const refresh = () => {
    dispatch(blogList(data)).then((res) => {
        setAllBlogs(res.payload.data);
    })
  }
  const showMessage = () => {
    toast.success('Du måste logga in för att ladda ner denna boken');
  }
  return (

    <div className='d-flex flex-column flex-grow-1 homepage'>
      <Header />
      <div className='page-wrapper flex-grow-1 py-0'>
        {/* Starts Page Banner */}
        <section className="banner-wrapper text-start position-relative bg-wrap ">
            <Container>
              <div className="block font-32 text-center fw-light video-style-font">
                <h1 className="font-64 mb-md-3 mb-0">{t('Blogg')}</h1>
                <p className="d-none d-md-block">Kunskapsbanken</p>
              </div>
            </Container>
            <div className="banner-video">
            <video
              src="/assets/video/Background-flow.mp4"
              autoplay="autoplay"
              playsinline="playsinline"
              muted="muted"
              loop="loop"
            ></video>
          </div>
        </section>
        {/* Ends Page Banner */}
       
        
        <section className='hv-100 pt-10 pb-10'>
          <Container Fluid>
            <Accordion defaultActiveKey="0" className='ns-accordion blog-accordion-wrapper'>
              {
                allBlogs && allBlogs.length >0 &&  allBlogs?.map((item, index) => (
                  <Accordion.Item key={index} eventKey={`${index}`}>
                    <div className="blg-acr-container">
                    <Accordion.Header className="d-flex align-items-center"><h4 className="fw-lighter mb-0"><span className="fw-lighter"> {item?.title} </span> - <span className="fw-medium">{item?.sub_title}</span></h4></Accordion.Header>
                   
                    <Accordion.Body>
                    
                      <Row className="align-items-center">
                        <Col sm={4} className="px-0">
                          <div className="blog-image mb-sm-0 mb-2">
                            <Image className="rounded-20"  src={item.image} alt='Outlook' />
                          </div>
                        </Col>
                        <Col sm={8} className="pe-0 ps-sm-8 ps-0">
                          <div className="blogs-detailes">
                            <p className="fw-lighter mobile-font-14 mb-0">{item.cover_text}</p>
                            <div className="d-flex mt-2 align-items-start justify-content-start">
                              <Link to={`/blogg/${item.slug}`}  className="btn btn-primary flex-shrink-0  d-inline-flex align-items-center">{t('Las_mer')}</Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      
                    </Accordion.Body>
                    </div>
                  </Accordion.Item>
                ))}
            </Accordion>
          </Container>
        </section>
        
      </div>
    <Footer/>
    </div>
  )
}

export default BlogList;