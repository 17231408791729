import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { assign_course_and_deadline, getCources } from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker,{ registerLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';

import moment from 'moment';
require('moment/locale/sw.js');
import {
  checkSubsDate,
} from "../commom/commonConst";
const NewCourseAssigment = (prop) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [selectedCourse, setselectedCourse] = useState(null);
  const [allCources, setAllCources] = useState();
  const options = [];
  const { business_id, first_name, last_name, role_id, id } = useSelector(
    (state) => state.auth.user
  );
  const user = useSelector((state) => state?.auth?.user);
  registerLocale('sv', sv)

  const onChangeCourse = (i,selectedOption) => {
    //setselectedCourse(selectedOption.value);
    let newFormValues = [...formValues];
    newFormValues[i]['course_id'] = selectedOption.value;
    setFormValues(newFormValues);
  };
  const onChangeDate = (i,date) => {
    //setselectedCourse(selectedOption.value);
    let newFormValues = [...formValues];
    newFormValues[i]['expired_at'] = date;
    setFormValues(newFormValues);
  };

  const handleClose = () => {
    setShow(false);
    prop.closeModal();
  };
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
    //  "page":"1",
    //  "limit":"10"
  };

  useEffect(() => {
    
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources( course.payload.data.courses.filter(
        (item) =>
          item.is_purchaed === true ||
          item.is_assigned === true ||
          checkSubsDate(user?.subscription?.trial_end) == 1
      ));
      setselectedCourse(course.payload.data.courses[0].id);
    });
    setShow(prop.show);
  }, []);
  const [formValues, setFormValues] = useState([{ course_id: "", expired_at: "",ingendeadline:false,user_id: prop.editId,business_id:business_id }]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.value] = e.target.checked;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { course_id: "", expired_at: "",ingendeadline:false,user_id: prop.editId,business_id:business_id }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    let body = {
      assign_course: formValues,
    };
    dispatch(assign_course_and_deadline(body))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.closeModal();
      })
      .catch(() => {});
  };
  allCources &&
    allCources.map((list) =>
      options.push({ value: list.id, label: list.title })
    );
  return (
    <>


<Modal centered show={show} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Tilldela deadlinee</Modal.Title>
          <span>{prop.userName}</span>
        </Modal.Header>
        <Modal.Body className="p-20px">
          <Form  >
          {formValues.map((element, index, row) => (
            <>
            <div className="bg-grey-block rounded-20 p-20px d-flex gap-20px flex-column bgbox">
              <Form.Group>
                <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">Välj kurs</Form.Label>
                <Select
                  className="black-theme selectinfo-box"
                  classNamePrefix="myselect"
                  placeholder={'Kurs'}
                  options={options}
                  onChange={(e) => onChangeCourse(index, e)}
                />
              </Form.Group>
              
              <Form.Group className="formcheck-info">
                {/* <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">Tilldela deadline</Form.Label>
                <Form.Check
                  type="checkbox"
                  label={`Ingen deadline`}
                  value={'ingendeadline'}
                  onChange={(e) => handleChange(index, e)}
                  id={`Ingen-deadline-${index}-1`}
                /> */}
                
                <div className="d-flex gap-20px">
                  <div className='deadline_picker'>
                 <DatePicker className='fs-20 mobile-font-14 form-inputblock fw-medium text-black' name="expired_at" placeholderText={t('Set_Deadline')} selected={element.expired_at}  onChange={(date) => onChangeDate(index, date)} minDate={moment().toDate()} locale="sv"  />
                 </div>
                 {/* <div className='deadline_picker'>
                 <DatePicker  placeholderText='Time' name="expired_at" className="fs-20 mobile-font-14 form-inputblock fw-medium text-black" selected={element.expired_at}  onChange={(date) => onChangeDate(index, date)} minDate={moment().toDate()}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            />
                            </div> */}
                </div>
                
              </Form.Group>
            </div>
            <div  className={`text-center pt-20px  pb-20px  mx-n20px px-20px ${index + 1 === row.length?'title-header':''}`}>
            {index + 1 === row.length && (
              <Button variant="link" className="plus-btn" onClick={() => addFormFields()}>+</Button>
            )}
            {index + 1 !== row.length && ( 
              <Button variant="link" className="plus-btn" onClick={() => removeFormFields(index)}>-</Button>
            )}
            </div>
            </>
            ))}
          </Form>
          
          <div className="d-flex justify-content-end align-items-center pt-20px">

            <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark"  onClick={handleSubmit}>Spara</Button>

          </div>
        </Modal.Body>
      </Modal>
    
    </>
  );
};

export default NewCourseAssigment;
