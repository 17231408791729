import React, { useState, useEffect } from "react";
import Header from "../commom/Header";
import {
  Container,
  Button,
  Accordion,
} from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  getCheckoutUrl,
} from "../../store/api/course.api";
import { useDispatch } from "react-redux";
import Footer from "../commom/Footer";
import {
  monthlyPlan,
  yearlyPlanName,
  yearlyPrice,
} from "../commom/commonConst";
import Slider from "react-slick";
import OurCourseList from '../commom/OurCourseList'
import Accordion_Custome from "../commom/Accordion";

const SevenDay = () => {
  const user = useSelector((state) => state.auth.user);
  const [selectedPlan, setSelectedPlan] = useState(yearlyPrice);
  const dispatch = useDispatch();

  useEffect(() => {

  }, []);

  


  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
};

  return (
    <div className="d-flex flex-column flex-grow-1 homepage">
      <Header />
      <div className="page-wrapper flex-grow-1 py-0">
        {/* Starts Page Banner */}

        <div className="banner-blockwrap homepage">
          <div className="banner-wrapper banner-sec text-start position-relative overflow-visible black-friday">
            <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
            <Container fluid className="my-70">
              <div className="banner-text position-relative mt-md-18 mt-5 bannertext-wrap">
                <div className="block pb-md-0 pb-3">
                  <h2 className="fw-semibold font-72 mobile-font-36 lineheight-xs mb-20">
                  50% på alla våra kurser
                  </h2>
                  {/* <h1 className='mobile_heading fw-light font-size-35'>{t('Knowledge_through_mobile_1')} </h1>
                <h2 className='font-large fw-light mobile_heading'>{t('Knowledge_through_mobile_2')} </h2> */}
                  <p className="font-24 fw-light mobile-font-18 mb-20">Använd koden:  <span className="fw-semibold">MELLANDAG50</span> i kassan - Gäller t.o.m. 1 januari</p>
                  <p className="font-24 fw-light mobile-font-18 mb-20 d-sm-block d-none">God jul och gott nytt år önskar vi på Docens 😇🎅🏾</p>
                  <p className="font-24 fw-light mobile-font-18 mb-0 only_mobile d-none">God jul och gott nytt år önskar vi på </p>
                  <p className="font-24 fw-light mobile-font-18 mb-20 only_mobile d-none">Docens 😇🎅🏾</p>
                </div>
                {/* <div className="d-flex mt-4 align-items-center justify-content-md-start justify-content-center ">
                  <Button
                    onClick={() => generateCheckoutURLSubscription("trial")}
                    className="btn btn-primary btn-gradientblock flex-shrink-0  font-24 d-inline-flex align-items-center a-hover"
                  >
                    Testa graits i 7 dagar
                  </Button>
                </div> */}
              </div>
              <div className="bottomfixed-block" onClick={()=> scrollToSection('buysection')}>
                <a role="button" className="font-20 text-white link-hover">
                  Utforska kurser
                  <span className="d-block ">
                    <svg xmlns="http://www.w3.org/2000/svg"  width="18" height="10" viewBox="0 0 18 10" fill="none">
                      <path d="M0.5 1L9.08815 9L17.5 1" stroke="white"  />
                    </svg>
                  </span>
                </a>
              </div>
            </Container>
          </div>
        </div>
        <section className="pb-120" id="buysection">
          <div className={`page-wrapper flex-grow-1  overflow-hidden py-0 hidetop-block `}>
            {/* Starts Courses To get start section */}
            <OurCourseList title={'Hitta den perfekta kursen för dig!'} ></OurCourseList>
            {/* Ends Courses To get start section */}
          </div>
        </section>
        <section className="mt-120">
          <Container fluid>
            <div className="maxw-650 mx-auto text-center">
              <h1 className="font-74 fw-semibold mb-3 mobile-mb-10">50% på alla våra kurser</h1>
              <p className="font-24 fw-light mobile-font-18 mb-20 d-sm-block d-none">God jul och gott nytt år önskar vi på Docens 😇🎅🏾</p>
                  <p className="font-24 fw-light mobile-font-18 mb-0 only_mobile d-none">God jul och gott nytt år önskar vi på </p>
                  <p className="font-24 fw-light mobile-font-18 mb-20 only_mobile d-none">Docens 😇🎅🏾</p>
              {/* <button type="button" className="btn btn-primary btn-gradientblock" onClick={() => generateCheckoutURLSubscription("trial")}>Testa i 7 dagar gratis</button> */}
            </div>
          </Container>
        </section>


        <section className="pb-120 mt-120">
          <Container fluid>
            <h2 className="text-center font-40 fw-lighter mb-9 mobile-mb-20">
              Vanliga frågor
            </h2>
            <Accordion_Custome></Accordion_Custome>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default SevenDay;
