import React, { useEffect,useState } from 'react';

import { Container, Image, Row, Col,Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const Congratulations = (prop) => {
   const { t } = useTranslation();
   const [show, setShow] = useState(true);
   useEffect(() => {

   }, []);
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   } 
   return (
      <>
       <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title><span>{t('Congratulations_you_won_certificate')}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
         <div className='page-wrapper flex-grow-1  text-center congratulations-page'>
            <Container fluid>
               <h4>{t('vära_värderingar')}</h4>
               <div className="pt-10 pb-100">
                  <Image src="/assets/images/10057275s.png" alt="Congratulations" width="66" height="41" style={{'max-width':'100px'}} />
                  <Row className=" align-items-center">
                     <Col md={6} className="d-flex con_button" >
                        <div className="d-flex ct-btn  mt-4 " >
                           <a target={'_blank'} download href={'https://docens.se:3005/' + prop.certificate_url} className='btn btn-outline-light d-flex align-items-center a-hover'>{t('ladda_hem_diplom')}<span className='icon-right-angle-icon d-block ms-2'></span></a>
                        </div>
                     </Col>
                     <Col md={6}>
                        <div className="d-flex ct-btn mt-4">
                           <a target={'_blank'} href={`https://www.linkedin.com/shareArticle?mini=true&url=${'https://docens.se:3005/'+ prop.certificate_url}`} onclick="window.open(this.href, 'mywin', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;" className='btn btn-outline-light d-flex align-items-center a-hover'>{t('Dela_på_Linkedin')}<span className='icon-right-angle-icon d-block ms-2'></span></a>
                        </div>
                     </Col>
                  </Row>
               </div>
            </Container>
         </div>
         </Modal.Body>
         </Modal>
      </>
   )
}

export default Congratulations;