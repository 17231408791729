import React, { useEffect, useState } from 'react';
import { Table, Image, Button } from 'react-bootstrap';
import Header from '../commom/Header2';
import { useDispatch } from 'react-redux';
import { getCreator, removeCreator } from '../../store/api/course.api';
import AddEditCreator from '../public/AddEditCreator';
import ConfirmPopup from '../commom/ConfirmPopup';
import { useTranslation } from "react-i18next";
import moment from 'moment'
import InnerHeader from './InnerHeader';
import Wrapper from '../component/Wrapper';
require('moment/locale/sw.js');
const Creators = () => {
   const [show, setShow] = useState(false);
   const { t } = useTranslation();
   const [allCreator, setAllCreator] = useState();
   const dispatch = useDispatch();
   const [editId, setEditId] = useState(null)
   const handleShow = (id) => {
      setEditId(id)
      setShow(true);
   }
   const callbackModal = () => {
      dispatch(getCreator()).then((res) => {
         setAllCreator(res.payload.data);
      })
      setShow(false);

   }
   const onDelete = (id) => {
      dispatch(removeCreator(id))
         .unwrap()
         .then((res) => {
            if (res.status === 200) {
               dispatch(getCreator()).then((res) => {
                  setAllCreator(res.payload.data);
               })
            }
         })
         .catch((e) => {
            console.log(e)
         });

   };
   useEffect(() => {
      dispatch(getCreator()).then((res) => {
         setAllCreator(res.payload.data);
      })
   }, [])
   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
   });

   const handleDelete = (id) => {
      setPopup({
         show: true,
         id,
      });
   };
   const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         onDelete(popup.id)
         setPopup({
            show: false,
            id: null,
         });
      }
   };
   const handleDeleteFalse = () => {
      setPopup({
         show: false,
         id: null,
      });
   };
   const add3Dots = (string, limit) => {
      if (string && string != '<p>null</p>') {
         var dots = "...";
         if (string?.length > limit) {
            string = string.substring(0, limit) + dots;
         }
      }
      return string != "null" ? string : '';
   }

   return (
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t("Cretor_List")} />
            <Wrapper>
               <div className='d-flex justify-content-end align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown'>
                  {/* <h1 className='mb-sm-0 mb-4 fw-semibold text-white h2'>{t('Cretor_List')} </h1> */}
                  <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={() => handleShow(null)}>{t('Add_New')}</Button>
                  {(show && !editId) &&
                     <AddEditCreator show={show} editId={null} callbackModal={callbackModal} />
                  }
               </div>
               {/* Starts Table */}
               <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
                  <Table bordered responsive className="text-center tableblock-wrap mb-0">
                     <thead className='top-bordernone'>
                        <tr>
                           <th>{t('Images')}</th>
                           <th>{t('Creator_name')}</th>
                           <th>{t('About')}</th>
                           <th>{t('Date')}</th>
                           <th>{t('Action')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           allCreator?.map((item, key) => (
                              <tr key={item.creator_id}>
                                 <td>
                                    <span className='table-img mx-auto'>
                                       <Image src={item.image ? item.image : '/assets/images/placeholder-image.png'} alt='Outlook' width={68} height={68} className="img-fit" />
                                    </span>
                                 </td>
                                 <td>{item?.creator_name}</td>
                                 <td><p dangerouslySetInnerHTML={{ __html: add3Dots(item.about, 100) }}></p></td>

                                 <td className='text-nowrap'>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>
                                 <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                       <a className='btn btn-link icon-ic-edit text-black link-hover action-btn' onClick={() => handleShow(item?.creator_id)}></a>
                                       {(show && editId === item?.creator_id) &&
                                          <AddEditCreator show={show} editId={item?.creator_id} callbackModal={callbackModal} />
                                       }
                                       <a className='btn btn-link icon-ic-trash text-black link-hover action-btn' onClick={() => handleDelete(item.creator_id)} ></a>
                                       {popup.show && (
                                          <ConfirmPopup
                                             handleDeleteTrue={handleDeleteTrue}
                                             handleDeleteFalse={handleDeleteFalse}
                                             message={t('You_sure_you_wanna_delete')}
                                          />
                                       )}
                                    </div>
                                 </td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>
                  {allCreator && allCreator.length === 0 &&
                     <p className='text-center'>{t('No_records_found')}</p>
                  }
               </div>
               {/* Ends Table */}
            </Wrapper>

         </div>
      </div>
   )
}
export default Creators;