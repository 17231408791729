import React, { Component, lazy, Suspense } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "wowjs/css/libs/animate.css";
import "react-rangeslider/lib/index.css";
import "./assets/scss/styles.scss";
import "react-toastify/dist/ReactToastify.css";
// React Router Dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Login from "./pages/public/Login";
import Reset from "./pages/public/Reset";
import Invitation from "./pages/public/Invitation";
import SignUp from "./pages/public/SignUp";
import Home from "./pages/public/Home";
import HomeNew from "./pages/public/HomeNew";
import ProductList from "./pages/public/ProductList";
import Congratulations from "./pages/public/Congratulations";
import TestPaperList from "./pages/public/TestPaperList";
import Dashboard from "./pages/public/Dashboard";
import Creators from "./pages/public/Creators";
import DemandList from "./pages/public/DemandList";
import AnswerTest from "./pages/public/AnswerTest";
import MyOrder from "./pages/public/MyOrder";
import SevenDay from "./pages/public/SevenDay";
import Cart from "./pages/public/Cart";
import OpenCourseTopics from "./pages/public/OpenCourseTopics";
import InnerOpenCourseTopics from "./pages/public/InnerOpenCourseTopics";

import CourseManagement from "./pages/public/CourseManagement";
import ChangePassword from "./pages/public/ChangePassword";
import Forget from "./pages/public/forget";
import AddNewProduct from "./pages/public/AddNewProduct";
import MyCourseDetails from "./pages/public/MyCourseDetails";
import UserListing from "./pages/public/UserListing";
import UserManagment from "./pages/public/UserManagment";
import SendNotification from "./pages/public/sendNotification";
import AddEditAnki from "./pages/public/addEditAnki";
import NoteListing from "./pages/public/NoteListing";
import MyCertiListing from "./pages/public/MyCertiListing";
import About from "./pages/public/About";
import Terms from "./pages/commom/Terms";
import PrivacyPolicy from "./pages/commom/privacyPolicy";
import TestDetail from "./pages/public/TestDetail";
import OurCourses from "./pages/public/OurCourses";
import MyProfile from "./pages/commom/MyProfile";
import Profile from "./pages/commom/Profile";

import Contact from "./pages/commom/Contact";
import ReorderCourse from "./pages/commom/ReorderCourse";
import Pricing from "./pages/public/Pricing";
import Notifications from "./pages/public/Notifications";
import { ToastContainer } from "react-toastify";
import PageFound from "./pages/public/PageFound";
import BuySubscription from "./pages/public/BuySubscription";
import TagListing from "./pages/public/TagListing";
import AnalysisList from "./pages/public/AnalysisList";
import Spiner from "./pages/commom/Spiner";
import ScrollToTop from "./pages/commom/ScrollToTop";
import NewslatterList from "./pages/public/NewslatterList";
import DownloadNewslatter from "./pages/public/DownloadNewslatter";
import ReorderNews from "./pages/commom/ReorderNews";
import Functionality from "./pages/public/Functionality";
import Newslatters from "./pages/public/Newslatters";
import CouponCountList from "./pages/public/CouponCountList";
import OneNewslatters from "./pages/public/OneNewslatters";
import TwoNewslatters from "./pages/public/TwoNewslatters";
import ThreeNewslatters from "./pages/public/ThreeNewslatters";
import BlogList from "./pages/public/BlogsList";
import AdminBlogList from "./pages/public/AdminBlogList";
import SingleBlog from "./pages/public/SingleBlog";
import ReorderBlogs from "./pages/commom/ReorderBlogs";
import Teams from "./pages/commom/Teams";
import TeamsNew from "./pages/public/TeamsNew";
import InternaKurser from "./pages/public/InternaKurser";
import Webinar from "./pages/public/Webinar";
import ThanksForEducation from "./pages/commom/ThanksForEducation";
import ThanksForWebinar from "./pages/commom/ThanksForWebinar"
import Course from "./pages/commom/Course";
import IndividualEducation from "./pages/commom/IndividualEducation";
import Education from "./pages/commom/Education";
import CompanyDashboard from "./pages/commom/CompanyDashboard";
import Package from "./pages/commom/Package";
import ThanksForPurchase from "./pages/commom/Onboarding/ThanksForPurchase";
import RegisterWrap from "./pages/commom/Onboarding/Register";
import ChatGtpSearch from "./pages/public/ChatGtpSearch";
import AllPackage from "./pages/public/AllPackage";
import PaymenTFail from "./pages/public/PaymentFail";
import ReorderPackage from "./pages/commom/ReorderPackage";
import UpdateName from "./pages/commom/Onboarding/UpdateName";
import PrivateEducations from "./pages/public/PrivateEducations";
import PrivateEducationIndividual from "./pages/public/PrivateEducationIndividual";
import Certificate from "./pages/public/Certificate";
import Reflaction from "./pages/public/Reflaction";
import ReflactionDetail from "./pages/public/ReflactionDetail";
import Deadlines from "./pages/public/Deadlines";
import PublicPodcast from "./pages/public/PublicPodcast";
import ReorderPodcast from "./pages/commom/ReorderPodcast";
import PodcastTagListing from "./pages/public/PodcastTagListing";
import MyPodcastDetails from "./pages/public/MyPodcastDetails";
import MyEducationDetails from "./pages/public/MyEducationDetails";
import OpenPodcastTopics from "./pages/public/OpenPodcast";
import PodcastProductList from "./pages/public/PodcastProductList";
import PodCastNew from "./pages/public/PodCastNew";
import OpenPodcastDetails from "./pages/public/OpenPodcastDetails";
import InnerPackageDetail from "./pages/public/InnerPackageDetail";
import SelectType from "./pages/commom/Onboarding/SelectType";
import BusinessSignup from "./pages/commom/Onboarding/BusinessSignup";
import ReflektionerTip from "./pages/commom/ReflektionerTip"
import DiplomTip from "./pages/commom/DiplomTip"
import ConfirmOnMove from "./pages/commom/Onboarding/ConfirmOnMove";
import WebinarExcel from "./pages/public/WebinarExcel";
import Inlarning from "./pages/commom/Inlarning";
import WebinarDetails from "./pages/public/WebinarDetails";
import PrivateWebinar from "./pages/public/WebinarPrivate";
import PrivateWebinarIndividual from "./pages/public/PrivateWebinarIndividual";
import MyWebinarDetails from "./pages/public/MyWebinarDetails";
import PasswordForWebinar from "./pages/commom/passwordForWebinar";
import WebinarHistory from "./pages/public/WebinarHistory"
import WebinarSignup from "./pages/public/WebinarSignup";
import WebinarChats from "./pages/public/WebinarChats";
import WebinarWacher from "./pages/public/WebinarWacher";
import WebinarPageOpener from "./pages/public/webinarPageOpener";
import ForTeachers from "./pages/public/ForTeachers";
import Contactnew from "./pages/public/ContactNew";
// import { requestForToken } from './firebase';

// const Home = lazy(()=> import('./pages/public/Home'));
// const Login = lazy(()=> import('./pages/public/Login'));
// const Reset = lazy(()=> import('./pages/public/Reset'));
// const SignUp = lazy(()=> import('./pages/public/SignUp'));
// const ProductList = lazy(()=> import('./pages/public/ProductList'));
// const Congratulations = lazy(()=> import('./pages/public/Congratulations'));
// const TestPaperList = lazy(()=> import('./pages/public/TestPaperList'));
// const Dashboard = lazy(()=> import('./pages/public/Dashboard'));
// const AnswerTest = lazy(()=> import('./pages/public/AnswerTest'));
// const MyOrder = lazy(()=> import('./pages/public/MyOrder'));
// const Cart = lazy(()=> import('./pages/public/Cart'));
// const OpenCourseTopics = lazy(()=> import('./pages/public/OpenCourseTopics'));
// const CourseManagement = lazy(()=> import('./pages/public/CourseManagement'));
// const ChangePassword = lazy(()=> import('./pages/public/ChangePassword'));
// const AddNewProduct = lazy(()=> import('./pages/public/AddNewProduct'));
// const MyCourseDetails = lazy(()=> import('./pages/public/MyCourseDetails'));
// const UserListing = lazy(()=> import('./pages/public/UserListing'));
// const SendNotification = lazy(()=> import('./pages/public/sendNotification'));
// const AddEditAnki = lazy(()=> import('./pages/public/addEditAnki'));
// const About = lazy(()=> import('./pages/public/About'));
// const PageFound = lazy(()=> import('./pages/public/PageFound'));
// const MyCertiListing = lazy(()=> import('./pages/public/MyCertiListing'));
// const TestListing = lazy(()=> import('./pages/public/TestListing'));
// const TestDetail = lazy(()=> import('./pages/public/TestDetail'));
// const OurCourses = lazy(()=> import('./pages/public/OurCourses'));
// const NoteListing = lazy(()=> import('./pages/public/NoteListing'));
// const Creators = lazy(()=> import('./pages/public/Creators'));
// const Forget = lazy(()=> import('./pages/public/forget'));
// const Notifications = lazy(()=> import('./pages/public/Notifications'));
// const MyProfile = lazy(()=> import('./pages/commom/MyProfile'));
// const Notfound = lazy(()=> import('./pages/commom/Notfound'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // requestForToken();
  }
  render() {
    return (
      <div className="App">
        {/* tost message configerd here */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeNew />} />
          {/* <Route path="/home-new" element={<HomeNew />} /> */}
          <Route path="/contact-new" element={<Contactnew />} />
          <Route path="/logga-in" element={<Login />} />
          <Route path="/logga-in/:id/:option" element={<Login />} />
          <Route path="/logga-in/:id/:option/:replay" element={<Login />} />
          <Route path="/registrera" element={<SignUp />} />
          {/* <Route path="/email-login" element={<EmailLogin />} /> */}
          <Route path="/kompetensbrevet" element={<Newslatters />} />
          <Route path="/nyhetsbrev-vecka-1" element={<OneNewslatters />} />
          <Route path="/nyhetsbrev-vecka-2" element={<TwoNewslatters />} />
          <Route path="/nyhetsbrev-vecka-3" element={<ThreeNewslatters />} />
          <Route path="/nyhetsbrev-lista" element={<NewslatterList />} />
          <Route path="/kunskapsbanken" element={<DownloadNewslatter />} />
          <Route path="/inbjudan/:token" element={<Invitation />} />
          <Route path="/produkt-lista" element={<ProductList />} />
          <Route path="/rabattkoder" element={<CouponCountList />} />
          <Route path="/grattis" element={<Congratulations />} />
          <Route path="/test" element={<TestPaperList />} />
          <Route path="/oversitk" element={<Dashboard />} />
          <Route path="/kreator" element={<Creators />} />
          <Route path="/demand-list" element={<DemandList />} />
          <Route path="/answer-test/:id/:courseid" element={<AnswerTest />} />
          <Route path="/my-order" element={<MyOrder />} />
          <Route path="/cart" element={<Cart />} />
          <Route
            path="/open-course-topics/:courseid"
            element={<OpenCourseTopics />}
          />
          <Route
            path="/private-open-course-topics/:courseid"
            element={<InnerOpenCourseTopics />}
          />
          <Route
            path="/private-open-course-topics/:courseid/:mediaId"
            element={<InnerOpenCourseTopics />}
          />
          {/* <Route path="/course-management/:id" element={<CourseManagement />} /> */}
          <Route path="/change-password" element={<ChangePassword />} />
          {/* <Route path="/add-new-product" element={<AddNewProduct />} /> */}
          <Route
            path="/my-course-details/:tagname/:tagId/:index"
            element={<MyCourseDetails />}
          />
          <Route path="/my-course-details" element={<MyCourseDetails />} />
          <Route path="/my-package" element={<AllPackage />} />
          <Route path="/om-oss" element={<About />} />
          <Route path="/anvandarvilkor" element={<Terms />} />
          <Route path="/integritetspolicy" element={<PrivacyPolicy />} />
          <Route path="/user-list" element={<UserListing />} />
          <Route path="/user-managment" element={<UserManagment />} />
          <Route path="/send-notificatios" element={<SendNotification />} />
          <Route path="/add-edit-anki" element={<AddEditAnki />} />
          <Route path="/reflaction" element={<NoteListing />} />
          
          <Route path="/my-certi-list" element={<MyCertiListing />} />
          <Route path="/glomt-losenord" element={<Forget />} />
          <Route path="/test-detail/:id/:courseId" element={<TestDetail />} />
          <Route path="/reset/:token" element={<Reset />} />
          <Route
            path="/vara-kurser/:tagname/:tagId/:index"
            element={<OurCourses />}
          />
          <Route path="/vara-kurser" element={<OurCourses />} />
          <Route path="/profil" element={<Profile />} />
          <Route path="/my-profil" element={<Profile />} />

          <Route path="/alla-notiser" element={<Notifications />} />
          <Route path="/funktioner" element={<Functionality />} />
          <Route path="/kontakta-oss" element={<Contact />} />
          <Route path="/reorder" element={<ReorderCourse />} />
          <Route path="/reorder-package" element={<ReorderPackage />} />
          <Route path="/reorder-podcast" element={<ReorderPodcast />} />

          <Route path="/pris" element={<Pricing />} />
          <Route path="/taggar" element={<TagListing />} />
          <Route path="/podcast/taggar" element={<PodcastTagListing />} />

          <Route path="/analyctics" element={<AnalysisList />} />
          <Route
            path="/subsription/:plan_id/:price/:is_trial"
            element={<BuySubscription />}
          />
          <Route path="/reorder-news" element={<ReorderNews />} />
          <Route path="/blogg" element={<BlogList />} />
          <Route path="/redigera-artiklar" element={<AdminBlogList />} />
          <Route path="/blogg/:slug" element={<SingleBlog />} />
          <Route path="/reorder-blogg" element={<ReorderBlogs />} />
          <Route path="/search-GTP" element={<ChatGtpSearch />} />
          <Route path="/*" element={<PageFound />} />
          {/* <Route path="/testa-i-7-dagar-gratis" element={<SevenDay />} />
          <Route path="/blackweek" element={<SevenDay />} /> */}
          <Route path="/mellandag" element={<SevenDay />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/företag" element={<TeamsNew />} />
          <Route path="/för-utbildare" element={<ForTeachers />} />
          <Route path="/interna-kurser" element={<InternaKurser />} />
          <Route path="/webinar" element={<Webinar />} />
          <Route path="/webinar-detail/:id" element={<WebinarDetails />} />
          <Route path="/webinar-excel" element={<WebinarExcel />} />
          <Route path="/private-webinar" element={<PrivateWebinar />} />
          
          <Route path="/thanks-education" element={<ThanksForEducation />} />
          <Route path="/webinar-history" element={<WebinarHistory />} />
          <Route path="/webinar-signups/:id" element={<WebinarSignup />} />
          <Route path="/webinar-chats/:id" element={<WebinarChats />} />
          <Route path="/webinar-watcher/:id/:type" element={<WebinarWacher />} />
          <Route path="/webinar-readmore/:id" element={<WebinarPageOpener />} />
          {/* <Route path="/course" element={<Course />} /> */}
          <Route
            path="/individual-education/:educationid"
            element={<IndividualEducation />}
          />
          
          <Route path="/company-dashboard" element={<CompanyDashboard />} />
          <Route path="/utbildning" element={<Education />} />



          <Route path="/package/:packageid" element={<Package />} />
          <Route path="/private-package-detail/:packageid" element={<InnerPackageDetail />} />

          <Route
            path="/payment_verification_success/:session_id"
            element={<ThanksForPurchase />}
          />
          <Route
            path="/payment_verification_fail/:session_id"
            element={<PaymenTFail />}
          />
          <Route path="/onboarding/register" element={<RegisterWrap />} />
          <Route path="/onboarding/select-type" element={<SelectType />} />
          <Route path="/onboarding/name" element={<UpdateName />} />
          <Route path="/onboarding/business" element={<BusinessSignup />} />
          <Route path="/onboarding/confirm-selection" element={<ConfirmOnMove />} />
          <Route path="/certificate" element={<Certificate />} />
          
          <Route path="/reflaction-detail/:course_id" element={<ReflactionDetail />} />
       
          <Route path="/podcast" element={<PublicPodcast />} />
          <Route
            path="/my-podcast-details/:tagname/:tagId/:index"
            element={<PodCastNew />}
          />
          <Route path="/my-podcast-details" element={<PodCastNew />} />
          {/* <Route
            path="/my-education-details/:tagname/:tagId/:index"
            element={<MyEducationDetails />}
          /> */}
          <Route
            path="/my-education-details"
            element={<MyEducationDetails />}
          />
          <Route
            path="/open-podcast-topics/:podcastid"
            element={<OpenPodcastTopics />}
          />
          <Route
            path="/private-podcast-topics/:podcastid"
            element={<OpenPodcastDetails />}
          />
          <Route
            path="/podcast-produkt-lista"
            element={<PodcastProductList />}
          />
          
          <Route path="/private-educations" element={<PrivateEducations />} />
          <Route path="/private-educations-individual/:educationid" element={<PrivateEducationIndividual />} />
          <Route path="/reflektioner-tip" element={<ReflektionerTip />} />
          <Route path="/diplom-tip" element={<DiplomTip />} />
          <Route path="/inlarning" element={<Inlarning />} />
          <Route path="/deadline" element={<Deadlines />} />

          <Route path="/my-webinar-details" element={<MyWebinarDetails />}/>
          <Route path="/webinar" element={<Webinar />} />
          <Route path="/webinar-detail/:id" element={<WebinarDetails />} />
          <Route path="/view-webinar/:id/:option" element={<WebinarExcel />} />
          <Route path="/view-webinar/:id/:option/:replay" element={<WebinarExcel />} />
          <Route path="/private-webinar" element={<PrivateWebinar />} />
          <Route path="/private-webinar-individual/:id" element={<PrivateWebinarIndividual />} />
          <Route path="/thanks-webinar" element={<ThanksForWebinar />} />
          <Route path="/set-password-once/:slug/:token" element={<PasswordForWebinar />} />
          
        </Routes>
      </div>
    );
  }
}

export default App;
