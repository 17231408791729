import React, { useEffect, useState } from 'react';
import { Container, Table, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../commom/Header2';
import AddNewQuestion from '../commom/AddNewQuestion';
import EditQuestion from '../commom/EditQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { getExamById, removeQuestion } from '../../store/api/course.api'
import { useParams } from 'react-router-dom';
import ConfirmPopup from '../commom/ConfirmPopup'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import InnerHeader from './InnerHeader';
import Wrapper from '../component/Wrapper';
require('moment/locale/sw.js');
const TestDetail = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const [allProduct, setAllProduct] = useState();
   const dispatch = useDispatch();
   const { id, courseId } = useParams();
   const { t } = useTranslation();
   const [editId,setEditId] = useState(null);
   const callbackModal = () => {
      setShow(false);
      refresh();
   }
   useEffect(() => {
      refresh();
   }, [])

   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
   });

   const handleDelete = (id) => {
      setPopup({
         show: true,
         id,
      });
   };
   const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         let data = {
            'is_delete': 1,
            'id': popup.id
         }
         dispatch(removeQuestion(data))
            .unwrap()
            .then((res) => {
               if (res.status === 200) {
                  refresh();
               }
            })
            .catch((e) => {
               console.log(e)
            });
         setPopup({
            show: false,
            id: null,
         });
      }
   };
   const handleDeleteFalse = () => {
      setPopup({
         show: false,
         id: null,
      });
   };

   const refresh = () => {
      let data = {
         "filter": {
            "is_delete": 0,
            'exam_id': id
         }
      }
      dispatch(getExamById(data)).then((res) => {
         setAllProduct(res.payload.data);
      })
   }
   const handleShow = (id) => {
      setEditId(id)
      setShow(true);
   }
   return (
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>

            <InnerHeader title={t('Question_List')} subtitle="" />
            <Wrapper>
               <div className='d-flex justify-content-end align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown'>
                  <Button type='button' className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100' onClick={()=>handleShow(null)}>{t('Add_New')}</Button>
                  {(show && !editId) &&
                     <AddNewQuestion show={show} id={id} courseId={courseId} callbackModal={callbackModal} />
                  }
               </div>
               {/* Starts Table */}
               <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
                  <Table bordered responsive className="text-center tableblock-wrap mb-0">
                     <thead className='top-bordernone'>
                        <tr>
                           <th>{t('Index')}</th>
                           <th>{t('Question')} </th>
                           <th>{t('Correct_Ans')}</th>
                           <th>{t('Date')}</th>
                           <th>{t('Action')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           allProduct?.map((product, index) => (
                              <tr key={product.question_id}>
                                 <td>
                                    {index + 1}
                                 </td>
                                 <td>{product?.question}</td>
                                 <td>{product?.is_correct_ans}</td>
                                 <td>{moment(product?.created_at).format("DD-MMM-YYYY")}</td>
                                 <td>
                                    <div className='d-flex align-items-center justify-content-center'>

                                    <a onClick={() => handleShow(product?.question_id)} className='btn btn-link icon-ic-edit text-black link-hover action-btn'></a>
                                    {(show && editId === product?.question_id) &&
                                        <EditQuestion show={show} editId={editId}   callbackModal={callbackModal} />
                                     }
                                       <a onClick={() => handleDelete(product.question_id)} className='btn btn-link icon-ic-trash text-black link-hover action-btn'></a>
                                       {popup.show && (
                                          <ConfirmPopup
                                             handleDeleteTrue={handleDeleteTrue}
                                             handleDeleteFalse={handleDeleteFalse}
                                             message={t('You_sure_you_wanna_delete')}
                                          />
                                       )}
                                    </div>
                                 </td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>
                  {allProduct && allProduct.length === 0 &&
                     <p className='text-center'>{t('No_records_found')}</p>
                  }
               </div>
               {/* Ends Table */}
            </Wrapper>
         </div>
      </div>
   )

}

export default TestDetail;

