import { Button, Col, Container, Image, Row } from "react-bootstrap";
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import Header from '../commom/Header';
import Footer from "../commom/./Footer";


const About = () => {
   const { role_id } = useSelector((state) => state?.auth?.user || {});

   useEffect(() => {

   }, [])
   return (
      <>
         <div className="homepage">
            {!role_id && <Header />}
            {role_id && <Header1 />}
            <section className="banner-wrapper text-start position-relative bg-wrap">
               <Container fluid className="banner-content-area">
                  <div className="block font-32 text-center fw-lighter mt-10">
                     <h1 className="text-white font-64 fw-semibold">Om oss</h1>
                  </div>
               </Container>
               <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
            </section>
            <section className="education-block mobile-pt-40px mb-4">
               <Container fluid>
                  <Row className="gy-md-3 position-relative">
                     <Col md={6} className="order-md-0 order-1">
                        <div className="pe-md-12">
                           <div className="mb-10">
                              <h2 className="text-white font-40 fw-lighter mb-1">
                                 Välkommen till Docens
                              </h2>
                              <h4 className="text-white fw-medium font-24 mt-1 margin-b-16">
                                 Ett litet familjeföretag med stora drömmar
                              </h4>
                           </div>
                           <div className="text-white block fw-lighter mb-md-8 font-20 mobile-font-13">
                              <p>
                                 Behöver utbildning inom ämnen i företagslivet vara tråkig och dåligt producerad? Eller kan den få kännas lyxig och inspirerande?
                              </p>
                              <p>
                                 Docens skapades med just den önskan, att skapa digitala utbildningsfilmer som ska kännas lyxiga och underhållande samtidigt som de är pedagogiska och lärorika. Oavsett om vi skapar en allmän kurs  som är tillgänglig för alla, eller en skräddarsydd företagsanpassad, ska den hålla samma höga standard.
                              </p>
                              <p>
                                 Varje projekt är för oss ett tillfälle att kombinera teknik och pedagogik för skapa intressanta och meningsfulla lärandeupplevelser. Genom nära samarbeten och ett stort personligt engagemang vill vi skapa utbildningsfilmer som inte bara informerar utan även inspirerar.
                              </p>
                              <p>
                                 Häng med oss du också vetja, så får du inte bara en leverantör av utbildningsmaterial utan någon du kan utveckla era utbildningsdrömmar med 💥
                              </p>
                           </div>
                           <Button variant="primary" className="d-none mobile-mt-20">
                              Skicka intresseanmälan
                           </Button>
                        </div>
                     </Col>
                     <Col md={6} className="order-md-1 order-0">
                        <div className="about-us-img mobile-mb-20 ps-md-12">
                           <Image
                              src="./assets/images/Egna_interna.png"
                              alt="Egna_interna"
                              className="img-fit center-fit rounded-20 w-100"
                           />
                        </div>
                     </Col>
                  </Row>
               </Container>
            </section>

            <Footer isBlack={false} />
         </div>
      </>

   )
}

export default About;