import React, { Component, useState, useEffect } from 'react';
import Header from '../commom/Header';
import { Container, Image, Row, Col, Form, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import FeatureCourseList from '../commom/FeatureCourseList';
import { useTranslation } from "react-i18next";
import Footer from '../commom/Footer';

const Functionality = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  useEffect(() => {
   // window.scrollTo(0, 0)
  }, [])
  
  const { t } = useTranslation();

  
  return (

    <div className='d-flex flex-column flex-grow-1 '>
      <Header />
      <div className='page-wrapper flex-grow-1 py-0'>
        {/* Starts Courses To get start section */}
        <section className="bg-gray  pb-100 hv-100"></section>

        <section className="bg-gray  pb-100 hv-100">
          <Container fluid>
            {/* 1 */}
            {/* <div className="mb-100">
                <Row className="mb-50">
                   
                    <Col className="order-md-0 order-0 order-sm-1" md={6}>
                        <div className='portfolio-leftblock mb-sm-0 mb-3'>
                        <h4 className="mb-2">{t('En_licens_per_anställd')}.</h4>
                            <p>{t('Alla_Docens_kurser_säljs')}</p>   
                            <h6>{t('Prislista')}</h6>
                            <ul>
                            <li>  {t('Vid_köp_av_10')}</li>
                            <li>  {t('Vid_köp_av_50')}</li>
                            <li>  {t('Vid_köp_av')}</li>
                            <li>  {t('100_licenser')}</li>
                          </ul>                     
                        </div>
                    </Col>
                    <Col className="order-md-1 order-1 order-sm-0" md={6}>
                        <div className='portfolio-image mb-md-0 mb-3'>
                        <Image src='/assets/images/offeres.png' alt='Översikt' />
                        </div>
                    </Col>
                </Row>
            </div> */}
        {/* 2 */}
            <div className="mb-100 ">
                <Row className="mb-50">
                    <Col className="order-md-1 order-0" md={6}>
                        <div className='portfolio-leftblock mb-sm-0 mb-3 mt-md-3'>
                       
                            <h4 className="mb-2">{t('Bestäm_dina_anställdas_kursplan')}.</h4>
                            <p>{t('En_ansvarig_administratör_kan')}</p>
                        
                        </div>
                    </Col>
                    <Col className="order-md-0 order-1" md={6}>
                        <div className='portfolio-image mb-md-0 mb-3'>
                            <Image src='/assets/images/testimonial-3.jpeg' alt='Översikt' />
                        </div>
                    </Col>
                </Row>
            </div>
            {/* 3 */}
            <div className="mb-100">
                <Row className="mb-50">
                <Col className="order-md-0 order-0"  md={6}>
                        <div className='portfolio-leftblock mb-3'>
                       
                            <h4 className="mb-2">{t('Utbilda_i_egen_takt')}.</h4>
                            <p>{t('En_digital_utbildning_tillåter')}</p>                        
                        </div>
                    </Col>
                    <Col className="order-md-1 order-1" md={6}>
                        <div className='portfolio-image  mb-3'>
                            <Image src='/assets/images/testimonial-1.png' alt='Översikt' />
                        </div>
                    </Col>
                    
                    
                </Row>
            </div>
            {/* 4 */}
            <div className="mb-100">
                <Row className="mb-50">
                    <Col className="order-md-1 order-0" md={6}>
                        <div className='portfolio-leftblock mb-sm-0 mb-3'>
                       
                            <h4 className="mb-2">{t('Tid_för_Reflektion')}</h4>
                            <p>{t('Reflektion_är_en')}</p>  
                            
                          <p>{t('Vårt_verktyg_för_anteckningar')}</p>                   
                        </div>
                    </Col>
                    <Col className="order-md-0 order-1" md={6}>
                        <div className='portfolio-image mb-md-0 mb-3'>
                            <Image src='/assets/images/notes.png' alt='Översikt' />
                        </div>
                    </Col>
                </Row>
            </div>
            {/* 5 */}
            <div className="mb-100">
                <Row className="mb-50">
                 
                    <Col className="order-md-0 order-0" md={6}>
                        <div className='portfolio-leftblock mb-sm-0 mb-3 mt-md-3'>
                       
                            <h4 className="mb-2">{t('Diplom_efter_slutförd_kurs')}.</h4>
                            <p>{t('Efter_varje_slutförd_kurs')}</p>                        
                        </div>
                    </Col>
                    <Col className="order-md-1 order-1" md={6}>
                        <div className='portfolio-image mb-md-0 mb-3'>
                            <Image src='/assets/images/testimonial-2.png' alt='Översikt' />
                        </div>
                    </Col>
                    
                </Row>
            </div>
            {/* 6 */}
            <div className="mb-0">
                <Row className="mb-0">
                <Col className="order-md-1 order-0" md={6}>
                        <div className='portfolio-leftblock mb-sm-0 mb-3 mt-md-3'>
                            <h4 className="mb-2">{t('Möjlighet_till_att_boka')}.</h4>
                            <p>{t('Vårt_samarbete_med')}</p>                        
                        </div>
                    </Col>
                    <Col className="order-md-0 order-1" md={6}>
                        <div className='portfolio-image mb-md-0 mb-3'>
                            <Image src='/assets/images/creator.png' alt='Översikt' />
                        </div>
                    </Col>
                   
                    
                </Row>
            </div>
           
          </Container>
        </section>

      

        <section className="hv-100 dark-background">
          <Container fluid>
            <div className="pt-100 pb-100">
              <Row className="align-items-center">
                <Col md={6} className="mb-6">
                  <div className='wrap-block mb-sm-0 '>
                    <h2 className="font-medium fw-light" style={{'white-space':'nowrap'}}>{t('I_webben_På')}</h2>
                    <h2 className="font-medium fw-light" style={{'white-space':'nowrap'}}> <span className='text-primary'>{t('Var_du_än_är')}</span></h2>
                    <h4 className="mt-5 f-w-600">{t('Gå_din_kurs_på_alla_enheter')}</h4>
                    <p>{t('Det_är_inte_alltid_vi_finner')} </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='portfolio-image mb-md-0'>
                    <Image src='/assets/images/testimonial-4.png' alt='Översikt' />
                    {/* <iframe width="400px" height="400px" src="https://player.vimeo.com/video/649052824?h=7bbfec822b"></iframe> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
      
      <Footer isShow={true} />
    </div>
  )
}


export default Functionality;