import React, {useEffect,useState} from 'react';
import { Modal,Table} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSubcriptionList } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import moment from 'moment';
require('moment/locale/sw.js');
const ViewEmailSubscriber = (prop) => {
   const [show, setShow] = useState(prop.showOpen);
   const [allsubscriber, setAllSubscriber] = useState();
   const { t } = useTranslation();
   const dispatch = useDispatch();
    let filter = {
      "filter":{
          "is_deleted":0,
      }
  }
   useEffect(() => {
      setShow(prop.show)
      dispatch(getSubcriptionList(filter)).then((res) => {
         setAllSubscriber(res.payload.data);
      })
   }, [])
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   } 
  
   return(
      <>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title>{t('Total_User')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
            
            {/* Starts Table */}
            <div className='wow fadeInDown'>
               <Table bordered hover responsive className='text-center'>
                  <thead>
                     <tr>
                        <th>{t('Email')}</th>
                        <th>{t('Subscription_date')}</th> 
                     </tr>
                  </thead>
                  <tbody>
                     {
                       allsubscriber && allsubscriber.length > 0 && allsubscriber?.map((item, key) => (
                           <tr className='border-color-on-hover' key={item.id}  >                             
                              <td>{item?.email} </td>
                              <td>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>                                                           
                           </tr>
                        ))
                     }
                  </tbody>
               </Table>
               {allsubscriber && allsubscriber.length===0 &&
                  <p className='text-center'>{t('No_records_found')}</p>
                  }
              
            </div>
            {/* Ends Table */}

         
         </Modal.Body>
         </Modal>
         
      </>
   )
}

export default ViewEmailSubscriber;