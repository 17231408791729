import React, { useState, useEffect } from "react";
import Header from "../commom/Header2";
import { Container, Table, Image, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddBlog from "./AddBlog";
import { removeBlog, blogList, getCources } from "../../store/api/course.api";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPopup from "../commom/ConfirmPopup";
import moment from "moment";
import { Link } from "react-router-dom";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";

const AdminBlogList = () => {
  const [showEditBlog, setShowEditBlog] = useState(false);
  const [allblogs, setAllBlog] = useState();
  const [editId, setEditId] = useState(null);
  const { role_id, id } = useSelector((state) => state?.auth?.user);
  const [allCources, setAllCources] = useState();

  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });
  const dispatch = useDispatch();
  let data = {
    filter: {
      is_deleted: 0,
    },
  };
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  useEffect(() => {
    refresh();
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources(course.payload.data.courses);
    });
  }, []);
  const refresh = () => {
    dispatch(blogList(data)).then((res) => {
      setAllBlog(res.payload.data);
    });
  };
  const { t } = useTranslation();

  const closeModal = () => {
    setShowEditBlog(false);
    refresh();
  };
  const openShow = (id) => {
    setEditId(id);
    setShowEditBlog(true);
  };
  const handleDelete = (id) => {
    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      let data = {
        is_delete: 1,
        slug: popup.id,
      };
      dispatch(removeBlog(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            refresh();
          }
        })
        .catch((e) => {
          console.log(e);
        });

      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const getIdFromName = (id) => {
    let tempCreator = allCources.filter((item) => item.id == id);

    return tempCreator[0]?.title;
  };
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 py-0 page-block">
        <InnerHeader title={t('blog_list')} subtitle="" />
        <Wrapper>

          <div className="d-flex justify-content-end align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown">
            {/* <h1 className="mb-sm-0  fw-semibold text-white h2">
                  {t("blog_list")}
                </h1> */}
            {role_id === 1 && (
              <>
                <div className="right-button">
                  <Button
                    variant="primary"
                    className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3"
                    onClick={() => openShow(null)}
                  >
                    {t("Add_New")}
                  </Button>
                  {showEditBlog && !editId && (
                    <AddBlog
                      show={showEditBlog}
                      editId={null}
                      closeModal={closeModal}
                    />
                  )}
                  <Link
                    to="/reorder-blogg"
                    className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3"
                  >
                    {t("Ordering")}
                  </Link>
                </div>
              </>
            )}
          </div>

          {/* Starts Table */}
          <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
            <Table bordered responsive className="text-center tableblock-wrap mb-0">
              <thead className='top-bordernone'>
                <tr>
                  <th>{t("Images")}</th>
                  <th>{t("Title")}</th>
                  <th>{t("Sub_title")}</th>
                  <th>{t("Beskrivning")}</th>

                  <th>{t("Date")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {allblogs &&
                  allblogs.length > 0 &&
                  allblogs?.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <span className="table-img mx-auto">
                          <a href={item.url} target={"_blank"}>
                            <Image
                              src={
                                item.image
                                  ? item.image
                                  : "/assets/images/placeholder-image.png"
                              }
                              alt="Outlook"
                              width={68}
                              height={68}
                              className="img-fit"
                            />
                          </a>
                        </span>
                      </td>
                      <td>{item?.title}</td>
                      <td>{item.sub_title}</td>
                      <td>
                        <p
                          tabIndex={item.description}
                          dangerouslySetInnerHTML={{
                            __html: add3Dots(item.description, 100),
                          }}
                        ></p>
                      </td>

                      <td className="text-nowrap">
                        {moment(item?.created_at).format("DD-MMM-YYYY")}
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <a
                            className="btn btn-link icon-ic-edit text-black link-hover action-btn"
                            onClick={() => openShow(item.slug)}
                          ></a>
                          {showEditBlog &&
                            item.slug !== null &&
                            editId === item.slug && (
                              <AddBlog
                                show={showEditBlog}
                                editId={item?.slug}
                                creator_id={item.attached_course_id}
                                creator_name={getIdFromName(
                                  item.attached_course_id
                                )}
                                closeModal={closeModal}
                              />
                            )}
                          <a
                            onClick={() => handleDelete(item.slug)}
                            className="btn btn-link icon-ic-trash text-black link-hover action-btn"
                          ></a>
                          {popup.show && (
                            <ConfirmPopup
                              handleDeleteTrue={handleDeleteTrue}
                              handleDeleteFalse={handleDeleteFalse}
                              message={t("You_sure_you_wanna_delete")}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {allblogs && allblogs.length === 0 && (
              <p className="text-center">{t("No_records_found")}</p>
            )}
          </div>
          {/* Ends Table */}
        </Wrapper>
      </div>
    </div>
  );
};

export default AdminBlogList;
