import React, { useState, useEffect } from 'react';
import Header from '../commom/Header2';
import { Container, Table, Image, Col, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import AddNewslatter from './AddNewsLatter';
import { newslatterList, removeNews, getCreator } from '../../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPopup from '../commom/ConfirmPopup';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Wrapper from '../component/Wrapper';
import InnerHeader from './InnerHeader';
const NewslatterList = () => {
  const [showEditNews, setShowEditNews] = useState(false);
  const [allnewsLatters, setAllnewsLatters] = useState();
  const [editId, setEditId] = useState(null);
  const { role_id, id } = useSelector((state) => state?.auth?.user);
  const [allCretor, setAllCreator] = useState();
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null
  });
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "is_deleted": 0
    }
  }

  useEffect(() => {
    refresh();
    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
    })
  }, [])
  const refresh = () => {
    dispatch(newslatterList(data)).then((res) => {
      setAllnewsLatters(res.payload.data);
    })
  }
  const { t } = useTranslation();

  const closeModal = () => {
    setShowEditNews(false);
    refresh();
  }
  const openShow = (id) => {
    setEditId(id)
    setShowEditNews(true);
  }
  const handleDelete = (id) => {
    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {

      let data = {
        'is_delete': 1,
        'id': popup.id
      }
      dispatch(removeNews(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            refresh();
          }
        })
        .catch((e) => {
          console.log(e)
        });

      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : '';
  }
  const getIdFromName = (name) => {
    console.log(name)
    let tempCreator = allCretor.filter((item => item.creator_name === name));
    return tempCreator[0]?.creator_id
  }
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className='page-wrapper flex-grow-1 py-0 page-block'>
        <InnerHeader title={t('newsletter_list')} subtitle="" />
        <Wrapper>

          <div >
            <div className='d-flex justify-content-end align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown'>
              {/* <h1 className='mb-sm-0  fw-semibold text-white h2'>{t('newsletter_list')}</h1> */}
              {role_id === 1 &&
                <>
                  <div className="right-button">
                    <Button variant="primary" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => openShow(null)}>{t('Add_New')}</Button>
                    {(showEditNews && !editId) &&
                      <AddNewslatter show={showEditNews} editId={null} editCourseId={null} editName={null} closeModal={closeModal} />
                    }
                    <Link to="/reorder-news" className='btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3'>{t('Ordering')}</Link>
                  </div>
                </>
              }
            </div>

            {/* Starts Table */}
            <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
              <Table bordered responsive className="text-center tableblock-wrap mb-0">
                <thead className='top-bordernone'>
                  <tr>
                    <th>{t('Images')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Beskrivning')}</th>
                    <th>{t('Nedladdningar')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('version')}</th>
                    <th>{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allnewsLatters && allnewsLatters.length > 0 && allnewsLatters?.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <span className='table-img mx-auto'>
                            <a href={item.url} target={'_blank'} >
                              <Image src={item.image ? item.image : '/assets/images/placeholder-image.png'} alt='Outlook' width={68} height={68} className="img-fit" />
                            </a>
                          </span>
                        </td>
                        <td>{item?.name}</td>

                        <td><p tabIndex={item.description} dangerouslySetInnerHTML={{ __html: add3Dots(item.description, 100) }}></p></td>
                        <td>{item.count}</td>
                        <td>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>
                        <td>{item.version}</td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center'>
                            <a className='btn btn-link icon-ic-edit text-black link-hover action-btn' onClick={() => openShow(item?.id)}></a>
                            {(showEditNews && editId === item?.id) &&
                              <AddNewslatter show={showEditNews} editId={item?.id} editCourseId={getIdFromName(item.creator_name)} editName={item.creator_name} closeModal={closeModal} />
                            }
                            <a onClick={() => handleDelete(item.id)} className='btn btn-link icon-ic-trash text-black link-hover action-btn'></a>
                            {popup.show && (
                              <ConfirmPopup
                                handleDeleteTrue={handleDeleteTrue}
                                handleDeleteFalse={handleDeleteFalse}
                                message={t('You_sure_you_wanna_delete')}
                              />
                            )}
                          </div>
                        </td>
                      </tr>

                    ))
                  }
                </tbody>
              </Table>
              {allnewsLatters && allnewsLatters.length === 0 &&
                <p className='text-center'>{t('No_records_found')}</p>
              }
            </div>
            {/* Ends Table */}
          </div>
        </Wrapper>
      </div>

    </div>
  )
}


export default NewslatterList;