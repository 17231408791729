import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Container, Row, Col } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {  educationEmail } from "../../store/api/podcast.api";
import { EDUCATION_LINK } from "../../services/httpService";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const ContactUsPopup = (prop) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setShow(prop.show);
    if (prop.editId) {
      setValue({
        ...value,
        ["name"]: prop.editName,
      });
    }
  }, []);
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onAdd = () => {
    if (value?.name.trim() == "") {
      toast.error("Name is required");
    } else if (value.email.trim() == "") {
      toast.error("Email is required");
    } else {
      let data = {
        ...value,
        education_link: EDUCATION_LINK,
      };
      dispatch(educationEmail(data))
        .unwrap()
        .then((res) => {
         
          if (res.status === 200) {
          
            setValue({
              email: "",
              name: "",
              phone: "",
              message: "",
            });
            setShow(false)
            //prop.callbackModal();
           setShowMessage(true)
          //  navigate("/thanks-education")
          } else {
            toast.error(res.message);
          }
        })
        .catch(() => {});
    }
  };
  const handleClose = () => {
    setShow(false);
    setShowMessage(false);
    prop.callbackModal();
  };
  return (
    <>
     
      <Modal centered show={show} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Intresseanmälan</Modal.Title>
          <span>{prop.titledata}</span>
        </Modal.Header>
        <Modal.Body className="p-20px">
          <Form  >
          <Form.Group controlId="formBasicEmail" className="mb-2">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block mobile-font-16">{t("Name")}</Form.Label>
              <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' placeholder={t("Name")} autoComplete="off" aria-label="exam_name" name="name" value={value.name} onChange={onChange} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail" className="mb-2">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block mobile-font-16">E-post</Form.Label>
              <Form.Control type="email" className='fs-20 mobile-font-14 form-inputblock fw-light' placeholder="Erik1992@gmail.com" autoComplete="off" aria-label="exam_name" name="email" value={value.email} onChange={onChange} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block mobile-font-16">Telefon</Form.Label>
              <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' placeholder="123" autoComplete="off" aria-label="exam_name" name="phone" value={value.phone} onChange={onChange} />
            </Form.Group>
          
          </Form>
          
          <div className="d-flex justify-content-end align-items-center pt-20px">

            <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark"  onClick={onAdd}>Skicka</Button>

          </div>
        </Modal.Body>
      </Modal>
      {showMessage  &&
      <Modal centered show={showMessage} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Tack för din ansökan!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-20px ">
          <span className="text-start fw-light text-svart block d-block">Vi kommer att höra av oss till dig inom 24 timmar.</span>

        </Modal.Body>
      </Modal>
        }
    </>
  );
};
export default ContactUsPopup;
