
import { Container, Table, Image,Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../commom/Header2';
import { getNote, removeNote } from '../../store/api/course.api';
import ConfirmPopup from '../commom/ConfirmPopup';
import EditNote from '../commom/EditNote';
import { useTranslation } from "react-i18next";
import moment from 'moment'
import InnerHeader from './InnerHeader';
import { Link } from 'react-router-dom';
const _ = require('lodash');
import { useNavigate } from "react-router-dom";

require('moment/locale/sw.js');
const NoteListing = () => {
   const dispatch = useDispatch();
   const [allNote, setAllNotes] = useState();
   const { id } = useSelector((state) => state.auth.user);
   const [show, setShow] = useState(false);
   const [editId, setEditId] = useState(null);
   const { t } = useTranslation();
   const navigate = useNavigate();
   const [popup, setPopup] = useState({
      show: false, // initial values set to false and null
      id: null
   });

   const handleDelete = (id) => {
      setPopup({
         show: true,
         id,
      });
   };
   const handleDeleteTrue = () => {
      if (popup.show && popup.id) {
         dispatch(removeNote(popup.id))
            .unwrap()
            .then((res) => {
               if (res.status === 200) {
                  refresh();
               }
            })
            .catch((e) => {
               console.log(e)
            });

         setPopup({
            show: false,
            id: null,
         });
      }
   };
   const handleDeleteFalse = () => {
      setPopup({
         show: false,
         id: null,
      });
   };
   useEffect(() => {
      refresh();
   }, [])

   const refresh = () => {
      let data = {
         "filter": {
            "n.user_id": id,
            "md.is_delete": 0

         }
      }
      dispatch(getNote(data)).then((res) => {
         setAllNotes(_.groupBy(res.payload.data,  'title'));
      })
   }
   const handleShow = (id) => {
      setEditId(id)
      setShow(true);
   }
   const callbackModal = () => {

      setShow(false);
      refresh();
   }
   const add3Dots = (string, limit) => {
      if (string) {
         var dots = "...";
         if (string?.length > limit) {
            string = string.substring(0, limit) + dots;
         }
      }
      return string != "null" ? string : '';
   }
   const gotoCourse = () =>{
      navigate('/my-course-details'); 
   }
   return (
      <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t('My_Notes')} subtitle="Här ser du dina reflektioner" />
            <div className='py-lg-40px px-xl-100px px-lg-30px px-20px flex-grow-1 table-borderwrap'>

            {/* Starts Table */}
            { allNote && Object.keys(allNote).length > 0 &&
               <div className='table-shadow'>

                  <Table bordered responsive className='text-center tableblock-wrap mb-0'>
                     <thead className='top-bordernone'>
                        <tr>
                           <th>{t('Kurs')}</th>
                           <th>{t('Antal')}</th>
                           <th>{t('Visa')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                              allNote && Object.keys(allNote).map(template_name => (
                              <tr >
                                 <td>{allNote[template_name][0]?.title}</td>
                                 <td>{allNote[template_name].length}</td>
                                 <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                       <Link to={'/reflaction-detail/'+allNote[template_name][0]?.course_id} className='btn btn-link icon-eye text-black link-hover action-btn'></Link>                                      
                                    </div>
                                 </td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </Table>
               </div>
            }
             { allNote && Object.keys(allNote).length === 0 &&
                     <div className="text-center max-w-469 mx-auto mt-sm-5 mt-3">
                     <div className="text-center mb-20px">
                       <Image src="/assets/images/No_notes.gif" alt="Du har inga diplom ännu" width={486} height={339} className="rounded-20px" />
                     </div>
                     <div className="text-start block mb-20px">
                       <h5 className="font-24 mobile-font-16 fw-medium mb-10px line text-center">Du har inte sparat några reflektioner ännu...</h5>
                       <span className="fw-medium font-20 mobile-font-14 text-center d-inline-block opacity-60">Tryck på knappen “reflektion” på kursidan för att lämna en reflektion om det du precis lärt dig.</span>
                     </div>
                     <Button variant="primary" onClick={gotoCourse}>Se alla kurser</Button>
                   </div>
                  }
            {/* Ends Table */}
            </div>

         </div>
      </div>
   )
}

export default NoteListing;