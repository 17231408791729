import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import EducationList from "./EducationList";
import Footer from "./Footer";
import Header from "./Header";
import Header1 from "./Header2";

const Education = () => {
  const { role_id } = useSelector((state) => state?.auth?.user || {});

  return (
    <>
      <div className="homepage">
        {!role_id && <Header />}
        {role_id && <Header1 />}
        <section className="banner-wrapper text-start position-relative bg-wrap">
          <Container fluid>
            <div className="banner-content-area">
              <div className="block font-32 text-center fw-lighter">
                <h1 className="text-white font-64 fw-semibold mb-md-3 mb-0">Utbildningar</h1>
                <p className="text-white d-md-block d-none">
                  Vidareutbilda dig hemifrån på kvällstid
                </p>
              </div>
            </div>
            <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
          </Container>
        </section>
        <section className="pb-100 education-block mobile-pt-40px">
          <Container fluid>
            <Row className="gy-md-3 position-relative">
              <Col md={5} className="order-md-0 order-1">
                <div>
                  <span className="text-white d-md-block d-none font-12 letter-spacing fw-semibold  mb-8 mobile-mb-20">
                    UTBILDNINGAR
                  </span>
                  <h2 className="text-white font-40 fw-lighter mb-1">
                    Utbildning på kvällstid
                  </h2>
                  <h4 className="text-white fw-medium font-24 mt-1 margin-b-16">
                    Kombinera jobb och studier
                  </h4>
                  <div className="text-white block fw-lighter mb-md-8 font-20 mobile-font-13">
                    <p>
                      Att kunna gå en utbildning ledd av kunniga universitetslärare digitalt på kvällstid erbjuder en möjlighet för dig som vill fortsätta arbeta och vidareutbilda dig samtidigt. Det kan även vara en lösning för dig som har familjeansvar, eller andra åtaganden som gör det svårt att delta i traditionella dagtidskurser.
                    </p>
                    <p>
                      För företag ger det en möjlighet att ge medarbetarna en chans till vidareutbildning utan att det påverkar det dagliga arbetet.{" "}
                    </p>
                  </div>
                  <Button variant="primary" className="d-none mobile-mt-20">
                    Skicka intresseanmälan
                  </Button>
                </div>
              </Col>
              <Col md={7} className="order-md-1 order-0">
                <span className="text-white d-md-none d-block font-12 letter-spacing fw-semibold  mb-8 mobile-mb-20">
                  UTBILDNINGAR
                </span>
                <div className="ps-md-10 ps-0 mobile-mb-20">
                  <Image
                    src="./assets/images/designicanva.png"
                    alt=""
                    className="rounded-20 w-100"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <EducationList />

        <Footer isBlack={false} />
      </div>
    </>
  );
};

export default Education;
