import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import {
  addProduct,
  getCources,
  getSingleMediaDetail,
  editProduct,
  addMp3,
} from "../../store/api/course.api";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ck_config } from "../commom/commonConst";

const AddNewProduct = (prop) => {
  // const editorRef = useRef(null);
  const [value, setValue] = useState({
    title: "",
    video_url: "",
    length: 0,
    description: "",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [allCources, setAllCources] = useState();
  const [selectedCourse, setselectedCourse] = useState(
    prop.editCourseId
      ? { value: prop.editCourseId, label: prop.editName }
      : null
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedMp3, setSelectedMp3] = useState(null);
  const [myfreeCourse, setMyfreeCourse] = useState(0);
  const editorConfiguration = ck_config;
  const [textContent, setTextContent] = useState(null);

  const options = [];
  const handleClose = () => {
    setShow(false);
    prop.callbackModal();
  };
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  const onEdit = () => {
    console.log(selectedCourse);
    var data = new FormData();
    data.append("id", prop.editId.toString());
    data.append("course_id", selectedCourse);
    data.append("title", value.title);
    data.append("video_url", value.video_url);
    data.append("is_free", myfreeCourse);
    data.append("length", value.length);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    data.append("description", textContent);
    dispatch(editProduct(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          var datamp3 = new FormData();
          datamp3.append("media_id", prop.editId.toString());
          datamp3.append("course_id", selectedCourse);
          if (selectedMp3) {
            datamp3.append("file", selectedMp3, selectedMp3.name);
          }
          setShow(false);
         prop.callbackModal();
          // dispatch(addMp3(datamp3))
          //   .unwrap()
          //   .then((resmp3) => {
          //     console.log(resmp3);
          //     //    setShow(false);
          //     //   prop.callbackModal();
          //   })
          //   .catch(() => {});
        }
      })
      .catch(() => {});
  };
  const onAdd = () => {
    var data = new FormData();
    data.append("id", selectedCourse);
    data.append("title", value.title);
    data.append("video_url", value.video_url);

    data.append("length", value.length);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }
    data.append("description", textContent);
    data.append("is_free", myfreeCourse);
    dispatch(addProduct(data))
      .unwrap()
      .then(() => {
        setShow(false);
        prop.callbackModal();
      })
      .catch(() => {});
  };
  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeCourse = (selectedOption) => {
    setselectedCourse(selectedOption.value);
  };
  useEffect(() => {
    dispatch(getCources(dataFilter)).then((course) => {
      setAllCources(course.payload.data.courses);
      // setselectedCourse(course.payload.data[0].id);
    });
    if (prop.editId) {
      dispatch(getSingleMediaDetail(prop.editId)).then((media) => {
        setselectedCourse(prop.editCourseId);

        setValue({
          ...value,
          ["title"]: media.payload.data[0].media_title,
          ["length"]: media.payload.data[0].media_length,
          ["video_url"]: media.payload.data[0].video_url,
        });
        setMyfreeCourse(media.payload.data[0].is_free);
        setTimeout(() => {
          setTextContent(
            media.payload.data[0].media_description != "null"
              ? media.payload.data[0].media_description
              : ""
          );
        }, 1000);
      });
    }
    setShow(prop.show);
  }, []);

  allCources &&
    allCources.map((list) =>
      options.push({ value: list.id, label: list.title })
    );
  const handleck = (e) => {
    setMyfreeCourse(e.target.checked ? 1 : 0);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {prop.editId ? (
              <span>{t("Edit_Product")}</span>
            ) : (
              <span>{t("Add_New_Product")}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="page-wrapper flex-grow-1 ">
            <Container fluid>
              <div className="max-950 overflow-hidden">
                <Form>
                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formTitle"
                  >
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder={t("Title")}
                      autoComplete="off"
                      aria-label="Title"
                      value={value.title}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-10 wow fadeInDown">
                    {options && (
                      <Select
                      className="custom-select black-theme"
                      classNamePrefix="myselect"
                        defaultValue={selectedCourse}
                        options={options}
                        onChange={onChangeCourse}
                      />
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formVideoLength"
                  >
                    <Form.Control
                      type="text"
                      name="length"
                      placeholder={t("Video_Length")}
                      autoComplete="off"
                      aria-label="length"
                      value={value.length}
                      onChange={onChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-10 wow fadeInDown"
                    controlId="formoriginalVideo"
                  >
                    <Form.Control
                      type="text"
                      name="video_url"
                      placeholder={t("Video_URL")}
                      autoComplete="off"
                      aria-label="video_url"
                      value={value.video_url}
                      onChange={onChange}
                    />
                  </Form.Group>

                  <div className="mb-10 wow fadeInDown">
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={textContent}
                      placeholder="Enter data"
                      onChange={(event, editor) => {
                        setTextContent(editor.getData());
                      }}
                    />
                  </div>
                  <Row className="gy-10">
                    <Col sm={6}>
                      <div className="mb-10 wow fadeInDown">
                        <input
                          type="file"
                          id="file1"
                          className="visually-hidden"
                          onChange={(e) => setSelectedFile(e.target.files[0])}
                        />
                        <label
                          htmlFor="file1"
                          className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                        >
                          <span className="file-icon">
                            <Image
                              src="/assets/images/Video_file_light.png"
                              alt="Select Image"
                              width="48"
                              height="48"
                            />
                          </span>
                          <span>{t("Select_Image")}</span>
                        </label>
                      </div>
                    </Col>
                    {/* <Col sm={6}>
                      <div className="mb-10 wow fadeInDown">
                        <input
                          type="file"
                          id="file2"
                          className="visually-hidden"
                          onChange={(e) => setSelectedMp3(e.target.files[0])}
                        />
                        <label
                          htmlFor="file2"
                          className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                        >
                          <span className="file-icon">
                            <Image
                              src="/assets/images/Video_file_light.png"
                              alt="Select Image"
                              width="48"
                              height="48"
                            />
                          </span>
                          <span>{t("Select_Mp3")}</span>
                        </label>
                      </div>
                    </Col> */}
                  </Row>
                  <div className="p-default">
                    <label>
                      {" "}
                      <input
                        type="checkbox"
                        name="is_free"
                        checked={myfreeCourse === 1 ? true : false}
                        onChange={handleck}
                      />{" "}
                      {t("is_free")}{" "}
                    </label>
                  </div>

                  {!prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onAdd}
                    >
                      {" "}
                      {t("Save")}{" "}
                    </Button>
                  )}
                  {prop.editId && (
                    <Button
                      variant="primary"
                      type="button"
                      className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                      onClick={onEdit}
                    >
                      {" "}
                      {t("Update")}{" "}
                    </Button>
                  )}
                </Form>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewProduct;
