import React, { useState, useEffect } from 'react';
import Header from '../commom/Header';
import { Container, Image, Accordion } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { newslatterList } from '../../store/api/course.api';
import { useDispatch } from 'react-redux';
import SubscribeForm from '../commom/SubscribeForm';
import { toast } from 'react-toastify';
import Footer from '../commom/Footer';
import TakeEmail from './TakeEmail';
const Newslatters = () => {
  const dispatch = useDispatch();
  let data = {
    "filter": {
      "is_deleted": 0
    }
  }
  const [allnewsLatters, setAllnewsLatters] = useState();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [editId, setEditId] = useState(null);
  const [url, setUrl] = useState(null);
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  useEffect(() => {
    refresh();
  }, []);
  const refresh = () => {
    dispatch(newslatterList(data)).then((res) => {
      setAllnewsLatters(res.payload.data);
    })
  }
  const showMessage = (id,url) => {
    
  //  toast.success('Du måste logga in för att ladda ner denna boken');
  setEditId(id)
  setUrl(url)
  setShowEmailPopup(true);
  }
  const closeModal = (flag) => {
    setShowEmailPopup(false);
   // refresh();
   if(flag)
   window.open(url, 'Download');
  }
  
  return (

    <div className='d-flex flex-column flex-grow-1 homepage'>
      <Header />
      <div className='page-wrapper flex-grow-1 py-0'>
        {/* Starts Page Banner */}

        <div className='banner-wrapper position-relative'>
          <div className="banner-video">
            <video src="/assets/video/Background-flow.mp4" autoplay="autoplay" playsinline="playsinline"
              muted="muted"
              loop="loop">
            </video>
          </div>
         <SubscribeForm chk_id={1}/>
        </div>
        {/* Ends Page Banner */}
        {/* Starts content with banner section */}
        <section className="cnt-with-image">
          {
           allnewsLatters && allnewsLatters.length >0 && allnewsLatters?.slice(0, 3)?.map((item, index) => (
              <div key={index} className={`content-image-box banner${index + 1}`}>
                <div className='book-images'>
                  {/* <Image  src={`/assets/images/book${index+1}.png`} width={725} height={517} alt="book"/> */}
                  <Image className={`my-image${index + 1}`} src={item?.image} width={725} height={517} alt="book" />
                </div>
                <Container fluid>
                  <div className='cnt-content'>
                    <h2 className="fw-normal">{item?.name} </h2>
                    <h4 className=" fw-bold mt-1 mt-md-5">{item?.sub_title !== 'null' ? item?.sub_title : ''}  </h4>
                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                    <div className="d-flex mt-3 align-items-start justify-content-start cnts-btn">
                      <a  onClick={()=>showMessage(item?.id,item.url)} className={`btn flex-shrink-0  d-inline-flex align-items-center  ${index == 1? 'btn-outline-secondary' : 'btn btn-outline-light a-hover'}`} >{t('ladda_ned')} <span className='icon-right-angle-icon d-block ms-2'></span></a>
                      {(showEmailPopup && editId === item?.id) &&
                                <TakeEmail show={showEmailPopup} editId={item?.id}  closeModal={(e)=>closeModal(e)}  />
                              }
                      
                    </div>
                  </div>
                </Container>
              </div>
            ))}
        </section>
        {/* Starts content with banner section */}
        <section className='dark-bakground pt-5 pb-6'>
          <Container fluid>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              {/* <h2 className="font-medium fw-light">{t('Logga_in_Ladda_ner')} </h2> */}
              <h2 className="font-medium fw-light" >{t('Logga_in')} <span className='font-medium fw-medium'>{t('Ladda_ner')}.</span></h2>
              <p className='mb-0'>{t('Som_inloggad_på_ditt_Docens_konto')} <strong> {t('Inget_konto')} </strong>  </p>
              <p>{t('Det_är_helt_kostnadsfritt')}</p>
              <div className="d-flex mt-3 align-items-start justify-content-center">
                <Link to='/logga-in' className='btn btn-primary flex-shrink-0  d-inline-flex align-items-center'>{t('SignIn')} <span className='icon-right-angle-icon d-block ms-2'></span></Link>
                <Link to='/registrera' className='btn btn-outline-light flex-shrink-0  d-inline-flex align-items-center a-hover  ms-4'>{t('Create_your_account')} <span className='icon-right-angle-icon d-block ms-2'></span></Link>
              </div>
            </div>
          </Container>
        </section>
        <section className='hv-100 gray-background pt-10 pb-10'>
          <Container fluid>
            <Accordion defaultActiveKey="0" className='ns-accordion'>
              {
                allnewsLatters && allnewsLatters.length >0 &&  allnewsLatters?.map((item, index) => (
                  <Accordion.Item key={index} eventKey={`${index}`}>
                    <Accordion.Header className='d-flex align-items-center'><span className='d-block me-4 fw-bold'> {item.version}</span><h4 className='fw-normal mb-0'>{item?.creator_name} | <strong>{item?.name}</strong></h4></Accordion.Header>
                    <Accordion.Body>
                      <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                      <div className="d-flex mt-3 align-items-start justify-content-start">
                        <a onClick={()=>showMessage(item?.id,item.url)} className='btn btn-primary flex-shrink-0  d-inline-flex align-items-center a-hover'>{t('ladda_ned')} <span className='icon-right-angle-icon d-block ms-2'></span></a>
                        {(showEmailPopup && editId === item?.id) &&
                                <TakeEmail show={showEmailPopup}  editId={item?.id}  closeModal={(e)=>closeModal(e)}  />
                              }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </Container>
        </section>
        {/* Starts bottom email section */}
        <section className="dark-background hv-100 border-bottom">
        <SubscribeForm chk_id={2}/>
        </section>
      </div>
      <Footer/>
    </div>
  )
}

export default Newslatters;