import { Accordion, Button, Col, Container, Image, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "../commom/Header2";
import Header1 from "../commom/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ContactUsPopup from "./ContactUsPopup";
import EducationContactForm from "./EducationContactForm";
import { useDispatch, useSelector } from "react-redux";
import { getEducations } from "../../store/api/podcast.api";
import { isMobile } from "react-device-detect";

const IndividualEducation = () => {
  const { educationid } = useParams();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('Beskrivning');
  const [educationData, setEducationData] = useState();
 
  const [isShowForm,setIsShowForm] = useState(false);
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const { role_id } = useSelector((state) => state?.auth?.user || {});
  const navigate = useNavigate();

 
  const callbackModal = () => {
    setIsShowForm(false);
  };
  const showForm1 = () => {
    
    setIsShowForm(true);
   
  }
  useEffect(() => {
    if(isShowForm)
    scrollToSection('menuid');

  }, [isShowForm]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
};

useEffect(() => {
  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
      slug:educationid
    },
    
  };
  dispatch(getEducations(dataFilter)).then((course) => {
    setEducationData(course.payload.data[0]);
  });
}, []);
const setTab = (tab) => {
  setSelectedTab(tab);
}
  return (
    <>
      <div
        className={` header-border-none ${
          !role_id ? "is-not-login pt-0" : "pt-100"
        }`}
      >
         {role_id && <Header />}
        {!role_id && <Header1 />}

        <div className="main-wrapper">
          <Container fluid>
            <section className=" pt-69 individualeducation-block mobile-pt-15">
              <Row className="gy-md-3 flex-md-row flex-column-reverse align-items-center">
                <Col md={5}>
                <div className="tab-courses d-flex align-items-center flex-wrap gap-5 d-md-none fw-medium mobile-mt-20 mb-md-5 mb-2">
                    <a role="button" className={`linkblock ${selectedTab === 'Beskrivning' ? "active" : ""}`} onClick={() => setTab('Beskrivning')}>Beskrivning</a>
                    <a role="button" className={`linkblock ${selectedTab === 'Mer_info' ? "active" : ""}`} onClick={() => setTab('Mer_info')}>Mer info</a>
                  </div>
                  <div>
                  <div className={`tabblock-wrap mobile-pb-60 ${selectedTab === 'Beskrivning' ? "active" : ""}`}>
                    <span className="text-white font-12 letter-spacing fw-semibold  mb-lg-8 mb-1 d-md-block d-none">
                      UTBILDNING
                    </span>
                    <h2 className="text-white font-40 fw-light mb-1">
                      {educationData?.title}
                    </h2>
                    <h4 className="text-white fw-medium font-24 mt-1 margin-b-16 mobile-font-14">
                      {educationData?.sub_title}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: add3Dots(educationData?.content, 500),
                      }}
                      className="text-white block fw-lighter mb-8 font-20 mobile-font-13"
                    ></div>
                   <div className="individule_button mb-0">
                    <Button className="w-100 rounded-pill font-22 mobile-font-16 btn-small" onClick={showForm1} variant="gradient">
                     <span>Anmäl ditt intresse</span> 
                    </Button>
                    </div> 
                  </div>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="">
                    <Image
                      src={educationData?.image}
                      alt=""
                      className="rounded-20 w-100 h-375 img-fit"
                    />
                  </div>
                </Col>
              </Row>
            </section>
            
           
            <section  className={`d-flex flex-wrap mobile-pb-100 pb-100 ${!isMobile ? "pt-100" : ""} `} id={`${!isMobile ? "menuid" : ""}`}>
             
            <div className="only_mobile d-none">
            <div className={`tabblock-wrap  mobile-pb-60 ${selectedTab === 'Mer_info' ? "active" : ""}`}>
              <div className="text-white content-block block  font-20 mobile-font-13">
                <div
                  className="mb-13 fw-lighter"
                  dangerouslySetInnerHTML={{
                    __html: educationData?.description,
                  }}
                />
              </div>
              </div>
              </div>
             
             
              <div className="hide_in_mobile text-white content-block block  font-20 mobile-font-13">
                <div
                  className="mb-13 fw-lighter"
                  dangerouslySetInnerHTML={{
                    __html: educationData?.description,
                  }}
                />
              </div>
              <div className={` ${isShowForm ? "width-461" : ""} ${isMobile ? "pt-100" : ""} `}   id={`${isMobile ? "menuid" : ""}`}>
              {isShowForm &&
                     <EducationContactForm  educationData={educationData} callbackModal={callbackModal} />
              }
            </div>
              {!isShowForm &&
              <div className="p-md-32 p-6 bg-darkblock flex-shrink-0 text-white fw-lighter font-16 width-461 set-height">
                <h2 className="text-white font-32 fw-lighter">
                  {educationData?.title}
                </h2>
                <div>
                  <h3 className="fw-medium font-20 mobile-font-16 mb-2">
                    Det här kommer du lära dig
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: educationData?.what_you_will_learn,
                    }}
                    className="mb-0 webinar-section-text"
                  />
                </div>
                <div>
                  <h3 className="fw-medium font-20 mobile-font-16 mb-2">
                    Den här utbildningen passar för dig som
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: educationData?.suitable_for,
                    }}
                    className="mb-0 webinar-section-text"
                  />
                </div>
                <div>
                  <h3 className="fw-medium font-20 mobile-font-16 mb-2">Kurslängd</h3>
                  <p className="mb-0 webinar-section-text">{educationData?.course_length}</p>
                </div>
                <div>
                  <h3 className="fw-medium font-20 mobile-font-16 mb-2">Kursstart</h3>
                  <p className="mb-0 webinar-section-text">{educationData?.course_start}</p>
                </div>
                <div>
                  <h3 className="fw-medium font-20 mobile-font-16 mb-2">Kursavgift</h3>
                  <p className="mb-0 webinar-section-text">{educationData?.course_fee}</p>
                </div>
                <div>
                  <h3 className="fw-medium font-20 mobile-font-16 mb-2">Lärare</h3>
                  <p className="mb-0 webinar-section-text">{educationData?.extent}</p>
                </div>
                <Button
                  onClick={showForm1}
                  variant="gradient"
                  className="w-100 btn-small rounded-pill mt-md-2 mt-0 mb-1"
                  type="button"
                >
                  <span>Anmäl ditt intresse</span>
                </Button>
              </div>
                }
            </section>
          

            <section className="text-white accordion-dark">
              <h2 className="text-center font-40 fw-lighter mb-3">
                Vanliga frågor
              </h2>
              <Accordion defaVanliga frågorultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Hur anmäler jag mig till era utbildningar?</Accordion.Header>
                  <Accordion.Body>
                  Genom att skicka en intresseanmälan till oss får du mer information om vilket datum nästa kursstart är inplanerad. Din intresseanmälan är inte bindande, utan först när du fått startdatum och betalat in kursavgiften är du anmäld till kursen.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                  Vad händer om jag missar ett lektionstillfälle?
                  </Accordion.Header>
                  <Accordion.Body>
                  Varje lektionstillfälle spelas in och finns tillgängligt under de 8 veckor kursen pågår. Du kan dock inte interagera eller ställa frågor i efterhand.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Vänder era utbildningar sig till privatpersoner eller företag?</Accordion.Header>
                  <Accordion.Body>
                  Våra kurser vänder sig till såväl privatpersoner, som till företag som vill vidareutbilda sin personal. Om du representerar ett företag som vill anmäla flera medarbetare till samma kurs, kontakta oss på info@docens.se
                  </Accordion.Body>
                </Accordion.Item>
                
              </Accordion>
            </section>
          </Container>
        </div>
        <Footer isBlack={false} />
      </div>
 
    
    </>
  );
};

export default IndividualEducation;
