import React, { useEffect, useState } from "react";
import Header from "../commom/Header2";
import { Row, Col, Image, Button, ProgressBar, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getnotification,
  getCources,
  getSingleUser,
  privateCourseStatistics,
  watchhistory
} from "../../store/api/course.api";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import TextBlock from "../component/TextBlock";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
require("moment/locale/sw.js");
import {
  checkSubsDate,
  DESIGNICANVA
} from "../commom/commonConst";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { business_id, first_name, last_name, role_id, id } = useSelector(
    (state) => state?.auth?.user
  );
  const user = useSelector((state) => state?.auth?.user);
  const { t } = useTranslation();
  const [singleUser, setSingleUser] = useState();
  const [limit, setLimit] = useState(4);
  const [memberWithHighestMaxScore, setmemberWithHighestMaxScore] = useState();
  const navigate = useNavigate();
  const [allCources, setAllCources] = useState();
  const [dataWithDeadline, setDataWithDeadline] = useState();
  const [mediaList, setMediaList] = useState();
  let dataH = {
    user_id: id
  }
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
    //  "page":"1",
    //  "limit":"10"
  };
  useEffect(() => {
    if (role_id === 4) {

      dispatch(getSingleUser(id)).then((res) => {
        setSingleUser(res.payload.data[0]);
        // const highestMaxScore = Math.max(...res.payload.data[0].assign_course.map(item => item.course_progress));
        //  setmemberWithHighestMaxScore(res.payload.data[0].assign_course.find(member => member.course_progress === highestMaxScore));
        setDataWithDeadline(res.payload.data[0].assign_course.slice(0, 4)?.filter((item) => item.expired_at !== null));

      })
      dispatch(getnotification()).then((res) => {
        if (res.payload.data?.list) {
          setAllNotification(res.payload.data?.list.slice(0, 5));
        }
      });
    } else {
      // dispatch(privateCourseStatistics({})).then((res) => {
      //   const highestMaxScore = Math.max(...res.payload.data.map(item => item.overallProgress));
      //    setmemberWithHighestMaxScore(res.payload.data.find(member => member.overallProgress === highestMaxScore && highestMaxScore >0))
      // })
      dispatch(getCources(dataFilter)).then((course) => {
        setAllCources(
          course?.payload?.data?.courses.filter(
            (item) =>
              item.is_purchaed === true ||
              item.is_assigned === true ||
              checkSubsDate(user?.subscription?.trial_end) == 1
          )
        );
      });
    }
    dispatch(watchhistory(dataH)).then((res) => {
      setMediaList(res?.payload?.data);
    })
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false
        },
      },
      {
        breakpoint: 575,
        settings: {
          arrows: true
        },
      },
    ]

  };

  const gotocourse = (id) => {
    navigate("/private-open-course-topics/" + id);
  }
  const gotoMedia = (id,media_id) => {
    navigate("/private-open-course-topics/" + id+"/"+media_id);
  }
  const gotoTip = (url) => {
    navigate(url);
  }
  const gotoDead = () => {
    navigate('/deadline');
  }
  return (
    <div className="innerblock-page d-flex flex-grow-1 flex-column">
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block">
        <InnerHeader title={t("Dashboard")} subtitle={t("Welcome_to_Admin")} />

        <Wrapper>
          <Row className="g-20px">

            <Col lg={6}>

              {/* <div className="shadow-block rounded-20 text-svart bg-white">
                <div className="p-md-20px p-16px title-header">
                  <TextBlock title="Forsätt där du slutade" subtitle="Vi håller koll på sånt!" />
                </div>
                {memberWithHighestMaxScore && (
                <div className="p-md-20px p-16px">
                {role_id === 4 && (
                        <div className="rounded-17 overflow-hidden max-w-445 mx-auto position-relative videoprogress-block">
                          <Image src={memberWithHighestMaxScore?.course_image} alt="Hållbarssmet" />
                          <ProgressBar now={memberWithHighestMaxScore?.course_progress} variant="green" className="position-absolute bottom-0 start-0 end-0 video-progress" />
                          <div className="text-grey-block position-absolute bottom-22px videotext-block">
                            <span className="d-block font-16 fw-semibold">{memberWithHighestMaxScore?.course_title}</span>
                            <span className="fw-light">{memberWithHighestMaxScore?.creator_name}</span>
                          </div>
                        </div>
                   )}
                   {role_id !== 4 && (
                    <div className="rounded-17 overflow-hidden max-w-445 mx-auto position-relative videoprogress-block">
                          <Image src={memberWithHighestMaxScore?.image} alt="Hållbarssmet" />
                          <ProgressBar now={memberWithHighestMaxScore?.overallProgress} variant="green" className="position-absolute bottom-0 start-0 end-0 video-progress" />
                          <div className="text-grey-block position-absolute bottom-22px videotext-block">
                            <span className="d-block font-16 fw-semibold">{memberWithHighestMaxScore?.title}</span>
                            <span className="fw-light">{memberWithHighestMaxScore?.creator_name}</span>
                          </div>
                        </div>
                   )}
                  <div className="text-center pt-md-20px pt-16px">
                  {role_id === 4 && (
                    <Button variant="primary" className="primarybtn-hover" onClick={() => gotocourse(memberWithHighestMaxScore.course_id)}>Forsätt titta</Button>
                    )}
                    {role_id !== 4 && (
                    <Button variant="primary" className="primarybtn-hover" onClick={() => gotocourse(memberWithHighestMaxScore.id)}>Forsätt titta</Button>
                    )}
                  </div>
                </div>
                 )}
                 {!memberWithHighestMaxScore &&
                  <div className="p-md-20px p-16px">
                 <p className='text-center'>{t('No_records_found')}</p>
                 </div>
                 }
              </div> */}


              <div className="shadow-block rounded-20 text-svart bg-white h-100">
                <div className="p-md-20px p-16px title-header">
                  <TextBlock title="Forsätt där du slutade" subtitle="Vi håller koll på sånt!" />
                </div>
                {mediaList && mediaList.length > 0 && (
                  <div className="dashboard-shadow-box">

                    <div className="rounded-17 overflow-hidden  position-relative videoprogress-block">
                      <Image src={mediaList[0]?.course_image} alt="Hållbarssmet" />
                      <ProgressBar now={mediaList[0]?.view_time} variant="green" className="position-absolute bottom-0 start-0 end-0 video-progress" />
                      <div className="text-grey-block position-absolute bottom-22px videotext-block">
                        <span className="d-block font-16 fw-semibold">{mediaList[0]?.title}</span>
                        <span className="fw-light">{mediaList[0]?.course_title}</span>
                      </div>
                    </div>


                    <div className="text-center pt-md-20px pt-16px">
                      <Button variant="primary" className="primarybtn-hover" onClick={() => gotoMedia(mediaList[0].course_id,mediaList[0].media_id)}>Forsätt titta</Button>
                    </div>
                  </div>
                )}
                {mediaList && mediaList.length === 0 &&
                  <div className="p-md-20px p-16px">
                    <p className='text-center'>{t('No_records_found')}</p>
                  </div>
                }
              </div>

            </Col>

            <Col lg={6}>
              {role_id === 4 && (
                <>

                  <div className="shadow-block rounded-20 text-svart bg-white">
                    <div className={`p-md-20px p-16px ${dataWithDeadline?.length > 0 ? "" : "title-header"}`}>
                      <TextBlock title="Deadlines" subtitle="Här ser du dina deadlines" />
                    </div>
                    <div className="pb-md-20px pb-16px">
                      <div className="">
                        <Table bordered responsive className="text-center tableblock-wrap mb-0 shadow-none ">

                          <tbody >
                            {dataWithDeadline && dataWithDeadline?.length > 0 && dataWithDeadline.map((course, key) => (
                              <tr >
                                <td>{course?.course_title}</td>
                                <td>{moment(course?.expired_at).format(
                                  "DD-MM-YYYY"
                                )}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center pt-md-20px pt-16px">
                        {dataWithDeadline?.length > 0 &&
                          <Button variant="primary" className="primarybtn-hover" onClick={gotoDead}>Se mer</Button>
                        }

                        {dataWithDeadline?.length === 0 &&
                          <p>Du har inga deadlines...🥳</p>
                        }
                      </div>
                    </div>
                  </div>

                </>
              )}
              {role_id !== 4 && (
                <div className="shadow-block rounded-20 text-svart bg-white">
                  <div className="p-md-20px p-16px title-header">
                    <TextBlock title="Kommande" subtitle="Kommer snart på Docens" />
                  </div>
                  {/* <div className="p-md-20px p-16px">
                       <div className="rounded-17 overflow-hidden max-w-445 mx-auto position-relative videoprogress-block">
                         <Image src="../assets/images/dash1.png" alt="Hållbarssmet" />
                         <div className="text-grey-block position-absolute bottom-6px videotext-block">
                           <span className="d-block font-16 fw-semibold">Grundläggande företagsjuridik</span>
                           <span className="fw-light">Malki Afram</span>
                         </div>
                       </div>
                 <div className="text-center pt-md-20px pt-16px">
                   <Button variant="primary" className="primarybtn-hover" onClick={() => gotocourse(DESIGNICANVA)}>Se kurs</Button>
                 </div>
               </div> */}
                  <div className="dashboard-shadow-box">
                    <Slider {...settings} className="sliderbox-wrap dashboard-sliderbox">

                      <div className="rounded-17 overflow-hidden w-100 position-relative videoprogress-block">
                        <Image className="w-100" src="../assets/images/dash1.png" alt="Hållbarssmet" />
                        <div className="text-grey-block position-absolute bottom-12px videotext-block left-text">
                          <span className="fw-light">Grundläggande företagsjuridik</span>
                          <span className="d-block font-16 fw-semibold">Malki Afram</span>
                        </div>
                      </div>
                      <div className="rounded-17 overflow-hidden w-100 position-relative videoprogress-block">
                        <Image className="w-100" src="../assets/images/Egna_interna.png" alt="Hållbarssmet" />
                        <div className="text-grey-block position-absolute bottom-12px videotext-block left-text">
                          <span className="fw-light">Självledarskap</span>
                          <span className="d-block font-16 fw-semibold">Marie Pervik</span>
                        </div>
                      </div>
                    </Slider>
                 
                  </div>
                </div>
              )}
            </Col>

            {role_id === 4 && (
              <>
                {singleUser?.assign_course?.length > 0 && (
                  <Col md={12}>
                    <div className="shadow-block rounded-20 text-svart bg-white">
                      <div className="p-md-20px p-16px title-header">
                        <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24">Mina kurser</h4>
                        <span className="opacity-60 fw-medium fs-20 mobile-font-14">Alla kurser du har tillgång till</span>
                      </div>
                      <div className="p-md-20px p-16px d-flex flex-column gap-20px flex-col">
                        {singleUser?.assign_course?.length > 0 &&
                          singleUser?.assign_course.slice(0, limit)?.map((course, key) => (
                            <div className="d-flex gap-20px align-items-center justify-content-between">
                              <div className="d-flex align-items-center gap-20px">
                                <div className="small-image flex-shrink-0">
                                  <Image src={course?.course_image} width={58} height={61} alt="Mina kurser" className="img-fit" />
                                </div>
                                <div>
                                  <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24">{course?.course_title}</h4>
                                  <span className="opacity-70 fw-medium fs-20 mobile-font-14">{course?.creator_name}</span>
                                </div>
                              </div>
                              <div className="flex-shrink-0">
                                <Button variant="outline-dark" title="Se kurs" className="py-2 fs-16" onClick={() => gotocourse(course.course_id)}>Se kurs</Button>
                              </div>
                            </div>
                          ))}
                        {limit === 4 &&
                          <div className="text-center">
                            <Button type="button" className="fw-light opacity-60 text-svart link-hover fs-20" onClick={() => setLimit(100)}>Visa mer</Button>
                          </div>
                        }
                        {limit !== 4 &&
                          <div className="text-center">
                            <Button type="button" className="fw-light opacity-60 text-svart link-hover fs-20" onClick={() => setLimit(4)}>Visa mindre</Button>
                          </div>
                        }
                      </div>
                    </div>
                  </Col>
                )}
              </>
            )}
            {role_id !== 4 && (
              <>
                {allCources?.length > 0 && (
                  <Col md={12}>
                    <div className="shadow-block rounded-20 text-svart bg-white">
                      <div className="p-md-20px p-16px title-header">
                        <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24">Mina kurser</h4>
                        <span className="opacity-60 fw-medium fs-20 mobile-font-14">Alla kurser du har tillgång till</span>
                      </div>
                      <div className="p-md-20px p-16px d-flex flex-column gap-20px flex-col">
                        {allCources?.length > 0 &&
                          allCources.slice(0, limit)?.map((course, key) => (
                            <div className="d-flex gap-20px align-items-center justify-content-between">
                              <div className="d-flex align-items-center gap-20px">
                                <div className="small-image flex-shrink-0">
                                  <Image src={course?.image} width={58} height={61} alt="Mina kurser" className="img-fit" />
                                </div>
                                <div>
                                  <h4 className="text-svart fw-medium mobile-font-16 mb-0 fs-24">{course?.title}</h4>
                                  <span className="opacity-70 fw-medium fs-20 mobile-font-14">{course?.auther_details?.name}</span>
                                </div>
                              </div>
                              <div className="flex-shrink-0">
                                <Button variant="outline-dark" title="Se kurs" className="py-2 fs-16" onClick={() => gotocourse(course.id)}>Se kurs</Button>
                              </div>
                            </div>
                          ))}
                        {limit === 4 &&
                          <div className="text-center">
                            <Button type="button" className="fw-light opacity-60 text-svart link-hover fs-20" onClick={() => setLimit(100)}>Visa mer</Button>
                          </div>
                        }
                        {limit !== 4 &&
                          <div className="text-center">
                            <Button type="button" className="fw-light opacity-60 text-svart link-hover fs-20" onClick={() => setLimit(4)}>Visa mindre</Button>
                          </div>
                        }
                      </div>
                    </div>
                  </Col>
                )}
              </>
            )}

            <Col md={6} onClick={() => gotoTip('/reflektioner-tip')}>
              <div className="flex justify-between bg-white rounded-20 p-md-20px p-16px border-block gap-16px boxlink">
                <div className="flex-grow-1">
                  <TextBlock title="Reflektioner" subtitle="Såhär använder du reflektionsverktyget" />
                </div>
                <div className="flex-shrink-0 text-end">
                  <Link to="/reflektioner-tip" className="text-svart fs-20 mobile-font-13 linkbox-wrap fw-medium link-hover">Tips</Link>
                </div>
              </div>
            </Col>
            <Col md={6} onClick={() => gotoTip('/diplom-tip')}>
              <div className="flex justify-between bg-white rounded-20 p-md-20px p-16px border-block gap-16px boxlink">
                <div className="flex-grow-1">
                  <TextBlock title="Diplom" subtitle="Hämta ut ditt diplom" />
                </div>
                <div className="flex-shrink-0 text-end">
                  <Link to="/diplom-tip" className="text-svart fs-20 mobile-font-13 linkbox-wrap fw-medium link-hover">Tips</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Wrapper>




      </div>
    </div>
  );
};

export default Dashboard;
