import React, { useEffect, useState } from 'react';
import { Form, Image, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getSingleUser, getCertificate } from '../../store/api/course.api';
import Slider from 'react-rangeslider'
import { useTranslation } from "react-i18next";
import moment from 'moment'
require('moment/locale/sw.js');
const ViewUserDetail = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   let dataFilter = {
      "filter": {
         "c.user_id": prop.id
      }
   }
   const [show, setShow] = useState(false);
   const [singleUser, setSingleUser] = useState();
   const [allCerti, setAllCerti] = useState();
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   }



   useEffect(() => {
      setShow(prop.show)
      dispatch(getSingleUser(prop.id)).then((res) => {
         setSingleUser(res.payload.data[0]);
      })
      dispatch(getCertificate(dataFilter)).then((res) => {
         setAllCerti(res.payload.data);
      });
   }, [])

   return (
      <>
         <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title>{singleUser?.first_name} {singleUser?.last_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='usermanagement-modal'>
               <Form className='max-511 mx-auto'>
                  {
                     singleUser?.assign_course?.map((course, key) => (
                        <div key={course.course_id} className='slider-horizontal mb-13'>
                           <h3 className='text-white mb-5  fw-light font-18'>{course.course_title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                           { course?.expired_at &&
                           <span className='text-primary font-18 me-6'> Dedline: {moment(course?.expired_at).format("DD-MMM-YYYY") }</span>
                           }
                           </h3>
                           
                           <Slider
                              min={0}
                              max={100}
                              value={course.course_progress?course.course_progress + '%':0 + '%'}
                              orientation='horizontal'
                              handleLabel={course.course_progress?course.course_progress + '%':0 + '%'}
                           />
                        </div>
                     ))
                  }

               { allCerti && allCerti.length > 0 &&
                  <div className='pt-13 mb-13'>
                     <h3 className='text-white mb-5'>{t('Task_and_Certification')}</h3>
                     <div className='d-flex flex-wrap w-100 certificate-imgwrap'>
                        {
                           allCerti && allCerti.length > 0 && allCerti?.map((certi, key) => (
                              <div className='certificate-img'>
                                 <a href={'https://docens.se:3005/'+ certi.certificate_url} target="_blank" className=''> <Image src='/assets/images/Congratulation-Certificate.png' alt='Certi ' width={76} height={76} className="img-fit" /></a>
                              </div>
                           ))
                        }

                     </div>
                  </div>
}
                  {(singleUser?.assign_course.length === 0) &&
                     <p className='text-center'>{t('No_records_found')}</p>
                  }
               </Form>

            </Modal.Body>
         </Modal>

      </>
   )
}

export default ViewUserDetail;