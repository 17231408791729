import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { getPodcasts, podcasttagList } from "../../store/api/podcast.api";
import Select from "react-select";

const PodcastList = (prop) => {
  const dispatch = useDispatch();
  const { role_id, id } = useSelector((state) => state?.auth?.user || {});
  const [allPodcasts, setAllPodcasts] = useState();
  const [tagListItems, setTagList] = useState([]);
  const { t } = useTranslation();
  const { tagId, index } = useParams();
  const [imageIndex, setImageIndex] = useState(-1);
  const navigate = useNavigate();

  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    },
    user_id: id,
    role_id: role_id,
  };

  useEffect(() => {
    if (tagId && tagId != 1) {
      setImageIndex(index);
      dataFilter.tags = tagId.toString();
    }
    dispatch(getPodcasts(dataFilter)).then((podcast) => {
      setAllPodcasts(podcast.payload.data);
    });
    dispatch(podcasttagList()).then((data) => {
      setTagList(
        data.payload.data.filter((item) => item.name !== "Mina podcast")
      );
    });
  }, []);

  const customSort = (item, index) => {
    setImageIndex(item.value);
    if (item.label === "Alla Podcast") {

    // if (item.name === "Alla Podcast") {
      delete dataFilter.tag;
      dispatch(getPodcasts(dataFilter)).then((podcast) => {
        setAllPodcasts(podcast.payload.data);
      });
    } else {
      dataFilter.tags = item.value.toString();

      // dataFilter.tags = item.id.toString();
      dispatch(getPodcasts(dataFilter)).then((podcast) => {
        setAllPodcasts(podcast.payload.data);
      });
    }
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const MediaList = ({ item, index }) => {
    return (
      <Col md={4} sm={6} className="wow fadeInDown">
        <Link
          to={"/open-podcast-topics/" + item.podcast_id}
          className="getstart-block getstart-block-new  position-relative d-flex flex-column feature-box"
        >
          <div className="get-started-img">
            <Image
              className="img-fit feature-img"
              src={item.image ? item.image : "/assets/images/Videi-1.jpeg"}
              alt="Image"
              width={526}
              height={348}
            />
          </div>
          <div className="img-content text-white d-flex align-items-start justify-content-end py-3 py-md-5">
            <div className="bottom-text text-start align-center">
              <h5 className="mb-1 fw-light cursor_class">
                {item.title}
              </h5>
              <div className="d-flex  flex-md-row flex-column">
                <span>
                  <p className="fw-semibold cursor_class  mb-0 fs-16 mr-5">
                    {item?.name_of_guest}
                  </p>
                </span>
                <span>{}</span>

                {/* {item?.podcast_id && (
                  <span className="fw-light fs-16 mobile-font-10">
                    {" "}
                    {item?.total_time == 0
                      ? ""
                      : toHoursAndMinutes(item?.total_time)}
                  </span>
                )} */}
              </div>
            </div>
          </div>
        </Link>
      </Col>
    );
  };

  let optionsTag=[]

  tagListItems &&
    tagListItems.map((list) =>
      optionsTag.push({ label: list.name, value: list.id })
    );
   
  return (
    <div className="d-flex flex-column flex-grow-1 homepage">
      <div className="page-wrapper flex-grow-1 py-0">
        <section className="banner-wrapper text-start position-relative bg-wrap">
          <Container>
            <div className="block font-32 text-center fw-light video-style-font">
              <h1 className="font-64 mb-md-3 mb-0">Podcast</h1>
              <p className="d-none d-md-block long-text">
                  Experterna
              </p>
            </div>
            <div className="banner-video">
              <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
              ></video>
            </div>
          </Container>
        </section>
        {/* Starts podcast To get start section */}
        <div className="">
          <Container fluid>
            <div className="py-80 mobile-pt-40">
              {/* <div className="text-center mb-md-3 mb-0">
                <h2 className="text-white mb-0 d-inline-block wow fadeInDown fw-lighter font-40 d-md-block d-none">
                  {t("What_to_learn_more_about")}
                </h2>
              </div> */}
              <Row>
                  <Col md={8} sm={7} className="mb-4">
                    <div className="dark-card h-100">
                      <h4 className="fw-medium">Podcast</h4>
                      <p className="fw-light font-20 mobile-font-14 mb-0">Ta del av podcasten Experterna där Michel Westher får experterna att dela med sig av sin kunskap. </p>
                    </div>
                  </Col>
                  <Col md={4} sm={5} className="mb-4">
                    <div className="dark-card h-100">
                      <h4 className="fw-medium text-center mb-0">Vad är du intresserad av?</h4>
                      <div className="course-select single-select w-100">
                        <Select 
                          menuPortalTarget={document.body} 
                          className="custom-select"
                          classNamePrefix="myselect"
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          options={optionsTag} 
                          placeholder={t('Alla_Podcast')} 
                          value={optionsTag.filter(function (option) {
                            return option.value === Number(imageIndex);
                          })}
                          isSearchable={false}
                          onChange={(values) =>customSort(values, values.value-1)} />
                        </div>
                      </div>
                  </Col>
              </Row>
          
              {/* {tagListItems.length > 0 && (
                <div className="categories-slider mobile-mb-14">
                  {tagListItems?.map((item, index) => (
                    <div
                      key={index}
                      className="feature-card-wrap-slider"
                      onClick={() => customSort(item, index)}
                    >
                      <div
                        className={`feature-box ${
                          index == imageIndex ? "border-primary-solid" : ""
                        }`}
                      >
                        <p>{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}

              <Row className="g-md-7 g-5">
                {allPodcasts?.length > 0 &&
                  allPodcasts.map((item, key) => (
                    <MediaList item={item} key={key} index={key} />
                  ))}
              </Row>
            </div>
          </Container>
        </div>
        {/* Ends podcast To get start section */}
        {allPodcasts && allPodcasts.length === 0 && (
          <p className="text-center" style={{marginTop: 20,}}>{t("No_records_found")}</p>
        )}
      </div>
    </div>
  );
};
export default PodcastList;
