import React, { useEffect, useState, useRef, Fragment } from "react";
import Header from "../commom/Header2";
import { Row, Col, Image, Button, Modal, Form, Table } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getCertificate
} from "../../store/api/course.api";
import { useTranslation } from "react-i18next";

import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import TextBlock from "../component/TextBlock";
import moment from 'moment'
import { useNavigate, Link } from "react-router-dom";

require('moment/locale/sw.js');
const Certificate = () => {

  const [data, setData] = useState(false)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [allCerti, setAllCerti] = useState();
  let dataFilter = {
     "filter":{
        "c.user_id":id
    }
   }
  useEffect(() => {
      dispatch(getCertificate(dataFilter)).then((res) => {
        setAllCerti(res.payload.data);
      });
  }, [])
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
        <InnerHeader title={'Diplom'} subtitle="Här ser du dina diplom" />

        <Wrapper>

        {allCerti && Object.keys(allCerti).length  > 0 &&
            <div className="d-lg-block d-none w-100">
              <div className="shadow-block table-borderwrap rounded-20 text-svart bg-white overflow-hidden">
                <Table bordered responsive className='text-center tableblock-wrap mb-0'>
                  <thead className='top-bordernone'>
                    <tr>
                      <th>Kurs</th>
                      <th>{t('Exam_Name')}</th>
                      <th>Åtgärd</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                     
                     allCerti &&  allCerti.length >0 &&  allCerti?.map((item, index) => (
                    <tr key={item.certificate_id}>
                      <td>{item.course_title}</td>
                      <td>{item.exam_name}</td>
                      <td>
                        <div className="d-flex gap-10px justify-content-center">
                          {/* <Button variant="link">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.62674 4.46043L14.7992 1.40295C18.9155 0.0308583 21.1519 2.27806 19.7906 6.39432L16.7331 15.5667C14.6804 21.7357 11.3096 21.7357 9.25684 15.5667L8.3493 12.8442L5.62674 11.9366C-0.542246 9.8839 -0.542246 6.52396 5.62674 4.46043Z" stroke="#292D32" stroke-opacity="0.8" stroke-width="1.62057" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.55469 12.3796L12.4225 8.50098" stroke="#292D32" stroke-opacity="0.8" stroke-width="1.62057" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </Button> */}
                          <a href={'https://docens.se:3005/'+item.certificate_url} target="_blank" variant="link">
                            <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="0.8">
                                <path d="M16.5257 10.7023C16.5257 12.9282 14.727 14.7269 12.5011 14.7269C10.2752 14.7269 8.47661 12.9282 8.47661 10.7023C8.47661 8.47639 10.2752 6.67773 12.5011 6.67773C14.727 6.67773 16.5257 8.47639 16.5257 10.7023Z" stroke="#292D32" stroke-width="1.68628" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5002 19.9999C16.4686 19.9999 20.1672 17.6616 22.7416 13.6145C23.7533 12.0294 23.7533 9.3651 22.7416 7.77992C20.1672 3.73284 16.4686 1.39453 12.5002 1.39453C8.5318 1.39453 4.83321 3.73284 2.25881 7.77992C1.24711 9.3651 1.24711 12.0294 2.25881 13.6145C4.83321 17.6616 8.5318 19.9999 12.5002 19.9999Z" stroke="#292D32" stroke-width="1.68628" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                            </svg>

                          </a>
                          {/* <Button variant="link">
                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="0.8">
                                <path d="M8.4315 16.8855C8.3292 16.8855 8.2371 16.865 8.1348 16.8241C7.8483 16.7115 7.66409 16.425 7.66409 16.118V9.97839C7.66409 9.55885 8.012 9.21094 8.4315 9.21094C8.8511 9.21094 9.19899 9.55885 9.19899 9.97839V14.2659L9.93569 13.5292C10.2325 13.2324 10.7237 13.2324 11.0204 13.5292C11.3171 13.8259 11.3171 14.3171 11.0204 14.6138L8.97389 16.6604C8.83059 16.8036 8.6259 16.8855 8.4315 16.8855Z" fill="#292D32" />
                                <path d="M8.4325 16.8836C8.2381 16.8836 8.04369 16.812 7.89019 16.6585L5.84369 14.6119C5.54689 14.3152 5.54689 13.824 5.84369 13.5272C6.14039 13.2305 6.6316 13.2305 6.9283 13.5272L8.9749 15.5738C9.2716 15.8705 9.2716 16.3617 8.9749 16.6585C8.8214 16.812 8.627 16.8836 8.4325 16.8836Z" fill="#292D32" />
                                <path d="M14.572 22.0004H8.4323C2.8759 22.0004 0.501999 19.6264 0.501999 14.07V7.93037C0.501999 2.374 2.8759 0 8.4323 0H13.5487C13.9682 0 14.3161 0.347913 14.3161 0.767455C14.3161 1.187 13.9682 1.53491 13.5487 1.53491H8.4323C3.715 1.53491 2.0369 3.21308 2.0369 7.93037V14.07C2.0369 18.7873 3.715 20.4655 8.4323 20.4655H14.572C19.2893 20.4655 20.9674 18.7873 20.9674 14.07V8.95364C20.9674 8.5341 21.3153 8.18619 21.7349 8.18619C22.1544 8.18619 22.5023 8.5341 22.5023 8.95364V14.07C22.5023 19.6264 20.1283 22.0004 14.572 22.0004Z" fill="#292D32" />
                                <path d="M21.7349 9.72253H17.6418C14.1422 9.72253 12.7812 8.36156 12.7812 4.86197V0.768874C12.7812 0.461892 12.9654 0.175376 13.252 0.0628156C13.5385 -0.0599772 13.8659 0.0116519 14.091 0.226539L22.2772 8.41273C22.4921 8.62762 22.5637 8.96531 22.441 9.25182C22.3182 9.53834 22.0419 9.72253 21.7349 9.72253ZM14.3162 2.621V4.86197C14.3162 7.50201 15.0018 8.18761 17.6418 8.18761H19.8828L14.3162 2.621Z" fill="#292D32" />
                              </g>
                            </svg>

                          </Button> */}
                        </div>
                      </td>
                    </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
            }
            { allCerti && Object.keys(allCerti).length === 0&&
                  <div className="text-center max-w-469 mx-auto">
                  <div className="text-center mb-20px empty-gif">
                    <Image src="/assets/images/nocerti.gif" alt="Du har inga diplom ännu" width={486} height={339} className="rounded-20px" />
                  </div>
                  <div className="text-start block mb-20px">
                    <h5 className="font-24 mobile-font-16 fw-medium mb-10px line text-center">Du har inga diplom ännu</h5>
                    <span className="fw-medium font-20 mobile-font-14 text-center d-inline-block opacity-60">Efter slutförd kurs har du möjlighet att ta ett slutprov. Vid godkänt slutprov får du ett diplom.</span>
                  </div>
                  <Button variant="primary" onClick={() => navigate("/my-course-details")}>Se alla kurser</Button>
                </div>
                  }
            
            {allCerti && Object.keys(allCerti).length  > 0 &&
          <div className="shadow-block rounded-20 w-100 text-svart bg-white d-lg-none d-block">
            <div className="p-md-20px p-16px title-header">
              <TextBlock title="List" />
            </div>
            <div className="d-grid gap-16px grid p-16px p-md-20px">
            {
                     
                     allCerti &&  allCerti.length >0 &&  allCerti?.map((item, index) => (
              <div className="d-flex gap-16px align-items-center">
                <span className="width-47 profileimage-box">
                  <Image src={item?.course_image} alt="Stresshantering" width={47} height={47} className="img-fit" />
                </span>
                <div className="profiletext-info d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-16 fw-medium text-svart mb-2px">{item?.exam_name}</h4>
                  </div>
                  <a href={'https://docens.se:3005/'+item.certificate_url} target="_blank" variant="link">
                            <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="0.8">
                                <path d="M16.5257 10.7023C16.5257 12.9282 14.727 14.7269 12.5011 14.7269C10.2752 14.7269 8.47661 12.9282 8.47661 10.7023C8.47661 8.47639 10.2752 6.67773 12.5011 6.67773C14.727 6.67773 16.5257 8.47639 16.5257 10.7023Z" stroke="#292D32" stroke-width="1.68628" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5002 19.9999C16.4686 19.9999 20.1672 17.6616 22.7416 13.6145C23.7533 12.0294 23.7533 9.3651 22.7416 7.77992C20.1672 3.73284 16.4686 1.39453 12.5002 1.39453C8.5318 1.39453 4.83321 3.73284 2.25881 7.77992C1.24711 9.3651 1.24711 12.0294 2.25881 13.6145C4.83321 17.6616 8.5318 19.9999 12.5002 19.9999Z" stroke="#292D32" stroke-width="1.68628" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                            </svg>

                          </a>
                </div>
              </div>
               ))
              }
            </div>
          </div>
}


        </Wrapper>
      </div>
    </div>
  );
};

export default Certificate;
