import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCources, customSort } from "../../store/api/course.api";
import { Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "./Header2";
import Header1 from "./Header";
import InnerHeader from '../public/InnerHeader';
import Wrapper from '../component/Wrapper';
import { Reorder } from "framer-motion";
import { customSortPodcast, getPodcasts } from "../../store/api/podcast.api";

const ReorderPodcast = (prop) => {
  const dispatch = useDispatch();
  const [allCources, setAllCources] = useState();
  const { role_id } = useSelector((state) => state?.auth?.user);

  const { t } = useTranslation();
  const listItems = [];
  const [items, setItems] = useState(listItems);
  const courses = [];
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  useEffect(() => {
    dispatch(getPodcasts(dataFilter)).then((podcast) => {
      setAllCources(podcast.payload.data);
      podcast.payload.data?.map((list, index) => {
        listItems.push({
          name: list.title,
          id: list.podcast_id,
        });
      });
    });
  }, []);

  const onAdd = () => {
    items?.map((list, index) => courses.push({ id: list.id, sort: index }));
    let data = {
      podcasts: courses,
    };
    dispatch(customSortPodcast(data)).then((response) => {});
  };
  allCources?.map((list, index) => {
    listItems.push({ name: list.title, id: list.podcast_id });
  });
  return (
    <div className='innerblock-page'>
         <Header headerShow={false} />
         <div className='page-wrapper flex-grow-1 page-block product-listpage'>
            <InnerHeader title={t('Change_Order')} />
            <Wrapper>
            <Reorder.Group values={items} onReorder={setItems}>
           {items.map((item) => (
             
             <Reorder.Item key={item.id} value={item}>
               <div
                 style={{
                   color: item.podcast == 1 ? "#47B872" : "#7015FF",
                   fontSize: 20,
                   width: "300px",
                   height: "30px",
                   borderRadius: "2px",
                   paddingLeft: "10px",
                   marginTop: "20px",
                   border: "1px solid black",
                 }}
               >
                 {item.name}
               </div>
             </Reorder.Item>
           ))}
         </Reorder.Group>
         <Button
           variant="primary"
           className="font-18 mt-15 w-sm-100 wow fadeInDown"
           type="button"
           onClick={onAdd}
         >
           {t("Submit")}
         </Button>
            </Wrapper>
            </div>
            </div>
            
   
  );
};
export default ReorderPodcast;
