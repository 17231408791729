import React, { useEffect, useState } from "react";
import { Table, Image, Button, Pagination } from "react-bootstrap";
import Header from "../commom/Header2";
import { useDispatch } from "react-redux";
import ConfirmPopup from "../commom/ConfirmPopup";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  getPodcastProductList,
  getPodcastProductRemove,
} from "../../store/api/podcast.api";
import AddNewPodcastProduct from "./AddNewPodcastProduct";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import { PaginationControl } from 'react-bootstrap-pagination-control';

require("moment/locale/sw.js");

const PodcastProductList = () => {
  const [show, setShow] = useState(false);
  const [allProduct, setAllProduct] = useState();
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editCourseId, setEditCourseId] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageLimit = 10;
  let data = {
    filter: {
      is_delete: 0,
    },
    page: page,
    limit: pageLimit,
  };
  const handleShow = (id, name, courseId) => {
    setEditId(id);
    setEditName(name);
    setEditCourseId(courseId);
    setShow(true);
  };
  const refresh = () => {
    dispatch(getPodcastProductList(data)).then((res) => {
      setAllProduct(res.payload.data);
    });
  };
  const callbackModal = () => {
    setShow(false);

    refresh();
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    refresh();
 }, [page]);
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });

  
  const handleDelete = (id) => {
    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      let data = {
        is_delete: 1,
        media_id: popup.id,
      };
      dispatch(getPodcastProductRemove(data))
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            refresh();
          }
        })
        .catch((e) => {
          console.log(e);
        });

      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block product-listpage">
        <InnerHeader title={t("Podcast_Product_List")} />
        <Wrapper>
          <div className="d-flex justify-content-end align-items-center mb-13 flex-sm-nowrap flex-wrap wow fadeInDown">
            {/* <h1 className="mb-sm-0 mb-4 fw-semibold text-white h2">
            {t("Podcast_Product_List")}{" "}
          </h1> */}
            <Button
              type="button"
              className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100"
              onClick={() => handleShow(null)}
            >
              {t("Add_New")}
            </Button>
            {show && !editId && (
              <AddNewPodcastProduct
                show={show}
                editId={null}
                editName={null}
                editCourseId={null}
                callbackModal={callbackModal}
              />
            )}
          </div>
          {/* Starts Table */}
          <div className='shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap'>
            <Table bordered responsive className="text-center tableblock-wrap mb-0">
              <thead className='top-bordernone'>
                <tr>
                  <th>{t("Images")}</th>
                  <th>{t("Podcast_Name")}</th>
                  <th>{t("Media_Name")}</th>
                  <th>{t("Date")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {allProduct?.media?.map((product, key) => (
                  <tr key={product?.media_id}>
                    <td>
                      <span className="table-img mx-auto">
                        <Image
                          src={
                            product.media_image
                              ? product.media_image
                              : "/assets/images/placeholder-image.png"
                          }
                          alt="Outlook"
                          width={68}
                          height={68}
                          className="img-fit"
                        />
                      </span>
                    </td>
                    <td>{product?.podcast_title}</td>
                    <td>{product?.media_title}</td>
                    <td>{moment(product?.created_at).format("DD-MMM-YYYY")}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <a
                          onClick={() =>
                            handleShow(
                              product?.media_id,
                              product?.podcast_title,
                              product?.podcast_id
                            )
                          }
                          className="btn btn-link icon-ic-edit text-black link-hover action-btn"
                        ></a>
                        {show && editId === product?.media_id && (
                          <AddNewPodcastProduct
                            show={show}
                            editId={editId}
                            editCourseId={editCourseId}
                            editName={editName}
                            callbackModal={callbackModal}
                            selectedEditData={product}
                          />
                        )}
                        <a
                          onClick={() => handleDelete(product.media_id)}
                          className="btn btn-link icon-ic-trash text-black link-hover action-btn"
                        ></a>
                        {popup.show && (
                          <ConfirmPopup
                            handleDeleteTrue={handleDeleteTrue}
                            handleDeleteFalse={handleDeleteFalse}
                            message={t("You_sure_you_wanna_delete")}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {allProduct && allProduct?.media?.length === 0 && (
              <p className="text-center">{t("No_records_found")}</p>
            )}
          </div>
          {/* Ends Table */}
          <div className='mt-3'></div>
               <PaginationControl
                        page={page}
                        total={allProduct && allProduct.media_count}
                        limit={pageLimit}
                        changePage={(page) => {
                          setPage(page);
                        }}
                        ellipsis={1}
                      />
        </Wrapper>
      </div>
    </div>
  );
};

export default PodcastProductList;
