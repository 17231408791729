import React, { useEffect, useState } from "react";
import Header from "../commom/Header2";
import Header1 from "../commom/Header";

import { Row, Col, Image, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCreator } from "../../store/api/course.api";
import ConfirmPopup from "../commom/ConfirmPopup";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Footer from "../commom/Footer";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import {
  addPodcasts,
  getPodcasts,
  podcasttagList,
  publishPodcast,
  removePodcasts,
} from "../../store/api/podcast.api";
import EditPodcast from "../commom/EditPodcast";
import { ck_config } from "../commom/commonConst";
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";

const PodCastNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { role_id, id } = useSelector((state) => state?.auth?.user);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allCources, setAllCources] = useState();
  const [allCretor, setAllCreator] = useState();
  const [value, setValue] = useState({
    title: "",
    tags: "",
    short_title: "",
    name_of_guest: "",
  });
  // const editorRef = useRef(null);
  const [textDescription, settextDescription] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPdf, setSelectedPDF] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);

  const [tempAllCources, setTempAllCources] = useState();
  const [showEditCourse, setshowEditCourse] = useState(false);
  const [editId, setEditId] = useState(null);
  const [pubMessage, setPubMessage] = useState("");
  const [tagListItems, setTagList] = useState([]);
  const [imageIndex, setImageIndex] = useState(-1);
  const [tagListToAssign, setTagListToAssign] = useState();

  let tags = [];
  let optionsTag = [];
  let courseoptionTag = [];

  const { stripe_subscription_id } = useSelector(
    (state) => state?.auth?.user?.subscription || {}
  );
  const user = useSelector((state) => state?.auth?.user);
  const { tagId, index } = useParams();

  // open when we click edit course
  const openShow = (id) => {
    setEditId(id);
    setshowEditCourse(true);
  };
  const editorConfiguration = ck_config;
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
    user_id: id,
    role_id: role_id,
  };

  if (role_id !== 1) {
    dataFilter.filter.is_public = 1;
  }
  const options = [];
  useEffect(() => {
    if (tagId && tagId != 1) {
      setImageIndex(index);
      dataFilter.tags = tagId.toString();
    }
    dispatch(getPodcasts(dataFilter)).then((course) => {
      setAllCources(course.payload.data);
    });
    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
      setSelectedCreator(data.payload.data[0].creator_id);
    });
    dispatch(podcasttagList()).then((data) => {
      setTagList(data.payload.data);
    });
  }, []);

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeCreator = (event) => {
    setSelectedCreator(event.value);
  };
  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };
  const closeModal = () => {
    setshowEditCourse(false);
    dispatch(getPodcasts(dataFilter)).then((course) => {
      setAllCources(course.payload.data);
    });
  };
  tagListItems &&
    tagListItems.map((list) =>
      optionsTag.push({ name: list.name, id: list.id })
    );
  tagListItems &&
    tagListItems.map((list) =>
      courseoptionTag.push({ label: list.name, value: list.id })
    );
  const onSelect = (selectedList, selectedItem) => {
    setTagListToAssign(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setTagListToAssign(selectedList);
  };
  allCretor &&
    allCretor.map((list) =>
      options.push({ value: list.creator_id, label: list.creator_name })
    );
  const PodcastList = ({ podcast, onDelete, index }) => {
    return (
      <Col md={6} xl={4}>
         {role_id !== 1 && (
        <div className="getstart-block position-relative d-flex flex-column feature-box"  onClick={() => gotoInnerPage(podcast)}>
          <>
            {" "}
            <Image
              className="img-fit cursor_class feature-img"
              src={
                podcast.image ? podcast.image : "/assets/images/placeholder-image.png"
              }
              alt="Image"
              width={526}
              height={348}
              onClick={() => gotoInnerPage(podcast)}
            />
            <div className="img-content text-white d-flex align-items-start justify-content-end">
              <div className="bottom-text text-md-start text-center">
                <h5
                  className="mb-1 fw-light cursor_class"
                  onClick={() => gotoInnerPage(podcast)}
                >
                  {podcast.title}
                </h5>
                <p className="fw-semibold cursor_class  mb-0 fs-16 mr-5" onClick={() => gotoInnerPage(podcast)}
                  > {podcast?.name_of_guest}</p>
              </div>
            </div>
          </>
        </div>
        )}
        {role_id === 1 && (
        <div className="getstart-block position-relative d-flex flex-column feature-box"  >
          <>
            {" "}
            <Image
              className="img-fit cursor_class feature-img"
              src={
                podcast.image ? podcast.image : "/assets/images/placeholder-image.png"
              }
              alt="Image"
              width={526}
              height={348}
              onClick={() => gotoInnerPage(podcast)}
            />
            <div className="img-content text-white d-flex align-items-start justify-content-end">
              <div className="bottom-text text-md-start text-center">
                <h5
                  className="mb-1 fw-light cursor_class"
                  onClick={() => gotoInnerPage(podcast)}
                >
                  {podcast.title}
                </h5>
                <p className="fw-semibold cursor_class  mb-0 fs-16 mr-5" onClick={() => gotoInnerPage(podcast)}
                  > {podcast?.name_of_guest}</p>
                <div className="mt-1">
                  {role_id === 1 && (
                    <a
                      className="btn btn-link icon-ic-trash text-white link-hover "
                      onClick={(e) => handleDelete(e, podcast.podcast_id)}
                    >
                      {" "}
                    </a>
                  )}
                  {popup.show && (
                    <ConfirmPopup
                      handleDeleteTrue={handleDeleteTrue}
                      handleDeleteFalse={handleDeleteFalse}
                      message={t("You_sure_you_wanna_delete")}
                    />
                  )}
                  {role_id === 1 && (
                    <a
                      className="btn btn-link icon-ic-edit text-white link-hover"
                      style={{ "margin-left": "5px" }}
                      onClick={() => openShow(podcast.podcast_id)}
                    ></a>
                  )}

                  {showEditCourse && editId === podcast.podcast_id && (
                    <EditPodcast
                      show={showEditCourse}
                      editId={editId}
                      closeModal={closeModal}
                    />
                  )}
                  {role_id === 1 && (
                    <label className="toggle-public-label">
                      <input
                        type="checkbox"
                        value={podcast.is_public}
                        checked={podcast.is_public === 1 ? true : false}
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span
                          className="label on"
                          onClick={() => handlePublish(false, podcast)}
                        >
                          {t("Off")}
                        </span>
                        <span
                          className="label off"
                          onClick={() => handlePublish(true, podcast)}
                        >
                          {t("On")}
                        </span>
                      </span>
                    </label>
                  )}
                  {publishPopup.show && (
                    <ConfirmPopup
                      handleDeleteTrue={handlePublishTrue}
                      handleDeleteFalse={handlePublishFalse}
                      message={t(pubMessage)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
         )}
      </Col>
    );
  };

  const onDelete = (id) => {
    let data = {
      podcast_id: id,
      is_delete: 1,
    };
    dispatch(removePodcasts(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPodcasts(dataFilter)).then((podcast) => {
            setAllCources(podcast.payload.data);

            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const gotoInnerPage = (course) => {
    navigate("/private-podcast-topics/" + course?.podcast_id);
  };
  const onSave = () => {
    var data = new FormData();
    tagListToAssign && tagListToAssign.map((list) => tags.push(list.id));
    data.append("title", value.title);
    data.append("creator_id", selectedCreator);
    data.append("description", textDescription);

    // data.append("suitable_for", textSuitableFor);
    data.append("short_title", value.short_title);
    data.append("name_of_guest", value.name_of_guest);
    if (tags.length > 0) {
      data.append("tags", tags);
    }

    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }

    dispatch(addPodcasts(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPodcasts(dataFilter)).then((podcast) => {
            setAllCources(podcast.payload.data);

            setShow(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  });

  const [publishPopup, setPublishPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
    flag: false,
  });
  const handleDelete = (e, id) => {
    e.preventDefault();
    setPopup({
      show: true,
      id,
    });
  };
  const handleDeleteTrue = () => {
    if (popup.show && popup.id) {
      onDelete(popup.id);
      setPopup({
        show: false,
        id: null,
      });
    }
  };
  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      id: null,
    });
  };
  const oncheckChange = (flag) => {
    if (flag) {
      if (role_id == 1) {
        setAllCources(allCources);
        setTempAllCources(allCources);
      } else {
        setAllCources(
          allCources.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
        setTempAllCources(
          allCources.filter(
            (item) => item.is_purchaed === true || item.is_assigned === true
          )
        );
      }
      setAllCources(
        allCources.filter(
          (item) => item.is_purchaed === true || item.is_assigned === true
        )
      );
    } else {
      setAllCources(tempAllCources);
    }
  };
  const customSort = (item, index) => {
    setImageIndex(item.value);

    if (item.label === "Alla Podcast") {
      delete dataFilter.tag;
      dispatch(getPodcasts(dataFilter)).then((podcast) => {
        setAllCources(podcast.payload.data);
      });
    } else if (item.label === "Mina podcast") {
      delete dataFilter.tag;
      dispatch(getPodcasts(dataFilter)).then((podcast) => {
        setAllCources(podcast.payload.data);
      });
    } else {
      dataFilter.tags = item.value.toString();

      // dataFilter.tags = item.id.toString();
      dispatch(getPodcasts(dataFilter)).then((podcast) => {
        setAllCources(podcast.payload.data);
      });
    }
    navigate("/my-podcast-details/" + item.label + "/" + item.value + "/" + index);
  };
  const handlePublish = (flag, podcast) => {
    setPublishPopup({
      show: true,
      podcast,
      flag,
    });
    if (flag) {
      setPubMessage("Vill_du_publicera_denna_podcast");
    } else {
      setPubMessage("Vill_du_ta_bort_den_här_podcast");
    }
  };
  const handlePublishTrue = () => {
    if (publishPopup.show && publishPopup.podcast) {
      makeMePublic(publishPopup.flag, publishPopup.podcast);
      setPublishPopup({
        show: false,
        id: null,
        flag: false,
      });
    }
  };
  const handlePublishFalse = () => {
    setPublishPopup({
      show: false,
      course: null,
      flag: false,
    });
  };

  const makeMePublic = (flag, podcast) => {
    let data;
    data = {
      podcast_id: podcast.podcast_id,
      is_public: flag == true ? 1 : 0,
    };
    dispatch(publishPodcast(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPodcasts(dataFilter)).then((podcast) => {
            setAllCources(podcast.payload.data);
          });
        }
      });
  };

  return (
    <>
      <div className="innerblock-page">
        <Header headerShow={false} />
        <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
          <div className="d-flex gap-lg-0 gap-20px justify-content-between align-items-center flex-lg-nowrap flex-wrap wow fadeInDown py-20px px-lg-30px  px-20px innerpage-header">
            <div className="text-svart fw-medium">
              <h1 className="mb-sm-0 mb-2px text-svart fw-medium text-white h4 mobile-font-16">{"Podcast"}</h1>
              <span className="opacity-60 fs-20 text-svart fw-medium mobile-font-14">Experterna</span>
            </div>
            <div className="single-selectionblock">
              <Select
                className="custom-select shadow-selection header-selection"
                classNamePrefix="myselect"

                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={courseoptionTag}
                placeholder={t('Alla_Podcast')}
                value={courseoptionTag.filter(function (option) {
                  return option.value === Number(imageIndex);
                })}
                isSearchable={false}
                onChange={(values) => customSort(values, values.value - 1)} />
            </div>
          </div>
          {/* <InnerHeader title={t('Podcast')} subtitle="" /> */}

          <Wrapper>

            {/* <span>{t('Köp_enstaka_kurs_till_ett')}</span> */}
            {/* {role_id !== 1 &&
               <label className="toggle-label">
                    <input type="checkbox" />
                    <span className="back">
		              <span className="toggle"></span>
                    <span className="label on" onClick={()=>oncheckChange(false)}>{t('All')}</span>
                    {role_id !== 4 &&
                    <span className="label off" onClick={()=>oncheckChange(true)}>{t('Course_you_buy')}</span>
                     }
                    {role_id === 4 &&
                    <span className="label off" onClick={()=>oncheckChange(true)}>{t('Course_you_Assigned')}</span>
                     }
                    </span>
                </label>
               } */}
            {role_id === 1 && (
              <>
                <div className="right-button d-flex align-items-center justify-content-end flex-wrap gap-10px mb-3">
                  <Button
                    variant="primary"
                    className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100"
                    onClick={handleShow}
                  >
                    {t("Add_New")}
                  </Button>
                  <Link
                    to="/reorder-podcast"
                    className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100"
                  >
                    {t("Ordering")}
                  </Link>
                  <Link
                    to="/podcast/taggar"
                    className="btn btn-primary flex-shrink-0 font-18 min-200 w-sm-100"
                  >
                    {t("Tags")}
                  </Link>
                </div>
              </>
            )}

            {/* {tagListItems.length > 0 && (
          <div className="categories-slider">
            {tagListItems?.map((item, index) => (
              <div
                key={index}
                className="feature-card-wrap-slider"
                onClick={() => customSort(item, index)}
              >
                <div
                  className={`feature-box ${
                    index == imageIndex ? "border-primary-solid" : ""
                  }`}
                >
                  <p>{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        )} */}
            {/* <img src={item.image?item.image:'/assets/images/SVG/053-diamond.svg'}  className='feature-image svg-btn-image-no-hover' alt="Banner image" width={40} height={40} /> */}

            {/* <div className="feature-card-wrap"> */}
            {/* <Slider {...featurecard}> */}
            {/* </div> */}
            {/* </Slider> */}
            <Row className="g-xl-20px g-8px">
              {allCources?.length > 0 &&
                allCources?.map((podcast, key) => (
                  <PodcastList
                    podcast={podcast}
                    key={key}
                    index={key}
                    onDelete={onDelete}
                  />
                ))}
            </Row>
          </Wrapper>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Create_Podcast")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={"Title"}
                    name="title"
                    aria-label="Product Name"
                    value={value.title}
                    onChange={onChange}
                  />
                  <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
                </div>
              </Form.Group>

              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("short_title")}
                    name="short_title"
                    aria-label="Product Name"
                    value={value.short_title}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              <Form.Group
                className="mb-10 wow fadeInDown"
                controlId="formBasicEmail"
              >
                <div className="position-relative control-withic">
                  <Form.Control
                    type="text"
                    placeholder={t("name_of_guest")}
                    name="name_of_guest"
                    aria-label="Product Name"
                    value={value.name_of_guest}
                    onChange={onChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-10 wow fadeInDown">
                {/* <Form.Control as="select"  className='flex-grow-1 question-control' custom='true' onClick={onChangeCreator}  >
                     
                     <option disabled value={-1} key={-1}>{t('select')}</option>
                     
                            { allCretor && allCretor.map(list =>
                                <option key={list.creator_id} value={list.creator_id}>{list.creator_name}</option>)}
                                
                  </Form.Control> */}
                {options && (
                  <Select
                    className="custom-select black-theme"
                    classNamePrefix="myselect"
                    options={options}
                    onChange={onChangeCreator}
                  />
                )}
              </Form.Group>
              <Row className="g-xl-20px g-8px">
                <Col md={6} xl={4}>
                  <div className="mb-10 wow fadeInDown">
                    <input
                      type="file"
                      id="file1"
                      className="visually-hidden"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="file1"
                      className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                    >
                      <span className="file-icon">
                        <Image
                          src="/assets/images/Video_file_light.png"
                          alt="Select Image"
                          width="48"
                          height="48"
                        />
                      </span>
                      <span>{t("Select_Image")}</span>
                    </label>
                  </div>
                </Col>
              </Row>

              {/**desc */}
              <div className="mb-10 wow fadeInDown">
                <CKEditor
                  style={{ color: "#fff" }}
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data=""
                  placeholder="Enter data"
                  onChange={(event, editor) => {
                    settextDescription(editor.getData());
                  }}
                />
              </div>

              {tagListItems && (
                <Multiselect
                  options={optionsTag}
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options,
                  selectedValues={tagListToAssign} // Preselected value to persist in dropdown
                  showCheckbox
                />
              )}

              <Button
                variant="primary"
                type="button"
                className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
                onClick={onSave}
              >
                {" "}
                {t("Save")}{" "}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        {/* {role_id !== 1 &&
          <Footer />
        } */}
      </div>
    </>
  );
};

export default PodCastNew;
