import React, {  useEffect, useState } from 'react';
import {  Form, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { getemployees, removeUser } from '../../store/api/course.api';

require('moment/locale/sw.js');
const DeleteEmpNew = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   const [showDelete1, setShowDelete1] = useState(false);

  
   
   const handleClose = () => {
      setShow(false);
      prop.closeModal();
   }
   
   useEffect(() => {
      setShow(prop.show)
      
      
     
   }, [])
   

   
   const onDelete = () => {
    let data = {
       'is_delete':1,
       'id': prop.editId
    }
    dispatch(removeUser(data))
       .unwrap()
       .then((res) => {
          if (res.status === 200) {
                setShow(false)
               setShowDelete1(true)
          }
       })
       .catch((e) => {
          console.log(e)
       });

 };
  
  
   

   return (
      <>
     
      <Modal centered show={show} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Ta bort användare</Modal.Title>
          <span>{prop.userName}</span>
        </Modal.Header>
        <Modal.Body className="p-20px">
          <span className="text-center fw-medium text-svart block pb-20px d-block">Är du säker?</span>
          <div className="d-flex align-items-center justify-content-center gap-20px">
            <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark" onClick={handleClose} >Nej jag klickade fel</Button>
            <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium btn.btninner-sec" variant="primary" onClick={() => {
            onDelete();
            }}>Ja</Button>
          </div>
        </Modal.Body>
      </Modal>
      {showDelete1  &&
      <Modal centered show={showDelete1} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Ta bort användare</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-20px ">
          <span className="text-start fw-light text-svart block d-block">Användaren är nu borttagen</span>

        </Modal.Body>
      </Modal>
        }
      </>
   )
}

export default DeleteEmpNew;