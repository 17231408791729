import React, { useEffect, useState } from 'react';
import { Form, Table, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { watchhistory } from '../../store/api/course.api';
import Slider from 'react-rangeslider'
import { useTranslation } from "react-i18next";
require('moment/locale/sw.js');
const MediawiseDetails = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   let dataFilter = {
      "filter": {
         "c.user_id": prop.id
      }
   }
   const [show, setShow] = useState(false);
   const [mediaList, setMediaList] = useState();
   const [allCerti, setAllCerti] = useState();
   const handleClose = () => {
      setShow(false);
      prop.callbackModal();
   }

   let dataH = {
      user_id: prop.id
   }

   useEffect(() => {
      setShow(prop.show)
      dispatch(watchhistory(dataH)).then((res) => {
         setMediaList(res.payload.data);
      })
     
   }, [])

   return (
      <>
         <Modal size="xl" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title>{prop.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='usermanagement-modal'>
             
               <Table bordered hover responsive className='text-center'>
                  <thead>
                     <tr>
                        <th>{t('Index')}</th>
                        <th>{t('Course_Name')}</th>
                        <th>Avsnitt</th>                       
                        <th>Aktivitet</th>
                        
                     </tr>
                  </thead>
                  <tbody>
                     {
                        mediaList?.map((item, index) => (
                           <tr key={item?.id}>
                              <td>
                                 <span className='table-img mx-auto'>
                                    {index+1}
                                 </span>
                              </td>
                              <td>{item?.course_name}</td>
                              <td>{item?.title}</td>
                              
                              <td>
                              
                           
                           <Slider
                              min={0}
                              max={100}
                              value={parseFloat(item.view_time?item.view_time + '%':0 + '%').toFixed(2)}
                              orientation='horizontal'
                              handleLabel={parseFloat(item.view_time?item.view_time + '%':0 + '%').toFixed(2)}
                           />
                       
                              </td>
                           </tr>
                        ))
                     }
                  </tbody>
               </Table>
            </Modal.Body>
         </Modal>

      </>
   )
}

export default MediawiseDetails;