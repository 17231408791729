import React, {  useEffect, useState } from 'react';
import {  Form, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {getSingleUser,editSingleEmployee } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import DeleteEmpNew from "../commom/DeleteEmpNew"
require('moment/locale/sw.js');
const AddUserNew = (prop) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   const [value, setValue] = useState({ firstname: "", lastname: "", code: "", phone: "", email: "" });
  
   const [iseditable, setIseditable] = useState(true);
  
   const { business_id } = useSelector((state) => state.auth.user);
   const [showDelete, setShowDelete] = useState(false);
   
   const handleClose = () => {
      setShow(false);
      prop.closeModal();
   }
   const onChange = (e) => {
      
      setValue({
         ...value,
         [e.target.name]: e.target.value,
      });
   };
   useEffect(() => {
      setShow(prop.show)
      
      
      if (prop.editId) {
         dispatch(getSingleUser(prop.editId)).then((res) => {
            
            setValue({
               ...value,
               ['firstname']: res.payload.data[0].first_name,
               ['lastname']: res.payload.data[0].last_name,
               ['email']: res.payload.data[0].email,
               ['phone']: res.payload.data[0].phone_no?res.payload.data[0].phone_no:'',
               ['code']: res.payload.data[0].tel_code,
             });
         })
      }
   }, [])
   

   
  
  
   const onEdit = () => {
      
      let body;
      body = {
         "id":prop.editId,
        'first_name':value.firstname,
        'last_name':value.lastname,
        'email':value.email,
        'business_id':business_id,
        
      }
      dispatch(editSingleEmployee(body))
         .unwrap()
         .then(() => {
            prop.closeModal();
         })
         .catch(() => {
            prop.closeModal();
         });
   }
   
   const closeModal = () => {
      
      setShowDelete(false);
      prop.closeModal();
    
    }
   return (
      <>
      <Modal centered show={show} onHide={handleClose} className="modal-innerbox ">
        <Modal.Header closeButton className="closeclass-block">
          <Modal.Title>Redigera användare</Modal.Title>
          <span>{value.firstname} {value.lastname}</span>
        </Modal.Header>
        <Modal.Body className="p-20px">
          <Form className="d-flex flex-column gap-20px">
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">Namn</Form.Label>
              <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' name="firstname" placeholder="Erik" autoComplete="off" aria-label="exam_name" onChange={onChange} value={value.firstname}  readOnly={iseditable} />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">Last Name</Form.Label>
              <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' name="lastname" placeholder="Eriksson" autoComplete="off" aria-label="exam_name" onChange={onChange} value={value.lastname}  readOnly={iseditable} />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="font-20 text-svart fw-medium mb-2 d-block">E-post</Form.Label>
              <Form.Control type="text" className='fs-20 mobile-font-14 form-inputblock fw-light' name="email" placeholder="Erik1992@gmail.com" autoComplete="off" aria-label="exam_name" onChange={onChange} value={value.email} readOnly={true}  />
            </Form.Group>
          
            <div className="d-flex justify-content-between align-items-center pt-0">
              <div className="d-flex gap-12px">
                <Button className="text-blue text-decoration-underline fs-20 mobile-font-16 fw-medium" variant="link" onClick={() => setIseditable(false)} >Ändra</Button>
                <Button className="text-blue text-decoration-underline fs-20 mobile-font-16 fw-medium" variant="link" onClick={() => {
                 setShow(false)
                  setShowDelete(true)
                }}>Ta bort</Button>
              </div>
              <Button className="py-1 btnfont-16 btnblock-link mobile-font-medium" variant="outline-dark" onClick={onEdit}>Spara</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {showDelete  &&
                     <DeleteEmpNew show={showDelete} editId={prop.editId} userName={prop?.userName}  closeModal={closeModal} />
      }
      </>
   )
}

export default AddUserNew;