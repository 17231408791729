import React, { useEffect, useState } from "react";

import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { getCreator } from "../../store/api/course.api";
import { editEducation } from "../../store/api/podcast.api";
import { ck_config } from "./commonConst";

const EditEducation = (prop) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const options = [];

  const [value, setValue] = useState({
    title: "",
    sub_title: "",
    course_length: "",
    course_fee: "",
    course_start: "",
    sort_title: "",
    extent: "",
  });
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [textDescription, setTextDescription] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [allCretor, setAllCreator] = useState();
  const [textSuitableFor, setTextSuitableFor] = useState(null);
  const [whatyouwillLearn, setWhatyouwilllearn] = useState(null);

  const editorConfiguration = ck_config;

  const handleClose = () => {
    setShow(false);
    prop.closeModal();
  };

  const onChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setShow(prop.show);

    dispatch(getCreator()).then((data) => {
      setAllCreator(data.payload.data);
      setSelectedCreator(data.payload.data[0].creator_id);
    });
    // set data from selected education
    if (prop.editId) {
      setTextContent(prop?.selectedEducationData.content);
      setTextSuitableFor(prop?.selectedEducationData.suitable_for);
      setWhatyouwilllearn(prop?.selectedEducationData.what_you_will_learn);
      setTextDescription(prop?.selectedEducationData.description);
      setValue({
        ...value,
        ["title"]: prop?.selectedEducationData.title,
        ["sub_title"]: prop?.selectedEducationData.sub_title,
        ["course_fee"]: prop?.selectedEducationData.course_fee,
        ["course_length"]: prop?.selectedEducationData.course_length,
        ["course_start"]: prop?.selectedEducationData.course_start,
        ["extent"]: prop?.selectedEducationData.extent,
      });
    }
  }, []);

  // on edit education to api
  const onSave = () => {
    var data = new FormData();
    data.append("id", prop.editId);
    data.append("title", value.title);
    data.append("content", textContent);
    data.append("creator_id", selectedCreator);
    data.append("what_you_will_learn", whatyouwillLearn);
    data.append("suitable_for", textSuitableFor);
    data.append("sub_title", value.sub_title);
    data.append("course_length", value.course_length);
    data.append("course_start", value.course_start);
    data.append("course_fee", value.course_fee);
    data.append("description", textDescription);
    data.append("extent", value.extent);
    if (selectedFile) {
      data.append("image", selectedFile, selectedFile.name);
    }

    dispatch(editEducation(data))
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          handleClose();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  allCretor &&
    allCretor.map((list) =>
      options.push({ value: list.creator_id, label: list.creator_name })
    );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit_Education")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={"Title"}
                  name="title"
                  aria-label="Product Name"
                  value={value.title}
                  onChange={onChange}
                />
                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("Underrubrik")}
                  name="sub_title"
                  aria-label="Product Name"
                  value={value.sub_title}
                  onChange={onChange}
                />
                <span className="icon-ic-edit edit-btn pointer flex width-24 position-absolute font-18 text-white link-hover"></span>
              </div>
            </Form.Group>

            <Row className="gy-10">
              <Col sm={6}>
                <div className="mb-10 wow fadeInDown">
                  <input
                    type="file"
                    id="file1"
                    className="visually-hidden"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <label
                    htmlFor="file1"
                    className="file-uploadblock d-inline-flex align-items-center align-items-center pointer"
                  >
                    <span className="file-icon">
                      <Image
                        src="/assets/images/Video_file_light.png"
                        alt="Select Image"
                        width="48"
                        height="48"
                      />
                    </span>
                    <span>{t("Select_Image")}</span>
                  </label>
                </div>
              </Col>
            </Row>

            <div className="mb-10 wow fadeInDown">
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={textContent}
                placeholder="Enter data"
                onChange={(event, editor) => {
                  setTextContent(editor.getData());
                }}
              />
            </div>

            {/**description */}
            <p className="mb-sm-0  fw-semibold text-white h3">
              {t("Beskrivning")}
            </p>
            <div className="mb-10 wow fadeInDown">
              <CKEditor
                style={{ color: "#fff" }}
                editor={ClassicEditor}
                config={editorConfiguration}
                data={textDescription}
                placeholder="Enter description"
                onChange={(event, editor) => {
                  setTextDescription(editor.getData());
                }}
              />
            </div>
            {/**what you will learn */}
            <p className="mb-sm-0  fw-semibold text-white h3">
              {t("What_you_will_learn")}
            </p>

            <div className="mb-10 wow fadeInDown">
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={whatyouwillLearn}
                placeholder="What you will learn"
                onChange={(event, editor) => {
                  setWhatyouwilllearn(editor.getData());
                }}
              />
            </div>
            {/*Who is the course suitable for ?*/}
            <p className="mb-sm-0  fw-semibold text-white h3">
              {t("Who_suitable_for")}
            </p>

            <div className="mb-10 wow fadeInDown">
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={textSuitableFor}
                placeholder="Who is the course suitable for?"
                onChange={(event, editor) => {
                  setTextSuitableFor(editor.getData());
                }}
              />
            </div>

            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("Course_length")}
                  name="course_length"
                  aria-label="Course Length"
                  value={value.course_length}
                  onChange={onChange}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("Course_start")}
                  name="course_start"
                  aria-label="Product Name"
                  value={value.course_start}
                  onChange={onChange}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("Course_fee")}
                  name="course_fee"
                  aria-label="Product Name"
                  value={value.course_fee}
                  onChange={onChange}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-10 wow fadeInDown"
              controlId="formBasicEmail"
            >
              <div className="position-relative control-withic">
                <Form.Control
                  type="text"
                  placeholder={t("Extent")}
                  name="extent"
                  aria-label="Product Name"
                  value={value.extent}
                  onChange={onChange}
                />
              </div>
            </Form.Group>

            <Button
              variant="primary"
              type="button"
              className="font-18 min-200 mt-9 w-sm-100 wow fadeInDown"
              onClick={onSave}
            >
              {" "}
              {t("Save")}{" "}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditEducation;
