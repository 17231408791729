import React, { useEffect, useState } from 'react';
import Header from '../commom/Header2';
import { Image } from 'react-bootstrap';
import { getnotification,rednotification } from '../../store/api/course.api'
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import moment from 'moment'
require('moment/locale/sw.js');
const Notifications = () => {
    const dispatch = useDispatch();
    const [allNotification, setAllNotification] = useState();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(getnotification()).then((res) => {
            setAllNotification(res.payload.data.list);
        });
    }, [])
    if(allNotification?.length > 0) {
        dispatch(rednotification()).then((res) => {
        });
    }
    return (
        <>
            <Header />
            <div className='page-wrapper flex-grow-1 page-block'>
                <div className=''>
                    <div className='card-block text-white h-100'>
                        <div className='card-header'>
                            <h4 className='mb-0 fw-medium text-white text-truncate'>{t('Notifications')}</h4>
                        </div>
                        <div className='card-body p-0'>
                            {allNotification &&
                                allNotification.map((item, key) => (
                                    <div key={item.id} className='notification-innerblock d-flex align-items-md-center align-items-start font-16'>
                                        <span className='width-58 notif-round flex me-5'>
                                            <Image src='/assets/images/notification.svg' alt="Good News ! you have to recived lorent" width={31} height={31} />
                                        </span>
                                        <div className='d-flex justify-content-between notification-info flex-lg-nowrap flex-wrap'>
                                            <p className='mb-0 flex-grow-1 notification-text'>{item?.content} </p>
                                            <p className='mb-0 notif-time flex-shrink-0 font-14 text-light'>{moment(item?.created_at).format("DD-MMM") }</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notifications;