import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setQuantityPremium } from '../../store/api/course.api';

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

const AssignSubcription = (prop) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
  
    const [value, setValue] = useState({quantity: prop.userDetail.purchage_qty, email: prop.userDetail.email });
    
    const [selectedtc, setSelectedTc] = useState(prop.userDetail.purchage_qty > 0? true: false);
    let filter = {
        "filter": {
            "is_delete": 0,
        },
        page: 1,
        limit: 10
    }
  
    const handleClose = () => {
        setShow(false);
        prop.closeModal();
    }
    useEffect(() => {
        console.log(prop.userDetail);
        setShow(prop.show)
      
    }, [])
    const handletc = () => {
        setSelectedTc(!selectedtc);
    };
    const onChange = (e) => {
             setValue({
           ...value,
           [e.target.name]: e.target.value,
         });
       };
    // on press signup button
    const onAdd = () => {
        let data = {
            course_quantity: value.quantity,
            is_quantity_purchased: selectedtc
          }
          if (prop.userDetail.business_id) {
            data.business_id = prop.business_id;
          } else {
            data.user_id = prop.userDetail.id;
          }
          dispatch(setQuantityPremium(data)).then(async (res) => {
           
            //  navigate('/my-course-details'); 
            handleClose()
          }).catch(err => {
            
            toast.error(err.message)
          });
    };
   

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Assign_Course')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='max-511 mx-auto assigncourse-wrap'>
                       
                        <Form.Group className='mb-10'>
                            <Form.Control type="text" name="email" placeholder={t('Email')} autoComplete="off" aria-label="length" value={value.email} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-10'>
                            <Form.Control type="text" name="quantity" placeholder={t('Set_Qantity')} autoComplete="off" aria-label="length" value={value.quantity} onChange={onChange}
                            />
                        </Form.Group>
                        <div className="p-default mb-10">
                            <input type="checkbox" name="tc" checked={selectedtc} onChange={handletc} value={value.tc} />
                            <label> &nbsp;{t('Set_Primium_Quantity')}</label>
                        </div>
                        <Button variant="primary" className='btn-small btn-block font-18 w-100' onClick={onAdd}>
                            {t('Assign_Course')}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AssignSubcription;