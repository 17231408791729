import React, { useEffect, useState, useRef, Fragment } from "react";
import Header from "../commom/Header2";
import { Image, Button, Table } from "react-bootstrap";
import InnerHeader from "./InnerHeader";
import TextBlock from "../component/TextBlock";
import Wrapper from "../component/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSingleUser
} from "../../store/api/course.api";
import moment from "moment";
const Deadlines = () => {
  const {  id } = useSelector((state) => state?.auth?.user || {});
  const dispatch = useDispatch();
  const [data, setData] = useState(true)
  const [dataWithDeadline, setDataWithDeadline] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSingleUser(id)).then((res) => {
       setDataWithDeadline(res.payload.data[0].assign_course?.filter((item) => item.expired_at !== null));
       
    })
  }, []);
  const gotoCourse = () =>{
    navigate('/my-course-details'); 
 }
  return (
    <div className='innerblock-page'>
      <Header headerShow={false} />
      <div className="page-wrapper flex-grow-1 page-block overflow-hidden mycoursedetails-page">
        <InnerHeader title={'Deadlines'} subtitle="Här ser du dina deadlines" />

        <Wrapper>

          {dataWithDeadline && dataWithDeadline?.length >0 ?
            <div className="d-lg-flex d-none w-100">
              <div className="shadow-block rounded-20 text-svart bg-white overflow-hidden table-borderwrap w-100">
                <Table bordered responsive className="text-center tableblock-wrap mb-0">
                  <thead className='top-bordernone'>
                    <tr>
                      <th>Kurs</th>
                      <th>Dagar till deadline</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                  { dataWithDeadline && dataWithDeadline?.length >0 && dataWithDeadline.map((course, key) => (
                    <tr>
                      <td>{course?.course_title}</td>
                      <td >{moment.duration(moment(course?.expired_at).diff(Date.now())).days()>0?moment.duration(moment(course?.expired_at).diff(Date.now())).days():'Deadline har redan varit'}</td>
                      <td>{moment(course?.expired_at).format(
                                    "DD-MM-YYYY"
                                  )}</td>
                    </tr>
                   ))}
                  </tbody>
                </Table>
              </div>
            </div>
            : <div className="text-center max-w-469 mx-auto">
              <div className="text-center mb-20px">
                <Image src="/assets/images/nodeadline.gif" alt="Du har inga diplom ännu" width={486} height={339} className="rounded-20px mobile-imgfull" />
              </div>
              <div className="text-start block mb-20px">
                <h5 className="font-24 mobile-font-16 fw-medium mb-md-10px mb-0 line text-center">Du har inga deadlines</h5>
                <span className="fw-medium font-20 mobile-font-14 text-center d-inline-block opacity-60">Tacka gudarna... eller din chef! 🙏🏻</span>
              </div>
              <Button variant="primary" onClick={gotoCourse}>Se alla kurser</Button>
            </div>
          }
        { dataWithDeadline && dataWithDeadline?.length >0 && dataWithDeadline.map((course, key) => (
          <div className="shadow-block rounded-20 text-svart bg-white d-lg-none d-block">
            <div className="p-md-20px p-16px title-header">
              <TextBlock title="Deadlines" />
            </div>
            <div className="d-grid gap-16px grid p-16px p-md-20px">
           
              <div className="d-flex gap-16px align-items-center">
                <span className="width-47 profileimage-box">
                  <Image src={course?.course_image} alt="Stresshantering" width={47} height={47} className="img-fit" />
                </span>
                <div className="profiletext-info d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-16 fw-medium text-svart mb-2px">{course?.course_title}</h4>
                    <span className="font-14 fw-medium opacity-60 text-svart">{moment(course?.expired_at).format(
                                    "DD-MM-YYYY"
                                  )}</span>
                  </div>
                  <span className="font-14 fw-medium">{moment.duration(moment(course?.expired_at).diff(Date.now())).days()}</span>
                </div>
              </div>
              
            </div>
          </div>
          ))}
        </Wrapper>

      </div>
    </div >
  );
};

export default Deadlines;
