import React, { useState, useEffect } from 'react';

import { Image, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';
import { addNewsLatter, editNewsLatter, getSingleNews, getCreator } from '../../store/api/course.api';
import Select from 'react-select';
import SubscribeForm from '../commom/SubscribeForm';

const TakeEmail = (prop) => {
    const [show, setShow] = useState(false);
    const handleClose = (flag) => {
        setShow(false);
        prop.closeModal(flag);
    }
    useEffect(() => {
        setShow(prop.show);
    }, []);
    return (
        <>
            <Modal show={show} onHide={()=>handleClose(false)} centered>
                <Modal.Header closeButton className='no-border-model'>
                </Modal.Header>
                <Modal.Body>
                    <section className="">
                        <SubscribeForm  chk_id={3} closeModal={()=>handleClose(true)} />
                    </section>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default TakeEmail;