import { Container } from "react-bootstrap";
import React, {useEffect} from 'react';
import Header from '../commom/Header';


const Terms = () =>{
  
   useEffect(() => {
    
    }, [])
   return(
      <>
       <div className='d-flex flex-column flex-grow-1 homepage'>
         <Header />
         <div className='page-wrapper flex-grow-1 py-0'>
            {/* Starts Page Banner */}
            <section className="banner-wrapper text-start position-relative bg-wrap mb-5">
                <Container>
                <div className="block font-32 text-center fw-light video-style-font">
                    <h1 className="font-64 mb-md-3 mb-0">Användarvilkor</h1>
                    <p className="d-none d-md-block">Fortress edu AB</p>
                </div>
                </Container>
                <div className="banner-video">
                <video
                src="/assets/video/Background-flow.mp4"
                autoplay="autoplay"
                playsinline="playsinline"
                muted="muted"
                loop="loop"
                ></video>
            </div>
            </section>
            {/* Ends Page Banner */}
            <div className="pageContainer">
                <div className="container-fluid container--small container--big__text">
                    <h1 className="h1">Användarvillkor DOCENS</h1>
                    <p>Gäller från och med 13:e december 2022 </p>
                    <p>
                    Tack för att du har valt DOCENS och ett extra stort tack för att du tar dig tid att noggrant läsa igenom våra användarvillkor. Vi vill börja med att i en kort sammanfattning förklara varför vi har skapat dessa användarvillkor. Vårt grundläggande mål är att på ett tydligt och lättläst vis
                    </p>
                    <ul>
                    <li>förklara vilken roll som du som företag eller privatperson (”användare”) och vi har i detta sammanhang</li>
                    <li>förklara hur du som användare ska agera för att vi på DOCENS ska kunna leverera och fortsätta utveckla vår plattform för utbildningar</li>
                    <li>säkerställa att du förstår de villkor som gäller för att du ska få använda vår plattform</li>
                    </ul>
                    <div>
                        <p>
                        Vår målsättning är att du, efter att ha läst dessa villkor, ska känna dig säker på ditt val av DOCENS och plattformen som vi tillhandahåller. Om du har några frågor eller funderingar är du givetvis varmt välkommen att höra av dig till oss. Tack för ditt förtroende och tack för att du använder DOCENS!
                        </p>
                    </div>
                    <div>
                        <p>
                        Dessa allmänna villkor (”Villkoren”) gäller då du besöker vår hemsida eller använder vår eventuella webb- och/eller mobilbaserade applikation samt när DOCENS tillhandahåller utbildningar via licens eller skräddarsydda utbildningar.
                        </p>
                    </div>
                    <h6>Information om DOCENS</h6>
                    <div>
                        <p>
                        DOCENS (Fortress Edu AB, Org.nr. 559376-5521, Dumpergatan 3, 442 40 Kungälv), säljer utbildningar via den egna webbplatsen DOCENS.se. Genom att genomföra ett köp godkänner du våra villkor.
                        </p>
                        <p>
                        DOCENS tillhandahåller utbildningar och föreläsningsserier via vår plattform ”Plattform”.
                        </p>
                        <p>Kontakt sker via mail: <b><a href="mailto:info@docens.se">info@docens.se</a></b></p>
                    </div>
                    <h6>Allmänna villkor</h6>
                    <div>
                        <p>
                        Samtliga priser på hemsidan anges i SEK och är inklusive 25% moms. Vi förbehåller oss rätten att justera priser då felaktigheter uppstått p.g.a. felaktig information, tryckfel eller prisändringar från berörda samarbetspartners. 
                        </p>
                        <p>
                        Efter slutförd beställning skickas en orderbekräftelse innehållandes pris och produkt uppgifter till angiven mailadress. Vid eventuella fel i orderbekräftelse kontaktas DOCENS.se omgående via mail: info@DOCENS.se.
                        </p>
                        <p>
                        Vid eventuella fel ska en reklamation skickas till info@DOCENS.se. Här ska en beskrivning av felet lämnas tillsammans med ordernummer, namn och kontaktuppgifter. Om varan inte är felaktig i enlighet med gällande konsumentlagstiftning förbehåller vi oss rätten att neka en reklamation. 
                        </p>
                        <p>
                        Vi förbehåller oss rätten att när som helst ändra i villkoren. Om vi gör detta kommer ändringarna att läggas upp på den här sidan. Längst upp på sidan kommer vi att ange det datum då villkoren senast reviderades. Din fortsatta användning av tjänsten efter detta datum utgör ditt godkännande av de nya användarvillkoren.
                        </p>
                    </div>
                    <h6>Prenumeration</h6>
                    <div>
                        <p>Din prenumeration till våra tjänster är personlig och får ej offentliggöras eller i övrigt delas med andra parter.</p>
                        <p>Prenumerationen löper antingen månadsvis eller på årsbasis beroende på vilken prenumeration du valt. Om du valt vår månadsprenumeration löper den på månadsbasis utan uppsägningstid. Vid den 25 dagen i innevarande månad förlängs prenumerationen per automatik med en månad i taget om uppsägning ej har skett. </p>
                        <p>Vid årsprenumeration löper avtalet årsvis och förlängs med tolv månader i taget om prenumerationen ej sagts upp senast 7 dagar innan prenumerationsperioden har löpt ut. Debitering sker månadsvis. </p>
                        <p>Vänligen notera att din lagstadgade ångerrätt om 7 dagar ej kan tillämpas i den mån du redan påbörjat någon av våra utbildningar (d.v.s. utnyttjat innehållet i prenumerationen).</p>
                        <p>Genom att nyttja våra tjänster är du införstådd med att du har läst igenom och accepterat våra användarvillkor. Vi förbehåller oss rätten att när som helst ändra i villkoren. Om vi gör detta kommer ändringarna att läggas upp på den här sidan. Längst upp på sidan kommer vi att ange det datum då villkoren senast reviderades. Din fortsatta användning av tjänsten efter detta datum utgör ditt godkännande av de nya användarvillkoren.</p>
                    </div>
                    <h6>Integritetspolicy </h6>
                    <div>
                        <p>
                        Nedan följer en kort redogörelse över hur vi hanterar din integritet. För mer utförlig information kring detta kan du granska vår separata Integritetspolicy. DOCENS är ansvarig för all behandling av dina personuppgifter som samlas in när du besöker vår webbplats. Vi samlar in och använder personuppgifter i enlighet med denna policy och de krav som gäller enligt dataskyddsförordningen, GDPR. Det är viktigt att du tar del av vår integritetspolicy, så att du känner dig trygg i vår behandling av dina personliga uppgifter.
                        </p>
                        <p>
                        När du ingår avtal med oss eller i övrigt intar en roll som användare av våra tjänster, ber vi dig att ge oss information som innefattar ditt namn, faktureringsadress, leveransadress, e-postadress, telefonnummer, kreditkortsuppgifter/betalningsuppgifter och valfri kontoinformation såsom användarnamn och lösenord. Vi använder denna information för syften, såsom att:
                        </p>
                        <p>
                        Skicka information till dig om ditt konto och din beställning.
            Svara på dina frågor, inklusive återköp och klagomål.
            Behandla betalningar och förhindra bedrägerier.
            Konfigurera ditt konto för vår butik.
            Följa lagliga krav vi har, såsom momsberäkning.
            Skicka dig marknadsföringsmeddelanden, om du har valt att ta emot dem.

                        </p>
                        <p>
                        Om du skapar ett konto kommer vi att lagra ditt namn, adress, e-postadress och telefonnummer, vilket kommer användas för att fylla i dina uppgifter i kassan för framtida beställningar.
                        </p>
                        <p>
                        Generellt lagrar vi bara information om dig så länge vi behöver informationen för de ändamål vilka vi samlar in och använder den, och så länge vi inte är juridiskt skyldiga att lagra den. Till exempel kommer vi att lagra beställningsinformation för gällande tidsperiod för skatte- och redovisningsändamål. Detta inkluderar ditt namn, e-postadress och fakturerings- och leveransadresser.
                        </p>
                        <p>
                        Inga uppgifter som inte frivilligt uppges registreras på hemsidan. Uppge inga uppgifter som du inte önskar ska registreras. Genom att fylla i dina uppgifter på någon utav våra sidor accepterar du vår behandling av dina personuppgifter enligt denna integritetspolicy.
                        </p>
                        <p>
                        Har du frågor om hur vi hanterar personuppgifter kan du granska vår Integritetspolicy eller kontakta oss på mail: <b><a href="mailto:info@docens.se">info@docens.se</a></b>
                        
                        </p>
                    </div>
                <h6>Cookies</h6>
                <h6>Vad är Cookies?</h6>
                    <div>
                        <p>
                        En cookie är en liten textfil som placeras på din dator när du besöker en webbplats. I den här textfilen lagrar webbplatsen diverse information kring ditt besök. Det är ofta information som används för statistik och analys och är helt oskadliga. De påverkar inte din dator eller mobiltelefon. Tack vare cookies kan webbsidor bland annat anpassas efter vad du gjort tidigare. 
                        </p>
                        <p>
                        Det finns två typer av cookies. Den ena typen sparar en fil på din dator under en längre tid men har ett utgångsdatum, medan den andra, kallad sessionscookies, saknar utgångsdatum. Den förstnämnda används exempelvis till att tala om vad som är nytt sedan du besökte hemsidan förra gången och raderas från din dator efter utgångsdatumet. Den senare lagras bara tillfälligt för att t.ex. hålla reda på vilket språk du har valt. När du stänger ner webbläsaren försvinner den. 
                        </p>
                    </div>
                    <h6>Du kan själv styra användningen av cookies</h6>
                    <div>
                        <p>
                        Enligt lagen om elektronisk kommunikation (LEK) ska alla besökare på en webbplats få information om att cookies används. Användandet av cookies kräver samtycke och genom att ställa in din webbläsare kan du bestämma om du vill ha cookies eller inte. Att inte acceptera cookies kan dock innebära att funktionaliteten på en hemsida kan begränsas.
                        </p>
                    </div>
                    <h6>Vår användning av cookies</h6>
                    <div>
                        <p>
                        På www.DOCENS.se används cookies för att lagra och inhämta information i din webbläsare. Detta görs bl.a. för att ge dig tillgång till funktioner som ökar användbarheten samt för att kunna rikta personligt anpassad information.
                        </p>
                        <p>
                        Om du besöker vår inloggningssida kommer vi att sätta en tillfällig cookie för att undersöka om din webbläsare accepterar dem. Denna cookie innehåller inga personuppgifter och den försvinner när du stänger din webbläsare.
                        </p>
                        <p>
                        När du loggar in kommer vi dessutom att skapa flera cookie-filer för att spara information om din inloggning och dina val för utformning av skärmlayouten. Cookie-filer för inloggning gäller i två dagar och cookie-filer för layoutval gäller i ett år. Om du kryssar i ”Kom ihåg mig” kommer din cookie att finnas kvar i två veckor. Om du loggar ut från ditt konto kommer cookie-filerna för inloggning att tas bort.
                        </p>
                        <p>
                        Vi använder också cookies för att hålla reda på varukorgens innehåll medan du använder vår webbplats.
                        </p>
                    </div>
                    <h6>Medan du besöker vår webbplats, kommer vi att spåra:</h6>
                    <div>
                        <p>
                        Produkter som du har visat: vi använder detta för att, till exempel, visa dig produkter som du nyligen har tittat på. Platsinformation, IP-adress och webbläsartyp: vi använder detta för ändamål såsom momsberäkning och fraktkostnader. Leveransadress: vi ber dig ange detta så att vi, till exempel, kan beräkna frakt innan du beställer, och skicka dig beställningen. Se mer information i vår Integritetspolicy.
                        </p>
                    </div>
                <h6>Ansvarsbegränsning</h6>
                    <div>
                        <p>
                        DOCENS.se tar inget ansvar för indirekta skador som kan uppstå vid användandet av denna hemsida eller för skador som beror på virus eller liknande, förorsakade vid nerladdning av material, data, texter, bilder, ljud eller video från denna hemsida.
                        </p>
                    </div>
            <h2>ÖVRIGA VILLKOR</h2>
                <h6>FORCE MAJEURE</h6>
                    <div>
                        <p>
                        Part är befriad från påföljd för underlåtenhet att fullgöra viss förpliktelse enligt detta avtal, om underlåtenheten har sin grund i omständighet över vilken sådan part inte råder och som förhindrar fullgörandet t.ex. strejk, lockout eller dylikt liksom myndighetsingripande eller andra omständigheter utanför parts kontroll.
                        </p>
                    </div>

                    <h6>ÖVRIGT</h6>
                    <div>
                        <p>Oaktat eventuella motsägelser i dessa Villkor, skall användaren, i egenskap av licenstagare eller beställare av skräddarsydd utbildning, utföra, fullgöra och underhålla sin avtalsenliga relation till så att ingen handling eller underlåtelse i deras relation utgör, orsakar eller bidrar till att DOCENS blir ersättningsskyldig mot dess föreläsare. </p>
                    </div>

                    <h6>TILLÄGG OCH ÄNDRINGAR</h6>
                    <div>
                        <p>
                        Vi förbehåller oss också rätten att ändra regler och villkor, och din användning av Plattformen efter att sådana ändringar genomförts ska anses utgöra ditt medgivande till sådana modifierade Villkor. Du samtycker till att regelbundet granska dessa Villkor för att kontrollera om de har ändrats. Om dessa Villkor skulle ändras kommer vi att sträva efter att på lämpligt sätt meddela detta via Plattformen eller per e-post.
                        </p>
                    </div>

                    <h6>ANVÄNDARENS ÅTAGANDEN samt ANVÄNDNING AV PLATTFORMEN</h6>
                    <div>
                        <p>
                        Användaren ansvarar för att förhålla sig till Docens vid var tid gällande villkor. I den mån Användaren är licenstagare gäller att en licens får användas för en person, eventuellt missbruk kommer att debiteras enligt gällande taxa. Användaren ansvarar vidare för att respektera föreläsaren och dess material och ej sprida detta utanför ramen för dess licenstagare. 
                        </p>
                        <p>Användaren skall erlägga betalning i enlighet med villkoren som överenskommits med Docens och licensavgift (eller kostnad för skräddarsydd utbildning) faktureras i enlighet med villkoren i den mellan Parterna godkända offerten.</p>
                        <p>Vid användning DOCENS Plattform samt övriga tjänsteutbud garanterar Användaren att denne ej agerar på ett sätt som:</p>
                        <ul>
                        <li>
                        (i) bryter eller kränker någon annans rätt till publicitet, integritet, upphovsrätt, varumärke eller annan immateriell rättighet.
                        </li>
                        <li>
                        (ii) är bedrägligt, kriminellt eller olagligt;
                        </li>
                        <li>
                        (iv)föreställer en annan person eller förvränger ett förhållande till en annan person;
                        </li>
                        <li>
                        (iii)kan vara obscent, opassande, pornografiskt, vulgärt, kränkande, rasistiskt, sexistiskt, diskriminerande, förolämpande, nedsättande, skadligt, trakasserande, skrämmande, besvärande, elakt eller hotfullt
                        </li>
                        <li>
                        (v) omfattar användning, spridning eller överföring av virus eller liknande som är avsedda att skada, störa, hindra eller ta över system, data eller personliga uppgifter.
                        </li>
                        
                        </ul>

                        <p>Vid användning DOCENS Plattform samt övriga tjänsteutbud garanterar Användaren också att denne inte, utan att först ha inhämtat skriftligt samtycke från DOCENS, kommer att:</p>
                        <ul>
                        <li>
                        (i) använda varumärken, logotyper, design, fotografier, instruktionsvideor eller annat material som används i vår Plattform.
                        </li>
                        <li>
                        (ii) kopiera, arkivera, ladda ner, ladda upp, distribuera, sända, framföra, visa, göra tillgängligt eller på annat sätt använda någon del av vara tjänster eller innehållet i Plattformen utöver vad som anges i Villkoren.
                        </li>
                        <li>
                        (iii) använda Plattform, några verktyg som tillhandahålls genom Plattformen eller något innehåll i Plattformen för något kommersiellt syfte, för vilket ett skriftligt samtycke från DOCENS saknas.
                        </li>
                        
                        
                        </ul>

                    </div>

                    <h6>PLATTFORMENS TILLGÄNGLIGHET, SÄKERHET OCH RIKTIGHET</h6>
                    <div>
                        <p>
                        Trots att DOCENS eftersträvar att ständigt ha Plattformen tillgänglig, kan DOCENS inte hållas ansvariga för det fall att Plattformen av någon anledning inte skulle vara tillgänglig. DOCENS kan därmed inte garantera att Användarens tillgång till Plattformen kommer vara oavbruten och utan fel. Dessutom kan DOCENS då och då vara tvungna att genomföra reparationer och underhåll av Plattformen som kan påverka dess tillgänglighet och funktionalitet. I den mån utbildningarna skickats till Användaren påverkas denne ej av sådana avbrott men i den mån utbildningarna finns tillgängliga på Plattformen är Användaren införstådd med att det kan förekomma tillfälliga avbrott.
                        </p>
                        
                        <p>Tillgång till Plattformen kan när som helst stängas av för dig som Användare, likaså för samtliga användare och licenstagare av Plattformen, tillfälligt eller permanent och utan förvarning. DOCENS kan också införa restriktioner för hur någon del av Plattformen används, utan att behöva meddela Användaren i förväg eller ange skäl för införandet av restriktionerna i den mån något avtalsbrott har upptäckts. </p>
                    <p>Docens ansvarar ej för det innehåll som förmedlas genom utbildningarna man tillhandahåller via Plattformen. Användaren är införstådd med att föreläsarna är oberoende konsulter med särskild kunskap inom sina respektive områden och Docens har inte möjlighet att granska varje del av den information som förmedlas.</p>
                    </div>

                    <h6>TVIST</h6>
                    <div>
                        <p>Tvist i anledning av detta avtal skall avgöras i enlighet med svensk rätt vid Göteborgs Tingsrätt. </p>
                    </div>

                    <h6>KONTAKT OCH SUPPORT</h6>
                    <div>
                        <p>
                        Du kan när som helst höra av dig till DOCENS för support och övriga frågor. 
            Supporten nås helgfria vardagar via mail på: <b><a href="mailto:info@docens.se">info@docens.se</a></b>

                        </p>
                    </div>

                    


                </div>
            </div>
           
         </div>
        </div>
      </>
      
   )
}

export default Terms;