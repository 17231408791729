import { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Image, Pagination, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCheckoutUrl,
  getPlans,
  getSinglePackage,
  getSubsPlan,
} from "../../store/api/course.api";

import Header from '../commom/Header2';
import Header1 from '../commom/Header';
import InnerHeader from "./InnerHeader";
import Wrapper from "../component/Wrapper";
import TextBlock from "../component/TextBlock";

const InnerPackageDetail = () => {
  const { packageid } = useParams();
  const dispatch = useDispatch();
  const [packageDetails, setPackageDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState('Om_Paketet');
  const { role_id, id, business_id, email } = useSelector((state) => state?.auth?.user);
  const [selectedPlan, setSelectedPlan] = useState();

  const { subscription } = useSelector((state) => state.auth.user || {});
  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = () => {
    let req = { package_id: packageid, user_id: id || null };

    dispatch(getSinglePackage(req)).then((packages) => {
      setPackageDetails(packages.payload.data);
    });
    dispatch(getSubsPlan())
      .unwrap()
      .then((res) => {
        if (res.status === 200) {
          let newArr = [];

          res.data
            .map((data) => {
              if (data.price_id == yearlyPlanName) {
                newArr.push(data);
              }
              if (data.price_id == monthlyPlan) {
                setSelectedPlan(data.price / 100);
                newArr.push(data);
              }
            })
            .filter((data) => (data = data));
        }
      })
      .catch(() => { });
  };
  
  
  const setTab = (tab) => {
    setSelectedTab(tab);
  }
  return (
    <div className="innerblock-page">
      {(role_id) &&
        <Header headerShow={false} />
      }
      {(!role_id) &&
        <Header1 />
      }
      <div className={"page-wrapper page-block main-wrapper text-lightblock " + (role_id ? 'pt-100 mobile-pt-15' : 'pt-14 mobile-pt-15')}>
        <InnerHeader title="Podcast" subtitle="Lorem Ipsum" backButton={true} backButtonURL={'/my-podcast-details'} />

        <Wrapper>
          <section className="mobile-pt-15 mb-80px">
            <Row className="gy-md-3 flex-md-row flex-column-reverse">
              <Col md={6}>
                <div className="videoblock-wrap">
                  <div className="position-relative">
                    <img src="https://i.vimeocdn.com/video/1720625188-3481bbbd31eef2fac81f3d334a7d9f5635bc798aaa5f5bca89a84adeade3506e-d_640" alt="Video Thumbnail" class="thumbnail-image rounded-20 w-100" />
                    <img src="/assets/img/icons/CircledPlay.svg" alt="Video Thumbnail" class="thumbnail-podcast-img .svg-btn-image-no-hover" width="100%" height="100%" />
                  </div>
                </div>
                <div className="p-10px shadow-block rounded-20 d-flex gap-5 mt-20px bg-white align-items-center">
                  <div className="d-flex text-svart align-items-center fs-20 fw-medium bg-grey-block px-5 py-18px rounded-20 flex-grow-1">
                    <Button variant="link" className="d-inline-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
                        <path d="M6 12.0798L1.40683 7.48663C0.864388 6.94419 0.864388 6.05654 1.40683 5.5141L6 0.920898" stroke="black" stroke-width="1.05671" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Button>
                    <span className="flex-grow-1 text-center">Introduktion av kurs</span>
                    <Button variant="link" className="d-inline-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
                        <path d="M1 12.0798L5.59317 7.48663C6.13561 6.94419 6.13561 6.05654 5.59317 5.5141L1 0.920898" stroke="black" stroke-width="1.05671" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Button>
                  </div>
                  <Button variant="primary">Reflektion</Button>
                </div>
              </Col>
              <Col md={6}>
                <div className="shadow-block bg-white rounded-20">
                  <div className="p-20px title-header">
                    <TextBlock title="Hur vi skapar engagemang" subtitle={'55:34 minuter'} />
                  </div>
                  <div className="p-20px title-header">
                    <div className="d-flex flex-column gap-10px">
                      <div className="text-svart teacherinfo-box  wow fadeInDown d-md-block">
                        <div className="full-width course-listing-table font-20 course-text-right mobile-font-13">
                          <div className=" d-flex justify-content-between link-hover">
                            <p className="mb-0">20. Uppgifter - ToDo</p>
                            <div className="text-right ps-12 ps-xl-2">55:34</div>
                          </div>
                        </div>
                      </div>
                      <div className="text-svart teacherinfo-box  wow fadeInDown d-md-block">
                        <div className="full-width course-listing-table font-20 course-text-right mobile-font-13">
                          <div className=" d-flex justify-content-between link-hover">
                            <p className="mb-0">20. Uppgifter - ToDo</p>
                            <div className="text-right ps-12 ps-xl-2">55:34</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-20px">
                  
                  </div>
                </div>
              </Col>
            </Row>
          </section>
          <section >
            <div className="shadow-block bg-white rounded-20">
              <div className="p-20px title-header">

                <TextBlock title="Engagemang, vad är det egentligen och hur blir man bra på att skapa det?" subtitle="Johan Book" />

              </div>
              <div>
                <div className="titleheader-box">
                  <div className="title-header p-20px">
                    <div className="fs-20 fw-light text-black">Johan är motivationsnörden som själv säger att han har ett: “Osunt intresse för forskningen inom motivation, ledarskap, medarbetarskap”. I det här avsnittet berättar Johan hur du och jag kan öka engagemanget på vår arbetsplats, med små enkla verktyg.
                      Bland annat får vi lära oss kraften av ett klapp på axeln och att ett “Bra gjort!” kan vara värt mer än en löneförhöjning för mitt engagemeng.</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Wrapper>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default InnerPackageDetail;
