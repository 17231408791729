import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getEducations } from "../../store/api/podcast.api";

const PrivateEducationList = () => {
  const dispatch = useDispatch();
  const { role_id, id } = useSelector((state) => state?.auth?.user || {});
  const [allEducation, setAllEducation] = useState();
  const [limit,setLimit] = useState(4);
  const { t } = useTranslation();
  const navigate = useNavigate();

  let dataFilter = {
    filter: {
      is_delete: 0,
      is_public: 1,
    },
    user_id: id ? id : null,
    role_id: role_id,
  };

  useEffect(() => {
    dispatch(getEducations(dataFilter)).then((course) => {
      setAllEducation(course.payload.data);
    });
  }, []);

  const add3Dots = (string, limit) => {
    if (string) {
      var dots = "...";
      if (string?.length > limit) {
        string = string.substring(0, limit) + dots;
      }
    }
    return string != "null" ? string : "";
  };

  const onCLickReadMore = (education) => {
    navigate("/private-educations-individual/" + education.slug);
  };

  return (
    <>
      <section>
        <div className="text-darkblock ">


          {allEducation?.length > 0 &&
            allEducation?.slice(0, limit)?.map((item) => {
              return (
                <div className="titleheader-box ">

                  <div className="d-flex align-items-lg-normal align-items-center w-100  position-relative bg-white title-header gap-40 p-lg-20px p-16px">
                    <div className="teamimg-wrap flex-shrink-0 ">
                      <Image src={item?.image} alt="Utbildning" className="w-100 h-100 img-fit" />
                    </div>

                    <div className="d-md-flex d-block flex-column">
                      <div>
                        <span className="fw-semibold d-md-block d-none mb-2">Utbildning </span>
                        <h3 className="font-32 fw-light font-32 mb-2 education-title"> {add3Dots(item?.title,isMobile?30:100)}</h3>
                        <div className="block font-20 fw-light blockinfo-content d-md-block d-none  mb-18px">
                          <div className="content-blokcwysiwg block"
                            dangerouslySetInnerHTML={{
                              __html: add3Dots(item?.content, 500),
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-2 mt-auto" onClick={() => onCLickReadMore(item)}>
                        <div className="d-flex gap-20px">
                          <div className="fw-light font-16 text-svart d-md-block d-none"><span className="fw-semibold">Omfattning:</span> <span className="d-inline-block">{item?.extent}</span></div>
                          <div className="fw-light font-16 text-svart subtext-block "><span className="fw-semibold">Kursstart:</span> <span className="d-inline-block">{item?.course_start}</span></div>
                        </div>
                        <Button variant="primary" className=" mobile-left mobile-font-8 d-md-block d-none flex-shrink-0" onClick={() => onCLickReadMore(item)}>Läs mer</Button>
                      </div>
                    </div>
                  </div>

                </div>

              );
            })}
          <div className="text-center pb-lg-20px pb-16px">
            {limit > 4 &&
              <Button onClick={()=> setLimit(100)} variant="link" className="readmore-btn">Visa mer</Button>
            }              
          </div>
        </div>

      </section>
    </>
  );
};
export default PrivateEducationList;
