export const googlesignupEvent = () =>{
    window.gtag("event","sign_up")
}


export const googlePurchangeEvent = (transaction_id,amount,item_id,item_name,price,qty) =>{
    window.gtag("event",
        "purchase", 
        {
        transaction_id: transaction_id,
        affiliation:"Docens",
        value: amount,
        tax:0,
        shipping: 0,
        currency:"SEK",
        coupon:"",
        items: [
            {
                item_id:item_id,
                item_name:item_name,
                affiliation:"Docens",
                coupon:"",
                currency:"SEK",
                discount: 0,
                index:0,
                item_brand:"Docens",
                item_category:"Education",
                price:price,
                quantity:qty
            }]

    })
}

export const facebookSignupEvent =() => {
    window.fbq('track','CompleteRegistration');
}

export const facebookPurchangeEvent =(amount) => {
    window.fbq('track','Purchase', 
    {currency:"SEK", 
     value: amount
    });
}
export const facebookTrialStartEvent = (amount) => {
    window.fbq('track','StartTrial', 
    {currency:"SEK", 
    value: amount, 
    predicted_ltv: amount});
}